import React from 'react';
import PropTypes from 'prop-types';

import DeviceCardListItem from './DeviceCardListItem';
import DeviceCardEmpty from './DeviceCardEmpty';

const DeviceCardList = ({ data }) =>
  data?.length ? <DeviceCardListItem {...data[0]} /> : <DeviceCardEmpty />;

DeviceCardList.defaultProps = {
  data: []
};

DeviceCardList.propTypes = {
  data: PropTypes.array
};

export default DeviceCardList;
