const businessNameParser = (basicData) => {
  if (basicData && basicData.tradeName) {
    return basicData.tradeName;
  }

  if (basicData && basicData.businessName) {
    return basicData.businessName;
  }

  return '';
};

const userNameParser = (qsa) => {
  let userName = '';

  if (Array.isArray(qsa) && qsa.length > 0) {
    userName = qsa.map((user) => user);
  }

  return userName;
};

const userCpfParser = (qsa) => {
  let userCpf = '';

  if (Array.isArray(qsa) && qsa.length > 0) {
    userCpf = qsa[0].cpfCnpj;
  }

  return userCpf;
};

const companyNameParser = (basicData) => {
  if (basicData && basicData.name) {
    return basicData.name;
  }
};

const cnaeCompanyParser = (basicData) => {
  const findMain = basicData?.activities?.find((cnaeItem) => {
    if (cnaeItem.main) {
      return cnaeItem;
    }
  });

  return findMain ? `${findMain.cnae} - ${findMain.description}` : 'Sem CNAE';
};

const cnaeListCompanyParser = (basicData) => {
  const filterList = basicData?.activities?.filter((cnaeItem) => {
    if (!cnaeItem.main) {
      return cnaeItem;
    }
  });

  return filterList?.length > 0 ? filterList : null;
};

const bigBoostDataParser = ({ basicData, qsa }) => {
  const parsedData = {
    activities: basicData?.economicActivities || [],
    ...basicData
  };

  return {
    qsa,
    companyName: companyNameParser(parsedData),
    businessName: businessNameParser(parsedData),
    user: {
      name: userNameParser(qsa),
      cpf: userCpfParser(qsa)
    },
    cnae: cnaeCompanyParser(parsedData),
    cnaeList: cnaeListCompanyParser(parsedData),
    capitalStock:
      parsedData?.capitalStock !== '' ? parsedData?.capitalStock : undefined,
    cnpjStatus: parsedData?.status !== '' ? parsedData?.status : undefined
  };
};

export {
  bigBoostDataParser,
  businessNameParser,
  userNameParser,
  userCpfParser,
  companyNameParser,
  cnaeCompanyParser,
  cnaeListCompanyParser
};
