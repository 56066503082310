import axios from 'axios';

export default function getEconomicGroupHistoriesApi(clientUuid, page) {
  const query = `
  query {
    economicGroup(economicGroupUuid: "${clientUuid}") {
      history(page: ${page}) {
        entries {
          id
          itemType
          createdAt
          event
          user
        }
        previousPage
        nextPage
      }
    }
  }`;

  return axios.post('/graphql', { query });
}
