import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import ClientStatusCreators from 'store/ducks/clientStatus';
import Header from './Header';

import BannerArea from './BannerArea';
import Infos from './Infos';
import HowItWorks from './HowItWorks';
import HowToEnable from './HowToEnable';
import CallToAction from './CallToAction';

import { Container } from './styles';

const AutomaticAnticipationLandingPage = ({ comboTax }) => {
  const textComboTax = comboTax.replace('.', ',');
  const dispatch = useDispatch();

  const { automaticAnticipationOptInConfirmed, clientId, clientClientId } =
    useSelector((state) => ({
      ...state.railsContext,
      ...state.clientStatus
    }));

  const fullstoryClassName = (index) => `lp_botao${index}_campanha_aa_combo`;

  const clientUUID = clientId || clientClientId;

  useEffect(() => {
    dispatch(ClientStatusCreators.getClientStatusRequest(clientUUID));
  }, [clientUUID, dispatch]);

  return (
    <Container
      data-testid="AutomaticAnticipationLandingPage"
      className="ocean-iso"
    >
      <Header
        tax={textComboTax}
        isCombo={automaticAnticipationOptInConfirmed}
        fullstoryClassName={fullstoryClassName('1')}
      />
      <HowItWorks
        tax={textComboTax}
        fullstoryClassName={fullstoryClassName('2')}
      />
      <BannerArea tax={textComboTax} />
      <Infos tax={textComboTax} fullstoryClassName={fullstoryClassName('3')} />
      <HowToEnable tax={textComboTax} />
      <CallToAction tax={textComboTax} />
    </Container>
  );
};

AutomaticAnticipationLandingPage.propTypes = {
  comboTax: PropTypes.string
};

AutomaticAnticipationLandingPage.defaultProps = {
  comboTax: '7,99'
};

export default AutomaticAnticipationLandingPage;
