import styled from 'styled-components';

export const Description = styled.p`
  margin: 0;
  font-size: 11px;
  text-align: center;

  ${(props) =>
    props.hasChildren &&
    `
    color: #afafaf;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 3px solid #e1e5ed;
  border-radius: 50%;
  width: 66px;
  height: 66px;
  margin-left: 2px;
  margin-right: 22px;
  margin-bottom: 8px;
  flex-shrink: 0;
  font-weight: bold;

  strong {
    font-size: 24px;
    line-height: 1;
  }

  ${(props) =>
    props.size === 'md' &&
    `
    width: 120px;
    height: 120px;
    border-width: 6px;

    strong {
      font-size: 40px;
    }

    ${Description} {
      font-size: 13px;
    }
  `}

  ${(props) =>
    !!props.hexColor &&
    `
    border-color: ${props.hexColor};
    color: ${props.hexColor};
  `}
`;

export const Image = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 18px;
`;
