import axios from 'axios';
import _ from 'lodash';
import { getCSRFToken } from 'utils/csrf-token';

export function getContractData(clientId) {
  return axios({ method: 'get', url: `/lojas/${clientId}/contratos.json` });
}

export function updateContractData(clientId, params) {
  return axios({
    method: 'post',
    url: `/lojas/${clientId}/contratos.json`,
    data: {
      client: params
    }
  });
}

export function createContract(uuid) {
  return axios({
    method: 'post',
    url: `/graphql`,
    data: {
      query: `mutation {
        createContractClient(clientUuid: "${uuid}") {
            token
        }
      }`
    }
  });
}

export function trackingContract(token) {
  return axios({
    method: 'post',
    url: `/graphql`,
    data: {
      query: `query {
        trackingJob(token: "${token}") {
            status
            message
        }
      }`
    }
  });
}

export function contractParams(user, client, address) {
  return {
    name: client.name,
    business_name: client.business_name,
    foundation_date: client.foundation_date,
    user: {
      name: user.name,
      email: user.email,
      cpf: user.cpf,
      phone: user.phone,
      phone_2: user.phone_2,
      phone_3: user.phone_3
    },
    main_address_attributes: {
      zipcode: address.zipcode,
      street: address.street,
      number: address.number,
      complement: address.complement,
      neighborhood: address.neighborhood,
      city: address.city,
      state: address.state,
      id: address.id
    }
  };
}

export function handleContractErrorStep(data) {
  if (!_.isEmpty(data.user)) {
    return 'USER_STEP';
  }

  if (!_.isEmpty(data.client) || !_.isEmpty(data.address)) {
    return 'CLIENT_STEP';
  }
}

export function updateUserData(clientId, user) {
  const normalizedCpf = user.cpf.replace(/[\s.-]*/gim, '');

  return axios({
    method: 'post',
    url: `/lojas/${clientId}/contrato/correcao_cadastral`,
    data: {
      user: {
        id: user.id,
        name: user.name,
        cpf: normalizedCpf
      }
    }
  });
}

export function updateUserDataProfile(clientId, user) {
  return axios({
    method: 'put',
    url: `/api/contract_signing_flow/users`,
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      Accept: 'version=1'
    },
    data: {
      user: {
        email: user.email,
        birthday: user.birthday,
        phone_2: user.phone_2,
        phone_3: user.phone_3
      },
      client: { uuid: clientId }
    }
  });
}

export function clientUserDataAddress(client) {
  return axios({
    method: 'put',
    url: `/api/contract_signing_flow/clients`,
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      Accept: 'version=1'
    },
    data: {
      client: {
        id: client.id,
        business_name: client.business_name,
        name: client.name,
        main_address: {
          zipcode: client.zipcode,
          street: client.street,
          number: client.number,
          complement: client.complement,
          neighborhood: client.neighborhood,
          city: client.city,
          state: client.state
        }
      }
    }
  });
}

export function getClientTaxes(clientId) {
  return axios({
    method: 'get',
    url: `/api/clients/${clientId}/pos_taxes`,
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken()
    }
  });
}

export function getAtecipationTax(clientId) {
  const query = `
  query {
    client(uuid: "${clientId}") {
      taxPlan {
        anticipationRate
        anticipationRateComposition
      }
    }
  }
  `;

  return axios.post('/graphql', { query });
}
