const useMultiacquirerHook = (BIG_BOOST) => {
  if (!BIG_BOOST) return null;

  const withoutBigBoostData = () => BIG_BOOST && BIG_BOOST.data === null;

  return {
    withoutBigBoostData
  };
};

export default useMultiacquirerHook;
