import React from 'react';
import PropTypes from 'prop-types';

export const BluCardWrapper = ({ children, className }) => (
  <div className={`blu-card-wrapper ${className}`}>{children}</div>
);

BluCardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

BluCardWrapper.defaultProps = {
  className: ''
};

export const BluCardPageTitle = ({
  type,
  href,
  title,
  withBack,
  className,
  handleBack
}) => (
  <div className={`blu-card-page-title ${className}`}>
    {withBack && type === 'button' && (
      <button type="button" onClick={handleBack} className="blu-card-back">
        <i className="ic ic-arrow-left" />
      </button>
    )}
    {withBack && type === 'link' && (
      <a className="blu-card-back" href={href}>
        <i className="ic ic-arrow-left" />
      </a>
    )}
    {title && (withBack ? <span>{title}</span> : <p>{title}</p>)}
  </div>
);

BluCardPageTitle.propTypes = {
  type: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string,
  withBack: PropTypes.bool,
  className: PropTypes.string,
  handleBack: PropTypes.func
};

BluCardPageTitle.defaultProps = {
  href: '',
  type: '',
  title: '',
  withBack: true,
  handleBack: null,
  className: ''
};

export const BluCardContainer = ({ children, className, style }) => (
  <div className={`blu-card-container ${className}`} style={style}>
    {children}
  </div>
);

BluCardContainer.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

BluCardContainer.defaultProps = {
  style: {},
  className: ''
};
