// 'SELECT_CLIENT' => Clients select list and search
// 'SELECT_PAYMENT_TYPE' => Select type of payment
// 'NEW_PAYMENT_COLLECTION' => Fills value of payment
// 'PAYMENT_COLLECTION_CONFIRMATION' => Confirmation Screen

const initialState = {
  step: 'SELECT_PAYMENT_CLIENT',
  option: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_STEP':
      return {
        ...state,
        step: action.step
      };
    case 'SET_PAYMENT_OPTION':
      return {
        ...state,
        option: action.option
      };
    default:
      return state;
  }
}
