import styled from 'styled-components';

import {
  colorInterfaceDarkDeep,
  fontFamilyBase,
  fontFamilyHighlight,
  colorStatusNegativePure
} from '@useblu/ocean-tokens/web/tokens';

const devices = {
  mobile: `(max-width: 768px)`,
  tablet: `(max-width: 1366px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-family: ${fontFamilyBase};
  color: ${colorInterfaceDarkDeep};

  font-size: 32px;
  font-weight: 900;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;

  margin: 0 0 8px;

  @media ${devices.mobile} {
    font-family: ${fontFamilyBase};
    color: ${colorInterfaceDarkDeep};

    font-size: 20px;
    font-weight: 900;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const Subtitle = styled.p`
  font-family: ${fontFamilyHighlight};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  margin: 0 0 24.5px;
`;

export const WrapInput = styled.div`
  @media ${devices.tablet} {
    margin-bottom: 24px;
  }
  @media ${devices.mobile} {
    margin-bottom: 24px;
  }

  .error-msg {
    color: ${colorStatusNegativePure};
    font-size: 12px;
  }

  .ods-input {
    &.error {
      border-color: ${colorStatusNegativePure};
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .ods-btn--primary {
    margin-left: 16px;
  }

  @media ${devices.mobile} {
    justify-content: space-between;
  }
`;
