import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { disableAutomaticAnticipation } from 'api/clients';
import { useAsyncFn } from 'react-use';
import useClientFeatureStatus from 'components/Dashboard/hooks/useClientFeatureStatus';

import { Typography } from '@useblu/ocean-react';
import {
  ButtonContainer,
  Loading,
  Button,
  AnticipationOptOutContainer
} from './AutomaticAnticipationModalOptOut.styles';

const AutomaticAnticipationModalOptOut = ({ onRequestClose }) => {
  const {
    loading,
    clientUUID,
    automaticAnticipationOptInConfirmed,
    updateStatus
  } = useClientFeatureStatus();

  const [optOutState, optOutRequest] = useAsyncFn(disableAutomaticAnticipation);

  const onSubmit = () => {
    optOutRequest(clientUUID);
  };

  useEffect(() => {
    if (!automaticAnticipationOptInConfirmed) {
      onRequestClose('AutomaticAnticipationOptOut');
    }
  }, [automaticAnticipationOptInConfirmed, onRequestClose]);

  useEffect(() => {
    if (
      !optOutState?.loading &&
      optOutState?.value?.data?.automatic_anticipation
    ) {
      updateStatus();
    }
  }, [optOutState]);

  return (
    <AnticipationOptOutContainer>
      <Typography variant="heading2">
        Deseja desativar a antecipação automática?
      </Typography>
      <Typography variant="paragraph">
        Não haverá estorno do que já foi antecipado.
      </Typography>
      <Typography variant="description">
        Caso mude de ideia no futuro, você poderá ativar a qualquer momento.
      </Typography>
      <ButtonContainer className="ocean-iso">
        <Button
          variant="secondary"
          onClick={() => onRequestClose('AutomaticAnticipationOptOut')}
          disabled={loading || optOutState.loading}
        >
          Cancelar
        </Button>

        <Button
          variant="primaryCritical"
          disabled={loading || optOutState.loading}
          onClick={onSubmit}
        >
          {loading || optOutState.loading ? (
            <Loading data-testid="aa-optout-loading" />
          ) : (
            'Sim, desativar'
          )}
        </Button>
      </ButtonContainer>
    </AnticipationOptOutContainer>
  );
};

AutomaticAnticipationModalOptOut.propTypes = {
  onRequestClose: PropTypes.func.isRequired
};

export default AutomaticAnticipationModalOptOut;
