import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Typography, Badge } from '@useblu/ocean-react';
import { useFetch } from 'utils-swr';
import { railsContextSelector } from 'selectors/rails-context';
import { useSelector } from 'react-redux';

import api from 'utils-swr/fetch';
import Ilustra from './images/ilustra.svg';

import * as S from './styles';

function ModalNewsPagblu({ modalProps }) {
  const { clientId } = useSelector(railsContextSelector);

  const { data: response } = useFetch(
    `/visual_interface/${clientId}/tag/cob_modal_new_pagblu_automatic_centralization`,
    1,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const { tag, title, subTitle, orderedList, button } = response?.data || {};

  const isOpen = useMemo(
    () => modalProps.isOpen && response?.show,
    [response?.show, modalProps]
  );

  function handle() {

    if (!button?.link?.webUrl) {
      modalProps?.onRequestClose();
      return;
    }

    window.location.href = button?.link?.webUrl;
  }

  useEffect(() => {
    if (isOpen) {
      api.patch(
        `visual_interface/${clientId}/tag/cob_modal_new_pagblu_automatic_centralization`
      );
    }
  }, [isOpen, clientId]);

  return (
    <Modal
      portalClassName="ocean-iso modal-mobile-full-height modal-mobile-adjust-padding "
      maxWidth="sm"
      onRequestClose={modalProps?.onRequestClose}
      isOpen={isOpen}
    >
      <S.Wrapper>
        <img src={Ilustra} alt="Ilustração" />
        {tag && <Badge>{tag.label}</Badge>}
        <S.Title>{title}</S.Title>
        <S.Description>{subTitle}</S.Description>
        <ul>
          {orderedList?.map((item, index) => (
            <li>
              <S.Icon>{index + 1}</S.Icon>
              <div>
                <Typography variant="description">{item}</Typography>
              </div>
            </li>
          ))}
        </ul>
        <div className="actions">
          <Button onClick={() => handle()}>{button?.label}</Button>
        </div>
      </S.Wrapper>
    </Modal>
  );
}

ModalNewsPagblu.propTypes = {
  modalProps: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
  }).isRequired,
};

export default ModalNewsPagblu;
