import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AccordionContainer } from './styles';

import AccordionSummary from './AccordionSummary';
import AccordionDescription from './AccordionDescription';

export const Accordion = ({ title, description }) => {
  const [accordionIsOpen, setAccordionIsOpen] = useState(false);

  return (
    <AccordionContainer>
      <AccordionSummary
        title={title}
        open={accordionIsOpen}
        toggleAccordion={setAccordionIsOpen}
      />
      <AccordionDescription content={description} open={accordionIsOpen} />
    </AccordionContainer>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired
};

export default Accordion;
