import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import clients from './clients';
import clientSearch from './client-search';
import clientsHistory from './clients-history';
import contractForm from './contract-form';
import paymentCollectionApp from './payment-collection-app';

export {
  clients,
  clientSearch,
  clientsHistory,
  contractForm,
  paymentCollectionApp,
};

export default combineReducers({
  clients,
  clientSearch,
  clientsHistory,
  contractForm,
  paymentCollectionApp,
  form: formReducer,
  railsContext: (state = {}) => state,
});
