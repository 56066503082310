import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Option from './Option';

// ATTENTION:
// This is a self contained component, it should be replaced by the dropdown
// from ocean asap.

const OptionsStyled = styled.div`
  padding: 8px 0px;
`;

const Options = ({ groups, showGroupName, itemStyle }) => (
  <OptionsStyled>
    {groups.map((group) => (
      <nav key={group.groupName}>
        {showGroupName && <small>{group.groupName}</small>}
        <ul>
          {group.items.map((item) => (
            <Option
              key={item.id}
              id={item.id}
              style={itemStyle}
              onClick={item.onClick}
              href={item.link}
              target={item.target}
              link={item.link}
              isNew={item.isNew}
              label={item.label}
              method={item.method}
              className={item.className}
            />
          ))}
        </ul>
      </nav>
    ))}
  </OptionsStyled>
);

Options.propTypes = {
  showGroupName: PropTypes.bool,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      groupName: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          isNew: PropTypes.bool,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
          link: PropTypes.string,
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          onClick: PropTypes.func
        })
      )
    })
  ),
  itemStyle: PropTypes.object
};

Options.defaultProps = {
  showGroupName: true,
  groups: [],
  itemStyle: null
};

export default Options;
