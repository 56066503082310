import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Loader = ({ type, size }) => {
  const loaderClass = classNames({
    loader__component: true,
    [type]: type,
    [size]: size
  });

  return (
    <div className={loaderClass}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

Loader.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'big', 'extra-big'])
};

Loader.defaultProps = {
  type: 'default',
  size: 'medium'
};

export default Loader;
