import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { addFormSubmitSagaTo } from 'redux-form-submit-saga';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import history from 'utils/browserHistory';
import rootSaga from './sagas';
import rootReducer from './rootReducer';

const composeEnhancers = composeWithDevTools({});

export default (preloadedState) => {
  const middleware = [];
  const enhancers = [];

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
      'newTransfer',
      'transfer',
      'transferApp',
      'clients',
      'billetCIP',
      'paymentConfirmation',
      'importPaymentConfirmation',
    ],
  };

  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  /* React Router */
  const routeMiddleware = routerMiddleware(history);
  middleware.push(routeMiddleware);

  /* Thunk */
  middleware.push(thunk);
  enhancers.push(applyMiddleware(...middleware));

  /* Redux Form Saga */
  const wrappedRootSaga = addFormSubmitSagaTo(rootSaga);

  const persistedReducer = persistReducer(persistConfig, rootReducer(history));

  /* Store */
  const store = createStore(
    persistedReducer,
    preloadedState,
    composeEnhancers(...enhancers)
  );

  const persistor = persistStore(store);

  /* Run Saga */
  sagaMiddleware.run(wrappedRootSaga);

  return { store, persistor, history };
};
