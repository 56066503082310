import React from 'react';
import { useSelector } from 'react-redux';

import { normalizeValue } from 'normalizers';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import Header from 'components/PaymentCollectionDetails/containers/Header';
import MainActions from 'components/PaymentCollectionDetails/containers/MainActions';
import ReadOnlyField from 'components/PaymentCollectionDetails/components/ReadOnlyField';
import ReadOnlyFieldCollapse from 'components/Shared/ReadOnlyFieldCollapse';
import Dates from 'components/PaymentCollectionDetails/containers/Dates';
import ListContainer from 'components/PaymentCollectionDetails/components/ListContainer';
import ListItem from 'components/PaymentCollectionDetails/components/ListItem';
import RefundPaymentCollectionHistory from 'components/RefundPaymentCollection/History';
import InstallmentsAndCosts from 'components/PaymentCollectionDetails/containers/InstallmentsAndCosts';
import PartialPaymentInfo from 'components/PaymentCollectionDetails/containers/PartialPaymentInfo';
import Costs from './components/Costs';
import Installments from './containers/Installments';

const ChargedScreen = () => {
  const data = useSelector(selectors.getDetailsData);

  const isBankBillet = data?.kind?.name === 'payment_collection_bank_billet';

  return (
    <div className="flex-container">
      <Header isCharger={false} />

      <div className="statement-detail__content container is-padded">
        <MainActions />

        <br />

        {data.economicGroup && (
          <ReadOnlyField
            label="Aprovado pelo grupo economico"
            value={`${data.economicGroup.name} - ${data.economicGroup.legalRepresentativeEmail}`}
          />
        )}

        {Boolean(data.valueToPartialPay) && (
          <ReadOnlyField
            label={`Valor parcial líquido disponível${
              data.economicGroup ? ' no grupo econômico' : ''
            }`}
            value={normalizeValue(data.valueToPartialPay)}
          />
        )}

        {data.hasPartialPayment && <PartialPaymentInfo />}

        {data.reference && (
          <ReadOnlyField label="Código de referência" value={data.reference} />
        )}

        <ReadOnlyField
          label={isBankBillet ? 'Identificador' : 'Identificador da Cobrança'}
          value={data.id}
        />

        <ReadOnlyFieldCollapse
          label="Cobrador"
          value={data.charger.name}
          openLabel="ver detalhes"
          fields={[
            { label: 'Nome fantasia', value: data.charger.businessName },
            { label: 'CNPJ/CPF', value: data.charger.cpfCnpj }
          ]}
        />

        {data.showSelectedPaymentOption && (
          <ReadOnlyField
            label="Tipo do pagamento"
            value={data.kind.description}
          />
        )}

        <Dates />

        {data.discount && (
          <ListContainer title="Descontos">
            <ListItem
              title={data.discount.title}
              value={normalizeValue(data.discount.value)}
            />
          </ListContainer>
        )}

        {data.bonuses && (
          <ListContainer title="Bônus">
            {data.bonuses.map((bonus) => (
              <ListItem
                key={bonus.title}
                title={bonus.title}
                value={normalizeValue(bonus.value)}
              />
            ))}
          </ListContainer>
        )}

        <InstallmentsAndCosts
          InstallmentsElementType={Installments}
          CostsElementType={Costs}
        />

        {data.refunds && (
          <RefundPaymentCollectionHistory
            refunds={data.refunds}
            showAccountStatementLink={data.showRefundAccountStatementLink}
            isCharger={false}
          />
        )}
      </div>
    </div>
  );
};

export default ChargedScreen;
