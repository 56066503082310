import React from 'react';
import PropTypes from 'prop-types';

import { ExclamationCircleOutline } from '@useblu/ocean-icons-react';
import Actions from './Actions';

import {
  Title,
  ModalFooterContainer,
  FooterText,
  ModalContent,
  TitlePage,
  Description,
  ScrollMobile,
  StoreSelector,
  InfoContainer
} from '../modal.styles';

const Checkout = ({
  product,
  taxPlan,
  nextStep,
  prevStep,
  loading,
  header,
  nextText,
  allCnpjs,
  info,
  nextClass,
  prevClass,
  amount,
  customFields
}) => (
  <>
    <ScrollMobile>
      <TitlePage>{header}</TitlePage>
      <Title>Detalhes da contratação</Title>

      {info && (
        <InfoContainer>
          <ExclamationCircleOutline size={25} stroke="#FF8A14" />
          <p>{info}</p>
        </InfoContainer>
      )}

      <ModalContent isCheckout>
        <Description>
          <h5>Produto</h5>
          <p>{product}</p>

          {taxPlan && (
            <>
              <h5>Taxa de Antecipação Automática</h5>
              <p>{taxPlan}</p>
            </>
          )}

          {amount && (
            <>
              <h5>Quantidade de maquininhas solicitadas</h5>
              <p>{amount}</p>
            </>
          )}

          <StoreSelector>
            <h5>Loja</h5>
            <p>{allCnpjs ? 'Todas as lojas' : 'Esta loja'}</p>

            {customFields}
          </StoreSelector>
        </Description>
      </ModalContent>
    </ScrollMobile>
    <ModalFooterContainer>
      <FooterText className="desktop" />
      <Actions
        prevStep={prevStep}
        hasPrev
        nextStep={nextStep}
        nextClass={nextClass}
        prevClass={prevClass}
        nextText={nextText}
        loading={loading}
      />
    </ModalFooterContainer>
  </>
);

Checkout.propTypes = {
  nextStep: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired,
  amount: PropTypes.number,
  prevStep: PropTypes.func,
  loading: PropTypes.bool,
  header: PropTypes.object,
  customFields: PropTypes.element,
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]),
  nextText: PropTypes.string,
  taxPlan: PropTypes.string,
  allCnpjs: PropTypes.bool,
  nextClass: PropTypes.string,
  prevClass: PropTypes.string
};

Checkout.defaultProps = {
  loading: false,
  amount: null,
  prevStep: () => false,
  allCnpjs: false,
  customFields: undefined,
  nextText: 'Confirmar',
  header: 'Resumo',
  taxPlan: undefined,
  info: undefined,
  nextClass: '',
  prevClass: ''
};

export default Checkout;
