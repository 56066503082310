import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import actions from 'store/ducks/rpaRegisterForm/actions';
import ModalCompleteYourData from 'components/RPARegisterForm/components/ModalCompleteYourData';
import {
  rpaRegisterFormRequestErrorSelector,
  rpaRegisterFormRequestLoadingSelector
} from 'store/ducks/rpaRegisterForm/selectors';
import RPARegisterFormScreen from './RPARegisterForm';
import ModalGenericError from './components/ModalGenericError';
import { GlobalInputsStyle } from './RPARegisterForm.styles';
import { RPARegisterFormProvider } from './context';

const env = window?.railsState?.railsContext?.env;
const isDevEnvironment = env === 'development' || env === 'staging';

// eslint-disable-next-line jest/require-hook
ReactGA.initialize('UA-142072024-6', {
  titleCase: false,
  debug: isDevEnvironment,
  testMode: process.env.NODE_ENV === 'test' || isDevEnvironment
});

// currentClientId comming from view rpa_register and rep_register_controller.rb
const RPARegisterForm = ({ currentClientId }) => {
  const dispatch = useDispatch();
  const [isVisible, setVisible] = useState(true);
  const [isErrorVisible, setErrorVisible] = useState(false);

  const error = useSelector(rpaRegisterFormRequestErrorSelector);
  const loading = useSelector(rpaRegisterFormRequestLoadingSelector);

  const not422 = !!error && error?.statusCode !== 422;

  useEffect(() => {
    setErrorVisible(not422);
  }, [error]);

  useEffect(() => {
    if (isErrorVisible) {
      setVisible(false);
    }
  }, [isErrorVisible]);

  useEffect(() => {
    dispatch(actions.fetchRpaRegisterDataRequest());
  }, [dispatch]);

  return (
    <RPARegisterFormProvider value={{ currentClientId }}>
      <div className="ocean-iso">
        <ModalCompleteYourData
          isVisible={isVisible}
          toogleModal={setVisible}
          error={Boolean(error)}
          loading={loading}
        />
        <ModalGenericError
          isVisible={isErrorVisible}
          toogleModal={setErrorVisible}
        />
        <RPARegisterFormScreen />
      </div>
      <GlobalInputsStyle />
    </RPARegisterFormProvider>
  );
};

export default RPARegisterForm;

RPARegisterForm.propTypes = {
  currentClientId: PropTypes.string.isRequired
};
