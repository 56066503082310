import React from 'react';
import PropTypes from 'prop-types';
import { TopBar, Button, Progress } from '@useblu/ocean-react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';

import { BrowserView, MobileView } from 'react-device-detect';
import { Formik, Form, ErrorMessage } from 'formik';

import {
  loadFormData,
  userCadastralUpdate,
  generateContract,
  showClientStep
} from 'actions/contract-form';

import { XOutline } from '@useblu/ocean-icons-react';
import PageTitle from '../components/PageTitle';
import EmailConfirmationModal from '../components/EmailConfirmationModal';

import {
  Container,
  Wrapper,
  InputWrapper,
  CustomInput,
  ErrorText,
  ButtonWrapper,
  Bar,
  AlertWrapper
} from './styles';
import useFormUserInfor from './useFormUserInfor';

const UserInfoConfirmation = ({
  user,
  loadFormData,
  userCadastralUpdate,
  showClientStep,
  showUserStep,
  generateContract,
  registrationStep
}) => {
  const {
    formikProps,
    formData,
    setFormData,
    hasChangeValidation,
    enableChangeValidation,
    showPhoneInput,
    setShowPhoneInput,
    visible,
    setVisible,
    setChangeEmail,
    changeEmail,
    loading,
    errorMessage
  } = useFormUserInfor({
    user,
    loadFormData,
    userCadastralUpdate,
    showClientStep,
    generateContract
  });

  const formValidationErrors = {
    email: (
      <AlertWrapper type="error">
        Seu novo e-mail não é válido. Confira o e-mail digitado e tente
        novamente.
      </AlertWrapper>
    ),
    phone: (
      <AlertWrapper type="error">
        O novo número de celular inserido já está sendo utilizado em outra
        conta.
      </AlertWrapper>
    )
  };

  const userStatus =
    registrationStep === 'pending_basic_data_confirmation' ||
    registrationStep === 'not_an_automatic_registration_client' ||
    registrationStep === 'pending_basic_data_confirmation_without_qsa';

  return (
    showUserStep &&
    userStatus && (
      <Container className="ocean-iso">
        <Bar>
          <TopBar
            id="TopBar-playground"
            variants="default"
            leftIcon={<XOutline />}
            title="Confirmação de dados"
            onLeftAction={() => {
              window.location.href = `/`;
            }}
          />
        </Bar>
        <div className="contract-form ocean-iso">
          <Wrapper>
            <>
              <PageTitle title="Primeiro, confirme seus dados pessoais" />

              {(errorMessage?.phone_2 || errorMessage?.phone_3) &&
                formValidationErrors.phone}
              {errorMessage?.email && formValidationErrors.email}

              {!loading ? (
                <Formik
                  {...formikProps}
                  validateOnChange={hasChangeValidation}
                  validateOnBlur={false}
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleSubmit,
                    handleChange
                  }) => (
                    <Form
                      onSubmit={(e) => {
                        enableChangeValidation(true);
                        handleSubmit(e);
                      }}
                    >
                      <EmailConfirmationModal
                        isVisible={visible}
                        email={changeEmail || ''}
                        toggleModal={setVisible}
                        handleSubmit={handleSubmit}
                        formData={formData}
                        setFormData={setFormData}
                        values={values}
                      />
                      <InputWrapper>
                        <CustomInput
                          id="name"
                          name="name"
                          label="Nome"
                          value={values.name || ''}
                          placeholder="Insira seu nome completo"
                          disabled
                        />
                        <ErrorMessage
                          type="name"
                          name="name"
                          render={(msg) => (
                            <ErrorText className="error-msg">{msg}</ErrorText>
                          )}
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <CustomInput
                          id="cpf"
                          data-testid="cpf"
                          name="cpf"
                          label="CPF"
                          placeholder="Insira seu CPF"
                          value={values.cpf || ''}
                          disabled
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <CustomInput
                          id="email"
                          name="email"
                          label="E-mail"
                          placeholder="Insira seu E-mail"
                          value={values.email || ''}
                          onChange={(e, event) => {
                            handleChange({
                              ...event,
                              target: { name: 'email', value: e.target.value }
                            });
                          }}
                          onKeyUp={(e) => {
                            setChangeEmail(e.target.value);
                          }}
                          className={
                            (errors.email && touched.email) ||
                            errorMessage?.email
                              ? 'error'
                              : ''
                          }
                        />

                        <ErrorMessage
                          type="email"
                          name="email"
                          render={(msg) => (
                            <ErrorText className="error-msg">{msg}</ErrorText>
                          )}
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <CustomInput
                          id="birthday"
                          name="birthday"
                          label="Data de Nascimento"
                          placeholder="Insira sua data de nascimento"
                          value={values.birthday || ''}
                          mask="date"
                          className={
                            errors.birthday && touched.birthday ? 'error' : ''
                          }
                        />
                        <ErrorMessage
                          name="birthday"
                          render={(msg) => (
                            <ErrorText className="error-msg">{msg}</ErrorText>
                          )}
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <CustomInput
                          id="phone"
                          name="phone"
                          mask="phone"
                          label="Celular com DDD"
                          placeholder="Insira um número de celular"
                          value={values.phone || ''}
                          disabled
                        />
                      </InputWrapper>

                      <InputWrapper>
                        <CustomInput
                          id="second-phone"
                          name="phone_2"
                          mask="phone"
                          label="Celular 2 com DDD"
                          placeholder="Insira um número de celular"
                          value={values.phone_2 || ''}
                          className={
                            (errors.phone_2 && touched.phone_2) ||
                            errorMessage?.phone_2
                              ? 'error'
                              : ''
                          }
                        />

                        <ErrorMessage
                          name="phone_2"
                          render={(msg) => (
                            <ErrorText className="error-msg">{msg}</ErrorText>
                          )}
                        />
                      </InputWrapper>

                      {showPhoneInput ? (
                        <InputWrapper>
                          <CustomInput
                            id="third-phone"
                            name="phone_3"
                            mask="phone"
                            label="Celular 3 com DDD"
                            placeholder="Insira um número de celular"
                            value={values.phone_3 || ''}
                            className={
                              (errors.phone_3 && touched.phone_3) ||
                              errorMessage?.phone_3
                                ? 'error'
                                : ''
                            }
                          />
                          <ErrorMessage
                            name="phone_3"
                            render={(msg) => (
                              <ErrorText className="error-msg">{msg}</ErrorText>
                            )}
                          />
                        </InputWrapper>
                      ) : (
                        <ButtonWrapper>
                          <Button
                            size="sm"
                            variant="secondary"
                            className="atv_etapa_1_dadospessoais_botaoaddtelefone"
                            blocked
                            onClick={() => setShowPhoneInput(true)}
                          >
                            Adicionar novo telefone
                          </Button>
                        </ButtonWrapper>
                      )}

                      <ButtonWrapper submit>
                        <BrowserView>
                          <Button
                            type="submit"
                            className="atv_etapa_1_dadospessoais_botaoconfirmar"
                          >
                            Continuar
                          </Button>
                        </BrowserView>

                        <MobileView>
                          <Button type="submit" bloked="true">
                            Continuar
                          </Button>
                        </MobileView>
                      </ButtonWrapper>
                    </Form>
                  )}
                </Formik>
              ) : (
                <div className="ocean-iso loading">
                  <Progress size="md" />
                </div>
              )}
            </>
          </Wrapper>
        </div>
      </Container>
    )
  );
};

UserInfoConfirmation.propTypes = {
  user: PropTypes.object,
  registrationStep: PropTypes.string,
  loadFormData: PropTypes.func.isRequired,
  userCadastralUpdate: PropTypes.func.isRequired,
  generateContract: PropTypes.func.isRequired,
  showClientStep: PropTypes.func.isRequired,
  showUserStep: PropTypes.bool.isRequired
};

UserInfoConfirmation.defaultProps = {
  user: {
    name: '',
    cpf: '',
    email: '',
    birthday: '',
    phone: '',
    phone_2: '',
    phone_3: ''
  },
  registrationStep: ''
};

const mapStateToProps = (state) => ({
  user: state.contractForm.user,
  registrationStep: state.contractForm.registration_step,
  loading: state.contractForm.loading,
  showUserStep: state.contractForm.showUserStep
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadFormData,
      generateContract,
      userCadastralUpdate,
      showClientStep
    },
    dispatch
  );

const UserStepReduxForm = reduxForm({
  form: 'UserInfoConfirmation'
})(UserInfoConfirmation);

export default connect(mapStateToProps, mapDispatchToProps)(UserStepReduxForm);
