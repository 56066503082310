import styled from 'styled-components';

import {
  fontFamilyBase,
  colorInterfaceDarkDeep,
  colorInterfaceLightDown,
} from '@useblu/ocean-tokens/web/tokens';

import { Devices } from 'utils/getDeviceMedia';

export const ModalContainer = styled.div`
  height: 484px;
`;

export const ChatGreetingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  width: 736px;
  padding: 0 8px;

  @media ${Devices.tablet}, ${Devices.mobile} {
    flex-direction: column;
    width: auto;
    padding: 0 16px;
  }
`;

export const GreetingContainer = styled.div`
  margin-right: 24px;

  h2 {
    font-family: ${fontFamilyBase};
    line-height: 35px;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    margin-bottom: 8px;
  }
  h1 {
    color: ${colorInterfaceDarkDeep};
    font-family: ${fontFamilyBase};
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 35px;
  }

  @media ${Devices.tablet}, ${Devices.mobile} {
    margin-right: 0;
    width: 100%;
  }
`;

export const ChatContainer = styled.div`
  margin-left: 24px;

  @media ${Devices.tablet}, ${Devices.mobile} {
    width: 100%;
    height: 100%;
    margin: 0px;
  }
`;

export const VerticalLine = styled.div`
  border-left: 1px solid ${colorInterfaceLightDown};
  height: 100%;
`;

export const ModalDesktopFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 56px;
  @media ${Devices.tablet}, ${Devices.mobile} {
    justify-content: center;
  }
`;
