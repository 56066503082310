import React from 'react';

import {
  SellMore,
  Separation,
  SellMoreVideoContainer,
  CustomReactPlayer,
  SellMoreDescription
} from './styles';

const SellMoreVideo = () => (
  <SellMoreVideoContainer>
    <SellMoreDescription>
      <div>
        <h1>Muito fácil de usar!</h1>
        <p>
          Além de você, todos os vendedore da sua loja podem acessar e cadastrar
          os clientes no aplicativo. A decisão é sua!
        </p>
      </div>
    </SellMoreDescription>
    <Separation />
    <SellMore>
      <div>
        <CustomReactPlayer url="https://youtu.be/GHU2-wHCqWw" />
      </div>
    </SellMore>
  </SellMoreVideoContainer>
);

export default SellMoreVideo;
