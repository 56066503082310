import styled from 'styled-components';
import { Typography } from '@useblu/ocean-react';

import {
  spacingInlineXs,
  colorBrandPrimaryPure,
  colorInterfaceDarkDeep,
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 136px 1fr;
  gap: 24px;

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;

    img {
      margin: 0 auto;
    }

    h2,
    p {
      text-align: center;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  @media screen and (max-width: 576px) {
    a:first-child {
      display: none;
    }

    a,
    button {
      width: 100%;
    }
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'heading2',
})`
  &&& {
    color: ${colorBrandPrimaryPure};
    margin-bottom: ${spacingInlineXs};

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }
`;

export const Description = styled(Typography).attrs({
  variant: 'description',
})`
  &&& {
    color: ${colorInterfaceDarkDeep};
  }
`;
