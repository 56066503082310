import { call, put, select } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/firstAccess/qsaAccounts';
import PermissionActions from 'store/ducks/firstAccess/permission';
import {
  getAssociates,
  saveQsaPartner,
  updateQsaPartner,
  updateQsaProfitParticipations,
  deleteQsaPartner
} from 'api/first-access';
import _ from 'lodash';
import history from 'utils/browserHistory';
import { clientSelector } from 'selectors/FirstAccess/shared';

export function* loadQsaAccounts(client) {
  const response = yield call(getAssociates, client);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(
      ActionCreators.firstAccessQsaAccountsSuccess(data.data.associates)
    );
    yield put(PermissionActions.permissionCheck());
  } else {
    yield put(ActionCreators.firstAccessQsaAccountsFailure());
  }
}

export function* saveQsaProfitParticipations({ companyCnpj, qsa, uuid }) {
  const client = yield select((state) => clientSelector(state));

  const adjustedQsa = qsa.map((partner) => {
    let profitParticipation = partner.companyAssociates[0].profitParticipation;

    if (profitParticipation && typeof profitParticipation === 'string') {
      profitParticipation = parseFloat(
        profitParticipation.replace('.', '').replace(',', '.')
      );
    }

    return {
      ...partner,
      companyAssociates: [
        {
          ...partner.companyAssociates[0],
          profitParticipation
        }
      ]
    };
  });

  yield call(updateQsaProfitParticipations, companyCnpj, adjustedQsa);

  const route = client.fullFirstAccess
    ? 'autorizacoes-dos-socios'
    : 'assinatura-de-contrato';

  history.push(`/lojas/${uuid}/primeiro-acesso/${route}`);
}

export function* qsaSavePartnerRequest(params) {
  const { partner } = params;

  let response = null;

  if (partner.companyAssociates && partner.companyAssociates[0].id) {
    response = yield call(updateQsaPartner, params);
  } else {
    response = yield call(saveQsaPartner, params);
  }

  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(
      ActionCreators.firstAccessQsaAccountsSavePartnerSuccess(
        data.data.createAssociate || data.data.updateAssociate
      )
    );
  } else {
    const errors = {};

    if (_.get(data, 'errors[0].problems[0].path[0]', '') === 'cpfCnpj') {
      errors.cpfCnpj = _.get(data, 'errors[0].problems[0].explanation', '');
    }

    yield put(ActionCreators.firstAccessQsaAccountsSavePartnerFailure(errors));
  }
}

export function* qsaDeletePartnerRequest({ cpfCnpj, companyCnpj }) {
  const response = yield call(deleteQsaPartner, cpfCnpj, companyCnpj);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(
      ActionCreators.firstAccessQsaAccountsDeletePartnerSuccess(
        data.data.destroyAssociate
      )
    );
  }
}
