import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReCaptchaV2 from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import JSEncrypt from 'jsencrypt';

import { useAsyncFn } from 'react-use';
import { Formik, ErrorMessage } from 'formik';
import { Alert, Link, Typography } from '@useblu/ocean-react';
import * as yup from 'yup';
import Loading from 'react-loading';
import searchUTM from 'hooks/searchUTM';

import parseErrorResponse from '../helpers/parseErrorResponse';
import { signIn } from '../services/session';
import PasswordInput from './PasswordInput';

import UnlockSteps from './UnlockSteps';
import { useAuthenticationContext } from '../context/authentication.context';

import {
  Title,
  Subtitle,
  CustomInput,
  CustomForm,
  WrapInput,
  WrapSubActions,
  WrapActions,
  BtnSubmit,
  AlertWrapper,
  WrapRecaptcha,
  WrapInfos,
} from '../devise.styles';

const SignInForm = ({
  secretKey,
  autoSignUpDisabled,
  recaptchaKeyV2,
  antiFraudAlertEnabled,
}) => {
  const { setRenderTwoFactorComponent, setSignInData } =
    useAuthenticationContext();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [defaultError, setDefaultError] = useState(false);
  const [hasChangeValidation, enableChangeValidation] = useState(false);
  const [isAccessBlocked, setAccessBlocked] = useState(false);
  const [clientEmail, setClientEmail] = useState('');
  const [isMaximumAttemptsMatched, setMaximumAttemptsMatched] = useState(false);

  const [recaptchaVersion, setRecaptchaVersion] = useState('v3');
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState();
  const [recaptchaTokenV3, setRecaptchaTokenV3] = useState();
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [utmParamsQuery, setUTMParamsQuery] = useState('');

  const [signInState, signInRequest] = useAsyncFn(signIn);

  const formik = {
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values, { validateForm, resetForm }) => {
      setClientEmail(values?.email);
      enableChangeValidation(true);
      setRecaptchaError(false);

      if (!validateForm()) return;

      if (recaptchaVersion === 'v2' && !recaptchaTokenV2) {
        setRecaptchaError(true);
        return;
      }
      const encrypt = new JSEncrypt();

      const secret = atob(secretKey);
      encrypt.setPublicKey(secret);

      const toHash = {
        password: values.password,
        username: values.email.trim().toLowerCase(),
      };

      const user = {
        secret: encrypt.encrypt(JSON.stringify(toHash)),
        recaptcha_token:
          recaptchaVersion === 'v2' ? recaptchaTokenV2 : recaptchaTokenV3,
        recaptcha_version: recaptchaVersion,
      };

      signInRequest(user);

      resetForm({
        values: {
          email: '',
          password: '',
        },
      });
    },
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .transform((value) => value.trim())
      .email('Este e-mail não é válido')
      .required('Este campo é obrigatório'),
    password: yup.string().required('Este campo é obrigatório'),
  });

  const handleRecaptchaV2 = (token) => {
    setRecaptchaTokenV2(token);
  };

  const handleRecaptchaV3 = useCallback(async () => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha('signIn');

    setRecaptchaTokenV3(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    const recaptchaLifeTime = 90 * 1000; // 1 min and 30 seconds

    handleRecaptchaV3();
    const interval = setInterval(() => {
      handleRecaptchaV3();
    }, [recaptchaLifeTime]);

    return () => clearInterval(interval);
  }, [handleRecaptchaV3]);

  useEffect(() => {
    if (signInState.loading) return;

    if (signInState.error) {
      const { accessBlocked, recaptchaV2, maximumAttemptsMatched } =
        parseErrorResponse(signInState.error.request.response);

      setDefaultError(true);
      setAccessBlocked(accessBlocked);
      setMaximumAttemptsMatched(maximumAttemptsMatched);

      if (recaptchaV2) {
        if (window.grecaptcha && recaptchaVersion === 'v2') {
          window.grecaptcha.reset();
          setRecaptchaTokenV2('');
        }

        setRecaptchaVersion('v2');

        return;
      }

      handleRecaptchaV3();
    }

    if (
      signInState &&
      signInState.value &&
      signInState.value.data &&
      signInState.value.data.message === 'success'
    ) {
      localStorage.setItem('logged', true);
      if (signInState.value.data.two_factor) {
        setSignInData(signInState.value.data);
        setRenderTwoFactorComponent(true);
      } else {
        window.location.assign(signInState.value?.data?.path || '/');
      }
    }
  }, [signInState]);

  useEffect(() => {
    const params = searchUTM();

    setUTMParamsQuery(params);
  }, []);

  const handleAlert = () => {
    if (defaultError) {
      return (
        <AlertWrapper>
          <Alert type="error">
            Email ou senha incorretos. Tente novamente ou{' '}
            <Link href="/users/password/new" size="sm">
              redefina sua senha
            </Link>
          </Alert>
        </AlertWrapper>
      );
    }

    if (antiFraudAlertEnabled) {
      return (
        <AlertWrapper>
          <Alert
            type="warning"
            title="A Blu não solicita senhas ou códigos por nenhum canal de comunicação"
          >
            <Typography variant="description">
              Evite entrar na sua Conta Digital Blu por buscadores. Acesse{' '}
              <u>portal.useblu.com.br</u>
            </Typography>
          </Alert>
        </AlertWrapper>
      );
    }

    return <></>;
  };

  return isAccessBlocked ? (
    <UnlockSteps
      clientEmail={clientEmail}
      isMaximumAttemptsMatched={isMaximumAttemptsMatched}
    />
  ) : (
    <>
      <Title>Bem-vindo(a) à Blu</Title>
      <Subtitle>Acesse a sua Conta Digital Blu:</Subtitle>

      {handleAlert()}

      <Formik
        initialValues={formik.initialValues}
        validateOnChange={hasChangeValidation}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={formik.onSubmit}
      >
        {({ errors, touched, values, handleSubmit, handleChange }) => (
          <CustomForm
            onSubmit={(e) => {
              enableChangeValidation(true);
              handleSubmit(e);
            }}
          >
            <WrapInput>
              <CustomInput
                id="user_login"
                name="email"
                label="E-mail"
                data-testid="email"
                placeholder="Informe seu e-mail"
                value={values.email}
                className={errors.email && touched.email ? 'error' : ''}
                tabIndex={-1}
              />

              <ErrorMessage
                name="email"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </WrapInput>

            <WrapInput>
              <PasswordInput
                id="user_password"
                data-testid="password"
                name="password"
                label="Senha"
                onChange={handleChange}
                placeholder="Informe sua senha"
                className={errors.password && touched.password ? 'error' : ''}
                value={values.password}
              />

              <ErrorMessage
                name="password"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </WrapInput>

            <WrapSubActions>
              <Link href="/users/password/new">Esqueci minha senha</Link>
              <Link href="/users/password/new?type=first-access">
                Primeiro acesso
              </Link>
            </WrapSubActions>

            {recaptchaVersion === 'v2' ? (
              <WrapRecaptcha data-testid="recaptchaV2">
                <div>
                  <ReCaptchaV2
                    sitekey={recaptchaKeyV2}
                    onChange={handleRecaptchaV2}
                  />
                  {recaptchaError ? (
                    <span className="error-msg">
                      Complete essa etapa para continuar.
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </WrapRecaptcha>
            ) : (
              <></>
            )}

            <WrapActions>
              <BtnSubmit
                className="login-btn"
                type="submit"
                id="user_submit"
                tabIndex={-1}
              >
                {signInState.loading ? (
                  <Loading
                    type="spinningBubbles"
                    color="#e1e5ed"
                    delay={0}
                    height={35}
                    width={35}
                  />
                ) : (
                  'Entrar'
                )}
              </BtnSubmit>
            </WrapActions>
          </CustomForm>
        )}
      </Formik>
      {!autoSignUpDisabled && (
        <WrapActions>
          <BtnSubmit
            onClick={() => {
              window.location.assign(
                `/novo-cadastro?autocadastro=seletor-de-negocio&${utmParamsQuery}`
              );
            }}
            variant="secondary"
            className="create-account-btn"
          >
            Criar conta Blu
          </BtnSubmit>
        </WrapActions>
      )}
      <WrapInfos>
        <Typography variant="description">Protegido por reCAPTCHA</Typography>
        <Typography variant="description">
          <a href="https://economize.blu.com.br/politica-de-privacidade">
            Privacidade
          </a>{' '}
          e{' '}
          <a href="https://portal.useblu.com.br/contrato-prestacao-de-servicos.pdf">
            Termos e serviço
          </a>
        </Typography>
      </WrapInfos>
    </>
  );
};

SignInForm.propTypes = {
  secretKey: PropTypes.string,
  recaptchaKeyV2: PropTypes.string,
  autoSignUpDisabled: PropTypes.bool,
  antiFraudAlertEnabled: PropTypes.bool,
};

SignInForm.defaultProps = {
  secretKey: '',
  recaptchaKeyV2: '',
  autoSignUpDisabled: false,
  antiFraudAlertEnabled: false,
};

export default SignInForm;
