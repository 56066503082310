import styled from 'styled-components';
import { Button } from '@useblu/ocean-react';

import { fontSizeXs, fontSizeSm } from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  @media screen and (max-width: 768px) {
    overflow: hidden;
    padding-bottom: 56px;
  }
`;

export const CustomButton = styled(Button)`
  &&& {
    margin-top: 16px;
    font-size: ${fontSizeXs};

    @media screen and (min-width: 769px) {
      font-size: ${fontSizeSm};
    }
  }
`;

export default Container;
