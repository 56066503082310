import { Button, Typography } from '@useblu/ocean-react';
import {
  colorBrandPrimaryPure,
  spacingStackXs,
  spacingInsetLg
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

export const SuccessContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-top: 25px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SuccessContent = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: ${spacingInsetLg};
  }
`;

export const CustomH3 = styled(Typography).attrs(() => ({
  variant: 'heading3'
}))`
  &&& {
    color: ${colorBrandPrimaryPure};
    margin-bottom: 16px;
  }
`;

export const CustomTypography = styled(Typography).attrs(() => ({
  variant: 'paragraph'
}))`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    text-align: center;
    & strong {
      margin-bottom: ${spacingStackXs};
    }
  }
`;

export const CustomButton = styled(Button)`
  &&& {
    margin-top: ${spacingInsetLg};
  }
`;
