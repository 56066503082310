import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types, Creators } = createActions({
  bankOptionsRequest: null,
  bankOptionsSuccess: ['data'],
  bankOptionsFailure: null
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  items: [],
  loading: false,
  error: false
};

/* Reducers */

export const request = (state) => ({
  ...state,
  loading: true
});

export const success = (state, action) => ({
  ...state,
  items: action.data,
  loading: false,
  error: false
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BANK_OPTIONS_REQUEST]: request,
  [Types.BANK_OPTIONS_SUCCESS]: success,
  [Types.BANK_OPTIONS_FAILURE]: failure
});
