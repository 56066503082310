import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  fontFamilyBase,
  colorInterfaceDarkDeep
} from '@useblu/ocean-tokens/web/tokens';
import Tag from './Tag';
import { ButtonLightMixin } from './Button';

// ATTENTION:
// This is a self contained component, it should be replaced by the dropdown
// from ocean asap.

const Li = styled.li`
  a,
  button {
    ${ButtonLightMixin}

    display: flex;
    padding: 0px 16px;
    height: 50px;
    width: 100%;
    font-size: 16px;
    align-items: center;
    background-color: transparent;
    justify-content: space-between;

    font-family: ${fontFamilyBase};
    color: ${colorInterfaceDarkDeep};
  }
`;

const Option = ({
  id,
  label,
  link,
  target,
  method,
  isNew,
  onClick,
  style,
  className
}) => {
  const onClickButton = () => {
    onClick();
  };

  return onClick ? (
    <Li key={id}>
      <button
        type="button"
        className={className}
        onClick={onClickButton}
        style={style}
      >
        {label} {isNew && <Tag>novo</Tag>}
      </button>
    </Li>
  ) : (
    <Li key={link}>
      <a {...aProps(link, className, target, method, style)}>
        {label} {isNew && <Tag>novo</Tag>}
      </a>
    </Li>
  );
};

const aProps = (link, className, target, method, style) => {
  const props = {
    className,
    href: link,
    target,
    style
  };

  if (method !== 'get') {
    props['data-method'] = method;
  }

  return props;
};

Option.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  link: PropTypes.string,
  target: PropTypes.string,
  isNew: PropTypes.bool,
  method: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string
};

Option.defaultProps = {
  method: 'get',
  link: '#',
  isNew: false,
  target: undefined,
  onClick: null,
  style: null,
  className: ''
};

export default Option;
