import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getRefund, getPaymentInboundsApi } from 'api/pix';
import { useSelector } from 'react-redux';

import Details from './Details';

const Refund = ({
  title,
  value,
  date,
  refundId,
  proofPath,
  ctStatus,
  ctParentUuid,
  ctParentPaymentUuid
}) => {
  const { clientId } = useSelector((state) => ({
    ...state.railsContext
  }));

  const [loadingRefund, setLoadingRefund] = useState(true);
  const [refundData, setRefundData] = useState({});
  const [paymentInboundData, setPaymentInboundData] = useState({});

  const getRefundData = useCallback(async () => {
    try {
      setLoadingRefund(true);

      const {
        data: { data: refund }
      } = await getRefund(clientId, refundId);

      if (!refund) {
        throw new Error();
      }

      setRefundData(refund);
    } finally {
      setLoadingRefund(false);
    }
  }, [clientId, refundId]);

  const getPaymentInbound = useCallback(async () => {
    getPaymentInboundsApi(clientId, ctParentPaymentUuid).then(
      ({ data: dataDebit }) => {
        setPaymentInboundData(dataDebit.data);
      }
    );
  }, [clientId, ctParentPaymentUuid]);

  useEffect(() => {
    if (refundId) getRefundData();

    getPaymentInbound();
  }, [refundId]);

  return (
    <Details
      title={title}
      value={value}
      date={date}
      refundId={refundId}
      clientUUID={clientId}
      refundData={refundData?.attributes}
      proofPath={proofPath}
      paymentInboundData={paymentInboundData}
      ctStatus={ctStatus}
      ctParentUuid={ctParentUuid}
    />
  );
};

Refund.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  refundId: PropTypes.string,
  proofPath: PropTypes.string.isRequired,
  ctStatus: PropTypes.string.isRequired,
  ctParentUuid: PropTypes.string.isRequired,
  ctParentPaymentUuid: PropTypes.string.isRequired
};

Refund.defaultProps = {
  refundId: ''
};

export default Refund;
