import { useEffect, useState } from 'react';
import * as yup from 'yup';
import moment from 'moment';

import isValidName from 'validations/ValidateName';

const useFormUserInfor = ({
  user,
  loadFormData,
  userCadastralUpdate,
  showClientStep,
  generateContract
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [hasChangeValidation, enableChangeValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [hasFieldError, setHasFieldError] = useState(false);
  const [changeEmail, setChangeEmail] = useState();
  const [formData, setFormData] = useState({
    name: '',
    cpf: '',
    email: '',
    birthday: '',
    phone: '',
    phone_2: '',
    phone_3: ''
  });

  const dateInputTest = (date) => {
    const regexddmmyyyy =
      /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/;

    if (regexddmmyyyy.test(date)) {
      return true;
    }
    return false;
  };

  const formikProps = {
    initialValues: {
      name: formData.name || '',
      cpf: formData.cpf || '',
      email: formData.email || '',
      birthday: formData.birthday || '',
      phone: formData.phone || '',
      phone_2: formData.phone_2 || '',
      phone_3: formData.phone_3 || ''
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Adicione um E-mail válido')
        .required('Este campo é obrigatório.'),
      birthday: yup
        .string()
        .required('Este campo é obrigatório.')
        .test({
          name: 'dateInputTest',
          message: 'Formato de data inválida. Ex: 01/01/1999.',
          test: (value) => dateInputTest(value)
        })
        .test({
          name: 'isValidDate',
          message:
            'É necessário ter 18 anos ou mais para ser um representante legal da empresa.',
          test: (value) => {
            const moreThan18Years =
              moment().diff(moment(value, 'DD/MM/YYYY'), 'years') > 17;
            return moreThan18Years;
          }
        })
        .test({
          name: 'isValidDate',
          message: 'Data inválida. Confira o ano da sua data de nascimento.',
          test: (value) => {
            const lessThan100Years =
              moment().diff(moment(value, 'DD/MM/YYYY'), 'years') <= 100;
            return lessThan100Years;
          }
        }),
      phone_2: yup
        .string()
        .matches(
          /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
          'Este Telefone não é válido.'
        )
        .test({
          name: 'isNotRepeatedPhone',
          message:
            'Seu novo telefone precisa ser diferente do número já cadastrado.',
          test: (phone2, parentValues) => {
            if (phone2 !== undefined) {
              const inputPhoneList = [
                parentValues.parent.phone,
                parentValues.parent.phone_3
              ];
              const notFoundRepeatedPhone = !inputPhoneList.includes(phone2);
              return notFoundRepeatedPhone;
            }
            return true;
          }
        }),
      phone_3: yup
        .string()
        .matches(
          /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
          'Este Telefone não é válido.'
        )
        .test({
          name: 'isNotRepeatedPhone',
          message:
            'Seu novo telefone precisa ser diferente do número já cadastrado.',
          test: (phone3, parentValues) => {
            if (phone3 !== undefined) {
              const inputPhoneList = [
                parentValues.parent.phone,
                parentValues.parent.phone_2
              ];
              const notFoundRepeatedPhone = !inputPhoneList.includes(phone3);
              return notFoundRepeatedPhone;
            }
            return true;
          }
        }),
      name: yup.string().test({
        name: 'isValidName',
        message: 'Este Nome não é válido',
        test: (value) => isValidName(value)
      })
    }),

    onSubmit: (values) => {
      if (values.email !== formData.email) {
        setVisible(true);
      } else {
        setErrorMessage();
        setHasFieldError(false);
        setLoading(true);
        userCadastralUpdate(values)
          .then((response) => {
            if (response.success) {
              setHasFieldError(false);
              generateContract();
              showClientStep();
            }
            if (response.response.data.errors) {
              setHasFieldError(true);
              setErrorMessage(response.response.data.errors);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const maskDefault = (value) => {
    const dateSplit = value.birthday && value.birthday.split('-');

    const cpfFormat = value.cpf
      .replace(/[^\d]/g, '')
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

    const phoneFormat = (phone) => {
      if (!phone) return;

      return phone
        .replace(/[^\d]/g, '')
        .replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    };

    setFormData({
      ...value,
      cpf: cpfFormat,
      phone: phoneFormat(value.phone),
      phone_2: phoneFormat(value.phone_2),
      phone_3: phoneFormat(value.phone_3),
      birthday: dateSplit && `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`
    });
  };

  useEffect(() => {
    if (!user?.name) {
      loadFormData();
    } else {
      loadFormData(true);
      maskDefault(user);
    }
  }, [loadFormData, user]);

  return {
    formikProps,
    formData,
    setFormData,
    hasChangeValidation,
    enableChangeValidation,
    showPhoneInput,
    setShowPhoneInput,
    visible,
    setVisible,
    setChangeEmail,
    changeEmail,
    dateInputTest,
    loading,
    errorMessage,
    hasFieldError
  };
};

export default useFormUserInfor;
