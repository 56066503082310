/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

export const sendValidationApi = async (
  clientUUID,
  authorizationType,
  enableForRepresentingClients
) =>
  axios.post(
    `/api/transaction_authorizations`,
    {
      client_id: clientUUID,
      transaction_authorization: {
        authorization_type: authorizationType,
        enable_for_representing_clients: enableForRepresentingClients,
        enable_automatic_anticipation: true,
      },
    },
    {
      headers: {
        Accept: 'version=3',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );

export const validationCodeApi = async (token, code) =>
  axios.patch(
    `/api/transaction_authorizations/${token}/perform`,
    { code },
    {
      headers: {
        'X-CSRF-Token': getCSRFToken(),
        'Content-Type': 'application/json',
        Accept: 'version=3',
      },
    }
  );

export const validationCodeStatusApi = async (clientUUID, token) =>
  axios.get(`/api/transaction_authorizations/${token}`, {
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      'Content-Type': 'application/json',
      Accept: 'version=3',
    },
  });

export const resendNotificationApi = (token) =>
  axios.post(
    `/api/transaction_authorizations/${token}/resend_notification`,
    null,
    {
      headers: {
        Accept: 'version=3',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );
