import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@useblu/ocean-react';
import AdvanceTransactionsDetailsTable from '../AdvanceTransactionsDetailsTable';
import { Title, ModalStyle, Description } from './styles';

const AdvanceTransactionsDetailsModal = ({
  modalOpen,
  setModalOpen,
  advancementData
}) => (
  <Modal
    onRequestClose={() => setModalOpen(false)}
    isOpen={modalOpen}
    ariaHideApp={false}
    portalClassName="ocean-iso custom-width modal-mobile-adjust modal-fixed-width"
  >
    <ModalStyle />
    <Title variant="heading3">Transações antecipadas</Title>
    <Description>Confira os detalhes de cada valor antecipado.</Description>
    <AdvanceTransactionsDetailsTable advancementData={advancementData} />
  </Modal>
);

AdvanceTransactionsDetailsModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  advancementData: PropTypes.object
};

AdvanceTransactionsDetailsModal.defaultProps = {
  advancementData: {}
};

export default AdvanceTransactionsDetailsModal;
