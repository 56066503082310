import { useState, useEffect } from 'react';

export default function useAdvancementDetailsDrawer() {
  const OPENEVENTTRIGGER = 'feature-drawer-anticipation-open';
  const CLOSEEVENTTRIGGER = 'feature-drawer-anticipation-close';

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [uuid, setUuid] = useState('');
  const [isAccounStatement, setIsAccounStatement] = useState(false);
  const [isURDetails, setIsURDetails] = useState(false);

  function openDrawer(uuid, isAccounStatement = false, isURDetails = false) {
    const event = new CustomEvent(OPENEVENTTRIGGER, {
      detail: { uuid, isAccounStatement, isURDetails },
    });
    document.dispatchEvent(event);
  }

  function closeDrawer(subject) {
    const event = new CustomEvent(CLOSEEVENTTRIGGER, {
      detail: { feature: subject },
    });
    document.dispatchEvent(event);
  }

  function closeAction(e) {
    setIsDrawerOpen(false);
    setUuid(e.detail.uuid);
  }

  function openAction(e) {
    setIsDrawerOpen(true);
    setUuid(e.detail.uuid);
    setIsURDetails(e.detail.isURDetails);
    setIsAccounStatement(e.detail.isAccounStatement);
  }

  // add listeners on mount
  useEffect(() => {
    document.addEventListener(OPENEVENTTRIGGER, openAction);
    document.addEventListener(CLOSEEVENTTRIGGER, closeAction);
  }, []);

  // remove listeners on unmount
  useEffect(
    () => () => {
      document.removeEventListener(OPENEVENTTRIGGER, openAction);
      document.removeEventListener(CLOSEEVENTTRIGGER, closeAction);
    },
    []
  );

  return {
    isDrawerOpen,
    uuid,
    isAccounStatement,
    isURDetails,
    openDrawer,
    closeDrawer,
  };
}
