import React from 'react';
import { useSelector } from 'react-redux';

import { normalizeValue } from 'normalizers';
import { railsContextSelector } from 'selectors/rails-context';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import InstallmentsContainer from '../InstallmentsContainer';
import StatusChip from './StatusChip';

const PaymentBilletInstallments = () => {
  const { clientId } = useSelector(railsContextSelector);
  const { uuid, bluBilletInstallments, bankBilletInstallments } = useSelector(
    selectors.getDetailsData
  );
  const listOfInstallments = bluBilletInstallments || bankBilletInstallments;

  return (
    <InstallmentsContainer>
      {listOfInstallments.map((installment, index) => (
        <div
          key={installment.uuid}
          className="list__item list__item-sp-between statement-detail__installment"
          data-testid={`item-${installment.uuid}`}
        >
          <div className="list__item-left">
            <p>
              <strong>{index + 1}ª Parcela</strong>
            </p>
            <p>
              {installment.uuid === uuid ? (
                'Você está vendo esta parcela'
              ) : (
                <a
                  className="detail__toggle"
                  href={`/lojas/${clientId}/cobrancas/${installment.uuid}`}
                >
                  Ver cobrança
                </a>
              )}
            </p>
          </div>

          <div
            className="list__item-right account-statement__item-value"
            style={{ flexDirection: 'column' }}
          >
            <p>
              <strong>{normalizeValue(installment.totalValue)}</strong>
            </p>
            <StatusChip installment={installment} />
          </div>
        </div>
      ))}
    </InstallmentsContainer>
  );
};

export default PaymentBilletInstallments;
