import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Eye, EyeOff } from '@useblu/ocean-icons-react';

const Container = styled.small`
  text-transform: none;
  display: inline-flex;
  margin-left: 5px;
  white-space: nowrap;

  button {
    color: #33c4d6;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    background: transparent;
    padding: 0;
    margin-left: 8px;

    svg {
      margin-right: 4px;
    }
  }
`;

const IdentityInfo = ({ id }) => {
  const [showsId, setShowsId] = useState(true);

  const EyeComponent = showsId ? EyeOff : Eye;

  return (
    <Container>
      {showsId ? '| '.concat(id) : ''}
      <button type="button" onClick={() => setShowsId(!showsId)}>
        <EyeComponent size={12} />
        {showsId ? 'ocultar id' : 'mostrar id'}
      </button>
    </Container>
  );
};

IdentityInfo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default IdentityInfo;
