import {
  getPaymentMethodInfos,
  setClientPaymentMethod
} from 'api/client-payment-method';

import { call, put } from 'redux-saga/effects';
import actions from 'store/ducks/clientPaymentMethods';

export function* clientPaymentMethodRequest(params) {
  try {
    const response = yield call(getPaymentMethodInfos, params.clientId);
    const { status, data } = response;

    if (status !== 200) {
      throw new Error();
    }

    const {
      data: { client }
    } = data;
    yield put(actions.clientPaymentMethodSuccess(client));
  } catch (error) {
    yield put(
      actions.clientPaymentMethodFailure(
        'Erro ao carregar informações da conta de recebimento.'
      )
    );
  }
}

export function* sendClientPaymentMethodRequest(params) {
  try {
    const response = yield call(
      setClientPaymentMethod,
      params.userId,
      params.paymentMethod,
      params.bankAccountId
    );
    const {
      status,
      data: { path }
    } = response;

    if (status !== 200 || !path) {
      throw new Error();
    }

    window.location.href = path;
  } catch (error) {
    yield put(
      actions.sendClientPaymentMethodFailure(
        'Favor entrar em contato com o suporte para efetuar essa alteração.'
      )
    );
  }
}
