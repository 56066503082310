import _ from 'lodash';
import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */
const { Types, Creators } = createActions({
  removeGroupCompanyRequest: ['groupId', 'companyId', 'companyUuid'],
  removeGroupCompanySuccess: ['companyId'],
  removeGroupCompanyFailure: ['companyId'],
  removeGroupCompanyFailureWithModal: ['companyId', 'message'],
  setSearch: ['term'],
  companiesRequest: ['groupId'],
  companiesSuccess: ['data', 'groupName'],
  companiesFailure: null,
  sendRequestCompanyRequest: [
    'groupId',
    'companyId',
    'companyUuid',
    'currentClientUuid'
  ],
  sendRequestCompanySuccess: ['companyId'],
  sendRequestCompanyFailure: ['companyId'],
  addAllEconomicGroupClientsRequest: ['groupId', 'currentClientUuid'],
  addAllEconomicGroupClientsSuccess: ['data'],
  addAllEconomicGroupClientsFailure: null
});

export { Types };
export default Creators;

/* Initial State */
const INITIAL_STATE = {
  term: '',
  items: [],
  loading: false,
  showModal: false,
  modalMessage: '',
  error: false,
  loadingSelectAll: false
};

/* Shared Functions */
const changeStatusCompany = (state, action, location) => {
  const index = _.findIndex(state.items, { client: { id: action.companyId } });

  const items = [...state.items];
  const item = { ...items[index] };

  item.inGroup = !item.inGroup;

  item.addSuccess = false;
  item.removeSuccess = false;

  if (location === 'Add') {
    if (!item.addLoading) {
      item.addLoading = true;
    } else {
      item.addLoading = !item.addLoading;
    }
  } else if (location === 'Remove') {
    if (!item.removeLoading) {
      item.removeLoading = true;
    } else {
      item.removeLoading = !item.removeLoading;
    }
  }

  items[index] = item;

  return {
    ...state,
    items
  };
};

/* Reducers Request */
export const request = (state) => ({
  ...state,
  loading: true
});

export const addAllEconomicGroupClientsRequest = (state) => {
  const { items } = state;

  for (let i = 0; i < items.length; i += 1) {
    if (!items[i].inGroup && items[i].client.currentBalance >= 0) {
      items[i].inGroup = true;
      items[i].addLoading = true;
      items[i].addSuccess = false;
      items[i].removeSuccess = false;
    }
  }

  return {
    ...state,
    items,
    loadingSelectAll: true,
    error: false
  };
};

export const requestCompany = (state, action) =>
  changeStatusCompany(state, action, 'Add');

export const removeCompany = (state, action) =>
  changeStatusCompany(state, action, 'Remove');

/* Reducers Success */
export const success = (state, action) => ({
  ...state,
  items: action.data,
  loading: false,
  error: false
});

export const companiesSuccess = (state, action) => ({
  ...state,
  items: action.data,
  loading: false,
  error: false
});

export const requestCompanySuccess = (state, action) => {
  const index = _.findIndex(state.items, { client: { id: action.companyId } });

  const items = [...state.items];
  const item = { ...items[index] };

  item.addLoading = false;
  item.addSuccess = true;

  items[index] = item;

  return {
    ...state,
    items
  };
};

export const removeCompanySuccess = (state, action) => {
  const index = _.findIndex(state.items, { client: { id: action.companyId } });

  const items = [...state.items];
  const item = { ...items[index] };

  item.removeLoading = false;
  item.removeSuccess = true;

  items[index] = item;

  return {
    ...state,
    items
  };
};

export const changeHeadquarterSuccess = (state, action) => ({
  ...state,
  items: action.data,
  loading: false,
  error: false
});

export const addAllEconomicGroupClientsSuccess = (state) => {
  const { items } = state;

  for (let i = 0; i < items.length; i += 1) {
    if (items[i].addLoading) {
      items[i].addSuccess = true;
      items[i].addLoading = false;
    }
  }

  return {
    ...state,
    loadingSelectAll: false,
    items,
    error: false
  };
};

/* Reducers Failure */
export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const requestCompanyFailure = (state, action) =>
  changeStatusCompany(state, action, 'Add');
export const removeCompanyFailure = (state, action) =>
  changeStatusCompany(state, action, 'Remove');

export const removeCompanyFailureWithModal = (state, action) => {
  const stateReturn = changeStatusCompany(state, action, 'Remove');

  return {
    ...stateReturn,
    showModal: true,
    modalMessage: action.message
  };
};

export const addAllEconomicGroupClientsFailure = (state) => {
  const { items } = state;

  for (let i = 0; i < items.length; i += 1) {
    if (items[i].addLoading) {
      items[i].inGroup = false;
      items[i].addLoading = false;
    }
  }

  return {
    ...state,
    loadingSelectAll: false,
    error: false
  };
};

/* Reducers Others */
export const setTerm = (state, action) => ({
  ...state,
  term: action.term
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REMOVE_GROUP_COMPANY_REQUEST]: removeCompany,
  [Types.REMOVE_GROUP_COMPANY_SUCCESS]: removeCompanySuccess,
  [Types.REMOVE_GROUP_COMPANY_FAILURE]: removeCompanyFailure,
  [Types.REMOVE_GROUP_COMPANY_FAILURE_WITH_MODAL]: removeCompanyFailureWithModal,
  [Types.COMPANIES_REQUEST]: request,
  [Types.COMPANIES_SUCCESS]: companiesSuccess,
  [Types.COMPANIES_FAILURE]: failure,
  [Types.SEND_REQUEST_COMPANY_REQUEST]: requestCompany,
  [Types.SEND_REQUEST_COMPANY_SUCCESS]: requestCompanySuccess,
  [Types.SEND_REQUEST_COMPANY_FAILURE]: requestCompanyFailure,
  [Types.ADD_ALL_ECONOMIC_GROUP_CLIENTS_REQUEST]: addAllEconomicGroupClientsRequest,
  [Types.ADD_ALL_ECONOMIC_GROUP_CLIENTS_SUCCESS]: addAllEconomicGroupClientsSuccess,
  [Types.ADD_ALL_ECONOMIC_GROUP_CLIENTS_FAILURE]: addAllEconomicGroupClientsFailure,
  [Types.SET_SEARCH]: setTerm
});
