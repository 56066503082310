import React from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Typography } from '@useblu/ocean-react';
import { useMedia } from 'react-use';

import icDate from '../assets/date.svg';
import icDateMobile from '../assets/date-mobile.svg';

import * as S from './styles';

const Header = ({ clientAA, clientTax }) => {
  const isMobile = useMedia('(max-width: 768px)');
  return (
    <S.Wrapper>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col xs={12} md={4}>
            <Grid.Row>
              <S.Tag>
                <Typography variant="description">PAGBLU</Typography>
              </S.Tag>
              <S.Title variant="heading2">
                {clientAA
                  ? `Ganhe ${clientTax}% de desconto`
                  : 'Economize até 10%'}
              </S.Title>
              <S.Description variant="heading2">
                {clientAA
                  ? 'Pagando qualquer fornecedor parceiro da Blu.'
                  : ' Pagando fornecedores parceiros da Blu usando seu saldo disponível com taxa zero de antecipação'}
              </S.Description>
            </Grid.Row>
          </Grid.Col>
          <Grid.Col
            xs={12}
            md={{
              span: 6,
              offset: 2
            }}
          >
            <img src={isMobile ? icDateMobile : icDate} alt="PagBlu" />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </S.Wrapper>
  );
};

Header.propTypes = {
  clientAA: PropTypes.bool.isRequired,
  clientTax: PropTypes.string.isRequired
};

export default Header;
