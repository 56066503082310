import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';
import { normalizeCurrency, formatDate } from 'normalizers';

export const AdvancementDetailsModal = ({
  data,
  modalIsOpen,
  onClose,
  getClient
}) => {
  const withName = (items) => items[0].clientUuid?.length > 0;

  if (data && data.advancementItems && data.advancementItems.length > 0)
    return (
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Modal"
        className={{
          afterOpen: 'modal--open',
          base: 'modal modal-v2 modal-taxes',
          beforeClose: 'modal modal-v2'
        }}
        overlayClassName={{
          afterOpen: 'modal-overlay--open',
          base: 'modal-overlay modal-v2--overlay',
          beforeClose: 'modal-overlay modal-v2--overlay'
        }}
        ariaHideApp={false}
        shouldReturnFocusAfterClose={false}
      >
        <div className="modal-v2-header">
          <h2 className="modal-v2-title">Transações antecipadas</h2>
          <button
            type="button"
            onClick={onClose}
            className="modal-v2-header__close button-close-tooltip"
          >
            <i className="fa fa-times" />
          </button>
        </div>
        <div className="modal-v2-content">
          <table className="table">
            <thead>
              <tr>
                {withName(data.advancementItems) && (
                  <th className="modal-taxes__store">Loja</th>
                )}
                <th className="modal-taxes__type">Tipo transação</th>
                <th>Adquirente</th>
                <th>Bandeira</th>
                <th>Data original</th>
                <th>Dias Antecipados</th>
                <th>Valor Antecipado</th>
                <th>Taxa de antecipação</th>
              </tr>
            </thead>
            <tbody>
              {data.advancementItems.map((item) => {
                const floatAdvancementValue = parseFloat(item.advancementValue);
                const floatAdvancementRateValue = parseFloat(
                  item.advancementRateValue
                );
                const advancementTotalValue =
                  floatAdvancementValue + floatAdvancementRateValue;

                return (
                  <tr key={item.receivableId}>
                    {item.clientUuid && (
                      <td className="modal-taxes__store">
                        {getClient(item.clientUuid)}
                      </td>
                    )}
                    <td
                      className={classNames({
                        'modal-taxes__type-1': !item.clientUuid,
                        'modal-taxes__type-2': item.clientUuid
                      })}
                    >
                      {item.kind}
                    </td>
                    <td>{item.acquirerName}</td>
                    <td>{item.providerBrandName}</td>
                    <td>{formatDate(item.originalReleaseAt)}</td>
                    <td>{item.advancedDays}</td>
                    <td className="space-left">
                      {`R$ ${normalizeCurrency(
                        advancementTotalValue.toFixed(2)
                      )}`}
                    </td>
                    <td>
                      {`R$ ${normalizeCurrency(
                        floatAdvancementRateValue.toFixed(2)
                      )}`}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr className="detail__footer">
                <td>Total</td>
                <td colSpan={withName(data.advancementItems) ? '5' : '4'}></td>
                <td className="darker space-left">
                  {`R$ ${normalizeCurrency(
                    parseFloat(
                      data.advancementValue +
                        Math.abs(data.advancementRateValue)
                    ).toFixed(2)
                  )}`}
                </td>
                <td>
                  {`R$ ${normalizeCurrency(
                    parseFloat(data.advancementRateValue).toFixed(2)
                  )}`}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className="modal-v2-footer">
          <button
            type="button"
            onClick={onClose}
            className="button button--rounder"
          >
            Fechar
          </button>
        </div>
      </Modal>
    );
  else return null;
};

AdvancementDetailsModal.propTypes = {
  data: PropTypes.object,
  modalIsOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  getClient: PropTypes.func
};

AdvancementDetailsModal.defaultProps = {
  getClient: null,
  data: {}
};

export default AdvancementDetailsModal;
