import React from 'react';
import PropTypes from 'prop-types';

import RefundHistoryListItem from './RefundHistoryListItem';

const RefundPaymentCollectionHistory = ({
  refunds,
  isCharger,
  showAccountStatementLink
}) => (
  <div className="list__wrapper refund-history-container">
    <h4 className="list__title">
      Devoluções {isCharger ? 'Efetuadas' : 'Recebidas'}
    </h4>
    <div className="refund-history-list">
      {refunds.map((refund, index) => (
        <RefundHistoryListItem
          key={refund.id}
          refund={{ order: index + 1, ...refund }}
          isCharger={isCharger}
          showAccountStatementLink={showAccountStatementLink}
        />
      ))}
    </div>
  </div>
);

RefundPaymentCollectionHistory.propTypes = {
  refunds: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCharger: PropTypes.bool.isRequired,
  showAccountStatementLink: PropTypes.bool.isRequired
};

export default RefundPaymentCollectionHistory;
