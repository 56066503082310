import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncFn } from 'react-use';
import Snackbar, { useSnackbar } from 'components/Shared/Snackbar';
import { requestNewPassword } from '../services/password';

import BlockedAccountSVG from '../images/illustra-security.svg';

import * as S from '../devise.styles';

const UnlockSteps = ({ clientEmail, isMaximumAttemptsMatched }) => {
  const [requestState, requestNew] = useAsyncFn(requestNewPassword);
  const [hasResendEmail, setHasResendEmail] = useState(false);
  const {
    isActive: snackIsActive,
    message: snackMessage,
    type: snackType,
    openSnackBar
  } = useSnackbar();

  const handleResendEmail = () => {
    requestNew({ email: clientEmail });
    setHasResendEmail(true);

    setTimeout(() => {
      setHasResendEmail(false);
    }, 15000);
  };

  React.useEffect(() => {
    if (requestState.loading) return;

    if (requestState?.value?.data?.message) {
      openSnackBar('Código reenviado com sucesso.', 'success');
    }

    if (requestState?.error) {
      openSnackBar('Falha no envio, tente novamente.', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestState]);

  return (
    <>
      <S.BlockedAccount>
        <Snackbar
          isActive={snackIsActive}
          message={snackMessage}
          type={snackType}
        />
        <img src={BlockedAccountSVG} alt="Conta bloqueada" />
        {isMaximumAttemptsMatched ? (
          <>
            <S.BlockedAccountTitle>
              Não consegue acessar sua conta?
            </S.BlockedAccountTitle>
            <S.Subtitle>
              Caso exista uma conta associada à <strong>{clientEmail}</strong>,
              enviaremos um e-mail com um link de redefinição de senha, válido
              por 30 minutos.
              <p>Não encontrou? Veja sua caixa de spam ou reenvie o e-mail.</p>
            </S.Subtitle>
            <S.WrapBtns>
              <S.BtnSubmit
                onClick={handleResendEmail}
                variant="secondary"
                tabIndex={-1}
                disabled={hasResendEmail}
              >
                Reenviar e-mail
              </S.BtnSubmit>
            </S.WrapBtns>
          </>
        ) : (
          <>
            <S.BlockedAccountTitle>
              Não consegue acessar sua conta?
            </S.BlockedAccountTitle>
            <S.Subtitle>Redefina a sua senha e tente novamente.</S.Subtitle>

            <S.WrapBtns>
              <S.BtnSubmit
                onClick={() => window.location.assign('/users/password/new')}
                variant="primary"
                tabIndex={-1}
              >
                Redefinir senha
              </S.BtnSubmit>
            </S.WrapBtns>
          </>
        )}
      </S.BlockedAccount>
    </>
  );
};
UnlockSteps.defaultProps = {
  clientEmail: '',
  isMaximumAttemptsMatched: false
};

UnlockSteps.propTypes = {
  clientEmail: PropTypes.string,
  isMaximumAttemptsMatched: PropTypes.bool
};

export default UnlockSteps;
