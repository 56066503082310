import { all, put, call, takeLatest, delay } from 'redux-saga/effects';
import humps from 'lodash-humps';

import { getAllActiveDevice, revokeBToken } from 'api/b-token';
import actions, { types } from './actions';

function* watchFetchBTokenByUserRequest(action) {
  try {
    const [response] = yield all([call(getAllActiveDevice), delay(1000)]);

    yield put(actions.fetchBTokenByUserSuccess(humps(response.data)));
  } catch (error) {
    yield put(
      actions.fetchBTokenByUserFailure({
        action,
        message: 'Ops! Algo deu errado :('
      })
    );
  }
}

function* watchRevokeBTokenByIdRequest(action) {
  try {
    yield all([call(revokeBToken, action.id), delay(1000)]);
    yield put(actions.revokeBTokenByIdSuccess(action.id));
  } catch (error) {
    yield put(
      actions.revokeBTokenByIdFailure({
        action,
        message: 'Ops! Algo deu errado :('
      })
    );
  }
}

export default [
  takeLatest(
    types.FETCH_B_TOKEN_BY_USER_REQUEST,
    watchFetchBTokenByUserRequest
  ),
  takeLatest(types.REVOKE_B_TOKEN_BY_ID_REQUEST, watchRevokeBTokenByIdRequest)
];
