import React from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';

const ChipStyled = styled.div`
  justify-self: flex-start;

  ${(props) =>
    props.processing &&
    css`
      color: #e29950;
      background-color: #feefe8;
    `}
`;

const PaymentPlanStatus = ({ type, name }) => (
  <ChipStyled
    className={classNames('chip-root chip-root--small', {
      'chip-color--success': type === 'billed',
      'chip-color--danger': type === 'rejected',
      'chip-color--warning': type === 'pending'
    })}
    processing={type === 'processing'}
  >
    <span className="chip-label chip-label--small">{name}</span>
  </ChipStyled>
);

PaymentPlanStatus.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default PaymentPlanStatus;
