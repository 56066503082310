/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { connect } from 'react-redux';
import { compose } from 'redux';

/* Actions and Validations */
import usersUploadActions from 'store/ducks/firstAccess/usersUpload';
import sharedActions from 'store/ducks/firstAccess/shared';

/* Presentational */
import { Field, reduxForm } from 'redux-form';
import RenderField from 'components/Form/RenderField';
import _ from 'lodash';
import Loading from 'react-loading';
import { clientSelector } from 'selectors/FirstAccess/shared';

import classNames from 'classnames';
import Modal from 'react-modal';
import history from 'utils/browserHistory';

class UsersUploadForm extends Component {
  constructor(props) {
    super(props);
    this.sendsEmail = this.sendsEmail.bind(this);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.state = {
      contract: true,
      circleContract: true,
      disabledButtonContract: true,
      errorContract: false,
      checkContract: false
    };
  }

  componentDidMount() {
    this.props.setCurrentStep('usersUploads');
  }

  uploadFile(e) {
    const { usersUploadSubmitRequest, uuid, client } = this.props;
    this.setState({
      contract: false,
      circleContract: false,
      disabledButtonContract: false
    });

    const file = e.target.files[0];

    usersUploadSubmitRequest(uuid, client, file);
    this.setState({
      contract: false,
      errorContract: true,
      disabledButtonContract: true
    });
  }

  submit = () => {
    history.push(`/lojas/${this.props.uuid}/primeiro-acesso/finalizado`);
  };

  next = () => {
    window.location = `/lojas/${this.props.uuid}/primeiro-acesso/finalizado`;
  };

  previous() {
    history.push(
      `/lojas/${this.props.uuid}/primeiro-acesso/assinatura-de-contrato`
    );
  }

  checkClientContractUpload() {
    const { client } = this.props;
    return !_.isNil(client.socialContract) && !_.isEmpty(client.socialContract);
  }

  buildClientDocumentStatus() {
    let {
      contract,
      circleContract,
      checkContract,
      disabledButtonContract,
      errorContract
    } = this.state;
    const { client } = this.props;

    if (client.socialContract || this.checkClientContractUpload()) {
      [contract, circleContract, disabledButtonContract, errorContract] = [
        false,
        false,
        false,
        false
      ];
      checkContract = true;
    }

    return {
      contract,
      circleContract,
      checkContract,
      disabledButtonContract,
      errorContract
    };
  }

  sendsEmail() {
    const { uuid, usersUploadEmailRequest } = this.props;
    usersUploadEmailRequest(uuid, this.refs.targetEmail.value);
  }

  renderNextButton(checkContract) {
    const { submitting } = this.props;

    let textButton = 'Confirmar';
    let disabledButton = true;

    disabledButton = false;
    if (!checkContract) {
      textButton = 'Enviar Documento Depois';
    }

    const content = (
      <button
        id="btnNext"
        onClick={this.next}
        className="button form-controls button--primary"
        disabled={disabledButton}
      >
        {textButton}
        <i className="fa fa-chevron-right" />
        <div style={submitting ? {} : { display: 'none' }}>
          <Loading
            type="spinningBubbles"
            color="#a1aec7"
            width="15px"
            height="15px"
            delay={0}
          />
        </div>
      </button>
    );

    return content;
  }

  renderEmailModal() {
    const { loading } = this.props;

    const classes = {
      afterOpen: 'modal--open',
      base: 'modal',
      beforeClose: 'modal'
    };

    const overlayClasses = {
      afterOpen: 'modal-overlay--open',
      base: 'modal-overlay',
      beforeClose: 'modal-overlay'
    };

    return (
      <Modal
        isOpen={this.props.modal.isOpen}
        onRequestClose={this.props.toggleModal}
        contentLabel="Modal"
        className={classes}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
      >
        {this.props.email.sent ? (
          <h2>E-mail enviado com sucesso!</h2>
        ) : (
          <h2>Informe o e-mail do responsável pelo envio do contrato</h2>
        )}
        <div className="modal-description">
          {!this.props.email.sent && (
            <div className="form-row">
              <Field
                name="email"
                className="string name"
                label="E-mail"
                ref="targetEmail"
                component={RenderField}
                type="text"
              />
            </div>
          )}
        </div>
        <div className="modal-action">
          {!this.props.email.sent && (
            <button
              className="button button--primary"
              onClick={this.sendsEmail}
              disabled={loading.email}
            >
              {loading.email ? 'Enviando' : 'Enviar'}
            </button>
          )}
          <button
            className="button button--secondary"
            onClick={this.props.toggleModal}
          >
            {this.props.email.sent ? 'Fechar' : 'Cancelar'}
          </button>
        </div>
      </Modal>
    );
  }

  renderLoading() {
    return (
      <div className="loading">
        <Loading
          type="spinningBubbles"
          color="#e1e5ed"
          width="100px"
          height="100px"
          delay={0}
        />
      </div>
    );
  }

  toogleModal = (e) => {
    e.preventDefault();

    this.props.toggleModal();
  };

  renderUserForm() {
    const { client, handleSubmit, loading } = this.props;
    let content;

    if (!_.isEmpty(client)) {
      const {
        circleContract,
        checkContract,
        contract,
        disabledButtonContract,
        errorContract
      } = this.buildClientDocumentStatus();

      const loaderContract = classNames({
        'fa fa-arrow-circle-up': circleContract,
        'fa fa-spinner fa-pulse fa-fw': loading.upload,
        'fa fa-check': checkContract,
        'fa fa-times': errorContract
      });

      const contractUploadState = classNames({
        valid: checkContract,
        invalid: errorContract && !loading.upload
      });

      content = (
        <form
          className="form-fields-for"
          encType="multipart/form-data"
          onSubmit={handleSubmit(this.submit)}
          noValidate
        >
          <h3 className="page-subtitle">
            <i className="fa fa-paperclip" /> Envio de contrato Social
          </h3>
          <div className="form-row">
            <div className="form-controls">
              <div className={`file-upload ${!contract ? 'disabled' : ''}`}>
                <input
                  type="file"
                  id="contract_upload"
                  name="contract_upload"
                  onChange={(e) => this.uploadFile(e)}
                  disabled={!disabledButtonContract}
                />
                <label htmlFor="contract_upload">
                  <div className="file-upload--info">
                    <span className="file-upload--title">
                      Contrato Social ({client.name.toUpperCase()}){' '}
                    </span>
                    <span className="file-upload--description">
                      Para prosseguir, anexe uma imagem legível nos formatos
                      JPG, JPEG, PNG OU PDF do contrato social da sua empresa. O
                      processo de validação é automático e leva apenas alguns
                      segundos.
                    </span>
                  </div>
                  <div className={`file-upload--icon ${contractUploadState}`}>
                    <i className={loaderContract} />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {!checkContract && (
            <span>
              <hr />
              <h2 className="file-upload--or-label">OU</h2>
              <hr />
              <p>
                Caso não possua o documento digitalizado, informe o e-mail de um
                responsável pelo envio do contrato.
              </p>
              <div className="form-row">
                <button
                  onClick={this.toogleModal}
                  className="button button--link"
                >
                  <i className="fa fa-envelope" /> Solicitar envio por e-mail
                </button>
              </div>
              {this.renderEmailModal()}
              <hr />
            </span>
          )}
          <div className="form-row actions">
            <button
              className="button form-controls button--secondary"
              onClick={this.previous}
            >
              Voltar
            </button>
            {this.renderNextButton(checkContract)}
          </div>
        </form>
      );
    }
    return content;
  }

  render() {
    return (
      <div className="flex-container">
        {_.isEmpty(this.props.client)
          ? this.renderLoading
          : this.renderUserForm()}
      </div>
    );
  }
}

UsersUploadForm.propTypes = {
  uuid: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  modal: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  usersUploadEmailRequest: PropTypes.func.isRequired,
  usersUploadSubmitRequest: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired
};

UsersUploadForm.defaultProps = {
  invalid: false,
  submitting: false,
  client: {}
};

const mapStateToProps = (state) => ({
  client: clientSelector(state),
  errors: state.firstAccessUsersUpload.errors,
  modal: state.firstAccessUsersUpload.modal,
  email: state.firstAccessUsersUpload.email,
  loading: state.firstAccessUsersUpload.loading,
  user: state.firstAccessUsersUpload.user
});

const mapDispatchToProps = (dispatch) => ({
  usersUploadEmailRequest: (uuid, email) =>
    dispatch(
      usersUploadActions.firstAccessUsersUploadEmailRequest(uuid, email)
    ),
  usersUploadSubmitRequest: (uuid, client, file) =>
    dispatch(
      usersUploadActions.firstAccessUsersUploadSubmitRequest(uuid, client, file)
    ),
  toggleModal: () =>
    dispatch(usersUploadActions.firstAccessUsersUploadToggleModal()),
  setCurrentStep: (step) => dispatch(sharedActions.firstAccessCurrentStep(step))
});

const UsersUploadFormReduxForm = reduxForm({
  form: 'usersUploadForm'
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  UsersUploadFormReduxForm
)(UsersUploadForm);
