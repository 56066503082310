import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAsyncFn } from 'react-use';
import { signInToken } from '../services/signUp';

import SVGResetPassword from '../images/illustra-reset-password.svg';

import {
  Title,
  Subtitle,
  WrapBtns,
  BtnSubmit,
  BodyWrapper
} from '../devise.styles';

const RedefinedPasswordMessage = ({ isNewUser, token }) => {
  const [signInTokenState, signInTokenRequest] = useAsyncFn(signInToken);

  useEffect(() => {
    if (signInTokenState?.value?.status === 200) {
      window.location.assign('/');
    }
  }, [signInTokenState]);

  return (
    <BodyWrapper>
      <img src={SVGResetPassword} alt="Senha redefinida com sucesso!" />

      <Title $newUser={isNewUser}>
        {isNewUser
          ? 'Senha cadastrada com sucesso'
          : 'Senha redefinida com sucesso!'}
      </Title>
      <Subtitle>
        <span>
          {isNewUser
            ? 'Na próxima vez que for acessar sua conta, utilize o e-mail em que recebeu o link e informe a senha que você acabou de cadastrar.'
            : 'Agora você já pode acessar sua Conta Digital Blu utilizando a sua nova senha.'}
        </span>
      </Subtitle>

      <WrapBtns className="without-margin" style={{ marginTop: '40px' }}>
        <BtnSubmit
          type="submit"
          tabIndex={-1}
          className="acessar_autocadastro_sucesso"
          onClick={() => {
            if (signInTokenState.loading) return;

            signInTokenRequest({
              token
            });
          }}
        >
          Acessar Conta Digital Blu
        </BtnSubmit>
      </WrapBtns>
    </BodyWrapper>
  );
};

RedefinedPasswordMessage.propTypes = {
  isNewUser: PropTypes.bool,
  token: PropTypes.string.isRequired
};

RedefinedPasswordMessage.defaultProps = {
  isNewUser: false
};

export default RedefinedPasswordMessage;
