export function coreTaxTitle(title) {
  switch (title) {
    case 'debit_rate':
      return 'Débito';
    case 'credit_rate_1':
      return 'Crédito à Vista';
    case 'credit_rate_2':
      return 'Crédito em 2x';
    case 'credit_rate_3':
      return 'Crédito em 3x';
    case 'credit_rate_4':
      return 'Crédito em 4x';
    case 'credit_rate_5':
      return 'Crédito em 5x';
    case 'credit_rate_6':
      return 'Crédito em 6x';
    case 'credit_rate_7':
      return 'Crédito em 7x';
    case 'credit_rate_8':
      return 'Crédito em 8x';
    case 'credit_rate_9':
      return 'Crédito em 9x';
    case 'credit_rate_10':
      return 'Crédito em 10x';
    case 'credit_rate_11':
      return 'Crédito em 11x';
    case 'credit_rate_12':
      return 'Crédito em 12x';
    default:
      console.log('season not defined');
  }

  return 0;
}

export function notCoreTaxTitle(title) {
  switch (title) {
    case 'debit_rate':
      return 'Débito';
    case 'credit_rate_1':
      return 'Crédito à Vista';
    case 'credit_rate_2':
      return 'Crédito em 2x';
    case 'credit_rate_3':
      return 'Crédito em 3x';
    case 'credit_rate_4':
      return 'Crédito em 4x';
    case 'credit_rate_5':
      return 'Crédito em 5x';
    case 'credit_rate_6':
      return 'Crédito em 6x';
    case 'credit_rate_7':
      return 'Crédito em 7x';
    case 'credit_rate_8':
      return 'Crédito em 8x';
    case 'credit_rate_9':
      return 'Crédito em 9x';
    case 'credit_rate_10':
      return 'Crédito em 10x';
    case 'credit_rate_11':
      return 'Crédito em 11x';
    case 'credit_rate_12':
      return 'Crédito em 12x';
    default:
      console.log('season not defined');
  }

  return 0;
}
