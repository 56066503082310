import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Provider, useSelector, useDispatch } from 'react-redux';
import store from 'store';

import DragContainer from 'components/Shared/DragContainer';
import importConfirmationCreators from 'store/ducks/importPaymentConfirmation';

const Component = ({ uuid, fileLink }) => {
  const dispatch = useDispatch();
  const { errorMessage, importLoading } = useSelector(
    (state) => state.importPaymentConfirmation
  );

  useEffect(() => {
    dispatch(importConfirmationCreators.clearImportPaymentConfirmation());
  }, [dispatch]);

  const onChangeBankFile = (e) => {
    dispatch(
      importConfirmationCreators.uploadTransferFileRequest(
        e.target.files[0],
        uuid
      )
    );
  };

  return (
    <>
      <DragContainer name="bankFile" onSuccess={onChangeBankFile}>
        {importLoading && (
          <div className="transfer-upload-loading">
            <i className="fa fa-spinner fa-spin transfer-upload-loading-fs" />
          </div>
        )}
        <p className="transfer-upload-title">
          <b>Arraste aqui o arquivo CNAB</b>
        </p>
        <p className="transfer-upload-format">Formato: CNAB 240 (.txt)</p>
        <ul className="transfer-upload-list">
          <li>O arquivo deve estar no padrão Unicode (UTF-8)</li>
          <li>
            O arquivo deve conter as seguintes colunas:
            <br />
            <b>Favorecido | CPF/CNPJ | Banco | Conta | Agência | Valor</b>
          </li>
          <li>
            <b>Para contas Blu:</b> Preencher com zeros os campos Banco,
            <br /> Conta e Agência
          </li>
        </ul>
        <button
          type="button"
          className="transfer-upload-button"
          disabled={importLoading}
        >
          Escolher arquivo CNAB
        </button>
        {!isEmpty(errorMessage) && (
          <p className="transfer-upload-error">* {errorMessage}</p>
        )}
      </DragContainer>
      <div className="transfer-example-file">
        <a href={fileLink} target="blank">
          <i className="ic ic-pdf-file" />
          Instruções para formatação do arquivo CNAB
        </a>
      </div>
    </>
  );
};

Component.propTypes = {
  uuid: PropTypes.string.isRequired,
  fileLink: PropTypes.string.isRequired,
};

const UploadTransferFile = ({ uuid, fileLink }) => (
  <Provider store={store}>
    <Component uuid={uuid} fileLink={fileLink} />
  </Provider>
);

UploadTransferFile.propTypes = {
  uuid: PropTypes.string.isRequired,
  fileLink: PropTypes.string.isRequired,
};

export default UploadTransferFile;
