const initialState = {
  loading: false,
  showClientStep: false,
  showContractStep: false,
  showUserStep: false,
  showFinishStep: false,
  user: {},
  client: {},
  address: {},
  errors: {},
  editName: false,
  editCPF: false,
  registration_step: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'DATA_LOAD':
      return {
        ...state,
        loading: true
      };
    case 'DATA_LOAD_SUCCESS':
      return {
        ...state,
        loading: false
      };
    case 'DATA_LOADED':
      return {
        ...state,
        loading: false,
        user: action.user,
        client: action.client,
        address: action.address,
        registration_step: action.registration_step
      };
    case 'DATA_SUBMIT':
      return {
        ...state,
        loading: true,
        showUserStep: false,
        showClientStep: false,
        showContractStep: false,
        errors: {}
      };
    case 'DATA_SUBMITED_ERROR':
      return {
        ...state,
        errors: action.errors
      };
    case 'USER_STEP':
      return {
        ...state,
        loading: false,
        showUserStep: true,
        showClientStep: false,
        showContractStep: false,
        showFinishStep: false
      };
    case 'CLIENT_STEP':
      return {
        ...state,
        loading: false,
        showClientStep: true,
        showContractStep: false,
        showUserStep: false,
        showFinishStep: false
      };

    case 'CONTRACT_STEP':
      return {
        ...state,
        loading: false,
        showContractStep: true,
        showClientStep: false,
        showUserStep: false,
        showFinishStep: false
      };
    case 'CONTRACT_FINISH': {
      return {
        ...state,
        loading: false,
        showContractStep: false,
        showClientStep: false,
        showUserStep: false,
        showFinishStep: true
      };
    }
    case 'UPDATE_CONTRACT':
      return {
        ...state,
        client: {
          ...state.client,
          contract_lote_key: action.token
        }
      };
    default:
      return state;
  }
}
