/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  spacingInlineXs,
  spacingInlineSm,
  spacingInlineMd,
  spacingInlineLg,
  spacingInlineXl,
  borderRadiusCircular,
  fontSizeXxs,
  lineHeightComfy,
  colorInterfaceDarkDown,
  colorInterfaceDarkDeep,
  fontSizeSm,
  fontWeightBold,
  fontWeightExtrabold,
  lineHeightMedium
} from '@useblu/ocean-tokens/web/tokens';

export const Title = styled.h2`
  font-weight: ${fontWeightExtrabold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMedium};
  color: ${colorInterfaceDarkDeep};
  margin-bottom: ${spacingInlineMd};
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
    margin-bottom: ${spacingInlineLg};
  }
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Badge = styled.div`
  border-radius: ${borderRadiusCircular};
  padding: 3px 12px;
  font-weight: ${fontWeightBold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightComfy};
  > span {
    color: ${colorInterfaceDarkDown};
  }
  background: rgba(184, 195, 255, 0.2);
  margin-right: ${spacingInlineXs};
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  &:first-child {
    margin-bottom: ${spacingInlineMd};
    @media (min-width: 768px) {
      margin-right: ${spacingInlineXl};
      margin-bottom: 0;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Image = styled.img`
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 222px;
  }

  @media (max-width: 768px) {
    max-width: 160px;
    margin: 0 auto;
  }
`;

export const DescriptionText = styled.p`
  font-size: ${fontSizeXxs};
  line-height: ${lineHeightComfy};
  color: ${colorInterfaceDarkDown};
  margin-bottom: ${spacingInlineXs};
  @media (min-width: 768px) {
    margin-bottom: ${spacingInlineSm};
  }
`;
