import styled from 'styled-components';
import { Container } from './Styles';

export const ContentWrapper = styled.div`
  display: grid;
  row-gap: 15px;
`;

export const SummaryContainer = styled(Container)`
  align-items: center;
`;

export const SummaryValues = styled.div`
  grid-column: 1 / span 2;
  justify-self: flex-start;
  border-bottom: solid 1px rgba(108, 121, 161, 0.6);
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 24px;
  padding-bottom: 8px;
`;

export const SummaryBilledRemaining = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
  color: #6c79a1;
  font-size: 12px;

  span:first-child {
    font-size: 16px;
  }
`;

export const SummaryBilled = styled(SummaryBilledRemaining)`
  color: #6bd36b;

  span:first-child {
    font-weight: bold;
  }
`;

export const ToggleLink = styled.a`
  justify-self: flex-end;
  color: #032bd0;
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  line-height: 24px;
  cursor: pointer;
`;
