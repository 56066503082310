import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { normalizeCurrency } from 'normalizers';
import RefundSimulationCurrentOutflows from './RefundSimulationCurrentOutflows';
import RefundSimulationFutureOutflows from './RefundSimulationFutureOutflows';
import RefundSimulationResultTooltip from './RefundSimulationResultTooltip';

const RefundSimulationResultStep = ({
  formAction,
  setActiveStep,
  authenticityToken,
  refundValue,
  currentDate,
  refundOutflows,
  advancesRateValue,
  insuranceRateValue,
  tableOnTimeDiferenceValue,
  totalOfOutflows
}) => {
  const [submitting, setSubmitting] = useState(false);
  const currentRefundOutflow = refundOutflows.find(
    ({ releasedAt }) => releasedAt === currentDate
  );
  const futureRefundOutflows = refundOutflows.filter(
    ({ releasedAt }) => releasedAt !== currentDate
  );

  return (
    <form
      action={formAction}
      method="POST"
      onSubmit={() => setSubmitting(true)}
    >
      <div className="flex-container">
        <input
          type="hidden"
          value={authenticityToken}
          name="authenticity_token"
        />
        <input
          type="hidden"
          name="payment_collection_refund[value]"
          value={refundValue}
        />
        <div className="refund-simulation-result-table">
          <div className="refund-simulation-result-table__header">
            <div className="refund-simulation-result-table__cell">
              MOVIMENTAÇÃO
            </div>
            <div className="refund-simulation-result-table__cell">DATA</div>
            <div className="refund-simulation-result-table__cell refund-simulation-result-table__cell--right">
              VALOR
            </div>
          </div>
          {currentRefundOutflow && (
            <RefundSimulationCurrentOutflows
              refundOutflow={currentRefundOutflow}
              advancesRateValue={advancesRateValue}
            />
          )}
          {!isEmpty(futureRefundOutflows) && (
            <RefundSimulationFutureOutflows
              refundOutflows={futureRefundOutflows}
            />
          )}
          <div className="refund-simulation-result-table__subtotal">
            R$ {normalizeCurrency(totalOfOutflows)}
          </div>
          <div className="refund-simulation-result-table__total">
            {tableOnTimeDiferenceValue > 0 && (
              <RefundSimulationResultTooltip
                description="DIFERENÇA TABELA À VISTA"
                tooltip="O valor da diferença da tabela à vista será estornado proporcionalmente pela Blu."
                value={`R$ ${normalizeCurrency(tableOnTimeDiferenceValue)}`}
              />
            )}
            {insuranceRateValue > 0 && (
              <RefundSimulationResultTooltip
                description="SEGURO BLU ESTORNADO"
                tooltip="O valor do seguro será estornado proporcionalmente pela Blu."
                value={`R$ ${normalizeCurrency(insuranceRateValue)}`}
              />
            )}
            <div className="refund-simulation-result-table__total-item refund-simulation-result-table__total-refund">
              <span>TOTAL DEVOLVIDO</span>
              <span>R$ {normalizeCurrency(refundValue)}</span>
            </div>
          </div>
        </div>
        <div className="refund-simulation-result-actions">
          <button
            type="button"
            className="button button--primary"
            onClick={() => setActiveStep({ index: 0 })}
            disabled={submitting}
          >
            Voltar
          </button>
          <button
            type="submit"
            className="button button--primary"
            disabled={submitting}
          >
            Confirmar
          </button>
        </div>
      </div>
    </form>
  );
};

RefundSimulationResultStep.propTypes = {
  formAction: PropTypes.string.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  refundValue: PropTypes.number.isRequired,
  currentDate: PropTypes.string.isRequired,
  refundOutflows: PropTypes.arrayOf(PropTypes.object).isRequired,
  advancesRateValue: PropTypes.number,
  insuranceRateValue: PropTypes.number,
  tableOnTimeDiferenceValue: PropTypes.number,
  totalOfOutflows: PropTypes.number.isRequired
};

RefundSimulationResultStep.defaultProps = {
  insuranceRateValue: 0,
  tableOnTimeDiferenceValue: 0,
  advancesRateValue: 0
};

export default RefundSimulationResultStep;
