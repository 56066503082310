import axios from 'axios';

export function uploadTransferFileApi(file) {
  const query = `
    mutation {
      batchPaymentFile(file: ${null}) {
        uuid
      }
    }
  `;

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  const data = new FormData();
  data.append('file', file);
  data.append('query', query);

  return axios({
    method: 'post',
    url: '/graphql',
    query,
    data,
    config
  });
}

export function getImportPaymentConfirmationListApi(fileUuid) {
  const query = `
    query {
      batchPaymentTransactionsFile(uuid: "${fileUuid.replace(/(.txt)/g, '')}") {
        status
        errorMessage
        transactions {
          bankCode
          bankName
          bankBranchCode
          bankAccount
          bankAccountCheckDigit
          payeeName
          payDay
          paymentAmount
          cpfCnpj
          blu
          error
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function setImportPaymentsConfirmationApi(
  uuid,
  transactions,
  confirmation,
  reference
) {
  const query = `
    mutation {
      batchPaymentTransactions(clientUuid: "${uuid}", transactions: ${JSON.stringify(
    JSON.stringify(transactions)
  )}, confirmation: ${confirmation}, reference: "${reference}") {
        error
        uuid
        transactions {
          row
          bankCode
          bankBranchCode
          bankAccount
          bankName
          bankAccountCheckDigit
          payeeName
          paymentAmount
          cpfCnpj
          blu
          error
          status
          uuid
          warningNotConfirmedSimilarTransaction
        }
        total
        otherRates
        inclusionAt
        totalWithTaxes
        totalAdvancedValue
        numberOfTransactions
      }
    }
  `;

  return axios.post('/graphql', { query });
}
