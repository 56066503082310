import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { railsContextSelector } from 'selectors/rails-context';

import DeviseScreenLayout from '../components/DeviseScreenLayout';
import MarketingAside from '../components/MarketingAside';
import RegisterPassword from '../components/RegisterPassword';
import ConfirmTokenSms from '../components/ConfirmTokenSms';
import RedefinedPasswordMessage from '../components/RedefinedPasswordMessage';
import WhatsappButton from '../components/WhatsappButton';

import ImgComeToBlu3 from '../images/img-come-to-blu-03.png';
import ImgComeToBlu4 from '../images/img-come-to-blu-04.png';
import ImgComeToBlu5 from '../images/img-come-to-blu-05.png';

export default function EditPassword() {
  const { currentUserId } = useSelector(railsContextSelector);
  const [userData, setUserData] = useState();
  const [token, setToken] = useState(undefined);
  const [isNewUser, setNewUser] = useState(false);
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (currentUserId !== 'anonymous') window.location = '/';

    if (
      window.location.search.includes('new_user=true') ||
      window.location.search.includes('type=first-access')
    )
      setNewUser(true);
  }, []);

  const editPasswordAside = (hasLogin, isNew, hasToken) => {
    if (!isNew) return false;

    if (hasToken)
      return (
        <MarketingAside
          title="Economize com a Blu"
          caption="Pronto para decolar o seu negócio com a Blu?"
          image={ImgComeToBlu5}
        />
      );

    if (hasLogin)
      return (
        <MarketingAside
          title="Economize muito do seu tempo"
          caption="Com a Blu, você simplifica e organiza toda a sua rotina financeira em um só lugar."
          image={ImgComeToBlu4}
        />
      );

    return (
      <MarketingAside
        title="Economize pagando menos taxas"
        caption="Com a Blu, você tem acesso às melhores taxas de maquininhas."
        image={ImgComeToBlu3}
      />
    );
  };

  const EditPasswordFlux = (hasLogin, hasToken) => {
    if (hasToken)
      return <RedefinedPasswordMessage token={token} isNewUser={isNewUser} />;

    if (hasLogin) {
      return (
        <ConfirmTokenSms
          className={isNewUser && 'autocadastro=confirmacao-do-token'}
          isNewUser={isNewUser}
          userData={userData}
          next={(token) => {
            setToken(token);
          }}
        />
      );
    }
    return (
      <RegisterPassword
        className={isNewUser && 'autocadastro=senha'}
        isNewUser={isNewUser}
        completed={(user) => {
          setUserData(user);
          setStep(2);
        }}
      />
    );
  };

  const hasToken = typeof token !== 'undefined';

  return (
    <DeviseScreenLayout
      color={isNewUser ? 'light' : undefined}
      className={hasToken ? 'no-vertical-breakpoint' : ''}
      align
      aside={editPasswordAside(userData, isNewUser, hasToken)}
      formStep={!isNewUser ? step : step + 2}
      hideStep={hasToken}
      isNewUser={isNewUser}
    >
      {isNewUser && <WhatsappButton />}
      {EditPasswordFlux(userData, hasToken)}
    </DeviseScreenLayout>
  );
}
