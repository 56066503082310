import React, { memo, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@useblu/ocean-react';

import usePrevious from './hooks/usePreviousTokenValue';

export function TokenDigit({ focus, autoFocus, ...rest }) {
  const inputRef = useRef(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <Input ref={inputRef} {...rest} />;
}

TokenDigit.propTypes = {
  focus: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

TokenDigit.defaultProps = {
  focus: false,
  autoFocus: false,
};

const TokenDigitInput = memo(TokenDigit);
export default TokenDigitInput;
