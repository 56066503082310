import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import iconAdd from '../images/icon-add.svg';
import iconAddMobile from '../images/icon-add-mobile.svg';
import { BigButton } from './Button';
import Tag from './Tag';

export const BigButtonStyled = styled(BigButton)`
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;

    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;

const OptInButton = ({
  cercAgreementOptInConfirmed,
  openMenuOptin,
  className
}) => {
  if (cercAgreementOptInConfirmed) return null;

  return (
    <BigButtonStyled onClick={openMenuOptin} className={className}>
      <img src={iconAdd} alt="Botão de adicionar" className="desktop" />
      <img src={iconAddMobile} alt="Botão de adicionar" className="mobile" />
      Adicionar saldo de outras maquininhas
      <Tag className="mobile">novo</Tag>
    </BigButtonStyled>
  );
};

OptInButton.propTypes = {
  cercAgreementOptInConfirmed: PropTypes.bool.isRequired,
  openMenuOptin: PropTypes.func.isRequired,
  className: PropTypes.string
};

OptInButton.defaultProps = {
  className: ''
};

export default OptInButton;
