import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { railsContextSelector } from 'selectors/rails-context';
import { useMedia } from 'react-use';
import MobileBalance from './components/MobileBalance';
import { HeaderContainer, Nav } from './styles';
import NavContent from './components/NavContent';
import { HeaderProvider } from './context';

const Header = ({
  showCreateEconomicGroup,
  optionsItemsGroup,
  navContent,
  ...rest
}) => {
  const { economicGroupView, clientId, currentClientRealName } =
    useSelector(railsContextSelector);
  const isMobile = useMedia('(max-width: 768px)');
  const homeLink = `/lojas/${clientId}cd4024ce/dashboard`;

  React.useEffect(() => {
    if (showCreateEconomicGroup) {
      optionsItemsGroup[0].items.push({
        id: clientId,
        label: 'Grupo Econômico',
        link: `/lojas/${clientId}/configuracoes-grupo`
      });
    }
  }, []);

  return (
    <HeaderProvider
      value={{
        showCreateEconomicGroup,
        optionsItemsGroup,
        clientId,
        economicGroupView,
        currentStoreId: clientId,
        clientName: currentClientRealName,
        homeLink,
        ...rest
      }}
    >
      <HeaderContainer id="header-default">
        <Nav>{navContent}</Nav>
        {isMobile && <MobileBalance />}
      </HeaderContainer>
    </HeaderProvider>
  );
};

Header.propTypes = {
  balance: PropTypes.object,
  optionsItemsGroup: PropTypes.arrayOf(PropTypes.object),
  showBalance: PropTypes.bool,
  showCreateEconomicGroup: PropTypes.bool,
  externalWallet: PropTypes.object,
  isFinancialRepresentative: PropTypes.bool,
  linkCercAgreement: PropTypes.string,
  hasContractToSigning: PropTypes.bool,
  navContent: PropTypes.node
};

Header.defaultProps = {
  balance: { total: '0.0', current: '0.0', future: '0.0' },
  optionsItemsGroup: [],
  showBalance: false,
  hasContractToSigning: true,
  showCreateEconomicGroup: false,
  externalWallet: {},
  isFinancialRepresentative: false,
  linkCercAgreement: '#',
  navContent: <NavContent className="ocean-iso" />
};

export default Header;
