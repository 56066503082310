import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@useblu/ocean-react';
import * as S from '../styles';
import illustraError from '../assets/illustra-error.svg';

const AdvancementDetailsDrawerError = ({
  isAccounStatement,
  currentStoreId,
  uuid,
}) => {
  const redirectToURs = () => {
    window.location.href = `/lojas/${currentStoreId}/movimentacoes/antecipacao-de-recebiveis/${uuid}`;
  };

  return (
    <S.ErrorContainer>
      <img src={illustraError} alt="error" />
      <Typography variant="heading4">
        Não é possível consultar os custos dessa antecipação
      </Typography>
      {isAccounStatement ? (
        <Typography variant="description">
          Se precisar entre em contato com o suporte ou consulte os valores
          antecipados.
        </Typography>
      ) : (
        <Typography variant="description" className="transaction">
          Se precisar entre em contato com o suporte.
        </Typography>
      )}
      {isAccounStatement && (
        <Button variant="secondary" size="sm" onClick={redirectToURs}>
          Consultar valores antecipados
        </Button>
      )}
      <Typography variant="caption">
        3003 0807 (capitais e regiões metropolitanas) <br />
        ou no 0800 326 0807 (demais regiões).
      </Typography>
    </S.ErrorContainer>
  );
};

AdvancementDetailsDrawerError.propTypes = {
  uuid: PropTypes.string.isRequired,
  currentStoreId: PropTypes.string.isRequired,
  isAccounStatement: PropTypes.bool.isRequired,
};

export default AdvancementDetailsDrawerError;
