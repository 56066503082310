import React from 'react';

import { render, within } from 'utils/test-utils';
import PaymentBilletInstallments from '../PaymentBilletInstallments';

const defaultState = {
  uuid: '0000-0000-0000-0000',
  installmentsTitle: 'Forma de Recebimento',
  totalWithInstallments: 100,
  bluBilletInstallments: [
    {
      uuid: '0000-0000-0000-0000',
      status: 'paid',
      paidAt: '2020-02-02',
      scheduledAt: '2020-02-02',
      totalValue: 50
    },
    {
      uuid: '1111-1111-1111-1111',
      status: 'canceled',
      scheduledAt: '2020-03-02',
      updatedAt: '2020-03-10',
      totalValue: 50
    },
    {
      uuid: '2222-2222-2222-2222',
      status: 'pending',
      scheduledAt: '2020-04-02',
      totalValue: 50
    }
  ]
};

const setup = (state = {}) =>
  render(<PaymentBilletInstallments />, {
    initialState: {
      railsContext: { clientId: '34sdf45' },
      paymentCollectionDetails: {
        data: {
          ...defaultState,
          ...state
        }
      }
    }
  });

describe('<PaymentBilletInstallments /> tests', () => {
  it('should display installments', () => {
    const { getByTestId } = setup();

    const paidItem = within(getByTestId('item-0000-0000-0000-0000'));
    expect(paidItem.getByText('1ª Parcela')).toBeInTheDocument();
    expect(
      paidItem.getByText('Você está vendo esta parcela')
    ).toBeInTheDocument();
    expect(paidItem.getByText('R$ 50,00')).toBeInTheDocument();
    expect(paidItem.getByText('Paga em 02/02/2020')).toBeInTheDocument();

    const canceledItem = within(getByTestId('item-1111-1111-1111-1111'));
    expect(canceledItem.getByText('2ª Parcela')).toBeInTheDocument();
    expect(
      canceledItem.getByRole('link', {
        name: 'Ver cobrança'
      })
    ).toHaveAttribute('href', '/lojas/34sdf45/cobrancas/1111-1111-1111-1111');
    expect(canceledItem.getByText('R$ 50,00')).toBeInTheDocument();
    expect(
      canceledItem.getByText('Cancelada em 10/03/2020')
    ).toBeInTheDocument();

    const pendingItem = within(getByTestId('item-2222-2222-2222-2222'));
    expect(pendingItem.getByText('3ª Parcela')).toBeInTheDocument();
    expect(
      pendingItem.getByRole('link', {
        name: 'Ver cobrança'
      })
    ).toHaveAttribute('href', '/lojas/34sdf45/cobrancas/2222-2222-2222-2222');
    expect(pendingItem.getByText('R$ 50,00')).toBeInTheDocument();
    expect(
      pendingItem.getByText('Agendada para 02/04/2020')
    ).toBeInTheDocument();
  });
});
