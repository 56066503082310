import axios from 'axios';

export const getBatchPayments = (id, clientUuid) =>
  axios.get(`/api/batch_payments/${id}/client/${clientUuid}`, {
    headers: { Accept: 'version=1' }
  });

export const getBatchPaymentsLastConfirmed = () =>
  axios.get(`/api/batch_payments/last_confirmed`, {
    headers: { Accept: 'version=1' }
  });
