import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { normalizeValue } from 'normalizers';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import ListContainer from '../components/ListContainer';

const InstallmentsContainer = ({ children }) => {
  const {
    installmentsTitle,
    totalOfInstallments,
    totalWithInstallments
  } = useSelector(selectors.getDetailsData);

  return (
    <ListContainer
      title={
        <>
          {installmentsTitle}
          {totalOfInstallments > 1 && (
            <span>Total: {normalizeValue(totalWithInstallments)}</span>
          )}
        </>
      }
    >
      {children}
    </ListContainer>
  );
};

InstallmentsContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default InstallmentsContainer;
