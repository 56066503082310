import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Field,
  Form,
  reduxForm,
  getFormValues,
  SubmissionError
} from 'redux-form';
import _ from 'lodash';
import Loading from 'react-loading';

import onSignUpSuccess from 'actions/sign-up-app';
import { saveClient } from 'api/sign-up-app';
import { railsContextSelector } from 'selectors/rails-context';
import RenderField from 'components/Form/RenderField';
import RenderDropdownListCallback from 'components/Form/RenderDropdownListCallback';
import signUpActions from 'store/ducks/signUp';
import getClientsBigboostActions from 'store/ducks/getClientBigboost';
import validateJuristicSignUpApp from 'validations/ValidateJuristicSignUpApp';
import TaxPlans from 'components/SignUpApp/Shared/TaxPlans';
import PosOptions from 'components/SignUpApp/Shared/PosOptions';
import PosSplitOptions from 'components/SignUpApp/Shared/PosSplitOptions';
import Captcha from 'components/SignUpApp/Shared/Captcha';
import plusImg from 'images/icons/plus.svg';
import closeImg from 'images/icons/ic-close.svg';
import { currencyToNumber } from 'normalizers';

import { getRepresentativesNames } from './helpers';

class ClientAndBranchSignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      nameArray: [],
      showRepBluForm: false,
      legalRepUser: true,
      manualFill:
        location.href.split('?')[1] === 'manualfill=true' ? true : false
    };
    this.captchaRef = React.createRef();
  }

  componentWillUnmount() {
    this.props.getClientBigboostReset();
  }

  componentDidMount() {
    const { signUpLoadDepartments, partnerId, clientId } = this.props;
    this.loadTaxPlan();

    if (_.isEmpty(partnerId) && _.isEmpty(clientId)) {
      signUpLoadDepartments();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loadingBigBoost,
      bigBoostMessageError,
      change,
      bigBoostData,
      bigBoostUserData,
      bigBoostUserDataLoading,
      bigBoostUserDataError,
      signUpLoadUserBigBoost,

      clientsBigboostData,
      clientsBigboostDataLoading,
      clientsBigboostDataError
    } = this.props;

    if (
      prevProps.loadingBigBoost &&
      !loadingBigBoost &&
      !bigBoostMessageError
    ) {
      const repData = getRepresentativesNames(bigBoostData.user.name);

      change('client[name]', bigBoostData.companyName);
      change('client[business_name]', bigBoostData.businessName);

      change('client[user][name]', repData[0]?.name);
      change('client[user][cpf]', repData[0]?.cpfCnpj);

      change('client[cnae]', bigBoostData.cnae);
      change('client[cnaeList]', bigBoostData.cnaeList);
      change('client[capitalStock]', bigBoostData.capitalStock);
      change('client[cnpjStatus]', bigBoostData.cnpjStatus);

      repData[0]?.cpfCnpj && signUpLoadUserBigBoost(repData[0]?.cpfCnpj);
      this.handleBigBoostData(bigBoostData.user.name);
    }

    if (
      prevProps.bigBoostUserDataLoading &&
      !bigBoostUserDataLoading &&
      !bigBoostUserDataError
    ) {
      change('client[user][mother_name]', bigBoostUserData.motherName);
      change('client[user][nationality]', bigBoostUserData.birthCountry);
    }

    if (
      prevProps.clientsBigboostDataLoading &&
      !clientsBigboostDataLoading &&
      !clientsBigboostDataError
    ) {
      if (!this.state.showRepBluForm) {
        change('client[user][mother_name]', clientsBigboostData[0].motherName);
        change(
          'client[user][nationality]',
          clientsBigboostData[0].birthCountry
        );
      }
    }
  }

  handleBigBoostData(bigBoostData) {
    if (!bigBoostData) return;
    this.setState({ nameArray: bigBoostData });
    const { change } = this.props;
    if (_.isEmpty(bigBoostData)) {
      change('client[user][valid_representative]', false);
      return;
    }
    const filteredClient = _.uniqWith(bigBoostData, _.isEqual);
    change('client[user][valid_representative]', true);
    change('client[user][legal_representative]', true);
    change('client[representative][valid_representative]', true);
    this.setState({ nameArray: filteredClient });
  }

  loadTaxPlan(param_id = null) {
    const { signUpLoadTaxPlans, partnerId, clientId } = this.props;

    if (
      !_.isUndefined(partnerId) ||
      !_.isUndefined(clientId) ||
      !_.isNull(param_id)
    ) {
      let isEmpty = _.isUndefined(partnerId) && _.isUndefined(clientId);
      let id = partnerId || clientId || param_id;

      signUpLoadTaxPlans(isEmpty, id);
    }
  }

  submit(values) {
    const { onSuccess } = this.props;

    const params = this.buildParams(values);

    this.validateTaxPlan();

    if (!_.isEmpty(params)) {
      this.captchaRef.current.execute();

      return saveClient(params)
        .then((response) => {
          if (_.isEqual(response.status, 200)) {
            onSuccess();
          }
        })
        .catch((error) => {
          const {
            response: { data }
          } = error;

          this.setState({ error: data });
          window.scrollTo(0, 0);
        });
    }
  }

  validateTaxPlan() {
    const { tax_plans } = this.props;
    const taxPlan = _.find(tax_plans, { selected: true });

    const taxPlanAttributes = [
      'debit_rate',
      'credit_rate_1',
      'credit_rate_2',
      'credit_rate_3',
      'credit_rate_4',
      'credit_rate_5',
      'credit_rate_6',
      'credit_rate_7',
      'credit_rate_8',
      'credit_rate_9',
      'credit_rate_10',
      'credit_rate_11',
      'credit_rate_12',
      'anticipation_rate',
      'outflow_to_payment_to_bill_rate',
      'bank_transfer_rate',
      'outflow_to_transfer_to_prepaid_card_rate',
      'payment_collection_rate',
      'outflow_to_transfer_to_client_rate',
      'pos_rent_amount'
    ];

    if (taxPlan && !taxPlan.id) {
      _.each(taxPlanAttributes, (attribute) => {
        const error = {};
        error[attribute] = 'não pode ficar em branco';

        if (!taxPlan[attribute] && taxPlan[attribute] !== 0) {
          throw new SubmissionError({ client: { tax_plan: error } });
        }
      });
    }
  }

  buildParams(values) {
    let { client } = this.props;
    const { partnerId, sellerId, clientId, clientType, railsContext } =
      this.props;
    const { tax_plans } = this.props;
    let taxPlan = {};

    if (_.isEmpty(client.tax_plan)) {
      taxPlan = _.find(tax_plans, { selected: true });
    } else {
      taxPlan = client.tax_plan;
    }

    client = _.merge(client, {
      tax_plan_id: _.get(taxPlan, 'id', null)
    });

    const clientParams = _.merge(values.client, client);

    let clientKind = {};

    if (_.isEqual(clientType, 'seller')) {
      clientKind = { seller: true };
    }

    if (_.isEqual(clientType, 'distributor')) {
      clientKind = { distributor: true };
    }

    if (_.isEqual(clientType, 'shipping_company')) {
      clientKind = { shipping_company: true };
    }

    if (_.isEqual(clientType, 'branch')) {
      clientKind = { branch_company: true };
    }

    const bankAccountParams = _.merge(
      _.omit(clientParams.bank_account, ['bank']),
      {
        bank_id: _.get(clientParams, 'bank_account.bank.id'),
        nickname: _.get(clientParams, 'bank_account.owner_name'),
        cpf_cnpj: clientParams.cpf_cnpj
      }
    );

    let buildedClientParams = _.merge(
      _.omit(clientParams, [
        'main_address',
        'bank_account',
        'tax_plan',
        'user',
        'representative',
        'cnae',
        'cnaeList',
        'capitalStock',
        'cnpjStatus'
      ]),
      {
        main_address_attributes: clientParams.main_address,
        bank_accounts_attributes: { 0: bankAccountParams },
        tax_plan_attributes: _.omit(taxPlan, [
          'id',
          'partner_tax_plan',
          'selected'
        ]),
        user_clients_attributes: {
          0: { user_attributes: clientParams.user },
          1: { user_attributes: clientParams.representative }
        },
        client_id: clientId,
        current_user_email: railsContext.currentUserEmail
      }
    );

    if (!_.isNil(partnerId)) {
      buildedClientParams = _.merge(buildedClientParams, {
        inverse_clientships_attributes: [
          {
            merchant: partnerId,
            seller: sellerId
          }
        ]
      });
    } else {
      buildedClientParams = _.merge(buildedClientParams, clientKind);
    }

    buildedClientParams.sales_forecast = parseFloat(
      currencyToNumber(buildedClientParams.sales_forecast || '0,00')
    );

    return { client: buildedClientParams };
  }

  toggleClientGroupDisplay(e) {
    const parent = $(e.currentTarget).closest('[data-collapsible]');

    parent.children('.content-collapsible').toggle();
    parent.toggleClass('open closed');
  }

  resetBankAccount(bankAccount) {
    const { signUpReloadBankAccount } = this.props;
    return signUpReloadBankAccount(bankAccount);
  }

  onDepartmentChange(departmentId) {
    const { client, signUpReloadClient } = this.props;
    client.department_id = departmentId;
    signUpReloadClient(client);
    this.loadTaxPlan(departmentId);
  }

  onNameChange(user) {
    const { client, signUpReloadClient, change, signUpLoadUserBigBoost } =
      this.props;
    const clientCpfCnpj = this.state.nameArray.filter(
      (clientObj) => clientObj.name === user
    );
    change('client[user][name]', user);
    change('client[user][cpf]', clientCpfCnpj[0].cpfCnpj);

    signUpReloadClient(client);
    signUpLoadUserBigBoost(clientCpfCnpj[0].cpfCnpj);
  }

  onSaleOriginChange(value) {
    const { client, signUpReloadClient } = this.props;
    client.sale_origin = value;
    signUpReloadClient(client);
  }

  handleLegalRepUser(value) {
    this.setState({
      showRepBluForm: !value
    });

    if (value === false) {
      const { change } = this.props;
      change('client[user][legal_representative]', false);
      change('client[user][valid_representative]', true);

      change('client[representative][legal_representative]', true);
      change('client[representative][valid_representative]', false);
    }
  }

  renderLoading(color = '#e1e5ed', height = 35, width = 35) {
    return (
      <Loading
        type="spinningBubbles"
        color={color}
        delay={0}
        height={height}
        width={width}
      />
    );
  }

  renderSubmitErrors(error) {
    if (!error?.message) {
      return;
    }

    const messageTitle = error.message.split(':')[0];
    const messageBody = error.message.replace(
      'Ocorreram os seguintes erros ao salvar o cliente: A validação falhou: ',
      ''
    );

    return (
      <div className="alert-message">
        <h3 className="errors" data-testid="message-title">
          {messageTitle}:
        </h3>
        <ul className="errors">
          {_.map(messageBody.split(','), (message, i) => (
            <li key={i} data-testid={`message-item-${i}`}>
              {message}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderDepartmentsOption(department) {
    return { key: department.id, label: department.name };
  }

  renderDepartments() {
    const { departments, partnerId, clientId } = this.props;
    let content;

    if (
      _.isEmpty(partnerId) &&
      _.isEmpty(clientId) &&
      departments &&
      departments.length
    ) {
      content = (
        <div className="client-data-group">
          <h3 className="page-subtitle">
            <i className="fa fa-folder-open" /> Departamento do Cliente
          </h3>
          <div className="form-row">
            <div className="form-controls">
              <Field
                name="client[department_id]"
                component={RenderDropdownListCallback}
                options={departments.map(this.renderDepartmentsOption)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ key }) => key}
                simpleValue
                label="Selecione o departamento do Cliente"
                placeholder="Escolha uma opção"
                handleChange={(departmentId) =>
                  this.onDepartmentChange(departmentId)
                }
              />
            </div>
          </div>
        </div>
      );
    }

    return content;
  }

  renderUserForm(userType) {
    return (
      <>
        {userType === 'user' && (
          <div className="form-row">
            <Field
              component={RenderField}
              label="Nome da mãe"
              name={`client[${userType}][mother_name]`}
              type="text"
              disabled={
                userType === 'user' && !!this.props.bigBoostUserData?.motherName
              }
            />
          </div>
        )}
        {userType === 'user' && (
          <div className="form-row">
            <Field
              component={RenderField}
              label="Nacionalidade"
              name={`client[${userType}][nationality]`}
              type="text"
              disabled={
                userType === 'user' &&
                !!this.props.bigBoostUserData?.birthCountry
              }
            />
          </div>
        )}
        <div className="form-row">
          <Field
            component={RenderField}
            label="E-mail"
            name={`client[${userType}][email]`}
            type="text"
          />
        </div>
        <div className="form-row">
          <Field
            component={RenderField}
            label="Telefone"
            name={`client[${userType}][phone]`}
            type="text"
            mask="phone"
          />
        </div>
        <div className="form-row">
          <Field
            component={RenderField}
            label="Telefone 2"
            name={`client[${userType}][phone_2]`}
            type="text"
            mask="phone"
          />
          <Field
            component={RenderField}
            label="Telefone 3"
            name={`client[${userType}][phone_3]`}
            type="text"
            mask="phone"
          />
        </div>
      </>
    );
  }

  renderJuristicSignUpForm() {
    const {
      handleSubmit,
      submitting,
      client,
      tax_plans,
      signUpSetTaxPlans,
      bigBoostData,
      railsContext,
      formValues
    } = this.props;

    let { error, showRepBluForm, manualFill } = this.state;

    return (
      <Form
        data-testid="juristic-signup-form"
        className="form-fields-for"
        onSubmit={handleSubmit((values) => this.submit(values))}
        noValidate
      >
        <div className="client-data-group">
          {this.renderSubmitErrors(error)}
          {this.renderDepartments()}

          <div className="form-row">
            <Field
              component={RenderField}
              label="Zendesk Ticket ID"
              name="client[zendesk_origin_ticket_id]"
              type="number"
              mask="number"
            />
          </div>

          <div className="form-row">
            <Field
              name="client[sale_origin]"
              component={RenderDropdownListCallback}
              options={[
                {
                  name: 'CRM',
                  value: 'CRM'
                },
                {
                  name: 'Campanha Marketing',
                  value: 'Campanha Marketing'
                },
                {
                  name: 'Indicação Rep Comercial',
                  value: 'Indicação Rep Comercial'
                },
                {
                  name: 'Venda Digital',
                  value: 'Venda Digital'
                }
              ]}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ value }) => value}
              simpleValue
              label="Canal de vendas"
              placeholder="Escolha uma opção"
              handleChange={(value) => this.onSaleOriginChange(value)}
            />
          </div>

          <div className="wrap-manual_fill">
            <h3 className="page-subtitle">
              <i className="fa fa-building" /> Dados do Cliente
            </h3>

            <button
              className="button button-primary btn-manual_fill"
              onClick={() => {
                if (!manualFill) {
                  this.props.change('client[user][valid_representative]', true);
                  this.props.change('client[user][legal_representative]', true);
                }

                manualFill
                  ? (location.href = location.href.split('?')[0])
                  : (location.href = location.href + '?manualfill=true');

                this.setState({
                  manualFill: !manualFill
                });
              }}
            >
              {manualFill ? 'Buscar na BigBoost' : 'Cadastrar manualmente'}
            </button>
          </div>
          <div className="form-row">
            <Field
              ref={(input) => (this.cpf_cnpj_input = input)}
              component={RenderField}
              id="cpf_cnpj"
              label="CNPJ"
              inputClassName="cpf_cnpj"
              name="client[cpf_cnpj]"
              type="text"
              mask="cnpj"
            />
            {railsContext.currentUserId !== 'anonymous' && !manualFill && (
              <div>
                <button
                  className="button button-primary search_bigboost"
                  data-testid="bigboost-search-button"
                  onClick={() => this.getBigBoostData({ cnpjSearch: true })}
                  disabled={this.props.loadingBigBoost}
                >
                  {this.props.loadingBigBoost ? (
                    <div className="u-flex content-center">
                      {this.renderLoading('#2d3645', 16, 16)}
                    </div>
                  ) : (
                    'Consultar'
                  )}
                </button>
              </div>
            )}
          </div>

          {this.props.loadingBigBoost ? (
            <div className="u-flex content-center">
              {this.renderLoading('#2d3645', 42, 42)}
            </div>
          ) : (
            <>
              {!manualFill && (
                <div>
                  <div className="form-row">
                    <Field
                      component={RenderField}
                      id="name"
                      label="Razão social"
                      name="client[name]"
                      type="text"
                      disabled={true}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      component={RenderField}
                      id="cnae"
                      label="CNAE"
                      name="client[cnae]"
                      type="text"
                      disabled={true}
                    />
                    <ul className="cnae-list">
                      {bigBoostData?.cnaeList?.map((item, k) => {
                        return (
                          <li key={k}>
                            <b>{k + 1}.</b>{' '}
                            {`${item.cnae} - ${item.description}`}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="form-row">
                    <Field
                      component={RenderField}
                      id="capitalStock"
                      label="Capital social"
                      name="client[capitalStock]"
                      type="text"
                      disabled={true}
                    />
                  </div>
                  <div className="form-row">
                    <Field
                      component={RenderField}
                      id="cnpjStatus"
                      label="Status CNPJ"
                      name="client[cnpjStatus]"
                      type="text"
                      disabled={true}
                    />
                  </div>
                </div>
              )}
              <div className="form-row">
                <Field
                  component={RenderField}
                  id="business_name"
                  label="Nome fantasia"
                  name="client[business_name]"
                  type="text"
                />
              </div>
              <div className="form-row">
                <Field
                  component={RenderField}
                  label="Patrimônio da empresa"
                  name="client[capital_stock]"
                  type="text"
                />
              </div>
              <div className="form-row">
                <Field
                  component={RenderField}
                  label="Faturamento Anual"
                  name="client[sales_forecast]"
                  type="text"
                  mask="currency"
                />
              </div>

              <div className="client-data-group">
                <h3 className="page-subtitle">
                  <i className="fa fa-user" /> Representante legal (receita
                  federal)
                </h3>

                <>
                  {!showRepBluForm ? (
                    <>
                      <div className="form-row">
                        {this.props?.bigBoostData?.user?.name?.length <= 1 ||
                        manualFill ? (
                          <Field
                            component={RenderField}
                            label="Nome"
                            name="client[user][name]"
                            type="text"
                            disabled={
                              getRepresentativesNames(this.state.nameArray)
                                ?.length === 1
                            }
                          />
                        ) : (
                          <Field
                            name="client[user][name]"
                            component={RenderDropdownListCallback}
                            options={getRepresentativesNames(
                              this.state.nameArray
                            )}
                            getOptionLabel={(user) => user.name}
                            getOptionValue={(user) => user.name}
                            simpleValue
                            label="Nome"
                            placeholder="Escolha uma opção"
                            handleChange={(user) => this.onNameChange(user)}
                          />
                        )}
                      </div>
                      <div className="form-row">
                        <Field
                          component={RenderField}
                          id="cpf"
                          label="CPF"
                          name="client[user][cpf]"
                          type="text"
                          mask="cpf"
                          disabled={!!this.props.bigBoostData?.user?.cpf}
                        />

                        {!this.props.bigBoostData?.user?.cpf && (
                          <div>
                            <button
                              className="button button-primary search_bigboost"
                              onClick={() =>
                                this.getBigBoostData({
                                  cnpjSearch: false,
                                  cpfSearch: formValues.client?.user?.cpf
                                })
                              }
                              disabled={this.props.clientsBigboostDataLoading}
                            >
                              {this.props.clientsBigboostDataLoading ? (
                                <div className="u-flex content-center">
                                  {this.renderLoading('#2d3645', 16, 16)}
                                </div>
                              ) : (
                                'Consultar'
                              )}
                            </button>
                          </div>
                        )}
                      </div>

                      {this.renderUserForm('user')}

                      <div className="form-row">
                        <button
                          type="button"
                          className="button button--link"
                          onClick={() =>
                            this.handleLegalRepUser(this.state.showRepBluForm)
                          }
                        >
                          <img
                            alt="adicionar"
                            style={{ marginRight: '10px' }}
                            src={showRepBluForm ? closeImg : plusImg}
                            width="15"
                          />
                          {showRepBluForm
                            ? 'REMOVER REP LEGAL BLU'
                            : 'CADASTRAR OUTRO USUÁRIO'}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-row">
                        <Field
                          component={RenderField}
                          id="user_name"
                          label="Nome"
                          name="client[user][name]"
                          type="text"
                        />
                      </div>
                      <div className="form-row">
                        <Field
                          component={RenderField}
                          id="cpf"
                          label="CPF"
                          name="client[user][cpf]"
                          type="text"
                          mask="cpf"
                          disabled={!!this.props.bigBoostData?.user?.cpf}
                        />

                        {!this.props.bigBoostData?.user?.cpf && (
                          <div>
                            <button
                              className="button button-primary search_bigboost"
                              onClick={() =>
                                this.getBigBoostData({
                                  cnpjSearch: false,
                                  cpfSearch: formValues.client?.user?.cpf
                                })
                              }
                              disabled={this.props.clientsBigboostDataLoading}
                            >
                              {this.props.clientsBigboostDataLoading ? (
                                <div className="u-flex content-center">
                                  {this.renderLoading('#2d3645', 16, 16)}
                                </div>
                              ) : (
                                'Consultar'
                              )}
                            </button>
                          </div>
                        )}
                      </div>

                      {this.renderUserForm('user')}

                      <h3 className="page-subtitle">
                        <i className="fa fa-user" /> NOVO USUÁRIO BLU
                      </h3>
                      <div className="form-row">
                        <Field
                          name="client[representative][name]"
                          id="name"
                          component={RenderField}
                          label="Nome"
                          type="text"
                        />
                      </div>
                      <div className="form-row">
                        <Field
                          component={RenderField}
                          id="cpf"
                          label="CPF"
                          name="client[representative][cpf]"
                          type="text"
                          mask="cpf"
                        />
                      </div>

                      {this.renderUserForm('representative')}
                    </>
                  )}
                </>
              </div>
            </>
          )}
        </div>

        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-credit-card" /> Preferências de POS BLU{' '}
            <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            <PosOptions />
          </div>
        </div>
        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-credit-card" /> Preferências de POS SPLIT{' '}
            <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            <PosSplitOptions />
          </div>
        </div>
        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle client-data-group-title"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-certificate" /> Plano de Taxas{' '}
            <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            {tax_plans ? (
              <TaxPlans
                taxPlans={tax_plans}
                setTaxToReducer={(taxPlan) => signUpSetTaxPlans(taxPlan)}
              />
            ) : (
              this.renderLoading()
            )}
          </div>
        </div>
        <div className="form-row actions">
          <button className="button button--primary" disabled={submitting}>
            Enviar
          </button>
        </div>
        <Captcha ref={this.captchaRef} />
      </Form>
    );
  }

  getBigBoostData({ cnpjSearch = true, cpfSearch }) {
    const { railsContext } = this.props;
    if (railsContext.currentUserId === 'anonymous') return;

    const { signUpLoadBigBoost, getClientBigboost, formValues, change } =
      this.props;

    if (cnpjSearch) {
      change('client[name]', '');
      change('client[business_name]', '');

      signUpLoadBigBoost(formValues.client.cpf_cnpj);
    }

    if (cpfSearch) {
      getClientBigboost(cpfSearch);
    }
  }

  render() {
    return (
      <div className="flex-container">{this.renderJuristicSignUpForm()}</div>
    );
  }
}

ClientAndBranchSignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  client: PropTypes.object,
  tax_plans: PropTypes.array,
  departments: PropTypes.array,
  partnerId: PropTypes.string,
  sellerId: PropTypes.string,
  clientType: PropTypes.string,
  clientId: PropTypes.string,
  signUpReloadBankAccount: PropTypes.func,
  signUpReloadClient: PropTypes.func,
  signUpSetMainAddress: PropTypes.func,
  signUpSetTaxPlans: PropTypes.func,
  signUpLoadTaxPlans: PropTypes.func,
  signUpLoadDepartments: PropTypes.func,
  signUpLoadBigBoost: PropTypes.func,
  formValues: PropTypes.object,
  railsContext: PropTypes.object
};

ClientAndBranchSignUpForm.defaultProps = {
  error: {},
  submitting: false,
  invalid: false
};

const mapStateToProps = (state) => ({
  client: state.signUp.client,
  tax_plans: state.signUp.tax_plans,
  departments: state.signUp.departments,
  loadingBigBoost: state.signUp.loadingBigBoost,
  bigBoostMessageError: state.signUp.bigBoostMessageError,
  initialValues: {
    client: {
      pos_preferences_cell_operator: 'definir_na_logistica',
      ...state.signUp.client
    }
  },
  formValues: getFormValues('clientAndBranchSignUpForm')(state),
  railsContext: railsContextSelector(state),
  bigBoostData: state.signUp.bigBoostData,

  bigBoostUserData: state.signUp.bigBoostUserData,
  bigBoostUserDataLoading: state.signUp.bigBoostUserDataLoading,
  bigBoostUserDataError: state.signUp.bigBoostUserDataError,

  clientsBigboostData: state.clientsBigboost.bigBoostData,
  clientsBigboostDataLoading: state.clientsBigboost.bigBoostDataLoading,
  clientsBigboostDataError: state.clientsBigboost.bigBoostDataError
});

const mapDispatchToProps = (dispatch) => ({
  onSuccess: () => dispatch(onSignUpSuccess()),
  signUpLoadDepartments: () => dispatch(signUpActions.signUpLoadDepartments()),

  signUpLoadTaxPlans: (isPartnerIdNil, id) =>
    dispatch(signUpActions.signUpLoadTaxPlans(isPartnerIdNil, id)),
  signUpSetTaxPlans: (taxPlan) =>
    dispatch(signUpActions.signUpSetTaxPlans(taxPlan)),
  signUpSetMainAddress: (address) =>
    dispatch(signUpActions.signUpSetMainAddress(address)),
  signUpReloadBankAccount: (bankAccount) =>
    dispatch(signUpActions.signUpReloadBankAccount(bankAccount)),
  signUpReloadClient: (client) =>
    dispatch(signUpActions.signUpReloadClient(client)),
  signUpLoadBigBoost: (cpfCnpj) =>
    dispatch(signUpActions.signUpLoadBigBoost(cpfCnpj)),
  signUpLoadUserBigBoost: (cpf) =>
    dispatch(signUpActions.signUpLoadUserBigBoost(cpf)),
  getClientBigboost: (cpf) =>
    dispatch(getClientsBigboostActions.getClientBigboost(cpf)),
  getClientBigboostReset: () =>
    dispatch(getClientsBigboostActions.getClientBigboostReset())
});

const ClientAndBranchSignUpReduxForm = reduxForm({
  form: 'clientAndBranchSignUpForm',
  validate: validateJuristicSignUpApp,
  asyncChangeFields: ['cpf_cnpj'],
  shouldValidate() {
    return true;
  },
  onSubmitFail: (errors) => {
    if (!_.isEmpty(errors)) {
      const fieldKeys = [];
      const fieldNames = [];

      const errorKey = Object.keys(errors.client)[0];
      const errorValue = Object.values(errors.client)[0];

      const error = { client: {} };
      error.client[errorKey] = errorValue;

      _.mixin({
        deeply(map) {
          return function (obj, fn) {
            return map(
              _.mapValues(obj, (v) => {
                return _.isPlainObject(v) ? _.deeply(map)(v, fn) : v;
              }),
              fn
            );
          };
        }
      });

      _.deeply(_.mapKeys)(error, (value, key) => fieldKeys.push(key));

      _.each(_.reverse(fieldKeys), (value, index) => {
        const name = _.isEqual(index, 0) ? value : `[${value}]`;
        return fieldNames.push(name);
      });

      const inputError = document.querySelectorAll(
        `input[name='${fieldNames.join('')}'], select[name='${fieldNames.join(
          ''
        )}']`
      )[0];

      if (inputError) {
        inputError.focus();
      }
    }
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  ClientAndBranchSignUpReduxForm
)(ClientAndBranchSignUpForm);
