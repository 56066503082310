import React from 'react';
import PropTypes from 'prop-types';

import { Modal, Button } from '@useblu/ocean-react';
import genericError from '../../assets/ilustra-erro-generico.svg';

import { ModalStyle, Body, Img, Title, Paragraph, Footer } from './styles';

const ModalGenericError = ({ isVisible, toogleModal }) => {
  const handleToogleModal = () => {
    window.location = '/rpacadastro';
    toogleModal(false);
  };

  return (
    <>
      <Modal
        isOpen={isVisible}
        portalClassName="ocean-iso is-rpa"
        onRequestClose={handleToogleModal}
        maxWidth="sm"
        ariaHideApp={false}
      >
        <Body>
          <Img src={genericError} alt="Register" />
          <Title>Ops!</Title>
          <Paragraph>Algo deu errado. Por favor, tente novamente.</Paragraph>
        </Body>
        <Footer>
          <Button onClick={handleToogleModal}>Ok</Button>
        </Footer>
      </Modal>
      <ModalStyle />
    </>
  );
};

ModalGenericError.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toogleModal: PropTypes.func.isRequired
};

export default ModalGenericError;
