import styled from 'styled-components';

import {
  colorBrandPrimaryDeep,
  colorInterfaceDarkDown,
  colorInterfaceLightUp
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  max-width: 1080px;
  width: 60%;
  margin: 32px;
  background: #f7f9ff;
  box-shadow: 0px 4px 4px rgba(12, 13, 20, 0.08);
  border-radius: 10px;
  padding: 45px 45px 45px 112px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media screen and (max-width: 769px) {
    width: 80%;
    margin: 8px;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colorInterfaceLightUp};

  ${Container} {
    img {
      margin-left: -145px;
      margin-right: 32px;
      @media screen and (max-width: 769px) {
        width: 46px;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 150%;
      color: ${colorInterfaceDarkDown};
    }
    b {
      color: ${colorBrandPrimaryDeep};
    }

    @media screen and (max-width: 769px) {
      img {
        margin-left: -145px;
        margin-right: 32px;
      }
      p {
        font-size: 12px;
        line-height: 110%;
      }
    }
  }
`;

export const Title = styled.h1`
  font-weight: 800;
  font-size: 40px;
  line-height: 110%;
  color: ${colorBrandPrimaryDeep};
  @media screen and (max-width: 769px) {
    font-size: 24px;
    line-height: 110%;
  }
`;
