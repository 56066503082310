import React from 'react';
import { EyeOutline, GiftOutline } from '@useblu/ocean-icons-react';
import { useParams } from 'react-router-dom';
import { Button } from '@useblu/ocean-react';
import ilustra from './assets/ilustra-boleto-facil-web.svg';
import { SuggestionCardContainer, Title, Content, IconItem } from './styles';

const SuggestionCard = () => {
  const { uuid } = useParams();

  const redirect = () => {
    window.location.href = `/lojas/${uuid}/extrato/pendentes/detalhes?category=payment_collection_bank_billet&status=scheduled`;
  };

  return (
    <SuggestionCardContainer>
      <div>
        <img src={ilustra} alt="ilustra-facil" />
      </div>
      <Content>
        <Title>
          Encontre seus boletos e ganhe cashback ao pagar fornecedores
        </Title>
        <IconItem>
          <EyeOutline /> Visualize e acompanhe todos os seus boletos
        </IconItem>
        <IconItem className="second">
          <GiftOutline />
          Ao pagar boleto de fornecedores a Blu devolve parte do valor pago
        </IconItem>
        <Button type="button" variant="primary" size="sm" onClick={redirect}>
          Encontrar boletos
        </Button>
      </Content>
    </SuggestionCardContainer>
  );
};

export default SuggestionCard;
