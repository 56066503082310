import React, { useState } from 'react';
import CustomModal from 'components/Shared/CustomModal';

const CancelButton = () => {
  const { clientId, id } = railsState.railsContext;
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        className="button button--secondary button--danger"
        data-disable-with="Processando..."
        onClick={() => setModalOpen(true)}
        style={{ width: '100%' }}
        type="button"
      >
        Cancelar cobrança
      </button>
      <CustomModal
        isOpen={isModalOpen}
        title="Deseja prosseguir com essa ação?"
        actions={[
          {
            text: 'Sim',
            extraClassName: 'button--secondary',
            onClick: () => {
              window.location.href = `/lojas/${clientId}/cobrancas/${id}/cancel`;
            }
          },
          {
            text: 'Não',
            extraClassName: 'button--secondary',
            onClick: () => setModalOpen(false)
          }
        ]}
      />
    </>
  );
};

export default CancelButton;
