import styled from 'styled-components';

import {
  colorInterfaceDarkDeep,
  colorInterfaceDarkDown,
  colorBrandPrimaryPure,
  fontFamilyHighlight,
  fontFamilyBase
} from '@useblu/ocean-tokens/web/tokens';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-bottom: 8px;

  @media ${devices.notebook} {
    margin-bottom: 16px;
  }
`;

export const Title = styled.p`
  font-family: ${fontFamilyHighlight};
  font-weight: 900;
  font-size: 24px;
  line-height: 26px;
  color: ${colorInterfaceDarkDeep};

  text-align: center;

  @media ${devices.notebook} {
    font-size: 32px;
    line-height: 35px;
    color: ${colorBrandPrimaryPure};

    margin-bottom: 16px;
  }
`;

export const Paragraph = styled.p`
  font-family: ${fontFamilyBase};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colorInterfaceDarkDown};

  text-align: center;
  margin-bottom: 16px;
`;

export const EmailText = styled.span`
  font-family: ${fontFamilyBase};
  display: block;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${colorInterfaceDarkDown};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  /* margin-bottom: 16px;
   */
`;
