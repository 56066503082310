import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';

class RenderDropdownListCallback extends Component {
  constructor(props) {
    super(props);
    this.state = { selectValue: props.selectValue };
    this.updateValue = this.updateValue.bind(this);
  }

  getSimpleValueProps() {
    const { simpleValue, options, getOptionValue, input } = this.props;

    if (simpleValue) {
      return {
        value: options.filter((value) => getOptionValue(value) === input.value),
        onChange: (value) => this.updateValue(value && getOptionValue(value))
      };
    }

    return {};
  }

  updateValue(newValue) {
    const { handleChange } = this.props;
    handleChange(newValue);
    this.setState({
      selectValue: newValue
    });
  }

  render() {
    const {
      label,
      input,
      meta: { active, visited, error },
      className,
      ...rest
    } = this.props;
    const { selectValue } = this.state;

    return (
      <div className="form-controls select">
        <label htmlFor={input.name}>{label}</label>
        <div className={classNames({ 'field-with-errors': visited && error })}>
          <Select
            {...input}
            {...rest}
            className={classNames(
              {
                'react-select__redux-form--active': active && !error,
                'react-select__redux-form--error': visited && error
              },
              className
            )}
            classNamePrefix="react-select"
            value={selectValue}
            onChange={(value) => this.updateValue(value)}
            onBlur={null}
            noOptionsMessage={() => 'Nenhum resultado encontrado'}
            {...this.getSimpleValueProps()}
          />
          {visited && error && <span className="field-error">{error}</span>}
        </div>
      </div>
    );
  }
}

RenderDropdownListCallback.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  selectValue: PropTypes.string,
  simpleValue: PropTypes.bool,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  options: PropTypes.array,
  getOptionValue: PropTypes.func
};

RenderDropdownListCallback.defaultProps = {
  selectValue: '',
  simpleValue: false,
  className: null,
  isClearable: true,
  options: [],
  getOptionValue: null
};

export default RenderDropdownListCallback;
