import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '@useblu/ocean-react';
import Loading from 'react-loading';

const StyledButton = styled(Button)`
  width: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButton = ({
  onClick,
  loading,
  disabled,
  children,
  variant,
  className
}) => (
  <StyledButton
    variant={variant}
    className={className}
    disabled={loading || disabled}
    style={{ width: '148px', display: 'flex' }}
    onClick={onClick}
  >
    {loading ? (
      <Loading
        type="spinningBubbles"
        color="#ffffff"
        delay={0}
        width={17}
        height={17}
        data-testid="loading"
      />
    ) : (
      <>{children}</>
    )}
  </StyledButton>
);

ActionButton.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string
};

ActionButton.defaultProps = {
  variant: undefined,
  onClick: undefined,
  disabled: false,
  className: ''
};

export default ActionButton;
