import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { persistReducer } from 'redux-persist';

/* Old Reducers */
import {
  clients,
  clientSearch,
  clientsHistory,
  contractForm,
  paymentCollectionApp,
} from 'reducers';

import { reducer as railsContext } from './ducks/railsContext';
import { reducer as bankOptions } from './ducks/bankOptions';
import { reducer as signUp } from './ducks/signUp';
import { reducer as pendentPayments } from './ducks/pendentPayments';
import { reducer as paymentSelected } from './ducks/paymentSelected';
import { reducer as companiesSearch } from './ducks/groupCompaniesSearchList';
import { reducer as groupConfiguration } from './ducks/groupConfiguration';
import { reducer as clientSelector } from './ducks/clientSelector';
import { reducer as historyReducer } from './ducks/history';
import { reducer as paymentConfirmationReducer } from './ducks/paymentConfirmation';
import { reducer as importPaymentConfirmation } from './ducks/importPaymentConfirmation';
import { reducer as firstAccessClientInfo } from './ducks/firstAccess/clientInfo';
import { reducer as firstAccessShared } from './ducks/firstAccess/shared';
import { reducer as firstAccessUsersUpload } from './ducks/firstAccess/usersUpload';
import { reducer as firstAccessQsaAccounts } from './ducks/firstAccess/qsaAccounts';
import { reducer as firstAccessUsersContracts } from './ducks/firstAccess/usersContracts';
import { reducer as firstAccessUsersAccounts } from './ducks/firstAccess/usersAccounts';
import { reducer as permission } from './ducks/firstAccess/permission';
import { reducer as paymentsList } from './ducks/paymentsList';
import { reducer as billetCIP } from './ducks/billetCIP';
import { reducer as advancementRequest } from './ducks/advancementRequest';
import bluRatingReducer, {
  persistConfig as bluRatingPersistConfig,
} from './ducks/bluRating/reducer';
import { reducer as clientStatus } from './ducks/clientStatus';
import { reducer as modalOptIn } from './ducks/ModalsOptIn';
import bToken from './ducks/bToken/reducer';
import paymentCollectionDetails from './ducks/paymentCollectionDetails/reducer';
import { reducer as UIState } from './ducks/UIState';
import { reducer as transactionAuthorization } from './ducks/transactionAuthorizationConfirmation';
import { reducer as batchPayments } from './ducks/batchPayments';
import { reducer as clientPaymentMethods } from './ducks/clientPaymentMethods';
import { reducer as clientsBigboost } from './ducks/getClientBigboost';
import { reducer as complementaryUserInfoModal } from './ducks/ComplementaryUserInfo';
import rpaRegisterForm from './ducks/rpaRegisterForm/reducer';
import zipCode from './ducks/zipCode/reducer';
import { reducer as dashboardState } from './ducks/dashboardState';

const rootReducer = (history) =>
  combineReducers({
    // Old reducers
    clients,
    clientSearch,
    clientsHistory,
    contractForm,
    paymentCollectionDetails,
    paymentCollectionApp,
    form,
    railsContext,
    railsState,
    bankOptions,
    signUp,
    pendentPayments,
    paymentSelected,
    groupConfiguration,
    companiesSearch,
    router: connectRouter(history),
    clientSelector,
    history: historyReducer,
    paymentConfirmation: paymentConfirmationReducer,
    importPaymentConfirmation,
    bluRating: persistReducer(bluRatingPersistConfig, bluRatingReducer),
    firstAccessShared,
    firstAccessClientInfo,
    firstAccessUsersUpload,
    firstAccessQsaAccounts,
    firstAccessUsersContracts,
    firstAccessUsersAccounts,
    permission,
    paymentsList,
    billetCIP,
    advancementRequest,
    modalOptIn,
    clientStatus,
    bToken,
    UIState,
    transactionAuthorization,
    batchPayments,
    clientsBigboost,
    clientPaymentMethods,
    complementaryUserInfoModal,
    rpaRegisterForm,
    zipCode,
    dashboardState,
  });

export default rootReducer;
