import styled from 'styled-components';
import { Typography } from '@useblu/ocean-react';
import {
  colorInterfaceLightUp,
  spacingInlineXs,
  colorInterfaceDarkDown,
  spacingInlineMd,
  borderRadiusMd,
  spacingInsetMd,
  spacingInlineLg,
  spacingInlineXxs,
  colorBrandPrimaryDown,
} from '@useblu/ocean-tokens/web/tokens';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  tablet: `(max-width: 1024px)`,
};

export const SuggestionCardContainer = styled.div`
  background-color: ${colorInterfaceLightUp};
  border-radius: ${borderRadiusMd};
  margin-top: ${spacingInsetMd};
  padding: ${spacingInlineMd};
  display: flex;
  align-items: center;
  gap: ${spacingInlineLg};

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'heading4',
})`
  &&& {
    margin-bottom: ${spacingInlineXs};
    color: ${colorInterfaceDarkDown};
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const IconItem = styled.p`
  display: flex;
  align-items: center;
  gap: ${spacingInlineXxs};
  color: ${colorInterfaceDarkDown};
  margin-bottom: ${spacingInlineXxs};

  &.second {
    margin-bottom: ${spacingInlineXs};
    align-items: flex-start;
  }

  & > svg {
    color: ${colorBrandPrimaryDown};
    min-width: 24px;
  }
`;
