import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  openModalComplementaryUserInfo: [],
  closeModalComplementaryUserInfo: []
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  loading: false,
  error: false,
  errorMessage: null,

  modalIsOpen: false
};

export const open = (state) => ({
  ...state,
  modalIsOpen: true
});

export const close = (state) => ({
  ...state,
  modalIsOpen: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_MODAL_COMPLEMENTARY_USER_INFO]: open,
  [Types.CLOSE_MODAL_COMPLEMENTARY_USER_INFO]: close
});
