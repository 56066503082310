import styled from 'styled-components';

import {
  fontFamilyBase,
  colorInterfaceDarkUp,
  colorBrandPrimaryPure,
  colorInterfaceLightUp
} from '@useblu/ocean-tokens/web/tokens';

import ContainerMixin from './ContainerMixin';
import { ButtonLightMixin } from './Button';

// ATTENTION:
// This is a self contained component, it should be replaced by the dropdown
// from ocean asap.

export const Content = styled.div`
  flex-direction: column;
  align-items: stretch;
  top: calc(100% + 4px);
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 5;

  &.is-closed {
    display: none;
  }

  &.is-open {
    display: flex;
  }

  ${ContainerMixin}

  right: ${(props) => (props.position === 'right' ? '0px' : 'none')};
  left: ${(props) => (props.position === 'left' ? '0px' : 'none')};

  small {
    display: flex;
    padding: 0px 16px;
    height: 40px;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    align-items: flex-end;
    padding-bottom: 8px;
    color: ${colorInterfaceDarkUp};
  }
`;

export const DropdownMenuContainer = styled.nav`
  font-family: ${fontFamilyBase};
  padding: 0px;
  margin: 0px;
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    display: none;
  }

  > button {
    background: none;
    padding: 0px;

    .pendo-resource-center-badge-notification-bubble {
      top: 0;
      left: 4px;
    }

    &.is-open,
    &:hover,
    &:active,
    &:focus {
      > *:first-child {
        color: ${colorBrandPrimaryPure};
        background-color: ${colorInterfaceLightUp};
        path,
        rect {
          stroke: ${colorBrandPrimaryPure};
        }
      }
    }

    > *:first-child {
      ${ButtonLightMixin}
    }
  }
`;
