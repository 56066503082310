import styled from 'styled-components';

import {
  fontFamilyHighlight,
  fontFamilyBase,
  colorBrandPrimaryDown,
  colorInterfaceLightDown,
  colorInterfaceDarkDown,
  colorBrandPrimaryPure,
  fontSizeXs,
  fontSizeSm,
  fontSizeMd,
  fontSizeLg
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  padding: 32px;
  background-color: ${colorInterfaceLightDown};
`;

export const CarouselContainer = styled.div`
  max-width: 565px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeMd};
  font-weight: 300;
  text-align: center;
  color: ${colorBrandPrimaryDown};

  @media screen and (min-width: 769px) {
    font-size: ${fontSizeLg};
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardImg = styled.img`
  width: 270px;
`;

export const CardNumber = styled.p`
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeSm};
  font-weight: 900;
  text-align: center;
  color: ${colorBrandPrimaryDown};
  margin-bottom: 0px;

  @media screen and (min-width: 769px) {
    font-size: ${fontSizeLg};
  }
`;

export const CardText = styled.p`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  text-align: center;
  color: ${colorInterfaceDarkDown};

  @media screen and (min-width: 769px) {
    font-size: ${fontSizeSm};
  }
`;

export const Highlight = styled.span`
  font-weight: bold;
  color: ${colorBrandPrimaryPure};
`;
