import React, { useEffect, useState } from 'react';
import { sendScreenEvent } from '@useblu/blu-lytics';
import PropTypes from 'prop-types';

import { useInterval, useMedia } from 'react-use';
import { Formik, ErrorMessage } from 'formik';
import Loading from 'react-loading';
import { Alert, Modal } from '@useblu/ocean-react';

import Snackbar, { useSnackbar } from 'components/Shared/Snackbar';
import { resendToken, confirmToken } from '../services/signUp';

import { ResetEmailModal } from './ResetEmailModal';
import { ResetPhoneModal } from './ResetPhoneModal';

import {
  Title,
  Subtitle,
  ConfirmTokenBtn,
  WrapBtns,
  CustomForm,
  AlertWrapper,
  Danger,
  CenterButtons,
  CenteredCustomTokenInput,
  ModalButton,
} from '../devise.styles';

function ConfirmToken({ authTokenType, onSuccess }) {
  const [hasError, setHasError] = useState({ active: false, message: '' });
  const [disabledButton, setDisabledButton] = useState(60);
  const [timeBlock, setTimeBlock] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changedEmail, setChangedEmail] = useState('');
  const [changedPhone, setChangedPhone] = useState('');
  const isTokenEmail = authTokenType === 'email';

  const isMobile = useMedia('(max-width: 768px)');
  const {
    isActive: snackIsActive,
    message: snackMessage,
    type: snackType,
    openSnackBar,
  } = useSnackbar();

  const { email, phone, leadId } = JSON.parse(
    sessionStorage.getItem('auto-signup-anonymus')
  );

  const formik = {
    initialValues: {
      first: '',
      second: '',
      third: '',
      fourth: '',
    },
    onSubmit: (values) => {
      setLoading(true);

      confirmToken({
        leadId,
        token: Object.values(values).join(''),
        authTokenType,
      })
        .then((data) => {
          setLoading(false);
          openSnackBar('Código enviado com sucesso..', 'success');

          onSuccess(data?.data);
        })
        .catch((error) => {
          setLoading(false);

          setHasError({
            active: true,
            message: error?.response?.data?.message || error.message,
          });
        });
    },
  };

  function resendTokenFunc(leadId, authTokenType) {
    resendToken({ leadId, authTokenType })
      .then(() => {
        openSnackBar('Código reenviado com sucesso.', 'success');
      })
      .catch((error) => {
        setHasError({
          active: true,
          message: error?.response?.data?.message || error.message,
        });
      });
  }

  useInterval(
    () => {
      if (disabledButton !== 0) setDisabledButton(disabledButton - 1);
    },
    disabledButton ? 1000 : null
  );

  useInterval(
    () => {
      if (hasError.active)
        setHasError((prevState) => ({ ...prevState, active: false }));
    },
    hasError.active ? 180000 : null
  );

  useInterval(
    () => {
      if (timeBlock) setTimeBlock(false);
    },
    timeBlock ? 3000 : null
  );

  useEffect(() => {
    sendScreenEvent(
      authTokenType === 'email'
        ? 'register_authorization_email_view'
        : 'entry_cicle_sms_code_view'
    );
  }, []);

  return (
    <>
      {isTokenEmail ? (
        <Title>
          Precisamos validar seu <br /> e-mail, por segurança
        </Title>
      ) : (
        <Title>Agora, só precisamos validar seu celular</Title>
      )}

      {isTokenEmail ? (
        <Subtitle $authToken>
          Enviamos um código de verificação para o e-mail{'  '}
          <strong style={{ display: isMobile && 'block' }}>
            {email || changedEmail}
          </strong>
        </Subtitle>
      ) : (
        <Subtitle $authToken>
          Enviamos um código de verificação via SMS para o celular{' '}
          <strong>{phone || changedPhone}</strong>
        </Subtitle>
      )}

      {isTokenEmail ? (
        <ModalButton onClick={() => setIsModalOpen(true)}>
          Alterar email
        </ModalButton>
      ) : (
        <ModalButton onClick={() => setIsModalOpen(true)}>
          Alterar celular
        </ModalButton>
      )}

      {hasError.active && (
        <AlertWrapper>
          <Alert type="error">{hasError?.message}</Alert>
        </AlertWrapper>
      )}

      <Modal
        maxWidth="sm"
        shouldCloseOnOverlayClick={false}
        isOpen={isModalOpen}
        portalClassName="ocean-iso"
        ariaHideApp={false}
        onRequestClose={() => setIsModalOpen(false)}
      >
        {isTokenEmail ? (
          <ResetEmailModal
            toggleModal={() => setIsModalOpen(false)}
            setChangedEmail={setChangedEmail}
          />
        ) : (
          <ResetPhoneModal
            toggleModal={() => setIsModalOpen(false)}
            setChangedPhone={setChangedPhone}
          />
        )}
      </Modal>

      <Snackbar
        isActive={snackIsActive}
        message={snackMessage}
        type={snackType}
      />

      <span
        style={{
          marginBottom: '8px',
        }}
      >
        Digite o código para continuar
      </span>

      <Formik
        initialValues={formik.initialValues}
        onSubmit={formik.onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          const errors = {};
          const joinToken = Object.values(values).join('');

          if (joinToken.length <= 3) {
            errors.first = 'Campo obrigatório';
          }

          if (joinToken.length >= 4 && !/^[a-z0-9]+$/i.test(joinToken)) {
            errors.first = 'Apenas Letras e Números';
          }

          return errors;
        }}
      >
        {({
          errors,
          values,
          touched,
          handleSubmit,
          setFieldValue,
          setErrors,
        }) => {
          const isError =
            (errors.first && touched.first) ||
            (errors.second && touched.second) ||
            (errors.third && touched.third) ||
            (errors.fourth && touched.fourth);

          const hasEmptyValuesOrLessThanFourDigits =
            Object.values(values).some((val) => val === '') ||
            Object.values(values).length < 4;

          return (
            <CustomForm onSubmit={handleSubmit} autoComplete="off">
              <div className="token">
                <CenteredCustomTokenInput
                  autoFocus
                  className={isError ? 'error' : ''}
                  digitsQuantity={4}
                  onChangeToken={(tokenValues) => {
                    const userToken = tokenValues.split('');
                    setFieldValue('first', userToken[0]);
                    setFieldValue('second', userToken[1]);
                    setFieldValue('third', userToken[2]);
                    setFieldValue('fourth', userToken[3]);
                    setErrors({});
                  }}
                />
              </div>

              <Danger>
                <ErrorMessage
                  name="first"
                  render={(msg) => <span className="error-msg">{msg}</span>}
                />
              </Danger>

              <CenterButtons>
                <WrapBtns
                  className="without-margin"
                  style={{ marginTop: '24px', width: '100%' }}
                >
                  <ConfirmTokenBtn
                    type="submit"
                    tabIndex={-1}
                    disabled={hasEmptyValuesOrLessThanFourDigits}
                  >
                    {loading ? (
                      <Loading
                        type="spinningBubbles"
                        color="#e1e5ed"
                        delay={0}
                        height={35}
                        width={35}
                      />
                    ) : (
                      'Verificar código'
                    )}
                  </ConfirmTokenBtn>
                </WrapBtns>

                {disabledButton ? (
                  <ConfirmTokenBtn className="send-new-token" disabled>
                    Reenviar em{' '}
                    {new Date(disabledButton * 1000)
                      .toISOString()
                      .substr(14, 5)}
                  </ConfirmTokenBtn>
                ) : (
                  <ConfirmTokenBtn
                    tabIndex={0}
                    className="resend-token"
                    component="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setDisabledButton(60);
                      if (!timeBlock) {
                        setHasError((prevState) => ({
                          ...prevState,
                          active: false,
                        }));
                        resendTokenFunc(leadId, authTokenType);
                        setTimeBlock(true);
                      }
                    }}
                  >
                    Reenviar código
                  </ConfirmTokenBtn>
                )}
              </CenterButtons>
            </CustomForm>
          );
        }}
      </Formik>
    </>
  );
}

ConfirmToken.propTypes = {
  authTokenType: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ConfirmToken;
