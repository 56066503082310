import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@useblu/ocean-react';

const Counter = ({ resendNotification, resendError }) => {
  const [resendButtonActive, setResendButtonActive] = useState(false);
  const [counter, setCounter] = useState(59);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0) {
      setResendButtonActive(true);
    }

    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (resendError) {
      setResendButtonActive(true);
    }
  }, [resendError]);

  const resendNotificationHandle = () => {
    setResendButtonActive(false);
    setCounter(59);

    resendNotification();
  };

  return resendButtonActive ? (
    <Button variant="text" onClick={resendNotificationHandle}>
      Reenviar notificação
    </Button>
  ) : (
    `Reenviar em 00:${String(counter).padStart(2, '0')}`
  );
};

Counter.propTypes = {
  resendNotification: PropTypes.func.isRequired,
  resendError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default Counter;
