function getDomain(link, sd) {
  const subdomain = sd || false;

  let url = link.replace(/(https?:\/\/)?(www.)?/i, '');

  if (!subdomain) {
    url = url.split('.');

    url = url.slice(url.length - 2).join('.');
  }

  if (url.indexOf('/') !== -1) {
    return url.split('/')[0];
  }

  return url;
}

function parseActualParams() {
  const qs = document.location.search.replace('?', '');
  const hash = document.location.hash.replace('#', '');

  const str = `${qs}#${hash}`;

  const campaignParams = ['source', 'medium', 'campaign', 'term', 'content'];
  const regex = new RegExp(
    `(utm_(${campaignParams.join('|')})|(d|g)clid)=.*?([^&#]*|$)`,
    'gi'
  );
  const gaParams = str.match(regex);
  const params = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  };

  if (!gaParams) return params;

  gaParams.forEach((param) => {
    const split = param.split('=');
    const key = split[0];

    // eslint-disable-next-line prefer-destructuring
    params[key] = split[1];
  });

  return params;
}

function searchUTM() {
  if (!document) return '';

  const params = parseActualParams();

  if (!params.utm_source) {
    const { referrer } = document;

    params.utm_source = referrer ? getDomain(referrer) : 'direto';
  }

  return `utm_source=${params.utm_source}&utm_campaign=${params.utm_campaign}&utm_medium=${params.utm_medium}&utm_term=${params.utm_term}&utm_content=${params.utm_content}`;
}

export default searchUTM;
