import axios from 'axios';
import _ from 'lodash';

export function getClient(params) {
  const query = `
  query {
    client(uuid: "${params.uuid}") {
      uuid
      name
      cpfCnpj
      salesForecast
      businessName
      contractLoteKey
      contractImage
      socialContract
      nationality
      income
      patrimony
      documentType
      documentName
      documentOrigin
      documentPlaceEmission
      documentExpeditionDate
      mainAddress {
        city
        zipcode
        street
        number
        complement
        neighborhood
        state
        main
        posDelivery
        locked
      }
      posDeliveryAddress {
        city
        zipcode
        street
        number
        complement
        neighborhood
        state
        main
        posDelivery
        locked
      }
      officialBankAccount {
        office
        number
        ownerName
        nickname
        savingsAccountOperation
        bank {
          compensationCode
          name
        }
      }
      clientFormStepStatus(userId: "${params.userId}") {
        clientInfo
        usersUploads
        qsaAccounts
        usersContracts
        usersAccounts
      }
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function getUser(params) {
  const query = `
    query {
      user(id: "${params.id}") {
        email
        phone
        nationality
        income
        patrimony
        documentType
        documentName
        documentNumber
        documentOrigin
        documentPlaceEmission
        documentExpeditionDate
        mainAddress {
          city
          state
          street
          number
          neighborhood
          complement
          zipcode
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function getUsers(params) {
  const query = `
  query {
    users(clientUuid: "${params.uuid}") {
      id
      name
      email
      cpf
      phone
      userClients(clientUuid: "${params.uuid}") {
        createOutflowByHistory
        createOutflowToNewBankAccount
        createOutflowToPaymentBill
        createPaymentCollections
        legalRepresentative
        managePaymentCollections
        viewTransactions
        client {
          id
        }
        user {
          id
        }
      }
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function submitClient(params) {
  const { client } = params;
  const query = `
  mutation {
    updateClient(client: {
      uuid:"${_.get(params, 'uuid', '')}",
      name: "${_.get(params, 'client.name', '')}",
      cpfCnpj: "${_.get(params, 'client.cpfCnpj', '')}",
      businessName: "${_.get(params, 'client.businessName', '')}",
      mainAddress: {
        zipcode: "${_.get(client, 'mainAddress.zipcode', '')}",
        street: "${_.get(client, 'mainAddress.street', '')}",
        number: "${_.get(client, 'mainAddress.number', '')}",
        complement: "${_.get(client, 'mainAddress.complement', '')}",
        neighborhood: "${_.get(client, 'mainAddress.neighborhood', '')}",
        city: "${_.get(client, 'mainAddress.city', '')}",
        state: "${_.get(client, 'mainAddress.state', '')}",
      }, posDeliveryAddress: {
        zipcode: "${_.get(client, 'posDeliveryAddress.zipcode', '')}",
        street: "${_.get(client, 'posDeliveryAddress.street', '')}",
        number: "${_.get(client, 'posDeliveryAddress.number', '')}",
        complement: "${_.get(client, 'posDeliveryAddress.complement', '')}",
        neighborhood: "${_.get(client, 'posDeliveryAddress.neighborhood', '')}",
        city: "${_.get(client, 'posDeliveryAddress.city', '')}",
        state: "${_.get(client, 'posDeliveryAddress.state', '')}",
      }
    }) {
      mainAddress {
        city
        complement
        neighborhood
        number
        state
        street
        zipcode
      }
      posDeliveryAddress {
        city
        complement
        neighborhood
        number
        state
        street
        zipcode
      }
      name
      cpfCnpj
      businessName
    }
  }`;

  return axios.post('/graphql', { query });
}

export function sendEmailUploadContract(params) {
  const query = `
  query {
    clientContractUploadRequest(uuid: "${params.uuid}", email: "${params.email}") {
      id
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function sendContractUpload(uuid, file) {
  const query = `
  mutation {
    uploadContractClient(uuid:"${uuid}", file:${null}) {
      socialContract
    }
  }`;

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };

  const data = new FormData();
  data.append('file', file);
  data.append('query', query);

  return axios({
    method: 'post',
    url: '/graphql',
    query,
    data,
    config
  });
}

export function findAddressByZipcode(params) {
  const query = `
  query {
    addressByCep(zipcode: "${params.data}") {
      city
      zipcode
      street
      number
      complement
      neighborhood
      state
      main
      posDelivery
      locked
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function findAddressByCpfCnpj(params) {
  const query = `
  query {
    client(cpfCnpj: "${params.cpfCnpj}") {
      mainAddress {
        city
        zipcode
        street
        number
        complement
        neighborhood
        state
        main
        posDelivery
        locked
      }
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function getAssociates(params) {
  const { client } = params;

  const query = `
  query {
    associates(cpfCnpj: "${client.cpfCnpj}") {
      cpfCnpj
      bureaus
      bureausConsultedAt
      name
      addresses {
        zipcode
        street
        number
        complement
        neighborhood
        city
        state
        main
      }
      companyAssociates(cpfCnpj: "${client.cpfCnpj}") {
        id
        position
        profitParticipation
        address {
          zipcode
          street
          number
          complement
          neighborhood
          city
          state
          main
        }
      }
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function updateSignedClickSign(params) {
  const query = `
  mutation {
    updateClient(client: {
      uuid:"${_.get(params, 'client.uuid', '')}",
      cpfCnpj: "${_.get(params, 'client.cpfCnpj', '')}",
      contractAcceptedAt: "${new Date()}"
    }) {
      name
    }
  }`;

  return axios.post('/graphql', { query });
}

export function saveQsaPartner(params) {
  const { client, partner } = params;
  const query = `
  mutation {
    createAssociate(associate: {
      cpfCnpj:"${_.get(partner, 'cpfCnpj', '')}",
      name:"${_.get(partner, 'name', '')}",
      companyAssociates: {
        companyCnpj:"${_.get(client, 'cpfCnpj', '')}",
        position:"${partner.companyAssociates[0].position}",
        profitParticipation:${0}
      }
    }) {
      cpfCnpj
      bureausConsultedAt
      name
      companyAssociates {
        id
        position
        profitParticipation
        address {
          city
          complement
          main
          neighborhood
          number
          state
          street
          zipcode
        }
      }
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function updateQsaPartner(params) {
  const { client, partner } = params;

  const position = partner.companyAssociates[0].position;
  const profitParticipation =
    position.indexOf('Sócio') > -1
      ? partner.companyAssociates[0].profitParticipation
      : 0;

  const query = `
  mutation {
    updateAssociate(associate: {
      cpfCnpj:"${_.get(partner, 'cpfCnpj', '')}",
      name:"${_.get(partner, 'name', '')}",
      companyAssociates: {
        id: "${partner.companyAssociates[0].id}",
        companyCnpj:"${_.get(client, 'cpfCnpj', '')}",
        position:"${position}",
        profitParticipation:${profitParticipation}
      }
    }) {
      cpfCnpj
      bureausConsultedAt
      name
      companyAssociates {
        id
        position
        profitParticipation
        address {
          city
          complement
          main
          neighborhood
          number
          state
          street
          zipcode
        }
      }
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function updateQsaProfitParticipations(companyCnpj, qsa) {
  const profitParticipations = _.map(qsa, (partner) => ({
    associateCpfCnpj: partner.cpfCnpj,
    profitParticipation: partner.companyAssociates[0].profitParticipation
  }));

  const query = `
  mutation {
    updateProfitParticipation(companyCnpj: "${companyCnpj}", profitParticipations: [
      ${profitParticipations.map(
        (p) =>
          `{ associateCpfCnpj: "${p.associateCpfCnpj}", profitParticipation: ${p.profitParticipation} }`
      )}
    ]) {
      id
      position
      profitParticipation
    }
  }`;

  return axios.post('/graphql', { query });
}

export function deleteQsaPartner(cpfCnpj, companyCnpj) {
  const query = `
  mutation {
    destroyAssociate(cpfCnpj: "${cpfCnpj}" companyCnpj: "${companyCnpj}") {
      cpfCnpj
    }
  }`;

  return axios.post('/graphql', { query });
}

export function saveUser(params) {
  const { uuid, user } = params;

  const query = `
  mutation {
    addUser(clientUuid:"${uuid}", user: {
      email:"${user.email}",
      name:"${user.name}",
      phone:"${user.phone}",
      cpf:"${user.cpf}",
      userClient: {
        viewTransactions:${_.get(
          user.userClients[0],
          'viewTransactions',
          false
        )},
        legalRepresentative:${_.get(
          user.userClients[0],
          'legalRepresentative',
          false
        )},
        createOutflowByHistory:${_.get(
          user.userClients[0],
          'createOutflowByHistory',
          false
        )},
        createOutflowToPaymentBill:${_.get(
          user.userClients[0],
          'createOutflowToPaymentBill',
          false
        )},
        createOutflowToNewBankAccount:${_.get(
          user.userClients[0],
          'createOutflowToNewBankAccount',
          false
        )},
        createPaymentCollections:${_.get(
          user.userClients[0],
          'createPaymentCollections',
          false
        )},
        managePaymentCollections:${_.get(
          user.userClients[0],
          'managePaymentCollections',
          false
        )},
      }
    }) {
      id
      email
      name
      phone
      cpf
      userClients(clientUuid: "${uuid}") {
        createOutflowByHistory
        createOutflowToNewBankAccount
        createOutflowToPaymentBill
        createPaymentCollections
        legalRepresentative
        managePaymentCollections
        viewTransactions
        client {
          id
        }
        user {
          id
        }
      }
    }
  }`;

  return axios.post('/graphql', { query });
}

export function updateUser(params) {
  const { uuid, user } = params;

  const query = `
  mutation {
    updateUser(clientUuid:"${uuid}", user: {
      email:"${user.email}",
      name:"${user.name}",
      phone:"${user.phone}",
      cpf:"${user.cpf}",
      userClient: {
        viewTransactions:${_.get(
          user.userClients[0],
          'viewTransactions',
          false
        )},
        legalRepresentative:${_.get(
          user.userClients[0],
          'legalRepresentative',
          false
        )},
        createOutflowByHistory:${_.get(
          user.userClients[0],
          'createOutflowByHistory',
          false
        )},
        createOutflowToPaymentBill:${_.get(
          user.userClients[0],
          'createOutflowToPaymentBill',
          false
        )},
        createOutflowToNewBankAccount:${_.get(
          user.userClients[0],
          'createOutflowToNewBankAccount',
          false
        )},
        createPaymentCollections:${_.get(
          user.userClients[0],
          'createPaymentCollections',
          false
        )},
        managePaymentCollections:${_.get(
          user.userClients[0],
          'managePaymentCollections',
          false
        )},
      }
    }) {
      id
      email
      name
      cpf
      userClients(clientUuid: "${uuid}") {
        createOutflowByHistory
        createOutflowToNewBankAccount
        createOutflowToPaymentBill
        createPaymentCollections
        legalRepresentative
        managePaymentCollections
        viewTransactions
        client {
          id
        }
        user {
          id
        }
      }
    }
  }`;

  return axios.post('/graphql', { query });
}

export function updateUserInfo(params) {
  const { id, user } = params;

  const query = `
  mutation {
    updateUser(clientUuid:"${id}", user: {
      name:"${user.name}",
      cpf:"${user.cpf}",
      phone:"${user.phone}",
      email:"${user.email}",
      documentExpeditionDate:"${user.documentExpeditionDate}",
      documentOrigin:"${user.documentOrigin}",
      documentPlaceEmission:"${user.documentPlaceEmission}",
      documentType:"${user.documentType}",
      income:"${user.income}",
      patrimony:"${user.patrimony}",
      nationality:"${user.nationality}",
      mainAddress: {
        zipcode: "${user.mainAddress.zipcode}",
        street: "${user.mainAddress.street}",
        neighborhood: "${user.mainAddress.neighborhood}",
        number: "${user.mainAddress.number}",
        complement: "${user.mainAddress.complement}",
        city: "${user.mainAddress.city}",
        state: "${user.mainAddress.state}"
      }
    }, notify: false) {
      name
      cpf
      phone
      email
      documentExpeditionDate
      documentOrigin
      documentPlaceEmission
      documentType
      income
      patrimony
      nationality
      mainAddress {
        zipcode
        street
        neighborhood
        number
        complement
        city
        state
      }
    }
  }`;

  return axios.post('/graphql', { query });
}

export function deleteUser({ email, clientUuid }) {
  const query = `
  mutation {
    removeUser(email:"${email}", clientUuid: "${clientUuid}") {
      id
      email
      name
    }
  }`;

  return axios.post('/graphql', { query });
}
