import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import loadable from '@loadable/component';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from 'store';
import history from 'utils/browserHistory';

const AsyncComponent = loadable((props) => import(`components/${props.path}`), {
  cacheKey: (props) => `components/${props.path}`,
  resolveComponent: (component, { initialProps }) => () => {
    const Component = component.default;
    return <Component {...initialProps} />;
  }
});

const Main = ({ componentPath, ...rest }) => (
  <Provider store={store}>
    <PersistGate loading={<span>Carregando...</span>} persistor={persistor}>
      <Router history={history}>
        <AsyncComponent path={componentPath} initialProps={rest} />
      </Router>
    </PersistGate>
  </Provider>
);

Main.propTypes = {
  componentPath: PropTypes.string.isRequired
};

export default Main;
