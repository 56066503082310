import {
  updateEconomicGroupApi,
  loadGroupConfigurationApi
} from 'api/group-configuration';

import getCompaniesStore from 'store/sagas/clientSelector';

import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/groupConfiguration';

export function* loadGroupConfiguration() {
  try {
    const response = yield call(loadGroupConfigurationApi);

    const {
      data: {
        data: { economicGroup }
      }
    } = response;

    yield put(
      ActionCreators.loadGroupConfigurationSuccess(
        economicGroup.id,
        economicGroup.uuid,
        economicGroup.name
      )
    );
  } catch (error) {
    yield put(ActionCreators.loadGroupConfigurationFailure());
  }
}

export function* saveGroupConfiguration(params) {
  try {
    const { groupId, groupName, currentClientUuid } = params;

    if (!groupId || groupId === '0') {
      yield put(ActionCreators.saveGroupNameSuccess(groupId, '', groupName));
    } else {
      const response = yield call(updateEconomicGroupApi, groupId, groupName);
      const {
        data: {
          data: { updateEconomicGroup }
        }
      } = response;

      yield put(
        ActionCreators.saveGroupNameSuccess(
          updateEconomicGroup.id,
          updateEconomicGroup.uuid,
          updateEconomicGroup.name
        )
      );
      yield call(getCompaniesStore, { currentClientUuid });

      setTimeout(put(ActionCreators.saveGroupUpdateName()));
    }
  } catch (err) {
    yield put(ActionCreators.saveGroupNameFailure());
  }
}
