import styled from 'styled-components';

import {
  fontSizeSm,
  borderRadiusLg,
  spacingInsetSm,
  fontFamilyBase,
  fontWeightBold,
  fontSizeXxs,
  fontSizeXxxs,
  spacingInlineXs,
  spacingInlineXxs,
  fontWeightMedium,
  colorInterfaceLightUp,
  colorInterfaceDarkDown,
  colorInterfaceLightDown,
  colorInterfaceDarkDeep,
  colorInterfaceDarkUp
} from '@useblu/ocean-tokens/web/tokens';

export const PaymentListContainer = styled.div`
  border-radius: ${borderRadiusLg};
  margin: 0 ${spacingInsetSm};
  background-color: ${colorInterfaceLightUp};
  padding: ${spacingInlineXs} 0;
  max-width: 350px;

  > h1 {
    padding: 0 ${spacingInlineXs};
    color: ${colorInterfaceDarkDown};
    font-size: ${fontSizeSm};
    font-family: ${fontFamilyBase};
  }
`;

export const PaymentList = styled.div`
  > li {
    display: flex;
    padding: ${spacingInlineXs};
    justify-content: space-between;
    border-bottom: 1px solid ${colorInterfaceLightDown};
    color: ${colorInterfaceDarkDeep};
    font-size: ${fontSizeXxs};

    span {
      font-weight: ${fontWeightMedium};
    }

    p {
      font-weight: ${fontWeightBold};
    }

    &:last-of-type {
      border-width: 2px;
    }

    &.subtitle {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ${spacingInlineXxs};
      padding: ${spacingInlineXs};
      font-size: ${fontSizeXxxs};
      color: ${colorInterfaceDarkDown};
      border-bottom: 0;

      svg {
        color: ${colorInterfaceDarkUp};
      }
    }

    p {
      margin: 0;
    }
  }
`;

export const PaymentFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacingInlineXxs} ${spacingInlineXs} ${spacingInlineXs};
  gap: ${spacingInlineXs};

  span {
    color: ${colorInterfaceDarkDown};
    font-size: ${fontSizeXxxs};
  }

  div {
    h1 {
      font-size: ${fontSizeXxs};
      color: ${colorInterfaceDarkDown};
      margin: 0;
    }
    p {
      margin: 0;
      font-size: ${fontSizeSm};
      color: ${colorInterfaceDarkDeep};
    }
  }
`;
