import React, { useEffect, useState } from 'react';
import { sendScreenEvent } from '@useblu/blu-lytics';
import PropTypes from 'prop-types';

import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Loading from 'react-loading';

import { Alert } from '@useblu/ocean-react';
import isValidCnpj from 'validations/ValidateCnpj';
import { createLeadData } from '../services/signUp';

import {
  CustomInput,
  CustomForm,
  WrapInput,
  WrapBtns,
  BtnSubmit,
  AlertWrapper,
} from '../devise.styles';

const JuristicForm = ({ onSuccess, onBack, formData }) => {
  const [hasChangeValidation, enableChangeValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const formikRef = React.useRef(null);
  const [hasError, setHasError] = useState({
    active: false,
    message: '',
    validEmail: true,
  });

  const urlParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const trackingUTM = {
    utm_source: urlParams.utm_source || '',
    utm_campaign: urlParams.utm_campaign || '',
    utm_medium: urlParams.utm_medium || '',
    utm_term: urlParams.utm_term || '',
    utm_content: urlParams.utm_content || '',
  };

  const formikProps = {
    initialValues: formData,
    validationSchema: yup.object({
      cnpj: yup
        .string()
        .required('Este campo é obrigatório.')
        .test({
          name: 'isValidCnpj',
          message: 'Este CNPJ não é válido.',
          test: (value) => isValidCnpj(value?.replace(/\D/g, '')),
        }),
      email: yup
        .string()
        .transform((value) => value.trim())
        .email('Adicione um e-mail válido.')
        .required('Este campo é obrigatório.')
        .test({
          name: 'Backend Validation.',
          message: 'Formato de e-mail inválido.',
          test: () => hasError.validEmail,
        }),
    }),
    onSubmit: async (values, { validateForm }) => {
      if (!validateForm()) return;
      setLoading(true);

      createLeadData({
        cnpj: values?.cnpj?.replace(/\D/g, ''),
        email: values?.email.trim(),
        distributor: values?.distributor,
        utm: trackingUTM,
      })
        .then((data) => {
          setLoading(false);

          const userInfo = {
            cnpj: values?.cnpj?.replace(/\D/g, ''),
            email: values?.email.trim(),
            distributor: values?.distributor,
            utm: trackingUTM,
            leadId: data?.data?.lead_id,
            token: '',
          };

          sessionStorage.setItem(
            'auto-signup-anonymus',
            JSON.stringify(userInfo)
          );

          onSuccess(userInfo);
        })
        .catch((error) => {
          setLoading(false);

          const errorMessage = error?.response?.data?.message || error.message;

          setHasError((prevState) => ({
            ...prevState,
            active: true,
            message: errorMessage,
          }));

          if (
            errorMessage === 'Já existe um usuário com um dos dados informados.'
          ) {
            sendScreenEvent('register_user_already_exists_view');
          }
        });
    },
  };

  useEffect(() => {
    sendScreenEvent('register_user_view');
  }, []);

  return (
    <>
      {hasError.active && (
        <AlertWrapper>
          <Alert type="warning">{hasError.message}</Alert>
        </AlertWrapper>
      )}

      <Formik
        {...formikProps}
        validateOnChange={hasChangeValidation}
        validateOnBlur={false}
        innerRef={formikRef}
      >
        {({ errors, touched, values, handleSubmit, handleChange }) => (
          <CustomForm
            onSubmit={(e) => {
              enableChangeValidation(true);
              handleSubmit(e);
            }}
          >
            <WrapInput>
              <CustomInput
                id="cnpj"
                name="cnpj"
                mask="cnpj"
                label="CNPJ da sua empresa"
                data-testid="cnpj"
                placeholder="00.000.000/0000-00"
                value={values.cnpj}
                className={errors.cnpj && touched.cnpj ? 'error' : ''}
                tabIndex={-1}
                style={{ boxSizing: 'initial' }}
              />

              <ErrorMessage
                name="cnpj"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </WrapInput>
            <WrapInput>
              <CustomInput
                id="email"
                type="email"
                name="email"
                label="E-mail do Representante Legal"
                placeholder="exemplo@email.com.br"
                helperText="O e-mail será usado para acessar sua Conta Digital Blu"
                className={errors.email && touched.email ? 'error' : ''}
                value={values.email}
                tabIndex={-1}
                onChange={(e) => {
                  const event = {
                    target: {
                      name: 'email',
                      value: e.target.value,
                    },
                  };
                  setHasError((prevState) => ({
                    ...prevState,
                    validEmail: true,
                  }));
                  handleChange(event);
                }}
              />
              <ErrorMessage
                name="email"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </WrapInput>
            <WrapBtns rowReverse formPadding>
              <BtnSubmit
                type="submit"
                className="continuar_autocadastro_dados_empresa"
                aria-label="continuar_autocadastro_dados_empresa"
                tabIndex={-1}
              >
                {loading ? (
                  <Loading
                    type="spinningBubbles"
                    color="#e1e5ed"
                    delay={0}
                    height={35}
                    width={35}
                  />
                ) : (
                  'Continuar'
                )}
              </BtnSubmit>

              <BtnSubmit
                className="voltar_autocadastro_dados_empresa"
                aria-label="voltar_autocadastro_dados_empresa"
                onClick={(e) => {
                  e.preventDefault();
                  onBack();
                }}
                variant="secondary"
                tabIndex={-1}
              >
                Voltar
              </BtnSubmit>
            </WrapBtns>
          </CustomForm>
        )}
      </Formik>
    </>
  );
};

JuristicForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
};

export default JuristicForm;
