import {
  loadCompaniesApi,
  sendRequestCompanyApi,
  removeGroupCompanyApi,
  saveGroupConfigurationApi,
  addAllEconomicGroupClientsApi
} from 'api/group-configuration';
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';

import getCompaniesStore from 'store/sagas/clientSelector';
import ActionCreators from 'store/ducks/groupCompaniesSearchList';
import ActionCreatorsGroupConfig from 'store/ducks/groupConfiguration';
import ActionCreatorsClientSelector from 'store/ducks/clientSelector';

export function* loadCompanies(params) {
  try {
    let { groupId } = params;

    if (!groupId || groupId === '0') {
      groupId = '';
    }

    const response = yield call(loadCompaniesApi, groupId);

    const {
      data: {
        economicGroup: { availableClients, name }
      }
    } = response.data;

    yield put(ActionCreators.companiesSuccess(availableClients, name));
  } catch (err) {
    yield put(ActionCreators.companiesFailure());
  }
}

export function* sendRequestCompany(params) {
  try {
    if (params.groupId && params.groupId !== '0') {
      yield call(sendRequestCompanyApi, params.groupId, params.companyId);

      yield put(ActionCreators.sendRequestCompanySuccess(params.companyId));

      yield put(
        ActionCreatorsClientSelector.updateClientSelectorEconomicGroup(
          params.companyUuid
        )
      );
    } else {
      const companies = yield select((state) => state.companiesSearch.items);
      const groupName = yield select(
        (state) => state.groupConfiguration.groupName
      );

      const companiesGroup = _.filter(companies, { inGroup: true });

      if (companiesGroup.length === 1) {
        const response = yield call(saveGroupConfigurationApi, groupName, [
          params.companyId
        ]);
        const {
          data: { createEconomicGroup }
        } = response.data;

        yield put(
          ActionCreatorsGroupConfig.saveGroupNameSuccess(
            createEconomicGroup.id,
            createEconomicGroup.uuid,
            createEconomicGroup.name
          )
        );
        yield call(getCompaniesStore, params.currentClientUuid);
        yield put(ActionCreators.sendRequestCompanySuccess(params.companyId));

        setTimeout(put(ActionCreatorsGroupConfig.saveGroupUpdateName()));
      }
    }
  } catch (err) {
    yield put(ActionCreators.sendRequestCompanyFailure(params.companyId));
  }
}

export function* removeGroupCompany(params) {
  try {
    if (params.groupId && params.groupId !== '0') {
      const response = yield call(
        removeGroupCompanyApi,
        params.groupId,
        params.companyId
      );

      const { errors } = response.data;

      if (errors && errors.length > 0) {
        if (errors[0].message.includes('transações pendentes de confirmação'))
          throw new TypeError(errors[0].message);

        throw new Error(errors[0].message);
      }

      yield put(ActionCreators.removeGroupCompanySuccess(params.companyId));

      yield put(
        ActionCreatorsClientSelector.updateClientSelectorEconomicGroup(
          params.companyUuid
        )
      );
    }
  } catch (err) {
    if (err.name === 'TypeError') {
      yield put(
        ActionCreators.removeGroupCompanyFailureWithModal(
          params.companyId,
          err.message
        )
      );
    } else {
      yield put(ActionCreators.removeGroupCompanyFailure(params.companyId));
    }
  }
}

export function* addAllEconomicGroupClient(params) {
  try {
    if (!params.groupId || params.groupId === '0') {
      const groupName = yield select(
        (state) => state.groupConfiguration.groupName
      );
      const items = yield select((state) => state.companiesSearch.items);

      const ids = [];

      _.forEach(items, (item) => {
        if (item.client.currentBalance >= 0) ids.push(item.client.id);
      });

      const response = yield call(saveGroupConfigurationApi, groupName, ids);

      const {
        data: {
          createEconomicGroup: { id, uuid, name }
        }
      } = response.data;

      yield put(ActionCreatorsGroupConfig.saveGroupNameSuccess(id, uuid, name));
      yield put(ActionCreators.addAllEconomicGroupClientsSuccess());

      setTimeout(put(ActionCreatorsGroupConfig.saveGroupUpdateName()));
    } else {
      yield call(addAllEconomicGroupClientsApi, params.groupId);

      yield put(ActionCreators.addAllEconomicGroupClientsSuccess());
    }

    yield call(getCompaniesStore, params.currentClientUuid);
  } catch (err) {
    yield put(ActionCreators.addAllEconomicGroupClientsFailure());
  }
}
