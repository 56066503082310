import React from 'react';

const TopBar = () => (
  <div className="main-topbar">
    <div>
      <i data-tip="Visa" className="main-topbar__ico-visa" />
      <i data-tip="Mastercard" className="main-topbar__ico-mastercard" />
      <i data-tip="+" className="main-topbar__ico-plus" />
      <i data-tip="Elo" className="main-topbar__ico-elo" />
      <i data-tip="Hipercard" className="main-topbar__ico-hypercard" />
    </div>
    <span>Agora a BLU também tem ELO e Hipercard!</span>
  </div>
);

export default TopBar;
