import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types, Creators } = createActions({
  permissionCheck: null,
  permissionShow: ['admin', 'position']
});

export { Types };
export default Creators;

/* Initial State */
const INITIAL_STATE = {
  admin: false,
  position: null
};

/* Reducers */

export const check = (state) => ({
  ...state
});

export const show = (state, action) => ({
  ...state,
  admin: action.admin,
  position: action.position
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PERMISSION_CHECK]: check,
  [Types.PERMISSION_SHOW]: show
});
