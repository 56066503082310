import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from 'store/ducks/bluRating';

import ScoreCardLayout from '../ScoreCardLayout';
import {
  Summary,
  SummaryDetailsTitle,
  SummaryDetailsDescription,
  SummaryIcon,
  Footer,
  FetchedAt,
  Wrapper
} from './ScoreCardInitialStyles';
import ScoreCardInitialLoading from './ScoreCardInitialLoading';
import ScoreCardInitialError from './ScoreCardInitialError';

import IconBronze from '../images/icon-bronze.svg';
import IconDefault from '../images/icon-default.svg';
import IconDiamond from '../images/icon-diamond.svg';
import IconGold from '../images/icon-gold.svg';
import IconSilver from '../images/icon-silver.svg';

const icons = {
  default: IconDefault,
  bronze: IconBronze,
  silver: IconSilver,
  gold: IconGold,
  diamond: IconDiamond
};

const ScoreCardInitial = ({ authToken, clientCpfCnpj, newLayout }) => {
  const dispatch = useDispatch();
  const loading = useSelector(selectors.isLoading);
  const error = useSelector(selectors.getError);
  const outdate = useSelector(selectors.isOutdate(clientCpfCnpj));
  const data = useSelector(selectors.getRatingByClient(clientCpfCnpj));

  const getDate = (format) => moment(data.fetchedAt).format(format);

  const fetchData = useCallback(() => {
    dispatch(actions.fetchRatingByClientRequest(clientCpfCnpj, authToken));
  }, [clientCpfCnpj, authToken, dispatch]);

  useEffect(() => {
    if (outdate && !loading && !error) fetchData();
  }, [error, fetchData, loading, outdate]);

  if (error) {
    return <ScoreCardInitialError newLayout={newLayout} retryFn={fetchData} />;
  }

  if (loading || !data) {
    return <ScoreCardInitialLoading newLayout={newLayout} />;
  }

  const icon = icons[data.relationshipLevel.rating.name];

  if (data?.relationshipLevel.rating.name === 'blocked') return null;

  return (
    <ScoreCardLayout newLayout={newLayout}>
      <Wrapper>
        <Summary>
          <div>
            <SummaryDetailsTitle
              style={{ color: data.relationshipLevel.rating.hexColor }}
            >
              {data.relationshipLevel.rating.description}{' '}
              {data.relationshipLevel.rating.value}
            </SummaryDetailsTitle>
            <SummaryDetailsDescription
              dangerouslySetInnerHTML={{
                __html: data.shortMessage.firstPerson
              }}
              newLayout={newLayout}
            />
          </div>
          {!!icon && (
            <SummaryIcon>
              <img src={icon} alt={data.relationshipLevel.rating.description} />
            </SummaryIcon>
          )}
        </Summary>
        <Footer newLayout={newLayout}>
          {!!data.fetchedAt && (
            <FetchedAt>
              Atualizado em
              <br />
              {getDate('DD/MM/YYYY')}
              {' às '}
              {getDate('HH:mm')}
            </FetchedAt>
          )}
        </Footer>
      </Wrapper>
    </ScoreCardLayout>
  );
};

ScoreCardInitial.defaultProps = {
  newLayout: 'light'
};

ScoreCardInitial.propTypes = {
  authToken: PropTypes.string.isRequired,
  clientCpfCnpj: PropTypes.string.isRequired,
  newLayout: PropTypes.string
};

export default ScoreCardInitial;
