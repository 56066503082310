import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const Summary = styled.summary`
  display: flex;
  justify-content: space-between;
  padding: 0px;
  margin-left: 21px;
  margin-right: 21px;
`;

export const SummaryDetailsTitle = styled.h2`
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  color: #eaa03b;
  margin: 0px;
`;

export const SummaryDetailsDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #343e4f;
  margin: 0px;

  ${({ newLayout }) =>
    newLayout === 'dark' &&
    `
    color: #FFF;
  `}
`;

export const SummaryIcon = styled.figure`
  margin: 0px;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f4f4f4;
  height: 60px;
  padding-left: 21px;
  padding-right: 23px;
  margin-top: 20px;

  ${({ newLayout }) =>
    newLayout === 'dark' &&
    `
    border-top-color: #343e50;
  `}
`;

export const FetchedAt = styled.small`
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: #79869c;
`;

export const ActionButton = styled.button`
  background: #0033f5;
  border-radius: 80px;
  min-height: 34px;
  padding: 0px 12px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  color: #ffffff;
  margin-bottom: 20px;
`;
