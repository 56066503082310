import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Typography } from '@useblu/ocean-react';

import {
  TechnicalProblemsContent,
  IlustrationLimitContainer,
  TechnicalProblemsFooter,
} from './styles';

import illustraBadRequest from './images/Illustra-bad-request.svg';

const TechnicalProblemsModal = ({ isOpen, onClose }) => {
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);

  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  const handleCloseModal = () => {
    setModalIsOpen(false);
    onClose();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      ariaHideApp={false}
      portalClassName="ocean-iso"
      maxWidth="sm"
      onRequestClose={handleCloseModal}
    >
      <>
        <TechnicalProblemsContent>
          <IlustrationLimitContainer>
            <img src={illustraBadRequest} alt="problemas técnicos" />
          </IlustrationLimitContainer>
          <div className="texts-content">
            <Typography variant="heading2">
              Estamos com problemas técnicos
            </Typography>
            <Typography variant="paragraph">
              Nenhum valor foi debitado de sua conta. Tente novamente, se o
              problema persistir entre em contato através de nossa central de
              relacionamento.
            </Typography>
          </div>
        </TechnicalProblemsContent>
        <TechnicalProblemsFooter>
          <Button
            variant="primary"
            onClick={handleCloseModal}
            data-testid="valueEdit"
          >
            Entendi
          </Button>
        </TechnicalProblemsFooter>
      </>
    </Modal>
  );
};

TechnicalProblemsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TechnicalProblemsModal;
