import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

export class SignUpTabs extends Component {
  constructor(props) {
    super(props);
    this.state = { showTabs: 'true', pathName: '' };
  }

  componentDidMount() {
    const { location } = this.props;
    const values = qs.parse(location.search, { ignoreQueryPrefix: true });
    const showTabs = _.isNil(values.tabs) ? 'true' : values.tabs;
    this.setStateValues(showTabs);
  }

  setStateValues(showTabs) {
    this.setState({
      showTabs,
      pathName: location.pathname
    });
  }

  renderTabs() {
    const { showTabs, pathName } = this.state;
    let content;
    const filteredPath = pathName
      .split('/')
      .filter((path) => !path.match(/cadastro/));

    if (_.isEqual(showTabs, 'true')) {
      content = (
        <div className="u-flex">
          <NavLink
            className="sales-navigation-tab"
            to={`${filteredPath.join('/')}/cadastro-pessoa-juridica`}
          >
            Cadastro de Pessoa Jurídica
          </NavLink>
          <NavLink
            className="sales-navigation-tab"
            to={`${filteredPath.join('/')}/cadastro-pessoa-fisica`}
          >
            Cadastro de Pessoa Física
          </NavLink>
        </div>
      );
    }

    return content;
  }

  render() {
    return <div className="sales-navigation">{this.renderTabs()}</div>;
  }
}

SignUpTabs.propTypes = {
  location: PropTypes.object.isRequired
};

export default SignUpTabs;
