import Masker from 'vanilla-masker';

export function usToBr(value) {
  return Number.parseFloat(value.replace(/\./g, '').replace(',', '.')).toFixed(
    2
  );
}

function numberToString(value) {
  return typeof value === 'string' ? value : value.toString();
}

function stringToNumber(value) {
  return typeof value === 'string' ? Number.parseFloat(value) : value;
}

export function valueSign(value) {
  if (stringToNumber(value) === 0) return '';

  return Math.sign(stringToNumber(value)) === -1 ? '-' : '+';
}

export function normalizeCurrency(value = '0.00', isAbsolute) {
  const newValue = numberToString(value.toFixed(2)).padEnd(2, '0');

  const currency = Masker.toMoney(newValue, {
    separator: ',',
    delimiter: '.',
    unit: '',
  });

  if (isAbsolute) return currency;

  return `${valueSign(value)} R$ ${currency}`;
}

export const formatPrice = (price) =>
  new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(price || 0);
