import styled from 'styled-components';
import { Typography } from '@useblu/ocean-react';
import {
  spacingInlineXxs,
  spacingInlineXs,
  spacingInlineSm,
  colorInterfaceDarkPure,
  colorInterfaceDarkDown,
  spacingInlineXxxl,
  spacingInlineXxxs,
  fontSizeLg,
  fontSizeMd,
  colorBrandPrimaryPure,
  colorInterfaceLightUp,
  fontSizeSm
} from '@useblu/ocean-tokens/web/tokens';

const mediaScreen = {
  xs: `@media (max-width: 480px)`,
  sm: `@media (min-width: 600px)`,
  md: `@media (min-width: 992px)`,
  lg: `@media (min-width: 993px)`
};

export const Wrapper = styled.section`
  background: #fff;
  position: relative;
  display: flex;
  align-items: center;

  ${mediaScreen.xs} {
    img {
      display: block;
      margin: 50px auto;
    }
  }
`;

export const Tag = styled.div`
  background: ${colorInterfaceLightUp};
  border-radius: ${spacingInlineXxxs};
  padding: ${spacingInlineXxs} ${spacingInlineXs};
  margin: ${spacingInlineXxxl} auto ${spacingInlineXxxs} auto;

  p {
    color: ${colorBrandPrimaryPure} !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    letter-spacing: 0.24em;
  }

  ${mediaScreen.xs} {
    margin-bottom: 20px;
  }

  ${mediaScreen.sm} {
    margin: 0 0 ${spacingInlineSm} 0;
    border-radius: ${spacingInlineXxs};
  }

  ${mediaScreen.md} {
    margin: ${spacingInlineXxxl} 0 ${spacingInlineXxxs} 0;
  }
`;

export const Title = styled(Typography)`
  width: 100%;
  color: ${colorInterfaceDarkPure} !important;
  line-height: 100% !important;
  text-align: center;

  ${mediaScreen.sm} {
    text-align: left;
    font-size: ${fontSizeLg} !important;
  }
`;

export const Description = styled(Typography).attrs(() => ({
  variant: 'heading2'
}))`
  &&& {
    width: 100%;
    position: relative;
    margin-top: ${spacingInlineSm};
    color: ${colorInterfaceDarkDown};
    text-align: left;
    font-weight: 300;
    font-size: ${fontSizeMd};
    line-height: 26px;

    ${mediaScreen.xs} {
      text-align: center;
      padding: 0 ${spacingInlineSm};
      font-size: ${fontSizeSm};
    }
  }
`;
