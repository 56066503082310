import { all, put, call, takeLatest, delay } from 'redux-saga/effects';
import humps from 'lodash-humps';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';
import {
  createRpaRegisterForm,
  fetchRpaRegisterData
} from 'api/rpa-register-form';

import actions, { types } from './actions';
import { WITHOUT_FATHER_NAME_REGISTERED } from './selectors';

const snakes = createHumps(snakeCase);

const mapformRpaData = ({ ...data }) => {
  const updatedData = {
    ...data,
    personalIdNumber: data?.personalIdNumber?.replace(/[ ().,*-]/g, ''),
    fatherName: data?.noFatherName
      ? WITHOUT_FATHER_NAME_REGISTERED
      : data?.fatherName
  };

  delete updatedData.email;
  delete updatedData.noFatherName;

  const snakedAttributes = snakes(updatedData);

  const checkArr = updatedData.verifyAttributes;

  const onlyDataToVerify = checkArr.reduce(
    (prev, curr, idx) => ({
      [checkArr[idx]]: snakedAttributes[checkArr[idx]],
      ...prev
    }),
    {}
  );

  const formatted = {
    id: updatedData.rpaCpf,
    seller: {
      ...onlyDataToVerify,
      verify_attributes: [...updatedData.verifyAttributes]
    }
  };
  return formatted;
};

function* watchFetchRpaRegisterDataRequest() {
  try {
    const [response] = yield all([call(fetchRpaRegisterData), delay(1000)]);
    yield put(actions.fetchRpaRegisterDataSuccess(humps(response.data)));
  } catch (error) {
    let statusCode = 500;
    let errorMsg = 'Algo deu errado ao carregar os seus dados.';

    if (error.response) {
      statusCode = error.response.status;
      errorMsg = error.response.data.message;
    }
    yield put(
      actions.fetchRpaRegisterDataFailure({
        statusCode,
        errorMsg
      })
    );
  }
}

function* watchCreateRpaRegisterFormRequest({ data, callback }) {
  try {
    const dataMaped = mapformRpaData(data);
    const [response] = yield all([
      call(createRpaRegisterForm, dataMaped),
      delay(1000)
    ]);
    yield put(actions.createRpaRegisterFormSuccess(humps(response.data)));
    if (callback)
      callback({
        isError: false,
        statusCode: 200,
        errorMsg: null
      });
  } catch (error) {
    let statusCode = 500;
    let errorMsg = 'Algo deu errado ao salvar os dados desse formulário.';

    if (error.response) {
      statusCode = error.response.status;
      errorMsg = error.response.data.message;
    }
    yield put(
      actions.createRpaRegisterFormFailure({
        statusCode,
        errorMsg
      })
    );
    if (callback)
      callback({
        isError: true,
        statusCode,
        errorMsg
      });
  }
}

export default [
  takeLatest(
    types.FETCH_RPA_REGISTER_DATA_REQUEST,
    watchFetchRpaRegisterDataRequest
  ),
  takeLatest(
    types.CREATE_RPA_REGISTER_FORM_REQUEST,
    watchCreateRpaRegisterFormRequest
  )
];
