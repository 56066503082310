import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'utils/moment.pt-br';
import MomentLocaleUtils from 'react-day-picker/moment';
import DayPicker, { DateUtils } from 'react-day-picker';

const defaultFormat = 'DD/MM/YYYY';

class DateRange extends Component {
  state = {
    showDayPicker: false,
    isSelectingLastDay: false,
    calendarQuatity: 2
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const { calendarQuatity } = this.state;
    const windowWidth = document.documentElement.clientWidth;

    if (calendarQuatity === 2 && windowWidth <= 768) {
      this.setState({ calendarQuatity: 1 });
    } else if (calendarQuatity === 1 && windowWidth > 768) {
      this.setState({ calendarQuatity: 2 });
    }
  };

  handleDayMouseEnter = (day) => {
    const { onSelect, values } = this.props;
    const { isSelectingLastDay } = this.state;

    const fromDate = moment(values.from, defaultFormat, true).toDate();
    const formattedDay = moment(day).format(defaultFormat);

    if (!isSelectingLastDay || (values.from && day < fromDate)) return;

    onSelect({ from: values.from, to: formattedDay });
  };

  handleDayClick = (day) => {
    const { onSelect, values } = this.props;
    const { isSelectingLastDay } = this.state;

    const formattedDay = moment(day).format(defaultFormat);
    const fromDate = moment(values.from, defaultFormat, true).toDate();

    if (isSelectingLastDay) {
      if (day < fromDate) {
        onSelect({ from: formattedDay, to: '' });
      } else {
        this.setState({ isSelectingLastDay: false, showDayPicker: false });
        onSelect({ from: values.from, to: formattedDay });
      }
    } else {
      this.setState({ isSelectingLastDay: true });
      onSelect({ from: formattedDay, to: '' });
    }
  };

  createHandleToggleClick = (target) => () => {
    this.setState((prevState) => ({
      ...prevState,
      showDayPicker: !prevState.showDayPicker,
      isSelectingLastDay: target === 'to'
    }));
  };

  disabledDays = (day) => {
    const { values, enabledRange } = this.props;
    const { isSelectingLastDay } = this.state;
    const fromDate = moment(values.from, defaultFormat, true).toDate();
    const fromLastDate = moment(values.from, defaultFormat, true)
      .add(enabledRange || 1, 'days')
      .toDate();

    return (
      DateUtils.isSameDay(day) ||
      (isSelectingLastDay && day < fromDate) ||
      (isSelectingLastDay && enabledRange !== null && day > fromLastDate)
    );
  };

  render() {
    const { names, values } = this.props;
    const { showDayPicker, calendarQuatity } = this.state;

    const fromDate = moment(values.from, defaultFormat, true).toDate();
    const toDate = moment(values.to, defaultFormat, true).toDate();

    return (
      <div className="form-row">
        <div className="form-controls">
          <label htmlFor="start-date">De:</label>
          <input
            id="start-date"
            type="text"
            value={values.from}
            className="date-field"
            name={names.from}
            placeholder="Escolha a data de início"
            readOnly
            onClick={this.createHandleToggleClick('from')}
          />
        </div>

        <div className="form-controls">
          <label htmlFor="end-date">Até:</label>
          <input
            id="end-date"
            type="text"
            value={values.to}
            name={names.to}
            className="date-field"
            placeholder="Escolha a data de fim"
            readOnly
            onClick={this.createHandleToggleClick('to')}
          />
        </div>

        {showDayPicker && (
          <DayPicker
            numberOfMonths={calendarQuatity}
            enableOutsideDays
            className="DayPicker--ranged"
            locale="pt-BR"
            localeUtils={MomentLocaleUtils}
            selectedDays={(day) =>
              DateUtils.isDayInRange(day, {
                from: fromDate,
                to: toDate
              })
            }
            onDayClick={(day) => this.handleDayClick(day)}
            disabledDays={(day) => this.disabledDays(day)}
            onDayMouseEnter={(day) => this.handleDayMouseEnter(day)}
            modifiers={{
              from: (day) => DateUtils.isSameDay(day, fromDate),
              to: (day) => DateUtils.isSameDay(day, toDate)
            }}
          />
        )}
      </div>
    );
  }
}

DateRange.propTypes = {
  names: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }),
  enabledRange: PropTypes.number,
  values: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired,
  onSelect: PropTypes.func.isRequired
};

DateRange.defaultProps = {
  names: { from: 'from', to: 'to' },
  enabledRange: null
};

export default DateRange;
