/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Drawer, Typography } from '@useblu/ocean-react';
import { normalizeMoney } from '@useblu/utils';
import { normalizeSeparator } from 'normalizers';
import * as S from './styles';
import useAdvancementSimulationDetailsDrawer from './hooks/useAdvancementSimulationDetailsDrawer';

const AdvancementSimulationDetailsDrawer = ({ balance }) => {
  const { isDrawerOpen, closeDrawer, data } =
    useAdvancementSimulationDetailsDrawer();

  return (
    <Drawer
      open={isDrawerOpen}
      onDrawerClose={closeDrawer}
      overlayClose={closeDrawer}
    >
      <S.DrawerContent>
        <S.DrawerTitle variant="heading3">
          Informação sobre valores
        </S.DrawerTitle>
        <S.DrawerValues>
          <S.DrawerValueItem>
            <Typography variant="description">Valor original</Typography>
            <S.SkeletonSpan>
              {normalizeMoney(data?.clientTransaction?.netValue)}
            </S.SkeletonSpan>
          </S.DrawerValueItem>
          <S.DrawerValuesDivider />
          <S.DrawerValueItem>
            <Typography variant="description">
              Saldo atual disponível
            </Typography>
            <S.SkeletonSpan>{normalizeMoney(balance.current)}</S.SkeletonSpan>
          </S.DrawerValueItem>
          <S.DrawerValuesDivider />
          <S.DrawerValueItem>
            <Typography variant="description">Saldo a antecipar</Typography>
            <S.SkeletonSpan>
              {normalizeMoney(data?.advancementSimulation?.advancementValue)}
            </S.SkeletonSpan>
          </S.DrawerValueItem>
          <S.DrawerValuesDivider />
          <S.DrawerValueItem>
            <Typography variant="description">Custo da antecipação</Typography>
            <S.SkeletonSpan>
              {normalizeMoney(
                data?.advancementSimulation?.advancementRateValue
              )}
            </S.SkeletonSpan>
          </S.DrawerValueItem>
          <S.DrawerValuesDivider />
          <S.DrawerValueItem>
            <Typography variant="description">
              Total a ser antecipado
            </Typography>
            <S.SkeletonSpan>
              {normalizeMoney(
                data?.advancementSimulation?.advancementTotalCost
              )}
            </S.SkeletonSpan>
          </S.DrawerValueItem>
        </S.DrawerValues>
        {data?.advancementSimulation?.advancementRateType !==
          'compound_interest' && (
          <>
            <S.Subtitle variant="heading5">Entenda o cálculo</S.Subtitle>
            <S.CalcExplanation>
              <Typography variant="description">
                Seu custo total de antecipação é igual ao valor antecipado
                multiplicado pela sua taxa efetiva, nesse caso,{' '}
                {normalizeSeparator(
                  data?.advancementSimulation?.advancementRateTotal,
                  false
                )}
                %
              </Typography>
              <S.Value>
                {`${normalizeMoney(
                  data?.advancementSimulation?.advancementTotalCost
                )} x ${normalizeSeparator(
                  data?.advancementSimulation?.advancementRateTotal,
                  false
                )}% = ${normalizeMoney(
                  data?.advancementSimulation?.advancementRateValue
                )}`}
              </S.Value>

              <Divider />
              <Typography variant="description">
                Como é calculada a sua taxa efetiva:
              </Typography>
              <div>
                <S.ExplanationTitleCaption variant="caption">
                  Usando a sua taxa de antecipação ao mês
                </S.ExplanationTitleCaption>

                <S.Value>
                  {parseFloat(data?.clientTransaction?.anticipationRate)}%
                </S.Value>

                <Typography variant="caption">
                  Sua taxa de Antecipação Pontual, calculada de acordo com os
                  seus pontos do Blu Mais
                </Typography>
              </div>
              <div>
                <S.ExplanationTitleCaption variant="caption">
                  E multiplicando pelo seu prazo médio em meses
                </S.ExplanationTitleCaption>

                <S.Value>
                  {normalizeSeparator(
                    data?.advancementSimulation?.advancementAverageTermInMonths,
                    false
                  )}
                </S.Value>

                <Typography variant="caption">
                  Prazo médio das suas vendas (equivalente a{' '}
                  {normalizeSeparator(
                    data?.advancementSimulation?.advancementAverageTerm
                  ) ||
                    normalizeSeparator(
                      data?.advancementSimulation?.advancementDesiredAverageTerm
                    )}{' '}
                  dias)
                </Typography>
              </div>
              <div>
                <S.ExplanationTitleCaption variant="caption">
                  O que resulta em
                </S.ExplanationTitleCaption>

                <S.Value>
                  {normalizeSeparator(
                    data?.advancementSimulation?.advancementRateTotal,
                    false
                  )}
                  %
                </S.Value>

                <Typography variant="caption">
                  Sua taxa efetiva de antecipação
                </Typography>
              </div>
            </S.CalcExplanation>
          </>
        )}
      </S.DrawerContent>
    </Drawer>
  );
};

AdvancementSimulationDetailsDrawer.propTypes = {
  balance: PropTypes.object.isRequired,
};

export default AdvancementSimulationDetailsDrawer;
