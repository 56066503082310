/* Core */
import React from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

/* Presentational */
import changeStep from 'actions/application';

const ChangeStepButton = ({ changeStep, children, step, ...rest }) => (
  <button {...rest} onClick={() => changeStep(step)} type="button">
    {children}
  </button>
);

ChangeStepButton.propTypes = {
  changeStep: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  children: PropTypes.string
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeStep }, dispatch);

export default connect(null, mapDispatchToProps)(ChangeStepButton);
