const initialState = {
  term: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SEARCH':
      return { term: action.term };
    default:
      return state;
  }
}
