import styled from 'styled-components';

import {
  colorComplementaryPure,
  colorBrandPrimaryPure,
  fontFamilyBase,
  colorStatusNegativePure,
  colorBrandPrimaryDown,
  colorInterfaceLightUp,
  fontFamilyHighlight,
  colorInterfaceDarkUp,
  colorInterfaceDarkDeep,
} from '@useblu/ocean-tokens/web/tokens';

const sizes = {
  mobile: `(max-width: 576px)`,
  small: `(max-width: 992px)`,
  tablet: `(max-width: 1200px)`,
};

export const WrapperPageAside = styled.div`
  display: flex;
  overflow: hidden;

  .aside-container,
  .page-container {
    width: 50%;
    height: 100vh;
  }

  .aside-container {
    max-width: 425px;

    align-items: center;
    justify-content: center;
    display: flex;

    &--color-default {
      background-color: ${colorBrandPrimaryPure};
    }

    &--color-light {
      background-color: ${colorInterfaceLightUp};
    }

    @media ${sizes.small} {
      display: none;
    }
  }

  .page-container {
    flex: 1;
    background-color: #fff;
  }

  .error-msg {
    color: ${colorStatusNegativePure};
    font-weight: 600;
    font-family: ${fontFamilyBase};
    font-size: 12px;
    line-height: 12px;
    margin-top: 4px;
  }
`;

export const LogoHome = styled.button`
  background: transparent;
  padding: 0;

  &.internal {
    display: none;

    @media ${sizes.small} {
      display: block;
      margin-bottom: 33px;
      text-align: left;
    }
  }
`;

export const WrapperContent = styled.div`
  width: 100%;
  max-width: 434px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.className === 'centerContent' ? 'center' : 'flex-start'};
  margin-top: 88px;

  @media ${sizes.mobile} {
    margin-top: 4rem;
    padding: 0 16px;
    max-width: initial;
    width: 100vw;
    justify-content: flex-start;

    &.adjust-logo {
      margin-top: 1rem;
    }
  }

  @media screen and (max-height: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => (props.align ? 'center' : 'start')};
    margin-top: ${(props) => (props.align ? '0px' : '48px')};

    > *:last-child {
      padding-bottom: ${(props) => (props.align ? '0px' : '48px')};
    }

    &.no-vertical-breakpoint {
      @media screen and (max-height: 768px) {
        display: flex;
        flex-direction: column;

        > *:last-child {
          padding-bottom: auto;
        }
      }
    }
  }
`;
export const LabelStage = styled.h3`
  font-weight: 800;
  font-family: ${fontFamilyHighlight};
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
  color: ${colorInterfaceDarkUp};
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow-y: auto;
  height: 100%;

  .ods-alert__content .ods-lnk {
    font-size: 12px;
  }

  .icon-show-password {
    cursor: pointer;
  }

  @media ${sizes.mobile} {
    justify-content: flex-start;
  }
`;

export const LogoStepper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperMarketingAside = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  .logo {
    margin-bottom: 48px;
  }

  .marketing-illustration {
    position: relative;
    min-width: 408px;
  }

  h2 {
    color: ${colorInterfaceDarkDeep};
    font-weight: 900;
    font-size: 28px;
    line-height: 26px;
    font-family: Avenir;
    font-style: normal;
  }

  p {
    color: ${colorInterfaceDarkDeep};
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }

  h3 {
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
    color: ${colorBrandPrimaryDown};
  }

  h1 {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 42px;
    line-height: 26px;
    color: ${colorBrandPrimaryPure};

    @media ${sizes.tablet} {
      margin-bottom: 142px;
    }

    @media screen and (max-height: 768px) {
      margin-bottom: 0;
    }
  }
`;

export const WrapperWithoutAside = styled.div`
  display: flex;
  justify-content: center;

  padding: 80px 0;
  min-height: 100vh;

  background-color: #ffffff;

  @media ${sizes.mobile} {
    padding: 4rem 0;
  }
`;

export const WrapperWithoutAsideContent = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 434px;

  & > img {
    width: 72px;
    height: 32px;
  }

  & > .ods-typography__description {
    margin: 24px 0 16px !important;

    font-size: 12px !important;

    & strong {
      color: ${colorComplementaryPure};
      font-weight: 400;
    }
  }

  @media ${sizes.mobile} {
    padding: 0 16px;
  }
`;
