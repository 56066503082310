import React from 'react';
import PropTypes from 'prop-types';

import { Button, Alert } from '@useblu/ocean-react';
import {
  Upload,
  CheckCircle,
  TrashOutline,
  XCircle,
  ExclamationCircleOutline
} from '@useblu/ocean-icons-react';

import {
  UploadInput,
  UploadInputTriggerCaption,
  UploadInputTriggerTitle,
  UploadInputTrigger,
  FileDescription,
  FileActions,
  FileLabel,
  FileStatus,
  FileStats,
  Loading
} from '../modal.styles';

const FilesUpload = ({ file, setFile, loading }) => {
  const [mensage, setMensage] = React.useState();
  const refInput = React.useRef(null);

  const validateFile = ({ size, type }) => {
    if (size > 20480000) {
      setMensage('Falha ao carregar, arquivo maior que 20MB.');
      return false;
    }
    if (type !== 'application/pdf') {
      setMensage('Falha ao carregar, arquivo não e um PDF.');
      return false;
    }
    setMensage();
    return true;
  };

  const onChange = (e) => {
    if (!e.target) return;

    const { files } = e.target;

    if (files.length <= 0) return;

    const [file] = files;

    setFile({ document: file, isValid: validateFile(file) });
  };

  const StatusComponent = (
    <>
      {!loading && file.isValid && !file.error && (
        <FileStats>
          <CheckCircle size={12} /> Pronto para enviar
        </FileStats>
      )}
      {!loading && !file.isValid && (
        <FileStats className="error">
          <XCircle size={12} /> {mensage}
        </FileStats>
      )}
    </>
  );

  return (
    <>
      <UploadInputTrigger
        onClick={() => {
          refInput.current.click();
        }}
      >
        <UploadInputTriggerTitle>
          <Upload size={20} />
          <span> Envie o contrato social - </span>
          <Button variant="text" component="a">
            Selecionar arquivo
          </Button>
        </UploadInputTriggerTitle>
        <UploadInputTriggerCaption>
          Formato PDF. Tamanho máximo de 20MB.
        </UploadInputTriggerCaption>
        <input
          ref={refInput}
          type="file"
          data-testid="uploader"
          accept="application/pdf"
          onChange={onChange}
        />
      </UploadInputTrigger>{' '}
      {!loading && file.isValid && file.error && (
        <Alert type="warning" icon={<ExclamationCircleOutline />}>
          Algo deu errado ao enviar o seu arquivo. Por favor, tente novamente.
          Caso o erro persista, fale com nossa Central de Atendimento ligando
          0800 326 0966 ou pelo chat.
        </Alert>
      )}
      {file.document && (
        <UploadInput>
          <FileDescription>
            <FileLabel>{file.document.name}</FileLabel>
            <FileStatus>{StatusComponent}</FileStatus>
          </FileDescription>
          <FileActions>
            {loading ? (
              <Loading>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="#EBECF5"
                    strokeWidth="4"
                  />
                  <path
                    d="M12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7362 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12"
                    stroke="#13BDBD"
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Loading>
            ) : (
              <Button
                variant="text"
                component="a"
                onClick={() => {
                  if (file) {
                    refInput.current.value = null;
                    setFile({ isValid: false });
                  }
                }}
              >
                <TrashOutline stroke="#B6B9CC" size={24} />
              </Button>
            )}
          </FileActions>
        </UploadInput>
      )}
    </>
  );
};

FilesUpload.propTypes = {
  setFile: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  loading: PropTypes.bool
};

FilesUpload.defaultProps = {
  loading: false
};

export default FilesUpload;
