import styled from 'styled-components';
import {
  colorHighlightUp,
  colorInterfaceLightDeep,
  colorStatusNegativePure,
  colorBrandPrimaryDeep
} from '@useblu/ocean-tokens/web/tokens';

const StyledCheckBoxField = styled.div`
  margin-top: 16px;

  .ods-checkbox__checkmark {
    border: 1px solid
      ${({ hasError }) =>
        hasError ? colorStatusNegativePure : colorInterfaceLightDeep};
  }
  .ods-checkbox__checkmark:hover {
    border: 1px solid
      ${({ hasError }) => (hasError ? colorHighlightUp : colorBrandPrimaryDeep)};
  }
`;

export default StyledCheckBoxField;
