import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useAsyncFn } from 'react-use';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { EyeOutline, EyeOffOutline } from '@useblu/ocean-icons-react';
import cx from 'classnames';
import * as yup from 'yup';
import Loading from 'react-loading';
import { Alert } from '@useblu/ocean-react';
import history from 'utils/browserHistory';

import InputControl from 'components/Shared/InputControl';

import { createUserPass } from '../services/signUp';

import IconValidation from './IconValidation';
import {
  Title,
  Subtitle,
  BtnSubmit,
  WrapBtns,
  AlertWrapper,
  WrapCustomInput,
  PasswordValidation
} from '../devise.styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const IconPass = (state, action) =>
  state ? (
    <EyeOutline
      className="icon-show-password"
      data-testid="eyeOn"
      color="rgba(182, 185, 204, 1)"
      onClick={() => {
        action(!state);
      }}
    />
  ) : (
    <EyeOffOutline
      className="icon-show-password"
      data-testid="eyeOff"
      color="rgba(182, 185, 204, 1)"
      onClick={() => {
        action(!state);
      }}
    />
  );

const RegisterPassword = ({ completed, isNewUser }) => {
  const query = useQuery();

  const inputRef = useRef(null);
  const [validationText, setValidationText] = useState(false);

  const [validationState, setValidation] = useState({
    eigthChar: {
      match: /.{8}/,
      message: '8 caracteres ou mais',
      state: 'untouched'
    },
    oneEspecial: {
      match: /[#?!@$%^&*-]{1}/,
      message: '1 caractere especial (exemplo: @, !, $, #)',
      state: 'untouched'
    },
    oneUppercase: {
      match: /[A-Z]{1}/,
      message: '1 letra maiúscula',
      state: 'untouched'
    },
    oneNumber: {
      match: /\d{1}/,
      message: '1 número',
      state: 'untouched'
    },
    oneLowercase: {
      match: /[a-z]{1}/,
      message: '1 letra minúscula',
      state: 'untouched'
    }
  });
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [hasError, setError] = useState(false);
  const [networkError, setNetworkError] = useState({
    error: false,
    message: ''
  });
  const [createUserPassState, createUserPassRequest] =
    useAsyncFn(createUserPass);
  const [type, setType] = useState('text');

  const setFocus = () => {
    if (inputRef.current) {
      setType((current) => (current === 'text' ? 'password' : 'text'));
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    inputRef.current.selectionStart = inputRef.current.value.length;
    inputRef.current.selectionEnd = inputRef.current.value.length;
  }, [type]);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Você não completou todos os requisitos para criação de senha.')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8})/,
        'Você não completou todos os requisitos para criação de senha.'
      )
  });

  const formik = {
    initialValues: {
      password: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validate: (values) => {
      let newState = {
        ...validationState
      };

      Object.entries(validationState).forEach(([key, validation]) => {
        newState = {
          ...newState,
          [key]: {
            ...validation,
            state: values.password.match(validation.match) ? 'valid' : 'invalid'
          }
        };
      });

      setValidation(newState);

      return false;
    },
    onSubmit: (values) => {
      if (
        validationSchema.isValidSync(values) &&
        !createUserPassState.loading
      ) {
        setValidationText(false);
        createUserPassRequest({
          user: {
            reset_password_token: query.get('reset_password_token'),
            password: values.password,
            password_confirmation: values.confirm
          }
        });
      } else {
        setValidationText(true);
      }
    }
  };

  useEffect(() => {
    if (createUserPassState?.loading) return;

    if (createUserPassState?.error) {
      setError(true);

      if (!createUserPassState?.error.response) {
        setNetworkError({
          error: true,
          message: createUserPassState?.error?.message
        });
      }
    }

    if (!createUserPassState?.value) return;

    const { data, status } = createUserPassState?.value;

    if (data?.token && status === 200) {
      if (data) {
        if (isNewUser) {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const clientType = urlParams.get('tipo-cliente');
          history.push({
            search: `?autocadastro=confirmacao-do-token&tipo-cliente=${clientType}`
          });
        }

        completed(data);
      }
    }
  }, [createUserPassState]);

  return (
    <>
      <Title>
        {isNewUser ? 'Cadastre sua senha' : 'Cadastre sua nova senha'}
      </Title>
      <Subtitle>
        <span>
          Para sua segurança, evite algo muito óbvio ou a mesma senha usada em
          outros sites.
        </span>
      </Subtitle>

      {hasError && !networkError.error && (
        <AlertWrapper>
          <Alert type="error">
            Sua nova senha deve ser diferente das senhas que você utilizou
            recentemente. Por favor informe uma nova senha.
          </Alert>
        </AlertWrapper>
      )}

      {networkError.error && !validationText && (
        <AlertWrapper>
          <Alert type="error">{networkError.message}</Alert>
        </AlertWrapper>
      )}

      {validationText && (
        <AlertWrapper>
          <Alert type="error">
            Você não completou todos os requisitos para criação de senha.
          </Alert>
        </AlertWrapper>
      )}

      <Formik {...formik}>
        {({ errors, values, touched, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <WrapCustomInput
              className={cx({
                margin: true,
                error: errors.password && touched.password
              })}
              style={{ marginBottom: '16px' }}
            >
              <InputControl
                id="password"
                name="password"
                innerRef={inputRef}
                autoFocus
                label="Senha"
                onChange={handleChange}
                type={isPassVisible ? 'text' : 'password'}
                placeholder="Digite sua nova senha"
                className={errors.password && touched.password ? 'error' : ''}
                value={values.name}
                adornment={IconPass(isPassVisible, (e) => {
                  setFocus();
                  setIsPassVisible(e);
                })}
              />
            </WrapCustomInput>

            <PasswordValidation>
              <p className="requirements">Sua senha deve conter:</p>
              {Object.entries(validationState).map(([key, validation]) => (
                <p key={key}>
                  <span style={{ marginRight: '6px' }}>
                    <IconValidation state={validation.state} />{' '}
                  </span>
                  {validation.message}
                </p>
              ))}
            </PasswordValidation>

            <WrapBtns>
              <BtnSubmit
                className={isNewUser && 'cadastrar_autocadastro_senha'}
                aria-label=""
                type="submit"
              >
                {createUserPassState.loading ? (
                  <Loading
                    type="spinningBubbles"
                    color="#e1e5ed"
                    delay={0}
                    height={35}
                    width={35}
                  />
                ) : (
                  'Cadastrar senha'
                )}
              </BtnSubmit>
            </WrapBtns>
          </form>
        )}
      </Formik>
    </>
  );
};

RegisterPassword.propTypes = {
  completed: PropTypes.func.isRequired,
  isNewUser: PropTypes.bool
};

RegisterPassword.defaultProps = {
  isNewUser: false
};

export default RegisterPassword;
