import React, { useState, useEffect, useRef } from 'react';
import { sendScreenEvent } from '@useblu/blu-lytics';
import { Formik } from 'formik';
import { EyeOutline, EyeOffOutline } from '@useblu/ocean-icons-react';
import cx from 'classnames';
import * as yup from 'yup';
import Loading from 'react-loading';
import { useSelector } from 'react-redux';
import { Alert } from '@useblu/ocean-react';

import InputControl from 'components/Shared/InputControl';
import { railsContextSelector } from 'selectors/rails-context';
import { createUser, signInToken } from '../../services/signUp';

import IconValidation from '../IconValidation';
import {
  Title,
  BtnSubmit,
  WrapBtns,
  AlertWrapper,
  WrapCustomInput,
  PasswordValidation,
} from '../../devise.styles';

const IconPass = (state, action) =>
  state ? (
    <EyeOutline
      className="icon-show-password"
      data-testid="eyeOn"
      color="rgba(182, 185, 204, 1)"
      onClick={() => {
        action(!state);
      }}
    />
  ) : (
    <EyeOffOutline
      className="icon-show-password"
      data-testid="eyeOff"
      color="rgba(182, 185, 204, 1)"
      onClick={() => {
        action(!state);
      }}
    />
  );

const CreatePassword = () => {
  const { currentUserId } = useSelector(railsContextSelector);

  const [isPassVisible, setIsPassVisible] = useState(false);
  const [validationText, setValidationText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState({
    active: false,
    message: '',
  });
  const inputRef = useRef(null);
  const [type, setType] = useState('text');
  const setFocus = () => {
    if (inputRef.current) {
      setType((current) => (current === 'text' ? 'password' : 'text'));
      inputRef.current.focus();
    }
  };
  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Você não completou todos os requisitos para criação de senha.')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-])(?=.{8})/,
        'Você não completou todos os requisitos para criação de senha.'
      ),
  });
  const { leadId } = JSON.parse(sessionStorage.getItem('auto-signup-anonymus'));

  const [validationState, setValidation] = useState({
    eigthChar: {
      match: /.{8}/,
      message: '8 caracteres ou mais',
      state: 'untouched',
    },
    oneEspecial: {
      match: /[#?!@$%^&*-]{1}/,
      message: '1 caractere especial (exemplo: @, !, $, #)',
      state: 'untouched',
    },
    oneUppercase: {
      match: /[A-Z]{1}/,
      message: '1 letra maiúscula',
      state: 'untouched',
    },
    oneNumber: {
      match: /\d{1}/,
      message: '1 número',
      state: 'untouched',
    },
    oneLowercase: {
      match: /[a-z]{1}/,
      message: '1 letra minúscula',
      state: 'untouched',
    },
  });

  const homeRedirect = (token) => {
    window.location.assign(
      `/lojas/${currentUserId}/validacao-cadastral?token=${token}`
    );
  };

  const onSuccess = (token) => {
    signInToken({ token })
      .then(() => {
        homeRedirect(token);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const formik = {
    initialValues: {
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: true,
    validate: (values) => {
      let newState = {
        ...validationState,
      };

      Object.entries(validationState).forEach(([key, validation]) => {
        newState = {
          ...newState,
          [key]: {
            ...validation,
            state: values.password.match(validation.match)
              ? 'valid'
              : 'invalid',
          },
        };
      });

      setValidation(newState);

      return false;
    },
    onSubmit: (values) => {
      if (validationSchema.isValidSync(values)) {
        setValidationText(false);
        setLoading(true);

        createUser({ leadId, passcode: values.password })
          .then((data) => {
            setLoading(false);

            onSuccess(data?.data?.password_token);
          })
          .catch((error) => {
            setLoading(false);

            setHasError({
              active: true,
              message: error?.response?.data?.message || error.message,
            });
          });
      } else {
        setValidationText(true);
      }
    },
  };

  useEffect(() => {
    inputRef.current.selectionStart = inputRef.current.value.length;
    inputRef.current.selectionEnd = inputRef.current.value.length;
  }, [type]);

  useEffect(() => {
    sendScreenEvent('register_password_view');
  }, []);

  return (
    <>
      <Title>Cadastre uma senha para acessar sua conta</Title>

      {hasError.active && (
        <AlertWrapper>
          <Alert type="error">{hasError.message}</Alert>
        </AlertWrapper>
      )}

      {validationText && (
        <AlertWrapper>
          <Alert type="error">
            Você não completou todos os requisitos para criação de senha.
          </Alert>
        </AlertWrapper>
      )}

      <Formik {...formik}>
        {({ errors, values, touched, handleSubmit, handleChange }) => (
          <form onSubmit={handleSubmit}>
            <WrapCustomInput
              className={cx({
                margin: true,
                error: errors.password && touched.password,
              })}
              style={{ marginBottom: '16px' }}
            >
              <InputControl
                id="password"
                name="password"
                innerRef={inputRef}
                autoFocus
                label="Senha"
                onChange={handleChange}
                type={isPassVisible ? 'text' : 'password'}
                placeholder="Digite sua nova senha"
                className={errors.password && touched.password ? 'error' : ''}
                value={values.name}
                adornment={IconPass(isPassVisible, (e) => {
                  setFocus();
                  setIsPassVisible(e);
                })}
              />
            </WrapCustomInput>

            <PasswordValidation>
              <p className="requirements">Sua senha deve conter:</p>
              {Object.entries(validationState).map(([key, validation]) => (
                <p key={key}>
                  <span style={{ marginRight: '6px' }}>
                    <IconValidation state={validation.state} />{' '}
                  </span>
                  {validation.message}
                </p>
              ))}
            </PasswordValidation>

            <WrapBtns>
              <BtnSubmit
                className="cadastrar_autocadastro_senha"
                aria-label=""
                type="submit"
              >
                {loading ? (
                  <Loading
                    type="spinningBubbles"
                    color="#e1e5ed"
                    delay={0}
                    height={35}
                    width={35}
                  />
                ) : (
                  'Cadastrar senha'
                )}
              </BtnSubmit>
            </WrapBtns>
          </form>
        )}
      </Formik>
    </>
  );
};

export default CreatePassword;
