import React from 'react';
import PropTypes from 'prop-types';
import { normalizeValue } from 'normalizers';
import Collapse from '../Shared/Collapse';

const UnavailableValues = ({ items, total, withCollapse }) => {
  const containerRef = React.useRef();

  return (
    <div>
      {withCollapse && (
        <div className="form-row">
          <div className="readonly-field">
            <div className="readonly-field__label">
              Valores travados por outras instituições
            </div>
            <div className="readonly-field__value">
              {normalizeValue(total)}
              <Collapse
                getContainerRef={() => containerRef.current}
                arrowColor="#f6f8fb"
                openLabel="Ver Detalhes"
              />
            </div>
          </div>
        </div>
      )}
      <div className="list__wrapper" ref={containerRef}>
        <h4 className="list__title">Lista de valores travados</h4>
        {items.map(({ acquirer, value }) => (
          <div
            className="list__item list__item-sp-between statement-detail__installment"
            key={`${acquirer}-${value}`}
          >
            <div className="list__item-left">
              <p>
                Utilizado por: <b>{acquirer}</b>
              </p>
            </div>
            <div className="list__item-right">
              <div className="list__item-with-link">
                <p>
                  <b>{normalizeValue(value)}</b>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

UnavailableValues.propTypes = {
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withCollapse: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      acquirer: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ).isRequired
};

UnavailableValues.defaultProps = {
  total: 0,
  withCollapse: false
};

export default UnavailableValues;
