/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import { Field } from 'redux-form';
import RenderField from 'components/Form/RenderField';
import _ from 'lodash';
import classNames from 'classnames';
import { normalizeCurrency } from 'normalizers';
import masker from 'vanilla-masker';
import maskInput from 'utils/masks';

class TaxPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxPlans: this.props.taxPlans,
      customTaxPlan: { selected: false }
    };
  }

  componentDidMount() {
    this.insertCustomTaxPlan();
    this.selectDefaultTaxPlan();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.insertCustomTaxPlan(nextProps);
    this.selectDefaultTaxPlan(nextProps);
  }

  selectTaxPlan(e, index) {
    const cards = $('.card.tax-plan');
    const target = $(e.currentTarget);

    if (!target.hasClass('selected')) {
      cards.removeClass('selected').find('input').attr('disabled', true);
      $(e.currentTarget)
        .addClass('selected')
        .find('input')
        .attr('disabled', false);
    }

    this.reloadSelectedTaxPlan(index);
  }

  togglePercentage(e, fieldName) {
    const percentage = e.currentTarget.checked;
    const field = $(`input[name="client[tax_plan][${fieldName}]"]`)[0];

    const mask = percentage ? 'percentage' : 'currency';
    masker(field).unMask();
    maskInput(field, mask);
  }

  reloadSelectedTaxPlan(index) {
    const { taxPlans } = this.props;

    taxPlans.map((taxPlan) => {
      if (_.isEqual(taxPlan, taxPlans[index])) {
        taxPlan.selected = true;
      } else {
        taxPlan.selected = false;
      }

      return taxPlan;
    });

    this.props.setTaxToReducer(taxPlans);
  }

  insertCustomTaxPlan(nextProps = null) {
    let taxPlans;
    if (!nextProps) {
      taxPlans = this.props.taxPlans;
    } else {
      taxPlans = nextProps.taxPlans;
    }

    const { customTaxPlan } = this.state;
    !_.find(taxPlans, customTaxPlan) && taxPlans.push(customTaxPlan);
    this.setState(taxPlans);
  }

  handleChange(e, fieldName, index) {
    const { taxPlans } = this.state;

    const value = e.currentTarget.value.replace('%', '');
    const normalizedValue = normalizeCurrency(value);
    const floatValue = parseFloat(normalizedValue.replace(',', '.'));

    taxPlans[index][fieldName] = floatValue;
    this.props.setTaxToReducer(taxPlans);
  }

  selectDefaultTaxPlan(nextProps = null) {
    let taxPlans;
    if (!nextProps) {
      taxPlans = this.props.taxPlans;
    } else {
      taxPlans = nextProps.taxPlans;
    }

    const selectedTaxPlan = _.find(
      taxPlans,
      _.matchesProperty('selected', true)
    );

    if (_.isEmpty(selectedTaxPlan)) {
      taxPlans[0].selected = true;
      this.props.setTaxToReducer(taxPlans);
    }
  }

  toggleTaxes(e) {
    e.preventDefault();
    $('.simple-taxes, .detailed-taxes').toggle();
  }

  renderCustomTaxPlan(taxPlan, index) {
    return (
      <div
        className={classNames({
          'card tax-plan custom': true,
          selected: taxPlan.selected
        })}
        key={index}
        onClick={(e) => this.selectTaxPlan(e, index)}
      >
        <h3 className="card-title">
          Customizado
          <br />
        </h3>
        <section className="details">
          <h5>Taxas</h5>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Débito"
              name="client[tax_plan][debit_rate]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'debit_rate', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito a vista"
              name="client[tax_plan][credit_rate_1]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_1', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 2X"
              name="client[tax_plan][credit_rate_2]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_2', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 3X"
              name="client[tax_plan][credit_rate_3]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_3', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 4X"
              name="client[tax_plan][credit_rate_4]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_4', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 5X"
              name="client[tax_plan][credit_rate_5]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_5', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 6X"
              name="client[tax_plan][credit_rate_6]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_6', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 7X"
              name="client[tax_plan][credit_rate_7]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_7', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 8X"
              name="client[tax_plan][credit_rate_8]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_8', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 9X"
              name="client[tax_plan][credit_rate_9]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_9', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 10X"
              name="client[tax_plan][credit_rate_10]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_10', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 11X"
              name="client[tax_plan][credit_rate_11]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_11', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Crédito em 12X"
              name="client[tax_plan][credit_rate_12]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'credit_rate_12', index)}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Antec. de recebíveis"
              name="client[tax_plan][anticipation_rate]"
              type="text"
              mask="percentage"
              onChange={(e) => this.handleChange(e, 'anticipation_rate', index)}
            />
          </div>
        </section>
        <section className="details">
          <h5>Taxas de repasse</h5>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Boleto bancário"
              name="client[tax_plan][outflow_to_payment_to_bill_rate]"
              type="text"
              mask="currency"
              onChange={(e) =>
                this.handleChange(e, 'outflow_to_payment_to_bill_rate', index)
              }
            />
          </div>
          <div className="form-row">
            <div className="form-controls boolean optional">
              <div>
                <input
                  value="0"
                  type="hidden"
                  name="client[tax_plan][outflow_to_payment_to_bill_rate_percentage]"
                />
                <Field
                  className="boolean optional"
                  name="client[tax_plan][outflow_to_payment_to_bill_rate_percentage]"
                  component="input"
                  onChange={(e) =>
                    this.togglePercentage(e, 'outflow_to_payment_to_bill_rate')
                  }
                  type="checkbox"
                />
              </div>
              <label htmlFor="client[tax_plan][all_taxes_percentage]">
                Porcentagem?
              </label>
            </div>
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Transferência"
              name="client[tax_plan][bank_transfer_rate]"
              type="text"
              mask="currency"
              onChange={(e) =>
                this.handleChange(e, 'bank_transfer_rate', index)
              }
            />
          </div>
          <div className="form-row">
            <div className="form-controls boolean optional">
              <div>
                <input
                  value="0"
                  type="hidden"
                  name="client[tax_plan][outflow_to_transfer_to_bank_account_rate_percentage]"
                />
                <Field
                  className="boolean optional"
                  name="client[tax_plan][outflow_to_transfer_to_bank_account_rate_percentage]"
                  component="input"
                  onChange={(e) =>
                    this.togglePercentage(
                      e,
                      'outflow_to_transfer_to_bank_account_rate'
                    )
                  }
                  type="checkbox"
                />
              </div>
              <label htmlFor="client[tax_plan][outflow_to_transfer_to_bank_account_rate_percentage]">
                Porcentagem?
              </label>
            </div>
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Cartão pré-pago"
              name="client[tax_plan][outflow_to_transfer_to_prepaid_card_rate]"
              type="text"
              mask="currency"
              onChange={(e) =>
                this.handleChange(
                  e,
                  'outflow_to_transfer_to_prepaid_card_rate',
                  index
                )
              }
            />
          </div>
          <div className="form-row">
            <div className="form-controls boolean optional">
              <div>
                <input
                  value="0"
                  type="hidden"
                  name="client[tax_plan][outflow_to_transfer_to_prepaid_card_rate_percentage]"
                />
                <Field
                  className="boolean optional"
                  name="client[tax_plan][outflow_to_transfer_to_prepaid_card_rate_percentage]"
                  component="input"
                  onChange={(e) =>
                    this.togglePercentage(
                      e,
                      'outflow_to_transfer_to_prepaid_card_rate'
                    )
                  }
                  type="checkbox"
                />
              </div>
              <label htmlFor="client[tax_plan][outflow_to_transfer_to_prepaid_card_rate_percentage]">
                Porcentagem?
              </label>
            </div>
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Entre clientes"
              name="client[tax_plan][outflow_to_transfer_to_client_rate]"
              type="text"
              mask="currency"
              onChange={(e) =>
                this.handleChange(
                  e,
                  'outflow_to_transfer_to_client_rate',
                  index
                )
              }
            />
          </div>
          <div className="form-row">
            <div className="form-controls boolean optional">
              <div>
                <input
                  value="0"
                  type="hidden"
                  name="client[tax_plan][outflow_to_transfer_to_client_rate_percentage]"
                />
                <Field
                  className="boolean optional"
                  name="client[tax_plan][outflow_to_transfer_to_client_rate_percentage]"
                  component="input"
                  onChange={(e) =>
                    this.togglePercentage(
                      e,
                      'outflow_to_transfer_to_client_rate'
                    )
                  }
                  type="checkbox"
                />
              </div>
              <label htmlFor="client[tax_plan][outflow_to_transfer_to_client_rate_percentage]">
                Porcentagem?
              </label>
            </div>
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Cobrança"
              name="client[tax_plan][payment_collection_rate]"
              type="text"
              mask="currency"
              onChange={(e) =>
                this.handleChange(e, 'payment_collection_rate', index)
              }
            />
          </div>
          <div className="form-row">
            <div className="form-controls boolean optional">
              <div>
                <input
                  value="0"
                  type="hidden"
                  name="client[tax_plan][payment_collection_rate_percentage]"
                />
                <Field
                  className="boolean optional"
                  name="client[tax_plan][payment_collection_rate_percentage]"
                  component="input"
                  onChange={(e) =>
                    this.togglePercentage(e, 'payment_collection_rate')
                  }
                  type="checkbox"
                />
              </div>
              <label htmlFor="client[tax_plan][payment_collection_rate_percentage]">
                Porcentagem?
              </label>
            </div>
          </div>
        </section>
        <section className="details">
          <h5>Taxa de cobrança</h5>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Parcelas de cob otimizada"
              name="client[tax_plan][payment_collection_installment]"
              type="number"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Taxa p/ parcelas cob otimizada"
              name="client[tax_plan][payment_collection_installment_rate]"
              type="text"
              mask="percentage"
            />
          </div>
        </section>
        <section className="details">
          <h5>POS</h5>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Aluguel do pos"
              name="client[tax_plan][pos_rent_amount]"
              type="text"
              mask="currency"
              onChange={(e) => this.handleChange(e, 'pos_rent_amount', index)}
            />
          </div>
        </section>
      </div>
    );
  }

  renderTaxPlan(taxPlan, index) {
    return (
      <div
        className={classNames({
          'card tax-plan': true,
          selected: taxPlan.selected
        })}
        key={index}
        onClick={(e) => this.selectTaxPlan(e, index)}
      >
        <h3 className="card-title">
          {taxPlan.name}
          <br />
          <small className="card-subtitle">{taxPlan.partner_name}</small>
        </h3>
        <section className="details">
          <h5>Taxas</h5>
          <dl>
            <dt>Débito</dt>
            <dd>{taxPlan.debit_rate}%</dd>
            <dt>Crédito a vista</dt>
            <dd>{taxPlan.credit_rate_1}%</dd>
            <dt>Crédito em 2x</dt>
            <dd>{taxPlan.credit_rate_2}%</dd>
            <dt>Crédito em 3x</dt>
            <dd>{taxPlan.credit_rate_3}%</dd>
            <dt>Crédito em 4x</dt>
            <dd>{taxPlan.credit_rate_4}%</dd>
            <dt>Crédito em 5x</dt>
            <dd>{taxPlan.credit_rate_5}%</dd>
            <dt>Crédito em 6x</dt>
            <dd>{taxPlan.credit_rate_6}%</dd>
            <dt>Crédito em 7x</dt>
            <dd>{taxPlan.credit_rate_7}%</dd>
            <dt>Crédito em 8x</dt>
            <dd>{taxPlan.credit_rate_8}%</dd>
            <dt>Crédito em 9x</dt>
            <dd>{taxPlan.credit_rate_9}%</dd>
            <dt>Crédito em 10x</dt>
            <dd>{taxPlan.credit_rate_10}%</dd>
            <dt>Crédito em 11x</dt>
            <dd>{taxPlan.credit_rate_11}%</dd>
            <dt>Crédito em 12x</dt>
            <dd>{taxPlan.credit_rate_12}%</dd>
            <dt>Taxa de antecipação (a.m.)</dt>
            <dd>{taxPlan.anticipation_rate}%</dd>
          </dl>
        </section>
        <section className="details">
          <h5>Taxas de repasse</h5>
          <dl>
            <dt>Boleto bancário</dt>
            <dd>
              R$ {normalizeCurrency(taxPlan.outflow_to_payment_to_bill_rate)}
            </dd>
            <dt>Transferência bancária</dt>
            <dd>R$ {normalizeCurrency(taxPlan.transfer_to_bank_account)}</dd>
            <dt>Transferência entre clientes</dt>
            <dd>R$ {normalizeCurrency(taxPlan.transfer_to_client)}</dd>
            <dt>Garantia</dt>
            <dd>R$ {normalizeCurrency(taxPlan.payment_collection_rate)}</dd>
            <dt>Parcelas na cob. otimizada</dt>
            <dd>{taxPlan.payment_collection_installment}</dd>
            <dt>Taxa parcelas cob. otimizada</dt>
            <dd>{taxPlan.payment_collection_installment_rate}%</dd>
          </dl>
        </section>
        <section className="details">
          <h5>Transferências bancárias</h5>
          <dl>
            <dt>Taxa</dt>
            <dd>R$ {normalizeCurrency(taxPlan.bank_transfer_rate)}</dd>
            <dt>Bancos isentos</dt>
            <dd>Nenhum</dd>
          </dl>
        </section>
        <section className="details">
          <h5>POS</h5>
          <dl>
            <dt>Valor da mensalidade</dt>
            <dd>R$ {taxPlan.pos_rent_amount}</dd>
            <dt>Dia da cobrança</dt>
            <dd>{taxPlan.pos_rent_payday}</dd>
          </dl>
        </section>
      </div>
    );
  }

  renderTaxPlans() {
    const { taxPlans } = this.props;
    const content = [];

    taxPlans.map((taxPlan, index) => {
      _.isNil(taxPlan.id)
        ? content.push(this.renderCustomTaxPlan(taxPlan, index))
        : content.push(this.renderTaxPlan(taxPlan, index));
    });

    return content;
  }

  render() {
    return <div className="tax-plans">{this.renderTaxPlans()}</div>;
  }
}

TaxPlans.propTypes = {
  taxPlans: PropTypes.array,
  setTaxToReducer: PropTypes.func
};

export default TaxPlans;
