import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { selectors } from 'store/ducks/paymentCollectionDetails';
import ListContainer from '../components/ListContainer';

const CostsTemplate = ({ title, children }) => {
  const { costs, optimization } = useSelector(selectors.getDetailsData);

  return (
    <ListContainer title={title}>
      {isEmpty(costs) ? (
        <div className="list__item list__item-sp-between statement-detail__installment">
          <div className="list__item-left">
            <p>Não houve custos nesta operação.</p>
          </div>
        </div>
      ) : (
        children({ ...costs, optimization })
      )}
    </ListContainer>
  );
};

CostsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default CostsTemplate;
