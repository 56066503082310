import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import SignUpTabs from 'components/SignUpApp/SignUpTabs';
import NaturalSignUpForm from 'components/SignUpApp/NaturalSignUpForm';
import ClientAndBranchSignUpForm from './ClientAndBranchSignUpForm';

const paths = [
  '/vendedores/:sellerId/:partnerId/vendas',
  '/:clientType/:clientId',
  '/:clientType'
];
class SignUpApp extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            {paths.map((path, index) => (
              <Route
                path={`${path}/cadastro-pessoa-juridica`}
                key={index}
                render={(props) => (
                  <div className="first-access-app flex-container">
                    <SignUpTabs
                      location={props.location}
                      {...props.match.params}
                    />

                    <ClientAndBranchSignUpForm {...props.match.params} />
                  </div>
                )}
              />
            ))}
            {paths.map((path, index) => (
              <Route
                path={`${path}/cadastro-pessoa-fisica`}
                key={index}
                render={(props) => (
                  <div className="first-access-app flex-container">
                    <SignUpTabs
                      location={props.location}
                      {...props.match.params}
                    />
                    <NaturalSignUpForm {...props.match.params} />
                  </div>
                )}
              />
            ))}
          </div>
        </Router>
      </Provider>
    );
  }
}

export default SignUpApp;
