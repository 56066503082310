import React from 'react';
import PropTypes from 'prop-types';

import Collapse from 'components/Shared/Collapse';
import { normalizeCurrency, formatDate } from 'normalizers';

class AdvancementDetailsOutflow extends React.Component {
  static propTypes = {
    paymentCollection: PropTypes.bool,
    optimization: PropTypes.bool,
    advancedValue: PropTypes.string.isRequired,
    advancesRateValue: PropTypes.string.isRequired,
    advancedItems: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        advanced_value: PropTypes.string,
        original_released_at: PropTypes.string,
        advanced_days: PropTypes.number,
        advances_rate_value: PropTypes.string,
        url: PropTypes.string
      })
    ).isRequired,
    advancedItemsNewModeling: PropTypes.arrayOf(
      PropTypes.shape({
        advancement_release_at: PropTypes.string,
        advancement_items: PropTypes.arrayOf(
          PropTypes.shape({
            receivable_id: PropTypes.string,
            accquirer_name: PropTypes.string,
            advancement_value: PropTypes.string,
            original_release_at: PropTypes.string,
            advanced_days: PropTypes.number,
            advancement_rate_value: PropTypes.string
          })
        )
      })
    )
  };

  static defaultProps = {
    paymentCollection: false,
    optimization: false,
    advancedItemsNewModeling: []
  };

  render() {
    const {
      optimization,
      advancedValue,
      advancesRateValue,
      advancedItems,
      advancedItemsNewModeling,
      paymentCollection
    } = this.props;

    const hasNewModeling = advancedItemsNewModeling.length > 0;
    const removeColumn = paymentCollection && !optimization;

    return (
      <div className="list__item list__item-sp-between statement-detail__installment">
        <div className="list__item-left">
          <p>
            <b>Antecipação de recebíveis</b>
          </p>
          <p>{advancesRateValue}</p>
        </div>
        <div className="list__item-right" style={{ alignItems: 'center' }}>
          <Collapse
            getContainerRef={() => this.containerRef}
            arrowColor="#e1e5ed"
            parent
          />
        </div>
        <div
          ref={(ref) => {
            this.containerRef = ref;
          }}
        >
          {hasNewModeling && (
            <div className="advancement-details">
              <table>
                <thead>
                  <tr>
                    <th>Agenda</th>
                    <th>Antecipado Em</th>
                    <th>Valor Antecipado</th>
                    <th>Data Pagamento Original</th>
                    <th>Dias Antecipados</th>
                    <th>Taxa de Antecipação</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {advancedItemsNewModeling.map((item) =>
                    item.advancement_items.map((advancementItem) => (
                      <tr key={advancementItem.receivable_id}>
                        <td>{advancementItem.accquirer_name || 'Blu'}</td>
                        <td>{formatDate(item.advancement_release_at) || ''}</td>
                        <td>
                          R${' '}
                          {normalizeCurrency(
                            parseFloat(advancementItem.advancement_value)
                          )}
                        </td>
                        <td>
                          {formatDate(advancementItem.original_release_at) ||
                            ''}
                        </td>
                        <td>{advancementItem.advanced_days}</td>
                        <td>
                          R${' '}
                          {normalizeCurrency(
                            parseFloat(advancementItem.advancement_rate_value)
                          )}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    ))
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total:</td>
                    <td>&nbsp;</td>
                    <td>{advancedValue}</td>
                    <td colSpan={2}>&nbsp;</td>
                    <td>{advancesRateValue}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
          {!hasNewModeling && (
            <div className="advancement-details">
              <table>
                <thead>
                  <tr>
                    <th>Tipo Transação</th>
                    <th>Valor Antecipado</th>
                    <th>Data Pagamento Original</th>
                    <th>Dias Antecipados</th>
                    {!removeColumn && <th>Antecipação Recebíveis</th>}
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {advancedItems.map((item) => (
                    <tr key={item.id}>
                      <td>{item.type}</td>
                      <td>
                        R$ {normalizeCurrency(parseFloat(item.advanced_value))}
                      </td>
                      <td>{formatDate(item.original_released_at)}</td>
                      <td>{item.advanced_days}</td>
                      {!removeColumn && (
                        <td>
                          R${' '}
                          {normalizeCurrency(
                            parseFloat(item.advances_rate_value)
                          )}
                        </td>
                      )}
                      <td>
                        <a href={item.url}>detalhes</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Total:</td>
                    <td>{advancedValue}</td>
                    <td colSpan="2">&nbsp;</td>
                    {!removeColumn && <td>{advancesRateValue}</td>}
                    <td>&nbsp;</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AdvancementDetailsOutflow;
