import styled from 'styled-components';
import { Wrapper as ScoreIndicator } from 'components/BluRating/ScoreIndicator/ScoreIndicatorStyles';

export const Content = styled.div`
  padding: 20px;
  max-height: 88vh;
  overflow: auto;
`;

export const HorizontalLine = styled.hr`
  margin: 25px 0px;
  border-top: none;
  border-bottom: 1px solid #f4f4f4;
`;

export const Paragraph = styled.p`
  margin: 0;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  color: #343e4f;
`;

export const Overview = styled.summary`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  ${ScoreIndicator} {
    margin: 0 auto;
    margin-bottom: 20px;
  }

  @media (min-width: 425px) {
    flex-direction: row;

    ${ScoreIndicator} {
      margin: 0;
      margin-right: 20px;
    }
  }
`;

export const OverviewDetails = styled.div`
  h3 {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.25;
    color: #343e4f;

    em {
      font-style: normal;
    }
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.25;
    color: #0028ff;
    padding: 10px;
    border-radius: 6px;
    margin-left: -10px;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
`;

export const List = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style-type: none;
`;

export const ListItem = styled.li`
  margin-bottom: 20px;
`;

export const ListItemHeader = styled.header`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  color: #343e4f;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  small {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
  }
`;

export const ListItemContent = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 425px) {
    flex-direction: row;
  }
`;

export const ListItemContentItem = styled.li`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  > p {
    max-width: 110px;
    font-size: 16px;
    line-height: 1.25;
    color: #343e4f;
    margin: 0px;

    small {
      display: block;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      color: #afafaf;
      white-space: nowrap;
    }
  }
`;
