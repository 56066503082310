import styled from 'styled-components';

import {
  colorBrandPrimaryPure,
  colorInterfaceDarkDown,
  fontFamilyHighlight,
  fontFamilyBase
} from '@useblu/ocean-tokens/web/tokens';

const devices = {
  mobile: `(max-width: 768px)`,
  tablet: `(max-width: 1366px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`
};

export const Container = styled.div`
  max-width: 328px;
  margin-bottom: 16px;

  @media ${devices.notebook} {
    margin-bottom: 24px;
    max-width: 554px;
  }
`;

export const StepWrapper = styled.div`
  margin-bottom: 16px;

  @media ${devices.notebook} {
    margin-bottom: 24px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 900;
  font-family: ${fontFamilyHighlight};
  color: ${colorBrandPrimaryPure};
  margin-bottom: ${(props) => (props.isMargin ? '8px' : '16px')};

  @media ${devices.notebook} {
    font-size: 32px;
    font-weight: 900;

    margin-bottom: ${(props) => (props.isMargin ? '8px' : '24px')};
  }
`;

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: ${fontFamilyBase};
  color: ${colorInterfaceDarkDown};

  margin-bottom: 16px;

  @media ${devices.notebook} {
    font-size: 16px;
    margin-bottom: 24px;
  }
`;
