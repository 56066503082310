import React, { Component } from 'react';
import PropTypes from 'prop-types';

import maskInput, { maskValue } from 'utils/masks';
import DateRange from 'components/Shared/DateRange';

const defaultValues = {
  'filter[from]': '',
  'filter[to]': '',
  'filter[payment_collection_id]': '',
  'filter[payment_collection_reference]': '',
  'filter[client_cpf_cnpj]': '',
  'filter[client_name]': ''
};

class FilterForm extends Component {
  static propTypes = {
    formAction: PropTypes.string.isRequired,
    selectedStatus: PropTypes.string,
    initialValues: PropTypes.object
  };

  static defaultProps = {
    initialValues: {}
  };

  constructor(props) {
    super(props);
    this.state = { form: { ...defaultValues, ...props.initialValues } };
  }

  componentDidMount() {
    maskInput(this.cpfCnpjInput, 'cpf_cnpj');
    maskInput(this.identityInput, 'number');
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [name]: value
      }
    }));
  };

  handleSelectDates = ({ from, to }) => {
    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        'filter[from]': from,
        'filter[to]': to
      }
    }));
  };

  handleResetForm = () => {
    this.setState({ form: { ...defaultValues } });
  };

  render() {
    const { form } = this.state;
    const { formAction, selectedStatus } = this.props;

    return (
      <div className="statement__filter-content flex-container is-padded">
        <form action={formAction} method="GET">
          <DateRange
            names={{ from: 'filter[from]', to: 'filter[to]' }}
            values={{ from: form['filter[from]'], to: form['filter[to]'] }}
            onSelect={this.handleSelectDates}
          />

          <div className="form-row">
            <div className="form-controls">
              <label htmlFor="payment_collection_id">Identificador</label>
              <input
                id="payment_collection_id"
                type="text"
                name="filter[payment_collection_id]"
                value={maskValue(
                  form['filter[payment_collection_id]'],
                  'number'
                )}
                placeholder="Somente números"
                maxLength={9}
                onChange={this.handleChange}
                ref={(i) => {
                  this.identityInput = i;
                }}
              />
            </div>

            <div className="form-controls">
              <label htmlFor="payment_collection_reference">
                Código de Referência
              </label>
              <input
                id="payment_collection_reference"
                type="text"
                name="filter[payment_collection_reference]"
                value={form['filter[payment_collection_reference]']}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-controls">
              <label htmlFor="client_cpf_cnpj">CNPJ/CPF</label>
              <input
                id="client_cpf_cnpj"
                type="text"
                name="filter[client_cpf_cnpj]"
                value={maskValue(form['filter[client_cpf_cnpj]'], 'cpf_cnpj')}
                onChange={this.handleChange}
                ref={(i) => {
                  this.cpfCnpjInput = i;
                }}
              />
            </div>

            <div className="form-controls">
              <label htmlFor="client_name">Nome</label>
              <input
                id="client_name"
                type="text"
                name="filter[client_name]"
                placeholder="Nome Fantasia ou Razão Social"
                value={form['filter[client_name]']}
                maxLength={255}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {selectedStatus && (
            <input type="hidden" name="status" value={selectedStatus} />
          )}

          <div className="form-row actions">
            <button
              type="button"
              className="button button--secondary button--medium"
              onClick={this.handleResetForm}
            >
              Limpar
            </button>
            <button
              type="submit"
              className="button button--primary button--medium"
            >
              Filtrar
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default FilterForm;
