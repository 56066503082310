import { createSelector } from 'reselect';
import moment from 'utils/moment.pt-br';

export const WITHOUT_FATHER_NAME_REGISTERED = 'SEM REGISTRO';

export const rpaRegisterFormRequestLoadingSelector = (state) =>
  state.rpaRegisterForm.loading;

export const rpaRegisterFormRequestErrorSelector = (state) =>
  state.rpaRegisterForm.error;

export const fetchRpaRegisterDataSelector = createSelector(
  (state) => state?.rpaRegisterForm?.data || {},
  (data) => {
    const birthdayMoment = !!data?.personalData?.birthday
      ? moment(data.personalData.birthday).format('DD/MM/YYYY')
      : null;

    const noFatherNameRegistred =
      data?.personalData?.fatherName === WITHOUT_FATHER_NAME_REGISTERED;
    const dataWithNofatherField = {
      ...data,
      personalData: {
        ...data?.personalData,
        fatherName: noFatherNameRegistred ? '' : data?.personalData?.fatherName,
        birthday: birthdayMoment,
        noFatherName: noFatherNameRegistred
      }
    };

    return dataWithNofatherField;
  }
);
