import { isEmpty } from 'lodash';

export const isLoading = (state) => state.bluRating.loading;

export const getError = (state) => state.bluRating.error;

export const getRatingByClient = (key) => (state) => state.bluRating.byKey[key];

export const isOutdate = (key) => (state) => {
  const data = getRatingByClient(key)(state);
  if (isEmpty(data)) return true;

  const currentDate = new Date();
  const timestamp = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  return timestamp.getTime() > data.fetchedAt;
};
