import { createActions, createReducer } from 'reduxsauce';
import update from 'immutability-helper';

/* Types & Creators */
const { Types, Creators } = createActions({
  getClientBigboost: ['cpf'],
  getClientBigboostSuccess: ['data'],
  getClientBigboostFailure: ['error'],

  getClientBigboostReset: []
});

export { Types };
export default Creators;

/* Initial State */
const INITIAL_STATE = {
  bigBoostData: [],

  bigBoostDataLoading: false,
  bigBoostDataError: null
};

/* Reducers */
export const loadBigBoostData = (state, action) =>
  update(state, {
    bigBoostData: {
      $push: [
        {
          cpf: action.cpf
        }
      ]
    },
    bigBoostDataLoading: {
      $set: true
    }
  });

export const loadBigBoostDataSuccess = (state, action) => {
  const indexData = state.bigBoostData.findIndex(
    (client) =>
      client?.cpf?.replace(/\D/g, '') === action?.data?.cpf?.replace(/\D/g, '')
  );

  return update(state, {
    bigBoostData: {
      [indexData]: {
        $set: action.data
      }
    },
    bigBoostDataLoading: {
      $set: false
    },
    bigBoostDataError: {
      $set: null
    }
  });
};

export const loadBigBoostDataFailure = (state, action) => ({
  ...state,
  bigBoostDataLoading: false,
  bigBoostDataError: action.error
});

export const loadBigBoostDataReset = () => INITIAL_STATE;

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CLIENT_BIGBOOST]: loadBigBoostData,
  [Types.GET_CLIENT_BIGBOOST_SUCCESS]: loadBigBoostDataSuccess,
  [Types.GET_CLIENT_BIGBOOST_FAILURE]: loadBigBoostDataFailure,
  [Types.GET_CLIENT_BIGBOOST_RESET]: loadBigBoostDataReset
});
