import React from 'react';
import { Typography, Button } from '@useblu/ocean-react';
import { useMedia } from 'react-use';
import DeviseScreenLayout from '../DeviseScreenLayout';
import ilustraFraud from '../../images/ilustra-fraud.svg';
import { WarningContainer } from '../../devise.styles';

const AntiFraudWarning = () => {
  const isMobile = useMedia('(max-width: 768px)');
  return (
    <DeviseScreenLayout className="adjust-logo">
      <WarningContainer $withCaptionOnMobile>
        <img src={ilustraFraud} alt="ilustra-f" />
        <Typography variant="heading2">
          Não podemos te oferecer acesso a conta neste momento
        </Typography>
        <Typography variant="paragraph">Tente novamente mais tarde.</Typography>
        {isMobile && (
          <Button
            variant="primary"
            onClick={() => window.location.assign('/users/sign_in')}
          >
            Voltar ao início
          </Button>
        )}
      </WarningContainer>
    </DeviseScreenLayout>
  );
};

export default AntiFraudWarning;
