import React from 'react';
import PropTypes from 'prop-types';

import googlePlayImg from './images/googlePlay.svg';
import appleStoreImg from './images/appleStore.svg';

import {
  LoginContainer,
  LoginDescription,
  LoginSection,
  LoginBanner,
  LoginForm,
  AppDownload
} from './styles';

const SelbieLogin = ({ selbieLogin }) => (
  <LoginContainer>
    <LoginDescription>
      <div>
        <p>
          Agora, ao invés de você pagar uma taxa fixa, a taxa passa a ser
          variável por transação (1,49%)*.
        </p>
        <LoginBanner>
          <div>
            <p>
              <span>
                R$ 00,<small>00</small>
              </span>{' '}
              para baixar o aplicativo
            </p>
            <p>
              <span>
                R$ 00,<small>00</small>
              </span>{' '}
              mensalidade
            </p>
          </div>
        </LoginBanner>
      </div>
    </LoginDescription>
    <LoginSection>
      <p>Confira aqui seu login e senha de acesso para usar no aplicativo</p>
      <LoginForm>
        <span>Usuário</span>
        <p>{selbieLogin ? selbieLogin.login : ''}</p>
        <span>Senha</span>
        <p>{selbieLogin ? selbieLogin.password : ''}</p>
      </LoginForm>
      <AppDownload>
        <div>
          <p>Baixe no seu celular escolhendo uma opção abaixo:</p>
        </div>
        <div>
          <a
            href="https://apps.apple.com/br/app/blu-by-sellbie/id1504781499"
            width="160px"
          >
            <img src={appleStoreImg} alt="apple store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.blubysellbie"
            width="160px"
          >
            <img src={googlePlayImg} alt="play store" />
          </a>
        </div>
      </AppDownload>
    </LoginSection>
  </LoginContainer>
);

SelbieLogin.propTypes = {
  selbieLogin: PropTypes.object.isRequired
};

export default SelbieLogin;
