import React from 'react';

import { Alert } from '@useblu/ocean-react';
import { DocumentTextOutline } from '@useblu/ocean-icons-react';
import { useSelector } from 'react-redux';
import { AlertWrapper } from './styles';

const ContractSigninAlert = () => {
  const clientId = useSelector(
    (state) =>
      state.railsContext?.clientId || state.railsContext?.clientClientId
  );

  const goToOldContract = () => {
    window.location.href = `/lojas/${clientId}/contrato/blu.pdf`;
  };

  return (
    <AlertWrapper className="ocean-iso" onClick={goToOldContract}>
      <Alert type="default" icon={<DocumentTextOutline />}>
        <p>
          <span>
            Nossos termos e condições foram atualizados. Acesse a nova versão do
            Contrato de Prestação de Serviço{' '}
            <a>
              <u>clicando aqui.</u>
            </a>
          </span>
        </p>
      </Alert>
    </AlertWrapper>
  );
};

export default ContractSigninAlert;
