import { call, put } from 'redux-saga/effects';

import ActionCreators from 'store/ducks/paymentsList';
import { getPaymentListWithLimitApi } from 'api/payment-confirmation';

export function* getPaymentsList({ uuid }) {
  try {
    const response = yield call(getPaymentListWithLimitApi, uuid);

    const {
      data: {
        preRegistrationTransactions: { count, total, transactions }
      }
    } = response.data;

    yield put(ActionCreators.getPaymentListSuccess(transactions, count, total));
  } catch (error) {
    yield put(ActionCreators.getPaymentListFailure());
  }
}
