import styled from 'styled-components';

import {
  fontFamilyBase,
  fontFamilyHighlight,
  fontSizeXs,
  fontSizeSm,
  colorInterfaceDarkDown,
  colorInterfaceLightPure,
  colorInterfaceLightUp,
  colorInterfaceLightDown,
  borderRadiusMd
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  padding: 32px 16px;
  background-color: ${colorInterfaceLightUp};
`;

export const CardList = styled.div`
  display: grid;
  grid-gap: 8px;

  @media screen and (min-width: 769px) {
    grid-template-columns: 1fr 1fr;
    max-width: 808px;
    margin: 0 auto;
  }
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 21px;
  background-color: ${colorInterfaceLightPure};
  border: 1px solid ${colorInterfaceLightDown};
  border-radius: ${borderRadiusMd};

  .tag {
    position: relative;
    width: auto;
    margin-bottom: -46px;
    margin-left: 92px;
    left: -50%;
  }
`;

export const InfoCardIcon = styled.img`
  max-width: 40px;
  margin-bottom: 20px;
`;

export const InfoCardTitle = styled.h3`
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeSm};
  font-weight: 900;
  color: ${colorInterfaceDarkDown};
  text-align: center;
  margin-bottom: 0;
`;

export const InfoCardText = styled.p`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  font-weight: 400;
  color: ${colorInterfaceDarkDown};
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
