import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { normalizeValue } from 'normalizers';
import { useSelector, useDispatch } from 'react-redux';

import UIStateCreators from 'store/ducks/UIState';

import {
  MobileBalanceNavStyled,
  CurrentBalance,
  Title,
  Value,
  Carret,
  MobileBalanceContainer,
  MobileBalanceBackDrop
} from './MobileBalance.styles';
import ToggleViewIcon from '../../shared/ToggleViewIcon';
import useMenuOpen from '../../hooks/useMenuOpen';
import Balance from '../../Balance';
import HeaderContext from '../context';

const MobileBalance = () => {
  const {
    balance: { total, current, future },
    externalWallet
  } = useContext(HeaderContext);

  const dispatch = useDispatch();
  const { privateMode } = useSelector(
    (state) => state.UIState || { privateMode: true }
  );
  const { classes, actionsProps, isContentMounted, ref } = useMenuOpen('click');

  const toggleBalanceVisible = () => {
    dispatch(UIStateCreators.tooglePrivateMode());
  };

  const classNamesTotalValue = useMemo(
    () => classNames({ isNegative: total * 1 < 0 }),
    [total]
  );

  return (
    <MobileBalanceNavStyled ref={ref}>
      <CurrentBalance>
        <Title className={classes} onClick={actionsProps.onClick}>
          Seu saldo
        </Title>
        <Value className={classes}>
          <button
            type="button"
            data-testid="toggle-balance"
            onClick={toggleBalanceVisible}
          >
            <ToggleViewIcon visible={!privateMode} />
          </button>
          <button
            type="button"
            className="align-left"
            onClick={actionsProps.onClick}
          >
            <span>Saldo disponível </span>
            <span className={classNamesTotalValue}>
              {!privateMode ? normalizeValue(total) : 'R$ ••••••'}
            </span>
          </button>
        </Value>
        <Carret type="button" onClick={actionsProps.onClick}>
          <svg width="24" height="24" viewBox="0 0 24 24" className={classes}>
            <path
              d="M8 10.6667L12 14.6667L16 10.6667"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Carret>
      </CurrentBalance>

      {isContentMounted && (
        <MobileBalanceContainer onClick={() => toggleBalanceVisible()}>
          <Balance
            isMobile
            className={classes}
            total={total}
            current={current}
            future={future}
            externalWallet={externalWallet}
          />
          <MobileBalanceBackDrop
            className={classes}
            onClick={actionsProps.onClick}
          />
        </MobileBalanceContainer>
      )}
    </MobileBalanceNavStyled>
  );
};

export default MobileBalance;
