import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/firstAccess/usersAccounts';
import { getUsers, saveUser, updateUser, deleteUser } from 'api/first-access';
import _ from 'lodash';

export function* loadUsersAccounts(params) {
  try {
    const response = yield call(getUsers, params);
    const { data } = response.data;
    yield put(ActionCreators.firstAccessUsersAccountsSuccess(data.users));
  } catch (err) {
    yield put(ActionCreators.firstAccessUsersAccountsFailure([]));
  }
}

export function* saveUserRequest(params) {
  const response = yield call(saveUser, params);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(
      ActionCreators.firstAccessUsersAccountsSaveUserSuccess(data.data.addUser)
    );
  } else {
    yield put(
      ActionCreators.firstAccessUsersAccountsSavingFailure(data.errors)
    );
  }
}

export function* updateUserRequest(params) {
  const response = yield call(updateUser, params);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(
      ActionCreators.firstAccessUsersAccountsUpdateUserSuccess(
        data.data.updateUser
      )
    );
  } else {
    yield put(
      ActionCreators.firstAccessUsersAccountsSavingFailure(data.errors)
    );
  }
}

export function* deleteUserRequest(params) {
  const response = yield call(deleteUser, params);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(ActionCreators.firstAccessUsersAccountsDeleteUserSuccess(data));
  }
}
