import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReadOnlyField from 'components/Application/ReadOnlyField';
import { normalizeCurrency, formatDate } from 'normalizers';
import moment from 'moment';

const LockValue = styled.p`
  width: auto;
  padding-right: 16px;
`;

const LockDetails = styled.div`
  display: flex;
  flex-direction: row;
`;

const AdvancementReceivablesList = ({ value, locks, currentClientUuid }) => {
  const [height, setHeight] = React.useState(0);
  const [isOpen, setOpen] = React.useState(false);

  function title(lock) {
    if (lock.rebalance) return 'Devolução de valor utilizado';

    return parseFloat(lock.rate_value) === 0
      ? 'Antecipação Custo Zero para Pagamento com Fornecedor'
      : 'Transações Antecipadas';
  }

  function renderLockTitle(lock) {
    return (
      <div className="list__item-left">
        <p>
          <b>{title(lock)}</b>
        </p>
        <p>{formatDate(lock.created_at)}</p>
        <p>{moment(lock.created_at).fromNow()}</p>
      </div>
    );
  }

  function renderLinkForDetails(lock) {
    if (lock.rebalance) return null;

    return (
      <a
        className="detail__toggle"
        href={`/lojas/${currentClientUuid}/movimentacoes/antecipacao-de-recebiveis/${lock.client_transaction_uuid}`}
      >
        Ver Detalhes
      </a>
    );
  }

  function renderLock(lock) {
    return (
      <div
        key={lock.id}
        style={{ height: 82 }}
        className="list__item list__item-sp-between statement-detail__installment"
      >
        {renderLockTitle(lock)}
        <div
          className="list__item-right"
          style={{ width: '100%', alignItems: 'center' }}
        >
          <LockDetails className="list__item-with-link">
            <LockValue className="lock-net-value">
              Valor antecipado:
              <b>R$ {normalizeCurrency(lock.net_value)}</b>
            </LockValue>
            {lock.rate_value !== '0.0' && (
              <>
                <LockValue className="lock-rate-value">
                  Taxa de antecipação:
                  <b>R$ {normalizeCurrency(lock.rate_value)}</b>
                </LockValue>
                <LockValue className="lock-gross-value">
                  Total:
                  <b>R$ {normalizeCurrency(lock.gross_value)}</b>
                </LockValue>
              </>
            )}
          </LockDetails>
          {renderLinkForDetails(lock)}
        </div>
      </div>
    );
  }

  function renderLocks() {
    return locks.map((lock) => (lock.net_value ? renderLock(lock) : null));
  }

  function renderLocksContainer() {
    return (
      <div
        className="list__wrapper pagnet-collapse-component"
        style={{ height, overflow: 'hidden' }}
      >
        <h4 className="list__title">Valores Antecipados</h4>
        {renderLocks()}
      </div>
    );
  }

  function toogle() {
    setHeight(isOpen ? 0 : 50 + locks.length * 83);
    setOpen(!isOpen);
  }

  return (
    <div>
      <div className="form-row">
        <ReadOnlyField label="Valores Antecipados" value={value}>
          <button className="detail__toggle" type="button" onClick={toogle}>
            Ver Detalhes
          </button>
        </ReadOnlyField>
      </div>

      {locks.length > 0 && renderLocksContainer()}
    </div>
  );
};

AdvancementReceivablesList.propTypes = {
  locks: PropTypes.array,
  value: PropTypes.string,
  currentClientUuid: PropTypes.string.isRequired
};

AdvancementReceivablesList.defaultProps = {
  locks: [],
  value: ''
};

export default AdvancementReceivablesList;
