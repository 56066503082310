import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import importConfirmationCreators from 'store/ducks/importPaymentConfirmation';
import maskInput from 'utils/masks';
import { normalizeCurrency, currencyToNumber } from 'normalizers';

const customStyles = {
  container: (base) => ({
    ...base,
    top: -2
  }),
  control: (base) => ({
    ...base,
    minHeight: 32,
    height: 32,
    flexWrap: 'unset'
  })
};

const EditableCell = ({
  children,
  isEditing,
  data,
  dataLabel,
  dataIndex,
  label,
  value,
  type,
  mask,
  options
}) => {
  const [inputValue, setInputValue] = useState(value);

  const dispatch = useDispatch();

  useEffect(() => {
    let valueAux = value;
    if (dataIndex === 'bankAccount')
      valueAux = `${value}-${data.bankAccountCheckDigit}`;

    setInputValue(valueAux);
    if (!isEditing) {
      dispatch(
        importConfirmationCreators.updateImportPaymentConfirmation(null)
      );
    }
  }, [data, dataIndex, value, isEditing, dispatch]);

  const inputRef = useCallback(
    (node) => {
      if (mask && node) {
        maskInput(node, mask);
      }
    },
    [mask]
  );

  const onChange = (e) => {
    let { value } = e.target;

    if (mask === 'currency') value = normalizeCurrency(e.target.value, true);

    setInputValue(value);

    if (mask === 'currency') value = currencyToNumber(value);
    if (mask === 'cpf_cnpj') value = value.replace(/[./-]/g, '');

    if (isEmpty(value)) value = '0';

    dispatch(
      importConfirmationCreators.updateImportPaymentConfirmation({
        [dataIndex]: value,
        id: data.id
      })
    );
  };

  const onChangeSelect = (e) => {
    if (e) setInputValue(e.value);
    else setInputValue(null);

    dispatch(
      importConfirmationCreators.updateImportPaymentConfirmation({
        [dataIndex]: e ? e.value : '',
        id: data.id
      })
    );

    dispatch(
      importConfirmationCreators.updateImportPaymentConfirmation({
        [dataLabel]: e ? e.label : '',
        id: data.id
      })
    );
  };

  const onChangeBankAccount = (type) => (e) => {
    let valueAux = e.target.value.replace(/[-/\\.,]/g, '');
    const accountArr = inputValue.split('-');

    if (type === 'account') {
      setInputValue(`${valueAux}-${accountArr[1]}`);

      if (isEmpty(valueAux)) valueAux = '0';

      dispatch(
        importConfirmationCreators.updateImportPaymentConfirmation({
          bankAccount: valueAux,
          id: data.id
        })
      );
    } else if (type === 'digit') {
      setInputValue(`${accountArr[0]}-${e.target.value}`);

      if (isEmpty(valueAux)) valueAux = '0';

      dispatch(
        importConfirmationCreators.updateImportPaymentConfirmation({
          bankAccountCheckDigit: valueAux,
          id: data.id
        })
      );
    }
  };

  const getAccountValue = (type) => {
    const accountArr = inputValue.split('-');

    if (type === 'account') return accountArr[0];

    return accountArr[1];
  };

  if (!isEditing) {
    return <div>{children}</div>;
  }

  if (type === 'select') {
    return (
      <div>
        <Select
          styles={customStyles}
          options={options}
          onChange={onChangeSelect}
          isClearable={true}
          defaultValue={{ value: inputValue, label }}
        />
      </div>
    );
  }

  return dataIndex === 'bankAccount' ? (
    <div className="editable-bank-account-container">
      <div className="table-editable-bank-account">
        <input
          type="text"
          value={getAccountValue('account')}
          onChange={onChangeBankAccount('account')}
        />
      </div>
      <div className="table-editable-bank-digit">
        <input
          type="text"
          value={getAccountValue('digit')}
          onChange={onChangeBankAccount('digit')}
          maxLength={1}
        />
      </div>
    </div>
  ) : (
    <div>
      <input
        ref={inputRef}
        className="table-editable"
        type="text"
        onChange={onChange}
        value={inputValue}
      />
    </div>
  );
};

EditableCell.propTypes = {
  children: PropTypes.any.isRequired,
  dataLabel: PropTypes.string,
  mask: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  dataIndex: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.array
};

EditableCell.defaultProps = {
  label: null,
  options: null,
  dataLabel: '',
  mask: ''
};

export default EditableCell;
