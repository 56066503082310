/* eslint-disable import/prefer-default-export */
import humps from 'lodash-humps';
import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

export async function getDetailsAnticipation(clientUUID, uuid) {
  return axios
    .get(
      `/api/clients/${clientUUID}/client_transactions/${uuid}/anticipation_details`,
      {
        headers: {
          Accept: 'version=1',
          'X-CSRF-Token': getCSRFToken(),
        },
      }
    )
    .then(async (response) => {
      return humps(response.data);
    })
    .catch(() => {
      return { error: true };
    });
}
