$('.js-data-example-ajax').select2({
  language: 'pt-BR',
  ajax: {
    url: '/escolher-cliente',
    dataType: 'json',
    processResults: function (data) {
      return {
        results: data.map(function (item) {
          return { id: item.id, text: item.name };
        })
      };
    }
  }
});
