import styled from 'styled-components';

import {
  fontFamilyBase,
  colorInterfaceDarkUp,
  colorBrandPrimaryPure,
  colorInterfaceLightUp,
  fontWeightBold,
  shadowLevel1,
  colorInterfaceLightDeep
} from '@useblu/ocean-tokens/web/tokens';

import ContainerMixin from './ContainerMixin';
import { ButtonLightMixin } from './Button';

// ATTENTION:
// This is a self contained component, it should be replaced by the dropdown
// from ocean asap.

export const MobileBackDrop = styled.div`
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 7;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.5s;

    &.is-closed {
      opacity: 1;
      top: -100vh;
    }

    &.is-open {
      opacity: 1;
      top: 0;
    }
  }
`;

export const MobileBar = styled.div`
  @media screen and (max-width: 768px) {
    display: block;
    width: 100%;
    height: 22px;
    position: absolute;
    background-color: #fff;

    &::before {
      content: '';
      position: absolute;
      width: 48px;
      height: 4px;
      border-radius: 4px;
      left: 50%;
      margin-left: -24px;
      margin-top: 8px;
      margin-bottom: 8px;

      background: ${colorInterfaceLightDeep};
    }
  }
`;

export const Content = styled.div`
  flex-direction: column;
  align-items: stretch;
  top: calc(100% + 4px);
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  z-index: 5;

  &.is-closed {
    display: none;
  }

  &.is-open {
    display: flex;
  }

  ${ContainerMixin}

  right: ${(props) => (props.position === 'right' ? '0px' : 'none')};
  left: ${(props) => (props.position === 'left' ? '6px' : 'none')};

  small {
    display: flex;
    padding: 0px 16px;
    height: 40px;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    align-items: flex-end;
    padding-bottom: 8px;
    color: ${colorInterfaceDarkUp};
  }
`;

export const DrawerMenuContainer = styled.nav`
  font-family: ${fontFamilyBase};
  padding: 0px;
  margin: 0px;
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    display: none;
  }

  > button {
    background: none;
    padding: 0px;

    &.is-open,
    &:hover,
    &:active,
    &:focus {
      > *:first-child {
        color: ${colorBrandPrimaryPure};
        background-color: ${colorInterfaceLightUp};
        path,
        rect {
          stroke: ${colorBrandPrimaryPure};
        }
      }
    }

    > *:first-child {
      ${ButtonLightMixin}
    }

    @media screen and (max-width: 768px) {
      &.is-open,
      &:hover,
      &:active,
      &:focus {
        > *:first-child {
          background: transparent;
        }
      }
    }
  }

  ${MobileBar}, ${MobileBackDrop} {
    display: none;
  }

  ${MobileBar}, ${MobileBackDrop} {
    display: block;
  }

  ${Content} {
    position: fixed;
    height: 290px;
    width: 100vw !important;
    left: 0px;
    border-radius: 8px 8px 0px 0px;
    bottom: -100vh;
    top: unset;
    box-shadow: ${shadowLevel1};
    z-index: 8;
    overflow-y: hidden;

    @keyframes show {
      from {
        bottom: -100vh;
      }
      to {
        bottom: 0px;
      }
    }

    @keyframes hide {
      from {
        bottom: 0px;
      }
      to {
        bottom: -100vh;
      }
    }

    animation-duration: 0.5s;

    &.is-closed {
      animation-name: hide;
      bottom: -100vh;
      display: flex;
    }

    &.is-open {
      animation-name: show;
      bottom: 0px;
    }

    & > *:last-child {
      overflow-y: auto;
      margin-top: 22px;
    }

    small {
      display: block;
      text-align: left;
      font-size: 20px;
      line-height: 22px;
      height: 22px;
      font-weight: ${fontWeightBold};
      margin-bottom: 16px;
      color: ${colorBrandPrimaryPure};
    }

    li {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
