import styled from 'styled-components';

import {
  fontFamilyHighlight,
  fontFamilyBase,
  colorBrandPrimaryDown,
  colorInterfaceDarkDown,
  fontSizeXs,
  fontSizeMd,
  fontSizeLg
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  padding: 0 16px 32px;
  max-width: 900px;
  margin: 0 auto;
`;

export const Title = styled.h2`
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeMd};
  font-weight: 300;
  line-height: 110%;
  text-align: center;
  color: ${colorBrandPrimaryDown};
  margin: 32px;

  @media screen and (min-width: 769px) {
    font-size: ${fontSizeLg};
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;

  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
`;

export const Img = styled.img`
  width: 144px;
  margin: 0 0 16px 0;

  @media screen and (min-width: 769px) {
    margin: 0 32px 0 0;
  }
`;

export const Text = styled.p`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  color: ${colorInterfaceDarkDown};
  margin: 0;
`;

export const Highlight = styled.span`
  font-weight: 700;
  color: ${colorBrandPrimaryDown};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
