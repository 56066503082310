import getEconomicGroupHistoriesApi from 'api/history';

import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/history';

export default function* getEconomicGroupHistories(params) {
  try {
    const response = yield call(
      getEconomicGroupHistoriesApi,
      params.clientUuid,
      params.page
    );

    const {
      data: {
        economicGroup: { history }
      }
    } = response.data;

    yield put(ActionCreators.getEconomicGroupHistoriesSuccess(history));
  } catch (error) {
    yield put(ActionCreators.getEconomicGroupHistoriesFailure());
  }
}
