import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid } from '@useblu/ocean-react';
import { ReceiptTaxOutline, ViewListOutline } from '@useblu/ocean-icons-react';
import { railsContextSelector } from 'selectors/rails-context';

import Card from './Card';
import Header from './Header';
import CallToAction from './CallToAction';

const SolutionPayment = ({
  currentClientTaxPlanRate,
  currentClientAAStatus,
}) => {
  const { clientId, clientClientId, cashbackTotal } =
    useSelector(railsContextSelector);

  const clientUUID = clientId || clientClientId;

  const taxPlanRate = currentClientTaxPlanRate.replace('.', ',');

  return (
    <>
      <div className="ocean-iso">
        <Header clientAA={currentClientAAStatus} clientTax={taxPlanRate} />
        <Grid.Container>
          <Grid.Row>
            <Card
              xs="12"
              sm="12"
              md="6"
              title="Precisa de prazo?"
              subtitle="boleto fácil"
              inverse
              fullstory={
                currentClientAAStatus
                  ? `lp_botao1_campanha_solucoesdepagamento_aa`
                  : `lp_botao1_campanha_solucoesdepagamento`
              }
              icon={<ViewListOutline />}
              url={`/lojas/${clientId}/dda-da-blu`}
            >
              Visualize, agende e pague os boletos emitidos pelos seus
              fornecedores e ainda ganhe cashback da Blu de até {cashbackTotal}
              %.
            </Card>

            <Card
              xs="12"
              sm="12"
              md="6"
              title="Quer ter o máximo de economia?"
              subtitle="pagblu"
              inverse={false}
              fullstory={
                currentClientAAStatus
                  ? `lp_botao2_campanha_solucoesdepagamento_aa`
                  : `lp_botao2_campanha_solucoesdepagamento`
              }
              icon={<ReceiptTaxOutline />}
              url={`/lojas/${clientUUID}/pagblu`}
            >
              {currentClientAAStatus
                ? `Ganhe desconto de ${taxPlanRate}% ao pagar fornecedores parceiros.`
                : 'Economize até 10% pagando fornecedores parceiros da Blu usando seu saldo disponível com taxa zero de antecipação.'}
            </Card>
          </Grid.Row>
        </Grid.Container>
        <CallToAction clientAA={currentClientAAStatus} clientId={clientId} />
      </div>
    </>
  );
};

SolutionPayment.propTypes = {
  currentClientTaxPlanRate: PropTypes.string.isRequired,
  currentClientAAStatus: PropTypes.bool.isRequired,
};

export default SolutionPayment;
