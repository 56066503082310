import {
  fontFamilyBase,
  colorInterfaceLightUp
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

export const AccordionContainer = styled.div``;

export const AccordionSummaryContent = styled.div`
  border-bottom: 1px solid #ebecf5;
  padding: 12px 40px 12px 16px;
  position: relative;
  cursor: pointer;

  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #393b47;
  }

  img {
    margin: auto;
    position: absolute;
    right: 15px;
    bottom: 0;
    top: 0;
  }
`;

export const AccordionDescriptionContent = styled.div`
  background-color: ${colorInterfaceLightUp};
  padding: 0 16px;
  min-height: 0px;
  height: 0px;
  visibility: hidden;

  &.show {
    transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition-duration: 100ms;
    height: auto;
    padding: 16px;
    visibility: inherit;
    border-bottom: 1px solid #ebecf5;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    font-family: ${fontFamilyBase};
    color: #393b47;
  }
`;
