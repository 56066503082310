import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import * as PS from '../../../Payment/styles';

const LoadingItem = ({ width }) => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="20"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width={width} height="20" />
    </ContentLoader>
  );
};

LoadingItem.propTypes = {
  width: PropTypes.string.isRequired,
};

const LoadingDetails = () => (
  <PS.ContentContainer>
    <PS.TableDataTransaction>
      <PS.Table>
        <tbody>
          <PS.ValueTableRow>
            <td>
              <p>Valor</p>
            </td>
            <td>
              <p>
                <LoadingItem width="25%" />
              </p>
            </td>
          </PS.ValueTableRow>
          <PS.DateTableRow>
            <td>
              <p>Recebido em</p>
            </td>
            <td>
              <p>
                <LoadingItem width="30%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow className="session">
            <td>
              <p>Chave pix</p>
            </td>
            <td>
              <p>
                <LoadingItem width="50%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow>
            <td>
              <p>De</p>
            </td>
            <td>
              <p>
                <LoadingItem width="90%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow>
            <td>
              <p>CPF/CNPJ</p>
            </td>
            <td>
              <p>
                <LoadingItem width="30%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow>
            <td>
              <p>Instituição</p>
            </td>
            <td>
              <p>
                <LoadingItem width="50%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow>
            <td>
              <p>Agência</p>
            </td>
            <td>
              <p>
                <LoadingItem width="20%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow>
            <td>
              <p>Conta</p>
            </td>
            <td>
              <p>
                <LoadingItem width="30%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow className="session">
            <td>
              <p>Tipo</p>
            </td>
            <td>
              <p>
                <LoadingItem width="40%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow>
            <td>
              <p>Código da operação</p>
            </td>
            <td>
              <p>
                <LoadingItem width="60%" />
              </p>
            </td>
          </PS.DateTableRow>
          <PS.DateTableRow className="session">
            <td>
              <p>Código de autenticação</p>
            </td>
            <td>
              <p>
                <LoadingItem width="70%" />
              </p>
            </td>
          </PS.DateTableRow>
        </tbody>
      </PS.Table>
    </PS.TableDataTransaction>
  </PS.ContentContainer>
);

export default LoadingDetails;
