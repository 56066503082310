import getCompaniesStoreApi from 'api/client-selector';

import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/clientSelector';

export default function* getCompaniesStore(params) {
  try {
    const response = yield call(getCompaniesStoreApi, params.currentClientUuid);

    const {
      data: {
        data: {
          currentUser: { clientsSelector }
        }
      }
    } = response;

    yield put(ActionCreators.getCompaniesStoreSuccess(clientsSelector));
  } catch (error) {
    yield put(ActionCreators.getCompaniesStoreFailure());
  }
}
