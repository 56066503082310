import React from 'react';
import PropTypes from 'prop-types';
import AntiFraudWarning from '../components/WarningsContent/AntiFraudWarning';
import CanceledAccountWarning from '../components/WarningsContent/CanceledAccountWarning';
import AccountNotReadyWarning from '../components/WarningsContent/AccountNotReadyWarning';
import ProcessedCafStatusWarning from '../components/WarningsContent/ProcessedCafStatusWarning';
import FailedCafStatusWarning from '../components/WarningsContent/FailedCafStatusWarning';
import ForceCafStatusWarning from '../components/WarningsContent/ForceCafStatusWarning';
import InvalidClientAfterSignup from '../components/WarningsContent/InvalidClientAfterSignup';
import BlockedClient from '../components/WarningsContent/BlockedClient';

const Warnings = ({
  userHasClientsInAntifraudAnalysis,
  userHasClientsInCanceledState,
  userProcessedCafStatus,
  userFailedCafStatus,
  userForceCafStatus,
  userHasInvalidClientAfterSignup,
  userHasBlockedLogin,
}) => {
  if (userHasInvalidClientAfterSignup) {
    return <InvalidClientAfterSignup />;
  }
  if (userHasBlockedLogin) {
    return <BlockedClient />;
  }
  if (userHasClientsInAntifraudAnalysis) {
    return <AntiFraudWarning />;
  }
  if (userHasClientsInCanceledState) {
    return <CanceledAccountWarning />;
  }
  if (userProcessedCafStatus) {
    return <ProcessedCafStatusWarning />;
  }
  if (userFailedCafStatus) {
    return <FailedCafStatusWarning />;
  }
  if (userForceCafStatus) {
    return <ForceCafStatusWarning />;
  }

  return <AccountNotReadyWarning />;
};

Warnings.propTypes = {
  userHasClientsInAntifraudAnalysis: PropTypes.bool.isRequired,
  userHasClientsInCanceledState: PropTypes.bool.isRequired,
  userProcessedCafStatus: PropTypes.bool,
  userFailedCafStatus: PropTypes.bool,
  userForceCafStatus: PropTypes.bool,
  userHasInvalidClientAfterSignup: PropTypes.bool,
  userHasBlockedLogin: PropTypes.bool,
};

Warnings.defaultProps = {
  userProcessedCafStatus: false,
  userFailedCafStatus: false,
  userForceCafStatus: false,
  userHasInvalidClientAfterSignup: false,
  userHasBlockedLogin: false,
};

export default Warnings;
