import React from 'react';
import PropsType from 'prop-types';

import { useMedia } from 'react-use';

import { GlobalStyle, Wrapper, WrapperMobile } from './PageView.styles';

import PageViewHeader from './PageViewHeader';

const PageView = ({ children, header, title, backLink }) => {
  const isMobile = useMedia('(max-width: 768px)');

  if (isMobile)
    return (
      <WrapperMobile className="ocean-iso">
        <div className="pgnt-page">
          <PageViewHeader title={title} backLink={backLink} />
          {children}
        </div>
        <GlobalStyle />
      </WrapperMobile>
    );
  return (
    <Wrapper className="ocean-iso">
      <div className="pgnt-page">
        {header}
        {children}
      </div>
      <GlobalStyle />
    </Wrapper>
  );
};

PageView.propTypes = {
  header: PropsType.node.isRequired,
  children: PropsType.node.isRequired,
  title: PropsType.string.isRequired,
  backLink: PropsType.string.isRequired,
};

export default PageView;
