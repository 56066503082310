import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ClientStatusCreators from 'store/ducks/clientStatus';
import useModalManagement from 'components/Dashboard/hooks/useModalManagement';
import { Button, Progress, Typography } from '@useblu/ocean-react';
import { formatDate, normalizeSeparator } from 'normalizers';
import { ChevronRight } from '@useblu/ocean-icons-react';
import { railsContextSelector } from 'selectors/rails-context';
import { useSelector, useDispatch } from 'react-redux';

import { getClientDetails } from 'api/clients';
import * as S from './styles';

import emptyIlustra from './images/empty-ilustra.svg';

const AutomaticAnticipationForm = ({
  bTokenActive,
  openSnackBar,
  automaticAnticipationFixRate,
  automaticAnticipationWith2FA,
  automaticAnticipationVariableRate,
}) => {
  const [automaticAnticipationKind, setAutomaticAnticipationKind] =
    useState(null);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const { openModal } = useModalManagement();

  const {
    loading: reduxLoading,
    automaticAnticipationOptInConfirmed,
    automaticAnticipationEnabledAt,
  } = useSelector((state) => state.clientStatus);

  const { clientId, clientClientId } = useSelector(railsContextSelector);
  const dispatch = useDispatch();

  const clientUUID = clientId || clientClientId;

  const [cancelClicked, setCancelClicked] = useState(false);

  const getAutomaticAnticipationKind = useCallback(() => {
    setLoadingScreen(true);
    getClientDetails(clientUUID)
      .then((response) => {
        const { automatic_anticipation_kind } = response.data;
        setAutomaticAnticipationKind(automatic_anticipation_kind || null);
        setLoadingScreen(false);
      })
      .catch(() => {
        setLoadingScreen(false);
      });
  }, [clientUUID]);

  useEffect(() => {
    dispatch(ClientStatusCreators.getClientStatusRequest(clientUUID));
    getAutomaticAnticipationKind();
  }, [clientUUID, dispatch, getAutomaticAnticipationKind]);

  useEffect(() => {
    if (!automaticAnticipationOptInConfirmed && cancelClicked) {
      openSnackBar('Antecipação Automática desativada.', 'success');
    }
  }, [automaticAnticipationOptInConfirmed, cancelClicked]);

  useEffect(() => {
    if (automaticAnticipationOptInConfirmed) {
      getAutomaticAnticipationKind();
    }
  }, [automaticAnticipationOptInConfirmed, getAutomaticAnticipationKind]);

  const onAutomaticAnticipationChange = () => {
    if (!bTokenActive && automaticAnticipationWith2FA) {
      return openModal('BTokenForceActivationOnTransaction');
    }

    const modal = automaticAnticipationOptInConfirmed
      ? 'AutomaticAnticipationOptOut'
      : 'AutomaticAnticipation';

    return openModal(modal);
  };

  const onCancel = () => {
    setCancelClicked(true);
    onAutomaticAnticipationChange();
  };

  const openMoreAbout = () => {
    window.open(
      'https://comunidade.blu.com.br/post/antecipacao-automatica?utm_source=portal-blu&utm_medium=link&utm_campaign=pagina-aa-botaosaibamais',
      '_blank'
    );
  };

  const loading = reduxLoading || loadingScreen;

  if (loading) {
    return (
      <S.LoadingContainer>
        <Progress size="lg" />
      </S.LoadingContainer>
    );
  }

  const isVariableAnticipation =
    (automaticAnticipationKind &&
      automaticAnticipationKind === 'automatic_anticipation_by_installments') ||
    !automaticAnticipationKind ||
    !automaticAnticipationOptInConfirmed;

  return (
    <>
      {automaticAnticipationOptInConfirmed ? (
        <S.InputContainer>
          <S.InputRow>
            <S.DescriptionContainer htmlFor="switch-automatic-anticipation">
              Custo de antecipação
              <Typography variant="description">
                {isVariableAnticipation
                  ? `A partir de ${normalizeSeparator(
                      automaticAnticipationVariableRate
                    )}% + ${normalizeSeparator(
                      automaticAnticipationVariableRate / 2
                    )}% por parcela`
                  : `${automaticAnticipationFixRate}%`}
              </Typography>
              <Typography variant="caption">
                {formatDate(
                  automaticAnticipationEnabledAt,
                  `[Operação realizada em] DD MMM YYYY[, às] HH:mm`
                )}
              </Typography>
              <S.CustomTag type="positive" setIconOff>
                Ativo
              </S.CustomTag>
            </S.DescriptionContainer>
            <S.InputButton
              onClick={loading ? null : onCancel}
              className={classNames({
                active: automaticAnticipationOptInConfirmed,
                loading,
              })}
            >
              {loading ? (
                <Progress />
              ) : (
                <>
                  <span>Desativar</span>
                  <ChevronRight />
                </>
              )}
            </S.InputButton>
          </S.InputRow>
        </S.InputContainer>
      ) : (
        <S.EmptyContainer>
          <S.EmptyImage>
            <img src={emptyIlustra} alt="empty-ilustra" />
          </S.EmptyImage>
          <S.EmptyDescription>
            <Typography variant="heading4">
              Antecipe suas vendas automaticamente e proteja seu fluxo de caixa
            </Typography>
            <Typography variant="description">
              Receba suas vendas feitas no crédito, de 1x a 12x, em até 1 dia
              útil, com taxas competitivas, e pensadas especialmente para o seu
              negócio.
            </Typography>
            <Typography variant="caption">
              O custo se ajusta ao número de parcelas, para que você gaste ainda
              menos com taxas.
            </Typography>
            <S.Actions>
              <Button
                variant="primary"
                size="sm"
                onClick={loading ? null : onAutomaticAnticipationChange}
              >
                Ativar agora
              </Button>
              <Button variant="text" size="sm" onClick={openMoreAbout}>
                Saiba mais
              </Button>
            </S.Actions>
          </S.EmptyDescription>
        </S.EmptyContainer>
      )}
    </>
  );
};

AutomaticAnticipationForm.propTypes = {
  bTokenActive: PropTypes.bool.isRequired,
  openSnackBar: PropTypes.func.isRequired,
  automaticAnticipationWith2FA: PropTypes.bool,
  automaticAnticipationFixRate: PropTypes.string.isRequired,
  automaticAnticipationVariableRate: PropTypes.string.isRequired,
};

AutomaticAnticipationForm.defaultProps = {
  automaticAnticipationWith2FA: false,
};

export default AutomaticAnticipationForm;
