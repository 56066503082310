import { Typography } from '@useblu/ocean-react';
import styled from 'styled-components';
import {
  spacingInlineXxxs,
  spacingInlineXxs,
  spacingInlineXs,
  colorBrandPrimaryDown,
  colorInterfaceDarkDown,
  colorComplementaryPure,
  colorInterfaceLightUp
} from '@useblu/ocean-tokens/web/tokens';

export const Wrapper = styled.div`
  display: grid;

  .ods-badge {
    margin-bottom: 8px;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: end;

    @media screen and (max-width: 576px) {
      button {
        width: 100%;
      }
    }
  }

  > img {
    max-width: 155px;
    height: auto;
    min-height: 84px;
    margin: 0 auto;
    margin-bottom: 16px;
  }

  ul {
    margin-bottom: 16px;
    display: grid;
    margin-top: 20px;
    row-gap: 12px;

    @media screen and (max-width: 576px) {
      margin-top: 0;
      margin-bottom: 24px;
    }

    li {
      display: flex;
      gap: ${spacingInlineXs};
      align-items: start;

      svg {
        color: ${colorBrandPrimaryDown};
      }

      > div {
        h4 {
          margin-bottom: ${spacingInlineXxxs};
          color: ${colorInterfaceDarkDown};

          @media screen and (max-width: 576px) {
            display: none;
          }
        }
      }
    }
  }

  button {
    min-width: 294px;
    margin-left: calc(100% - 294px);

    @media screen and (max-width: 576px) {
      min-width: 100%;
      margin: 0;
    }
  }
`;

export const Caption = styled(Typography).attrs({
  variant: 'description'
})`
  &&& {
    color: ${colorComplementaryPure};
    margin-top: ${spacingInlineXs};
    margin-bottom: ${spacingInlineXxs};
  }
`;

export const Title = styled(Typography).attrs({
  variant: 'heading3'
})`
  &&& {
    margin-bottom: 8px;

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }
`;

export const Description = styled(Typography).attrs({
  variant: 'description'
})`
  &&& {
    @media screen and (max-width: 576px) {
      margin-bottom: 20px;
    }
  }
`;

export const Icon = styled.div`
  min-width: 21px;
  min-height: 21px;
  display: grid;
  place-content: center;
  border-radius: 25px;
  background: ${colorInterfaceLightUp};

  font-size: 14px;
  /* Brand Primary/Down */
  color: #5872f5;

  @media screen and (max-width: 576px) {
    background: transparent;
  }
`;
