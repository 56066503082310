import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { normalizeCurrency } from 'normalizers';
import moment from 'utils/moment.pt-br';
import PaymentPlanStatus from './PaymentPlanStatus';
import { Container, Row } from './Styles';

const Table = styled(Container)`
  border: solid 1px rgba(108, 121, 161, 0.4);
  border-radius: 8px;
  padding: 10px 0px;
  row-gap: 20px;
`;

export const TableHeader = styled(Row)`
  font-weight: 600;
  font-size: 16px;
  color: rgba(108, 121, 161, 0.6);
  border-bottom: solid 1px rgba(108, 121, 161, 0.2);
  padding-bottom: 2px;
`;

export const ValueColumn = styled.span`
  justify-self: flex-end;
`;

const PaymentPlanHistory = ({ payments }) => (
  <Table data-testid="payment-plan-tableless">
    <TableHeader>
      <span>Nº da Nota</span>
      <span>Data</span>
      <span>Status</span>
      <ValueColumn>Valor</ValueColumn>
    </TableHeader>
    {payments.map((payment) => (
      <Row key={payment.invoiceNumber}>
        <span>{payment.invoiceNumber}</span>
        <span>{moment(payment.billedDate).format('L')}</span>
        <PaymentPlanStatus
          type={payment.status.type}
          name={payment.status.name}
        />
        <ValueColumn>R$ {normalizeCurrency(payment.billedValue)}</ValueColumn>
      </Row>
    ))}
  </Table>
);

PaymentPlanHistory.propTypes = {
  payments: PropTypes.arrayOf(
    PropTypes.shape({
      invoiceNumber: PropTypes.string.isRequired,
      billedDate: PropTypes.string.isRequired,
      billedValue: PropTypes.number.isRequired,
      status: PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};

export default PaymentPlanHistory;
