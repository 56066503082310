/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { Button, Typography } from '@useblu/ocean-react';
import { getIn } from 'formik';
import { DocumentTextOutline, TrashOutline } from '@useblu/ocean-icons-react';
import { colorStatusNegativePure } from '@useblu/ocean-tokens/web/tokens';

import {
  DragContainerStyled,
  List,
  FileContainer,
  IconButton
} from './DropZone.styles';

const DropZone = ({ field, form: { touched, errors, setFieldValue } }) => {
  const [loading, setLoading] = useState(false);
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  useEffect(() => {
    if (field.value) setLoading(true);
  }, [field.value]);

  useInterval(
    () => {
      setLoading(false);
    },
    loading ? 800 : null
  );

  if (field.value && !loading) {
    return (
      <FileContainer>
        <DocumentTextOutline size={48} />
        <Typography variant="description">{field.value.name}</Typography>
        <IconButton
          aria-label="Remover arquivo"
          onClick={() => setFieldValue(field.name, null)}
        >
          <TrashOutline color={colorStatusNegativePure} />
        </IconButton>
      </FileContainer>
    );
  }

  return (
    <div>
      <DragContainerStyled
        name={field.name}
        onSuccess={(e) => setFieldValue(field.name, e.target.files[0])}
        loading={loading}
        error={showError}
      >
        <Typography variant="heading3">Arraste aqui o arquivo CNAB</Typography>
        <Typography variant="paragraph">Formato: CNAB</Typography>
        <List>
          <li>
            <Typography variant="description">
              O arquivo deve estar no padrão Unicode (UTF-8)
            </Typography>
          </li>
          <li>
            <Typography variant="description">
              O tamanho máximo do arquivo deve ser de 2MB
            </Typography>
          </li>
        </List>
        <Button
          type="button"
          className="transfer-upload-button"
          variant="secondary"
        >
          Escolher arquivo CNAB
        </Button>
      </DragContainerStyled>
      {showError && (
        <p className="ods-form-control__helper-text ods-form-control__helper-text--error">
          {fieldError}
        </p>
      )}
    </div>
  );
};

export default DropZone;
