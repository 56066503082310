import React from 'react';
import PropTypes from 'prop-types';
import RangeSelector from './RangeSelector';

import { DateObject, range, indexInRange } from './DateHelper';

const MonthDateFilter = ({ selected, onChange }) => {
  const initialDate = DateObject(selected);
  const dates = range(selected, 'M');
  const initialIndex = indexInRange(dates, initialDate);

  return (
    <RangeSelector
      selected={initialIndex}
      range={dates}
      item={(date) => (
        <>
          <span>{date.month}</span>
          <small>{date.year}</small>
        </>
      )}
      onChange={onChange}
    />
  );
};

MonthDateFilter.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MonthDateFilter;
