import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import { normalizeCurrency } from 'normalizers';

const RefundSimulationCurrentOutflows = ({
  refundOutflow,
  advancesRateValue
}) => (
  <div className="refund-simulation-result-table__current-outflows">
    <div className="refund-simulation-result-table__subheader">SALDO ATUAL</div>
    <div className="refund-simulation-result-table__row">
      <div className="refund-simulation-result-table__cell">
        Devol. Cobrança
      </div>
      <div className="refund-simulation-result-table__cell">
        {moment(refundOutflow.releasedAt).format('DD/MM/YYYY')}
      </div>
      <div className="refund-simulation-result-table__cell refund-simulation-result-table__cell--money">
        -R$ {normalizeCurrency(refundOutflow.netValue)}
      </div>
    </div>
    <div className="refund-simulation-result-table__row">
      <div className="refund-simulation-result-table__cell">
        Taxa Antecipação
        <span className="refund-simulation-result-table__advances-rate-tooltip">
          <i
            data-for="refund-simulation-result-table__advances-rate-tooltip"
            data-tip
            className="fa fa-info-circle"
          />
          <ReactTooltip
            id="refund-simulation-result-table__advances-rate-tooltip"
            place="top"
            effect="solid"
            type="light"
            border
            clickable
            className="refund-simulation-result-table__advances-rate-tooltip-content"
          >
            Taxa cobrada se houver parcela do recebimento já antecipada ou
            recebida e saldo atual não suficiente para a devolução.
          </ReactTooltip>
        </span>
      </div>
      <div className="refund-simulation-result-table__cell">
        {moment(refundOutflow.releasedAt).format('DD/MM/YYYY')}
      </div>
      <div className="refund-simulation-result-table__cell refund-simulation-result-table__cell--money">
        -R$ {normalizeCurrency(advancesRateValue)}
      </div>
    </div>
  </div>
);

RefundSimulationCurrentOutflows.propTypes = {
  refundOutflow: PropTypes.object.isRequired,
  advancesRateValue: PropTypes.number.isRequired
};

export default RefundSimulationCurrentOutflows;
