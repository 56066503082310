import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RefundSimulationStepper = ({ steps, activeIndex }) => (
  <div className="refund-payment-collection__stepper">
    <div className="refund-payment-collection__stepper-inner">
      {steps.map(({ label }, index) => {
        const isCompleted = index < activeIndex;
        const isActived = activeIndex === index;

        return (
          <Fragment key={label}>
            <div className="refund-payment-collection__step">
              <span className="refund-payment-collection__step-inner">
                <span className="refund-payment-collection__step-icon-container">
                  <svg
                    className={classNames(
                      'refund-payment-collection__step-icon',
                      {
                        'refund-payment-collection__step-icon--active':
                          isActived || isCompleted
                      }
                    )}
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    role="presentation"
                  >
                    {isCompleted ? (
                      <path d="M12 0a12 12 0 1 0 0 24 12 12 0 0 0 0-24zm-2 17l-5-5 1.4-1.4 3.6 3.6 7.6-7.6L19 8l-9 9z" />
                    ) : (
                      <Fragment>
                        <circle cx="12" cy="12" r="12" />
                        <text
                          className="refund-payment-collection__step-icon-text"
                          x="12"
                          y="16"
                          textAnchor="middle"
                        >
                          {index + 1}
                        </text>
                      </Fragment>
                    )}
                  </svg>
                </span>
                <span className="refund-payment-collection__step-label-container">
                  <span
                    className={classNames(
                      'refund-payment-collection__step-label',
                      {
                        'refund-payment-collection__step-label--active':
                          isActived || isCompleted
                      }
                    )}
                  >
                    {label}
                  </span>
                </span>
              </span>
            </div>
            {index + 1 < steps.length && (
              <div className="refund-payment-collection__step-connector">
                <span className="refund-payment-collection__step-connector-line" />
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  </div>
);

RefundSimulationStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeIndex: PropTypes.number
};

RefundSimulationStepper.defaultProps = {
  activeIndex: 0
};

export default RefundSimulationStepper;
