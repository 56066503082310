import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AgendaFilter = ({
  unavailableCount,
  unavailablePath,
  allTransactionsPath,
  selectedStatus
}) => {
  const hasUnavailable = unavailableCount > 0 || unavailableCount === -1;

  const unavailableActiveClass = classNames({
    active: selectedStatus === 'unavailable'
  });

  return (
    hasUnavailable && (
      <div className="statement__filter">
        <div className="statement__filter-info">
          <div className="statement__filter-info-right">
            <div className="statement__filter-tab-link-container">
              <a href={allTransactionsPath} className="all-transactions-link">
                <span className={classNames({ active: !selectedStatus })}>
                  Todas
                </span>
              </a>

              <span className="statement__filter-pending-separator display-web">
                |
              </span>
              <a
                href={unavailablePath}
                className={`statement__filter-pending-link ${unavailableActiveClass}`}
              >
                <span
                  className={`pending-link-description ${unavailableActiveClass}`}
                >
                  Travados por outra instituição
                </span>
                {unavailableCount > 0 && (
                  <span className="unavailable-link-counter">
                    {unavailableCount}
                  </span>
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

AgendaFilter.propTypes = {
  unavailableCount: PropTypes.number,
  unavailablePath: PropTypes.string,
  allTransactionsPath: PropTypes.string,
  selectedStatus: PropTypes.string
};

AgendaFilter.defaultProps = {
  unavailableCount: null,
  unavailablePath: '',
  allTransactionsPath: '',
  selectedStatus: ''
};

export default AgendaFilter;
