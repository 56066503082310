import styled from 'styled-components';

import {
  colorBrandPrimaryDeep,
  colorBrandPrimaryDown,
  colorBrandPrimaryPure,
  colorComplementaryPure,
  colorInterfaceLightUp
} from '@useblu/ocean-tokens/web/tokens';

import HandsPNG from './images/hands.png';

export const Container = styled.div`
  max-width: 1270px;
  width: 100%;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colorInterfaceLightUp};

  @media screen and (max-width: 1025px) {
    background-color: #fff;
  }

  ${Container} {
    height: 530px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    background-image: url('${HandsPNG}');
    background-repeat: no-repeat;
    background-position: 65%;
    background-size: 65%;
    padding: 132px 32px 70px 56px;

    @media screen and (max-width: 1225px) {
      background-size: 76%;
    }

    @media screen and (max-width: 1025px) {
      flex-direction: column;
      height: auto;
      background-image: none;
      padding: 32px 32px 32px 32px;
      align-items: center;
    }
  }
`;

export const Icon = styled.img`
  width: 92px;
  height: 86px;

  @media screen and (max-width: 1025px) {
    width: 48px;
    height: 46px;
  }
`;

export const Hands = styled.div`
  display: none;

  @media screen and (max-width: 1025px) {
    display: block;
    width: 300px;
    height: 280px;
    margin-bottom: 32px;

    ::before {
      display: block;
      content: '';
      width: 250px;
      height: 280px;
      background-color: ${colorInterfaceLightUp};
      border-top-right-radius: 90px;
    }

    ::after {
      display: block;
      content: '';
      background-image: url('${HandsPNG}');
      width: 300px;
      height: 280px;
      position: relative;
      top: -280px;
      background-size: cover;
    }
  }
`;

export const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  line-height: 110%;
  margin-bottom: 8px;

  @media screen and (max-width: 1025px) {
    font-size: 24px;
  }
`;

export const Description = styled.div`
  p {
    font-size: 24px;
    line-height: 110%;

    @media screen and (max-width: 1025px) {
      font-size: 16px;
    }
  }
`;

export const FixedTaxContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${Title}, ${Description} {
    color: ${colorBrandPrimaryPure};
  }

  ${Description} {
    width: 450px;
  }

  ${Icon} {
    margin-left: -40px;
    margin-top: -28px;

    @media screen and (max-width: 1025px) {
      margin-left: -20px;
      margin-top: -14px;
    }
  }

  @media screen and (max-width: 1025px) {
    ${Description} {
      width: calc(100% - 48px);
      max-width: 268px;
    }
  }
`;

export const CashbackContainer = styled.div`
  ${Title}, ${Description} {
    color: ${colorComplementaryPure};
  }

  ${Description} {
    width: 275px;
  }

  ${Icon} {
    margin: 8px;
  }

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: row;

    ${Description} {
      width: calc(100% - 48px);
      max-width: 268px;
    }
  }
`;

export const PaymentDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 288px;
  background-color: ${colorInterfaceLightUp};

  background: ${colorInterfaceLightUp};
  background: linear-gradient(
    180deg,
    ${colorBrandPrimaryDown} 45%,
    ${colorInterfaceLightUp} 45%,
    ${colorInterfaceLightUp} 100%
  );

  color: ${colorBrandPrimaryDeep};
  font-weight: 300;
  font-size: 24px;
  line-height: 110%;

  ${Container} {
    padding: 30px 163px;
    width: 100%;
    display: flex;
    flex-direction: row;

    img {
      max-width: 240px;
      max-height: 285px;
      width: 50%;
      margin-right: 90px;

      @media screen and (max-width: 1225px) {
        margin-right: 32px;
      }

      @media screen and (max-width: 1025px) {
        font-size: 18px;
      }

      @media screen and (max-width: 720px) {
        font-size: 13px;
      }
    }

    p {
      max-width: 493px;
    }

    p:first-child {
      height: 102px;
      color: #fff;
    }

    p:last-child {
      color: ${colorBrandPrimaryDeep};
    }

    @media screen and (max-width: 1025px) {
      padding: 30px 20px;
    }
  }
  @media screen and (max-width: 1225px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1025px) {
    font-size: 18px;
  }
  @media screen and (max-width: 720px) {
    font-size: 12px;
  }
  @media screen and (max-width: 320px) {
    font-size: 10px;
  }
`;

export default Container;
