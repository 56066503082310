import React from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

export default class Lottie extends React.Component {
  componentDidMount() {
    const { options, eventListeners } = this.props;

    const {
      loop,
      autoplay,
      animationData,
      rendererSettings,
      segments
    } = options;

    this.options = {
      container: this.el,
      renderer: 'svg',
      loop: loop !== false,
      autoplay: autoplay !== false,
      segments: segments !== false,
      animationData,
      rendererSettings
    };

    this.options = { ...this.options, ...options };

    this.anim = lottie.loadAnimation(this.options);
    this.setSpeed();
    this.setDirection();
    this.registerEvents(eventListeners);
  }

  componentDidUpdate(prevProps) {
    if (this.options.animationData !== prevProps.options.animationData) {
      const { eventListeners } = this.props;
      this.deRegisterEvents(eventListeners);
      this.destroy();
      this.options = { ...this.options, ...prevProps.options };
      this.anim = lottie.loadAnimation(this.options);
      this.registerEvents(prevProps.eventListeners);
    } else {
      const { isStopped, segments, forceSegments } = this.props;

      if (isStopped) {
        this.stop();
      } else if (segments) {
        const shouldForce = !!forceSegments;
        this.playSegments(shouldForce);
      } else {
        this.play();
      }

      this.pause();
      this.setSpeed();
      this.setDirection();
    }
  }

  componentWillUnmount() {
    const { eventListeners } = this.props;

    this.deRegisterEvents(eventListeners);
    this.destroy();
    this.options.animationData = null;
    this.anim = null;
  }

  setSpeed() {
    const { speed } = this.props;

    this.anim.setSpeed(speed);
  }

  setDirection() {
    const { direction } = this.props;

    this.anim.setDirection(direction);
  }

  play() {
    this.anim.play();
  }

  playSegments(shouldForce) {
    const { segments } = this.props;

    this.anim.playSegments(segments, shouldForce);
  }

  stop() {
    this.anim.stop();
  }

  pause() {
    const { isPaused } = this.props;

    if (isPaused && !this.anim.isPaused) {
      this.anim.pause();
    } else if (!isPaused && this.anim.isPaused) {
      this.anim.pause();
    }
  }

  destroy() {
    this.anim.destroy();
  }

  registerEvents(eventListeners) {
    eventListeners.forEach((eventListener) => {
      this.anim.addEventListener(
        eventListener.eventName,
        eventListener.callback
      );
    });
  }

  deRegisterEvents(eventListeners) {
    eventListeners.forEach((eventListener) => {
      this.anim.removeEventListener(
        eventListener.eventName,
        eventListener.callback
      );
    });
  }

  render() {
    const { width, height, ariaRole, ariaLabel, title, style } = this.props;

    const getSize = (initial) => {
      let size;

      if (typeof initial === 'number') {
        size = `${initial}px`;
      } else {
        size = initial || '100%';
      }

      return size;
    };

    const lottieStyles = {
      width: getSize(width),
      height: getSize(height),
      overflow: 'hidden',
      margin: '0 auto',
      outline: 'none',
      ...style
    };

    return (
      // Bug with eslint rules https://github.com/airbnb/javascript/issues/1374
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        ref={(c) => {
          this.el = c;
        }}
        style={lottieStyles}
        title={title}
        role={ariaRole}
        aria-label={ariaLabel}
        tabIndex="0"
      />
    );
  }
}

Lottie.propTypes = {
  eventListeners: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStopped: PropTypes.bool,
  isPaused: PropTypes.bool,
  speed: PropTypes.number,
  segments: PropTypes.arrayOf(PropTypes.number),
  forceSegments: PropTypes.bool,
  direction: PropTypes.number,
  ariaRole: PropTypes.string,
  ariaLabel: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object
};

Lottie.defaultProps = {
  eventListeners: [],
  isStopped: false,
  isPaused: false,
  speed: 1,
  ariaRole: 'button',
  ariaLabel: 'animation',
  title: '',
  height: '',
  width: '',
  segments: [],
  forceSegments: false,
  direction: 1,
  style: {}
};
