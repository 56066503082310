import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@useblu/ocean-react';

import ISearch from 'images/icons/search.svg';

import * as S from './styles';

const CallToAction = ({ clientId, clientAA }) => (
  <>
    <S.Wrapper>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col xs={12}>
            <S.Icon>
              <img src={ISearch} alt="Fornecedores" />
            </S.Icon>
            <S.Title>
              São mais de 2.500 fornecedores parceiros para você economizar e
              comprar sem análise de crédito.
            </S.Title>

            <S.SubTitle>
              Encontre agora o fornecedor ideal para o seu negócio!
            </S.SubTitle>

            <S.Button
              onClick={() =>
                window.open(
                  `https://fornecedores.blu.com.br/?utm_source=portal_blu&utm_medium=botao&utm_campaign=solucoes_pagamento&utm_content=&uid=${clientId}`,
                  '_blank'
                )
              }
              role="button"
              variant="secondary"
              size="md"
              className={
                clientAA
                  ? `lp_botao3_campanha_solucoesdepagamento_aa`
                  : `lp_botao3_campanha_solucoesdepagamento`
              }
            >
              Encontrar fornecedor
            </S.Button>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </S.Wrapper>
  </>
);

CallToAction.propTypes = {
  clientId: PropTypes.number.isRequired,
  clientAA: PropTypes.bool.isRequired
};

export default CallToAction;
