import React, { useEffect } from 'react';
import { useInterval } from 'react-use';
import PropTypes from 'prop-types';
import {
  FakeChatContainer,
  Chat,
  ChatBubble,
  ChatScroll,
  ChatTyping,
} from './FakeChat.styles';

const FakeChat = ({ lastMessage }) => {
  const loadingStateMessages = [
    <>
      Estamos conferindo seus dados de cadastro e preparando o ambiente da sua
      conta...
    </>,
  ];

  const [messages, setMessages] = React.useState([]);
  const [lastAddedMessage, setLastAddedMessage] = React.useState(0);
  const [hasLoading, toggleLoading] = React.useState(true);

  const addMessage = (message) => setMessages((e) => [...e, message]);

  const addStatusMessage = (lastMessage) => {
    if (!lastMessage && loadingStateMessages.length > lastAddedMessage) {
      addMessage(loadingStateMessages[lastAddedMessage]);
      setLastAddedMessage(lastAddedMessage + 1);
    } else {
      toggleLoading(false);
    }
  };

  const isIntervalActivated = loadingStateMessages.length > lastAddedMessage;

  useInterval(
    () => {
      addStatusMessage();
    },
    isIntervalActivated ? 5000 : null
  );

  useEffect(() => {
    addStatusMessage(lastMessage);
  }, [lastMessage]);

  return (
    <ChatScroll>
      <div>
        <FakeChatContainer>
          <Chat>
            {messages.map((message) => (
              <div data-testid="msg" key={message}>
                {message}
              </div>
            ))}
            {lastMessage && <div data-testid="msg">{lastMessage}</div>}
          </Chat>
        </FakeChatContainer>
        {hasLoading && (
          <ChatBubble>
            <ChatTyping>
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </ChatTyping>
          </ChatBubble>
        )}
      </div>
    </ChatScroll>
  );
};

FakeChat.propTypes = {
  lastMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

FakeChat.defaultProps = {
  lastMessage: '',
};

export default FakeChat;
