import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useMenuOpen from '../hooks/useMenuOpen';

import {
  DrawerMenuContainer,
  MobileBackDrop,
  MobileBar,
  Content
} from './DrawerMenu.styles';

// ATTENTION:
// This is a self contained component, it should be replaced by the dropdown
// from ocean asap.

const DrawerMenu = ({
  displayClass,
  title,
  children,
  action,
  width,
  position
}) => {
  const {
    classes,
    actionsProps,
    containerProps,
    isOpen,
    isContentMounted,
    ref
  } = useMenuOpen(action);

  return (
    <DrawerMenuContainer
      className={displayClass}
      ref={ref}
      data-testid={title}
      {...(action === 'hover' && containerProps)}
    >
      <button
        type="button"
        id="open"
        data-testid="open"
        {...actionsProps}
        className={classNames({ 'is-open': isOpen })}
      >
        {children[0]}
      </button>

      <MobileBackDrop onClick={actionsProps.onClick} className={classes} />

      {children[1] && isContentMounted && (
        <Content
          position={position}
          className={classes}
          data-testid="content"
          style={{ width }}
        >
          <MobileBar onClick={actionsProps.onClick} />
          {children[1]}
        </Content>
      )}
    </DrawerMenuContainer>
  );
};

DrawerMenu.propTypes = {
  action: PropTypes.oneOf(['click', 'hover']),
  position: PropTypes.oneOf(['left', 'right']),
  displayClass: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  width: PropTypes.string,
  title: PropTypes.string
};

DrawerMenu.defaultProps = {
  width: '220px',
  action: 'click',
  position: 'right',
  title: ''
};

export default DrawerMenu;
