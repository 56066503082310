import styled from 'styled-components';

import { colorStatusNegativePure } from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;

  & .ods-form-control__root {
    width: 48px;

    & .ods-input {
      height: 48px;
      width: 48px;
    }

    & input {
      text-align: center;
    }
  }
`;

export const ErrorMessage = styled.p`
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;

  color: ${colorStatusNegativePure};

  margin: 4px 0 0 0;
`;
