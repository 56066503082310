import styled, { createGlobalStyle } from 'styled-components';

import { Devices } from 'utils/getDeviceMedia';
import {
  fontFamilyBase,
  fontSizeXs,
  fontSizeMd,
  spacingInsetXxs,
  spacingInlineSm,
  spacingInsetLg,
  borderRadiusSm,
  colorInterfaceLightDown,
  colorInterfaceDarkDown,
  colorInterfaceDarkDeep,
  spacingInsetSm,
  fontSizeXxs,
  fontSizeSm,
  spacingStackSm,
  spacingStackXs,
  spacingStackXxs,
  spacingInlineXs,
  borderRadiusMd,
  spacingInlineMd,
  fontWeightBold,
  colorBrandPrimaryPure,
  colorInterfaceLightUp,
  colorStatusNegativePure,
  colorInterfaceLightPure,
  lineHeightComfy,
  spacingInsetMd,
  fontWeightMedium,
  spacingInlineXxs,
  colorInterfaceDarkUp,
} from '@useblu/ocean-tokens/web/tokens';
import { Grid, Tag } from '@useblu/ocean-react';

export const Bar = styled.div`
  margin-top: ${spacingInsetLg};
  width: 100%;

  .ods-topbar {
    flex-wrap: nowrap;
    height: auto;
  }

  .ods-topbar-title {
    margin: 0;
    font-size: ${fontSizeMd};
  }

  .ods-topbar-left {
    margin-left: 0;
  }

  @media ${Devices.mobile} {
    background-color: ${colorInterfaceLightPure};
    display: flex;
    left: 0;
    margin: 0;
    padding: 17px 16px;
    position: fixed;
    top: 0;
    z-index: 10;

    .ods-topbar {
      margin-bottom: 0;
      max-width: 540px;
    }

    .ods-topbar-title {
      font-size: ${fontSizeSm};
    }

    .ods-topbar-left {
      margin-left: 0;
      margin-right: ${spacingStackXs};
    }
  }
`;

export const InputContainer = styled.div`
  border-radius: ${borderRadiusSm};
  display: flex;
  flex-direction: column;

  @media ${Devices.mobile} {
    padding: 0 ${spacingInlineXs};
  }
`;

export const DescriptionContainer = styled.div`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  color: ${colorInterfaceDarkDeep};
  padding: ${spacingInsetSm};
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > .ods-typography__caption {
    font-weight: ${fontWeightMedium};
  }
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colorInterfaceLightDown};
  border-radius: ${borderRadiusMd};
`;

export const InputButton = styled.button`
  background: none;
  padding: ${spacingInsetSm};
  display: flex;
  font-size: ${fontSizeXxs};
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colorInterfaceLightDown};
  font-weight: ${fontWeightBold};
  color: ${colorBrandPrimaryPure};

  &:hover {
    background-color: ${colorInterfaceLightDown};
  }

  &.active {
    color: ${colorStatusNegativePure};
  }

  &.loading {
    justify-content: center;
  }
`;

export const ActivationRow = styled.div`
  border-top: 1px solid ${colorInterfaceLightDown};
  background-color: ${colorInterfaceLightUp};
`;

export const EmptyContainer = styled.div`
  display: flex;
  background: rgba(247, 249, 255, 0.64);
  border-radius: ${borderRadiusMd};
  padding: ${spacingInlineMd};
  margin-top: ${spacingStackSm};

  @media ${Devices.mobile} {
    margin: 0 ${spacingInlineXs};
    flex-direction: column;
  }
`;

export const EmptyImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${spacingInlineSm};

  @media ${Devices.mobile} {
    margin-bottom: ${spacingStackSm};
  }
`;

export const EmptyDescription = styled.div`
  flex: 2;

  & > .ods-typography__heading4 {
    color: ${colorInterfaceDarkDown};
    margin-bottom: ${spacingStackXxs};
  }

  & > .ods-typography__description {
    margin-bottom: ${spacingStackXxs};
  }

  & > .ods-typography__caption {
    margin-bottom: ${spacingStackXs};
    color: ${colorInterfaceDarkUp};
  }
`;

export const AutomaticContainer = styled(Grid.Container)`
  &&& {
    @media ${Devices.mobile} {
      position: absolute;
      left: 0;
      top: 0;
      min-width: 100%;
      min-height: 100%;
      z-index: 10;
      background-color: ${colorInterfaceLightPure};
      display: flex;

      .ods-container {
        padding: 0;
        flex: 1;
        display: flex;
        max-width: unset;
      }

      .ods-col-lg-6 {
        padding: 0;
      }
    }
  }
`;

export const Description = styled.p`
  color: ${colorInterfaceDarkDown};
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  line-height: ${lineHeightComfy};
  margin-bottom: ${spacingInsetMd};

  @media ${Devices.mobile} {
    margin: 56px 0 ${spacingInsetMd};
    padding: 0 ${spacingInlineXs};
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  padding: ${spacingStackXs};
  justify-content: center;
`;

export const CustomTag = styled(Tag)`
  position: absolute;
  right: ${spacingInlineXs};
`;

export const BTokenModalContainer = styled.div`
  display: flex;
  gap: ${spacingInlineSm};
  flex-wrap: wrap;

  & > div:nth-of-type(2) {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: ${spacingStackXs};
  }

  & > div:last-of-type {
    flex: 1 1 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media ${Devices.mobile} {
    flex-direction: column;
    align-items: center;

    & > div:nth-of-type(2) {
      text-align: center;
    }

    & > div:last-of-type {
      flex: 1;
      width: 100%;
      display: flex;

      & > button {
        flex: 1;
      }
    }
  }
`;

export const BTokenModalMargins = createGlobalStyle`
  .ods-modal__body {
    padding: 0 ${spacingInlineMd} ${spacingStackSm};
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: ${spacingInlineXxs};
`;
