/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import { Field } from 'redux-form';
import RenderField from 'components/Form/RenderField';
import Loading from 'react-loading';
import Modal from 'react-modal';
import { CollapsibleComponent } from 'react-collapsible-component';

class UsersAccountsEditModal extends Component {
  constructor(props) {
    super(props);
    this.permissions = {};
  }

  handleInputChange(e, field) {
    const { user } = this.props;
    user[field] = e.currentTarget.value;
    this.props.setUser(user);
  }

  handlePermissionChange(e, field) {
    this.permissions[field] = e.currentTarget.checked;
  }

  save() {
    const { user } = this.props;

    if (!user.userClients || user.userClients.length === 0) {
      user.userClients = [{}];
    }

    Object.keys(this.permissions).map((permission) => {
      user.userClients[0][permission] = this.permissions[permission];
      return null;
    });

    this.props.setUser(user);
    this.props.onSave();
  }

  renderUserPermissions() {
    return (
      <div>
        <div className="user-permissions">
          <h3 className="user-permission--head">PERMISSÕES</h3>
        </div>
        <div className="user-permissions">
          <ul>
            <li className="user-permission">
              <h3 className="user-permission--title">Visualização</h3>
            </li>
            <li className="user-permission">
              <div>
                <Field
                  name="currentUser.userClients[0].viewTransactions"
                  component="input"
                  className="boolean"
                  type="checkbox"
                  onChange={(e) =>
                    this.handlePermissionChange(e, 'viewTransactions')
                  }
                />
              </div>
              <label
                className="user-permission--subtitle"
                htmlFor="currentUser.userClients[0].viewTransactions"
              >
                Ver extrato
              </label>
            </li>
            <li className="user-permission">
              <div>
                <Field
                  name="currentUser.userClients[0].legalRepresentative"
                  component="input"
                  className="boolean"
                  type="checkbox"
                  onChange={(e) =>
                    this.handlePermissionChange(e, 'legalRepresentative')
                  }
                />
              </div>
              <label
                className="user-permission--subtitle"
                htmlFor="currentUser.userClients[0].legalRepresentative"
              >
                Representante legal
              </label>
            </li>
          </ul>
          <ul>
            <li className="user-permission">
              <h3 className="user-permission--title">Transferência</h3>
            </li>
            <li className="user-permission">
              <div>
                <Field
                  name="currentUser.userClients[0].createOutflowByHistory"
                  component="input"
                  className="boolean"
                  type="checkbox"
                  onChange={(e) =>
                    this.handlePermissionChange(e, 'createOutflowByHistory')
                  }
                />
              </div>
              <label
                className="user-permission--subtitle"
                htmlFor="currentUser.userClients[0].createOutflowByHistory"
              >
                Contas cadastradas
              </label>
            </li>
            <li className="user-permission">
              <div>
                <Field
                  name="currentUser.userClients[0].createOutflowToPaymentBill"
                  component="input"
                  className="boolean"
                  type="checkbox"
                  onChange={(e) =>
                    this.handlePermissionChange(e, 'createOutflowToPaymentBill')
                  }
                />
              </div>
              <label
                className="user-permission--subtitle"
                htmlFor="currentUser.userClients[0].createOutflowToPaymentBill"
              >
                Por boleto
              </label>
            </li>
            <li className="user-permission">
              <div>
                <Field
                  name="currentUser.userClients[0].createOutflowToNewBankAccount"
                  component="input"
                  className="boolean"
                  type="checkbox"
                  onChange={(e) =>
                    this.handlePermissionChange(
                      e,
                      'createOutflowToNewBankAccount'
                    )
                  }
                />
              </div>
              <label
                className="user-permission--subtitle"
                htmlFor="currentUser.userClients[0].createOutflowToNewBankAccount"
              >
                Para novas contas
              </label>
            </li>
          </ul>
          <ul>
            <li className="user-permission">
              <h3 className="user-permission--title">Pagamento a fornecedor</h3>
            </li>
            <li className="user-permission">
              <div>
                <Field
                  name="currentUser.userClients[0].createPaymentCollections"
                  component="input"
                  className="boolean"
                  type="checkbox"
                  onChange={(e) =>
                    this.handlePermissionChange(e, 'createPaymentCollections')
                  }
                />
              </div>
              <label
                className="user-permission--subtitle"
                htmlFor="currentUser.userClients[0].createPaymentCollections"
              >
                Enviar
              </label>
            </li>
            <li className="user-permission">
              <div>
                <Field
                  name="currentUser.userClients[0].managePaymentCollections"
                  component="input"
                  className="boolean"
                  type="checkbox"
                  onChange={(e) =>
                    this.handlePermissionChange(e, 'managePaymentCollections')
                  }
                />
              </div>
              <label
                className="user-permission--subtitle"
                htmlFor="currentUser.userClients[0].managePaymentCollections"
              >
                Aprovar
              </label>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  renderEditModal() {
    const { errors, type, invalid } = this.props;

    const classes = {
      afterOpen: 'modal--open',
      base: 'modal users-accounts--modal',
      beforeClose: 'modal users-accounts--modal'
    };

    const overlayClasses = {
      afterOpen: 'modal-overlay--open',
      base: 'modal-overlay',
      beforeClose: 'modal-overlay'
    };

    let cpfAdditionalProps = {};
    let emailAdditionalProps = {};
    let phoneAdditionalProps = {};

    if (
      errors &&
      errors.length > 0 &&
      errors.filter((error) => error.message === 'CPF is not valid').length > 0
    ) {
      cpfAdditionalProps = {
        meta: {
          error: 'CPF não é válido',
          touched: true
        }
      };
    }

    if (
      errors &&
      errors.length > 0 &&
      errors.filter((error) => error.message === 'Email already registered')
        .length > 0
    ) {
      emailAdditionalProps = {
        meta: {
          error: 'Este e-mail já está cadastrado',
          touched: true
        }
      };
    }

    if (
      errors &&
      errors.length > 0 &&
      errors.filter((error) => error.message === 'Phone already registered')
        .length > 0
    ) {
      phoneAdditionalProps = {
        meta: {
          error: 'Este telefone já está cadastrado',
          touched: true
        }
      };
    }

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentLabel="Modal"
        className={classes}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
      >
        <h3 className="page-subtitle">
          <i className="fa fa-user" /> Edição de Usuário
        </h3>
        <div className="modal-container">
          <div className="modal-description">
            <div className="form-row">
              <Field
                type="text"
                required
                name="currentUser.name"
                component={RenderField}
                label="Nome"
                onBlur={(e) => this.handleInputChange(e, 'name')}
              />
            </div>
            <div className="form-row">
              <Field
                type="text"
                required
                name="currentUser.cpf"
                component={RenderField}
                mask="cpf"
                label="CPF"
                onBlur={(e) => this.handleInputChange(e, 'cpf')}
                {...cpfAdditionalProps}
              />
            </div>
            <div className="form-row">
              <Field
                type="text"
                required
                name="currentUser.phone"
                component={RenderField}
                mask="phone"
                label="Telefone"
                onBlur={(e) => this.handleInputChange(e, 'phone')}
                {...phoneAdditionalProps}
              />
            </div>
            <div className="form-row">
              <Field
                type="text"
                required
                name="currentUser.email"
                component={RenderField}
                label="E-mail"
                disabled={type === 'edit'}
                onBlur={(e) => this.handleInputChange(e, 'email')}
                {...emailAdditionalProps}
              />
            </div>
            <CollapsibleComponent>
              {this.renderUserPermissions()}
            </CollapsibleComponent>
          </div>
        </div>
        <div className="modal-action">
          <button
            className="button button--secondary"
            onClick={() => this.props.onClose()}
          >
            Cancelar
          </button>
          <button
            className="button button--primary"
            onClick={() => this.save()}
            disabled={this.props.invalid}
          >
            Salvar
            {this.props.loading && (
              <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                <Loading
                  type="spinningBubbles"
                  color="#ffffff"
                  width="15px"
                  height="15px"
                  delay={0}
                />
              </div>
            )}
          </button>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <div className="flex-container">{this.renderEditModal()}</div>
      </div>
    );
  }
}

UsersAccountsEditModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  invalid: PropTypes.bool,
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({})
  ]),
  type: PropTypes.string.isRequired
};

UsersAccountsEditModal.defaultProps = {
  isOpen: false,
  loading: false,
  invalid: false
};

export default UsersAccountsEditModal;
