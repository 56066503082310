import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from 'store';
import AdvancementDetailsComponent from './AdvancementDetailsComponent';

const AdvancementDetailsOutflowNM = ({
  advancementRequestIds,
  clientUuid,
  tracking,
  transactionUUID,
}) => (
  <Provider store={store}>
    <AdvancementDetailsComponent
      advancementRequestIds={advancementRequestIds}
      clientUuid={clientUuid}
      tracking={tracking}
      transactionUUID={transactionUUID}
    />
  </Provider>
);

AdvancementDetailsOutflowNM.propTypes = {
  tracking: PropTypes.string.isRequired,
  clientUuid: PropTypes.string.isRequired,
  advancementRequestIds: PropTypes.array.isRequired,
  transactionUUID: PropTypes.string.isRequired,
};

export default AdvancementDetailsOutflowNM;
