import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { maskValue } from 'utils/masks';

const AutocompleteFormWithData = ({
  user,
  currentUserCpf,
  setIncomeValue,
  setPatrimonyValue,
  setDocumentTypeValue
}) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('cpf', maskValue(currentUserCpf, 'cpf'));

    user?.documentOrigin &&
      setFieldValue('documentOrigin', user.documentOrigin);
    user?.documentPlaceEmission &&
      setFieldValue('documentPlaceEmission', user.documentPlaceEmission);
    user?.documentNumber &&
      setFieldValue('documentNumber', user.documentNumber);
    user?.phone && setFieldValue('phone', maskValue(user.phone, 'phone'));
    user?.email && setFieldValue('email', user.email);
    user?.nationality && setFieldValue('nationality', user.nationality);

    if (user?.documentExpeditionDate) {
      const dateReversed = user.documentExpeditionDate.split('-').reverse();
      setFieldValue(
        'documentExpeditionDate',
        `${dateReversed[0]}/${dateReversed[1]}/${dateReversed[2]}`
      );
    }

    if (user?.income) {
      setIncomeValue(user.income);
      setFieldValue('income', user.income);
    }

    if (user?.patrimony) {
      setPatrimonyValue(user.patrimony);
      setFieldValue('patrimony', user.patrimony);
    }

    if (user?.documentType) {
      setDocumentTypeValue(user.documentType);
      setFieldValue('documentType', user.documentType);
    }

    if (user?.mainAddress) {
      setFieldValue('city', user.mainAddress?.city);
      setFieldValue('complement', user.mainAddress?.complement);
      setFieldValue('neighborhood', user.mainAddress?.neighborhood);
      setFieldValue('number', user.mainAddress?.number);
      setFieldValue('state', user.mainAddress?.state);
      setFieldValue('street', user.mainAddress?.street);
      setFieldValue('zipCode', user.mainAddress?.zipcode);
    }
  }, [user]);

  return null;
};

export default AutocompleteFormWithData;
