import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { railsContextSelector } from 'selectors/rails-context';

import history from 'utils/browserHistory';
import searchUTM from 'hooks/searchUTM';
import MarketingAside from '../components/MarketingAside';
import DeviseScreenLayout from '../components/DeviseScreenLayout';
import JuristicForm from '../components/JuristicForm';
import DistributorForm from '../components/DistributorForm';
import SocialForm from '../components/SocialForm';
import ConfirmToken from '../components/ConfirmToken';
import CreatePassword from '../components/CreatePassword';
import WhatsappButton from '../components/WhatsappButton';

import ImgComeToBlu1 from '../images/img-come-to-blu-01.png';
import ImgComeToBlu2 from '../images/img-come-to-blu-02.png';
import ImgComeToBlu3 from '../images/img-come-to-blu-03.png';
import ImgComeToBlu4 from '../images/img-come-to-blu-04.png';

import { Title, Subtitle, RoleTitle } from '../devise.styles';

export default function SignUp({ bluLightProviderName }) {
  const [formStep, setStep] = useState(0);
  const { currentUserId } = useSelector(railsContextSelector);
  const queryParams = new URLSearchParams(window.location.search);
  const hasDistributor = queryParams.get('tipo-cliente');
  const [isBluLightUser, setBluLightUser] = useState(false);
  const [utmParamsQuery, setUTMParamsQuery] = useState('');

  const [formData, setFormData] = useState({
    cnpj: '',
    distributor: '',
    email: '',
    name: '',
    cpf: '',
    phone: '',
    isAccepted: '',
  });

  const hasParams = (name) => {
    switch (name) {
      case 'seletor-de-negocio':
        return setStep(0);
      case 'dados-da-empresa':
        return setStep(1);
      case 'token-email':
        return setStep(2);
      case 'dados-do-rep':
        return setStep(3);
      case 'token-sms':
        return setStep(4);
      case 'cadastro-de-senha':
        return setStep(5);
      case 'confirmacao-de-acesso':
        return setStep(6);
      default:
        return setStep(0);
    }
  };

  const hasParamsDistribuitor = {
    lojista: () =>
      setFormData((prevState) => ({ ...prevState, distributor: false })),
    fornecedor: () =>
      setFormData((prevState) => ({ ...prevState, distributor: true })),
  };

  useEffect(() => {
    const params = searchUTM();

    setUTMParamsQuery(params);
  }, []);

  useEffect(() => {
    const autocadastro = queryParams.get('autocadastro');

    hasParams(autocadastro);

    setBluLightUser(queryParams.get('utm_source') === 'parceiro-blu-light');

    return history.listen(() => {
      const queryParamsListen = new URLSearchParams(window.location.search);
      const autocadastroListen = queryParamsListen.get('autocadastro');
      if (history.action === 'POP') {
        hasParams(autocadastroListen);
      }
    });
  }, []);

  useEffect(() => {
    if (hasDistributor) hasParamsDistribuitor[hasDistributor]();

    if (currentUserId !== 'anonymous') window.location = '/';
  }, []);

  const manageSteps = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <Title $grey>
              {isBluLightUser
                ? 'Cadastre-se para ativar a sua garantia de boletos'
                : 'Queremos entender mais sobre o seu negócio'}
            </Title>
            <Subtitle>
              Preencha os dados da empresa e informe o e-mail do{' '}
              <strong>Representante Legal.</strong>
            </Subtitle>
            <JuristicForm
              className="autocadastro=dados-da-empresa"
              formData={formData}
              onSuccess={(e) => {
                setFormData(e);
                setStep(2);
                history.push({
                  pathname: '/novo-cadastro',
                  search: `?autocadastro=token-email&tipo-cliente=${
                    formData.distributor === false ? 'lojista' : 'fornecedor'
                  }&${utmParamsQuery}`,
                });
              }}
              onBack={() => {
                setStep(0);
                history.push({
                  pathname: '/novo-cadastro',
                  search: `?autocadastro=seletor-de-negocio&tipo-cliente=${
                    formData.distributor === false ? 'lojista' : 'fornecedor'
                  }&${utmParamsQuery}`,
                });
              }}
            />
          </>
        );
      case 2:
        return (
          <ConfirmToken
            autoSignUp
            authTokenType="email"
            className="autocadastro=token-email"
            onSuccess={() => {
              setStep(3);
              history.push({
                pathname: '/novo-cadastro',
                search: `?autocadastro=dados-do-rep&tipo-cliente=${
                  formData.distributor === false ? 'lojista' : 'fornecedor'
                }&${utmParamsQuery}`,
              });
            }}
          />
        );
      case 3:
        return (
          <>
            <Title $grey>Agora, é a hora de você se apresentar</Title>
            <Subtitle>
              Lembre-se que você precisa ser um{' '}
              <strong>Representante Legal.</strong>
            </Subtitle>
            <SocialForm
              className="autocadastro=dados-do-rep"
              formData={formData}
              onSuccess={({ formData }) => {
                setFormData(formData);
                setStep(4);
                history.push({
                  pathname: '/novo-cadastro',
                  search: `?autocadastro=dados-da-empresa&tipo-cliente=${
                    formData.distributor === false ? 'lojista' : 'fornecedor'
                  }&${utmParamsQuery}`,
                });
              }}
              onBack={() => {
                setStep(1);
                history.push({
                  pathname: '/novo-cadastro',
                  search: `?autocadastro=token-email&tipo-cliente=${
                    formData.distributor === false ? 'lojista' : 'fornecedor'
                  }&${utmParamsQuery}`,
                });
              }}
            />
          </>
        );
      case 4:
        return (
          <ConfirmToken
            autoSignUp
            authTokenType="phone"
            className="autocadastro=token-sms"
            onSuccess={() => {
              setStep(5);
              history.push({
                pathname: '/novo-cadastro',
                search: `?autocadastro=cadastro-de-senha&tipo-cliente=${
                  formData.distributor === false ? 'lojista' : 'fornecedor'
                }&${utmParamsQuery}`,
              });
            }}
          />
        );
      case 5:
        return <CreatePassword className="autocadastro=cadastro-de-senha" />;
      default:
        return (
          <>
            <RoleTitle>Antes de começar, diz pra gente, você é:</RoleTitle>
            <DistributorForm
              className="autocadastro=seletor-de-negocio"
              formData={formData}
              onSuccess={(e) => {
                setFormData({ ...formData, distributor: e });
                setStep(1);
                history.push({
                  pathname: '/novo-cadastro',
                  search: `?autocadastro=dados-da-empresa&tipo-cliente=${
                    e === false ? 'lojista' : 'fornecedor'
                  }&${utmParamsQuery}`,
                });
              }}
            />
          </>
        );
    }
  };

  const asideStep = (formStep) => {
    switch (formStep) {
      case 2:
      case 4:
        return (
          <MarketingAside
            title="Economize muito do seu tempo"
            caption="Com a Blu, você simplifica e organiza toda a sua rotina financeira em um só lugar."
            image={ImgComeToBlu4}
          />
        );

      case 3:
        return (
          <MarketingAside
            title="Economize ao pagar fornecedores"
            caption="Com a Blu, você sempre vai pagar mais barato os seus fornecedores."
            image={ImgComeToBlu2}
          />
        );

      case 5:
        return (
          <MarketingAside
            title="Economize pagando menos taxas"
            caption="Com a Blu, você tem acesso às melhores taxas de maquininhas."
            image={ImgComeToBlu3}
          />
        );

      default:
        return (
          <MarketingAside
            title="Muito prazer, nós somos a Blu"
            caption="A Conta Digital que mais gera economia para o varejo e segurança para a indústria"
            image={ImgComeToBlu1}
          />
        );
    }
  };

  return (
    <DeviseScreenLayout
      color="light"
      formStep={formStep}
      aside={asideStep(formStep)}
      hideAside={isBluLightUser}
      bluLightProviderName={bluLightProviderName}
    >
      <WhatsappButton />
      {manageSteps(formStep)}
    </DeviseScreenLayout>
  );
}

SignUp.propTypes = {
  bluLightProviderName: PropTypes.string,
};

SignUp.defaultProps = {
  bluLightProviderName: undefined,
};
