import React from 'react';

const useRailsContext = () => {
  const context = window.railsState?.railsContext;
  const [railsContext, setRailsContext] = React.useState(context);

  React.useEffect(() => {
    setRailsContext(context);
  }, [context]);

  return {
    ...railsContext
  };
};

export default useRailsContext;
