import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Image, Description } from './ScoreIndicatorStyles';
import DefaultBadge from './images/default-badge.svg';
import ErrorBadge from './images/error-badge.svg';
import BlockedBadge from './images/blocked-badge.svg';

const ScoreIndicator = ({ rating, size, error, children, id }) => {
  if (error)
    return <Image src={ErrorBadge} alt="Relacionamento não encontrado" />;

  if (rating.name === 'blocked')
    return <Image src={BlockedBadge} alt="Relacionamento bloqueado" />;

  if (rating.name === 'default')
    return <Image src={DefaultBadge} alt="Relacionamento padrão" />;

  return (
    <Wrapper hexColor={rating.hexColor} size={size} data-testid={id}>
      <strong>{rating.value}</strong>
      <Description hasChildren={!!children} data-testid="description">
        {children || rating.description}
      </Description>
    </Wrapper>
  );
};

ScoreIndicator.defaultProps = {
  id: null,
  rating: null,
  size: 'sm',
  error: false,
  children: null
};

ScoreIndicator.propTypes = {
  id: PropTypes.string,
  rating: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    description: PropTypes.string,
    hexColor: PropTypes.string
  }),
  error: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.any
};

export default ScoreIndicator;
