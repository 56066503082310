import _ from 'lodash';

const validateQsaAccountsForm = (values, props) => {
  const errors = {};
  const params = _.merge(values, props);

  if (params.partner) {
    const { partner } = params;

    if (_.isEmpty(partner.name)) {
      errors.partner = {
        ...errors.partner,
        name: 'não pode ficar em branco'
      };
    }

    if (_.isEmpty(partner.cpfCnpj)) {
      errors.partner = {
        ...errors.partner,
        cpfCnpj: 'não pode ficar em branco'
      };
    }

    if (_.isEmpty((partner.companyAssociates || [{}])[0].position)) {
      errors.partner = {
        ...errors.partner,
        position: 'não pode ficar em branco'
      };
    }
  }

  return errors;
};

export default validateQsaAccountsForm;
