import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

export const getAllActiveDevice = () =>
  axios.get('/api/b_token', {
    headers: { Accept: 'version=1' }
  });

export const revokeBToken = (id) =>
  axios.patch(
    `/api/b_token/${id}/revoke`,
    {},
    {
      headers: {
        Accept: 'version=1',
        'X-CSRF-Token': getCSRFToken()
      }
    }
  );
