import styled, { css } from 'styled-components';
import {
  fontWeightExtrabold,
  colorBrandPrimaryPure,
  colorBrandPrimaryDown,
  colorInterfaceLightPure,
  colorInterfaceLightUp
} from '@useblu/ocean-tokens/web/tokens';

export const ButtonMixin = css`
  font-size: 16px;
  line-height: 18px;
  padding: 15px 24px;
  border-radius: 28px;
  text-align: left;
  white-space: nowrap;

  color: ${colorInterfaceLightPure};
  font-weight: ${fontWeightExtrabold};
  background-color: ${colorBrandPrimaryPure};

  &.is-open,
  &:hover,
  &:active,
  &:focus {
    background-color: ${colorBrandPrimaryDown};
    path {
      stroke: ${colorBrandPrimaryDown};
    }
  }
`;

export const ButtonLightMixin = css`
  background: transparent;
  cursor: pointer;

  &.is-open,
  &:hover,
  &:active,
  &:focus {
    color: ${colorBrandPrimaryPure};
    background-color: ${colorInterfaceLightUp};
  }
`;

export const BigButton = styled.button`
  ${ButtonMixin}

  border-radius: 0px;
  padding: 18px 16px;
  white-space: normal;

  img {
    float: left;
    margin-right: 16px;
  }
`;

export const Circle = styled.span`
  width: 48px;
  height: 48px;
  margin: 0px 8px;
  border-radius: 40px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  i {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    width: 40px;
    height: 40px;
    padding: 5px 2px 7px 0px;

    &:first-child {
      margin: 0px;
      margin-right: 4px;
    }
  }
`;
