import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Field,
  Form,
  reduxForm,
  getFormValues,
  SubmissionError
} from 'redux-form';
import _ from 'lodash';
import Loading from 'react-loading';

import onSignUpSuccess from 'actions/sign-up-app';
import { saveClient } from 'api/sign-up-app';
import { railsContextSelector } from 'selectors/rails-context';
import RenderField from 'components/Form/RenderField';
import RenderDropdownListCallback from 'components/Form/RenderDropdownListCallback';
import signUpActions from 'store/ducks/signUp';
import validateJuristicSignUpApp from 'validations/ValidateJuristicSignUpApp';
import TaxPlans from 'components/SignUpApp/Shared/TaxPlans';
import PosOptions from 'components/SignUpApp/Shared/PosOptions';
import PosSplitOptions from 'components/SignUpApp/Shared/PosSplitOptions';
import Captcha from 'components/SignUpApp/Shared/Captcha';

class JuristicSignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { error: {} };
    this.captchaRef = React.createRef();
  }

  componentDidMount() {
    const { signUpLoadDepartments, partnerId, clientId } = this.props;
    this.loadTaxPlan();

    if (_.isEmpty(partnerId) && _.isEmpty(clientId)) {
      signUpLoadDepartments();
    }
  }

  componentDidUpdate(prevProps) {
    const { loadingBigBoost, bigBoostMessageError, change, bigBoostData } =
      this.props;

    if (
      prevProps.loadingBigBoost &&
      !loadingBigBoost &&
      !bigBoostMessageError
    ) {
      change('client[name]', bigBoostData.companyName);
      change('client[business_name]', bigBoostData.businessName);

      change('client[user][name]', bigBoostData.user.name[0].name);
      change('client[user][cpf]', bigBoostData.user.name[0].cpfCnpj);

      change('client[cnae]', bigBoostData.cnae);
      change('client[cnaeList]', bigBoostData.cnaeList);
      change('client[capitalStock]', bigBoostData.capitalStock);
      change('client[cnpjStatus]', bigBoostData.cnpjStatus);
    }
  }

  loadTaxPlan(param_id = null) {
    const { signUpLoadTaxPlans, partnerId, clientId } = this.props;

    if (
      !_.isUndefined(partnerId) ||
      !_.isUndefined(clientId) ||
      !_.isNull(param_id)
    ) {
      let isEmpty = _.isUndefined(partnerId) && _.isUndefined(clientId);
      let id = partnerId || clientId || param_id;

      signUpLoadTaxPlans(isEmpty, id);
    }
  }

  submit(values) {
    const { onSuccess } = this.props;
    const params = this.buildParams(values);
    this.validateTaxPlan();

    delete params.client.cnae;
    delete params.client.cnaeList;
    delete params.client.capitalStock;
    delete params.client.cnpjStatus;

    if (!_.isEmpty(params)) {
      this.captchaRef.current.execute();

      return saveClient(params)
        .then((response) => {
          if (_.isEqual(response.status, 200)) {
            onSuccess();
          }
        })
        .catch((error) => {
          const {
            response: { data }
          } = error;

          this.setState({ error: data });
          window.scrollTo(0, 0);
        });
    }
  }

  validateTaxPlan() {
    const { tax_plans } = this.props;
    const taxPlan = _.find(tax_plans, { selected: true });

    const taxPlanAttributes = [
      'debit_rate',
      'credit_rate_1',
      'credit_rate_2',
      'credit_rate_3',
      'credit_rate_4',
      'credit_rate_5',
      'credit_rate_6',
      'credit_rate_7',
      'credit_rate_8',
      'credit_rate_9',
      'credit_rate_10',
      'credit_rate_11',
      'credit_rate_12',
      'anticipation_rate',
      'outflow_to_payment_to_bill_rate',
      'bank_transfer_rate',
      'outflow_to_transfer_to_prepaid_card_rate',
      'payment_collection_rate',
      'outflow_to_transfer_to_client_rate',
      'pos_rent_amount'
    ];

    if (taxPlan && !taxPlan.id) {
      _.each(taxPlanAttributes, (attribute) => {
        const error = {};
        error[attribute] = 'não pode ficar em branco';

        if (!taxPlan[attribute] && taxPlan[attribute] !== 0) {
          throw new SubmissionError({ client: { tax_plan: error } });
        }
      });
    }
  }

  buildParams(values) {
    let { client } = this.props;
    const { partnerId, sellerId, clientId, clientType, railsContext } =
      this.props;
    const { tax_plans } = this.props;
    let taxPlan = {};

    if (_.isEmpty(client.tax_plan)) {
      taxPlan = _.find(tax_plans, { selected: true });
    } else {
      taxPlan = client.tax_plan;
    }

    client = _.merge(client, {
      tax_plan_id: _.get(taxPlan, 'id', null)
    });

    const clientParams = _.merge(values.client, client);

    let clientKind = {};

    if (_.isEqual(clientType, 'seller')) {
      clientKind = { seller: true };
    }

    if (_.isEqual(clientType, 'distributor')) {
      clientKind = { distributor: true };
    }

    if (_.isEqual(clientType, 'shipping_company')) {
      clientKind = { shipping_company: true };
    }

    if (_.isEqual(clientType, 'branch')) {
      clientKind = { branch_company: true };
    }

    const bankAccountParams = _.merge(
      _.omit(clientParams.bank_account, ['bank']),
      {
        bank_id: _.get(clientParams, 'bank_account.bank.id'),
        nickname: _.get(clientParams, 'bank_account.owner_name'),
        cpf_cnpj: clientParams.cpf_cnpj
      }
    );

    let buildedClientParams = _.merge(
      _.omit(clientParams, [
        'main_address',
        'bank_account',
        'tax_plan',
        'user'
      ]),
      {
        main_address_attributes: clientParams.main_address,
        bank_accounts_attributes: { 0: bankAccountParams },
        tax_plan_attributes: _.omit(taxPlan, [
          'id',
          'partner_tax_plan',
          'selected'
        ]),
        user_clients_attributes: { 0: { user_attributes: clientParams.user } },
        client_id: clientId,
        current_user_email: railsContext.currentUserEmail
      }
    );

    if (!_.isNil(partnerId)) {
      buildedClientParams = _.merge(buildedClientParams, {
        inverse_clientships_attributes: [
          {
            merchant: partnerId,
            seller: sellerId
          }
        ]
      });
    } else {
      buildedClientParams = _.merge(buildedClientParams, clientKind);
    }

    return { client: buildedClientParams };
  }

  toggleClientGroupDisplay(e) {
    const parent = $(e.currentTarget).closest('[data-collapsible]');

    parent.children('.content-collapsible').toggle();
    parent.toggleClass('open closed');
  }

  resetBankAccount(bankAccount) {
    const { signUpReloadBankAccount } = this.props;
    return signUpReloadBankAccount(bankAccount);
  }

  onDepartmentChange(departmentId) {
    const { client, signUpReloadClient } = this.props;
    client.department_id = departmentId;
    signUpReloadClient(client);
    this.loadTaxPlan(departmentId);
  }

  onSaleOriginChange(value) {
    const { client, signUpReloadClient } = this.props;
    client.sale_origin = value;
    signUpReloadClient(client);
  }

  renderLoading(color = '#e1e5ed', height = 35, width = 35) {
    return (
      <Loading
        type="spinningBubbles"
        color={color}
        delay={0}
        height={height}
        width={width}
      />
    );
  }

  renderSubmitErrors(error) {
    if (!error?.message) {
      return;
    }

    const messageTitle = error.message.split(':')[0];
    const messageBody = error.message.replace(
      'Ocorreram os seguintes erros ao salvar o cliente: A validação falhou: ',
      ''
    );

    return (
      <div className="alert-message">
        <h3 className="errors" data-testid="message-title">
          {messageTitle}:
        </h3>
        <ul className="errors">
          {_.map(messageBody.split(','), (message, i) => (
            <li key={i} data-testid={`message-item-${i}`}>
              {message}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderDepartmentsOption(department) {
    return { key: department.id, label: department.name };
  }

  renderDepartments() {
    const { departments, partnerId, clientId } = this.props;
    let content;

    if (
      _.isEmpty(partnerId) &&
      _.isEmpty(clientId) &&
      departments &&
      departments.length
    ) {
      content = (
        <div className="client-data-group">
          <h3 className="page-subtitle">
            <i className="fa fa-folder-open" /> Departamento do Cliente
          </h3>
          <div className="form-row">
            <div className="form-controls">
              <Field
                name="client[department_id]"
                component={RenderDropdownListCallback}
                options={departments.map(this.renderDepartmentsOption)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ key }) => key}
                simpleValue
                label="Selecione o departamento do Cliente"
                placeholder="Escolha uma opção"
                handleChange={(departmentId) =>
                  this.onDepartmentChange(departmentId)
                }
              />
            </div>
          </div>
        </div>
      );
    }

    return content;
  }

  renderJuristicSignUpForm() {
    const {
      handleSubmit,
      submitting,
      client,
      tax_plans,
      signUpSetMainAddress,
      signUpSetTaxPlans,
      railsContext,
      bigBoostData
    } = this.props;

    let { error } = this.state;

    return (
      <Form
        data-testid="juristic-signup-form"
        className="form-fields-for"
        onSubmit={handleSubmit((values) => this.submit(values))}
        noValidate
      >
        <div className="client-data-group">
          {this.renderSubmitErrors(error)}
          {this.renderDepartments()}

          <div className="form-row">
            <Field
              name="client[sale_origin]"
              component={RenderDropdownListCallback}
              options={[
                {
                  name: 'CRM',
                  value: 'CRM'
                },
                {
                  name: 'Campanha Marketing',
                  value: 'Campanha Marketing'
                },
                {
                  name: 'Indicação Rep Comercial',
                  value: 'Indicação Rep Comercial'
                },
                {
                  name: 'Venda Digital',
                  value: 'Venda Digital'
                }
              ]}
              getOptionLabel={({ name }) => name}
              getOptionValue={({ value }) => value}
              simpleValue
              label="Canal de vendas"
              placeholder="Escolha uma opção"
              handleChange={(value) => this.onSaleOriginChange(value)}
            />
          </div>

          <h3 className="page-subtitle">
            <i className="fa fa-building" /> Dados do cliente
          </h3>
          <div className="form-row">
            <Field
              ref={(input) => (this.cpf_cnpj_input = input)}
              component={RenderField}
              id="cpf_cnpj"
              label="CNPJ"
              inputClassName="cpf_cnpj"
              name="client[cpf_cnpj]"
              type="text"
              mask="cnpj"
            />
            {railsContext.currentUserId !== 'anonymous' && (
              <div>
                <button
                  className="button button-primary search_bigboost"
                  data-testid="bigboost-search-button"
                  onClick={this.getBigBoostData}
                  disabled={this.props.loadingBigBoost}
                >
                  {this.props.loadingBigBoost ? (
                    <div className="u-flex content-center">
                      {this.renderLoading('#2d3645', 16, 16)}
                    </div>
                  ) : (
                    'Consultar'
                  )}
                </button>
              </div>
            )}
          </div>

          <div className="form-row">
            <Field
              component={RenderField}
              id="name"
              label="Razão social"
              name="client[name]"
              type="text"
              disabled={true}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              id="cnae"
              label="CNAE"
              name="client[cnae]"
              type="text"
              disabled={true}
            />
            <ul className="cnae-list">
              {bigBoostData?.cnaeList?.map((item, k) => {
                return (
                  <li key={k}>
                    <b>{k + 1}.</b> {`${item.cnae} - ${item.description}`}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              id="capitalStock"
              label="Capital social"
              name="client[capitalStock]"
              type="text"
              disabled={true}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              id="cnpjStatus"
              label="Status CNPJ"
              name="client[cnpjStatus]"
              type="text"
              disabled={true}
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              id="business_name"
              label="Nome fantasia"
              name="client[business_name]"
              type="text"
            />
          </div>
        </div>
        <div className="client-data-group">
          <h3 className="page-subtitle">
            <i className="fa fa-user" /> Representante legal
          </h3>
          <div className="form-row">
            <Field
              component={RenderField}
              id="user_name"
              label="Nome"
              name="client[user][name]"
              type="text"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              id="cpf"
              label="CPF"
              name="client[user][cpf]"
              type="text"
              mask="cpf"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="E-mail"
              name="client[user][email]"
              type="text"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Telefone"
              name="client[user][phone]"
              type="text"
              mask="phone"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Telefone 2"
              name="client[user][phone_2]"
              type="text"
              mask="phone"
            />
            <Field
              component={RenderField}
              label="Telefone 3"
              name="client[user][phone_3]"
              type="text"
              mask="phone"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Ticket ID"
              name="client[zendesk_origin_ticket_id]"
              type="number"
              mask="number"
            />
          </div>
        </div>

        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-credit-card" /> Preferências de POS BLU{' '}
            <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            <PosOptions />
          </div>
        </div>
        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-credit-card" /> Preferências de POS SPLIT{' '}
            <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            <PosSplitOptions />
          </div>
        </div>
        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle client-data-group-title"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-certificate" /> Plano de Taxas{' '}
            <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            {tax_plans ? (
              <TaxPlans
                taxPlans={tax_plans}
                setTaxToReducer={(taxPlan) => signUpSetTaxPlans(taxPlan)}
              />
            ) : (
              this.renderLoading()
            )}
          </div>
        </div>
        <div className="form-row actions">
          <button className="button button--primary" disabled={submitting}>
            Enviar
          </button>
        </div>
        <Captcha ref={this.captchaRef} />
      </Form>
    );
  }

  getBigBoostData = (e) => {
    e.preventDefault();
    const {
      signUpLoadBigBoost,
      formValues: { client }
    } = this.props;

    this.cpf_cnpj_input.ref.current.handleBlur();
    signUpLoadBigBoost(client.cpf_cnpj);
  };

  render() {
    return (
      <div className="flex-container">{this.renderJuristicSignUpForm()}</div>
    );
  }
}

JuristicSignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  client: PropTypes.object,
  tax_plans: PropTypes.array,
  departments: PropTypes.array,
  partnerId: PropTypes.string,
  sellerId: PropTypes.string,
  clientType: PropTypes.string,
  clientId: PropTypes.string,
  signUpReloadBankAccount: PropTypes.func,
  signUpReloadClient: PropTypes.func,
  signUpSetMainAddress: PropTypes.func,
  signUpSetTaxPlans: PropTypes.func,
  signUpLoadTaxPlans: PropTypes.func,
  signUpLoadDepartments: PropTypes.func,
  signUpLoadBigBoost: PropTypes.func,
  formValues: PropTypes.object,
  railsContext: PropTypes.object
};

JuristicSignUpForm.defaultProps = {
  error: {},
  submitting: false,
  invalid: false
};

const mapStateToProps = (state) => ({
  client: state.signUp.client,
  tax_plans: state.signUp.tax_plans,
  departments: state.signUp.departments,
  loadingBigBoost: state.signUp.loadingBigBoost,
  bigBoostMessageError: state.signUp.bigBoostMessageError,
  initialValues: {
    client: {
      pos_preferences_cell_operator: 'definir_na_logistica',
      ...state.signUp.client
    }
  },
  formValues: getFormValues('juristicSignUpForm')(state),
  railsContext: railsContextSelector(state),
  bigBoostData: state.signUp.bigBoostData
});

const mapDispatchToProps = (dispatch) => ({
  onSuccess: () => dispatch(onSignUpSuccess()),
  signUpLoadDepartments: () => dispatch(signUpActions.signUpLoadDepartments()),

  signUpLoadTaxPlans: (isPartnerIdNil, id) =>
    dispatch(signUpActions.signUpLoadTaxPlans(isPartnerIdNil, id)),
  signUpSetTaxPlans: (taxPlan) =>
    dispatch(signUpActions.signUpSetTaxPlans(taxPlan)),
  signUpSetMainAddress: (address) =>
    dispatch(signUpActions.signUpSetMainAddress(address)),
  signUpReloadBankAccount: (bankAccount) =>
    dispatch(signUpActions.signUpReloadBankAccount(bankAccount)),
  signUpReloadClient: (client) =>
    dispatch(signUpActions.signUpReloadClient(client)),
  signUpLoadBigBoost: (cpfCnpj) =>
    dispatch(signUpActions.signUpLoadBigBoost(cpfCnpj))
});

const JurisdicSignUpReduxForm = reduxForm({
  form: 'juristicSignUpForm',
  validate: validateJuristicSignUpApp,
  asyncChangeFields: ['cpf_cnpj'],
  shouldValidate() {
    return true;
  },
  onSubmitFail: (errors) => {
    if (!_.isEmpty(errors)) {
      const fieldKeys = [];
      const fieldNames = [];

      const errorKey = Object.keys(errors.client)[0];
      const errorValue = Object.values(errors.client)[0];

      const error = { client: {} };
      error.client[errorKey] = errorValue;

      _.mixin({
        deeply(map) {
          return function (obj, fn) {
            return map(
              _.mapValues(obj, (v) => {
                return _.isPlainObject(v) ? _.deeply(map)(v, fn) : v;
              }),
              fn
            );
          };
        }
      });

      _.deeply(_.mapKeys)(error, (value, key) => fieldKeys.push(key));

      _.each(_.reverse(fieldKeys), (value, index) => {
        const name = _.isEqual(index, 0) ? value : `[${value}]`;
        return fieldNames.push(name);
      });

      const inputError = document.querySelectorAll(
        `input[name='${fieldNames.join('')}'], select[name='${fieldNames.join(
          ''
        )}']`
      )[0];

      if (inputError) {
        inputError.focus();
      }
    }
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  JurisdicSignUpReduxForm
)(JuristicSignUpForm);
