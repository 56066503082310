import React from 'react';
import PropsType from 'prop-types';

import IcWorks from '../assets/iphone-works.png';

import * as S from './styles';

const HowItWorks = ({ title, clientAA, clientTax }) => (
  <>
    <S.GridThumbnail xs={12} sm={5} md={5}>
      <S.Thumbnail src={IcWorks} alt={title} />
    </S.GridThumbnail>

    <S.GridBody xs={12} sm={7} md={7}>
      <S.Wrapper>
        <S.Title>{title}</S.Title>

        <S.List>
          <S.Item>
            <S.Icon>1</S.Icon>
            <S.Description>
              Peça ao seu representante comercial ou fornecedor para enviar seu
              próximo pagamento via Blu.
            </S.Description>
          </S.Item>

          <S.Item>
            <S.Icon>2</S.Icon>
            <S.Description>
              Na sua Conta Digital Blu, clique na aba “Aprovações” e selecione a
              cobrança que gostaria de aprovar.
            </S.Description>
          </S.Item>

          <S.Item>
            <S.Icon>3</S.Icon>
            <S.Description>
              Em seguida, selecione a opção de pagamento
              <span>PagBlu.</span>
            </S.Description>
          </S.Item>

          <S.Item>
            <S.Icon>4</S.Icon>
            <S.Description>
              {clientAA
                ? `Você receberá de volta o mesmo percentual da sua taxa de antecipação automática. Ou seja, você terá ${clientTax}% de desconto ao pagar a cobrança com seu fornecedor.`
                : 'Você usará seu saldo disponível para pagar seu fornecedor com taxa zero de antecipação.'}
            </S.Description>
          </S.Item>
        </S.List>
      </S.Wrapper>
    </S.GridBody>
  </>
);

HowItWorks.propsType = {
  title: PropsType.string.isRequired,
  clientAA: PropsType.bool.isRequired,
  clientTax: PropsType.string.isRequired
};

export default HowItWorks;
