/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@useblu/ocean-react';

import useModalManagement from 'components/Dashboard/hooks/useModalManagement';
import useClientFeatureStatus from 'components/Dashboard/hooks/useClientFeatureStatus';

import { useSelector } from 'react-redux';
import { railsContextSelector } from 'selectors/rails-context';

import useModalTriggers from './hooks/useModalTriggers';
import AutomaticAnticipationModalOptIn from './features/AutomaticAnticipation';
import AutomaticAnticipationOptOut from './features/AutomaticAnticipationOptOut';
import ForbiddenUser from './components/ForbiddenUser';
import ReceptionRoomModal from './features/ReceptionRoomModal';

import AutomaticScheduling from './features/ModalAutomaticScheduling';
import Pagblu from './features/ModalPagblu';
import ModalAboutBtoken from './features/ModalAboutBtoken';

const Modals = ({
  tax,
  comboTax,
  isFinancialRepresentative,
  hasSocialContractToSigning,
  automaticAnticipationFixRate,
  automaticAnticipationVariableRate,
  automaticAnticipationWith2FA,
}) => {
  const { isModalOpen, feature, closeModal } = useModalManagement();
  const { clientUUID, storeName } = useClientFeatureStatus();
  const { pendingBluLightInvitations } = useSelector(railsContextSelector);

  const convertedTax = (tax || '6.99').replace('.', ',');
  const convertedComboTax = (comboTax || '5.99').replace('.', ',');

  useModalTriggers();

  const modalProps = {
    isOpen: isModalOpen,
    hasSocialContractToSigning,
    tax: convertedTax,
    comboTax: convertedComboTax,
    automaticAnticipationFixRate,
    automaticAnticipationWith2FA,
    automaticAnticipationVariableRate,
    client: {
      uuid: clientUUID,
      name: storeName,
    },
    ariaHideApp: false,
    onRequestClose: (e) => {
      closeModal(typeof e !== 'string' ? feature : e);
    },
  };

  const financialRepresentativeModals = [
    'AutomaticAnticipationOptOut',
    'AutomaticAnticipation',
  ];

  if (feature === 'ContractSigning')
    return (
      <Modal
        portalClassName="ocean-iso modal-mobile-adjust-not-full"
        maxWidth="lg"
        disableClose={pendingBluLightInvitations}
        shouldCloseOnEsc={!pendingBluLightInvitations}
        shouldCloseOnOverlayClick={!pendingBluLightInvitations}
        {...modalProps}
      >
        <ReceptionRoomModal {...modalProps} />
      </Modal>
    );

  if (isFinancialRepresentative) {
    if (feature === 'AutomaticAnticipationOptOut')
      return (
        <Modal portalClassName="ocean-iso" maxWidth="md" {...modalProps}>
          <AutomaticAnticipationOptOut {...modalProps} />
        </Modal>
      );

    if (feature === 'AutomaticAnticipation')
      return (
        <Modal
          portalClassName="ocean-iso modal-mobile-adjust modal-fixed-width"
          maxWidth="lg"
          {...modalProps}
        >
          <AutomaticAnticipationModalOptIn {...modalProps} />
        </Modal>
      );

    if (feature === 'AutomaticScheduling') {
      return (
        <Modal
          portalClassName="ocean-iso modal-mobile-full-height"
          maxWidth="sm"
          {...modalProps}
        >
          <AutomaticScheduling />
        </Modal>
      );
    }

    if (feature === 'Pagblu') {
      return <Pagblu modalProps={modalProps} />;
    }

    if (feature === 'ModalAboutBtoken') {
      return (
        <Modal
          portalClassName="ocean-iso modal-mobile-full-height"
          maxWidth="sm"
          {...modalProps}
        >
          <ModalAboutBtoken modalProps={modalProps} />
        </Modal>
      );
    }
  }

  if (
    feature &&
    financialRepresentativeModals.findIndex((i) => i === feature) > -1
  ) {
    return (
      <Modal portalClassName="ocean-iso" maxWidth="md" {...modalProps}>
        <ForbiddenUser {...modalProps} />
      </Modal>
    );
  }

  return null;
};

Modals.propTypes = {
  automaticAnticipationWith2FA: PropTypes.bool,
  automaticAnticipationFixRate: PropTypes.string,
  automaticAnticipationVariableRate: PropTypes.string,
  tax: PropTypes.string,
  comboTax: PropTypes.string,
  isFinancialRepresentative: PropTypes.bool.isRequired,
  hasSocialContractToSigning: PropTypes.bool,
};

Modals.defaultProps = {
  automaticAnticipationWith2FA: false,
  automaticAnticipationFixRate: '',
  automaticAnticipationVariableRate: '',
  hasSocialContractToSigning: null,
  tax: '6.99',
  comboTax: '5.99',
};

export default Modals;
