import axios from 'axios';

export default function getCompaniesStoreApi(currentClientUuid) {
  const query = `
  query {
    currentUser {
      clientsSelector(currentClientUuid: "${currentClientUuid}") {
        id
        name
        economicGroup
        inEconomicGroup
      }
    }
  }
  `;

  return axios.post('/graphql', { query });
}
