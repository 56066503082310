import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import { getPendentPayments } from 'api/payment-collection';
import pendentPaymentsActions, {
  selectors as pendentPaymentsSelectors
} from 'store/ducks/pendentPayments';
import paymentSelectedActions from 'store/ducks/paymentSelected';

function* loadPendentPayments({ uuid }) {
  try {
    const clientId = yield select((state) => state.railsContext?.clientId);

    const response = yield call(getPendentPayments, clientId);

    const pendentPayments = response.data.data.paymentCollections || [];

    yield put(pendentPaymentsActions.pendentPaymentsSuccess(pendentPayments));

    if (pendentPayments.length) {
      let paymentUuid = null;
      let sentOrReceived = null;
      let pendingOrApproved = null;

      if (uuid) {
        const category = pendentPayments.filter(
          (category) =>
            category.paymentCollections.filter(
              (group) =>
                group.paymentCollections.filter(
                  (payment) => payment.uuid === uuid
                ).length > 0
            ).length > 0
        );

        if (category.length > 0) {
          paymentUuid = uuid;
          sentOrReceived = category[0].category.split('_')[0];
          pendingOrApproved = category[0].category.split('_')[1];
        }
      }

      if (!paymentUuid) {
        paymentUuid = pendentPayments[0].paymentCollections[0].uuid;
        sentOrReceived = pendentPayments[0].category.split('_')[0];
        pendingOrApproved = pendentPayments[0].category.split('_')[1];
      }

      yield put(paymentSelectedActions.paymentSelectedRequest(paymentUuid));
      yield put(
        pendentPaymentsActions.pendentPaymentsUpdateSentOrReceived(
          sentOrReceived,
          pendingOrApproved
        )
      );
    }
  } catch (err) {
    yield put(pendentPaymentsActions.pendentPaymentsFailure());
  }
}

function* setNewestPaymentInCurrentCategoryAsDefault() {
  try {
    const payments = yield select(
      pendentPaymentsSelectors.getPaymentsInCurrentCategory
    );
    if (_.isEmpty(payments)) {
      return yield put(
        pendentPaymentsActions.pendentPaymentsUpdateContentType('empty')
      );
    }

    const isSelected = yield select(
      pendentPaymentsSelectors.hasPaymentSelectedInCurrentCategory
    );
    if (isSelected) {
      return yield put(
        pendentPaymentsActions.pendentPaymentsUpdateContentType('detail')
      );
    }

    yield put(
      paymentSelectedActions.paymentSelectedRequest(
        payments[0].paymentCollections[0].uuid
      )
    );
  } catch (error) {
    console.error(
      'Ocorreu algum erro ao selecionar o primeiro da lista.',
      error
    );
  }
}

export { loadPendentPayments, setNewestPaymentInCurrentCategoryAsDefault };
