import React from 'react';
import PropTypes from 'prop-types';

import { normalizeCurrency } from 'normalizers';
import ChargerRefundSummary from './ChargerRefundSummary';
import ChargedRefundSummary from './ChargedRefundSummary';

const RefundPaymentCollectionHeaderSummary = ({
  isCharger,
  refundedValue,
  remainingRefundValue,
  admNetValue
}) => (
  <div className="statement-detail__refunded">
    {isCharger ? (
      <ChargerRefundSummary
        admNetValue={admNetValue}
        grossValue={remainingRefundValue}
      />
    ) : (
      <ChargedRefundSummary paidValue={remainingRefundValue} />
    )}
    <span className="statement-detail__refunded-value">
      Valor devolvido:{' '}
      <span
        className={`statement-detail__refunded--${
          isCharger ? 'outflow' : 'inflow'
        }`}
      >
        R$ {normalizeCurrency(refundedValue, false)}
      </span>
    </span>
  </div>
);

RefundPaymentCollectionHeaderSummary.propTypes = {
  isCharger: PropTypes.bool.isRequired,
  refundedValue: PropTypes.number.isRequired,
  remainingRefundValue: PropTypes.number.isRequired,
  admNetValue: PropTypes.number
};

RefundPaymentCollectionHeaderSummary.defaultProps = {
  admNetValue: 0
};

export default RefundPaymentCollectionHeaderSummary;
