import React from 'react';
import { Checkbox } from '@useblu/ocean-react';
import StyledCheckBoxField from './styles';

export default function CheckBoxField({ field, form, ...props }) {
  const fatherEmptyError =
    form?.errors?.fatherName && form?.touched?.fatherName;
  return (
    <StyledCheckBoxField hasError={fatherEmptyError}>
      <Checkbox {...field} {...props} />
    </StyledCheckBoxField>
  );
}
