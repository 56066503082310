import React from 'react';
import { Link } from '@useblu/ocean-react';

import downloadBluApp from 'images/download-blu-app.png';
import { BluAppDownloadCard, CallToAction, QRCode } from './index.styles';

const BluAppDownload = () => (
  <BluAppDownloadCard>
    <h1>Ainda não tem o app da Blu?</h1>

    <span>
      Você pode baixá-lo gratuitamente nas lojas virtuais. Disponível para&nbsp;
      <Link href="https://appblu.app.link/btoken">Android</Link>
      &nbsp;e&nbsp;
      <Link href="https://appblu.app.link/btoken">iOS</Link>.
    </span>

    <CallToAction>
      <QRCode>
        <Link href="https://appblu.app.link/btoken">
          <img src={downloadBluApp} alt="Baixar app Blu" />
        </Link>
      </QRCode>
    </CallToAction>
  </BluAppDownloadCard>
);

export default BluAppDownload;
