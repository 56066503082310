import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  loading: false,
  error: null,
  data: null
};

export default createReducer(initialState, {
  [types.FETCH_PAYMENT_COLLECTION_DETAILS_REQUEST]: () => ({
    ...initialState,
    loading: true
  }),
  [types.FETCH_PAYMENT_COLLECTION_DETAILS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    data: action.data
  }),
  [types.FETCH_PAYMENT_COLLECTION_DETAILS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error
  })
});
