import axios from 'axios';

export function getTaxPlans(params) {
  let path = `/api/tax_plans?partner_uuid=${params.id}`;
  if (params.isPartnerIdNil) {
    path = `/api/tax_plans?department_id=${params.id}`;
  }

  return axios({
    method: 'get',
    url: `${path}`,
    headers: {
      Accept: 'version=1',
      'Content-Type': 'application/json'
    }
  });
}

export function getDepartments() {
  return axios({
    method: 'get',
    url: '/api/departments',
    headers: {
      Accept: 'version=1',
      'Content-Type': 'application/json'
    }
  });
}

export function saveClient(params) {
  return axios({
    method: 'post',
    url: '/api/clients',
    headers: {
      Accept: 'version=1',
      'Content-Type': 'application/json'
    },
    data: params
  });
}

export function createUser(params) {
  return axios({
    method: 'post',
    url: '/api/client_automatic_registrations',
    headers: {
      Accept: 'version=1',
      'Content-Type': 'application/json'
    },
    data: params
  });
}

export function resendMail(params) {
  return axios({
    method: 'post',
    url: '/api/client_automatic_registrations/resend_automatic_registration_email',
    headers: {
      Accept: 'version=1',
      'Content-Type': 'application/json'
    },
    data: params
  });
}

export function signInToken({ token }) {
  return axios({
    method: 'post',
    url: `/users/sign_in?token=${token}`,
    headers: {
      Accept: 'application/json'
    }
  });
}

export function createUserPass(params) {
  return axios({
    method: 'put',
    url: '/users/password',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: params
  });
}

export function confirmToken(clientId, token, params) {
  return axios({
    method: 'patch',
    url: `/lojas/${clientId}/autorizacoes/${token}/perform?provider=sms&type=public`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: params
  });
}

export function resendToken(clientId, token) {
  return axios({
    method: 'get',
    url: `/lojas/${clientId}/autorizacoes/${token}/resend_sms_token?type=public`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
}

export function checkClient(cnpj) {
  return axios({
    method: 'get',
    url: `/api/client_automatic_registrations/check_client/${cnpj}`,
    headers: {
      Accept: 'version=1',
      'Content-Type': 'application/json'
    }
  });
}
