export const refundData = {
  data: {
    id: 'fa0958ca-809e-4104-80f0-c8eb21039cee',
    type: 'refund',
    attributes: {
      value: '5.0',
      client_transaction_refund_id: '7f3233c5-6c13-460c-bc04-26802ffa61e0',
      requester_uuid: 'c48e0336',
      status: 'pending',
      return_identification_transiting_id:
        '71bd89d7-5d54-4714-84d1-3bd1dbae6816',
      reason: 'A short reason description',
      requested_at: '2021-12-15T14:09:01.171Z',
      liquidated_at: '2021-12-15T14:09:01.171Z',
      debt_id: 'afbaf7ff-5039-4e96-924f-a742420ecd21',
      original_e2e_id: null,
      original_pactual_id: null,
      pactual_id: null
    }
  }
};

export const paymentInboundData = {
  data: {
    attributes: {
      debit_party: {
        account: '12345678',
        personType: 'NATURAL_PERSON',
        taxId: '0000000000',
        accountType: 'CACC',
        branch: '1',
        bank: '00000000',
        name: 'EMPRESA TESTE'
      },
      refunds: [
        {
          data: {
            id: 'e863c823-99d8-40fd-81b9-64e3ffd2d969',
            attributes: {
              value: '10.0',
              reason: 'A short reason description'
            }
          }
        }
      ]
    }
  }
};
