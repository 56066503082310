import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@useblu/ocean-react';
import Actions from './Actions';

import {
  Title,
  TitlePage,
  ModalContent,
  Caption,
  ScrollMobile,
  ModalFooterContainer,
  FooterText
} from '../modal.styles';

const ComboInfo = ({
  nextStep,
  loading,
  clientId,
  comboTax,
  prevStep,
  header
}) => (
  <>
    <ScrollMobile>
      <TitlePage> {header} </TitlePage>
      <Title>
        Centralize seus recebíveis na Blu e tenha uma taxa fixa de antecipação
        automática de {comboTax}%
      </Title>
      <ModalContent style={{ minHeight: '216px' }}>
        <Caption>
          <span>
            Com a <strong>Antecipação Automática Blu</strong> todas as vendas
            feitas no crédito serão antecipadas automáticamente e estarão
            disponíveis como saldo atual em até 24h ou no próximo dia útil. Com
            a <strong>Centralização de Recebíveis Blu</strong> as vendas de suas
            outras maquininhas também farão parte do seu saldo atual em sua
            Conta Digital Blu.
          </span>
          <br />
          <span className="caption-links">
            Saiba mais sobre a{' '}
            <Link
              href={`/lojas/${clientId}/antecipacao-automatica-com-cashback`}
            >
              Antecipação Automática Blu
            </Link>{' '}
            ou{' '}
            <Link href={`/lojas/${clientId}/economize-mais-use-recebiveis`}>
              Centralização de Recebíveis Blu
            </Link>
          </span>
        </Caption>
        <FooterText className="mobile">
          A taxa não inclui MDR ou &quot;Taxa do Cartão&quot;.
        </FooterText>
      </ModalContent>
    </ScrollMobile>
    <ModalFooterContainer>
      <FooterText className="desktop">
        A taxa não inclui MDR ou &quot;Taxa do Cartão&quot;.
      </FooterText>
      <Actions
        prevStep={prevStep}
        nextStep={nextStep}
        loading={loading}
        nextClass="1_campanha_aa_combo"
      />
    </ModalFooterContainer>
  </>
);

ComboInfo.propTypes = {
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  clientId: PropTypes.string,
  header: PropTypes.string,
  loading: PropTypes.bool,
  comboTax: PropTypes.string
};

ComboInfo.defaultProps = {
  loading: false,
  clientId: '',
  header: '',
  comboTax: '7,99'
};

export default ComboInfo;
