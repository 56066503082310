import axios from 'axios';

export function getPaymentListApi(uuid) {
  const query = `
    query {
      preRegistrationTransactions(clientUuid: "${uuid}") {
        total
        count
        transactions {
          id
          uuid
          payee
          releasedAt
          dueDate
          grossValue
          observation
          advancedValue
          advancesRateValue
          transactionCategory
          paymentToBillOverdue
          transactionCategoryId
          isEconomicGroup
          status
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function getPaymentListWithLimitApi(uuid) {
  const query = `
    query {
      preRegistrationTransactions(clientUuid: "${uuid}", limit: 10) {
        total
        count
        transactions {
          id
          uuid
          payee
          releasedAt
          grossValue
          observation
          transactionCategory
          paymentToBillOverdue
          transactionCategoryId
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function setPaymentsApi(uuid, ids) {
  const query = `
    mutation {
      createBatchPayment(clientUuid: "${uuid}", clientTransactionIds: [${ids}]) {
        uuid
        isSameOwnership
        transactionsWithSameOwnership {
          id
          grossValue
        }
        transactionsWithoutSameOwnership {
          id
          grossValue
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function cancelPaymentsApi(uuid, ids) {
  const query = `
    mutation {
      cancelUnconfirmedTransactions(clientUuid: "${uuid}", clientTransactionIds: [${ids}]) {
        id
      }
    }
  `;

  return axios.post('/graphql', { query });
}
