import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider as ReCaptchaV3Provider } from 'react-google-recaptcha-v3';

import DeviseScreenLayout from '../components/DeviseScreenLayout';
import Authentication from '../components/Authentication';
import AuthenticationProvider from '../context/authentication.provider';

const SignIn = ({
  secretKey,
  autoSignUpDisabled,
  recaptchaKeyV2,
  recaptchaKeyV3,
  antiFraudAlertEnabled,
}) => {
  useEffect(() => {
    localStorage.removeItem('view-modal-automatic-scheduling');
  }, []);

  return (
    <AuthenticationProvider>
      <DeviseScreenLayout>
        <ReCaptchaV3Provider reCaptchaKey={recaptchaKeyV3}>
          <Authentication
            secretKey={secretKey}
            autoSignUpDisabled={autoSignUpDisabled}
            recaptchaKeyV2={recaptchaKeyV2}
            recaptchaKeyV3={recaptchaKeyV3}
            antiFraudAlertEnabled={antiFraudAlertEnabled}
          />
        </ReCaptchaV3Provider>
      </DeviseScreenLayout>
    </AuthenticationProvider>
  );
};
SignIn.propTypes = {
  secretKey: PropTypes.string,
  recaptchaKeyV2: PropTypes.string,
  recaptchaKeyV3: PropTypes.string,
  autoSignUpDisabled: PropTypes.bool,
  antiFraudAlertEnabled: PropTypes.bool,
};

SignIn.defaultProps = {
  secretKey: '',
  recaptchaKeyV2: '',
  recaptchaKeyV3: '',
  autoSignUpDisabled: false,
  antiFraudAlertEnabled: false,
};

export default SignIn;
