import axios from 'axios';

export function loadCompaniesApi(groupId) {
  const query = `
  query {
    economicGroup(economicGroupUuid: "${groupId}") {
      name
      availableClients {
        inGroup
        isHeadquarter
        client {
          id
          uuid
          name
          cpfCnpj
          currentBalance
        }
      }
    }
  }`;

  return axios.post('/graphql', { query });
}

export function sendRequestCompanyApi(groupId, companyId) {
  const query = `
  mutation {
    addEconomicGroupClient(clientId: ${companyId}, economicGroupId: ${groupId}) {
      availableClients {
        inGroup
      }
    }
  }`;

  return axios.post('/graphql', { query });
}

export function loadGroupConfigurationApi() {
  const query = `
  query {
    economicGroup {
      id
      uuid
      name
      availableClients {
        inGroup
        isHeadquarter
        client {
          id
          uuid
          name
          cpfCnpj
          currentBalance
        }
      }
    }
  }`;

  return axios.post('/graphql', { query });
}

export function saveGroupConfigurationApi(name, ids) {
  const query = `
  mutation {
    createEconomicGroup(name: "${name}", clientIds: [${ids}]) {
      id
      uuid
      name
    }
  }`;

  return axios.post('/graphql', { query });
}

export function updateEconomicGroupApi(groupId, name) {
  const query = `
  mutation {
    updateEconomicGroup(economicGroupId: "${groupId}", name: "${name}" ) {
      id
      uuid
      name
    }
  }`;

  return axios.post('/graphql', { query });
}

export function removeGroupCompanyApi(groupId, companyId) {
  const query = `
  mutation {
    removeEconomicGroupClient(economicGroupId: "${groupId}", clientId: "${companyId}")
  }`;

  return axios.post('/graphql', { query });
}

export function addAllEconomicGroupClientsApi(groupId) {
  const query = `
  mutation {
    addAllEconomicGroupClients(economicGroupId: "${groupId}") {
      availableClients {
        inGroup
        isHeadquarter
        client {
          id
          uuid
          name
          cpfCnpj
          currentBalance
        }
      }
    }
  }`;

  return axios.post('/graphql', { query });
}
