import {
  colorInterfaceDarkDeep,
  colorInterfaceLightPure,
  colorInterfaceLightUp,
  colorInterfaceLightDeep
} from '@useblu/ocean-tokens/web/tokens';

import styled from 'styled-components';

export const TaxesTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ hideHeader }) => (hideHeader ? '24px' : '80px')};

  h1 {
    font-size: 40px;
    color: ${colorInterfaceDarkDeep};
    text-align: center;
    font-weight: 800;
    margin: 0 16px 40px 16px;
    line-height: 110%;
  }

  @media (max-width: 1060px) {
    h1 {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }
`;

export const BorderRadiusContainer = styled.div`
  display: block;
  overflow: hidden;
  border-radius: ${({ removeBorder }) => (removeBorder ? '0' : '8px')};
  align-self: ${({ removeBorder }) => (removeBorder ? 'initial' : 'center')};
`;

export const Taxes = styled.table`
  width: 941px;
  margin: 0;
  font: Avenir;
  tr {
    background-color: ${colorInterfaceLightUp};
  }
  tr:nth-child(odd) {
    background-color: rgba(184, 195, 255, 0.24);
  }
  tr:nth-child(1) {
    background-color: rgba(184, 195, 255, 0.48);
  }
  th {
    font-weight: 800;
    width: 112px;
    border: none;
  }
  td {
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
  }
  td:first-child {
    color: ${colorInterfaceDarkDeep};
    width: 323px;
    font-weight: 700;
    padding-left: 24px;
  }
  td:first-child,
  th {
    font-size: 24px;
  }
  td:not(:first-child),
  th {
    text-align: center;
  }
  .taxes-table-optin {
    border: 1px solid ${colorInterfaceLightDeep};
  }
  .taxes-table-optin tr {
    background-color: ${colorInterfaceLightPure};
    border-bottom: 1px solid ${colorInterfaceLightDeep};
  }

  .taxes-table-optin td,
  .taxes-table-optin th {
    font-size: 16px;
    border-bottom: 1px solid ${colorInterfaceLightDeep};
    line-height: 22px;
  }

  .taxes-table-optin td:not(:first-child) {
    font-weight: 600;
  }

  @media (min-width: 600px) and (max-width: 1060px) {
    width: 500px;
    margin-bottom: 32px;

    td:first-child {
      padding: 0;
      text-align: center;
      width: auto;
    }
  }

  @media (min-width: 414px) and (max-width: 600px) {
    width: 350px;
    margin-bottom: 16px;

    td:first-child {
      padding: 0;
      text-align: center;
      width: auto;
    }
  }

  @media (max-width: 413px) {
    width: 310px;
    margin-bottom: 16px;

    td:first-child {
      padding: 0;
      text-align: center;
      width: auto;
    }
  }
`;

export const TaxesTableOptin = styled(Taxes)`
  background: yellow;
`;
