import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useFormikContext, Field } from 'formik';
import { Progress as ProgressOcean } from '@useblu/ocean-react';
import ReactGA from 'react-ga';

import useCep from 'hooks/useZipCode';

import {
  FormRow,
  FormTitle,
  StepTitle
} from 'components/RPARegisterForm/RPARegisterForm.styles';
import thirdStep from 'components/RPARegisterForm/assets/step-3.svg';
import TextField from '../TextField';
import MaskField from '../MaskField';

const Progress = styled(ProgressOcean)`
  &&& {
    position: relative;
    width: 24px;
    height: 24px;

    .ods-progress__primary svg circle {
      stroke: #b8c3ff;
    }

    .ods-progress__secondary svg circle {
      stroke: #0025e0;
      stroke-width: 5px;
    }
  }
`;

export default function AddressStep() {
  const { setValues, setErrors, dirty, values, errors } = useFormikContext();
  const { data, loading, error } = useCep(values?.addressZipcode);

  useEffect(() => {
    setValues({
      ...values,
      addressStreet: data?.street || '',
      addressNeighborhood: data?.neighborhood || '',
      addressCity: data?.city || '',
      state: data?.state || ''
    });
  }, [data, dirty]);

  useEffect(() => {
    if (error?.message && !data) {
      setErrors({ ...errors, addressZipcode: error?.message });
    }
  }, [error, data, errors]);

  useEffect(() => {
    ReactGA.event({
      category: 'rpacadastro',
      action: 'rpa_address_view'
    });
  }, []);

  return (
    <div id="rpa_address_view">
      <img src={thirdStep} alt="Primeiro passo" />
      <FormTitle variant="heading2">Complete seus dados</FormTitle>
      <StepTitle variant="heading4">
        Para finalizar, informe o seu endereço.
      </StepTitle>
      <FormRow>
        <Field name="addressZipcode">
          {({ field, meta }) => (
            <MaskField
              {...field}
              mask="99999-999"
              label="CEP"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              placeholder="Informe o seu CEP"
              adornment={loading && <Progress />}
            />
          )}
        </Field>
      </FormRow>
      <Field name="addressStreet">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Endereço"
            error={meta.error && meta.touched}
            helperText={meta.error && meta.touched ? meta.error : null}
            placeholder="Informe o seu endereço"
            disabled={!data?.street}
          />
        )}
      </Field>
      <FormRow>
        <Field name="addressNumber">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Número"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              placeholder="Informe o número"
            />
          )}
        </Field>
        <Field name="addressComplement">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Complemento (opcional)"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              placeholder="Informe o complemento"
            />
          )}
        </Field>
      </FormRow>
      <Field name="addressNeighborhood">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Bairro"
            error={meta.error && meta.touched}
            helperText={meta.error && meta.touched ? meta.error : null}
            placeholder="Informe o nome do bairro"
            disabled={!data?.neighborhood}
          />
        )}
      </Field>
      <FormRow>
        <Field name="addressCity">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Cidade"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              placeholder="Informe a cidade"
              disabled
            />
          )}
        </Field>
        <Field name="state">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Estado"
              disabled
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              placeholder="Selecione uma opção"
            />
          )}
        </Field>
      </FormRow>
    </div>
  );
}
