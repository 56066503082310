import React from 'react';

import { Container, Title, Action } from './styles';

import cashbackLogo from './images/cashbackLogo.svg';

const CallToAction = () => (
  <Container data-testid="automatic-anticipation-call-to-action">
    <div>
      <Title>Quer saber mais sobre a Antecipação Automática da Blu?</Title>

      <Action
        component="a"
        variant="inverse"
        href="https://www.comunidade.blu.com.br/post/antecipacao-automatica?utm_source=landing-page-AA&utm_medium=botao-AA&utm_campaign=antecipacao-automatica"
        target="_blank"
        rel="noopener noreferrer"
      >
        Acesse nossa Comunidade
      </Action>
    </div>

    <img src={cashbackLogo} alt="cashback" />
  </Container>
);

export default CallToAction;
