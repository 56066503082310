import {
  colorBrandPrimaryPure,
  colorInterfaceLightDeep
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

export const SpinnerStyle = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-top: 3px solid ${colorInterfaceLightDeep};
  border-right: 3px solid ${colorInterfaceLightDeep};
  border-bottom: 3px solid  ${colorBrandPrimaryPure};
  border-left: 3px solid ${colorInterfaceLightDeep};
  animation: spin 0.8s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;
