import styled from 'styled-components';

export const modalStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(52, 62, 79, 0.5)',
    zIndex: 999999
  },
  content: {
    position: 'relative',
    maxWidth: '660px',
    padding: 0,
    margin: 'auto',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto'
  }
};

export const Header = styled.header`
  display: flex;
  height: 74px;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  padding: 0 20px;

  h2 {
    flex: 1;
    color: #55697a;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;
  color: #afafaf;
  font-size: 36px;
  right: 20px;
`;

export const BackButton = styled.button`
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  left: 16px;
  color: #0024e8;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 6px;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 4px;
  }

  i {
    margin-right: 8px;
  }
`;
