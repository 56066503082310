export default function buildAdvancementSimulation({
  clientTransaction,
  advancementSimulation,
  advancementSimulationsEconomicGroup
}) {
  if (advancementSimulationsEconomicGroup) {
    const items = [];

    advancementSimulationsEconomicGroup.map((simulation) =>
      simulation.advancementItems.map((item) =>
        items.push({
          clientUuid: simulation.clientUuid,
          ...item
        })
      )
    );

    return {
      advancementValue: clientTransaction.advancedValue,
      advancementRateValue: clientTransaction.advancesRateValue,
      advancementItems: items
    };
  }

  return advancementSimulation;
}
