import {
  performTransactionAuthorization,
  showTransactionAuthorization,
  resendNotificationApi,
} from 'api/transaction-authorization';
import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/transactionAuthorizationConfirmation';

import delay from '@redux-saga/delay-p';

export function* transactionAuthorization(params) {
  try {
    const response = yield call(
      performTransactionAuthorization,
      params.token,
      params.code
    );

    const { errors, data } = response;

    if (errors && errors.length > 0) {
      throw new Error();
    }

    yield put(ActionCreators.transactionAuthorizationSuccess(data));
  } catch (error) {
    if (error.response.status === 401) {
      window.location.assign('/');
    }
    yield put(ActionCreators.transactionAuthorizationFailure(error.response));
  }
}

export function* resendNotification(params) {
  try {
    yield call(resendNotificationApi, params.token);

    yield put(ActionCreators.resendNotificationSuccess());
  } catch (error) {
    if (error.response.status === 401) {
      window.location.assign('/');
    }

    if (error.response?.data?.message)
      yield put(
        ActionCreators.resendNotificationFailure(error.response?.data?.message)
      );
    else {
      yield put(
        ActionCreators.resendNotificationFailure(
          'Ocorreu um erro ao reenviar a notificação, tente novamente mais tarde.'
        )
      );
    }
  }
}

export function* showTransactionAuthorizationStatus(params) {
  try {
    const response = yield call(showTransactionAuthorization, params.token);

    const { errors, data } = response;

    if (errors && errors.length > 0) {
      throw new Error();
    }

    yield put(
      ActionCreators.showTransactionAuthorizationSuccess(
        data.status,
        data.expires_at,
        data.portability,
        data.automatic_portability,
        data.liveness,
        data.canceled_by
      )
    );
    yield delay(2500);
  } catch (error) {
    if (error.response.status === 401) {
      window.location.assign('/');
    }
    yield put(ActionCreators.showTransactionAuthorizationFailure());
  }
}
