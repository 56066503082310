import styled, { createGlobalStyle, css } from 'styled-components';

import {
  fontSizeSm,
  fontSizeMd,
  fontSizeXs,
  borderRadiusMd,
  spacingStackSm,
  spacingStackXs,
  spacingInlineMd,
  spacingStackXxs,
  spacingInlineXs,
  spacingInlineXxs,
  spacingStackXxxs,
  lineHeightComfy,
  fontSizeXxs,
  spacingInsetLg,
  spacingInlineSm,
  spacingInsetXxs,
  spacingInsetSm,
  colorInterfaceLightUp,
  fontFamilyBase,
  fontSizeXxxs,
  lineHeightMedium,
  fontWeightRegular,
  colorInterfaceDarkUp,
  colorInterfaceDarkPure,
  colorInterfaceDarkDown,
  colorInterfaceLightDown,
  colorInterfaceDarkDeep,
} from '@useblu/ocean-tokens/web/tokens';
import { Devices } from 'utils/getDeviceMedia';
import { Button, Tag } from '@useblu/ocean-react';

export const BreadcrumbStyle = createGlobalStyle`
  .light-main .dark-main-content .breadcrumbs {
    border: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 744px;
    width: 100%;
  }

  .breadcrumbs-container {
    margin-top: 32px;
    padding: 0;
  }
`;

export const Bar = styled.div`
  width: 100%;

  .ods-topbar {
    flex-wrap: nowrap;
    height: auto;
    margin-bottom: ${spacingStackSm};
  }

  .ods-topbar-title {
    margin: 0;
    font-size: ${fontSizeMd};
    color: ${colorInterfaceDarkDeep};

    span {
      font-size: ${fontSizeXs};
      color: ${colorInterfaceDarkDown};
      margin-top: ${spacingStackXxxs};
      font-weight: ${fontWeightRegular};
    }
  }

  @media ${Devices.mobile} {
    .ods-topbar-title {
      font-size: ${fontSizeSm};
    }

    .ods-topbar-left {
      margin-left: 0;
      margin-right: ${spacingStackXs};
    }
  }
`;

export const RefundStatusTag = styled(Tag)`
  &&& {
    @media ${Devices.mobile} {
      margin: 0 ${spacingInlineXs};
    }
  }
`;

export const WrapperResumeValues = styled.div`
  .payment-data {
    position: relative;
    border: 1px solid ${colorInterfaceLightDown};
    border-radius: ${borderRadiusMd};
    padding: ${spacingStackSm} ${spacingInlineXs};
    margin-top: ${spacingInlineXs};
    display: flex;
    flex-direction: column;
    gap: ${spacingStackSm};
  }

  .date-information {
    border-left: 1px solid ${colorInterfaceLightDown};
    border-right: 1px solid ${colorInterfaceLightDown};
    border-bottom: 1px solid ${colorInterfaceLightDown};
    padding: ${spacingInlineXs};
    border-bottom-left-radius: ${borderRadiusMd};
    border-bottom-right-radius: ${borderRadiusMd};
    color: ${colorInterfaceDarkUp};
  }

  @media ${Devices.mobile} {
    padding: 0 ${spacingInlineXs};

    .payment-data {
      gap: ${spacingStackXxs};
      margin-top: 0;
      border: 0;
      padding: ${spacingStackSm} 0;
    }
  }
`;

export const ResumeValuesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingStackXxs};

  @media ${Devices.mobile} {
    gap: ${spacingStackXxs};
  }
`;

export const ResumeValuesItem = styled.div`
  display: flex;
  gap: ${spacingInlineXs};

  > p {
    margin: 0;

    :last-of-type {
      flex: 2;
      color: ${colorInterfaceDarkPure};
      font-size: ${fontSizeXs};
    }

    :first-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      align-items: flex-start;
      text-align: right;
      flex: 1;
      color: ${colorInterfaceDarkDown};
      font-size: ${fontSizeXs};
    }
  }

  @media ${Devices.mobile} {
    flex-direction: column;
    gap: 0;

    > p {
      :first-of-type {
        justify-content: flex-start;
      }
    }
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-top: ${spacingInlineXs};
  margin-top: ${spacingInlineMd};
  border-top: 1px solid ${colorInterfaceLightDown};
  justify-content: flex-end;
  gap: ${spacingInlineXxs};

  @media ${Devices.mobile} {
    display: flex;
    flex-direction: column-reverse;
    border-top: 0;
    margin: ${spacingInlineXs} ${spacingInlineXs} ${spacingInlineXs};
  }
`;

export const Container = styled.div`
  .pgnt-page {
    padding-top: ${spacingStackXs};

    .pgnt-header-back-link > span {
      display: flex;
      align-items: center;
    }

    & > div:first-of-type {
      border: 0;
    }
  }

  @media ${Devices.mobile} {
    .pgnt-page {
      padding: 0;
    }
  }
`;

export const LinkButton = styled(Button).attrs(() => ({
  variant: 'text',
}))`
  &&& {
    padding: 0;
    height: auto;
    font-size: ${fontSizeXxs};

    &:hover,
    &:active,
    &:focus {
      background: none;
    }

    @media ${Devices.mobile} {
      min-width: auto;
    }
  }
`;

export const PaymentCostsContainer = styled.div`
  border: 1px solid ${colorInterfaceLightDown};
  border-radius: ${borderRadiusMd};
  margin-top: ${spacingInsetLg};

  table {
    margin: ${spacingInlineSm} 0;

    td {
      p {
        padding: ${spacingInsetXxs} 0;
      }
    }
  }

  @media ${Devices.mobile} {
    border: none;

    table {
      margin: 0 ${spacingInlineXs};
      margin-top: ${spacingStackXxs};

      tr {
        padding-bottom: ${spacingInsetSm};
      }

      td {
        p {
          padding: 0;
        }
      }
    }
  }
`;

export const PaymentCostsHeader = styled.h3`
  color: ${colorInterfaceDarkDown};
  background-color: ${colorInterfaceLightUp};
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXxxs};
  line-height: ${lineHeightMedium};
  margin-bottom: 0;
  padding: 9.5px ${spacingInsetSm};
`;

export const Table = styled.table`
  margin: 0;
  width: auto;

  tr,
  td {
    border: none;
  }
`;

const baseTableRow = css`
  & > td {
    padding: 0;
    vertical-align: top;

    &:nth-child(1) {
      width: 200px;

      & > p {
        color: ${colorInterfaceDarkDown};
        text-align: end;
      }
    }

    &:nth-child(2) {
      padding-left: ${spacingInsetSm};

      & > p {
        color: ${colorInterfaceDarkPure};
        font-weight: ${fontWeightRegular};
      }
    }

    & > p {
      font-family: ${fontFamilyBase};
      font-size: ${fontSizeXs};
      line-height: ${lineHeightComfy};
      margin: 0;
    }
  }

  @media ${Devices.mobile} {
    display: flex;
    flex-direction: column;

    & > td {
      &:nth-child(1) {
        p {
          text-align: start;
        }
      }

      &:nth-child(2) {
        padding-left: 0;
      }
    }
  }
`;

export const TableRow = styled.tr`
  ${baseTableRow}
`;
