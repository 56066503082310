import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { normalizeCurrency } from 'normalizers';
import Collapse from 'components/Shared/Collapse';
import RefundTransactions from './RefundTransactions';

const RefundHistoryListItem = ({
  refund,
  isCharger,
  showAccountStatementLink
}) => {
  const containerRef = useRef(null);
  const refundedValue = isCharger ? refund.value : refund.chargedValue;
  let refundedAtDescription = 'Processamento pendente';

  if (refund.status === 'canceled') {
    refundedAtDescription = `Devolução cancelada: ${refund.canceledReason}`;
  } else if (refund.status === 'processed') {
    refundedAtDescription =
      isCharger && refund.username
        ? `${refund.refundedAt} - Usuário: ${refund.username}`
        : refund.refundedAt;
  }

  return (
    <div>
      <div className="list__item list__item-sp-between statement-detail__installment refund-history-list__item">
        <div className="refund-history-list__item-header">
          <div className="list__item-left">
            <p>
              <b>
                {refund.order}ª{' '}
                {refund.cancelledPaymentPlan
                  ? 'Cancelamento de nota'
                  : 'Devolução'}
                <small> (ID {refund.id})</small>
              </b>
            </p>
            <p>{refundedAtDescription}</p>
          </div>
          <div className="list__item-right refund-history-list__item-header-value">
            {refund.transactions.length > 0 && (
              <div className="statement-detail__content-group">
                <Collapse
                  getContainerRef={() => containerRef.current}
                  arrowColor="#e1e5ed"
                  parent
                />
              </div>
            )}
            <div className="list__item-with-link">
              <p>
                <b>R$ {normalizeCurrency(parseFloat(refundedValue), false)}</b>
              </p>
            </div>
          </div>
        </div>
        {refund.transactions.length > 0 && (
          <RefundTransactions
            ref={containerRef}
            isCharger={isCharger}
            transactions={refund.transactions}
            showAccountStatementLink={showAccountStatementLink}
          />
        )}
      </div>
    </div>
  );
};

RefundHistoryListItem.propTypes = {
  refund: PropTypes.shape({
    id: PropTypes.number.isRequired,
    order: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    chargedValue: PropTypes.number.isRequired,
    status: PropTypes.string,
    refundedAt: PropTypes.string,
    username: PropTypes.string,
    canceledReason: PropTypes.string,
    transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
    cancelledPaymentPlan: PropTypes.bool
  }).isRequired,
  isCharger: PropTypes.bool.isRequired,
  showAccountStatementLink: PropTypes.bool.isRequired
};

export default RefundHistoryListItem;
