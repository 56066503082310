import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Select, Typography } from '@useblu/ocean-react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';

import { railsContextSelector } from 'selectors/rails-context';
import TableList from './components/TableList';
import DropZone from './components/DropZone';
import useAsyncCNAB from './useAsyncCNAB';
import { Wrapper, Content, Form } from './ImportCNAB.styles';

const validationSchema = yup.object({
  bankFile: yup.mixed().required('Informe o arquivo CNAB.')
});

const ImportCNAB = () => {
  const { clientCpfCnpj } = useSelector(railsContextSelector);
  const {
    getCnabsPerPageState,
    uploadCnabState,
    uploadCnabRequest,
    hasMore,
    loadMore,
    cnabList
  } = useAsyncCNAB(clientCpfCnpj);

  const handleSubmit = (values, actions) => {
    const formData = new FormData();
    formData.append('client', clientCpfCnpj);
    formData.append('original_file', values.bankFile, values.bankFile.name);

    uploadCnabRequest(formData);
    actions.setFieldValue('bankFile', null, false);
  };

  return (
    <Wrapper className="container ocean-iso">
      <Content className="flex-container">
        <Typography variant="heading2">Importação usando CNAB</Typography>
        <Typography variant="paragraph" style={{ padding: '16px 0' }}>
          Transfira seus registros de pedidos de cobrança de forma fácil através
          do arquivo CNAB.
        </Typography>
        <Formik
          initialValues={{
            bankFile: null
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <Field name="bankFile" component={DropZone} />
              <Button type="submit" disabled={uploadCnabState.loading} blocked>
                {uploadCnabState.loading ? 'Importando...' : 'Importar'}
              </Button>
              {uploadCnabState.error && (
                <p className="ods-form-control__helper-text ods-form-control__helper-text--error">
                  {uploadCnabState.error.message}
                </p>
              )}
            </Form>
          )}
        </Formik>
        <TableList
          data={cnabList}
          loading={getCnabsPerPageState.loading}
          hasMore={hasMore}
          loadMore={loadMore}
        />
      </Content>
    </Wrapper>
  );
};

export default ImportCNAB;
