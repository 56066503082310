/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as yup from 'yup';
import { Formik, ErrorMessage } from 'formik';

import { Button, Alert } from '@useblu/ocean-react';

import * as S from './styles';
import { AlertWrapper, CustomInput } from '../../devise.styles';

import { editLeadData, resendToken } from '../../services/signUp';

export const ResetPhoneModal = ({ toggleModal, setChangedPhone }) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState({
    active: false,
    message: ''
  });

  const sessionData = JSON.parse(
    sessionStorage.getItem('auto-signup-anonymus')
  );

  const formikProps = {
    initialValues: {
      phone: ''
    },
    validationSchema: yup.object({
      phone: yup
        .string()
        .required('Este campo é obrigatório.')
        .matches(/\(\d{2}\) \d{5}-\d{4}/, 'Este Telefone não é válido.')
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await editLeadData({
          leadId: sessionData?.leadId,
          phone: values?.phone
        });

        resendToken({
          leadId: sessionData?.leadId,
          authTokenType: 'phone'
        })
          .then(() => {
            setLoading(false);
            toggleModal(false);

            setChangedPhone(values?.phone);

            sessionStorage.setItem(
              'auto-signup-anonymus',
              JSON.stringify({
                ...sessionData,
                phone: values?.phone
              })
            );
          })
          .catch((error) => {
            setLoading(false);

            setHasError({
              active: true,
              message: error?.response?.data?.message || error.message
            });
          });
      } catch (error) {
        setLoading(false);

        setHasError({
          active: true,
          message: error?.response?.data?.message || error.message
        });
      }
    }
  };

  return (
    <S.Container>
      <S.Title>Informe seu novo celular</S.Title>

      <S.Subtitle>
        Enviaremos um código de segurança para confirmar-lo.
      </S.Subtitle>

      {hasError.active && (
        <AlertWrapper>
          <Alert type="warning">{hasError.message}</Alert>
        </AlertWrapper>
      )}

      <Formik validateOnBlur={false} {...formikProps}>
        {({ errors, touched, values, handleSubmit, handleChange }) => (
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <S.WrapInput>
              <CustomInput
                id="phone"
                name="phone"
                mask="phone"
                value={values.phone}
                position="left"
                label="Digite seu novo celular"
                placeholder="(00) 90000-0000"
                className={errors.phone && touched.phone ? 'error' : ''}
                onChange={(e) => {
                  handleChange(e);
                }}
                tabIndex={-1}
              />
              <ErrorMessage
                name="phone"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </S.WrapInput>

            <S.ButtonWrapper>
              <Button variant="secondary" size="md" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="md"
                loading={loading}
              >
                Alterar
              </Button>
            </S.ButtonWrapper>
          </form>
        )}
      </Formik>
    </S.Container>
  );
};

ResetPhoneModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setChangedPhone: PropTypes.func.isRequired
};
