import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import {
  Container,
  ScrollContainer,
  Item,
  ButtonContainer,
  Button,
  Circle,
  LeftNavigation,
  RightNavigation
} from './style';

const Carousel = ({ children, transparent, initialStep }) => {
  const containerReference = useRef();
  const [currentStep, setCurrentStep] = useState(initialStep);
  const childrenCount = React.Children.count(children);

  function scrollToStep(step) {
    const newStep = parseInt(step, 10);
    setCurrentStep(newStep);
    let scrollTo = containerReference.current.offsetWidth * newStep;

    if (transparent)
      scrollTo =
        ((containerReference.current.scrollWidth -
          containerReference.current.offsetWidth) /
          (childrenCount - 1)) *
        newStep;

    containerReference.current.scrollTo({
      top: 0,
      left: scrollTo,
      behavior: 'smooth'
    });
  }

  function handleButtonClick(e) {
    scrollToStep(e.target.dataset.step);
  }

  function handleContainerScroll() {
    const step = Math.round(
      containerReference.current.scrollLeft /
        containerReference.current.offsetWidth
    );
    scrollToStep(step);
  }

  useEffect(() => {
    if (!transparent) {
      containerReference.current.onscroll = debounce(
        handleContainerScroll,
        100
      );
    }

    if (initialStep !== 0) {
      scrollToStep(initialStep);
    }
  }, [transparent, initialStep]);

  return (
    <div>
      <Container ref={containerReference}>
        <ScrollContainer transparent={transparent} width={childrenCount * 100}>
          {React.Children.map(children, (child, index) => {
            const active = index === currentStep;
            return (
              <Item transparent={transparent} active={active}>
                {transparent && active && currentStep > 0 && (
                  <LeftNavigation onClick={() => scrollToStep(currentStep - 1)}>
                    <i className="fa fa-arrow-left" />
                  </LeftNavigation>
                )}
                {child}
                {transparent && active && currentStep + 1 < childrenCount && (
                  <RightNavigation
                    onClick={() => scrollToStep(currentStep + 1)}
                  >
                    <i className="fa fa-arrow-right" />
                  </RightNavigation>
                )}
              </Item>
            );
          })}
        </ScrollContainer>
      </Container>

      <ButtonContainer>
        {React.Children.map(children, (_, index) => (
          <Button
            data-step={index}
            type="button"
            onClick={handleButtonClick}
            aria-label={`Ir para item ${index + 1}`}
          >
            <Circle data-step={index} active={currentStep === index} />
          </Button>
        ))}
      </ButtonContainer>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  transparent: PropTypes.bool,
  initialStep: PropTypes.number
};

Carousel.defaultProps = {
  transparent: false,
  initialStep: 0
};

export default Carousel;
