import axios from 'axios';
import _ from 'lodash';
import qs from 'qs';

import { currencyToNumber } from 'normalizers';
import { getCSRFToken } from 'utils/csrf-token';

export function validatePaymentCollection(data, clientId) {
  return axios({
    method: 'post',
    url: `/lojas/${clientId}/cobrancas.json?validate=true`,
    data: {
      payment_collection: data
    }
  });
}

export function confirmPaymentCollection(data, clientId) {
  return axios({
    method: 'post',
    url: `/lojas/${clientId}/cobrancas.json`,
    data: {
      payment_collection: data
    }
  });
}

export const fetchPaymentCollectionDetails = (uuid, isCharger) =>
  axios.get(
    `/api/payment_collections/${uuid}/${isCharger ? 'charger' : 'charged'}`,
    {
      headers: {
        Accept: 'version=1',
        Authorization: `token ${getCSRFToken()}`
      }
    }
  );

export function paymentCollectionParams(values, option, extraParams = {}) {
  /* eslint-disable camelcase */
  const {
    charged_id,
    installments,
    due_date_on,
    scheduled_at,
    value,
    bluBilletEnabled,
    bluBilletDays
  } = values;
  /* eslint-enable */

  const params = { charged_id, scheduled_at };

  if (values.installments) {
    params.installments_attributes = installments.map((installment) => ({
      value: currencyToNumber(installment.value),
      due_date_on: installment.due_date_on
    }));
  } else {
    params.installments_attributes = [
      { due_date_on, value: currencyToNumber(value) }
    ];
  }

  if (['OPTIMIZED', 'OPTIMIZED_ON_TIME'].includes(option)) {
    params.optimization = true;
  }

  params.blu_billet = Boolean(bluBilletEnabled);
  if (bluBilletEnabled) {
    params.blu_billet_days = Number(bluBilletDays);
  }

  return { ...params, ...extraParams };
}

export function queryStringParams(values, option) {
  return qs.stringify({
    payment_collection: paymentCollectionParams(values, option)
  });
}

export function formatError(error) {
  const fixedKeys = _.mapValues({ ...error }, (val) => val.join(', '));

  if (error.charged) {
    fixedKeys._error = `O cobrado ${error.charged[0]}`;
  }

  return fixedKeys;
}

export const getPendentPayments = (clientUuid) => {
  const query = `
    query {
      paymentCollections(clientUuid: "${clientUuid}") {
        category
        paymentCollections {
          date
          paymentCollections {
            uuid
            charger { name }
            charged { name }
            clientType
            scheduledAt
            status
            totalValue
            inputtedValue
            partialPaymentValue
          }
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
};

export const getPaymentSelected = (clientUuid, uuid) => {
  const query = `
    query {
      paymentCollection(clientUuid: "${clientUuid}", uuid: "${uuid}") {
        id
        agreementUrl
        canChargeAutomatically
        charger {
          name
          cpfCnpj
        }
        charged {
          name
          cpfCnpj
          uuid
        }
        clientType
        createdAt
        installments {
          dueDateOn
          value
        }
        optimization
        scheduledAt
        reference
        status
        totalValue
        inputtedValue
        onTimeValue
        trustedPayeeUrl
        partialPaymentValue
        valueOfPartialPayment
        originalPaymentCollectionUrl
        originalPaymentCollectionTotalValue
        uuid
        paymentOptions {
          kind
          name
          description
          zeroAdvancementRate
          advancementRate
          advancementRateReliable
          discount
          discountPercentage
          cashback
          cashbackPercentage
          scheduledAt
          daysRemaining
          tax
          totalValue
          totalValueWithoutAdvancementRate
          recommended
          selectable
          installments {
            id
            dueDateOn
            value
          }
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
};

export const approvePayment = (clientUuid, uuid) => {
  const query = `
    mutation {
      approvePaymentCollection(clientUuid:"${clientUuid}", uuid: "${uuid}") {
        uuid,
        status
      }
    }
  `;

  return axios.post('/graphql', { query });
};

export const approvePaymentWithKind = (clientUuid, uuid, kind) => {
  const query = `
    mutation {
      approvePaymentCollectionV2(clientUuid:"${clientUuid}", uuid: "${uuid}", kind: "${kind}") {
        uuid
        status
      }
    }
  `;

  return axios.post('/graphql', { query });
};

export const approvePartialPayment = (clientUuid, uuid) => {
  const query = `
    mutation {
      partialPayPaymentCollection(clientUuid:"${clientUuid}", uuid: "${uuid}") {
        grossValue,
        netValue,
        valueRemainingToPay,
        paymentCollection {
          uuid
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
};

export const declinePayment = (clientUuid, uuid) => {
  const query = `
    mutation {
      rejectPaymentCollection(clientUuid:"${clientUuid}", uuid: "${uuid}") {
        uuid
      }
    }
  `;

  return axios.post('/graphql', { query });
};

export const cancelPayment = (clientUuid, uuid) => {
  const query = `
    mutation {
      cancelPaymentCollection(clientUuid:"${clientUuid}", uuid: "${uuid}") {
        uuid
      }
    }
  `;

  return axios.post('/graphql', { query });
};

export const refundPaymentSimulation = (clientUuid, uuid, value) => {
  const query = `
    query {
      paymentCollectionRefundSimulation(clientUuid:"${clientUuid}", uuid: "${uuid}", value: "${value}") {
        currentDate
        refundOutflows {
          id
          releasedAt
          netValue
        }
        advancesRateValue
        insuranceRateValue
        tableOnTimeDiferenceValue
        totalOfOutflows
        refundNetValue
      }
    }
  `;

  return axios.post('/graphql', { query });
};
