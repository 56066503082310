import Moment from 'moment';
import { findIndex } from 'lodash';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_FROM_SERVER = 'YYYY-MM-DD';

export const toDay = () => moment().format(DATE_FORMAT_FROM_SERVER);

export const serverToDate = (day) => moment(day, DATE_FORMAT_FROM_SERVER);

export const serverToLong = (day, group) => {
  if (group === 'month') return serverToDate(day).format('MMMM [de] YYYY');

  return serverToDate(day).format('LL');
};

export const dateToPrint = (day) => moment(day).format(DATE_FORMAT);

export const dateToServer = (day) =>
  moment(day).format(DATE_FORMAT_FROM_SERVER);
export const printToDate = (day) => moment(day, DATE_FORMAT);
export const serverToPrint = (day) => dateToPrint(serverToDate(day));

export const DateObject = (day) => {
  const dayMoment = serverToDate(day);

  return {
    day: dayMoment.format('DD'),
    month: dayMoment.format('MMM'),
    year: dayMoment.format('Y'),
    date: dayMoment.format(DATE_FORMAT)
  };
};

export const indexInRange = (dates, subject) =>
  findIndex(dates, (item) => {
    return subject.day === item.day && subject.date === item.date;
  });

export const range = (from, group) => {
  const fromDate =
    group === 'days'
      ? serverToDate(from).subtract(6, 'months')
      : serverToDate(from).subtract(24, 'months');
  const toDate = serverToDate(from);

  const rageDates = moment.range(fromDate, toDate);
  return Array.from(rageDates.by(group)).map((day) => DateObject(day));
};
