import axios from 'axios';

import { getCSRFToken } from 'utils/csrf-token';

export function getPaymentMethodInfos(clientId) {
  const query = `
  query {
    client(id: ${clientId}){
      automaticBankAccountId
      paymentMethod
    }
  }
  `;

  return axios.post('/graphql', { query });
}

export function setClientPaymentMethod(
  userId,
  paymentMethod,
  bankAccountId = ''
) {
  return axios.post(
    `/lojas/${userId}/update`,
    {
      client: {
        payment_method: paymentMethod,
        automatic_bank_account_id: bankAccountId
      }
    },
    {
      headers: {
        'X-CSRF-Token': getCSRFToken()
      }
    }
  );
}
