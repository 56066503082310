/* Core */
import React from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import classNames from 'classnames';

const FlashMessage = ({ type, message }) => (
  <div className={classNames({ 'flash-message': true, [type]: type })}>
    {message}
  </div>
);

FlashMessage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string
};

export default FlashMessage;
