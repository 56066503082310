import styled from 'styled-components';

import {
  colorInterfaceDarkDeep,
  colorInterfaceDarkDown,
  colorInterfaceDarkUp,
  colorHighlightPure,
  fontFamilyBase,
  fontWeightBold,
  fontWeightMedium,
  colorInterfaceLightDown,
  colorInterfaceDarkPure,
  colorInterfaceLightUp,
  colorStatusPositivePure,
  colorBrandPrimaryPure,
  colorStatusNegativePure
} from '@useblu/ocean-tokens/web/tokens';

export const Balance = styled.div`
  width: 100%;
  text-align: left;
  padding: 13px 16px;
  display: block;
  height: 64px;
  border-radius: 8px;
  white-space: nowrap;
`;

export const List = styled.ul`
  padding: 15px 0;
`;

export const ListItem = styled.li`
  display: flex;
  margin-bottom: 5px;
`;

export const ArrowRightIcon = styled.i`
  margin-right: 15px;
  color: ${colorHighlightPure};
  font-size: 20px;
`;

export const Title = styled.h3`
  display: block;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 2px;
  margin-right: 4px;
  width: 100%;
  white-space: nowrap;
  font-weight: ${fontWeightMedium};
  color: ${colorInterfaceDarkDown};
  &.highlighted {
    color: ${colorInterfaceDarkDeep};
    font-weight: ${fontWeightBold};
  }
`;

export const Value = styled.h1`
  display: block;
  font-size: 16px;
  line-height: 18px;
  margin: 0px;
  width: 100%;
  white-space: nowrap;
  font-family: ${fontFamilyBase};
  font-weight: ${fontWeightMedium};
  color: ${colorInterfaceDarkDown};
  &.highlighted {
    font-weight: 700;
    color: ${colorInterfaceDarkDeep};
  }
  &.off {
    color: ${colorInterfaceDarkUp};
  }
  &.isNegative {
    color: ${colorStatusNegativePure};
  }
`;

export const ClientBalanceNavStyled = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100%;
  width: 100%;
`;

export const LastUpdated = styled.div`
  color: ${colorInterfaceDarkUp};
  font-size: 12px;
  margin-top: 16px;
`;

export const Content = styled.div`
  padding: 20px 16px;
  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    small {
      display: inline;
      font-weight: normal;
      font-size: 12px;
      margin: 0px;
      padding: 0px;
      color: ${colorInterfaceDarkDown};
    }
  }
  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
`;

export const MessageContent = styled.div`
  padding: 20px 16px;
  background-color: ${colorInterfaceLightUp};
  h1 {
    color: ${colorStatusPositivePure};
    font-size: 15px;
    font-weight: ${fontWeightBold};
  }
  p {
    color: ${colorInterfaceDarkDown};
    font-size: 13px;
    margin-bottom: 0;
  }
  i {
    font-size: 19px;
    margin-right: 8px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  border-top: 1px solid ${colorInterfaceLightDown};
`;

export const ItemTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colorInterfaceDarkDeep};
`;

export const ItemValue = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${colorInterfaceDarkPure};
`;

export const HideBalance = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > button {
    display: flex;
    padding: 12px;
    margin: 8px;
    margin-right: 0;
    background: transparent;
    border-radius: 40px;

    &:hover,
    &:active {
      color: ${colorBrandPrimaryPure};
      background-color: ${colorInterfaceLightUp};
      path,
      rect {
        stroke: ${colorBrandPrimaryPure};
      }
    }
  }
`;
