import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  batchPaymentsRequest: ['batchUuid', 'clientUuid'],
  batchPaymentsSuccess: ['data'],
  batchPaymentsFailure: null,
  transactionData: ['data'],
  clearBatchPayments: null,
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {
    client_transactions: [],
    uuid: null,
  },
};

export const request = (state) => ({
  ...state,
  error: false,
  loading: true,
});

export const success = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  data: action.data,
});

export const failure = (state, action) => ({
  ...state,
  loading: false,
  error: true,
});

export const transaction = (state, action) => ({
  ...state,
  transactionData: action.data,
});

export const clearBatchPayments = (state) => ({
  ...state,
  ...INITIAL_STATE,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BATCH_PAYMENTS_REQUEST]: request,
  [Types.BATCH_PAYMENTS_SUCCESS]: success,
  [Types.BATCH_PAYMENTS_FAILURE]: failure,
  [Types.TRANSACTION_DATA]: transaction,
  [Types.CLEAR_BATCH_PAYMENTS]: clearBatchPayments,
});
