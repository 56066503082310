import moment from 'utils/moment.pt-br';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MomentLocaleUtils from 'react-day-picker/moment';

import DayPicker, { DateUtils } from 'react-day-picker';
import React, { useRef, useState, useEffect } from 'react';
import { CalendarOutline } from '@useblu/ocean-icons-react';
import { colorInterfaceDarkUp } from '@useblu/ocean-tokens/web/tokens';
import { useFormikContext, useField } from 'formik';
import InputControl from 'components/Shared/InputControl';

const DatePickerInput = styled(InputControl)`
  &&& {
    input:hover {
      cursor: pointer;
    }
  }
`;

const DatePicker = ({
  name,
  enableWeekends,
  disabledDays,
  min,
  max,
  label,
  enablePastDays,
  position,
  controlError,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const [field] = useField({ name, ...props });
  const { setFieldValue } = useFormikContext();

  const node = useRef(null);

  const closePicker = (e) => {
    if (
      node.current.contains(e.target) &&
      e.target.className !== 'DayPicker-Wrapper'
    ) {
      return;
    }

    setShow(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', closePicker, false);

    return () => {
      document.removeEventListener('mousedown', closePicker, false);
    };
  }, []);

  const disabledDaysHandle = (day) => {
    let isDisabled = false;

    if (!enablePastDays && DateUtils.isPastDay(day)) return true;

    if (min && moment(day).isBefore(min, 'day')) return true;

    if (max && moment(day).isAfter(max, 'day')) return true;

    if (!enableWeekends && (day.getDay() === 0 || day.getDay() === 6))
      return true;

    if (disabledDays.length > 0) {
      disabledDays.forEach((holiday) => {
        if (moment(day).startOf('day').isSame(moment(holiday).startOf('day'))) {
          isDisabled = true;
        }
      });
    }

    if (isDisabled) return true;

    return false;
  };
  const handleDayClick = (day) => {
    setFieldValue(field.name, day);
    setShow(false);
  };

  const handleClickInput = () => {
    setShow(!show);
  };

  const selectedDayAux = moment(field.value, 'L', true).toDate();

  return (
    <div ref={node} className="DayPicker-input-wrapper formik-datepicker">
      <DatePickerInput
        name={name}
        onClick={handleClickInput}
        label={label}
        controlError={controlError}
        placeholder="    /     /     "
        adornment={<CalendarOutline color={colorInterfaceDarkUp} />}
        value={field.value && moment(field.value).format('L')}
        readOnly
      />
      {show && (
        <div className="DayPicker-Wrapper" onClick={closePicker}>
          <DayPicker
            enableOutsideDays
            locale="pt-BR"
            className={classNames({
              'DayPicker--top': position === 'top',
              'DayPicker--bottom': position === 'bottom'
            })}
            localeUtils={MomentLocaleUtils}
            disabledDays={disabledDaysHandle}
            selectedDays={(day) => DateUtils.isSameDay(selectedDayAux, day)}
            onDayClick={handleDayClick}
          />
        </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  enableWeekends: PropTypes.bool,
  disabledDays: PropTypes.array,
  label: PropTypes.string,
  enablePastDays: PropTypes.bool,
  position: PropTypes.string,
  controlError: PropTypes.bool
};

DatePicker.defaultProps = {
  min: null,
  max: null,
  label: '',
  enableWeekends: true,
  disabledDays: [],
  enablePastDays: true,
  position: 'bottom',
  controlError: false
};

export default DatePicker;
