import React from 'react';
import Modal from 'react-modal';
import Loading from 'react-loading';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AntecipationWarningModal = ({ open, loading, onConfirm, onClose }) => {
  const classes = {
    afterOpen: 'modal--open',
    base: 'modal modal-v2',
    beforeClose: 'modal modal-v2'
  };

  const customStyleModal = {
    content: {
      width: 'auto'
    }
  };

  const overlayClasses = {
    afterOpen: 'modal-overlay--open',
    base: 'modal-overlay modal-v2--overlay',
    beforeClose: 'modal-overlay modal-v2--overlay'
  };

  return (
    <Modal
      isOpen={open}
      contentLabel="Confirmação"
      className={classes}
      style={customStyleModal}
      overlayClassName={overlayClasses}
      shouldReturnFocusAfterClose={false}
      ariaHideApp={false}
    >
      <div style={{ padding: '20px' }}>
        <h1>Antecipação de recebíveis</h1>
        <p>
          Atenção! Você está realizando uma operação com <br />
          antecipação de recebíveis. Esta taxa não poderá <br />
          ser estornada. Deseja continuar?
        </p>
        <div className="u-flex">
          <button
            type="button"
            onClick={onClose}
            className="button button--danger button--rounder button--ghost border-less flex-1"
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={onConfirm}
            disabled={loading}
            className={classNames('button button--rounder', {
              'flex-1': loading
            })}
          >
            {loading ? (
              <p className="u-flex content-center">
                <Loading
                  type="spinningBubbles"
                  color="#343e4f"
                  delay={0}
                  width={17}
                  height={17}
                />
              </p>
            ) : (
              'Ok, continuar'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

AntecipationWarningModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

AntecipationWarningModal.defaultProps = {
  loading: false
};

export default AntecipationWarningModal;
