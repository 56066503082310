import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

export default class PosStatementFilter extends Component {
  static propTypes = {
    options: PropTypes.array,
    paymentMethodUrls: PropTypes.object,
    posTransactionsOptionsUrl: PropTypes.string,
    paymentMethodOptions: PropTypes.array,
    authorizationCodesUrl: PropTypes.string,
    selectedCategory: PropTypes.string
  };

  state = {
    open: false,
    selectedCategory: this.props.selectedCategory,
    posOptions: [],
    references: [],
    reference: '',
    authorizationCodes: []
  };

  componentDidMount() {
    const { posTransactionsOptionsUrl } = this.props;

    axios.get(posTransactionsOptionsUrl).then(({ data }) => {
      this.setState({ posOptions: data });
    });
  }

  paymentMethodFields = () => {
    const { selectedCategory } = this.state;
    const { paymentMethodOptions } = this.props;

    return paymentMethodOptions.map((category) => (
      <div className="form-controls" key={category.id}>
        <label htmlFor={`category_${category.id}`}>
          <input
            type="radio"
            name="filter[payment_method]"
            value={category.id}
            id={`category_${category.id}`}
            checked={selectedCategory === category.id}
            onChange={(e) => this.selectCategory(e, category.id)}
          />
          {category.name}
        </label>
      </div>
    ));
  };

  handleSearchAuthorizationCode = (inputValue) => {
    const { authorizationCodesUrl } = this.props;

    return axios
      .get(`${authorizationCodesUrl}?search=${inputValue}`)
      .then(({ data: { authorization_codes } }) =>
        authorization_codes.map((code) => ({ value: code, label: code }))
      );
  };

  toggleFilter = () => {
    const { open } = this.state;

    this.setState({
      open: !open
    });
  };

  selectCategory = (e) => {
    this.setState({
      selectedCategory: e.target.value
    });
  };

  payeeFilter = (item, search) => {
    const name = item.name || item;
    return name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  };

  formAction = () => {
    const { selectedCategory } = this.state;
    const { paymentMethodUrls } = this.props;

    if (!selectedCategory) {
      return '';
    }

    return paymentMethodUrls[selectedCategory];
  };

  render() {
    const { open, posOptions, selectedCategory } = this.state;

    return (
      <div className="statement__filter">
        <div className="statement__filter-info">
          <div className="statement__filter-info-right">
            <button
              type="button"
              className="button button--secondary button--medium"
              onClick={this.toggleFilter}
            >
              {open ? 'Esconder Filtro' : 'Mostrar Filtro'}
            </button>
          </div>
        </div>

        {open && (
          <div className="statement__filter-content flex-container is-padded">
            <form action={this.formAction()} method="GET">
              <div className="form-row statement__filter-types">
                <div className="categories">{this.paymentMethodFields()}</div>
              </div>

              {posOptions.length > 0 && (
                <div className="form-row">
                  <div className="form-controls">
                    <label htmlFor="filter_payees_options">POS</label>
                    <Select
                      name="filter[terminal_number]"
                      options={posOptions}
                      placeholder="Escolha uma opção"
                      getOptionValue={({ id }) => id}
                      getOptionLabel={({ name }) => name}
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
              )}

              <div className="form-row">
                <div className="form-controls">
                  <label htmlFor="filter_reference">
                    Código de autorização
                  </label>
                  <AsyncSelect
                    name="filter[provider_authorization_code]"
                    loadOptions={this.handleSearchAuthorizationCode}
                    placeholder="Selecione uma opção..."
                    cacheOptions
                    noOptionsMessage={() => 'Digite para buscar'}
                    classNamePrefix="react-select"
                  />
                </div>
              </div>

              <div className="form-row actions">
                <button
                  type="submit"
                  className="button button--primary button--medium"
                  disabled={!selectedCategory}
                >
                  Filtrar transações
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}
