import React from 'react';
import { Input } from '@useblu/ocean-react';
import InputMask from 'react-input-mask';

import StyledTextField from './styles';

const DateField = ({ field, form, ...props }) => {
  return (
    <StyledTextField>
      <InputMask
        {...field}
        {...props}
        placeholder="dd/mm/aaaa"
        maskChar=""
        mask="d9/m9/ys99"
        formatChars={{
          9: '[0-9]',
          a: '[A-Za-z]',
          '*': '[A-Za-z0-9]',
          d: '[0-3]',
          m: '[0-1]',
          y: '[1-2]',
          s: '(0|9)'
        }}
      >
        {(inputProps) => (
          <Input
            {...inputProps}
            value={field?.value}
            onChange={field?.handleChange}
          />
        )}
      </InputMask>
    </StyledTextField>
  );
};

export default DateField;
