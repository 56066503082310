import React from 'react';
import PropTypes from 'prop-types';

import { Radio } from '@useblu/ocean-react';
import Actions from './Actions';

import {
  Title,
  Caption,
  FormRadioConfirmation,
  ModalFooterContainer,
  FooterText,
  ModalContent,
  ScrollMobile,
  TitlePage
} from '../modal.styles';

const CnpjSelection = ({
  header,
  title,
  description,
  nextStep,
  prevStep,
  hasInfo,
  loading,
  hasPrev,
  isChecked,
  nextText,
  nextClass,
  prevClass
}) => {
  const [checked, setChecked] = React.useState(isChecked);

  const onNextStep = () => {
    nextStep(checked);
  };

  return (
    <>
      <ScrollMobile>
        <TitlePage>{header}</TitlePage>
        <Title>{title}</Title>

        <ModalContent>
          <Caption>
            <span data-testid="description">{description}</span>
          </Caption>
          <FormRadioConfirmation className="ocean-iso">
            <Radio
              label="Esta loja"
              name="all_cnpjs"
              className="fullstory-radio-apenas_atual"
              checked={checked === false}
              onChange={() => {
                setChecked(false);
              }}
            />
            <Radio
              label="Para todas as lojas"
              name="all_cnpjs"
              className="fullstory-radio-todas_as_lojas"
              checked={checked === true}
              onChange={() => {
                setChecked(true);
              }}
            />
          </FormRadioConfirmation>

          {hasInfo && (
            <FooterText className="mobile">
              A taxa não inclui MDR ou &quot;Taxa do Cartão&quot;.
            </FooterText>
          )}
        </ModalContent>
      </ScrollMobile>
      <ModalFooterContainer>
        {!hasInfo && <FooterText className="desktop" />}
        {hasInfo && (
          <FooterText className="desktop">
            A taxa não inclui MDR ou &quot;Taxa do Cartão&quot;.
          </FooterText>
        )}
        <Actions
          prevStep={prevStep}
          hasPrev={hasPrev}
          nextStep={onNextStep}
          nextText={nextText}
          nextClass={nextClass}
          prevClass={prevClass}
          loading={loading}
        />
      </ModalFooterContainer>
    </>
  );
};

CnpjSelection.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func,
  hasPrev: PropTypes.bool,
  hasInfo: PropTypes.bool,
  isChecked: PropTypes.bool,
  loading: PropTypes.bool,
  nextText: PropTypes.string,
  nextClass: PropTypes.string,
  prevClass: PropTypes.string
};

CnpjSelection.defaultProps = {
  loading: false,
  prevStep: () => false,
  hasPrev: false,
  hasInfo: false,
  isChecked: true,
  nextText: 'Avançar',
  nextClass: '',
  prevClass: ''
};

export default CnpjSelection;
