import styled from 'styled-components';
import { Devices } from 'utils/getDeviceMedia';
import { colorBrandPrimaryPure } from '@useblu/ocean-tokens/web/tokens';

export const ModalContent = styled.div`
  display: flex;

  & > div:first-of-type {
    flex: 1;
  }

  & > div:last-of-type {
    flex: 3;
    h1 {
      color: ${colorBrandPrimaryPure};
    }
  }

  @media ${Devices.mobile} {
    flex-direction: column;
    align-items: center;

    & > div:last-of-type {
      text-align: center;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${Devices.mobile} {
    button {
      flex: 1;
    }
  }
`;
