import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { normalizeCurrency } from 'normalizers';

const ChargerRefundSummary = ({ admNetValue, grossValue }) => (
  <Fragment>
    {admNetValue > 0 && (
      <span>
        Valor recebido:{' '}
        <span className="statement-detail__refunded--inflow">
          R$ {normalizeCurrency(admNetValue)}
        </span>
      </span>
    )}
    {grossValue > 0 && (
      <span>
        Saldo restante:{' '}
        <span className="statement-detail__refunded--inflow">
          R$ {normalizeCurrency(grossValue)}
        </span>
      </span>
    )}
  </Fragment>
);

ChargerRefundSummary.propTypes = {
  admNetValue: PropTypes.number.isRequired,
  grossValue: PropTypes.number.isRequired
};

export default ChargerRefundSummary;
