import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@useblu/ocean-react';
import * as S from '../styles';
import ilustraGE from '../assets/ilustra-GE.svg';

const AnticipationFromGE = ({ storesState, outflow }) => {
  const [haveAccessToEconomicGroup, setHaveAccessToEconomicGroup] = useState();

  useEffect(() => {
    if (storesState) {
      const haveAccess = storesState.some((store) => {
        return store.economicGroup;
      });

      setHaveAccessToEconomicGroup(haveAccess);
    }
  }, [storesState]);

  const redirectToOriginalPayment = () => {
    const store = storesState.find((store) => store.economicGroup);
    window.location.href = `/lojas/${store.id}/transacoes/${outflow?.uuid}?financial_statement=true`;
  };

  return (
    <S.AnticipationGEContainer>
      <img src={ilustraGE} alt="Ilustração" />
      <Typography variant="description">
        {haveAccessToEconomicGroup
          ? 'Para conferir os detalhes do custo dessa antecipação, consulte o pagamento original.'
          : 'Solicite acesso ao grupo econômico à pessoa responsável para conferir os detalhes do custo desta antecipação.'}
      </Typography>
      {haveAccessToEconomicGroup && (
        <Button size="sm" onClick={redirectToOriginalPayment}>
          Acessar pagamento original
        </Button>
      )}
    </S.AnticipationGEContainer>
  );
};

AnticipationFromGE.propTypes = {
  outflow: PropTypes.object,
  storesState: PropTypes.array,
};

AnticipationFromGE.defaultProps = {
  outflow: {},
  storesState: [],
};

export default AnticipationFromGE;
