import React, { useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useAsyncFn } from 'react-use';

import Snackbar, { useSnackbar } from 'components/Shared/Snackbar';
import DeviseScreenLayout from '../components/DeviseScreenLayout';
import { requestNewPassword } from '../services/password';

import * as S from '../devise.styles';

const NewPassword = ({ firstAccess }) => {
  const [status, setStatus] = useState(undefined);
  const [requestState, requestNew] = useAsyncFn(requestNewPassword);
  const [clientEmail, setClientEmail] = useState('');
  const [hasResendEmail, setHasResendEmail] = useState(false);
  const {
    isActive: snackIsActive,
    message: snackMessage,
    type: snackType,
    openSnackBar
  } = useSnackbar();

  const formik = {
    initialValues: {
      email: ''
    },
    validateOnBlur: false,
    validateOnChange: true,
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Adicione um E-mail válido')
        .required('Este campo é obrigatório')
    }),

    onSubmit: (values) => {
      requestNew(values);
      setClientEmail(values?.email);
    }
  };

  React.useEffect(() => {
    if (requestState.loading) return;

    if (requestState?.value?.data?.message) {
      setStatus(requestState.value.data.message);
    }
  }, [requestState]);

  const handleResendEmail = () => {
    setHasResendEmail(true);
    requestNew({ email: clientEmail });

    setTimeout(() => {
      setHasResendEmail(false);
    }, 15000);

    if (requestState?.value?.status === 200) {
      openSnackBar('Código reenviado com sucesso.', 'success');
      setStatus(requestState.value.data.message);
    }

    if (requestState.error) {
      openSnackBar('Falha no envio, tente novamente.', 'error');
    }
  };

  return (
    <DeviseScreenLayout>
      <>
        <Snackbar
          isActive={snackIsActive}
          message={snackMessage}
          type={snackType}
        />
        {status === 'email_sent' && (
          <S.ResetPasswordInstructions>
            <S.Title>Instruções enviadas</S.Title>

            <S.Subtitle>
              <p>
                Caso exista uma conta associada à <strong>{clientEmail}</strong>
                , enviaremos um um link com as instruções de primeiro acesso,
                válido por 30 minutos.
              </p>
              <p>
                Se não encontrar, veja sua caixa de spam ou reenvie o e-mail.
              </p>

              <S.WrapBtns>
                <S.BtnSubmit
                  onClick={(e) => {
                    e.preventDefault();
                    setStatus(undefined);
                  }}
                  variant="text"
                  tabIndex={-1}
                >
                  Voltar
                </S.BtnSubmit>
                <S.BtnSubmit
                  onClick={handleResendEmail}
                  variant="secondary"
                  tabIndex={-1}
                  disabled={hasResendEmail}
                >
                  Reenviar e-mail
                </S.BtnSubmit>
              </S.WrapBtns>
            </S.Subtitle>
          </S.ResetPasswordInstructions>
        )}

        {!status && (
          <>
            <S.TitleH2 $firstAccess={firstAccess}>
              {firstAccess
                ? 'Instruções de primeiro acesso'
                : 'Redefina sua senha'}
            </S.TitleH2>
            <Snackbar
              isActive={snackIsActive}
              message={snackMessage}
              type={snackType}
            />

            <S.Subtitle>
              <span>
                {firstAccess
                  ? 'Informe seu e-mail e enviaremos um link com as instruções de primeiro acesso à sua Conta Digital Blu.'
                  : 'Iremos enviar as instruções de redefinição de senha para o seu e-mail cadastrado na Blu.'}
              </span>
            </S.Subtitle>

            <Formik {...formik} validationSchema={formik.validationSchema}>
              {({ errors, values, touched, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <S.WrapInput>
                    <S.CustomInput
                      id="email"
                      type="email"
                      name="email"
                      label="E-mail"
                      placeholder="exemplo@email.com.br"
                      className={errors.email && touched.email ? 'error' : ''}
                      value={values.email}
                      tabIndex={-1}
                    />
                    <ErrorMessage
                      name="email"
                      render={(msg) => <span className="error-msg">{msg}</span>}
                    />
                  </S.WrapInput>

                  <S.WrapBtns style={{ flexDirection: 'row-reverse' }}>
                    <S.BtnSubmit type="submit" tabIndex={-1}>
                      Enviar
                    </S.BtnSubmit>
                    <S.BtnSubmit
                      onClick={(e) => {
                        e.preventDefault();
                        window.history.back();
                      }}
                      variant="secondary"
                      tabIndex={-1}
                    >
                      Voltar
                    </S.BtnSubmit>
                  </S.WrapBtns>
                </form>
              )}
            </Formik>
          </>
        )}
      </>
    </DeviseScreenLayout>
  );
};

NewPassword.propTypes = {
  firstAccess: PropTypes.bool.isRequired
};

export default NewPassword;
