import React from 'react';
import PropTypes from 'prop-types';
import { columnType } from '../../types';

const TableBodyColumn = ({
  column,
  data,
  components,
  indexRow,
  indexCol,
  classNames
}) => {
  let content = null;

  if (column.disableDataKey && data[column.disableDataKey]) {
    content = column.disableMessage;
  } else {
    content = column.render
      ? column.render(data, indexRow, indexCol)
      : data[column.dataIndex];
  }

  const getContent = (content) => {
    if (column.onCell && components) {
      const { cell: Cell } = components;
      return (
        <Cell {...column.onCell(data, indexRow, indexCol)}>{content}</Cell>
      );
    }
    return content;
  };

  const getClassName = () => {
    let className = `table-list__body-item column-body-${column.key}`;
    const { cell = '' } = classNames;
    if (cell) {
      if (typeof cell === 'string') {
        className += ` ${cell}`;
      } else {
        className += ` ${cell(data)}`;
      }
    }
    return className;
  };

  return <td className={getClassName()}>{getContent(content)}</td>;
};

TableBodyColumn.propTypes = {
  column: columnType.isRequired,
  classNames: PropTypes.shape({
    cell: PropTypes.any,
    row: PropTypes.any
  }),
  components: PropTypes.shape({
    cell: PropTypes.any
  }),
  data: PropTypes.object.isRequired,
  indexRow: PropTypes.number.isRequired,
  indexCol: PropTypes.number.isRequired
};

TableBodyColumn.defaultProps = {
  components: null,
  classNames: {}
};

export default TableBodyColumn;
