/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Typography, Button } from '@useblu/ocean-react';
import {
  spacingInlineXxs,
  colorBrandPrimaryPure,
  spacingInlineSm,
  spacingStackXs,
  spacingInlineXs
} from '@useblu/ocean-tokens/web/tokens';

export const Wrapper = styled.div`
  width: 476px;

  & .ods-divider {
    margin: 24px 0;
  }

  & .ods-form-control__root + .ods-form-control__root {
    margin-top: 32px;
  }

  & .ods-btn {
    margin-top: 24px;
  }

  & .ods-input--left {
    flex-direction: row-reverse;

    input {
      padding: 0 0 0 10px;
    }

    .ods-input__adornment {
      padding: 0 0 0 16px;
    }
  }

  & .ods-alert {
    margin-top: 24px;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Title = styled(Typography)`
  &&& {
    color: ${colorBrandPrimaryPure};
    margin-bottom: ${spacingInlineXxs};
  }
`;
export const Subtitle = styled(Typography)`
  &&& {
    margin-bottom: ${spacingInlineSm};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacingStackXs};
`;

export const TotalValueButton = styled(Button)`
  &&& {
    margin-top: 0;
    margin-bottom: ${spacingInlineXs};
  }
`;
