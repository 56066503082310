/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import Loading from 'react-loading';
import _ from 'lodash';
import Modal from 'react-modal';

class QsaAccountsDeleteModal extends Component {
  renderDeleteModal() {
    const { loading } = this.props;

    const classes = {
      afterOpen: 'modal--open',
      base: 'modal',
      beforeClose: 'modal'
    };

    const overlayClasses = {
      afterOpen: 'modal-overlay--open',
      base: 'modal-overlay',
      beforeClose: 'modal-overlay'
    };

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentLabel="Modal"
        className={classes}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
      >
        <h2>Excluir esse sócio?</h2>
        <div className="modal-action">
          <button
            className="button button--secondary"
            onClick={() => this.props.onClose()}
          >
            Cancelar
          </button>
          <button
            className="button button--primary"
            onClick={() => this.props.onDelete()}
            disabled={false}
          >
            Sim
            {this.props.loading && (
              <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                <Loading
                  type="spinningBubbles"
                  color="#ffffff"
                  width="15px"
                  height="15px"
                  delay={0}
                />
              </div>
            )}
          </button>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <div className="flex-container">{this.renderDeleteModal()}</div>
      </div>
    );
  }
}

QsaAccountsDeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

QsaAccountsDeleteModal.defaultProps = {
  isOpen: false,
  loading: false
};

export default QsaAccountsDeleteModal;
