/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useAsyncFn } from 'react-use';
import PropTypes from 'prop-types';

import useClientFeatureStatus from 'components/Dashboard/hooks/useClientFeatureStatus';
import { Steps } from '@useblu/ocean-react';
import { currencyToNumber, normalizeSeparator } from 'normalizers';
import { anticipationOptinApi } from 'api/clients';
import CnpjSelectionAutomaticAntecipation from '../components/CnpjSelectionAutomaticAntecipation';
import Checkout from '../components/Checkout';
import FeatureTermsAutomaticAntecipation from '../components/FeatureTermsAutomaticAntecipation';
import BTokenValidationAutomaticAnticipation from '../components/BTokenValidationAutomaticAnticipation';

import { Frame } from '../modal.styles';
import OptInSuccessAutomaticAnticipation from '../components/OptInSuccessAutomaticAnticipation';

const AutomaticAnticipationModalOptIn = ({
  onRequestClose,
  automaticAnticipationFixRate,
  automaticAnticipationWith2FA,
  automaticAnticipationVariableRate,
}) => {
  const [planOption, setPlanOption] = useState(
    'automatic_anticipation_by_installments'
  );
  const [modalStep, setModalStep] = useState(0);
  const [allCnpjs, setAllCnpjs] = useState(true);

  const [automaticAnticipationState, automaticAnticipationRequest] =
    useAsyncFn(anticipationOptinApi);

  const cummonProps = {
    automaticAnticipationFixRate: automaticAnticipationFixRate?.replace(
      '.',
      ','
    ),
    automaticAnticipationVariableRate:
      automaticAnticipationVariableRate?.replace('.', ','),
  };

  const {
    clientUUID,
    loading,
    automaticAnticipationOptInConfirmed,
    setLoading,
    updateStatus,
  } = useClientFeatureStatus();

  useEffect(() => {
    const { value } = automaticAnticipationState;
    const firstClient = value?.data[0];

    if (
      firstClient &&
      (firstClient?.automatic_anticipation_by_installment_status ||
        firstClient?.automatic_anticipation_status)
    ) {
      updateStatus();
    }
  }, [automaticAnticipationState]);

  const onClose = () => {
    setModalStep(0);
    onRequestClose('AutomaticAnticipation');
  };

  const onSubmitAutomaticAnticipation = () => {
    setLoading(true);
    automaticAnticipationRequest(clientUUID, planOption, allCnpjs);
  };

  const selectContent = (step) => {
    if (automaticAnticipationOptInConfirmed)
      return (
        <OptInSuccessAutomaticAnticipation
          nextStep={onClose}
          nextText="Finalizar"
        />
      );

    switch (step) {
      default:
        return (
          <CnpjSelectionAutomaticAntecipation
            header={<Steps currentStep={1} steps={3} />}
            {...cummonProps}
            loading={loading}
            planOption={planOption}
            isChecked={allCnpjs}
            prevStep={() => {
              setPlanOption('');
            }}
            nextStep={(e) => {
              setModalStep(1);
              setAllCnpjs(e);
            }}
          />
        );
      case 1:
        return (
          <FeatureTermsAutomaticAntecipation
            header={<Steps currentStep={2} steps={3} />}
            loading={loading}
            {...cummonProps}
            nextStep={() => {
              setModalStep(2);
            }}
            prevStep={() => {
              setModalStep(0);
            }}
          />
        );
      case 2:
        return (
          <Checkout
            {...cummonProps}
            prevClass="2_campanha_aa__taxa1"
            nextClass="_confirmar_campanha_aa__taxa1"
            header={<Steps currentStep={3} steps={3} />}
            product="Antecipação Automática Blu"
            taxPlan={
              planOption === 'automatic_anticipation'
                ? `Taxa fixa de ${cummonProps.automaticAnticipationFixRate}%`
                : `Taxa variável de ${
                    cummonProps.automaticAnticipationVariableRate
                  }% + ${normalizeSeparator(
                    currencyToNumber(
                      cummonProps.automaticAnticipationVariableRate
                    ) / 2
                  )}% por parcela`
            }
            loading={loading}
            allCnpjs={allCnpjs}
            nextStep={() => {
              if (automaticAnticipationWith2FA) {
                setModalStep(3);
              } else {
                onSubmitAutomaticAnticipation();
              }
            }}
            prevStep={() => {
              setModalStep(1);
            }}
          />
        );
      case 3:
        return (
          <BTokenValidationAutomaticAnticipation
            clientUUID={clientUUID}
            allCnpjs={allCnpjs}
            updateStatus={updateStatus}
          />
        );
    }
  };

  return <Frame $withoutHeight>{selectContent(modalStep)}</Frame>;
};

AutomaticAnticipationModalOptIn.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  automaticAnticipationWith2FA: PropTypes.bool,
  automaticAnticipationFixRate: PropTypes.string,
  automaticAnticipationVariableRate: PropTypes.string,
};

AutomaticAnticipationModalOptIn.defaultProps = {
  automaticAnticipationFixRate: '',
  automaticAnticipationWith2FA: false,
  automaticAnticipationVariableRate: '',
};

export default AutomaticAnticipationModalOptIn;
