import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@useblu/ocean-react';

import expiredImage from '../images/ilustra-expirado.svg';
import lockedImage from '../images/ilustra-locked.svg';
import {
  ModalTitle,
  ModalParagraph,
  Content,
  Image,
  ButtonContainer,
} from './ExpiredModalStyles';

export default function ExpiredModal({ isOpen, close, canceledBy }) {
  const canceled = !!canceledBy?.id;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      ariaHideApp={false}
      id="modal"
      maxWidth="sm"
    >
      <Content>
        <Image src={canceled ? lockedImage : expiredImage} />
        <div>
          <ModalTitle>
            {canceled
              ? 'Não é possível concluir a operação'
              : 'O tempo para autorizar acabou'}
          </ModalTitle>
          <ModalParagraph>
            {canceled
              ? `${canceledBy.name} recusou a autorização. Se isso foi um engano, tente fazer novamente.`
              : 'Você pode tentar autorizar novamente. Caso já tenha feito o processo no app verifique se a operação foi concluída.'}
          </ModalParagraph>
        </div>
      </Content>
      <ButtonContainer>
        <Button onClick={close}>{canceled ? 'Entendi' : 'OK'}</Button>
      </ButtonContainer>
    </Modal>
  );
}

ExpiredModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  canceledBy: PropTypes.object,
};

ExpiredModal.defaultProps = {
  canceledBy: null,
};
