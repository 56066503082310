/* eslint-disable import/prefer-default-export */
export const MocksPlans = {
  title: 'Taxas e Valores',
  plans: {
    title: 'Operação de cartão',
    items: [
      {
        id: 0,
        name: 'Débito',
        description: '1,20%'
      },
      {
        id: 1,
        name: 'Crédito à Vista',
        description: '2,20%'
      },
      {
        id: 2,
        name: 'Crédito em 2x a 6x',
        description: '2,40%'
      },
      {
        id: 3,
        name: 'Crédito em 7x a 12x',
        description: '1,06%'
      },
      {
        id: 4,
        name: 'Bandeiras Hiper e Elo',
        description: '+1,50%',
        resume: 'em todas as taxas'
      }
    ]
  },
  tax: {
    title: 'Outras taxas',
    items: [
      {
        id: 0,
        name: 'Antecipação de Recebíveis*',
        description: '2,39%',
        resume: '(CDI + 3.0% a.m)'
      }
    ]
  },
  description:
    '*Lembrando que com a Blu você tem a opção de comprar com seus fornecedores pagando ZERO para antecipar, o que pode gerar uma economia de até 10% para o seu negócio.'
};
