import axios from 'axios';

export function createMultiAcquirerOrder({
  clientUUID,
  documentType,
  documentNumber,
  documentDateEmission,
  documentPlaceEmission,
  posAmount,
  documentUfEmission,
  legalRepresentativeCpf,
  legalRepresentativeEmail,
  legalRepresentativePhone,
  legalRepresentativeNationality,
  addressId
}) {
  const query = `mutation {
    createMultiAcquirerOrder(
      clientUuid: "${clientUUID}", 
      documentType: "${documentType}", 
      documentNumber: "${documentNumber}", 
      documentDateEmission: "${documentDateEmission}", 
      documentPlaceEmission: "${documentPlaceEmission}", 
      posAmount: ${posAmount}, 
      documentUfEmission: "${documentUfEmission}",
      addressId: ${addressId},
      legalRepresentativeCpf: "${legalRepresentativeCpf}",
      legalRepresentativeEmail: "${legalRepresentativeEmail}",
      legalRepresentativePhone: "${legalRepresentativePhone}",
    ) {
      success
    }
  }`;

  return axios.post('/graphql', { query });
}

export function getBigBoostConsultingData(client_uuid) {
  const query = `
      query {
        bigBoostConsulting(clientUuid: "${client_uuid}") {
          legalRepresentativeCpf
          legalRepresentativeEmail
          legalRepresentativePhone
        }
      }
    `;

  return axios.post('/graphql', { query });
}

export function sendLagUpload(uuid, file) {
  const query = `
    mutation {
      uploadLagLicense(uuid:"${uuid}", file:${null}) {
        success
      }
  }`;
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  };
  const data = new FormData();
  data.append('file', file);
  data.append('query', query);
  return axios({
    method: 'post',
    url: '/graphql',
    query,
    data,
    config
  });
}
