import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Router, Switch, Route } from 'react-router-dom';
import { useInterval } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import actions from 'store/ducks/bToken/actions';

import BTokenNotActive from './BTokenNotActive';
import BTokenActive from './BTokenActive';
import BTokenConfiguration from './BTokenConfiguration';

const BToken = ({ bTokenActive }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bTokenState = useSelector((state) => state.bToken);
  const [bTokenControl, setBTokenControl] = useState(bTokenActive);

  useEffect(() => {
    if (Object.keys(bTokenState.byId || {}).length > 0 && !bTokenActive) {
      setBTokenControl(true);
      window.location.href = '/btoken/sucesso';
    } else if (
      bTokenActive &&
      bTokenState.byId &&
      Object.keys(bTokenState.byId).length === 0
    ) {
      window.location.href = '/btoken';
    }
  }, [bTokenState, bTokenActive, history]);

  useEffect(() => {
    if (bTokenActive) {
      dispatch(actions.fetchBTokenByUserRequest());
    }
  }, [dispatch, bTokenActive]);

  useInterval(
    () => {
      dispatch(actions.fetchBTokenByUserRequest());
    },
    !bTokenControl ? 3000 : null
  );

  return (
    <div className="ocean-iso">
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/btoken"
            render={() =>
              bTokenControl ? <BTokenConfiguration /> : <BTokenNotActive />
            }
          />
          <Route path="/btoken/sucesso" component={BTokenActive} />
        </Switch>
      </Router>
    </div>
  );
};

BToken.propTypes = {
  bTokenActive: PropTypes.bool.isRequired
};

export default BToken;
