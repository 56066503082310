import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ScoreIndicator from 'components/BluRating/ScoreIndicator';
import {
  Content,
  Overview,
  OverviewDetails,
  List,
  ListItem,
  ListItemHeader,
  ListItemContent,
  ListItemContentItem,
  Paragraph,
  HorizontalLine
} from './DetailsModalStyles';
import ModalLayout from './ModalLayout';
import HelpModal from './HelpModal';
import iconSell from './images/icon-sell.svg';
import iconBuy from './images/icon-buy.svg';

const DetailsModal = ({
  isOpen,
  relationshipLevel,
  longMessage,
  scores,
  onClose
}) => {
  const [showHelpModal, setShowHelpModal] = useState(false);

  const {
    numberOfSales,
    totalSales,
    totalPurchases,
    numberOfSuppliers
  } = scores;

  const hasNumberOfSuppliers = Boolean(numberOfSuppliers?.rating.value);
  const hasTotalPurchases = Boolean(totalPurchases?.rating.value);
  const hasSaleByStore = Boolean(numberOfSales?.rating.value);
  const hasTotalSales = Boolean(totalSales?.rating.value);

  const toggleShowHelpModal = () => {
    setShowHelpModal(!showHelpModal);
  };

  return (
    <>
      <HelpModal isOpen={showHelpModal} onClose={toggleShowHelpModal} />
      <ModalLayout
        title="Pontuação Detalhada"
        onClose={onClose}
        isOpen={isOpen && !showHelpModal}
      >
        <Content>
          <Overview>
            <ScoreIndicator
              id="score-average"
              size="md"
              rating={relationshipLevel.rating}
            >
              Pontuação
              <br />
              média
            </ScoreIndicator>
            <OverviewDetails>
              <h3 data-testid="overview-title">
                <em
                  style={{
                    color: relationshipLevel.rating.hexColor
                  }}
                >
                  {relationshipLevel.rating.description}
                </em>
              </h3>
              <Paragraph
                dangerouslySetInnerHTML={{ __html: longMessage.thirdPerson }}
              />
              <button type="button" onClick={toggleShowHelpModal}>
                Entenda como funciona
              </button>
            </OverviewDetails>
          </Overview>
          <HorizontalLine />
          <Paragraph>
            A pontuação consolidada acima leva em consideração a média de alguns
            fatores cujos principais são os totais de compras e vendas em 30 ou
            60 dias.
          </Paragraph>
          <List>
            <ListItem>
              <ListItemHeader>
                <img src={iconBuy} alt="Compras" /> Compras{' '}
                <small>(últimos 60 dias)</small>
              </ListItemHeader>
              <ListItemContent>
                {hasNumberOfSuppliers && (
                  <ListItemContentItem>
                    <ScoreIndicator rating={numberOfSuppliers.rating} />
                    <p>
                      Fornecedores únicos (média){' '}
                      <small data-testid="suppliers-average">
                        {numberOfSuppliers.range}
                      </small>
                    </p>
                  </ListItemContentItem>
                )}
                {hasTotalPurchases && (
                  <ListItemContentItem>
                    <ScoreIndicator rating={totalPurchases.rating} />
                    <p>
                      Volume
                      <br />
                      Total{' '}
                      <small data-testid="purchases-total">
                        {totalPurchases.range}
                      </small>
                    </p>
                  </ListItemContentItem>
                )}
              </ListItemContent>
            </ListItem>
            <ListItem>
              <ListItemHeader>
                <img src={iconSell} alt="Vendas" /> Vendas{' '}
                <small>(últimos 30 dias)</small>
              </ListItemHeader>
              <ListItemContent>
                {hasSaleByStore && (
                  <ListItemContentItem>
                    <ScoreIndicator rating={numberOfSales.rating} />
                    <p>
                      Quantidade Total{' '}
                      <small data-testid="sales-amount">
                        {numberOfSales.range}
                      </small>
                    </p>
                  </ListItemContentItem>
                )}
                {hasTotalSales && (
                  <ListItemContentItem>
                    <ScoreIndicator rating={totalSales.rating} />
                    <p>
                      Volume
                      <br />
                      Total{' '}
                      <small data-testid="sales-total">
                        {totalSales.range}
                      </small>
                    </p>
                  </ListItemContentItem>
                )}
              </ListItemContent>
            </ListItem>
          </List>
        </Content>
      </ModalLayout>
    </>
  );
};

DetailsModal.defaultProps = {
  isOpen: false
};

const ScoreType = PropTypes.shape({
  range: PropTypes.string.isRequired,
  rating: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    description: PropTypes.string,
    hexColor: PropTypes.string
  })
});

DetailsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  relationshipLevel: PropTypes.shape({
    rating: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number,
      description: PropTypes.string.isRequired,
      hexColor: PropTypes.string.isRequired
    })
  }).isRequired,
  longMessage: PropTypes.shape({
    firstPerson: PropTypes.string.isRequired,
    thirdPerson: PropTypes.string.isRequired
  }).isRequired,
  ranges: PropTypes.shape({
    numberOfSales: PropTypes.string,
    totalSales: PropTypes.string,
    totalPurchases: PropTypes.string,
    numberOfSuppliers: PropTypes.string
  }).isRequired,
  scores: PropTypes.shape({
    numberOfSales: ScoreType,
    totalSales: ScoreType,
    totalPurchases: ScoreType,
    numberOfSuppliers: ScoreType
  }).isRequired
};

export default DetailsModal;
