import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { actions, selectors } from 'store/ducks/paymentCollectionDetails';
import { railsContextSelector } from 'selectors/rails-context';
import Loader from 'components/Shared/Loader';
import { VerticalContainer } from 'components/PaymentCollectionDetails/Styles';
import ChargerScreen from '../ChargerScreen';
import ChargedScreen from '../ChargedScreen';
import ErrorScreen from '../ErrorScreen';

const RootScreen = ({ isCharger }) => {
  const dispatch = useDispatch();
  const { id: uuid } = useSelector(railsContextSelector);
  const isLoading = useSelector(selectors.isLoading);
  const error = useSelector(selectors.getError);
  const data = useSelector(selectors.getDetailsData);

  useEffect(() => {
    dispatch(actions.fetchPaymentCollectionDetailsRequest(uuid, isCharger));
  }, [dispatch, isCharger, uuid]);

  if (error) return <ErrorScreen {...error} />;

  if (isLoading || isEmpty(data)) {
    return (
      <VerticalContainer data-testid="loading">
        <Loader size="big" />
      </VerticalContainer>
    );
  }

  return isCharger ? <ChargerScreen /> : <ChargedScreen />;
};

RootScreen.propTypes = {
  isCharger: PropTypes.bool.isRequired
};

export default RootScreen;
