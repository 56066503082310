import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Progress } from '@useblu/ocean-react';
import { CheckCircleOutline, ChevronUp, X } from '@useblu/ocean-icons-react';
import { useMedia } from 'react-use';
import { sendScreenEvent } from '@useblu/blu-lytics';
import { getBatchPayments } from 'api/batch-payments';

import moment from 'moment';

import { normalizeCurrency } from 'normalizers';
import * as S from './TransactionAuthorizationDoneStyles';
import TransactionList from './TransactionList';

export default function TransactionAuthorizationDone({ uuid, clientUuid }) {
  const [showTransactions, setShowTransactions] = useState(true);
  const [loading, setLoading] = useState(true);
  const [batchPayment, setBatchPayment] = useState({});

  const isMobile = useMedia('(max-width: 768px)');

  const handleShowTransactions = () =>
    setShowTransactions((oldState) => !oldState);

  const handleRequestBatchPayment = () => {
    getBatchPayments(uuid, clientUuid)
      .then((response) => {
        setBatchPayment(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleRedirectStatement = () => {
    window.location.href = `/lojas/${clientUuid}/extrato/pendentes`;
  };

  useEffect(() => {
    sendScreenEvent('batch_payment_success_view');
    handleRequestBatchPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Wrapper>
      {isMobile && (
        <S.TopBar onClick={handleRedirectStatement}>
          <X />
        </S.TopBar>
      )}
      <S.Container>
        <S.Head>
          <CheckCircleOutline />
          <Typography variant="heading3">O pagamento foi autorizado</Typography>
          <Typography variant="description">
            Para mais detalhes acompanhe o status pelo extrato.
          </Typography>
        </S.Head>
        <S.Content>
          {loading ? (
            <Progress />
          ) : (
            <>
              <S.TransactionList>
                {batchPayment?.client_transactions?.length > 1 && (
                  <S.TotalTransactions $active={showTransactions}>
                    <S.TotalTransactionContent>
                      <Typography variant="description">
                        Valor Total:{' '}
                        {normalizeCurrency(batchPayment?.total_value)}
                      </Typography>
                      <Typography variant="caption">
                        {batchPayment?.client_transactions?.length} transações
                      </Typography>
                    </S.TotalTransactionContent>
                    <S.TotalTransactionIcon
                      onClick={handleShowTransactions}
                      $active={showTransactions}
                    >
                      <ChevronUp />
                    </S.TotalTransactionIcon>
                  </S.TotalTransactions>
                )}
                <TransactionList
                  showTransactions={showTransactions}
                  batchPayment={batchPayment}
                />
              </S.TransactionList>
              <S.ButtonContainer>
                <Button onClick={handleRedirectStatement}>
                  Ir para o extrato
                </Button>
              </S.ButtonContainer>
              <Typography variant="caption">
                Operação realizada em{' '}
                {moment(batchPayment?.created_at).format(
                  'DD/MM/YYYY, [às] HH:mm'
                )}
              </Typography>
            </>
          )}
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
}

TransactionAuthorizationDone.propTypes = {
  uuid: PropTypes.string.isRequired,
  clientUuid: PropTypes.string.isRequired,
};
