import { useState, useEffect } from 'react';

export default function useAdvancementSimulationDetailsDrawer() {
  const OPENEVENTTRIGGER = 'feature-drawer-anticipation-simulation-open';
  const CLOSEEVENTTRIGGER = 'feature-drawer-anticipation-simulation-close';

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [data, setData] = useState({});

  function openDrawer(data) {
    const event = new CustomEvent(OPENEVENTTRIGGER, {
      detail: { data },
    });
    document.dispatchEvent(event);
  }

  function closeDrawer(subject) {
    const event = new CustomEvent(CLOSEEVENTTRIGGER, {
      detail: { feature: subject },
    });
    document.dispatchEvent(event);
  }

  function closeAction() {
    setIsDrawerOpen(false);
    setData({});
  }

  function openAction(e) {
    setIsDrawerOpen(true);
    setData(e.detail.data);
  }

  // add listeners on mount
  useEffect(() => {
    document.addEventListener(OPENEVENTTRIGGER, openAction);
    document.addEventListener(CLOSEEVENTTRIGGER, closeAction);
  }, []);

  // remove listeners on unmount
  useEffect(
    () => () => {
      document.removeEventListener(OPENEVENTTRIGGER, openAction);
      document.removeEventListener(CLOSEEVENTTRIGGER, closeAction);
    },
    []
  );

  return {
    isDrawerOpen,
    data,
    openDrawer,
    closeDrawer,
  };
}
