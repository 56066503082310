import styled from 'styled-components';

import {
  spacingInlineXs,
  colorBrandPrimaryUp,
  colorBrandPrimaryDown,
  colorInterfaceLightPure,
  colorInterfaceLightDown,
  colorBrandPrimaryPure
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollContainer = styled.div`
  width: ${({ width }) => `${width}%`};
  display: flex;

  ${({ transparent }) =>
    transparent &&
    `
    width: 200%;
    padding-right: 33.3%;
    margin-left: 33.3%;
  `}
`;

export const Item = styled.div`
  flex: 1;

  ${({ transparent }) =>
    transparent &&
    `
    margin: ${spacingInlineXs};
    padding: ${spacingInlineXs};
    background: ${colorInterfaceLightPure};
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  ${({ active, transparent }) =>
    active &&
    transparent &&
    `
    transition: all .2s ease-in;
    transform: scale(1.1);
    opacity: 1;
    z-index: 2;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  margin: 5px;
  padding: 10px;
  background: none;
`;

export const Circle = styled.div`
  max-width: 8px;
  max-height: 8px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${({ active }) =>
    active ? colorBrandPrimaryDown : colorBrandPrimaryUp};
`;

export const LeftNavigation = styled.button`
  width: 50px;
  height: 50px;
  position: absolute;
  left: -25px;
  color: ${colorBrandPrimaryPure};
`;

export const RightNavigation = styled.button`
  width: 50px;
  height: 50px;
  position: absolute;
  right: -25px;
  background-color: ${colorInterfaceLightDown};
  color: ${colorBrandPrimaryPure};
`;
