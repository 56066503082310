import React from 'react';
import { useAsyncFn } from 'react-use';
import PropTypes from 'prop-types';

import Snackbar, { useSnackbar } from 'components/Shared/Snackbar';
import DeviseScreenLayout from './DeviseScreenLayout';
import { requestNewPassword } from '../services/password';

import * as S from '../devise.styles';

const TokenExpired = ({ email }) => {
  const [requestState, requestNew] = useAsyncFn(requestNewPassword);
  const {
    isActive: snackIsActive,
    message: snackMessage,
    type: snackType,
    openSnackBar
  } = useSnackbar();

  React.useEffect(() => {
    if (requestState.loading) return;

    if (requestState?.value?.data?.message) {
      openSnackBar('Código reenviado com sucesso.', 'success');
    }

    if (requestState?.error) {
      openSnackBar('Falha no envio, tente novamente.', 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestState]);

  return (
    <DeviseScreenLayout>
      <Snackbar
        isActive={snackIsActive}
        message={snackMessage}
        type={snackType}
      />
      <S.Title>Link expirado</S.Title>
      <S.Subtitle>
        Seu pedido de alteração de senha é muito antigo e passou do tempo de
        validade. Por favor, reenvie o e-mail de redefinição de senha.
      </S.Subtitle>

      <S.WrapBtns>
        <S.BtnSubmit
          onClick={() => requestNew({ email })}
          variant="primary"
          tabIndex={-1}
        >
          Reenviar e-mail
        </S.BtnSubmit>
      </S.WrapBtns>
    </DeviseScreenLayout>
  );
};

TokenExpired.propTypes = {
  email: PropTypes.string.isRequired
};

export default TokenExpired;
