const initialState = {
  items: [],
  loading: false,
  selected: null,
  cpf_cnpj: null,
  showSearch: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CLIENTS_LOAD':
      return {
        ...state,
        loading: true
      };
    case 'CLIENTS_LOADED':
      return {
        ...state,
        items: action.items,
        loading: false,
        selected: null,
        showSearch: true
      };
    case 'SET_CPF_CNPJ':
      return {
        ...state,
        cpf_cnpj: action.cpf_cnpj
      };
    case 'SELECT_CLIENT':
      return {
        ...state,
        selected: action.selected
      };
    case 'SAVE_CLIENT':
      return {
        ...state,
        selected: action.client
      };
    case 'CLIENT_FIND':
      return {
        ...state,
        cpf_cnpj: action.cpf_cnpj
      };
    case 'CLIENT_FOUND':
      return {
        ...state,
        selected: action.selected
      };
    case 'CLEAR_CLIENTS':
      return {
        ...state,
        items: [],
        showSearch: false
      };
    case 'CLEAR_SELECTED_CLIENT':
      return {
        ...state,
        selected: null,
        cpf_cnpj: null
      };
    case 'CLIENT_BANK_ACCOUNT_DISABLED':
      return {
        ...state,
        flashMessage: action.message,
        items: state.items.filter(
          (item) =>
            (item.name && item.name !== action.clientName) ||
            (item.bank_account_owner_name &&
              item.bank_account_owner_name !== action.clientName)
        )
      };
    default:
      return state;
  }
}
