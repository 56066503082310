import styled from 'styled-components';
import {
  colorStatusWarningDeep,
  colorStatusWarningDown,
} from '@useblu/ocean-tokens/web/tokens';
import { Button as OceanButton, Link as OceanLink } from '@useblu/ocean-react';

export const Button = styled(OceanButton)`
  display: block;

  &.ods-btn {
    color: #fff;
    background-color: '0025E0';
  }

  &.ods-btn:hover {
    background-color: '5872F5';
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Link = styled(OceanLink)`
  display: none;

  &.ods-lnk {
    color: ${colorStatusWarningDeep};
    font-weight: bold;
  }

  &.ods-lnk:hover {
    color: ${colorStatusWarningDown};
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const AlertWrapper = styled.div`
  .ods-alert__content {
    width: 100%;
  }

  p {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
  }
`;
