import React from 'react';

import { Button, Typography } from '@useblu/ocean-react';
import { ChevronRight } from '@useblu/ocean-icons-react';

import { useMedia } from 'react-use';
import ilustraAtivarbToken from './images/ilustra-ativar-btoken.svg';
import ilustraAtivarbTokenMobile from './images/Ilustra-ativar-btoken-mobile.svg';
import { ModalContent, ModalActions, List } from './style';

const BTokenForceActivation = () => {
  const isMobile = useMedia('(max-width: 768px)');

  const redirect = () => {
    window.location.href = '/btoken';
  };

  return (
    <>
      <ModalContent>
        <div className="img-container">
          <img
            src={isMobile ? ilustraAtivarbTokenMobile : ilustraAtivarbToken}
            alt="not active"
          />
        </div>
        <div className="info-container">
          <Typography variant="heading3">
            Ative o bToken, e deixe sua conta ainda mais segura
          </Typography>
          <List>
            <li>
              <span>
                <ChevronRight />
              </span>
              <p>
                <b>Mais segurança para o seu negócio:</b> garanta que apenas o
                celular cadastrado aprove transações
              </p>
            </li>
            <li>
              <span>
                <ChevronRight />
              </span>
              <p>
                <b>Mais velocidade para você:</b> aprove, na hora, suas
                transações feitas pelo app e computador
              </p>
            </li>
          </List>
        </div>
      </ModalContent>
      <ModalActions>
        <Button onClick={redirect}>Ativar bToken</Button>
      </ModalActions>
    </>
  );
};

export default BTokenForceActivation;
