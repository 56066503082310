import React from 'react';

import instagram from 'images/emails/instagram.png';
import facebook from 'images/emails/facebook.png';
import linkedin from 'images/emails/linkedin.png';
import youtube from 'images/emails/youtube.png';
import Footer from 'components/AutomaticAnticipationLandingPage/Footer';
import {
  DesktopFooterContainer,
  MobileFooterContainer,
  FooterInfo
} from './styles';
import logoBlu from './images/logoBlu.svg';

const SellMoreFooter = () => (
  <div>
    <DesktopFooterContainer>
      <div>
        <img src={logoBlu} alt="blu" height="40px" width="100px" />
      </div>
      <FooterInfo>
        <p>Canal direto do representante</p>
        <span>0800 020 2853</span>
        <span>repblu@useblu.com.br</span>
      </FooterInfo>
      <FooterInfo>
        <p>Onde estamos</p>
        <span>Av. Olegário Maciel, 30a</span>
        <span>Barra da Tijuca, Rio de Janeiro - RJ</span>
      </FooterInfo>
      <div>
        <img src={facebook} alt="facebook" />
        <img src={instagram} alt="instagram" />
        <img src={linkedin} alt="linkedin" />
        <img src={youtube} alt="youtube" />
      </div>
    </DesktopFooterContainer>
    <MobileFooterContainer>
      <Footer />
    </MobileFooterContainer>
  </div>
);

export default SellMoreFooter;
