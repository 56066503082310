import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Typography } from '@useblu/ocean-react';
import * as S from './styles';
import Counter from './Counter';
import illustraLiveness from '../images/ilustra-liveness.svg';
import illustraUnauthorized from '../images/ilustra-unauthorized.svg';

const LivenessValidationModal = ({
  isOpen,
  uuid,
  resendNotification,
  resendError,
  unauthorized = false,
}) => {
  const onClose = () => {
    window.location.assign(`/lojas/${uuid}/extrato/pendentes`);
  };

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      id="modal"
      maxWidth="sm"
      onRequestClose={onClose}
    >
      <S.LivenessModalContainer>
        <S.LivenessImage>
          <img
            src={unauthorized ? illustraUnauthorized : illustraLiveness}
            alt="Liveness"
          />
        </S.LivenessImage>
        <S.LivenessContent>
          <Typography variant="heading3">
            {unauthorized ? (
              <>
                Você não tem permissão para <br /> autorizar esta operação
              </>
            ) : (
              'Toque na notificação para autorizar'
            )}
          </Typography>
          <Typography variant="description">
            {unauthorized ? (
              <>
                Fique tranquilo, uma notificação foi enviada à pessoa com
                <br /> permissão para concluir a operação.
              </>
            ) : (
              'A notificação foi enviada para o celular com bToken ativo. Revise as informações antes de autorizar'
            )}
          </Typography>
        </S.LivenessContent>
        <S.LivenessActions>
          {unauthorized ? (
            <Button variant="primary" onClick={onClose}>
              Entendi
            </Button>
          ) : (
            <Counter
              resendNotification={resendNotification}
              resendError={resendError}
            />
          )}
          {resendError ? <p>{resendError}</p> : null}
        </S.LivenessActions>
      </S.LivenessModalContainer>
    </Modal>
  );
};

LivenessValidationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
  resendNotification: PropTypes.func.isRequired,
  resendError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  unauthorized: PropTypes.bool,
};

LivenessValidationModal.defaultProps = {
  resendError: null,
  unauthorized: false,
};

export default LivenessValidationModal;
