import React from 'react';
import styled from 'styled-components';

import { fontWeightRegular } from '@useblu/ocean-tokens/web/tokens';

import cieloSVG from './images/cielo.svg';
import redeSVG from './images/rede.svg';
import getnetSVG from './images/getnet.svg';
import safrapaySVG from './images/safrapay.svg';
import stoneSVG from './images/stone.svg';
import allSVG from './images/all.svg';

export const Cielo = () => <img src={cieloSVG} alt="cielo" />;

export const Getnet = () => <img src={getnetSVG} alt="getnet" />;

export const Rede = () => <img src={redeSVG} alt="rede" />;

export const Safra = () => <img src={safrapaySVG} alt="safra" />;

export const Stone = () => <img src={stoneSVG} alt="stone" />;

const Span = styled.span`
  text-transform: capitalize;
  font-weight: ${fontWeightRegular};
`;

export const getAcquirerImage = (name) => {
  switch (name) {
    case 'cielo':
      return Cielo;
    case 'getnet':
      return Getnet;
    case 'rede':
      return Rede;
    case 'safra':
      return Safra;
    case 'stone':
      return Stone;
    default:
      return () => <Span>{name}</Span>;
  }
};

export const AcquirerSlider = styled.div`
  @keyframes animated {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -1000px 0;
    }
  }

  height: 20px;
  width: 100%;
  margin: 20px 0px 12px 0px;
  overflow: hidden;
  background-repeat: repeat-x;
  background-image: url('${allSVG}');
  background-size: cover;
  animation: animated 24s linear infinite;
`;
