import React, { Component } from 'react';
import { Field } from 'redux-form';
import RenderDropdownList from 'components/Form/RenderDropdownList';

class PosSplitOptions extends Component {
  posOperatorOptions() {
    return [
      { key: 'definir_na_logistica', label: 'A definir na Logística' },
      { key: 'Vivo', label: 'Vivo' },
      { key: 'Claro', label: 'Claro' },
      { key: 'TIM', label: 'TIM' },
      { key: 'Oi', label: 'Oi' }
    ];
  }

  posQuantityOptions() {
    return [
      { key: 1, label: '1 POS' },
      { key: 2, label: '2 POS' },
      { key: 3, label: '3 POS' },
      { key: 4, label: '4 POS' },
      { key: 5, label: '5 POS' },
      { key: 6, label: '6 POS' },
      { key: 7, label: '7 POS' }
    ];
  }

  posPreferencesEquipmentOptions() {
    return [
      { key: '_nenhum_', label: '(nenhum)' },
      {
        key: 'com_bobina_-_wi_fi_necessário',
        label: 'Com bobina - wi fi necessário'
      },
      {
        key: 'com_bobina_-_wi_fi_não_necessário',
        label: 'Com bobina - wi fi não necessário'
      },
      { key: 'sem_bobina', label: 'Sem bobina' },
      { key: 'pinpad', label: 'pinpad' },
      {
        key: 'tef__sem_envio_de_equipamento_',
        label: 'TEF sem envio de equipamento'
      }
    ];
  }

  render() {
    return (
      <div>
        <div className="form-row">
          <div className="form-controls">
            <Field
              name="client[pos_preferences_split_quantity]"
              component={RenderDropdownList}
              options={this.posQuantityOptions()}
              simpleValue
              getOptionLabel={({ label }) => label}
              getOptionValue={({ key }) => key}
              label="Quantidade de POS"
              placeholder="Escolha uma opção"
            />
          </div>
          <div className="form-controls">
            <Field
              name="client[pos_preferences_split_equipment]"
              component={RenderDropdownList}
              options={this.posPreferencesEquipmentOptions()}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ key }) => key}
              simpleValue
              label="Equipamento"
              placeholder="Escolha uma opção"
            />
          </div>
          <div className="form-controls">
            <Field
              name="client[pos_preferences_split_cell_operator]"
              component={RenderDropdownList}
              options={this.posOperatorOptions()}
              simpleValue
              getOptionLabel={({ label }) => label}
              getOptionValue={({ key }) => key}
              label="Operadora de POS"
              placeholder="Escolha uma opção"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PosSplitOptions;
