import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Shortcut, Typography } from '@useblu/ocean-react';
import { BellOutline, LockClosedOutline } from '@useblu/ocean-icons-react';
import * as S from './BTokenModalContent.styles';

const BTokenOptions = ({ setModalStep, resendNotificationBToken }) => {
  const resendNotification = () => {
    resendNotificationBToken();
    setModalStep(0);
  };

  return (
    <S.NotificationModalContainer>
      <Typography variant="heading3">Como gostaria de autorizar?</Typography>
      <S.OptionsContainer>
        <Shortcut
          icon={<BellOutline />}
          size="small"
          label="Enviar notificação"
          orientation="vertical"
          onClick={resendNotification}
        />
        <Shortcut
          icon={<LockClosedOutline />}
          size="small"
          label="Digitar código"
          orientation="vertical"
          onClick={() => setModalStep(2)}
        />
      </S.OptionsContainer>
      <S.NotificationActions>
        <Typography variant="caption">
          Usar o seu celular para autorizar as ações feitas no computador é uma
          medida de segurança para proteger sua conta.
        </Typography>
        <Link
          icon="externalLink"
          size="md"
          href="https://comunidade.blu.com.br/post/btoken-blu"
          target="_blank"
        >
          Saiba mais sobre o bToken
        </Link>
      </S.NotificationActions>
    </S.NotificationModalContainer>
  );
};

BTokenOptions.propTypes = {
  setModalStep: PropTypes.func.isRequired,
  resendNotificationBToken: PropTypes.func,
};

BTokenOptions.defaultProps = {
  resendNotificationBToken: null,
};

export default BTokenOptions;
