import React, { useRef } from 'react';
import Loading from 'react-loading';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DragContainer = ({ name, children, onSuccess, loading, className }) => {
  const refUpload = useRef();

  const onChangeFile = (e) => {
    if (onSuccess) onSuccess(e);
    e.target.value = '';
  };

  return (
    <div ref={refUpload} className={classNames('transfer-upload', className)}>
      {loading && (
        <div className="transfer-upload-loading">
          <Loading type="spinningBubbles" color="#343e4f" delay={0} />
        </div>
      )}
      <input
        type="file"
        name={name}
        className="transfer-upload-input"
        data-testid="file-upload"
        onChange={onChangeFile}
      />
      {children}
    </div>
  );
};

DragContainer.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  onSuccess: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string
};

DragContainer.defaultProps = {
  children: null,
  onSuccess: null,
  loading: false,
  className: ''
};

export default DragContainer;
