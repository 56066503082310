import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@useblu/ocean-react';
import { ExternalLink } from '@useblu/ocean-icons-react';

import { railsContextSelector } from 'selectors/rails-context';

import Ilustra from './images/ilustra.svg';

import * as S from './styles';

export default function ModalAboutBtoken() {
  const { clientId } = useSelector(railsContextSelector);

  return (
    <>
      <S.Container>
        <img src={Ilustra} alt="Ilustração" />
        <div>
          <S.Title>Ative o bToken para continuar</S.Title>
          <S.Description>
            O bToken serve para autenticar as suas aprovações com mais segurança
            e rapidez. <strong>É fácil e você só ativa uma vez!</strong>
          </S.Description>
        </div>
      </S.Container>
      <S.Footer>
        <a
          href="https://www.comunidade.blu.com.br/post/btoken-blu"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="text">
            Saiba mais <ExternalLink />
          </Button>
        </a>
        <a
          href={`/lojas/${clientId}/pagamento-a-fornecedor/autenticacao/sobre`}
        >
          <Button>Ativar bToken</Button>
        </a>
      </S.Footer>
    </>
  );
}
