import styled from 'styled-components';

import {
  colorInterfaceDarkDown,
  colorInterfaceDarkPure,
  colorStatusPositivePure,
  fontWeightExtrabold,
  fontWeightBold,
  fontWeightRegular,
  colorInterfaceLightUp,
  colorInterfaceLightDown,
  colorInterfaceLightPure,
  colorInterfaceDarkDeep,
  colorInterfaceDarkUp,
  colorBrandPrimaryDown,
  fontSizeXs,
  fontSizeXxs,
  spacingInlineXs
} from '@useblu/ocean-tokens/web/tokens';

import ContainerMixin from '../shared/ContainerMixin';

export const BalanceContainer = styled.div`
  ${ContainerMixin}
  width: 364px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 100%;
    button {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
`;

export const PreSaleContent = styled.div`
  padding: ${spacingInlineXs};
  background: ${colorInterfaceLightUp};
  h1 {
    color: ${colorBrandPrimaryDown};
    font-size: ${fontSizeXs};
    font-weight: ${fontWeightBold};
  }
  p {
    color: ${colorInterfaceDarkDeep};
    font-size: ${fontSizeXxs};
  }
`;

export const Total = styled.div`
  padding: 24px 0px;

  &.isNegative {
    h1 {
      color: ${colorInterfaceDarkPure};
      font-weight: 700;
    }
  }

  img {
    margin: 0px 16px;
    float: left;
  }
  h1 {
    font-size: 20px;
    line-height: 22px;
    margin: 4px 0px 0px 0px;
    font-weight: ${fontWeightExtrabold};
    color: ${colorStatusPositivePure};
  }
  p {
    font-size: 14px;
    line-height: 21px;
    margin: 0px;
    color: ${colorInterfaceDarkDown};
  }
`;

export const Value = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${colorInterfaceDarkPure};
  font-weight: ${fontWeightBold};
`;

export const ValueOff = styled(Value)`
  color: ${colorInterfaceDarkUp};
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  border-top: 1px solid ${colorInterfaceLightDown};
`;

export const SubItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  background: ${colorInterfaceLightPure};
`;

export const ExternalWalletList = styled.div`
  border-top: 1px dashed ${colorInterfaceLightDown};
`;

export const SubItemValue = styled.span`
  font-size: 14px;
  color: ${colorInterfaceDarkDown};
  font-weight: ${fontWeightRegular};
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colorInterfaceDarkDeep};
`;

export const PendingItem = styled.div`
  padding: 20px 16px;
  background-color: ${colorInterfaceLightUp};
  h1 {
    color: ${colorStatusPositivePure};
    font-size: 15px;
    font-weight: ${fontWeightBold};
  }
  p {
    color: ${colorInterfaceDarkDown};
    font-size: 13px;
    margin-bottom: 0;
  }
  i {
    font-size: 19px;
    margin-right: 8px;
  }
`;
