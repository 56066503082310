import styled from 'styled-components';
import {
  spacingInsetSm,
  spacingInlineXs,
  lineHeightMedium,
  fontSizeXxxs,
  fontSizeXxs,
  fontSizeXs,
  colorInterfaceDarkDeep,
  fontWeightMedium,
  fontWeightBold,
  colorInterfaceLightDown,
} from '@useblu/ocean-tokens/web/tokens';

export const Icon = styled.img`
  display: none;
  margin-right: ${spacingInlineXs};
  @media (min-width: 768px) {
    display: block;
  }
`;

export const ListItem = styled.li`
  display: flex;
  padding: ${spacingInsetSm};
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const InfoType = styled.div`
  > em {
    font-style: normal;
    font-weight: ${fontWeightMedium};
    font-size: ${fontSizeXs};
    line-height: ${lineHeightMedium};
    color: ${colorInterfaceDarkDeep};
  }

  > p {
    font-size: ${fontSizeXxs};
    line-height: ${lineHeightMedium};
    color: #67697a;
    margin-bottom: 0;

    @media (min-width: 768px) {
      &.mobile {
        display: none;
      }
      &.desktop {
        display: block;
      }
    }

    @media (max-width: 767px) {
      &.mobile {
        display: block;
      }
      &.desktop {
        display: none;
      }
    }
  }
`;

export const InfoValue = styled.div`
  text-align: right;
  > em {
    font-style: normal;
    font-weight: ${fontWeightBold};
    font-size: ${fontSizeXs};
    line-height: ${lineHeightMedium};
    color: ${colorInterfaceDarkDeep};
  }

  > p {
    font-size: ${fontSizeXxxs};
    line-height: ${lineHeightMedium};
    color: #67697a;
    margin-bottom: 0;
  }
`;

export const Separator = styled.hr`
  height: 1px;
  background-color: ${colorInterfaceLightDown};
`;
