/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BTokenModalContent from 'components/Shared/BTokenModalContent';
import { sendEmailAutomaticAnticipation } from 'api/transaction-authorization';
import BTokenNotification from 'components/Shared/BTokenModalContent/BTokenNotification';
import BTokenOptions from 'components/Shared/BTokenModalContent/BTokenOptions';
import {
  sendValidationApi,
  validationCodeApi,
  resendNotificationApi,
  validationCodeStatusApi,
} from '../services/automaticAnticipationService';

const BTokenValidationAutomaticAnticipation = ({
  clientUUID,
  allCnpjs,
  updateStatus,
}) => {
  const [token, setToken] = useState('');
  const [modalStep, setModalStep] = useState(0);

  useEffect(() => {
    sendValidationApi(clientUUID, 'b_token', allCnpjs).then((response) => {
      const { token } = response.data;
      setToken(token);
    });
  }, []);

  useEffect(() => {
    let interval;

    if (token) {
      const interval = setInterval(() => {
        validationCodeStatusApi(clientUUID, token).then((response) => {
          const { status } = response.data;

          if (status === 'completed') {
            updateStatus();
            clearInterval(interval);
          }
        });
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [token]);

  const sendEmailValidation = () => {
    return sendEmailAutomaticAnticipation(clientUUID, allCnpjs);
  };

  const resendNotification = () => {
    resendNotificationApi(token);
  };

  const validateToken = (code) => validationCodeApi(token, code);

  const updateStatusAnticipation = (data) => {
    const { status } = data;

    if (status === 'completed') {
      updateStatus();
    }
  };

  const renderModalStep = () => {
    switch (modalStep) {
      case 0:
        return <BTokenNotification setModalStep={setModalStep} />;
      case 1:
        return (
          <BTokenOptions
            setModalStep={setModalStep}
            resendNotificationBToken={resendNotification}
          />
        );
      case 2:
        return (
          <BTokenModalContent
            info={{
              title:
                'Digite o código bToken ou toque na notificação em seu celular',
              subtitle: 'Para descobrir código abra o aplicativo e toque em',
              serviceReceiver: '"bToken"',
            }}
            mobileFullScreen
            setModalStep={setModalStep}
            sendEmailApi={sendEmailValidation}
            tokenValidationApi={validateToken}
            postSendApi={updateStatusAnticipation}
          />
        );
      default:
        return null;
    }
  };

  return renderModalStep();
};

BTokenValidationAutomaticAnticipation.propTypes = {
  clientUUID: PropTypes.string.isRequired,
  allCnpjs: PropTypes.bool.isRequired,
  updateStatus: PropTypes.func.isRequired,
};

BTokenValidationAutomaticAnticipation.defaultProps = {};

export default BTokenValidationAutomaticAnticipation;
