import React from 'react';
import PropTypes from 'prop-types';

import ScoreCardLayout from '../ScoreCardLayout';
import {
  Wrapper,
  Summary,
  SummaryDetailsDescription,
  ActionButton
} from './ScoreCardInitialStyles';

const ScoreCardInitialError = ({ newLayout, retryFn }) => (
  <ScoreCardLayout newLayout={newLayout}>
    <Wrapper>
      <Summary>
        <div>
          <SummaryDetailsDescription>
            Não foi possível carregar esta informação.
          </SummaryDetailsDescription>
        </div>
        <ActionButton type="button" onClick={retryFn}>
          Recarregar
        </ActionButton>
      </Summary>
    </Wrapper>
  </ScoreCardLayout>
);

ScoreCardInitialError.defaultProps = {
  newLayout: 'light'
};

ScoreCardInitialError.propTypes = {
  newLayout: PropTypes.string,
  retryFn: PropTypes.func.isRequired
};

export default ScoreCardInitialError;
