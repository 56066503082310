import axios from 'axios';
import humps from 'lodash-humps';

import { getCSRFToken } from 'utils/csrf-token';

const handleRequest = async (
  axiosConfig,
  authBearer = false,
  howFullError = false
) => {
  try {
    const { data } = await axios({
      headers: {
        Accept: 'version=1',
        Authorization: `${authBearer ? 'Bearer' : 'token'} ${getCSRFToken()}`,
      },
      ...axiosConfig,
    });

    return Promise.resolve(humps(data));
  } catch (error) {
    if (howFullError) return Promise.reject(error);

    return Promise.reject(
      new Error(error.response?.data.message || 'Ocorreu um erro inesperado.')
    );
  }
};

export const getApprovalsList = (clientUuid, defaultParams) =>
  handleRequest({
    method: 'get',
    url: defaultParams
      ? `/api/approvals?client_uuid=${clientUuid}&default_params=true`
      : `/api/approvals?client_uuid=${clientUuid}`,
  });

export const getApprovalsDetails = (
  clientUuid,
  category,
  page = 1,
  status = '',
  search = ''
) =>
  handleRequest({
    method: 'get',
    url: `/api/approvals/${category}?client_uuid=${clientUuid}${
      status && `&filter[status]=${status}`
    }${search && `&filter[charger_name]=${search}`}&page=${page}`,
  });

export const checkClientOptinBankBillet = (clientUuid) =>
  handleRequest({
    method: 'get',
    url: `/api/client_optins/check?tag=feature_cob_bank_billet_charged&client_uuid=${clientUuid}`,
  });

export const toggleClientOptinBankBillet = (clientUuid, allClients = false) =>
  handleRequest(
    {
      method: 'get',
      url: `/api/client_optins/toggle?tag=feature_cob_bank_billet_charged&client_uuid=${clientUuid}&all_clients=${allClients}`,
    },
    false,
    true
  );

export const getBankStatement = (clientUuid, page = 1, activeParams = '') =>
  handleRequest(
    {
      method: 'get',
      url: `/api/extract/?client_uuid=${clientUuid}&page=${page}&${activeParams}`,
    },
    true
  );

export const getBankStatementPending = (
  clientUuid,
  page = 1,
  activeParams = ''
) =>
  handleRequest(
    {
      method: 'get',
      url: `/api/extract/pending?client_uuid=${clientUuid}&page=${page}&${activeParams}`,
    },
    true
  );

export const getBankStatementScheduled = (
  clientUuid,
  page = 1,
  activeParams = ''
) =>
  handleRequest(
    {
      method: 'get',
      url: `/api/extract/scheduled?client_uuid=${clientUuid}&page=${page}&${activeParams}`,
    },
    true
  );

export const getFilterOptions = (clientUuid) =>
  handleRequest(
    {
      method: 'get',
      url: `/api/extract/filter_options?client_uuid=${clientUuid}`,
    },
    true
  );

export const cancelBankBillet = (clientUuid) =>
  handleRequest(
    {
      method: 'POST',
      url: `/api/payment_collections/${clientUuid}/cancel`,
    },
    true
  );

export const rejectBankBillet = (paymentCollectionUuid, reason) =>
  handleRequest(
    {
      method: 'POST',
      url: `/api/payment_collections/${paymentCollectionUuid}/reject?cancellation_reason=${reason}`,
    },
    true
  );
