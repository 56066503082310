import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({ title, value, children }) => (
  <div className="list__item list__item-sp-between statement-detail__installment">
    <div className="list__item-left">
      <p>
        <b>{title}</b>
      </p>
      {value && <p>{value}</p>}
      {children}
    </div>
  </div>
);

ListItem.defaultProps = {
  value: '',
  children: null
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.node
};

export default ListItem;
