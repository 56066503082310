import React from 'react';
import PropTypes from 'prop-types';
import { DocumentDownload } from '@useblu/ocean-icons-react';
import { colorStatusNegativePure } from '@useblu/ocean-tokens/web/tokens';

import moment from 'utils/moment.pt-br';
import { Actions, LinkStyled, StatusColumn } from './TableListItem.styles';
import StatusParse from './StatusParse';

const TableListItem = ({
  createdAt,
  status,
  errorMessage,
  originalFile,
  logErrors
}) => (
  <tr>
    <td className="table-list__body-item" style={{ textAlign: 'center' }}>
      {moment(createdAt).format('DD/MM/YYYY HH:mm')}
    </td>
    <StatusColumn
      className="table-list__body-item"
      isFinished={status === 'finished'}
    >
      <StatusParse status={status} errorMessage={errorMessage} />
    </StatusColumn>
    <td className="table-list__body-item">
      <Actions>
        <LinkStyled
          href={originalFile.url}
          target="_blank"
          rel="noreferrer"
          download={originalFile.name}
        >
          <DocumentDownload size={16} />
          Original
        </LinkStyled>
        {logErrors && (
          <LinkStyled
            color={colorStatusNegativePure}
            href={logErrors.url}
            target="_blank"
            rel="noreferrer"
            download={logErrors.name}
            style={{ display: 'none' }}
          >
            <DocumentDownload size={16} />
            Erros
          </LinkStyled>
        )}
      </Actions>
    </td>
  </tr>
);

const filePropTypes = PropTypes.shape({
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

TableListItem.defaultProps = {
  logErrors: null,
  errorMessage: ''
};

TableListItem.propTypes = {
  createdAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  originalFile: filePropTypes.isRequired,
  logErrors: filePropTypes
};

export default React.memo(TableListItem);
