import React from 'react';
import { useFormikContext } from 'formik';

import { WrapCustomSelect, CustomSelect } from '../styles';

const SelectControl = ({
  name,
  options = [],
  label,
  valueSelect,
  setValue,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const customStyles = {
    control: () => ({
      border: '2px solid #d8dae8',
      borderRadius: '4px',
      display: 'flex',
      height: '48px'
    })
  };

  const handleChange = (selectedOption) => {
    setValue(selectedOption.value);
    setFieldValue(name, selectedOption.value);
  };

  return (
    <WrapCustomSelect>
      {label && <label>{label}</label>}
      <CustomSelect
        name={name}
        style={customStyles}
        onChange={handleChange}
        options={options}
        value={options.filter(({ value }) => value === valueSelect)}
        {...props}
      />
    </WrapCustomSelect>
  );
};

export default SelectControl;
