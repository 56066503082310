import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import TableWeb from './components/TableWeb';
import TableMobile from './components/TableMobile';

import {
  tableLAG,
  tableMattress,
  tableClothes,
  tableFurniture,
  tableGlasses,
  tableEBA,
  tableDefault
} from './static/staticTaxes';

import useTaxesByCnae from './hooks/useTaxesByCnae';

import { TaxesTableContainer } from './styles';

const TaxesTable = ({ hideHeader }) => {
  const {
    currentClientDepartmentId,
    currentClientTaxPlan,
    ebaIds,
    lagIds,
    mattressIds,
    clothersIds,
    furnitureIds,
    glassesIds
  } = useSelector((state) => ({
    ...state.railsContext
  }));

  const isMobile = useMedia('(max-width: 1060px)');
  const { handleCheckIfHasTaxesByCnae, getCnaeTaxes } = useTaxesByCnae();

  const getStaticTaxes = () => {
    const lag = lagIds?.includes(currentClientDepartmentId);
    const mattress = mattressIds?.includes(currentClientDepartmentId);
    const clothes = clothersIds?.includes(currentClientDepartmentId);
    const furniture = furnitureIds?.includes(currentClientDepartmentId);
    const glasses = glassesIds?.includes(currentClientDepartmentId);
    const eba = ebaIds?.includes(currentClientDepartmentId);

    if (lag) return tableLAG;
    if (mattress) return tableMattress;
    if (clothes) return tableClothes;
    if (furniture) return tableFurniture;
    if (glasses) return tableGlasses;
    if (eba) return tableEBA(currentClientTaxPlan);

    return tableDefault;
  };

  const priceList = handleCheckIfHasTaxesByCnae()
    ? getCnaeTaxes()
    : getStaticTaxes();

  return (
    <TaxesTableContainer hideHeader id="taxes-table" data-testid="taxes-table">
      <h1 hidden={hideHeader}>Confira as taxas para cada bandeira</h1>
      {!isMobile ? (
        <TableMobile hideHeader={hideHeader} taxes={priceList} />
      ) : (
        <TableWeb hideHeader={hideHeader} taxes={priceList} />
      )}
    </TaxesTableContainer>
  );
};

TaxesTable.propTypes = {
  hideHeader: PropTypes.bool
};

TaxesTable.defaultProps = {
  hideHeader: false
};

export default TaxesTable;
