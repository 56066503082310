import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal } from '@useblu/ocean-react';
import {
  Container,
  IconWrapper,
  Title,
  Paragraph,
  EmailText,
  ButtonWrapper
} from './styles';

import ConfirmationIcon from '../../images/icon-email-confirmation.png';

const EmailConfirmationModal = ({
  isVisible,
  toggleModal,
  email,
  handleSubmit,
  values,
  setFormData
}) => {
  const handleConfirm = () => {
    setFormData({ ...values });
    toggleModal(false);
    handleSubmit(values);
  };

  const handleCancel = () => {
    toggleModal(false);
  };

  return (
    <Modal
      isOpen={isVisible}
      ariaHideApp={false}
      portalClassName="ocean-iso"
      onRequestClose={handleConfirm}
      disableClose
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      maxWidth="sm"
    >
      <Container>
        <IconWrapper>
          <img src={ConfirmationIcon} alt="Modal icon" />
        </IconWrapper>

        <Title>Revise os dados informados</Title>
        <Paragraph subtitle>
          Você está alterando o seu e-mail para
          <EmailText>{email}</EmailText>
        </Paragraph>

        <Paragraph>
          Ao confirmar a alteração, esse será o seu novo e-mail de acesso à
          Conta Digital Blu.
        </Paragraph>
        <ButtonWrapper>
          <Button variant="secondary" size="lg" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            size="lg"
            className="atv_etapa_1_dadospessoais_botaonovoemail"
            onClick={handleConfirm}
          >
            Confirmar
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};
EmailConfirmationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired
};

export default EmailConfirmationModal;
