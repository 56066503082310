/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import classNames from 'classnames';
import maskInput, { maskComplete, maskValue } from 'utils/masks';

export default class RenderField extends Component {
  componentDidMount() {
    const { mask, autofocus } = this.props;

    if (mask) {
      maskInput(this.input, mask);
    }

    if (autofocus) {
      this.input.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { autofocus } = newProps;

    if (autofocus) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      mask,
      input: { value },
      onMaskComplete
    } = this.props;

    if (mask !== prevProps.mask) {
      maskInput(this.input, mask);
    }

    if (onMaskComplete && maskComplete(value, mask)) {
      onMaskComplete();
    }
  }

  maskValue() {
    const {
      mask,
      defaultValue,
      input: { value }
    } = this.props;

    if (!mask && defaultValue && defaultValue !== value) {
      return defaultValue;
    }

    if (mask && defaultValue) {
      return maskValue(defaultValue, mask);
    }

    if (mask && value) {
      return maskValue(value, mask);
    }

    return value;
  }

  render() {
    const {
      input,
      label,
      id,
      placeholder,
      type,
      meta: { touched, error },
      hint,
      maxLength,
      flex,
      children,
      extraClass,
      prefix,
      disabled,
      onInput,
      loading,
      inputClassName,
      dataTip,
      dataTestid,
      inputMode
    } = this.props;

    const style = {};

    if (flex) {
      style.flex = flex;
    }

    return (
      <div
        className={classNames({
          'form-controls': true,
          [type]: type,
          [extraClass]: extraClass,
          'is-disabled': disabled
        })}
        style={style}
      >
        {typeof label === 'string' && <label htmlFor={id}>{label}</label>}
        {typeof label === 'function' && label(id)}
        <div
          className={classNames({
            'field-with-errors': touched && error,
            'is-loading': loading
          })}
        >
          {prefix && <span className="form-prefix">{prefix}</span>}
          <input
            ref={(i) => {
              this.input = i;
            }}
            id={id}
            {...input}
            maxLength={maxLength}
            onInput={onInput}
            type={type}
            {...(dataTip && { 'data-tip': dataTip })}
            {...(dataTestid && { 'data-testid': dataTestid })}
            className={inputClassName}
            inputMode={inputMode}
            placeholder={placeholder}
            value={this.maskValue()}
            disabled={disabled}
          />
          {children}
          {hint && (
            <span className="hint">
              <small>{hint}</small>
            </span>
          )}
          {touched && error && <span className="field-error">{error}</span>}
        </div>
      </div>
    );
  }
}

RenderField.propTypes = {
  mask: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  extraClass: PropTypes.string,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  maxLength: PropTypes.number,
  onMaskComplete: PropTypes.func,
  hint: PropTypes.string,
  children: PropTypes.object,
  onInput: PropTypes.func,
  flex: PropTypes.number,
  loading: PropTypes.bool,
  inputClassName: PropTypes.string,
  dataTip: PropTypes.bool,
  dataTestid: PropTypes.string,
  inputMode: PropTypes.string
};

RenderField.defaultProps = {
  mask: '',
  input: {},
  meta: {},
  label: '',
  id: '',
  placeholder: '',
  defaultValue: '',
  type: '',
  extraClass: '',
  prefix: '',
  disabled: false,
  autofocus: false,
  onMaskComplete: null,
  hint: '',
  children: null,
  onInput: null,
  maxLength: null,
  flex: null,
  loading: false,
  inputClassName: '',
  dataTip: false,
  dataTestid: '',
  inputMode: ''
};
