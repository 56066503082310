import styled, { css } from 'styled-components';
import {
  colorInterfaceLightUp,
  colorStatusNegativePure,
  borderRadiusMd
} from '@useblu/ocean-tokens/web/tokens';

import DragContainer from 'components/Shared/DragContainer';

export const List = styled.ul`
  list-style-type: disc;
  padding-left: 15px;
  margin: 8px 0 25px;
`;

export const DragContainerStyled = styled(DragContainer)`
  &&& {
    border-width: 2px;

    ${(props) =>
      props.error &&
      css`
        border-color: ${colorStatusNegativePure};
      `}
  }
`;

export const FileContainer = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr auto;
  align-items: center;
  background: ${colorInterfaceLightUp};
  border-radius: ${borderRadiusMd};
  padding: 16px;
`;

export const IconButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  background: transparent;
`;
