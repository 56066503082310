import React from 'react';

import communityImage from './images/communityImage.svg';
import { CommunityContainer, ButtonWithoutHover } from './styles';

const Community = () => {
  const redirect = () => {
    window.open(
      'https://www.comunidade.blu.com.br/post/como-funciona-o-blu-venda',
      '_blank'
    );
  };
  return (
    <CommunityContainer>
      <div>
        <p>
          Quer saber como funciona o<br /> app Blu Venda +?
        </p>
        <ButtonWithoutHover type="button" size="lg" onClick={redirect}>
          Acesse nossa Comunidade
        </ButtonWithoutHover>
      </div>
      <div>
        <img src={communityImage} alt="nossa comunidade" />
      </div>
    </CommunityContainer>
  );
};

export default Community;
