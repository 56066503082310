import styled from 'styled-components';
import ReactPlayer from 'react-player';
import {
  fontFamilyBase,
  fontFamilyHighlight,
  colorBrandPrimaryDown,
  colorInterfaceLightPure,
  colorBrandPrimaryPure,
  fontSizeMd
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  padding: 32px 16px;
  background-color: ${colorBrandPrimaryPure};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1270px;
  width: 100%;
  justify-content: space-between;

  &.automatic-anticipation-header {
    padding: 32px 0;
  }

  div {
    width: 45%;
  }

  @media screen and (max-width: 1025px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      width: 100%;
      margin-bottom: 32px;
    }

    /* div:last-child {
      width: 60%;
      height: 250px;
    } */
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 900;
  font-family: ${fontFamilyHighlight};
  line-height: 35px;
  color: ${colorInterfaceLightPure};
  width: 70%;
  min-width: 425px;

  @media screen and (max-width: 768px) {
    width: 100%;
    min-width: 320px;
  }
`;

export const List = styled.ol`
  list-style: none;
  color: ${colorInterfaceLightPure};
`;

export const Item = styled.li`
  display: flex;
  flex-direction: row;

  p {
    margin-top: 16px;
    font-size: 16px;
    font-family: ${fontFamilyBase};
    font-weight: 400;
    line-height: 150%;
    color: ${colorInterfaceLightPure};
  }

  &:before {
    color: ${colorInterfaceLightPure};
    display: inline-block;
    margin-right: 32px;
    font-weight: 300;
    font-size: 32px;
    line-height: 150%;
  }
`;

export const CustomReactPlayer = styled(ReactPlayer)`
  && {
    height: 100% !important;
    border: 4px solid ${colorInterfaceLightPure};

    @media screen and (max-width: 1025px) {
      width: 100% !important;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const BannerContainer = styled.div`
  padding: 4rem 0px;
  background-color: ${colorBrandPrimaryDown};
`;

export const BannerContent = styled.div`
  @media screen and (min-width: 985px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    max-width: 1270px;
    margin: 0 auto;
  }
`;

export const BannerTextContainer = styled.div`
  padding: 32px 16px;
  flex: 3;

  @media screen and (min-width: 985px) {
    padding: 0 16px;
    margin: 0 auto;
    max-width: 960px;
    text-align: center;
  }
`;

export const BannerText = styled.p`
  font-family: ${fontFamilyHighlight};
  color: ${colorInterfaceLightPure};
  font-size: ${fontSizeMd};
  line-height: 26.4px;
  margin: 0;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const BannerImgContainer = styled.div`
  flex: 2;

  @media screen and (min-width: 985px) {
    max-width: 540px;
  }
`;

export const BannerImg = styled.img`
  margin-bottom: -10px;
  width: 100%;

  @media screen and (min-width: 985px) {
    margin-bottom: -6px;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
`;
