import styled from 'styled-components';

export const PaymentPendingContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BannerContainer = styled.div`
  max-width: 1124px;
`;
