import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px;
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;

  ${({ newLayout }) =>
    newLayout === 'dark' &&
    `
    background: transparent;
    border: 4px solid #343e50;
  `}
`;

const Title = styled.h2`
  margin: 0;
  margin-top: 15px;
  margin-left: 21px;
  margin-bottom: 8px;
  color: #78879c;
  font-weight: normal;
  font-size: 12px;
  line-height: 110%;
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ScoreCardLayout = ({ children, newLayout }) => (
  <Wrapper newLayout={newLayout}>
    <Title>Relacionamento Blu</Title>
    <Container>{children}</Container>
  </Wrapper>
);

ScoreCardLayout.defaultProps = {
  newLayout: 'light'
};

ScoreCardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  newLayout: PropTypes.string
};

export default ScoreCardLayout;
