import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  getClientStatusRequest: ['uuid'],
  getClientStatusSuccess: ['status'],
  getClientStatusFailure: ['error']
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  loading: false,
  cercAgreementOptInConfirmed: false,
  cercAgreementOptInStatus: 'initial',
  cercAgreementOptInAgreedAt: null,
  automaticAnticipationOptInConfirmed: false,
  automaticAnticipationEnabledAt: null,
  automaticAnticipationEnabledType: ''
};

export const getClientStatusRequest = (state) => ({
  ...state,
  loading: true
});

export const getClientStatusError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error
});

export const getClientStatusSuccess = (state, action) => ({
  ...state,
  loading: false,
  ...action.status
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CLIENT_STATUS_REQUEST]: getClientStatusRequest,
  [Types.GET_CLIENT_STATUS_SUCCESS]: getClientStatusSuccess,
  [Types.GET_CLIENT_STATUS_FAILURE]: getClientStatusError
});
