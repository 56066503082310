import React, { useState, useEffect, useRef } from 'react';

import { EyeOutline, EyeOffOutline } from '@useblu/ocean-icons-react';

import { CustomInput } from '../devise.styles';

const IconPass = (state, action) =>
  state ? (
    <EyeOutline
      className="icon-show-password"
      data-testid="eyeOn"
      color="rgba(182, 185, 204, 1)"
      onClick={() => {
        action(!state);
      }}
    />
  ) : (
    <EyeOffOutline
      className="icon-show-password"
      data-testid="eyeOff"
      color="rgba(182, 185, 204, 1)"
      onClick={() => {
        action(!state);
      }}
    />
  );

const PasswordInput = (props) => {
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [type, setType] = useState('text');
  const inputRef = useRef(null);

  const setFocus = () => {
    if (inputRef.current) {
      setType((current) => (current === 'text' ? 'password' : 'text'));
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.selectionStart = inputRef.current.value.length;
    inputRef.current.selectionEnd = inputRef.current.value.length;
  }, [type]);

  return (
    <CustomInput
      type={isPassVisible ? 'text' : 'password'}
      innerRef={inputRef}
      adornment={IconPass(isPassVisible, (e) => {
        setFocus();
        setIsPassVisible(e);
      })}
      {...props}
    />
  );
};

export default PasswordInput;
