import React from 'react';
import PropTypes from 'prop-types';
import createHumps from 'lodash-humps/lib/createHumps';
import { snakeCase } from 'lodash';

import { normalizeValue } from 'normalizers';
import ListItem from 'components/PaymentCollectionDetails/components/ListItem';
import AdvancementDetailsOutflow from 'components/Shared/AdvancementDetailsOutflow';
import CostsTemplate from 'components/PaymentCollectionDetails/containers/CostsTemplate';

const snakes = createHumps(snakeCase);

const Costs = ({ title }) => (
  <CostsTemplate title={title}>
    {({ advancements, aditionalTax, optimization }) => (
      <>
        {advancements && (
          <AdvancementDetailsOutflow
            advancedValue={normalizeValue(advancements.advancedValue)}
            advancesRateValue={normalizeValue(advancements.advancesRateValue)}
            advancedItemsNewModeling={snakes(
              advancements.advancedItemsNewModeling
            )}
            advancedItems={snakes(advancements.advancedItems)}
            optimization={optimization}
            paymentCollection
          />
        )}

        {aditionalTax && (
          <ListItem
            title={aditionalTax.title}
            value={normalizeValue(aditionalTax.value)}
          />
        )}
      </>
    )}
  </CostsTemplate>
);

Costs.propTypes = {
  title: PropTypes.string.isRequired
};

export default Costs;
