import { initializeProviders } from '@useblu/blu-lytics';

//TODO: Apagar quando migrarmos todos os MFE's
const environment = process.env.NODE_ENV;
const mixPanelApiKey = "a8ca68e485c5aee74f8fca5120485299";

const cookiesNotAllowed = document.cookie.includes(
  'privacy_policy_not_allowed'
);
const railsContext = window?.railsState?.railsContext;
const isNotStaff = railsContext?.staffUser === undefined || !railsContext?.staffUser;
const isLogged = railsContext?.currentUserId && railsContext?.currentUserId !== 'anonymous';
const shouldInitialize = (isNotStaff && isLogged) || !isLogged;

if (!cookiesNotAllowed) {
  initializeProviders(
    [
      { providerName: 'MixPanel', apiKey: mixPanelApiKey },
    ],
    { environment: shouldInitialize ? environment : 'development' }
  );
}