import React from 'react';
import classNames from 'classnames';

const isNotOrWithin = (ref, target) =>
  ref.contains && ref !== target && !ref.contains(target);

export default function useMenuOpen(action) {
  const [isOpen, setOpen] = React.useState(false);
  const [isContentMounted, mountContent] = React.useState(false);
  const ref = React.useRef();
  let animationTimeout = null;
  let leaveTimeout = null;

  React.useEffect(() => {
    const boundedAction = (e) => {
      if (isNotOrWithin(ref.current || {}, e.target)) setOpen(false);
    };

    document.body.addEventListener('click', boundedAction);
    return function cleanup() {
      document.body.removeEventListener('click', boundedAction);
    };
  }, [setOpen, ref, action]);

  const setOpenWithAnimation = (e) => {
    setOpen(e);
    const state = typeof e === 'function' ? e(isOpen) : e;

    if (animationTimeout) clearTimeout(animationTimeout);

    if (!state && action === 'click') {
      animationTimeout = setTimeout(() => {
        mountContent(state);
      }, 500);
    } else {
      mountContent(state);
    }
  };

  const onClick = (e) => {
    e.preventDefault();
    if (action === 'click') setOpenWithAnimation((open) => !open);
  };

  const onMouseEnter = (e) => {
    e.preventDefault();
    if (action === 'hover') setOpenWithAnimation(true);
  };

  const disableTimeoutEvent = (e) => {
    e.preventDefault();
    clearTimeout(leaveTimeout);
  };

  const leaveTimeoutEvent = (e) => {
    e.preventDefault();

    leaveTimeout = setTimeout(() => {
      setOpenWithAnimation(false);
    }, 100);
  };

  const containerProps = {
    onMouseLeave: leaveTimeoutEvent,
    onMouseOver: disableTimeoutEvent,
    onFocus: disableTimeoutEvent
  };

  const actionsProps = {
    onClick,
    onMouseEnter
  };

  const classes = classNames({
    'is-open': isOpen,
    'is-closed': !isOpen
  });

  return {
    classes,
    actionsProps,
    containerProps,
    isOpen,
    isContentMounted,
    ref
  };
}
