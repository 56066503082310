import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Collapse extends React.Component {
  state = {
    isOpen: false
  };

  componentDidMount() {
    this.setState({ isOpen: false });
  }

  createArrow() {
    const { arrowColor, parent, getContainerRef, transferParent } = this.props;

    const arrow = document.createElement('div');
    const arrowIco = document.createElement('i');

    const containerRef = getContainerRef();

    arrow.appendChild(arrowIco);
    arrow.className = 'arrow-up';
    arrowIco.className = 'fa fa-caret-up arrow-up-ico';
    arrowIco.style.color = arrowColor;

    if (parent) {
      const offsetLeft = this.collapseButton
        ? this.collapseButton.offsetLeft
        : 0;

      arrowIco.style.left = `${String(
        offsetLeft - containerRef.offsetLeft + 45
      )}px`;
    } else if (transferParent) {
      const offsetLeftParent = this.collapseButton.offsetParent
        ? this.collapseButton.offsetParent.offsetLeft
        : 0;
      const offsetLeft = this.collapseButton
        ? this.collapseButton.offsetLeft
        : 0;

      arrowIco.style.left = `${String(offsetLeftParent + offsetLeft + 20)}px`;
    } else {
      const offsetLeft = this.collapseButton
        ? this.collapseButton.offsetLeft
        : 0;

      arrowIco.style.left = `${String(offsetLeft + 45)}px`;
    }

    return arrow;
  }

  handleClick() {
    let currentPage = '';

    if (window.location.href.indexOf('transferencia') > -1) {
      currentPage = 'Transferência - ';
    } else if (window.location.href.indexOf('boleto') > -1) {
      currentPage = 'Boleto - ';
    } else if (window.location.href.indexOf('cobranca') > -1) {
      currentPage = 'Cobrança - ';
    }

    const label = !this.state.isOpen ? 'Ver Detalhes' : 'Esconder Detalhes';

    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      getContainerRef,
      openLabel = 'ver detalhes',
      closeLabel = 'esconder detalhes',
      arrowColor,
      hiddenCollapse
    } = this.props;

    const { isOpen } = this.state;

    const containerRef = getContainerRef();

    if (containerRef) {
      if (
        this.originalClass === undefined &&
        this.originalHeight === undefined
      ) {
        this.originalClass = containerRef.className;
        this.originalHeight = containerRef.clientHeight;

        if (arrowColor) {
          containerRef.insertBefore(
            this.createArrow(),
            containerRef.childNodes[0]
          );
        }
      }

      if (isOpen) {
        containerRef.style.height = `${this.originalHeight}px`;
      } else {
        containerRef.style.height = 0;
      }

      containerRef.className = classNames({
        [this.originalClass]: this.originalClass,
        'pagnet-collapse-component': true,
        closed: !isOpen,
        opened: isOpen
      });
    }

    return hiddenCollapse ? (
      <span
        ref={(ref) => {
          this.collapseButton = ref;
        }}
        onClick={() => this.handleClick(this)}
        style={{ cursor: 'pointer' }}
      >
        {openLabel}
      </span>
    ) : (
      <button
        className="detail__toggle"
        type="button"
        ref={(ref) => {
          this.collapseButton = ref;
        }}
        onClick={() => this.handleClick(this)}
      >
        {isOpen ? closeLabel : openLabel}
      </button>
    );
  }
}

Collapse.propTypes = {
  getContainerRef: PropTypes.func,
  openLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  arrowColor: PropTypes.string,
  hiddenCollapse: PropTypes.bool,
  parent: PropTypes.bool,
  transferParent: PropTypes.bool
};

Collapse.defaultProps = {
  parent: false,
  transferParent: false,
  hiddenCollapse: false
};

export default Collapse;
