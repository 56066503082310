import styled from 'styled-components';
import ReactLoading from 'react-loading';
import { Button as OceanButton } from '@useblu/ocean-react';
import { Devices } from 'utils/getDeviceMedia';

import {
  fontFamilyBase,
  fontFamilyHighlight,
  colorHighlightPure,
  colorBrandPrimaryPure,
  colorStatusNegativePure,
  colorInterfaceLightPure,
  spacingInlineXxs,
  spacingStackXs,
  spacingStackXxs,
  spacingStackSm
} from '@useblu/ocean-tokens/web/tokens';

export const Button = styled(OceanButton)`
  @media ${Devices.mobile} {
    flex: 1;
  }
`;

export const NegativeButton = styled(OceanButton)`
  margin-left: ${spacingInlineXxs};
  &&& {
    background-color: ${colorStatusNegativePure};
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${spacingInlineXxs};
  padding-top: ${spacingStackSm};
`;

export const Loading = styled(ReactLoading).attrs({
  type: 'spinningBubbles',
  color: colorInterfaceLightPure,
  delay: 0,
  width: 17,
  height: 17
})`
  display: inline-block;
`;

export const Title = styled.h1`
  font-family: ${fontFamilyHighlight};
  line-height: 35px;
  font-size: 32px;
  font-weight: 800;
  color: #5872f5;
  margin-bottom: 8px;
  text-align: inherit;

  @media ${Devices.tablet} {
    .customModal-optin & {
      font-size: 20px;
      line-height: 22px;
      br {
        display: none;
      }
    }
  }
  @media ${Devices.mobile} {
    .customModal-optin & {
      br {
        display: none;
      }
    }
  }
`;

export const SmallTitle = styled(Title)`
  font-size: 20px;
  color: ${colorBrandPrimaryPure};
`;

export const SmallTitleRed = styled(SmallTitle)`
  color: ${colorStatusNegativePure};
`;

export const Caption = styled.h2`
  font-family: ${fontFamilyBase};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #393b47;
  margin-bottom: 32px;
  text-align: inherit;

  &.modalSteps__confirm {
    margin-bottom: 0;
  }

  p.warning {
    margin-top: 32px;
    font-size: 14px;
    color: ${colorHighlightPure};
  }

  @media ${Devices.tablet} {
    .customModal-optin & {
      br {
        display: none;
      }
    }
  }
  @media ${Devices.mobile} {
    .customModal-optin & {
      br {
        display: none;
      }
    }
  }
`;

export const AnticipationOptOutContainer = styled.div`
  .ods-typography__heading2 {
    color: ${colorStatusNegativePure};
    margin-bottom: ${spacingStackXs};
  }

  .ods-typography__paragraph {
    margin-bottom: ${spacingStackXxs};
  }
`;
