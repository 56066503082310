import React from 'react';
import PropTypes from 'prop-types';

const TableBodyRow = ({
  children,
  data,
  classNames,
  columnsLength,
  indexErrorMessage
}) => {
  if (data.clickableRow) {
    return (
      <tr onClick={() => data.onClickRow(data)} className={data.className}>
        {children}
      </tr>
    );
  }

  const getClassName = () => {
    let className = '';
    const { row = '' } = classNames;
    if (row) {
      if (typeof row === 'string') {
        className += ` ${row}`;
      } else {
        className += ` ${row(data)}`;
      }
    }
    return className;
  };

  if (data[indexErrorMessage]) {
    return (
      <>
        <tr className={getClassName()}>{children}</tr>
        <tr className="table-row-error">
          <td colSpan={columnsLength}>
            <i className="fa fa-exclamation-circle" />
            {data[indexErrorMessage]}
          </td>
        </tr>
      </>
    );
  }

  return <tr className={getClassName()}>{children}</tr>;
};

TableBodyRow.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  classNames: PropTypes.shape({
    cell: PropTypes.any,
    row: PropTypes.any
  }),
  columnsLength: PropTypes.number.isRequired,
  indexErrorMessage: PropTypes.string
};

TableBodyRow.defaultProps = {
  classNames: {},
  indexErrorMessage: ''
};

export default TableBodyRow;
