import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@useblu/ocean-react';
import useModalManagement from 'components/Dashboard/hooks/useModalManagement';

import howItWorks1 from './images/howItWorks1.svg';
import howItWorks2 from './images/howItWorks2.svg';
import howItWorks3 from './images/howItWorks3.svg';

import {
  Container,
  Title,
  InfoContainer,
  Img,
  Text,
  ButtonContainer
} from './styles';

const HowItWorks = ({ tax, fullstoryClassName }) => {
  const { openModal } = useModalManagement();

  return (
    <Container data-testid="automatic-anticipation-how-it-works">
      <Title>Como funciona a Antecipação Automática da Blu</Title>

      <InfoContainer>
        <Img src={howItWorks1} alt="" />
        <Text>
          A partir do momento do aceite, caso você possua saldo na sua Agenda
          Blu, ele passará automaticamente para o seu Saldo atual para você já
          aproveitar a taxa fixa de antecipação.
        </Text>
      </InfoContainer>

      <InfoContainer>
        <Img src={howItWorks2} alt="" />
        <Text>
          Após essa etapa, todo o saldo vendido no crédito, independente do
          número de parcelas, será antecipado automaticamente e estará
          disponível na sua Conta Digital Blu como saldo atual no próximo dia
          útil.
        </Text>
      </InfoContainer>

      <InfoContainer>
        <Img src={howItWorks3} alt="" />
        <Text>
          Você pagará uma taxa fixa de antecipação de {tax}%, independente do
          número de parcelas que forem antecipadas! <br />
          <br />
          Essa taxa não inclui o MDR ou “Taxa do Cartão”.
        </Text>
      </InfoContainer>

      <ButtonContainer>
        <Button
          className={fullstoryClassName}
          role="button"
          onClick={() => openModal('AutomaticAnticipation')}
        >
          Ativar Antecipação Automática
        </Button>
      </ButtonContainer>
    </Container>
  );
};

HowItWorks.propTypes = {
  tax: PropTypes.string,
  fullstoryClassName: PropTypes.string
};

HowItWorks.defaultProps = {
  tax: '7,99',
  fullstoryClassName: ''
};

export default HowItWorks;
