import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { maskCpfOrCnpj } from 'utils/masks';

const customStyleModal = {
  content: {
    width: 'auto'
  }
};

const ChipStyled = styled.div`
  align-self: center;
  margin: 0 20px;
`;

export default class Item extends Component {
  static propTypes = {
    uuid: PropTypes.string,
    client: PropTypes.object,
    selectClient: PropTypes.func,
    nextStep: PropTypes.string,
    chipInfo: PropTypes.string
  };

  static defaultProps = {
    nextStep: '',
    uuid: null,
    chipInfo: ''
  };

  state = {
    modalIsOpen: false
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  deleteItem = () => {
    const { client, disableBankAccount } = this.props;
    const bankAccount = client.bank_account;

    if (bankAccount == null) {
      disableBankAccount(client, client.name, 'Client');
    } else {
      disableBankAccount(bankAccount, bankAccount.owner_name, 'BankAccount');
    }
  };

  onClick = (event) => {
    event.preventDefault();
    const { uuid, client, nextStep, selectClient } = this.props;

    selectClient(client, uuid || nextStep);
  };

  openModal = () => {
    const { client } = this.props;
    const bankAccount = client.bank_account || {};

    const nickname =
      bankAccount.nickname || client.name || bankAccount.owner_name;

    const name =
      (bankAccount.nickname && `${client.name || bankAccount.owner_name} | `) ||
      '';

    this.setState({ modalIsOpen: true });
  };

  cpfOrBankAccount = () => {
    const { client } = this.props;
    const bankAccount = client.bank_account;
    const cpfCnpj = client.cpf_cnpj;

    if (bankAccount) {
      let { office } = bankAccount;

      office =
        office.indexOf('-') > -1
          ? office.substring(0, office.indexOf('-'))
          : office;

      return `${bankAccount.bank} | Ag ${office} | CC. ${bankAccount.number}`;
    }

    return maskCpfOrCnpj(cpfCnpj);
  };

  renderClientItemModal() {
    const classes = {
      afterOpen: 'modal--open',
      base: 'modal',
      beforeClose: 'modal'
    };

    const overlayClasses = {
      afterOpen: 'modal-overlay--open',
      base: 'modal-overlay',
      beforeClose: 'modal-overlay'
    };

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={() => this.closeModal()}
        contentLabel="Modal"
        className={classes}
        style={customStyleModal}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
      >
        <h2>Excluir conta favorecida?</h2>
        <div className="modal-action">
          <button
            className="button button--primary"
            onClick={() => this.deleteItem()}
          >
            Sim
          </button>
          <button
            className="button button--secondary"
            onClick={() => this.closeModal()}
          >
            Não
          </button>
        </div>
      </Modal>
    );
  }

  renderClientItemAction() {
    const { client, disableButtonRemove = false } = this.props;
    const bankAccount = client.bank_account;
    let content;

    if (disableButtonRemove) {
      if (
        (client && _.isEmpty(bankAccount)) ||
        (bankAccount && !bankAccount.bank_account_automatic)
      ) {
        content = (
          <div
            className="clients-search-list__item-action"
            onClick={() => this.openModal()}
          >
            <i className="fa fa-trash" />
          </div>
        );
      } else {
        content = (
          <div className="clients-search-list__item-action">
            <i
              data-tip="Essa conta não pode ser removida, pois você utiliza ela no repasse automático."
              className="fa fa-info-circle"
            />
            <ReactTooltip place="top" type="dark" effect="float" />
          </div>
        );
      }
    }

    return content;
  }

  renderAccountTypeIco() {
    const { client } = this.props;
    const bankAccount = client.bank_account;

    if (bankAccount) {
      return (
        <div className="clients-search-list__item-type">
          <i
            data-tip={bankAccount.name || ''}
            className={
              'item-type-ico-' + (bankAccount.bank_compensation_code || '')
            }
          />
        </div>
      );
    }

    return (
      <div className="clients-search-list__item-type">
        <i data-tip="Conta BLU" className="item-type-ico-pagnet" />
      </div>
    );
  }

  render() {
    const { client, chipInfo } = this.props;
    const bankAccount = client.bank_account || {};

    const nickname =
      bankAccount.nickname || client.name || bankAccount.owner_name;

    const name =
      (bankAccount.nickname && `${client.name || bankAccount.owner_name} | `) ||
      '';

    return (
      <div>
        <div
          className={
            'clients-search-list__item' +
            (!bankAccount ? ' item-type-pagnet' : '')
          }
        >
          <div
            className="clients-search-list__item-description"
            onClick={(event) => this.onClick(event)}
          >
            <p className="clients-search-list__item-name">{nickname}</p>
            <small className="clients-search-list__item-account">
              {name + this.cpfOrBankAccount()}
            </small>
          </div>
          {chipInfo && (
            <ChipStyled className="chip-root chip-root--small">
              <span className="chip-label chip-label--small">{chipInfo}</span>
            </ChipStyled>
          )}
          {this.renderAccountTypeIco()}
          {this.renderClientItemAction()}
          {this.renderClientItemModal()}
        </div>
      </div>
    );
  }
}
