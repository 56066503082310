import { put, select } from 'redux-saga/effects';

import ActionCreators from 'store/ducks/firstAccess/permission';

export function* checkPermission() {
  const userCpf = yield select((state) => state.railsContext.currentUserCpf);
  const corporateBoard = yield select(
    (state) => state.firstAccessQsaAccounts.qsa
  );

  if (!corporateBoard.length) {
    yield put(ActionCreators.permissionShow(true, 'Administrador'));
  } else {
    const user = corporateBoard.filter((board) => board.cpfCnpj === userCpf);

    if (!user.length) {
      yield put(ActionCreators.permissionShow(false, 'Operador'));
    }
    {
      const { position } = user[0].companyAssociates[0];

      yield put(
        ActionCreators.permissionShow(
          position.toLowerCase().indexOf('admin') > -1,
          position
        )
      );
    }
  }
}
