import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    padding: 0px 40px;
    margin-bottom: 20px;
  }
`;

export const Image = styled.img`
  align-self: center;
  width: 100%;
  max-width: 210px;
  padding: 16px 0;
`;
