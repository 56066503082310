import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import history from 'utils/browserHistory';
import Loading from 'react-loading';
import Breadcrumb from 'components/Shared/Breadcrumb';
import { normalizeCurrency } from 'normalizers';

import importConfirmationCreators from 'store/ducks/importPaymentConfirmation';
import bankOptionsActions from 'store/ducks/bankOptions';
import Card from 'components/Shared/Card';
import Table from 'components/Shared/Table';
import { maskValue } from 'utils/masks';
import { importPaymentConfirmationSelector } from 'selectors/payment-confirmation';
import EditableCell from './components/EditableCell';

const ImportPaymentConfirmationWrapper = () => {
  const dispatch = useDispatch();

  const { uuid, importUuid } = useParams();

  const stateUuid = useSelector(
    (state) => state.importPaymentConfirmation.fileUuid
  );
  const fileUuid = importUuid || stateUuid;
  const bankOptions = useSelector((state) => state.bankOptions.items);
  const {
    data,
    dataEdit,
    duplicatedTransaction,
    errorMessage,
    loading,
    loadingConfirm
  } = useSelector(importPaymentConfirmationSelector);

  const [editingKey, setEditingKey] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState(null);

  useEffect(() => {
    if (data.length === 0) {
      dispatch(
        importConfirmationCreators.getImportPaymentConfirmationRequest(fileUuid)
      );
    }
  }, [fileUuid, dispatch]);

  useEffect(() => {
    dispatch(bankOptionsActions.bankOptionsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (errorMessage) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [errorMessage]);

  useEffect(() => {
    if (duplicatedTransaction) {
      onOpenModal('similarTransaction')();
    }
  }, [duplicatedTransaction]);

  useEffect(() => {
    if (!stateUuid) {
      dispatch(importConfirmationCreators.setFileUuid(fileUuid));
    }
  }, [stateUuid, fileUuid, dispatch]);

  const isEditingRow = (index) => index === editingKey;

  const verifyBluAccount = (data) =>
    data.blu &&
    isEmpty(data.bankCode.replace(/0/g, '')) &&
    isEmpty(data.bankAccount.replace(/0/g, '')) &&
    isEmpty(data.bankBranchCode.replace(/0/g, ''));

  const getColumns = () => [
    {
      title: 'Favorecido',
      dataIndex: 'payeeName',
      key: 'payeeName',
      editable: false
    },
    {
      title: 'CPF/CNPJ',
      dataIndex: 'cpfCnpj',
      key: 'cpfCnpj',
      editable: false,
      render: (data) => maskValue(data.cpfCnpj, 'cpf_cnpj')
    },
    {
      title: 'Banco',
      dataIndex: 'bankCode',
      dataLabel: 'bankName',
      key: 'bankCode',
      editable: true,
      type: 'select',
      render: (data) =>
        verifyBluAccount(data) ? (
          <div className="blu-account">Conta Blu</div>
        ) : (
          `${data.bankName} (${data.bankCode})`
        )
    },
    {
      title: 'Agência (sem digito)',
      dataIndex: 'bankBranchCode',
      key: 'bankBranchCode',
      editable: true,
      type: 'text',
      render: (data) =>
        verifyBluAccount(data) ? null : `${data.bankBranchCode}`
    },
    {
      title: 'Conta',
      dataIndex: 'bankAccount',
      key: 'bankAccount',
      editable: true,
      type: 'text',
      render: (data) =>
        verifyBluAccount(data)
          ? null
          : `${data.bankAccount}-${data.bankAccountCheckDigit}`
    },
    {
      title: 'Valor',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      editable: true,
      type: 'text',
      mask: 'currency',
      render: (data) =>
        `R$ ${normalizeCurrency(parseFloat(data.paymentAmount))}`
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      width: '75px',
      render: (data, indexRow) => {
        const editing = isEditingRow(indexRow);
        return (
          <div className="table-row-actions">
            {editing ? (
              <>
                <button
                  type="button"
                  className="table-row-actions__itemns"
                  onClick={onSave}
                >
                  <i className="fa fa-check" />
                </button>
                <button
                  type="button"
                  className="table-row-actions__itemns action-danger"
                  onClick={onOpenModal('deleteRow')}
                >
                  <i className="fa fa-trash" />
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="table-row-actions__itemns"
                  onClick={() => setEditingKey(indexRow)}
                >
                  <i className="fa fa-pencil" />
                </button>
              </>
            )}
          </div>
        );
      }
    }
  ];

  const fetchObjConfirmTransactions = (d, index) => {
    const returnData = {
      ...d,
      row: index + 1
    };

    delete returnData.warningNotConfirmedSimilarTransaction;
    delete returnData.status;
    returnData.error = '';
    return returnData;
  };

  const fetchObjConfirmSimilarTransactions = (d, index) => {
    let returnData = {
      ...d,
      similarTransactionConfirmed: false,
      row: index + 1
    };
    if (d.warningNotConfirmedSimilarTransaction) {
      returnData = {
        ...returnData,
        similarTransactionConfirmed: true
      };
    }
    delete returnData.warningNotConfirmedSimilarTransaction;
    delete returnData.status;
    returnData.error = '';
    return returnData;
  };

  const onOpenModal = (modalType) => () => {
    setModalIsOpen(true);
    setModalType(modalType);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);

    if (modalType === 'similarTransaction')
      dispatch(importConfirmationCreators.closeDuplicatedTransaction());
  };

  const onSave = () => {
    const dataAux = [...data];
    dataAux[editingKey] = {
      ...data[editingKey],
      ...dataEdit
    };

    dispatch(
      importConfirmationCreators.updateDataImportPaymentConfirmation(
        dataAux,
        false
      )
    );
    setEditingKey(null);
  };

  const onDelete = () => {
    const dataAux = [...data];
    dataAux.splice(editingKey, 1);

    dispatch(
      importConfirmationCreators.updateDataImportPaymentConfirmation(
        dataAux,
        false
      )
    );
    onCloseModal();
    setEditingKey(null);
  };

  const onCancelImportation = () => {
    window.location.href = `/lojas/${uuid}/transacoes/transferencia/importacao`;
  };

  const onSubmit = () => {
    dispatch(
      importConfirmationCreators.setImportPaymentConfirmationRequest(
        uuid,
        data.map(fetchObjConfirmTransactions)
      )
    );
    onCloseModal();
  };

  const onSubmitDuplicationTransaction = () => {
    dispatch(
      importConfirmationCreators.updateDataImportPaymentConfirmation(
        data.map(fetchObjConfirmSimilarTransactions),
        false
      )
    );

    dispatch(importConfirmationCreators.clearErrors());

    onCloseModal();

    history.push(
      `/lojas/${uuid}/transacoes/transferencia/nova/confirmacao-importacao`
    );
  };

  const onAddTransactions = () => {
    onSubmit();
  };

  const bankOptionsMapped = bankOptions.map((option) => ({
    label: `${option.name}`,
    value: option.compensation_code
  }));

  const columns = getColumns().map((column) => {
    if (!column.editable) return column;

    if (column.type === 'select') {
      return {
        ...column,
        onCell: (data, indexRow) => ({
          data,
          dataIndex: column.dataIndex,
          value: data[column.dataIndex],
          isEditing: isEditingRow(indexRow),
          title: column.title,
          type: column.type,
          dataLabel: column.dataLabel,
          label: data[column.dataLabel],
          options: bankOptionsMapped
        })
      };
    }

    return {
      ...column,
      onCell: (data, indexRow) => {
        let value = data[column.dataIndex];

        if (column.mask === 'currency')
          value = normalizeCurrency(parseFloat(data[column.dataIndex]));

        return {
          data,
          dataIndex: column.dataIndex,
          value,
          isEditing: isEditingRow(indexRow),
          title: column.title,
          type: column.type,
          mask: column.mask
        };
      }
    };
  });

  const priceCount = () =>
    `R$ ${normalizeCurrency(
      parseFloat(data.reduce((acc, current) => acc + +current.paymentAmount, 0))
    )}`;

  const disabledButtonConfirm = () => loading || loadingConfirm || !data.length;
  const disabledButtonCancel = () => loading || !data.length;

  const renderModal = () => {
    let buttons;
    let title;

    const classes = {
      afterOpen: 'modal--open',
      base: 'modal modal-v2',
      beforeClose: 'modal modal-v2'
    };

    const overlayClasses = {
      afterOpen: 'modal-overlay--open',
      base: 'modal-overlay modal-v2--overlay',
      beforeClose: 'modal-overlay modal-v2--overlay'
    };

    const customStyleModal = {
      content: {
        width: 'auto'
      }
    };

    let message = null;

    if (modalType === 'deleteRow') {
      title = (
        <h2 className="modal-v2-title">
          Essa ação excluirá a transação que
          <br />a linha se refere
        </h2>
      );
      message = <p className="modal-v2-description">Deseja continuar?</p>;

      buttons = (
        <div className="modal-v2-actions">
          <button
            type="button"
            className="button button--danger button--rounder button--ghost border-less"
            onClick={onCloseModal}
          >
            Não excluir
          </button>
          <button
            type="button"
            className="button button--rounder"
            onClick={() => onDelete(editingKey)}
          >
            Excluir transação
          </button>
        </div>
      );
    } else if (modalType === 'cancelImport') {
      title = (
        <h2 className="modal-v2-title">
          Essa ação encerrará a
          <br />
          importação de arquivos?
        </h2>
      );
      message = (
        <p className="modal-v2-description">Deseja mesmo sair dessa página?</p>
      );
      buttons = (
        <div className="modal-v2-actions">
          <button
            type="button"
            className="button button--danger button--rounder button--ghost border-less"
            onClick={onCloseModal}
          >
            Voltar para o arquivo
          </button>
          <button
            type="button"
            className="button button--rounder"
            onClick={onCancelImportation}
          >
            Encerrar importação
          </button>
        </div>
      );
    } else if (modalType === 'similarTransaction') {
      title = (
        <h2 className="modal-v2-title">
          Você fez outra(s) transferência(s) para esse
          <br />
          mesmo favorecido neste mesmo valor hoje
        </h2>
      );
      message = (
        <p className="modal-v2-description">
          Deseja fazer essa transação realmente?
        </p>
      );
      buttons = (
        <div className="modal-v2-actions">
          <button
            type="button"
            className="button button--danger button--rounder button--ghost border-less"
            onClick={onCloseModal}
          >
            Voltar para o arquivo
          </button>
          <button
            type="button"
            className="button button--rounder"
            onClick={onSubmitDuplicationTransaction}
          >
            Continuar
          </button>
        </div>
      );
    }

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onCloseModal}
        contentLabel="Modal"
        className={classes}
        style={customStyleModal}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
      >
        <div style={{ padding: '20px' }}>
          {title}
          {message}
          {buttons}
        </div>
      </Modal>
    );
  };

  return (
    <div className="import-confirmation">
      <Breadcrumb
        items={[
          ['Início', `/lojas/${uuid}/dashboard`],
          ['Transferência', `/lojas/${uuid}/transacoes/transferencia/nova`],
          [
            'Importar arquivo de transferência',
            `/lojas/${uuid}/transacoes/transferencia/importacao`
          ],
          ['Dados do arquivo']
        ]}
        className="breadcrumbs-2 no-padding only-desktop"
      />

      <button
        className="button button--back only-mobile"
        type="button"
        style={{ marginBottom: '10px' }}
        onClick={() => history.goBack()}
      >
        <i className="fa fa-angle-left" />
        voltar
      </button>

      <div className="import-confirmation-title">
        <h1>Dados do arquivo</h1>
        <p>Confira abaixo as transações presentes no arquivo:</p>
      </div>

      <Card className="import-confirmation-content">
        {errorMessage && (
          <p className="text-error">
            <i className="fa fa-exclamation-circle" />
            {errorMessage}
          </p>
        )}
        <Table
          className="import-confirmation-content__list"
          columns={columns}
          components={{ cell: EditableCell }}
          dataSource={data}
          loading={loading}
          indexErrorMessage="error"
          emptyMessage="Não há pagamentos no arquivo selecionado"
          classNames={{
            cell: (data) => (data.error ? 'data-cell-error' : ''),
            row: (data) => {
              if (data.warningNotConfirmedSimilarTransaction) {
                return 'data-row-warning';
              }
              return data.error ? 'data-row-error' : '';
            }
          }}
        />
      </Card>
      <div className="import-confirmation-footer">
        <div className="import-confirmation-footer__buttons">
          <button
            className="button button--secondary button--rounder flex-1"
            onClick={onOpenModal('cancelImport')}
            type="button"
            disabled={disabledButtonCancel()}
          >
            Cancelar pagamentos
          </button>
          <button
            className="button button--rounder flex-1"
            onClick={onAddTransactions}
            type="button"
            disabled={disabledButtonConfirm()}
          >
            {disabledButtonConfirm() ? (
              <div className="u-flex content-center">
                <Loading
                  type="spinningBubbles"
                  color="#343e4f"
                  delay={0}
                  width={17}
                  height={17}
                />
                <span style={{ marginLeft: '7px' }}>AGUARDE</span>
              </div>
            ) : (
              'Adicionar pagamentos a lista'
            )}
          </button>
        </div>
        <div className="import-confirmation-footer__info">
          <div className="import-confirmation-footer__qtds">
            <p className="itemn-title">Qtd. de itens:</p>
            <p className="itemn-description">{data.length}</p>
          </div>
          <div className="import-confirmation-footer__amount">
            <p className="itemn-title">Valor total:</p>
            <p className="itemn-description">{priceCount()}</p>
          </div>
        </div>
      </div>

      {renderModal()}
    </div>
  );
};

export default ImportPaymentConfirmationWrapper;
