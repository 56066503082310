import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  openModal: ['feature'],
  closeModal: ['feature']
});

export { Types };

export default Creators;

const INITIAL_STATE = {
  isModalOpen: false,
  feature: ''
};

export const openModal = (state, action) => ({
  ...state,
  isModalOpen: true,
  feature: action.feature
});

export const closeModal = (state, action) => ({
  ...state,
  isModalOpen: false,
  feature: action.feature
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_MODAL]: openModal,
  [Types.CLOSE_MODAL]: closeModal
});
