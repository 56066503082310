import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { railsContextSelector } from 'selectors/rails-context';
import { UserGroup } from '@useblu/ocean-icons-react';
import { useMedia } from 'react-use';
import Option from '../../shared/Option';
import ClientSelectorActions from '../../../../store/ducks/clientSelector';
import DropdownMenu from '../../shared/DropdownMenu';
import DrawerMenu from '../../shared/DrawerMenu';
import {
  ClientContainer,
  Client,
  Nav,
  Menu,
  UserGroupWrapper
} from './ClientSelector.styles';

const ClientSelector = ({ currentStoreId, clientName }) => {
  const dispatch = useDispatch();
  const stores = useSelector((state) => state.clientSelector?.data);
  const { economicGroupView } = useSelector(railsContextSelector);

  const isMobile = useMedia('(max-width: 768px)');

  useEffect(() => {
    const getCompaniesStore = (currentClientUuid) => {
      dispatch(
        ClientSelectorActions.getCompaniesStoreRequest(currentClientUuid)
      );
    };

    getCompaniesStore(currentStoreId);
  }, [dispatch, currentStoreId]);

  _.remove(stores, { id: currentStoreId });

  let client = (
    <Client>
      <span>{clientName}</span>
      <i className="fa fa-angle-down new-store-selector__icon" />
    </Client>
  );

  if (economicGroupView) {
    client = (
      <ClientContainer>
        <UserGroupWrapper data-testid="user-group-icon">
          <UserGroup size={20} />
        </UserGroupWrapper>
        <Client economicGroupView>
          <span>{clientName}</span>
          <i className="fa fa-angle-down new-store-selector__icon" />
        </Client>
      </ClientContainer>
    );
  }

  const content = (
    <nav>
      <Menu>
        <small>Selecionar Loja</small>
        {stores &&
          stores.map((store) => (
            <Option
              key={store.id}
              id={store.id}
              link={`/lojas/${store.id}/dashboard`}
              label={store.name}
            />
          ))}
      </Menu>
    </nav>
  );

  const menu = isMobile ? (
    <DrawerMenu displayClass="selecionar-cliente" width="280px">
      {client}
      {content}
    </DrawerMenu>
  ) : (
    <DropdownMenu displayClass="selecionar-cliente" width="280px">
      {client}
      {content}
    </DropdownMenu>
  );

  return stores && stores.length <= 0 ? (
    <Nav>
      <Client>
        <span>{clientName}</span>
      </Client>
    </Nav>
  ) : (
    menu
  );
};

ClientSelector.propTypes = {
  clientName: PropTypes.string,
  currentStoreId: PropTypes.string
};

ClientSelector.defaultProps = {
  clientName: '',
  currentStoreId: ''
};

export default ClientSelector;
