import {
  cancelPaymentsApi,
  getPaymentListApi,
  setPaymentsApi
} from 'api/payment-confirmation';

import { call, put } from 'redux-saga/effects';
import { railsContextSelector } from 'selectors/rails-context';
import ActionCreators from 'store/ducks/paymentConfirmation';

export function* getPaymentConfirmation({ uuid }) {
  try {
    if (!uuid) {
      throw new Error('Uuid obrigatório!');
    }
    const response = yield call(getPaymentListApi, uuid);

    const {
      data: {
        data: {
          preRegistrationTransactions: { transactions }
        }
      }
    } = response;

    yield put(ActionCreators.getPaymentConfirmationSuccess(transactions));
  } catch (error) {
    yield put(ActionCreators.getPaymentConfirmationFailure());
  }
}

export function* setPaymentConfirmation({ clientUuid, ids = [] }) {
  try {
    if (!clientUuid) {
      throw new Error('Uuid obrigatório!');
    }
    if (!ids.length) {
      throw new Error('Dados para confirmação de pagamentos é obrigatório!');
    }

    const {
      data: {
        data: {
          createBatchPayment: {
            transactionsWithSameOwnership,
            transactionsWithoutSameOwnership,
            uuid
          }
        }
      }
    } = yield call(setPaymentsApi, clientUuid, ids);

    const { location } = window;

    if (railsState.railsContext.bTokenActive && uuid) {
      location.assign(
        `${location.origin}/lojas/${clientUuid}/pagamentos/${uuid}/confirm?provider=b_token`
      );
    } else {
      location.assign(
        `${location.origin}/lojas/${clientUuid}/transacoes/pagamentos-pendentes`
      );
    }

    yield put(
      ActionCreators.setPaymentConfirmationSuccess(
        transactionsWithSameOwnership,
        transactionsWithoutSameOwnership
      )
    );
  } catch (error) {
    console.error(error);
    yield put(ActionCreators.setPaymentConfirmationFailure());
  }
}

export function* cancelPaymentConfirmation({ uuid, ids = [] }) {
  try {
    if (!uuid) {
      throw new Error('Uuid obrigatório!');
    }
    if (!ids.length) {
      throw new Error(
        'Lista de ids para cancelar de pagamentos é obrigatório!'
      );
    }

    yield call(cancelPaymentsApi, uuid, ids);

    const { location } = window;
    location.assign(
      `${location.origin}/lojas/${uuid}/transacoes/confirmacao-pagamentos`
    );

    yield put(ActionCreators.cancelPaymentConfirmationSuccess());
  } catch (error) {
    yield put(ActionCreators.cancelPaymentConfirmationFailure());
  }
}

export function* setPaymentConfirmationInDate({ uuid, transactions = [] }) {
  try {
    if (!uuid) {
      throw new Error('Uuid obrigatório!');
    }
    if (!transactions.length) {
      throw new Error('Lista de transações é obrigatório!');
    }

    const outDateIds = transactions
      .filter((transaction) => transaction.paymentToBillOverdue)
      .map((transaction) => transaction.id);
    const inDateIds = transactions
      .filter((transaction) => !transaction.paymentToBillOverdue)
      .map((transaction) => transaction.id);

    yield call(cancelPaymentsApi, uuid, outDateIds);

    const {
      data: {
        data: {
          createBatchPayment: {
            isSameOwnership,
            transactionsWithSameOwnership,
            transactionsWithoutSameOwnership
          }
        }
      }
    } = yield call(setPaymentsApi, uuid, inDateIds);

    const { location } = window;

    if (isSameOwnership) {
      location.assign(`${location.origin}/lojas/${uuid}/movimentacoes`);
    } else {
      location.assign(
        `${location.origin}/lojas/${uuid}/transacoes/pagamentos-pendentes`
      );
    }

    yield put(
      ActionCreators.setPaymentConfirmationInDateSuccess(
        transactionsWithSameOwnership,
        transactionsWithoutSameOwnership
      )
    );
  } catch (error) {
    yield put(ActionCreators.setPaymentConfirmationInDateFailure());
  }
}
