import styled from 'styled-components';
import {
  fontFamilyHighlight,
  fontSizeMd,
  fontSizeSm,
  lineHeightMedium,
  spacingInsetLg,
  spacingStackXs,
  spacingStackSm,
  spacingStackXxxs,
  colorInterfaceDarkDeep,
  fontFamilyBase,
  fontSizeXxs,
  lineHeightComfy,
  colorInterfaceDarkDown,
  colorBrandPrimaryDown,
  fontWeightMedium,
  colorInterfaceLightUp,
  colorBrandPrimaryPure,
  colorInterfaceLightDown,
  colorInterfaceLightPure,
  shadowLevel1,
  spacingStackMd,
  spacingInlineMd,
  spacingInlineXxs,
  fontSizeXs,
} from '@useblu/ocean-tokens/web/tokens';

import { Grid, Button } from '@useblu/ocean-react';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  tablet: `(max-width: 1024px)`,
};

export const GridContainer = styled(Grid.Container)`
  &&& {
    @media ${devices.mobile} {
      margin-bottom: 103px;
    }
  }

  &&& {
    @media (max-width: 577px) {
      padding: 0 16px;
    }
  }
`;

export const Bar = styled.div`
  margin-top: ${spacingInsetLg};
  width: 100%;

  .ods-topbar {
    flex-wrap: nowrap;
    height: auto;
  }

  .ods-topbar-title {
    margin: 0;
    font-size: ${fontSizeMd};
  }

  .ods-topbar-left {
    margin-left: 0;
  }

  @media ${devices.mobile} {
    background-color: ${colorInterfaceLightPure};
    box-shadow: ${(props) => (props.inScroll ? shadowLevel1 : 0)};
    display: flex;
    justify-content: center;
    left: 0;
    margin: 0;
    padding: 17px 16px;
    position: fixed;
    top: 0;

    .ods-topbar {
      margin-bottom: 0;
      max-width: 540px;
    }

    .ods-topbar-title {
      font-size: ${fontSizeSm};
    }

    .ods-topbar-left {
      margin-left: 0;
      margin-right: ${spacingStackXs};
    }
  }
`;

export const Subtitle = styled.h2`
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeSm};
  font-weight: 900;
  color: ${colorInterfaceDarkDeep};
  line-height: ${lineHeightMedium};
  margin-bottom: ${spacingStackXxxs};

  @media ${devices.mobile} {
    margin-top: 74px;
  }
`;

export const Description = styled.p`
  color: ${colorInterfaceDarkDown};
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXxs};
  line-height: ${lineHeightComfy};
  margin-bottom: ${spacingInsetLg};

  @media ${devices.mobile} {
    margin-bottom: ${spacingStackSm};
    margin-top: 58px;
    font-size: ${fontSizeXs};
  }
`;

export const TutorialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacingInlineMd};
  gap: ${spacingStackMd};

  @media ${devices.mobile} {
    justify-content: center;
  }
`;

export const MobileQrcode = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${spacingStackXs};

  @media ${devices.mobile} {
    display: none;
  }
`;

export const StepsContainer = styled.div`
  flex: 1;
`;

export const Steps = styled.ol`
  display: flex;
  margin-left: 0;
  flex-direction: column;
  gap: ${spacingStackXs};
  margin-top: ${spacingStackSm};
  list-style: none;
`;

export const StepItem = styled.li`
  counter-increment: custom;
  display: flex;
  gap: ${spacingInlineXxs};
  color: ${colorInterfaceDarkDown};
  font-family: ${fontFamilyBase};

  &:before {
    content: counter(custom);
    display: inline-flex;
    min-width: 20px;
    height: 20px;
    font-weight: ${fontWeightMedium};
    border-radius: 50%;
    justify-content: center;
    background-color: ${colorInterfaceLightUp};
    color: ${colorBrandPrimaryDown};
  }

  &:first-child {
    counter-reset: custom;
  }
`;

export const LearnMore = styled.div`
  align-items: center;
  display: flex;

  @media ${devices.mobile} {
    align-items: flex-start;
    flex-direction: column;

    p {
      margin-bottom: 5px;
    }
  }

  p {
    color: ${colorInterfaceDarkDown};
    display: block;
    font-size: ${fontSizeXxs};
    line-height: ${lineHeightComfy};
    margin-bottom: 0;
  }
`;

export const LearnMoreLink = styled.div`
  align-items: center;
  display: flex;

  ${(props) => props.$withMargin && `margin-top: ${spacingStackSm};`}

  &:hover {
    cursor: pointer;
  }

  p {
    color: ${colorBrandPrimaryPure};
    font-size: ${fontSizeXxs};
    font-weight: ${fontWeightMedium};
    margin-right: 4px;
  }

  @media ${devices.mobile} {
    margin-left: 0;

    p {
      margin-bottom: 0;
    }
  }
`;

export const Footer = styled.div`
  background-color: ${colorInterfaceLightPure};
  border-top: 1px solid ${colorInterfaceLightDown};
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 16px;
  position: fixed;
  width: 100%;

  @media ${devices.notebook} {
    display: none;
  }
`;

export const DownloadAppBtn = styled(Button)`
  width: 100%;
  max-width: 538px;
`;
