import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { normalizeCurrency } from 'normalizers';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import SummaryValues from './SummaryValues';

const Header = ({ isCharger }) => {
  const {
    title,
    amountValue,
    refunded,
    status,
    showSummaryValues
  } = useSelector(selectors.getDetailsData);

  return (
    <>
      <div className="statement-detail__title">
        <div className="container is-padded background-title">
          <div className="statement-detail__value flex-container is-padded">
            <div className="statement-detail__value-info">
              <h2 className="statement-detail__type">{title}</h2>

              <small className="statement-detail__prefix">R$</small>
              <strong
                className={classNames(
                  'statement-detail__amount',
                  refunded && 'statement-detail__amount--canceled'
                )}
              >
                {normalizeCurrency(amountValue)}
              </strong>
            </div>
          </div>

          {showSummaryValues && <SummaryValues isCharger={isCharger} />}
        </div>
      </div>

      <div
        className={`statement-detail__status statement-detail--bg-${status.name}`}
      >
        {status.description}
      </div>
    </>
  );
};

Header.propTypes = {
  isCharger: PropTypes.bool.isRequired
};

export default Header;
