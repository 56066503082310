import styled, { css } from 'styled-components';
import { Link } from '@useblu/ocean-react';
import { colorStatusPositiveDown } from '@useblu/ocean-tokens/web/tokens';

export const LinkStyled = styled(Link)`
  &&& {
    display: inline-flex;
    align-items: center;
    line-height: inherit;

    ${(props) =>
      props.color &&
      css`
        color: ${props.color};
      `}
  }
`;

export const StatusColumn = styled.td`
  text-align: center;
  ${(props) =>
    props.isFinished &&
    css`
      color: ${colorStatusPositiveDown};
    `}
`;

export const Actions = styled.div`
  &&& {
    text-align: left;
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
    justify-content: center;

    @media screen and (max-width: 490px) {
      grid-auto-flow: row;
    }
  }
`;
