import React from 'react';
import PropTypes from 'prop-types';

import { WrapperMarketingAside } from './DeviseScreenLayout.styles';
import LogoBlu from './LogoBlu';

const MarketingAside = ({ title, caption, image }) => (
  <WrapperMarketingAside>
    <div>
      <LogoBlu />
      <h2>{title}</h2>
      <p>{caption}</p>
    </div>
    <div>
      <img className="marketing-illustration" src={image} alt="vem pra Blu" />
    </div>
  </WrapperMarketingAside>
);

MarketingAside.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};

export default MarketingAside;
