import React from 'react';
import styled from 'styled-components';

import successReponseImg from 'images/success-icon.png';
import { BoxContent } from './DeviceCard.styles';
import DeviceCardLayout from './DeviceCardLayout';

const CustomBoxContent = styled(BoxContent)`
  h1 {
    font-weight: normal;
    font-size: 20px;
    line-height: 110%;
    color: #67697a;
    margin: 16px 0 16px 0;
  }
  h2 {
    font-size: 16px;
    color: #d8dae8;
  }
`;

const DeviceCardSuccess = () => (
  <DeviceCardLayout
    iconImg={{ alt: 'Sem Dispositivo', src: successReponseImg, width: '40px' }}
    isSuccess
  >
    <CustomBoxContent>
      <h1>bToken desativado com sucesso!</h1>
      <h2>Nenhum dispositivo autorizado</h2>
    </CustomBoxContent>
  </DeviceCardLayout>
);

export default DeviceCardSuccess;
