/* eslint-disable import/prefer-default-export */
import styled, { createGlobalStyle } from 'styled-components';

import {
  borderRadiusMd,
  spacingInlineXs,
  spacingInlineXxs,
  colorInterfaceLightUp,
  spacingStackSm,
  fontSizeSm,
  fontSizeMd,
  fontSizeXs,
  fontSizeXxs,
  spacingStackXs,
  fontWeightBold,
  spacingInlineMd,
  fontWeightRegular,
  colorInterfaceLightDown,
  colorInterfaceDarkDown,
  colorInterfaceDarkPure,
  colorInterfaceDarkUp,
  spacingInsetXxs,
  spacingInsetSm,
  spacingInsetMd,
  spacingInsetXs,
  spacingInlineXxl,
  colorInterfaceLightPure,
} from '@useblu/ocean-tokens/web/tokens';
import { Grid, Tag } from '@useblu/ocean-react';
import { Devices } from 'utils/getDeviceMedia';

const devices = {
  mobile: `(max-width: 768px)`,
};

export const WrapperRefunds = styled.div`
  margin-top: ${spacingInlineXs};

  .ods-transaction-list-item__sub-information {
    width: 25%;
  }

  @media ${devices.mobile} {
    padding: 0 ${spacingInlineXs};
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-top: ${spacingInlineXs};
  margin-top: ${spacingInlineMd};
  border-top: 1px solid ${colorInterfaceLightDown};
  justify-content: flex-end;
  gap: ${spacingInlineXxs};

  @media ${devices.mobile} {
    display: flex;
    flex-direction: column-reverse;
    margin: ${spacingInlineXs} ${spacingInlineXs} ${spacingInlineXs};
  }
`;

export const WrapperSkeleton = styled.div`
  width: 100%;
  height: 200px;
`;

export const Reason = styled.div`
  border: 1px solid ${colorInterfaceLightUp};
  border-radius: ${borderRadiusMd};
  p {
    margin: 0;
    padding: ${spacingInlineXxs} ${spacingInlineXs};
  }
`;

export const WrapperResumeValues = styled.div`
  .payment-data {
    position: relative;
    border: 1px solid ${colorInterfaceLightDown};
    border-top-left-radius: ${borderRadiusMd};
    border-top-right-radius: ${borderRadiusMd};
    padding: ${spacingInlineXxs} ${spacingInlineXs};
    margin-top: ${spacingInlineMd};
    display: flex;
    flex-direction: column;
    gap: ${spacingInlineXs};
  }

  .date-information {
    border-left: 1px solid ${colorInterfaceLightDown};
    border-right: 1px solid ${colorInterfaceLightDown};
    border-bottom: 1px solid ${colorInterfaceLightDown};
    padding: ${spacingInlineXs};
    border-bottom-left-radius: ${borderRadiusMd};
    border-bottom-right-radius: ${borderRadiusMd};
    color: ${colorInterfaceDarkUp};
  }

  @media ${devices.mobile} {
    padding: 0 ${spacingInlineXs};

    .payment-data {
      margin-top: ${spacingInlineXs};
    }
  }
`;

export const Bar = styled.div`
  margin-bottom: ${spacingInsetXxs};
  margin-top: ${spacingStackSm};
  width: 100%;

  .ods-topbar {
    flex-wrap: nowrap;
    height: auto;
  }

  .ods-topbar-title {
    margin: 0;
    font-size: ${fontSizeMd};
  }

  .ods-topbar-left {
    margin-left: 0;
  }

  @media ${Devices.mobile} {
    background-color: ${colorInterfaceLightPure};
    display: flex;
    left: 0;
    margin: 0;
    padding: 17px 16px;
    position: fixed;
    top: 0;
    z-index: 10;

    .ods-topbar {
      margin-bottom: 0;
      max-width: 540px;
    }

    .ods-topbar-title {
      font-size: ${fontSizeSm};
    }

    .ods-topbar-left {
      margin-left: 0;
      margin-right: ${spacingStackXs};
    }
  }
`;

export const StatusTag = styled(Tag)`
  position: absolute;
  right: 0;
  margin: ${spacingInlineXs} ${spacingStackSm};
`;

export const ResumeItem = styled.div`
  span {
    font-size: ${fontSizeXxs};
    font-weight: ${fontWeightRegular};
    margin: 0;
    color: ${colorInterfaceDarkDown};
  }
  p {
    margin: 0;
    font-size: ${fontSizeXs};
    color: ${colorInterfaceDarkPure};
    font-weight: ${fontWeightRegular};
  }

  &.value {
    p {
      font-size: ${fontSizeSm};
      font-weight: ${fontWeightBold};
    }
  }
`;

export const Container = styled.div`
  .pgnt-page {
    padding-top: ${spacingStackXs};

    & > div:first-of-type {
      border: 0;
    }
  }

  @media ${Devices.mobile} {
    background-color: ${colorInterfaceLightPure};
    min-height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
`;

export const GridContainer = styled(Grid.Container)`
  .ods-col-md-10 {
    padding: 0;
  }
`;

export const BreadcrumbStyle = createGlobalStyle`
  .light-main .dark-main-content .breadcrumbs {
    border: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 760px;
    width: 100%;
  }

  .breadcrumbs-container {
    margin-top: 32px;
    padding: 0;
  }

  @media ${Devices.notebook} {
    .light-main .dark-main-content .breadcrumbs {
      max-width: 638px;
    }
  }

  @media ${Devices.tablet} {
    .light-main .dark-main-content .breadcrumbs {
      max-width: 600px;
    }
  }
`;

export const TagStatusTransaction = styled(Tag)`
  margin-bottom: ${spacingInsetSm};
  margin-top: ${spacingInsetMd};
  padding: 3.5px ${spacingInsetXs};
  width: fit-content;

  @media ${Devices.mobile} {
    margin: ${spacingInlineXxl} ${spacingInsetSm} ${spacingInsetSm};
  }
`;
