import styled from 'styled-components';

import InputControl from 'components/Shared/InputControl';
import { Alert } from '@useblu/ocean-react';

import {
  colorStatusNegativePure,
  shadowLevel1,
  fontWeightBold,
  spacingInlineXs,
  colorStatusWarningDeep,
} from '@useblu/ocean-tokens/web/tokens';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`,
};

export const Bar = styled.div`
  box-shadow: ${shadowLevel1};
  padding: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;

  @media ${devices.notebook} {
    margin-bottom: 5rem;
  }

  .ods-topbar .ods-topbar-title span {
    display: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const Wrapper = styled.div`
  max-width: 328px;

  @media ${devices.notebook} {
    max-width: 554px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 16px;

  @media ${devices.notebook} {
    margin-bottom: 24px;
  }
`;

export const ErrorText = styled.p`
  color: red;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.submit ? 'flex-end' : 'center')};

  margin-bottom: 24px;

  font-size: ${(props) => (props.submit ? '16px' : '14px')};

  @media ${devices.notebook} {
    margin-bottom: ${(props) => (props.submit ? '80px' : '24px ')};
    font-size: ${(props) => (props.submit ? '16px' : '14px')};
  }
`;

export const CustomInput = styled(InputControl)`
  &&& {
    min-width: 434px;

    @media ${devices.tablet} {
      min-width: initial;
      width: 100%;
    }

    .token & {
      min-width: initial;
      height: 48px;
    }

    &.error {
      border-color: ${colorStatusNegativePure};
    }
  }
`;

export const AlertWrapper = styled(Alert)`
  &&& {
    margin-bottom: ${spacingInlineXs};

    span {
      color: ${colorStatusWarningDeep};
      font-weight: ${fontWeightBold};
    }
  }
`;
