import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../images/logo-blu.svg';

import { LogoHome } from './DeviseScreenLayout.styles';

const LogoBlu = ({ className, dataTestId }) => (
  <LogoHome
    className={className}
    data-testid={dataTestId}
    onClick={() => {
      window.location = '/';
    }}
  >
    <img className="logo" src={Logo} alt="logo blu" />
  </LogoHome>
);

LogoBlu.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string
};

LogoBlu.defaultProps = {
  className: '',
  dataTestId: ''
};

export default LogoBlu;
