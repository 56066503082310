import axios from 'axios';

export default class UsersForm {
  constructor(options) {
    this.options = options;
    this.setupEvents();
    this.onCpfCnpjComplete = this.onCpfCnpjComplete.bind(this);
    this.toggleFields(options.startOpen);
  }

  setupEvents() {
    this.onCpfCnpjComplete();
  }

  toggleFields(open) {
    $('[data-user-fields]').toggle(open);
    $('.is-loading').removeClass('is-loading');

    if (
      open &&
      $('#client_user_clients_attributes_0_user_attributes_id').length
    ) {
      $('#client_user_clients_attributes_0_user_attributes_id').remove();
      this.disableAttr();
    }
  }

  fillClientData(response) {
    if (!response.data.user) {
      return;
    }

    const {
      data: { user }
    } = response;

    $('[data-user-id]').val(user.id);
    $('[data-user-name]').val(user.name);
    $('[data-user-phone]').val(user.phone).trigger('keyup');
    $('[data-user-phone-2]').val(user.phone_2).trigger('keyup');
    $('[data-user-phone-3]').val(user.phone_3).trigger('keyup');
    $('[data-user-email]').val(user.email);
  }

  disableAttr() {
    $('[data-user-cpf]').attr(this.options.disableAttr, true);
    $('[data-store-id]').attr(this.options.disableAttr, true);
    $('[data-user-name]').attr(this.options.disableAttr, true);
    $('[data-user-phone]').attr(this.options.disableAttr, true);
    $('[data-user-phone-2]').attr(this.options.disableAttr, true);
    $('[data-user-phone-3]').attr(this.options.disableAttr, true);
    $('[data-user-email]').attr(this.options.disableAttr, true);
  }

  onCpfCnpjComplete() {
    const self = this;

    $('[data-user-cpf]').on('keyup', function onKeyUp() {
      if ($(this).val().length === 14 && $(this).val().indexOf('_') < 0) {
        const cpf = $(this).val().replace(/\W/g, '');

        $(this).parent().addClass('is-loading');

        axios({
          url: `/api/users/${cpf}`,
          method: 'get',
          headers: { Accept: 'version=1' },
          responseType: 'json'
        })
          .then((response) => {
            self.fillClientData(response);
            self.toggleFields(true);
            if (!!response.data.user) {
              self.disableAttr();
            }
          })
          .catch(() => {
            self.toggleFields(true);
          });
      }
    });
  }
}
