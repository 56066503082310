import React from 'react';
import PropTypes from 'prop-types';
import RangeSelector from './RangeSelector';

import { DateObject, range, indexInRange } from './DateHelper';

const DayDateFilter = ({ selected, onChange }) => {
  const initialDate = DateObject(selected);
  const dates = range(selected, 'days');
  const initialIndex = indexInRange(dates, initialDate);

  return (
    <RangeSelector
      selected={initialIndex}
      range={dates}
      item={(date) => (
        <>
          <span>{date.day}</span>
          <small>{date.month}</small>
        </>
      )}
      onChange={onChange}
    />
  );
};

DayDateFilter.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DayDateFilter;
