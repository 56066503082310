/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { connect } from 'react-redux';
import { compose } from 'redux';

/* Actions and Validations */
import usersUploadActions from 'store/ducks/firstAccess/usersUpload';
import { clientSelector } from 'selectors/FirstAccess/shared';

/* Presentational */
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import classNames from 'classnames';

class UsersUpload extends Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.state = {
      contract: true,
      circleContract: true,
      disabledButtonContract: true,
      errorContract: false,
      checkContract: false,
      contractName: ''
    };
  }

  uploadFile(e) {
    const { usersUploadSubmitRequest, uuid, client } = this.props;

    this.setState({
      contract: false,
      circleContract: false,
      disabledButtonContract: false
    });

    const file = e.target.files[0];

    if (!file || file.type !== 'application/pdf') {
      this.setState({
        contractName: 'Formato inválido! Tente novamente com um arquivo .pdf'
      });
    } else {
      usersUploadSubmitRequest(uuid, client, file);
      this.setState({
        contract: false,
        errorContract: true,
        disabledButtonContract: false,
        contractName: file.name
      });
    }
  }

  next() {
    window.location = `/lojas/${this.props.uuid}/dashboard`;
  }

  checkClientContractUpload() {
    const { client } = this.props;

    return !_.isNil(client.socialContract) && !_.isEmpty(client.socialContract);
  }

  buildClientDocumentStatus() {
    let {
      contract,
      circleContract,
      checkContract,
      disabledButtonContract,
      errorContract,
      contractName
    } = this.state;
    const { client } = this.props;

    if (client.socialContract || this.checkClientContractUpload()) {
      [contract, circleContract, disabledButtonContract, errorContract] = [
        false,
        false,
        false,
        false
      ];
      checkContract = true;
    }

    return {
      contract,
      circleContract,
      checkContract,
      disabledButtonContract,
      errorContract,
      contractName
    };
  }

  renderNextButton(checkContract) {
    const { submitting } = this.props;
    let textButton = 'Continuar';
    let buttonClass = 'button form-controls button--primary--small';

    if (!checkContract) {
      textButton = 'Enviar depois';
      buttonClass = 'button form-controls button--link';
    }

    const content = (
      <button
        id="btnNext"
        type="submit"
        onClick={this.next}
        className={buttonClass}
        disabled={submitting}
      >
        {textButton}
      </button>
    );

    return content;
  }

  renderUserForm() {
    const { client, handleSubmit, loading } = this.props;
    let content;

    if (!_.isEmpty(client)) {
      const {
        circleContract,
        checkContract,
        contract,
        errorContract,
        contractName
      } = this.buildClientDocumentStatus();

      const loaderContract = classNames({
        'fa fa-arrow-circle-up': circleContract,
        'fa fa-spinner fa-pulse fa-fw': loading.upload,
        'fa fa-check': checkContract,
        'fa fa-times': errorContract
      });

      const contractUploadState = classNames({
        valid: checkContract,
        invalid: errorContract && !loading.upload
      });

      content = (
        <form
          className="form-fields-for"
          encType="multipart/form-data"
          onSubmit={handleSubmit(this.next)}
          noValidate
        >
          <h1 className="page-title">
            <i className="fa fa-paperclip" /> Envio de contrato Social ou
            Requerimento do Empresário (MEI)
          </h1>

          <p className="page-intro">
            <strong>
              Contrato Social ou Requerimento do Empresário (MEI) (
              {client.name.toUpperCase()})
            </strong>
            <br />
            Para prosseguir, anexe o contrato social ou Requerimento do
            Empresário (MEI) da sua empresa no formato PDF.
          </p>

          <div className="form-row">
            <div className="form-controls">
              <div className="file-upload">
                <input
                  type="file"
                  id="contract_upload"
                  name="contract_upload"
                  accept="application/pdf"
                  onChange={(e) => this.uploadFile(e)}
                />
                <label htmlFor="contract_upload">
                  <div className="file-upload--info">
                    <span className="file-upload--title">
                      Clique e escolha o arquivo que deseja enviar
                    </span>
                    <span className="file-upload--description">
                      {contractName}
                    </span>
                  </div>
                  <div className={`file-upload--icon ${contractUploadState}`}>
                    <i className={loaderContract} />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="form-row actions">
            {this.renderNextButton(checkContract)}
          </div>
        </form>
      );
    }
    return content;
  }

  render() {
    const { client, clientLoaded } = this.props;

    const currentClient = client;

    if (clientLoaded) {
      currentClient.socialContract = clientLoaded.socialContract;
    }

    if (client.social_contract.url) {
      currentClient.socialContract = true;
    }

    return <div className="flex-container">{this.renderUserForm()}</div>;
  }
}

UsersUpload.propTypes = {
  uuid: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  usersUploadSubmitRequest: PropTypes.func.isRequired,
  loading: PropTypes.object.isRequired,
  clientLoaded: PropTypes.shape({})
};

UsersUpload.defaultProps = {
  submitting: false,
  clientLoaded: undefined
};

const mapStateToProps = (state) => ({
  clientLoaded: clientSelector(state),
  errors: state.firstAccessUsersUpload.errors,
  modal: state.firstAccessUsersUpload.modal,
  email: state.firstAccessUsersUpload.email,
  loading: state.firstAccessUsersUpload.loading,
  user: state.firstAccessUsersUpload.user
});

const mapDispatchToProps = (dispatch) => ({
  usersUploadSubmitRequest: (uuid, client, file) =>
    dispatch(
      usersUploadActions.firstAccessUsersUploadSubmitRequest(uuid, client, file)
    ),
  toggleModal: () =>
    dispatch(usersUploadActions.firstAccessUsersUploadToggleModal())
});

const UsersUploadReduxForm = reduxForm({
  form: 'usersUploadForm'
});

export { UsersUpload };

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  UsersUploadReduxForm
)(UsersUpload);
