import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import useModalManagement from 'components/Dashboard/hooks/useModalManagement';

const useModalTriggers = () => {
  const {
    firstLoginFromAutomaticRegistration,
    economicGroupView,
    clientId,
    legalRepresentative,
  } = useSelector((state) => state.railsContext);

  const { openModal, isModalOpen } = useModalManagement();

  let isOnHome = false;

  if (window?.location?.pathname) {
    isOnHome = window.location.pathname.includes('extrato/pendentes');
  }

  const hasVisualizedModalBefore =
    Boolean(sessionStorage.getItem(`reception_modal_visualized${clientId}`)) ===
    true;

  useEffect(() => {
    if (
      isOnHome &&
      firstLoginFromAutomaticRegistration &&
      legalRepresentative &&
      !hasVisualizedModalBefore &&
      !isModalOpen &&
      !economicGroupView
    ) {
      sessionStorage.setItem(`reception_modal_visualized${clientId}`, 'true');
      openModal('ContractSigning');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoginFromAutomaticRegistration, isModalOpen, economicGroupView]);
};

export default useModalTriggers;
