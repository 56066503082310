import React from 'react';
import PropTypes from 'prop-types';

import ScoreCardChargedLayout from './ScoreCardChargedLayout';
import ScoreIndicator from '../../ScoreIndicator';
import { ShortMessage, ActionButton } from './Styles';

const ScoreCardChargedError = ({ retryFn }) => (
  <ScoreCardChargedLayout>
    <ScoreIndicator error />
    <ShortMessage>Não foi possível carregar esta informação.</ShortMessage>
    <ActionButton type="button" onClick={retryFn}>
      Recarregar
    </ActionButton>
  </ScoreCardChargedLayout>
);

ScoreCardChargedError.propTypes = {
  retryFn: PropTypes.func.isRequired
};

export default ScoreCardChargedError;
