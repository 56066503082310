import masker from 'vanilla-masker';
import _ from 'lodash';

function handlePatternToApply(input, mask, type) {
  if (input.value.length === 14 && type === 'cpf_cnpj') {
    return masker(input).maskPattern(mask[1]);
  }
  return masker(input).maskPattern(mask[0]);
}

function setupMultipleMask(input, mask, type) {
  const inputHandler = (masks, max, event) => {
    const c = event.target;
    const v = c.value.replace(/\D/g, '');
    const m = c.value.length > max ? 1 : 0;
    masker(c).unMask();
    masker(c).maskPattern(masks[m]);
    c.value = masker.toPattern(v, masks[m]);
  };

  handlePatternToApply(input, mask, type);
  input.addEventListener(
    'keyup',
    inputHandler.bind(undefined, mask, 14),
    false
  );
}

export const masks = {
  cpf: '999.999.999-99',
  cnpj: '99.999.999/9999-99',
  cpf_cnpj: ['999.999.999-99', '99.999.999/9999-99'],
  phone: '(99) 99999-9999',
  zipcode: '99999-999',
  barCode: '99999.99999 99999.999999 99999.999999 9 99999999999999',
  barCodeUtilities: '999999999999 999999999999 999999999999 999999999999',
  date: '99/99/9999',
  number: '0',
  currency: {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: ''
  },
  percentage: {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: '',
    suffixUnit: '%'
  },
  darf: {
    referenceNumber: '99 9 99 999999-99'
  },
  bank: {
    noop: {
      branch: '9999',
      account: '999999999999-9'
    },
    1: { branch: '9999', account: '99999999-9' },
    33: { branch: '9999', account: '99999999-9' },
    237: { branch: '9999', account: '9999999-9' },
    341: { branch: '9999', account: '99999-9' },
    104: { branch: '9999', account: '999999999-9' },
    399: { branch: '9999', account: '999999-9' },
    745: { branch: '9999', account: '9999999-9' },
    41: { branch: '9999', account: '999999999-9' },
    213: { branch: '9999', account: '99999-9' }
  }
};

export function getMask(type) {
  return _.get(masks, type);
}

export default function maskInput(input, type) {
  const mask = getMask(type);
  if (!mask) return;
  masker(input).unMask();

  switch (type) {
    case 'currency': {
      masker(input).maskMoney(mask);
      break;
    }
    case 'percentage': {
      masker(input).maskMoney(mask);
      break;
    }
    case 'phone': {
      masker(input).maskPattern(mask);
      break;
    }
    case 'zipcode': {
      masker(input).maskPattern(mask);
      break;
    }
    case 'number': {
      masker(input).maskNumber();
      break;
    }
    default: {
      if (type.match(/bank/)) {
        masker(input).maskPattern(mask);
        break;
      } else if (mask instanceof Array) {
        setupMultipleMask(input, mask, type);
        break;
      } else {
        masker(input).maskPattern(mask);
        break;
      }
    }
  }
}

export function maskValue(value = '', type) {
  const mask = getMask(type);
  if (!mask) return;

  const inputValue = value.replace(/\D/g, '');

  switch (type) {
    case 'phone': {
      return masker.toPattern(inputValue, '(99) 99999-9999');
    }
    case 'cpf_cnpj': {
      if (inputValue.length <= 11) {
        return masker.toPattern(inputValue, mask[0]);
      }
      return masker.toPattern(inputValue, mask[1]);
    }
    case 'cpf': {
      return masker.toPattern(inputValue, '999.999.999-99');
    }
    case 'cnpj': {
      return masker.toPattern(inputValue, '99.999.999/9999-99');
    }
    case 'currency': {
      return masker.toMoney(inputValue, mask);
    }
    case 'percentage': {
      return masker.toMoney(inputValue, mask);
    }
    case 'number': {
      return masker.toNumber(inputValue);
    }
    default: {
      return masker.toPattern(inputValue, mask);
    }
  }
}

export function maskCpf(cpf) {
  if (!cpf) {
    return '';
  }
  return masker.toPattern(cpf, masks.cpf);
}

export function maskCpfOrCnpj(cpfCnpj) {
  if (!cpfCnpj) {
    return '';
  }

  if (cpfCnpj.length <= 11) {
    return masker.toPattern(cpfCnpj, masks.cpf_cnpj[0]);
  }

  return masker.toPattern(cpfCnpj, masks.cpf_cnpj[1]);
}

export function maskComplete(value, type) {
  const mask = getMask(type);
  return value && mask && value.length === mask.length;
}

export function maskHintBankAccount(compensationCode) {
  const mask = getMask('bank');
  const element = _.pick(mask, _.toString(compensationCode));
  let account = '';
  let maskAccount = '';

  if (_.isEmpty(element)) {
    account = mask.noop.account;
  } else {
    account = mask[compensationCode].account;
  }

  maskAccount = _.map(account, (value) => (value === '-' ? '-' : 'X'));

  return maskAccount.join('');
}
