import styled from 'styled-components';
import {
  colorInterfaceDarkDeep,
  colorBrandPrimaryPure,
  fontWeightExtrabold,
  colorBrandPrimaryDeep,
  colorInterfaceLightPure
} from '@useblu/ocean-tokens/web/tokens';

export const ClientContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const Client = styled.div`
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 48px;
  padding: ${(props) => (props.economicGroupView ? '0px' : '15px 16px')};
  color: ${(props) =>
    props.economicGroupView ? colorBrandPrimaryDeep : colorInterfaceDarkDeep};

  span {
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
    max-width: 314px;
    min-width: 100px;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 44px;

    font-weight: ${fontWeightExtrabold};
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
    padding: 0px;

    i {
      font-size: 18px;
    }

    i,
    span {
      color: ${(props) =>
        props.economicGroupView
          ? colorBrandPrimaryDeep
          : colorBrandPrimaryPure};
    }

    span {
      max-width: 100%;
      font-size: 20px;
      margin-right: 8px;
    }
  }
`;

export const Nav = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px 4px;

  ${Client} {
    span {
      -webkit-line-clamp: unset;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0px;
  }
`;

export const Menu = styled.ul`
  text-align: center;

  li {
    a {
      padding: 12px 16px;
      min-height: 56px;
      height: auto;
      text-align: left;
    }
  }
`;

export const UserGroupWrapper = styled.div`
  color: ${colorInterfaceLightPure};
  background: ${colorBrandPrimaryDeep};
  border-radius: 50%;
  margin-right: 8px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    path,
    rect {
      stroke: none !important;
    }
  }
`;
