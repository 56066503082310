/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import moment from 'utils/moment.pt-br';
import MomentLocaleUtils from 'react-day-picker/moment';
import DayPicker, { DateUtils } from 'react-day-picker';

export default class DatePickerInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.props.default,
      showDatePicker: false
    };
  }

  toggleDatePicker() {
    const { showDatePicker } = this.state;

    this.setState({
      showDatePicker: !showDatePicker
    });
  }

  handleDayClick(e, day) {
    this.setState({
      date: moment(day).format('L'),
      showDatePicker: false
    });
  }

  render() {
    const { label, id, name } = this.props;
    const { date, showDatePicker } = this.state;
    const selectedDay = moment(date, 'L', true).toDate();

    return (
      <div className="form-row">
        <div className="form-controls">
          <label htmlFor={id}>{label}</label>
          <input
            type="text"
            name={name}
            id={id}
            defaultValue={date}
            onClick={this.toggleDatePicker.bind(this)}
            required="true"
          />

          {showDatePicker && (
            <DayPicker
              enableOutsideDays
              locale="pt-BR"
              className="DayPicker--single"
              localeUtils={MomentLocaleUtils}
              selectedDays={(day) => DateUtils.isSameDay(selectedDay, day)}
              onDayClick={this.handleDayClick.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}

DatePickerInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  default: PropTypes.string
};
