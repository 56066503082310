import styled from 'styled-components';
import { Grid, Typography } from '@useblu/ocean-react';
import {
  spacingStackLg,
  spacingStackMd,
  spacingInlineXs,
  spacingInlineLg,
  fontSizeMd,
  colorBrandPrimaryPure,
  colorInterfaceLightUp,
  fontSizeLg,
  spacingStackSm
} from '@useblu/ocean-tokens/web/tokens';

const mediaScreen = {
  xs: `@media (max-width: 480px)`,
  sm: `@media (min-width: 600px)`,
  md: `@media (min-width: 992px)`,
  lg: `@media (min-width: 993px)`
};

export const Wrapper = styled.div`
  padding: ${spacingStackMd} ${spacingStackLg};
  margin-top: ${spacingStackMd};
  height: 100%;

  ${mediaScreen.xs} {
    padding: ${spacingStackMd};
    height: auto;
    margin-top: ${spacingStackMd};
  }
`;

export const GridThumbnail = styled(Grid.Col)`
  ${mediaScreen.xs} {
    order: 1;
  }
`;

export const GridBody = styled(Grid.Col)``;

export const Title = styled(Typography).attrs(() => ({
  variant: 'heading3'
}))`
  &&& {
    margin: ${spacingInlineXs} 0px;
    color: ${colorBrandPrimaryPure};
    font-size: ${fontSizeMd};
  }
`;

export const Thumbnail = styled.img`
  margin-top: ${spacingInlineLg};
`;

export const List = styled.ul`
  margin: 0;
`;

export const Icon = styled.div`
  color: ${colorBrandPrimaryPure};
  background: ${colorInterfaceLightUp};
  display: flex;
  border-radius: 50%;
  align-items: center;
  padding: 8px 0px;
  width: 65px;
  justify-content: center;
  font-weight: 900;
  margin-right: ${spacingInlineXs};
  font-size: ${fontSizeLg};

  ${mediaScreen.xs} {
    margin: 0 auto;
  }
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-top: ${spacingStackSm};
  justify-content: space-between;

  ${mediaScreen.xs} {
    flex-wrap: wrap;
  }
`;

export const Description = styled.div`
  flex: 1;
  color: #67697a;
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  span {
    font-weight: 900;
    margin-left: 5px;
  }

  ${mediaScreen.xs} {
    text-align: center;
    flex: auto;
  }
`;
