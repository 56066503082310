import axios from 'axios';

export function requestNewPassword(params) {
  const data = new FormData();

  data.append('user[email]', params.email.toLowerCase());

  return axios.post(`/users/password`, data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
}

export default requestNewPassword;
