import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-modal';

const CustomModal = ({ isOpen, title, actions, width }) => (
  <Modal
    isOpen={isOpen}
    contentLabel="Modal"
    className={{
      afterOpen: 'modal--open',
      base: 'modal',
      beforeClose: 'modal'
    }}
    style={{ content: { width } }}
    overlayClassName={{
      afterOpen: 'modal-overlay--open',
      base: 'modal-overlay',
      beforeClose: 'modal-overlay'
    }}
    ariaHideApp={false}
  >
    <h2>{title}</h2>
    <div className="modal-action">
      {actions.map(({ text, extraClassName, onClick }) => (
        <button
          key={text}
          className={classNames('button', extraClassName)}
          onClick={onClick}
          type="button"
        >
          {text}
        </button>
      ))}
    </div>
  </Modal>
);

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      extraClassName: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      text: PropTypes.string.isRequired
    })
  )
};

CustomModal.defaultProps = {
  title: '',
  width: 'auto',
  actions: []
};

export default CustomModal;
