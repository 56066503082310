import React from 'react';

import { Typography } from '@useblu/ocean-react';

import smartphoneImg from '../images/smartphone.svg';
import desktopImg from '../images/desktop.svg';
import {
  ItemTitle,
  HowToUseContainer,
  ItemContainer,
  StyledH3,
  StyledParagraph,
  Option,
  DesktopInfo
} from './HowToUseStyles';
import Accordion from 'components/Shared/Accordion';

const SmartphoneParagraph = ({ mobile }) => (
  <StyledParagraph variant="paragraph" mobile={mobile}>
    Realizar suas transações pelo aplicativo é muito fácil, a etapa de validação
    acontece de forma automática. Você não precisa digitar nenhum código, o
    bToken garante a segurança na autenticação.
  </StyledParagraph>
);

const DesktopParagraph = ({ mobile }) => (
  <DesktopInfo mobile={mobile}>
    <Typography variant="paragraph">
      Existem duas maneiras de usar o bToken para validar suas transações no
      computador:
    </Typography>
    <ol>
      <Option>
        <Typography variant="paragraph">
          <strong>1.</strong> Autorizando direto no celular. Se as notificações
          estiverem habilitadas no seu aplicativo, você receberá uma notificação
          no celular com a opção ”autorizar”. Basta selecionar essa opção e a
          validação acontecerá de forma automática.
        </Typography>
      </Option>
      <Option>
        <Typography variant="paragraph">
          <strong>2.</strong> Usando o código gerado no app. Clique no botão
          “bToken” que aparece na tela inicial do aplicativo e digite o código
          no portal. O código é dinâmico e constantemente atualizado para
          garantir mais segurança e pode ser usado sem conexão com a internet.
        </Typography>
      </Option>
    </ol>
  </DesktopInfo>
);

export default function HowToUse() {
  return (
    <HowToUseContainer>
      <StyledH3 variant="heading3"> Como usar o bToken?</StyledH3>

      <ItemContainer>
        <ItemTitle>
          <img src={smartphoneImg} alt="Smartphone" />
          <div>
            <Typography variant="heading4">No aplicativo no celular</Typography>
            <SmartphoneParagraph />
          </div>
        </ItemTitle>
        <SmartphoneParagraph mobile="true" />
      </ItemContainer>

      <ItemContainer>
        <ItemTitle>
          <img src={desktopImg} alt="Desktop" />
          <div>
            <Typography variant="heading4">No computador</Typography>
            <DesktopParagraph />
          </div>
        </ItemTitle>
        <DesktopParagraph mobile="true" />
      </ItemContainer>

      <StyledH3 variant="heading3">Mais informações</StyledH3>
      <Accordion
        title="Posso ativar o bToken em mais de um celular?"
        description=" Por segurança, o bToken só pode estar ativo em um único dispositivo
      por usuário. Se você trocar de celular ou precisar ativar o bToken em
      um novo dispositivo, o antigo será desabilitado."
      />

      <Accordion
        title="Para confirmar transações com o bToken, o aplicativo precisa estar
        conectado à internet?"
        description="Quando você estiver realizando transações no portal e for solicitado o
        código bToken, você pode consultá-lo no app sem precisar que o celular
        esteja conectado à internet. A geração de código dinâmico no
        aplicativo funciona no modo offline."
      />
      <Accordion
        title="Como posso cancelar o bToken?"
        description="Você pode desativar o bToken a qualquer momento ou em caso de perda ou
        roubo do celular. Acesse sua conta pelo portal ou pelo aplicativo e
        selecione a opção “bToken” no menu. Será apresentado o seu dispositivo
        autorizado e a opção de desativar. Basta clicar nesta opção e pronto,
        o bToken será cancelado. Por segurança, só é possível ativá-lo
        novamente no mesmo dispositivo após um período de 24 horas."
      />
    </HowToUseContainer>
  );
}
