import React from 'react';
import PropTypes from 'prop-types';

import { AccordionSummaryContent } from './styles';

import expandIcon from './images/expand.svg';
import minimizeIcon from './images/minimize.svg';

export const AccordionSummary = ({ title, open, toggleAccordion }) => (
  <AccordionSummaryContent onClick={() => toggleAccordion(!open)} role="button">
    <p>{title}</p>
    <img
      src={open ? minimizeIcon : expandIcon}
      alt={`icon-${open ? 'minimize' : 'expand'}`}
    />
  </AccordionSummaryContent>
);

AccordionSummary.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleAccordion: PropTypes.func.isRequired
};

export default AccordionSummary;
