import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  advancementRequestRequest: ['advancementRequestUuids', 'clientUuid'],
  advancementRequestSuccess: ['data'],
  advancementRequestFailure: null
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  loading: false,
  error: false,
  data: {}
};

export const request = (state) => ({
  ...state,
  loading: true
});

export const success = (state, action) => ({
  ...state,
  loading: false,
  data: action.data
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ADVANCEMENT_REQUEST_REQUEST]: request,
  [Types.ADVANCEMENT_REQUEST_SUCCESS]: success,
  [Types.ADVANCEMENT_REQUEST_FAILURE]: failure
});
