import Masker from 'vanilla-masker';
import moment from 'moment';

export function currencyToNumber(value) {
  if (value.replace) {
    value = value.replace(/\./g, '').replace(',', '.');
  }

  value = parseFloat(value);

  return value.toFixed(2);
}

export function extractDateProps(date) {
  if (!date) return {};

  const regex = /(\d{4}).+(\d{2}).+(\d{2})T(\d{2}):(\d{2})/gm;
  const [match, year, month, day, hour, minute] = regex.exec(date);

  return {
    full: match,
    year: Number(year),
    month: Number(month),
    day: Number(day),
    hour: Number(hour),
    minute: Number(minute),
  };
}

export function normalizeSeparator(value, fixed = true) {
  let newValue = value;

  if (fixed) {
    newValue = parseFloat(value).toFixed(2);
  }

  return newValue ? `${newValue.toString().replace('.', ',')}` : value;
}

export function normalizeCurrency(value = '0.00', isAbsolute = true) {
  const newValue = typeof value === 'string' ? value : value.toFixed(2);
  const currency = Masker.toMoney(newValue, {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: '',
  });

  if (isAbsolute) return currency;

  const numberSign = Math.sign(value) === -1 ? '-' : '';
  return `${numberSign}${currency}`;
}

export const normalizeValue = (value) => {
  const transformedValue = normalizeCurrency(parseFloat(value), true);
  if (value >= 0) {
    return `R$ ${transformedValue}`;
  }
  return `- R$ ${transformedValue}`;
};

export function normalizeDate(value) {
  if (!value) {
    return null;
  }

  return moment(value, 'DD/MM/YYYY').toDate();
}

export function formatDate(value, format) {
  if (!value) {
    return null;
  }
  return moment(value).format(format || 'DD/MM/YYYY');
}

export function formatDateToUS(value) {
  if (!value) {
    return null;
  }
  return moment(value).format('MM/DD/YYYY');
}

export function chartValue(value) {
  const valueAux = String(value);

  if (valueAux.length > 3) {
    let count = 0;

    for (let index = 0; index < valueAux.length; index += 1) {
      const char = valueAux[index];
      if (char === '0') {
        count += 1;
      }
    }

    if (count < 3 && valueAux.length < 4) {
      return value;
    }
    if (count < 6 && valueAux.length < 7) {
      if (count < 3) {
        const retorno = valueAux.substring(0, valueAux.length - count);
        const ret = String(parseFloat(retorno) * 0.1);
        if (ret.includes('.') && ret.length > 3) {
          return `${parseFloat(ret).toFixed(1)} mil`;
        }

        return `${parseFloat(retorno) * 0.1} mil`;
      }
      return `${valueAux.substring(0, valueAux.length - 3)} mil`;
    }
    if (count < 9 && valueAux.length < 10) {
      if (count < 6) {
        const retorno = valueAux.substring(0, valueAux.length - count);
        const ret = String(parseFloat(retorno) * 0.1);
        if (ret.includes('.') && ret.length > 3) {
          return `${parseFloat(ret).toFixed(1)} mi`;
        }

        return `${parseFloat(retorno) * 0.1} mi`;
      }
      return `${valueAux.substring(0, valueAux.length - 6)} mi`;
    }
    if (count < 12 && valueAux.length < 13) {
      if (count < 9) {
        const retorno = valueAux.substring(0, valueAux.length - count);
        return `${parseFloat(retorno) * 0.1} bi`;
      }
      return `${valueAux.substring(0, valueAux.length - 9)} bi`;
    }

    if (count < 12) {
      const retorno = valueAux.substring(0, valueAux.length - count);
      return `${parseFloat(retorno) * 0.1} tri`;
    }

    return `${valueAux.substring(0, valueAux.length - 12)} tri`;
  }

  return value;
}
