import React from 'react';

import cellPhoneImg from './images/cell-phone.svg';
import googlePlayImg from './images/googlePlay.svg';
import appleStoreImg from './images/appleStore.svg';

import {
  HeaderContainer,
  HeaderImage,
  HeaderInfo,
  TextBalloon,
  HeaderTitle,
  HeaderSubTitle,
  DownloadSection,
  WhatsSpotlight,
  HeaderImageContainer,
  DownloadSectionMobile
} from './styles';

const Header = () => (
  <HeaderContainer>
    <HeaderInfo>
      <div>
        <TextBalloon>Você pediu e ele voltou!</TextBalloon>
        <HeaderTitle>Blu Venda +</HeaderTitle>
        <HeaderSubTitle>O link de pagamento da Blu.</HeaderSubTitle>

        <DownloadSection>
          <p>Baixe no seu celular escolhendo uma opção abaixo:</p>
          <a
            href="https://apps.apple.com/br/app/blu-by-sellbie/id1504781499"
            target="_blank"
            rel="noreferrer"
          >
            <img src={appleStoreImg} alt="apple store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.blubysellbie"
            target="_blank"
            rel="noreferrer"
          >
            <img src={googlePlayImg} alt="play store" />
          </a>
        </DownloadSection>
      </div>
    </HeaderInfo>
    <HeaderImageContainer>
      <HeaderImage>
        <img src={cellPhoneImg} alt="whatsapp" />
      </HeaderImage>
      <WhatsSpotlight>
        <p>
          Venda muito mais <br />
          <span>via WhatsApp</span>
        </p>
      </WhatsSpotlight>
    </HeaderImageContainer>
    <DownloadSectionMobile>
      <p>Baixe no seu celular escolhendo uma opção abaixo:</p>
      <a
        href="https://apps.apple.com/br/app/blu-by-sellbie/id1504781499"
        target="_blank"
        rel="noreferrer"
      >
        <img src={appleStoreImg} alt="apple store" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.blubysellbie"
        target="_blank"
        rel="noreferrer"
      >
        <img src={googlePlayImg} alt="play store" />
      </a>
    </DownloadSectionMobile>
  </HeaderContainer>
);

export default Header;
