import React from 'react';
import PropTypes from 'prop-types';

import ReadOnlyField from 'components/Application/ReadOnlyField';
import Collapse from '../Collapse';

class ReadOnlyFieldCollapse extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    fields: PropTypes.array,
    openLabel: PropTypes.string
  };

  static defaultProps = {
    fields: [],
    openLabel: 'ver detalhes'
  };

  renderFieldsContainer() {
    const { fields } = this.props;

    const fieldItems = fields.map((field, i) =>
      field.value ? (
        <ReadOnlyField
          key={i}
          label={field.label}
          value={field.value}
          path={field.path}
        />
      ) : null
    );

    return (
      <div
        className="detail__content"
        ref={(ref) => {
          this.containerRef = ref;
        }}
      >
        <h4 className="detail__title">Detalhes da conta</h4>
        {fieldItems}
      </div>
    );
  }

  render() {
    const { label, value, fields, openLabel } = this.props;

    const collapse =
      fields.length > 0 ? (
        <Collapse
          getContainerRef={() => this.containerRef}
          arrowColor="#8698bc"
          openLabel={openLabel}
        />
      ) : null;

    return (
      <div>
        <div className="form-row">
          <ReadOnlyField label={label} value={value}>
            {collapse}
          </ReadOnlyField>
        </div>

        {fields.length > 0 && this.renderFieldsContainer()}
      </div>
    );
  }
}

export default ReadOnlyFieldCollapse;
