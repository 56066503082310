import { Typography } from '@useblu/ocean-react';
import {
  spacingInlineLg,
  spacingInlineSm,
  spacingInsetXl,
  spacingStackMd,
  spacingStackSm,
  spacingStackXxs
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  tablet: `(max-width: 1024px)`
};

const segmentMaxWidth = `744px`;

export const HowToUseContainer = styled.div`
  max-width: ${segmentMaxWidth};
  padding: ${spacingInsetXl} 0;
  margin: 0 auto;
`;

export const StyledH3 = styled(Typography)`
  padding-bottom: ${spacingStackMd};

  @media ${devices.mobile} {
    padding-bottom: ${spacingStackSm};
  }
`;

export const ItemContainer = styled.div`
  margin-bottom: 48px;
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media ${devices.mobile} {
    align-items: center;
    margin-bottom: ${spacingStackSm};
  }
  > img {
    margin-right: ${spacingInlineLg};
    @media ${devices.mobile} {
      margin-right: ${spacingInlineSm};
      max-width: 40px;
    }
  }

  h4 {
    @media ${devices.notebook} {
      padding-bottom: ${spacingStackXxs};
    }
  }
`;

export const StyledParagraph = styled(Typography)`
  display: ${(props) => (props.mobile ? 'none' : 'block')};
  @media ${devices.mobile} {
    display: ${(props) => (props.mobile ? 'block' : 'none')};
  }
`;

export const DesktopInfo = styled.div`
  display: ${(props) => (props.mobile ? 'none' : 'block')};
  @media ${devices.mobile} {
    display: ${(props) => (props.mobile ? 'block' : 'none')};
  }
`;

export const Option = styled.li`
  margin-top: ${spacingStackSm};
`;
