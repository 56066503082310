import React from 'react';
import styled from 'styled-components';

const Sign = styled.em`
  display: flex;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  text-align: center;
  align-items: center;

  svg {
    display: block;
    line-height: 20px;
    font-size: 20px;
    font-weight: bold;
    text-transform: none;
    width: 24px;
  }
`;

export const PlusSign = () => (
  <Sign>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99935 1.66699V12.3337M12.3327 7.00033H1.66602H12.3327Z"
        stroke="#EBECF5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Sign>
);

export const EqualsSign = () => (
  <Sign>
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3327 7H1.66602"
        stroke="#EBECF5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3327 1H1.66602"
        stroke="#EBECF5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Sign>
);
