import React, { useEffect } from 'react';
import { Field } from 'formik';
import ReactGA from 'react-ga';

import {
  FormRow,
  FormTitle,
  StepTitle
} from 'components/RPARegisterForm/RPARegisterForm.styles';
import secondStep from 'components/RPARegisterForm/assets/step-2.svg';
import TextField from '../TextField';
import MaskField from '../MaskField';

export default function DocumentsStep() {
  useEffect(() => {
    ReactGA.event({
      category: 'rpacadastro',
      action: 'rpa_documents_view'
    });
  }, []);

  return (
    <div id="rpa_documents_view">
      <img src={secondStep} alt="Primeiro passo" />
      <FormTitle variant="heading2">Complete seus dados</FormTitle>

      <StepTitle variant="heading4">
        Agora preencha os seguintes documentos.
      </StepTitle>
      <FormRow>
        <Field name="pisNumber">
          {({ field, meta }) => (
            <MaskField
              {...field}
              label="Número do PIS"
              placeholder="Informe o número do PIS"
              mask="999.99999.99-9"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Field name="personalIdNumber">
          {({ field, meta }) => (
            <MaskField
              {...field}
              label="Documento de identidade"
              placeholder="Informe o n° da identidade"
              mask="99.999.999-*"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
            />
          )}
        </Field>
        <Field name="personalIdNumberEmitter">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Órgão emissor"
              placeholder="Informe o órgão emissor"
              maxLength="20"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
            />
          )}
        </Field>
      </FormRow>
    </div>
  );
}
