import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { formatDate } from 'normalizers';
import { formatPrice } from 'components/AccountStatement/helpers/currency';
import PageView from 'components/AccountStatement/components/PageView';
import * as S from 'components/AccountStatement/screens/DARFScreen/styles';
import { Button, Tag, TopBar } from '@useblu/ocean-react';
import { ArrowLeftOutline } from '@useblu/ocean-icons-react';
import { normalizeMoney } from '@useblu/utils';
import useAdvancementDetailsDrawer from 'components/Shared/AdvancementDetailsDrawer/hooks/useAdvancementDetailsDrawer';
import accountStatementStatus from './accountStatementStatus';

const DARFScreen = ({
  title,
  value,
  date,
  feePayment,
  transactionUuid,
  status,
  statusName,
  proofPath,
  proofEnabled,
  withdrawalRateValue,
  rateValue,
  hasAdvancementRateValue,
  advancementRateValue,
}) => {
  const isMobile = useMedia('(max-width: 768px)');

  const { openDrawer } = useAdvancementDetailsDrawer();

  return (
    <S.Container>
      <S.BreadcrumbStyle />
      <PageView
        title={title}
        backLink="/"
        header={
          <>
            <S.Bar>
              <TopBar
                id="TopBar-playground"
                variants="default"
                leftIcon={isMobile ? <ArrowLeftOutline /> : null}
                title={title}
                description="DARF"
              />
            </S.Bar>
          </>
        }
      >
        <Tag
          className={`statement-detail--bg-${status}`}
          type={accountStatementStatus(status)}
          setIconOff
        >
          {statusName}
        </Tag>
        <S.WrapperResumeValues>
          <div className="payment-data">
            <S.ResumeValuesSection>
              <S.ResumeValuesItem>
                <p>Valor total</p>
                <p>
                  <b>{formatPrice(value)}</b>
                </p>
              </S.ResumeValuesItem>
              <S.ResumeValuesItem>
                <p>Data do pagamento</p>
                <p>{formatDate(date)}</p>
              </S.ResumeValuesItem>
            </S.ResumeValuesSection>
            <S.ResumeValuesSection>
              <S.ResumeValuesItem>
                <p>Nome</p>
                <p>{feePayment.name}</p>
              </S.ResumeValuesItem>
              <S.ResumeValuesItem>
                <p>Período de apuração</p>
                <p>{formatDate(feePayment.reference_date)}</p>
              </S.ResumeValuesItem>
              <S.ResumeValuesItem>
                <p>CPF ou CNPJ</p>
                <p>{feePayment.cpf_cnpj}</p>
              </S.ResumeValuesItem>
              <S.ResumeValuesItem>
                <p>Código da receita</p>
                <p>{feePayment.treasury_revenue_code}</p>
              </S.ResumeValuesItem>
              <S.ResumeValuesItem>
                <p>Número de referência</p>
                <p>
                  {feePayment.reference_number
                    ? feePayment.reference_number
                    : '--'}
                </p>
              </S.ResumeValuesItem>
            </S.ResumeValuesSection>
            <S.ResumeValuesSection>
              <S.ResumeValuesItem>
                <p>Código de autenticação</p>
                <p>{transactionUuid}</p>
              </S.ResumeValuesItem>
            </S.ResumeValuesSection>
          </div>
          <div className="date-information">
            Operação realizada em {moment(date).format('LLL')}
          </div>
        </S.WrapperResumeValues>

        {(rateValue > 0 ||
          withdrawalRateValue > 0 ||
          hasAdvancementRateValue) && (
          <S.PaymentCostsContainer>
            <S.PaymentCostsHeader>Custos do pagamento</S.PaymentCostsHeader>
            <S.Table>
              <tbody>
                {rateValue > 0 && (
                  <S.TableRow>
                    <td>
                      <p>Tarifas</p>
                    </td>
                    <td>
                      <p>{normalizeMoney(rateValue)}</p>
                    </td>
                  </S.TableRow>
                )}
                {withdrawalRateValue > 0 && (
                  <S.TableRow>
                    <td>
                      <p>Repasse</p>
                    </td>
                    <td>
                      <p>{normalizeMoney(withdrawalRateValue)}</p>
                    </td>
                  </S.TableRow>
                )}
                {hasAdvancementRateValue && (
                  <S.TableRow>
                    <td>
                      <p>Taxa de antecipação</p>
                    </td>
                    <td>
                      <p>{normalizeMoney(advancementRateValue)}</p>
                    </td>

                    <td style={{ verticalAlign: 'middle' }}>
                      <S.LinkButton
                        variant="text"
                        onClick={() => openDrawer(transactionUuid)}
                        data-track="DetalhesDARF_Link_AntecipacaoPontual"
                      >
                        Ver detalhes
                      </S.LinkButton>
                    </td>
                  </S.TableRow>
                )}
              </tbody>
            </S.Table>
          </S.PaymentCostsContainer>
        )}

        <S.WrapperButtons>
          <Button
            size="md"
            variant="primary"
            disabled={!proofEnabled}
            onClick={() => window.open(proofPath, '_blank')}
          >
            Baixar comprovante
          </Button>
        </S.WrapperButtons>
      </PageView>
    </S.Container>
  );
};

DARFScreen.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  feePayment: PropTypes.object,
  transactionUuid: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusName: PropTypes.string.isRequired,
  proofPath: PropTypes.string.isRequired,
  proofEnabled: PropTypes.bool.isRequired,
  withdrawalRateValue: PropTypes.string.isRequired,
  rateValue: PropTypes.string.isRequired,
  hasAdvancementRateValue: PropTypes.bool.isRequired,
  advancementRateValue: PropTypes.string.isRequired,
};

DARFScreen.defaultProps = {
  feePayment: {},
};

export default DARFScreen;
