import {
  borderWidthHairline,
  colorBrandPrimaryDeep,
  colorInterfaceDarkDeep,
  colorInterfaceDarkDown,
  colorBrandPrimaryPure,
  colorHighlightUp,
  colorInterfaceLightDown,
  fontSizeSm,
  fontWeightExtrabold,
  spacingInlineMd,
  spacingInsetLg,
  spacingInsetMd,
  spacingStackMd,
  spacingStackSm,
  spacingStackXs
} from '@useblu/ocean-tokens/web/tokens';
import styled, { createGlobalStyle } from 'styled-components';

import { Button, Divider, Typography } from '@useblu/ocean-react';

// .ocean-iso .ods-input:hover

const devices = {
  mobile: `(max-width: 768px)`
};

export const GlobalInputsStyle = createGlobalStyle`
  .ocean-iso .ods-modal__overlay--after-open{
    display: none
  }
  
  .is-rpa .ods-modal__overlay--after-open {
    display: block;
  }

  .ocean-iso .ods-form-control__helper-text{
    margin-bottom: 0px
  }
  .ocean-iso .ods-input--error:hover {
    border: ${borderWidthHairline} solid ${colorHighlightUp} !important;
  }
  .ocean-iso .ods-input:hover {
    border: ${borderWidthHairline} solid ${colorBrandPrimaryDeep};
    margin: 1px;
  }

  .ocean-iso .ods-input--disabled--text .ods-input__adornment {
    opacity: 1;
  }

  @media(max-width: 25rem) {
    .ocean-iso .ods-modal__content--after-open {
      max-height: 100%;
  }
}
 
`;

export const MobileHeader = styled.div`
  display: none;

  @media ${devices.mobile} {
    display: ${(props) => (props.completed ? 'none' : 'flex')};
  }

  justify-content: flex-start;
  gap: ${spacingStackXs};
  align-items: center;
  padding: ${spacingStackXs} 0;
  border-bottom: 1px solid ${colorInterfaceLightDown};
  font-weight: ${fontWeightExtrabold};
  color: ${colorBrandPrimaryPure};
  font-size: ${fontSizeSm};
  margin-bottom: ${spacingStackXs};

  > button {
    background: none;
  }
`;

export const FormTitle = styled(Typography).attrs(() => ({
  variant: 'heading2'
}))`
  &&& {
    margin-top: ${spacingInsetMd};
    margin-bottom: ${spacingInsetLg};
    display: block;

    @media ${devices.mobile} {
      display: none;
    }
  }
`;

export const StepTitle = styled(Typography).attrs(() => ({
  variant: 'heading4'
}))`
  &&& {
    margin-bottom: ${spacingInsetMd};

    @media (max-width: 25rem) {
      margin-top: 29px;
      font-size: 24px;
      font-weight: 900;
    }
  }
`;

export const StepContainer = styled.div`
  max-width: 511px;
  margin: 0 auto;
`;

export const ClientInfo = styled.div`
  margin-bottom: ${spacingInlineMd};
`;

export const InfoRow = styled.div`
  &:first-child {
    margin-bottom: ${spacingStackXs};
  }

  &:last-child {
    margin-top: ${spacingStackXs};
  }

  display: flex;
  align-items: center;

  @media (min-width: 60rem) {
    > img {
      display: none;
    }

    color: ${colorBrandPrimaryPure};
  }

  > div {
    display: flex;
    align-items: center;

    span:first-child {
      display: block;
      margin-right: ${spacingStackXs};
      width: 64px;
    }

    span:last-child {
      color: #0c0d14;
    }

    @media (max-width: 25rem) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      span:first-child {
        color: ${colorInterfaceDarkDeep};
        font-size: 16px;
        font-weight: 600;
      }

      span:last-child {
        color: ${colorInterfaceDarkDown};
        font-size: 14px;
      }
    }
  }
`;

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.nameRow ? '1.3fr 1fr' : '1fr 1fr'};
  gap: ${spacingStackXs};
  align-items: start;

  @media ${devices.mobile} {
    display: flex;
    flex-direction: column;
    gap: 0;

    div {
      width: 100%;
    }
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .back {
    margin-right: auto;
  }
  @media ${devices.mobile} {
    .back {
      display: none;
    }
  }
`;

export const ButtonsDivider = styled(Divider)`
  &&& {
    margin: ${spacingStackMd} 0;
  }
  @media ${devices.mobile} {
    &&& {
      margin: ${spacingStackSm} 0;
      display: none;
    }
  }
`;

export const CustomButton = styled(Button)`
  @media ${devices.mobile} {
    margin-top: ${spacingStackSm};
    width: 100%;
  }
`;
