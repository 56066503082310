import React from 'react';

const ContractDownload = () => (
  <div>
    Para efetuar o download do Contrato da Plataforma Blu,{' '}
    <a
      href="download/VERSAO-VIGENTE-03.09.2020_Contrato-de-Prestação-de Serviços-Blu.pdf"
      download="VERSAO-VIGENTE-03.09.2020_Contrato-de-Prestação-de Serviços-Blu.pdf"
      target="_blank"
      rel="noopener noreferrer"
    >
      clique aqui
    </a>
  </div>
);

export default ContractDownload;
