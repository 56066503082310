import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import {
  ArrowLeftOutline,
  ReceiptRefundOutline,
} from '@useblu/ocean-icons-react';
import moment from 'moment';
import {
  Button,
  TransactionListItem,
  List,
  TopBar,
  SubHeader,
  Tag,
  Grid,
} from '@useblu/ocean-react';
import Snackbar, { useSnackbar } from 'components/Shared/Snackbar';
import { formatDate } from 'normalizers';
import { formatPrice } from 'components/AccountStatement/helpers/currency';
import accountStatementStatus from 'components/AccountStatement/screens/DARFScreen/accountStatementStatus';
import { isEmpty } from 'lodash';

import * as S from '../../../styles';
import * as PS from '../../../Payment/styles';
import ModalRefund from '../ModalRefund';
import '../../../breadcrumb.css';
import LoadingDetails from './LoadingDetails';

const accountTypes = {
  CACC: 'Conta Corrente',
  SVGS: 'Conta Poupança',
  SLRY: 'Conta Salário',
  TRAN: 'Conta de Pagamento',
};

const Details = ({
  date,
  debitParty,
  value,
  transactionId,
  title,
  loading,
  refunds,
  proofLink,
  e2eId,
  statusName,
  uuid,
  status,
  description,
  receiverKey,
  transactionUuid,
  getPaymentInbounds,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const isMobile = useMedia('(max-width: 768px)');

  const {
    isActive: snackIsActive,
    message: snackMessage,
    type: snackType,
    openSnackBar,
  } = useSnackbar();

  const maximumRefundAmount = refunds.reduce((amount, refund) => {
    if (refund.data.attributes.status !== 'failed') {
      return (
        Number(amount).toFixed(2) -
        Number(refund.data.attributes.value).toFixed(2)
      );
    }

    return Number(amount).toFixed(2);
  }, Number(value));

  const toggleModal = () => {
    setIsOpenModal((oldValue) => !oldValue);
  };

  const refundStatus = {
    failed: <Tag type="negative">Cancelado</Tag>,
    processed: <Tag>Processando</Tag>,
  };

  const handleRedirectDashboard = () => {
    window.location.href = '/';
  };

  return (
    <S.Container>
      <PS.GridContainer fluid="md">
        <Grid.Row>
          <Grid.Col
            md={{
              span: '10',
              offset: '1',
            }}
            lg={{
              span: '8',
              offset: '2',
            }}
            xl={{
              span: '8',
              offset: '2',
            }}
          >
            <PS.BreadcrumbStyle />
            <PS.Bar>
              <TopBar
                id="TopBar-playground"
                variants="default"
                title={title}
                leftIcon={
                  isMobile ? (
                    <ArrowLeftOutline data-testid="back-button" />
                  ) : null
                }
                onLeftAction={() => {
                  handleRedirectDashboard();
                }}
              />
            </PS.Bar>
            <PS.TagStatusTransaction
              className={`statement-detail--bg-${status}`}
              type={accountStatementStatus(status)}
              setIconOff
            >
              {statusName}
            </PS.TagStatusTransaction>
            {!loading ? (
              <PS.ContentContainer>
                <PS.TableDataTransaction>
                  <PS.Table>
                    <tbody>
                      <PS.ValueTableRow>
                        <td>
                          <p>Valor</p>
                        </td>
                        <td>
                          <p>{formatPrice(value)}</p>
                        </td>
                      </PS.ValueTableRow>
                      <PS.DateTableRow
                        className={
                          !receiverKey && isEmpty(description) ? 'session' : ''
                        }
                      >
                        <td>
                          <p>Recebido em</p>
                        </td>
                        <td>
                          <p>{formatDate(date)}</p>
                        </td>
                      </PS.DateTableRow>
                      {receiverKey && (
                        <PS.DateTableRow
                          className={isEmpty(description) ? 'session' : ''}
                        >
                          <td>
                            <p>Chave pix</p>
                          </td>
                          <td>
                            <p>{receiverKey}</p>
                          </td>
                        </PS.DateTableRow>
                      )}
                      {!isEmpty(description) && (
                        <PS.DateTableRow className="session">
                          <td>
                            <p>Descrição</p>
                          </td>
                          <td>
                            <p>{description}</p>
                          </td>
                        </PS.DateTableRow>
                      )}
                      <PS.DateTableRow>
                        <td>
                          <p>De</p>
                        </td>
                        <td>
                          <p>{debitParty.name}</p>
                        </td>
                      </PS.DateTableRow>
                      <PS.DateTableRow>
                        <td>
                          <p>CPF/CNPJ</p>
                        </td>
                        <td>
                          <p>{debitParty.taxId}</p>
                        </td>
                      </PS.DateTableRow>
                      <PS.DateTableRow>
                        <td>
                          <p>Instituição</p>
                        </td>
                        <td>
                          <p>{debitParty.bank}</p>
                        </td>
                      </PS.DateTableRow>
                      <PS.DateTableRow>
                        <td>
                          <p>Agência</p>
                        </td>
                        <td>
                          <p>{debitParty.branch}</p>
                        </td>
                      </PS.DateTableRow>
                      <PS.DateTableRow>
                        <td>
                          <p>Conta</p>
                        </td>
                        <td>
                          <p>{debitParty.account}</p>
                        </td>
                      </PS.DateTableRow>
                      <PS.DateTableRow className="session">
                        <td>
                          <p>Tipo</p>
                        </td>
                        <td>
                          <p>{accountTypes[debitParty.accountType]}</p>
                        </td>
                      </PS.DateTableRow>
                      <PS.DateTableRow>
                        <td>
                          <p>Código da operação</p>
                        </td>
                        <td>
                          <p>{transactionUuid}</p>
                        </td>
                      </PS.DateTableRow>
                      <PS.DateTableRow className="session">
                        <td>
                          <p>Código de autenticação</p>
                        </td>
                        <td>
                          <p>{e2eId}</p>
                        </td>
                      </PS.DateTableRow>
                    </tbody>
                  </PS.Table>
                </PS.TableDataTransaction>
              </PS.ContentContainer>
            ) : (
              <LoadingDetails />
            )}

            {!!refunds.length && (
              <S.WrapperRefunds>
                <List>
                  <SubHeader>Devoluções</SubHeader>
                  {refunds.map((item) => (
                    <TransactionListItem
                      icon={<ReceiptRefundOutline />}
                      key={item.data.id}
                      level2={debitParty.name}
                      tags={refundStatus[item.data.attributes.status]}
                      time={
                        item.data.attributes.status === 'confirmed' &&
                        moment(item.data.attributes.requested_at).format(
                          'DD/MM/YYYY HH:mm'
                        )
                      }
                      onClick={() => {
                        window.location.href = `/lojas/${uuid}/transacoes/${item.data.attributes.client_transaction_refund_id}`;
                      }}
                      value={formatPrice(Number(item.data.attributes.value))}
                    >
                      Devolução Pix
                    </TransactionListItem>
                  ))}
                </List>
              </S.WrapperRefunds>
            )}

            <S.WrapperButtons>
              <Button
                disabled={loading || maximumRefundAmount <= 0}
                size="md"
                variant="secondary"
                onClick={toggleModal}
              >
                Fazer Reembolso
              </Button>
              <Button
                size="md"
                variant="primary"
                disabled={loading}
                onClick={() => window.open(proofLink, '_blank')}
              >
                Baixar comprovante
              </Button>
            </S.WrapperButtons>

            <ModalRefund
              maxValue={Number(maximumRefundAmount)}
              toggleModal={toggleModal}
              isOpen={isOpenModal}
              dateLimit={moment(date).add(90, 'days').format('LL')}
              transactionId={transactionId}
              openSnackBar={openSnackBar}
              uuid={uuid}
              getPaymentInbounds={getPaymentInbounds}
            />
            <Snackbar
              isActive={snackIsActive}
              message={snackMessage}
              type={snackType}
            />
          </Grid.Col>
        </Grid.Row>
      </PS.GridContainer>
    </S.Container>
  );
};

Details.propTypes = {
  date: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  debitParty: PropTypes.shape({
    account: PropTypes.string,
    personType: PropTypes.string,
    taxId: PropTypes.string,
    accountType: PropTypes.string,
    branch: PropTypes.string,
    bank: PropTypes.string,
    name: PropTypes.string,
  }),
  description: PropTypes.string,
  status: PropTypes.string.isRequired,
  refunds: PropTypes.array,
  transactionId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  proofLink: PropTypes.string.isRequired,
  e2eId: PropTypes.string,
  uuid: PropTypes.string.isRequired,
  receiverKey: PropTypes.string,
  getPaymentInbounds: PropTypes.func.isRequired,
  statusName: PropTypes.string.isRequired,
  transactionUuid: PropTypes.string.isRequired,
};

Details.defaultProps = {
  description: '',
  debitParty: {},
  receiverKey: null,
  refunds: [],
  e2eId: '',
};

export default Details;
