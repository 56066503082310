import React from 'react';
import PropTypes from 'prop-types';

import { AccordionDescriptionContent } from './styles';

export const AccordionDescription = ({ content, open, children }) => (
  <AccordionDescriptionContent role="contentinfo" className={open && 'show'}>
    {content}
    {children}
  </AccordionDescriptionContent>
);

AccordionDescription.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired
};

AccordionDescription.defaultProps = {
  children: null
};

export default AccordionDescription;
