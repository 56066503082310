import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, useField } from 'formik';
import { Input } from '@useblu/ocean-react';

import maskInput, { maskValue } from 'utils/masks';

const InputControl = ({
  name,
  mask = false,
  onChangeCB,
  innerRef,
  helperText,
  controlError,
  ...props
}) => {
  const inputRef = useRef(null);
  const [field] = useField({ name, ...props });
  const { handleChange, errors, touched } = useFormikContext();

  const inputProps = {
    ...field,
    ...props
  };

  useLayoutEffect(() => {
    if (mask && innerRef) maskInput(innerRef.current, mask);
    if (mask && !innerRef) maskInput(inputRef.current, mask);
  }, [mask, innerRef]);

  return (
    <Input
      ref={innerRef || inputRef}
      {...inputProps}
      onChange={(e) => {
        if (mask) {
          e.target.value = maskValue(e.target.value, mask);
        }

        if (props.onChange) {
          props.onChange(e);
        } else {
          handleChange(e);
        }

        if (onChangeCB) onChangeCB(e);
      }}
      error={errors[name] && touched[name] && controlError}
      helperText={
        errors[name] && touched[name] && controlError
          ? errors[name]
          : helperText
      }
    />
  );
};

InputControl.propTypes = {
  name: PropTypes.string.isRequired,
  mask: PropTypes.any,
  onChangeCB: PropTypes.func,
  onChange: PropTypes.func,
  innerRef: PropTypes.any,
  helperText: PropTypes.string,
  controlError: PropTypes.bool
};

InputControl.defaultProps = {
  mask: false,
  onChange: undefined,
  onChangeCB: () => false,
  innerRef: false,
  helperText: '',
  controlError: false
};

export default InputControl;
