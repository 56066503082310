import styled, { createGlobalStyle } from 'styled-components';
import { Typography } from '@useblu/ocean-react';
import {
  colorBrandPrimaryPure,
  colorInterfaceDarkDown,
  spacingStackXs,
  spacingStackXxs,
  spacingInsetLg,
  spacingInsetXs,
  fontSizeXs,
  fontSizeSm,
  spacingStackSm
} from '@useblu/ocean-tokens/web/tokens';

export const ModalStyle = createGlobalStyle`
  .ocean-iso .ods-modal__header {
    padding: 0px;
    height: 32px;
    @media (max-width: 480px) {
      height: 48px;
    }
  }
  .ocean-iso .ods-modal__content .ods-modal__body {
    padding-bottom: ${spacingStackXxs};
    @media (max-width: 480px) {
        padding: ${spacingStackXs};
        padding-top: 0;
    }
  }
  .ocean-iso .ods-modal__overlay{
    z-index: 99999;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: ${spacingInsetLg};
  @media (max-width: 480px) {
    margin-bottom: 31.6px;
  }
`;

export const Img = styled.img`
  margin-bottom: ${spacingInsetLg};
  @media (max-width: 480px) {
    margin-bottom: 24px;
  }
`;

export const Title = styled(Typography).attrs(() => ({
  variant: 'heading2'
}))`
  &&& {
    color: ${colorBrandPrimaryPure};
    width: 440px;
    margin-bottom: ${spacingStackXs};
    text-align: center;
    @media (max-width: 480px) {
      width: 312px;
      margin-left: ${spacingInsetXs};
      margin-right: ${spacingInsetXs};
      font-size: ${fontSizeSm};
    }
  }
`;

export const Paragraph = styled(Typography).attrs(() => ({
  variant: 'paragraph'
}))`
  color: ${colorInterfaceDarkDown};
  font-size: ${fontSizeXs};
  width: 440px;
  text-align: center;
  @media (max-width: 480px) {
    width: 312px;
    margin-left: ${spacingStackXxs};
    margin-right: ${spacingStackXxs};
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column-reverse;
  }
  & button {
    width: 176px;
    @media (max-width: 480px) {
      width: 100%;
      padding: 0px;
      margin: 0px;
    }
  }
  > div {
    margin-top: ${spacingInsetLg};
    margin-bottom: ${spacingStackSm};
    @media (max-width: 480px) {
      margin-left: ${spacingStackXxs};
      margin-right: ${spacingStackXxs};
      margin-top: 0px;
    }
  }
`;
