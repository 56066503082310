import React, { useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import rpaImage from 'components/RPARegisterForm/assets/ilustra-rpa-sucesso.svg';
import RPARegisterFormContext from 'components/RPARegisterForm/context';
import {
  SuccessContainer,
  SuccessContent,
  CustomButton,
  CustomH3,
  CustomTypography
} from './styles';

export default function Success() {
  const { currentClientId } = useContext(RPARegisterFormContext);
  useEffect(() => {
    ReactGA.event({
      category: 'rpacadastro',
      action: 'rpa_data_submit_success'
    });
  });
  return (
    <SuccessContainer id="rpa_data_submit_success">
      <img src={rpaImage} alt="Cadastro finalizado" />
      <SuccessContent>
        <CustomH3 variant="heading3">Cadastro finalizado!</CustomH3>
        <CustomTypography variant="paragraph">
          <strong>
            Agora você pode continuar navegando na sua Conta Digital Blu.
          </strong>{' '}
          Continue a indicar seus clientes na Campanha Rep Blu para aumentar
          ainda mais o seu faturamento ganhando prêmios em dinheiro.
        </CustomTypography>
        <CustomButton
          variant="primary"
          onClick={() => {
            window.location = `/lojas/${currentClientId}/extrato/pendentes`;
          }}
        >
          Voltar para a Conta Digital Blu
        </CustomButton>
      </SuccessContent>
    </SuccessContainer>
  );
}
