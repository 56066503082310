import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';

const RenderDropdownList = ({
  input,
  label,
  children,
  meta: { active, touched, error },
  simpleValue,
  className,
  ...rest
}) => {
  let simpleValueProps = {};

  if (simpleValue) {
    simpleValueProps = {
      value: rest.options.filter(
        (value) => rest.getOptionValue(value) === input.value
      ),
      onChange: (value) => input.onChange(value && rest.getOptionValue(value))
    };
  }

  return (
    <div className="form-controls select form-controls--payment_on">
      <label>{label}</label>
      <div className={classNames({ 'field-with-errors': touched && error })}>
        <Select
          {...input}
          {...rest}
          className={classNames(
            {
              'react-select__redux-form--active': active && !error,
              'react-select__redux-form--error': touched && error
            },
            className
          )}
          classNamePrefix="react-select"
          onChange={(value) => input.onChange(value)}
          onBlur={null}
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
          {...simpleValueProps}
        />
        {children}
        {touched && error && <span className="field-error">{error}</span>}
      </div>
    </div>
  );
};

RenderDropdownList.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  children: PropTypes.element,
  meta: PropTypes.object,
  className: PropTypes.string,
  simpleValue: PropTypes.bool,
  isClearable: PropTypes.bool
};

RenderDropdownList.defaultProps = {
  label: '',
  children: null,
  meta: null,
  className: null,
  simpleValue: false,
  isClearable: true
};

export default RenderDropdownList;
