import { createContext, useContext } from 'react';

export const authenticationDefaultValues = {
  setError: () => null,
  creatorClientUuid: '',
  error: '',
  renderTwoFactorComponent: false,
  setRenderTwoFactorComponent: () => null,
  signInData: {},
  setSignInData: () => {},
  token: '',
  setToken: () => null,
  successAlert: false,
  setSuccessAlert: () => null,
  loadingTokenValidation: false,
  setLoadingTokenValidation: () => null,
  twoFactorAuthentication: () => null,
  resendToken: () => null,
  isOpenSnackBar: false,
  setOpenSnackBar: () => null,
  disabledButton: 60,
  setDisabledButton: () => null
};

export const AuthenticationContext = createContext(authenticationDefaultValues);

export const useAuthenticationContext = () => useContext(AuthenticationContext);
