/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

export const getPaymentInboundsApi = async (clientUUID, transactionId) => {
  const response = await axios.get(
    `/api/clients/${clientUUID}/pix/payment_inbounds/${transactionId}`,
    {
      headers: {
        Accept: 'version=1',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );
  return response;
};

export const sendRefund = async (clientUUID, transactionId, value, reason) => {
  const response = await axios.post(
    `/api/clients/${clientUUID}/pix/refunds`,
    {
      refund: {
        movement_id: transactionId,
        value,
        reason,
      },
    },
    {
      headers: {
        Accept: 'version=1',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );
  return response;
};

export const getRefund = async (clientUUID, refundId) => {
  const response = await axios.get(
    `/api/clients/${clientUUID}/pix/refunds/${refundId}`,
    {
      headers: {
        Accept: 'version=1',
      },
    }
  );

  return response;
};

export const sendPixkey = async (clientUUID, pixKey, keyType) => {
  const response = await axios.post(
    `/api/clients/${clientUUID}/pix/payments/initiate`,
    {
      initiation_key: pixKey,
      initiation_type: 'DICT',
      key_type: keyType,
    },
    {
      headers: {
        Accept: 'version=1',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );

  return response;
};

export const createPixKeyContact = async (clientUUID, pixId) => {
  const response = await axios.post(
    `/api/clients/${clientUUID}/history_payees/create_pix_key_contact`,
    {
      history_payee: {
        payable_id: pixId,
      },
    },
    {
      headers: {
        Accept: 'version=1',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );

  return response;
};

export const getRefundInbound = async (clientUUID, movementId) => {
  const response = await axios.get(
    `/api/clients/${clientUUID}/pix/refund_inbounds/${movementId}`,
    {
      headers: {
        Accept: 'version=1',
      },
    }
  );

  return response;
};

export const getPixPayment = async (clientUUID, clientTransactionUuid) => {
  const response = await axios.get(
    `/api/clients/${clientUUID}/pix/payments/${clientTransactionUuid}`,
    {
      headers: {
        Accept: 'version=1',
      },
    }
  );

  return response;
};

export const getPaymentData = async (clientUUID, transactionId) => {
  const response = await axios.get(
    `/api/clients/${clientUUID}/payments/${transactionId}`,
    {
      headers: {
        Accept: 'version=2',
      },
    }
  );

  return response;
};
