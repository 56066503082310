import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { normalizeCurrency } from 'normalizers';
import PaymentPlanHistory from './PaymentPlanHistory';
import PaymentPlanStatus from './PaymentPlanStatus';
import {
  ContentWrapper,
  SummaryContainer,
  SummaryValues,
  SummaryBilled,
  SummaryBilledRemaining,
  ToggleLink
} from './PaymentPlanSectionStyles';

const PaymentPlanSection = ({
  billedValue,
  billedRemainingValue,
  status,
  payments
}) => {
  const [showPayments, setShowPayments] = useState(false);

  return (
    <div className="list__wrapper">
      <h4 className="list__title">Faturamento</h4>
      <ContentWrapper className="list__item">
        <SummaryContainer>
          <SummaryValues>
            <SummaryBilled>
              <span>R$ {normalizeCurrency(billedValue)}</span>
              <span>Faturados</span>
            </SummaryBilled>
            <SummaryBilledRemaining>
              <span>R$ {normalizeCurrency(billedRemainingValue)}</span>
              <span>Aguardamento faturamento</span>
            </SummaryBilledRemaining>
          </SummaryValues>
          <PaymentPlanStatus type={status.type} name={status.name} />
          <ToggleLink onClick={() => setShowPayments(!showPayments)}>
            {showPayments ? 'Esconder' : 'Mostrar'} Faturamentos
          </ToggleLink>
        </SummaryContainer>
        {showPayments && <PaymentPlanHistory payments={payments} />}
      </ContentWrapper>
    </div>
  );
};

PaymentPlanSection.propTypes = {
  billedValue: PropTypes.number.isRequired,
  billedRemainingValue: PropTypes.number.isRequired,
  status: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  ...PaymentPlanHistory.propTypes
};

export default PaymentPlanSection;
