/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { connect } from 'react-redux';

/* Actions and Validations */
import sharedActions from 'store/ducks/firstAccess/shared';

/* Presentational */
import _ from 'lodash';
import history from 'utils/browserHistory';
import { clientSelector } from 'selectors/FirstAccess/shared';

class FormWizard extends Component {
  componentDidMount() {
    const { uuid, userId, requestClient } = this.props;
    requestClient(uuid, userId);
  }

  setCompletedStep(barStep) {
    const { currentStep } = this.props;

    const stepsOrder = [
      'clientInfo',
      'qsaAccounts',
      'usersAccounts',
      'usersContracts',
      'usersUploads'
    ];

    if (barStep === currentStep) {
      return 'current';
    }

    if (stepsOrder.indexOf(currentStep) > stepsOrder.indexOf(barStep)) {
      return 'previous-completed';
    }

    return 'not-completed';
  }

  redirect(steps) {
    const { uuid } = this.props;
    if (!steps.clientInfo) {
      history.push(`/lojas/${uuid}/primeiro-acesso/informacoes-da-empresa`);
    } else if (!steps.qsaAccounts) {
      history.push(`/lojas/${uuid}/primeiro-acesso/informacoes-dos-socios`);
    } else if (!steps.usersAccounts) {
      history.push(`/lojas/${uuid}/primeiro-acesso/autorizacoes-dos-socios`);
    } else if (!steps.usersContracts) {
      history.push(`/lojas/${uuid}/primeiro-acesso/assinatura-de-contrato`);
    } else if (!steps.usersUploads) {
      history.push(`/lojas/${uuid}/primeiro-acesso/enviar-contrato`);
    }
  }

  checkRedirect(currentStep, steps) {
    switch (currentStep) {
      case 'qsaAccounts':
        if (!steps.clientInfo) {
          this.redirect(steps);
        }
        break;
      case 'usersAccounts':
        if (!steps.clientInfo || !steps.qsaAccounts) {
          this.redirect(steps);
        }
        break;
      case 'usersContracts':
        if (!steps.clientInfo || !steps.qsaAccounts || !steps.usersAccounts) {
          this.redirect(steps);
        }
        break;
      case 'usersUploads':
        if (
          !steps.clientInfo ||
          !steps.qsaAccounts ||
          !steps.usersAccounts ||
          !steps.usersContracts
        ) {
          this.redirect(steps);
        }
        break;
      default:
        break;
    }
  }

  render() {
    const { client, railsProps } = this.props;
    let content;

    if (_.isEmpty(client)) {
      content = '';
    } else {
      content = (
        <div className="first-access-wizard float-container">
          <div className="first-access-wizard--progress-bar">
            {railsProps.currentClient.need_validation && (
              <div
                className={`first-access-wizard--bar ${this.setCompletedStep(
                  'clientInfo'
                )}`}
              >
                <div className="first-access-wizard--marker">1</div>
              </div>
            )}
            <div
              className={`first-access-wizard--bar ${this.setCompletedStep(
                'qsaAccounts'
              )}`}
            >
              <div className="first-access-wizard--marker">
                {railsProps.currentClient.need_validation ? '2' : '1'}
              </div>
            </div>
            {railsProps.currentClient.need_validation && (
              <div
                className={`first-access-wizard--bar ${this.setCompletedStep(
                  'usersAccounts'
                )}`}
              >
                <div className="first-access-wizard--marker">3</div>
              </div>
            )}
            <div
              className={`first-access-wizard--bar ${this.setCompletedStep(
                'usersContracts'
              )}`}
            >
              <div className="first-access-wizard--marker">
                {railsProps.currentClient.need_validation ? '4' : '2'}
              </div>
            </div>
            <div
              className={`first-access-wizard--bar ${this.setCompletedStep(
                'usersUploads'
              )}`}
            >
              <div className="first-access-wizard--marker">5</div>
            </div>
          </div>
        </div>
      );
    }

    return content;
  }
}

FormWizard.propTypes = {
  requestClient: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
  client: PropTypes.object,
  uuid: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  railsProps: PropTypes.shape({
    currentClient: PropTypes.shape({
      need_validation: PropTypes.bool
    })
  }).isRequired
};

FormWizard.defaultProps = {
  client: {}
};

const mapStateToProps = (state) => ({
  client: clientSelector(state),
  currentStep: state.firstAccessShared.currentStep,
  userId: state.railsContext.currentUserId
});

const mapDispatchToProps = (dispatch) => ({
  requestClient: (uuid, userId) =>
    dispatch(sharedActions.firstAccessClientRequest(uuid, userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(FormWizard);
export { FormWizard as PureFormWizard };
