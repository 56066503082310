import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types, Creators } = createActions({
  firstAccessUsersContractsRequest: ['uuid', 'userId'],
  firstAccessUsersContractsSuccess: null
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  loading: false
};

/* Reducers */

export const request = (state) => ({
  ...state,
  loading: true
});

export const success = (state, action) => ({
  ...state,
  loading: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRST_ACCESS_USERS_CONTRACTS_REQUEST]: request,
  [Types.FIRST_ACCESS_USERS_CONTRACTS_SUCCESS]: success
});
