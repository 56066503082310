import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Formik, Form, ErrorMessage } from 'formik';

import { XOutline } from '@useblu/ocean-icons-react';
import { TopBar, Button, Select, Progress } from '@useblu/ocean-react';
import {
  clientAddressUpdate,
  showUserStep,
  showContractStep
} from 'actions/contract-form';

import { bindActionCreators } from 'redux';
import PageTitle from '../components/PageTitle';

import {
  Container,
  Wrapper,
  InputWrapper,
  CustomInput,
  Paragraph,
  AddressBlock,
  ButtonWrapper,
  ErrorText,
  Bar
} from './styles';

import useFormAddress from './useFormAddress';
import { stateMock } from './mockState';
import TaxPlansModal from '../components/TaxPlansModal';

const UserAddressConfirmation = ({
  client,
  address,
  clientAddressUpdate,
  showUserStep,
  showClientStep,
  showContractStep,
  registrationStep
}) => {
  const {
    formikProps,
    hasChangeValidation,
    enableChangeValidation,
    cepError,
    visible,
    setVisible,
    updateFieldsByCep,
    hasState,
    formData,
    isLoading
  } = useFormAddress({
    client,
    address,
    stateMock,
    clientAddressUpdate,
    showClientStep,
    showContractStep
  });

  const userStatus =
    registrationStep === 'pending_basic_data_confirmation' ||
    registrationStep === 'not_an_automatic_registration_client' ||
    registrationStep === 'pending_basic_data_confirmation_without_qsa';

  return (
    showClientStep &&
    userStatus && (
      <Container className="ocean-iso">
        <Bar>
          <TopBar
            id="TopBar-playground"
            variants="default"
            leftIcon={<XOutline />}
            title="Confirmação de dados"
            onLeftAction={() => {
              window.location.href = `/`;
            }}
          />
        </Bar>

        <Wrapper>
          <PageTitle title="Agora, confirme os dados da empresa" step={2} />
          <Formik
            {...formikProps}
            validateOnChange={hasChangeValidation}
            validateOnBlur={false}
          >
            {({ values, handleSubmit, setFieldValue }) => (
              <Form
                onSubmit={(e) => {
                  enableChangeValidation(true);
                  handleSubmit(e);
                }}
              >
                {isLoading ? (
                  <div className="ocean-iso loading">
                    <Progress size="md" />
                  </div>
                ) : (
                  <>
                    <InputWrapper>
                      <CustomInput
                        name="name"
                        id="social-reason"
                        label="Razão social"
                        placeholder="Insira a razão social"
                        value={values.name}
                        disabled={formData.name}
                      />
                      <ErrorMessage
                        type="name"
                        name="name"
                        render={(msg) => (
                          <ErrorText className="error-msg">{msg}</ErrorText>
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <CustomInput
                        name="business_name"
                        id="fantasy-name"
                        label="Nome fantasia"
                        placeholder="Insira o nome fantasia"
                        values={values.business_name}
                        disabled={formData.business_name}
                      />

                      <ErrorMessage
                        type="business_name"
                        name="business_name"
                        render={(msg) => (
                          <ErrorText className="error-msg">{msg}</ErrorText>
                        )}
                      />
                    </InputWrapper>

                    <ButtonWrapper>
                      <Button
                        type="button"
                        size="sm"
                        variant="secondary"
                        className="atv_etapa_2_dadosempresa_botaotaxas"
                        blocked
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        Ver todas as taxas
                      </Button>
                    </ButtonWrapper>

                    <Paragraph>Endereço</Paragraph>

                    <InputWrapper>
                      <CustomInput
                        name="zipcode"
                        id="cep"
                        label="CEP"
                        placeholder="Insira o CEP"
                        value={values.zipcode}
                        onBlur={({ target }) =>
                          updateFieldsByCep(target.value, setFieldValue)
                        }
                        mask="zipcode"
                      />

                      {cepError && (
                        <ErrorText className="error-msg">
                          O CEP informado não existe.
                        </ErrorText>
                      )}
                    </InputWrapper>

                    <InputWrapper>
                      <CustomInput
                        name="street"
                        id="street"
                        label="Endereço"
                        placeholder="Insira o endereço"
                        value={values.street}
                      />

                      <ErrorMessage
                        type="street"
                        name="street"
                        render={(msg) => (
                          <ErrorText className="error-msg">{msg}</ErrorText>
                        )}
                      />
                    </InputWrapper>

                    <AddressBlock>
                      <InputWrapper className="rowInput">
                        <CustomInput
                          name="number"
                          id="number"
                          label="Número"
                          placeholder="Insira o número"
                          value={values.number}
                        />

                        <ErrorMessage
                          type="number"
                          name="number"
                          render={(msg) => (
                            <ErrorText className="error-msg">{msg}</ErrorText>
                          )}
                        />
                      </InputWrapper>

                      <InputWrapper className="rowInput">
                        <CustomInput
                          name="complement"
                          id="complement"
                          label="Complemento"
                          placeholder="Insira o complemento"
                          value={values.complement}
                        />

                        <ErrorMessage
                          type="complement"
                          name="complement"
                          render={(msg) => (
                            <ErrorText className="error-msg">{msg}</ErrorText>
                          )}
                        />
                      </InputWrapper>
                    </AddressBlock>

                    <InputWrapper>
                      <CustomInput
                        name="neighborhood"
                        id="neighborhood"
                        label="Bairro"
                        placeholder="Insira o bairro"
                        value={values.neighborhood}
                      />

                      <ErrorMessage
                        type="neighborhood"
                        name="neighborhood"
                        render={(msg) => (
                          <ErrorText className="error-msg">{msg}</ErrorText>
                        )}
                      />
                    </InputWrapper>

                    <AddressBlock>
                      <InputWrapper className="city">
                        <CustomInput
                          name="city"
                          id="city"
                          label="Cidade"
                          placeholder="Insira a cidade"
                          value={values.city}
                        />
                        <ErrorMessage
                          type="city"
                          name="city"
                          render={(msg) => (
                            <ErrorText className="error-msg">{msg}</ErrorText>
                          )}
                        />
                      </InputWrapper>

                      <InputWrapper className="state">
                        <Select
                          name="state"
                          id="state"
                          label="Estado"
                          placeholder="UF"
                          defaultValue={values.state && hasState(values.state)}
                          onChange={(selectedOption) => {
                            setFieldValue('state', selectedOption.value);
                          }}
                          options={stateMock}
                          value={values.idState}
                        />
                        <ErrorMessage
                          type="state"
                          name="state"
                          render={(msg) => (
                            <ErrorText className="error-msg">{msg}</ErrorText>
                          )}
                        />
                      </InputWrapper>
                    </AddressBlock>

                    <ButtonWrapper>
                      <Button
                        variant="secondary"
                        className="atv_etapa_2_dadosempresa_botaovoltar"
                        onClick={() => showUserStep()}
                        size="md"
                      >
                        Voltar
                      </Button>
                      <Button
                        type="submit"
                        className="atv_etapa_2_dadosempresa_botaoconfirmar"
                        size="md"
                      >
                        Confirmar
                      </Button>
                    </ButtonWrapper>
                  </>
                )}
              </Form>
            )}
          </Formik>
          <TaxPlansModal isVisible={visible} toggleModal={setVisible} />
        </Wrapper>
      </Container>
    )
  );
};

UserAddressConfirmation.propTypes = {
  client: PropTypes.object,
  address: PropTypes.object,
  registrationStep: PropTypes.string,
  clientAddressUpdate: PropTypes.func.isRequired,
  showUserStep: PropTypes.func.isRequired,
  showClientStep: PropTypes.bool.isRequired,
  showContractStep: PropTypes.func.isRequired
};

UserAddressConfirmation.defaultProps = {
  client: {},
  address: {},
  registrationStep: ''
};

const mapStateToProps = (state) => ({
  client: state.contractForm.client,
  address: state.contractForm.address,
  loading: state.contractForm.loading,
  showClientStep: state.contractForm.showClientStep,
  registrationStep: state.contractForm.registration_step
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clientAddressUpdate,
      showContractStep,
      showUserStep
    },
    dispatch
  );

const UserStepAddressReduxForm = reduxForm({
  form: 'UserAddressConfirmation'
})(UserAddressConfirmation);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserStepAddressReduxForm);
