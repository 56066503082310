import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from 'react-loading';
import axios from 'axios';

import DateRange from 'components/Shared/DateRange';

class StatementExportForm extends Component {
  state = {
    from: '',
    to: '',
    isLoading: false,
    // eslint-disable-next-line react/no-unused-state
    polling: null,
    doneExporting: false,
    kind:
      new URLSearchParams(window.location.search).get('kind') || 'released_at',
    extension: 'xlsx',
    version: 'simplified'
  };

  setupPolling = (response) => {
    const { pollingUrl } = this.props;
    const {
      data: { id }
    } = response;
    const self = this;

    const pollingInterval = setInterval(() => {
      $.ajax({
        url: pollingUrl.replace('id', id),
        dataType: 'script',
        success(data, textStatus, xhr) {
          if (xhr.status === 200) {
            clearInterval(self.state.polling);
            self.setState({
              isLoading: false,
              polling: null,
              from: '',
              to: '',
              doneExporting: true
            });
          }
        }
      });
    }, 30000);

    this.setState({
      // eslint-disable-next-line react/no-unused-state
      polling: pollingInterval,
      doneExporting: false
    });
  };

  exportStatement = () => {
    const { from, to, kind, extension, version } = this.state;
    const { createUrl } = this.props;

    this.setState({ isLoading: true });

    axios
      .post(createUrl, {
        start_date: from,
        end_date: to,
        kind,
        extension,
        version
      })
      .then((response) => this.setupPolling(response));
  };

  handleKindChange = (event) => {
    const { value } = event.target;

    this.setState({ kind: value });

    if (value === 'payment_collection' || value === 'payment_collection_paid') {
      this.state.version = 'simplified';
    }
  };

  handleExtensionChange = (event) => {
    const { value } = event.target;
    this.setState({ extension: value });
  };

  handleExportTypeChange = (event) => {
    const { value } = event.target;
    this.setState({ version: value });
  };

  handleSelectDates = ({ from, to }) => {
    this.setState({ from, to });
  };

  render() {
    const { from, to, isLoading, doneExporting, kind, extension, version } =
      this.state;
    const { accountStatementEnabled } = this.props;

    let versionSelect;

    if (
      kind === 'payment_collection' ||
      kind === 'payment_collection_paid' ||
      kind === 'sales' ||
      kind === 'bank_locks' ||
      kind === 'account_statements' ||
      kind === 'chargeback_sales' ||
      kind === 'split_sales'
    ) {
      versionSelect = (
        <select
          id="version"
          name="version"
          onChange={this.handleExportTypeChange}
          value={version}
        >
          <option value="simplified">Simplificada</option>
        </select>
      );
    } else {
      versionSelect = (
        <select
          id="version"
          name="version"
          onChange={this.handleExportTypeChange}
          value={version}
        >
          <option value="simplified">Simplificada</option>
          <option value="complete">Completa (nova versão)</option>
        </select>
      );
    }

    return (
      <div className="statement-export-form">
        <h1 className="page-title">Conciliação</h1>

        <DateRange values={{ from, to }} onSelect={this.handleSelectDates} />

        <div className="form-row">
          <div className="form-controls">
            <label htmlFor="kind">Tipo:</label>
            <select
              id="kind"
              name="kind"
              onChange={(event) => this.handleKindChange(event)}
              value={kind}
            >
              <option value="released_at">Quando vou receber/pagar</option>
              {accountStatementEnabled && (
                <option value="account_statements">
                  Relatório de Movimentação
                </option>
              )}
              <option value="happened_at">Quando Aconteceu</option>
              <option value="payment_collection">Cobrança</option>
              <option value="payment_collection_paid">Cobrança paga</option>
              <option value="sales">Vendas</option>
              <option value="split_sales">Vendas com split</option>
              <option value="chargeback_sales">Vendas em chargeback</option>
              <option value="bank_locks">Travas Bancárias</option>
              <option value="settlement_external_wallets">
                Vendas outras maquininhas
              </option>
              <option value="full_external_wallets">Agenda centralizada</option>
              <option value="bms_conciliation">
                Conciliação BMS (*dados processados em d-1)
              </option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-controls">
            <label htmlFor="version">Versão:</label>
            {versionSelect}
          </div>
        </div>

        <div className="form-row">
          <div className="form-controls">
            <label htmlFor="extension">Formato do arquivo:</label>
            <select
              id="extension"
              name="extension"
              onChange={this.handleExtensionChange}
              value={extension}
            >
              <option value="xlsx">Excel (*.xlsx)</option>
              <option value="csv">Comma Separated Values (*.csv)</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <button
            className="button button--primary button--medium"
            type="button"
            onClick={() => this.exportStatement()}
            disabled={isLoading || !from || !to}
          >
            {isLoading ? 'Exportando...' : 'Iniciar exportação'}
          </button>
        </div>

        {isLoading && (
          <div className="alert-message warning statement-export-form__loading">
            <Loading type="spinningBubbles" color="#fff" delay={0} />O arquivo
            está sendo exportado. Você pode sair desta página se desejar, o
            arquivo estará listado abaixo quando a exportação for concluída.
          </div>
        )}

        {doneExporting && (
          <div className="alert-message notice">Exportação concluída!</div>
        )}
      </div>
    );
  }
}

StatementExportForm.propTypes = {
  createUrl: PropTypes.string,
  pollingUrl: PropTypes.string,
  accountStatementEnabled: PropTypes.bool
};

StatementExportForm.defaultProps = {
  createUrl: '',
  pollingUrl: '',
  accountStatementEnabled: false
};

export default StatementExportForm;
