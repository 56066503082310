import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from 'react-loading';

import moment from 'utils/moment.pt-br';
import actions from 'store/ducks/bToken/actions';
import deviceImg from 'images/device-icon.png';
import iconTime from 'images/emails/icon-time.png';
import {
  BoxContent,
  BoxAction,
  ActionsContainer,
  StyledH3
} from './DeviceCard.styles';
import DeviceCardLayout from './DeviceCardLayout';
import { Button, Modal, Typography } from '@useblu/ocean-react';

const DeviceCardListItem = ({ id, mobileDevice, lastEnabledAt, loading }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <DeviceCardLayout
      iconImg={{ alt: 'Dispositivo', src: deviceImg, width: '112px' }}
    >
      <BoxContent>
        <h1>{mobileDevice.brand.concat(' ', mobileDevice.model)}</h1>
        <h2>Sistema {mobileDevice.platform}</h2>

        <span>
          <img alt="Data e hora da ativação" src={iconTime} width="13.30px" />
          Ativado em {moment(lastEnabledAt, moment.ISO_8601).format('LLL')}
        </span>
      </BoxContent>
      <BoxAction>
        {loading ? (
          <Loading
            type="spin"
            color="#0025E0"
            delay={0}
            width={24}
            height={24}
          />
        ) : (
          <button type="button" onClick={() => setIsModalOpen(true)}>
            Desativar
          </button>
        )}
      </BoxAction>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        portalClassName="ocean-iso"
        maxWidth="sm"
        ariaHideApp={false}
      >
        <StyledH3 variant="heading3">Desativar bToken?</StyledH3>
        <Typography variant="paragraph">
          Ao desativar o bToken, uma nova ativação no mesmo dispositvo só poderá
          ser realizada após 24 horas.
        </Typography>
        <ActionsContainer>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Cancelar
          </Button>
          <Button
            data-testid="revoke"
            variant="primaryCritical"
            onClick={() => {
              dispatch(actions.revokeBTokenByIdRequest(id));
              setIsModalOpen(false);
            }}
          >
            Desativar
          </Button>
        </ActionsContainer>
      </Modal>
    </DeviceCardLayout>
  );
};

DeviceCardListItem.defaultProps = {
  loading: false
};

DeviceCardListItem.propTypes = {
  id: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  lastEnabledAt: PropTypes.string.isRequired,
  mobileDevice: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired
  }).isRequired
};

export default DeviceCardListItem;
