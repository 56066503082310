import React from 'react';
import { Divider } from '@useblu/ocean-react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import {
  ClientInfo,
  FormRow,
  FormTitle,
  InfoRow,
  StepTitle
} from 'components/RPARegisterForm/RPARegisterForm.styles';
import firstStep from 'components/RPARegisterForm/assets/step-1.svg';
import cpfIcon from 'components/RPARegisterForm/assets/cpf.svg';
import emailIcon from 'components/RPARegisterForm/assets/email.svg';
import { maskValue } from 'utils/masks';
import TextField from '../TextField';
import CheckBoxField from '../CheckBoxField';
import DateField from '../DateField';
import { InfoDescription } from './styles';

function PersonalDataStep({ cpf, email }) {
  return (
    <div id="rpa_personal_data_view">
      <img src={firstStep} alt="Primeiro passo" />
      <FormTitle variant="heading2">Complete seus dados</FormTitle>

      <StepTitle variant="heading4">
        Para começar, informe os dados pessoais a seguir.
      </StepTitle>

      <ClientInfo>
        <InfoRow>
          <img src={cpfIcon} alt="CPF" />
          <div>
            <InfoDescription>CPF</InfoDescription>
            <InfoDescription value>
              {maskValue(cpf, 'cpf_cnpj')}
            </InfoDescription>
          </div>
        </InfoRow>
        <Divider />
        <InfoRow>
          <img src={emailIcon} alt="E-mail" />
          <div>
            <InfoDescription>E-mail</InfoDescription>
            <InfoDescription value>{email}</InfoDescription>
          </div>
        </InfoRow>
      </ClientInfo>

      <FormRow nameRow>
        <Field name="name">
          {({ field, meta }) => (
            <TextField
              {...field}
              label="Nome completo"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              placeholder="Informe o seu nome completo"
            />
          )}
        </Field>
        <Field name="birthday">
          {({ field, meta }) => (
            <DateField
              {...field}
              label="Data de nascimento"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
            />
          )}
        </Field>
      </FormRow>

      <Field name="motherName">
        {({ field, meta }) => (
          <TextField
            {...field}
            label="Nome da mãe"
            error={meta.error && meta.touched}
            helperText={meta.error && meta.touched ? meta.error : null}
            placeholder="Informe o nome da sua mãe"
          />
        )}
      </Field>

      <Field name="fatherName">
        {({ field, meta, form }) => {
          const disabled = form.values.noFatherName;
          return (
            <TextField
              {...field}
              checkboxMargin
              disabled={disabled}
              label="Nome do pai"
              error={meta.error && meta.touched}
              helperText={meta.error && meta.touched ? meta.error : null}
              placeholder={
                disabled
                  ? 'Nome do pai não consta'
                  : 'Informe o nome do seu pai'
              }
            />
          );
        }}
      </Field>

      <Field name="noFatherName" type="checkbox">
        {({ field, form }) => {
          const handleChangeLocal = (e) => {
            form.setFieldValue('fatherName', '');
            form.handleChange(e);
          };
          return (
            <CheckBoxField
              form={form}
              {...field}
              label="Nome do pai não consta no registro."
              onChange={handleChangeLocal}
            />
          );
        }}
      </Field>
    </div>
  );
}

PersonalDataStep.propTypes = {
  cpf: PropTypes.string,
  email: PropTypes.string
};

PersonalDataStep.defaultProps = {
  cpf: '',
  email: ''
};

export default PersonalDataStep;
