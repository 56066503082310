import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@useblu/ocean-react';
import * as S from './styles';

const Header = ({ clientAA, clientTax }) => (
  <S.Wrapper>
    <Grid.Container>
      <Grid.Col
        xs={{
          span: '12'
        }}
        md={{
          span: '7'
        }}
      >
        <Grid.Row>
          <S.Tag>
            <Typography variant="description">soluções de pagamento</Typography>
          </S.Tag>
          <S.Title variant="heading2">
            {clientAA
              ? `Ganhe até ${clientTax}% de desconto pagando fornecedores parceiros`
              : 'Economize até 10% ao pagar seus fornecedores'}
          </S.Title>
          <S.Description variant="heading2">
            Com a Blu, você sempre vai
            <span>pagar mais barato</span>
          </S.Description>
        </Grid.Row>
      </Grid.Col>
    </Grid.Container>
  </S.Wrapper>
);

Header.propTypes = {
  clientAA: PropTypes.bool.isRequired,
  clientTax: PropTypes.string.isRequired
};

export default Header;
