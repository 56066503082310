import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FilterNavOptions from './components/FilterNavOptions';
import FilterForm from './components/FilterForm';

class PaymentCollectionFilter extends Component {
  static propTypes = {
    pendingTransactionsPath: PropTypes.string.isRequired,
    allTransactionsPath: PropTypes.string.isRequired,
    paidTransactionsPath: PropTypes.string.isRequired,
    pendingTransactionsCount: PropTypes.number,
    activeFilters: PropTypes.object,
    showPaidTransactions: PropTypes.bool,
    selectedStatus: PropTypes.string
  };

  static defaultProps = {
    showPaidTransactions: false
  };

  state = { isFilterFormOpen: false };

  getFormInitialValues() {
    const { activeFilters } = this.props;

    if (!activeFilters) return;

    return Object.keys(activeFilters).reduce(function (result, key) {
      result[`filter[${key}]`] = activeFilters[key];
      return result;
    }, {});
  }

  sortingFilterChanged = (e) => {
    const { value } = e.target;

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('filter[sorting]', value);

    window.location.search = searchParams;
  };

  toggleFilter = () => {
    this.setState((prevProps) => ({
      ...prevProps,
      isFilterFormOpen: !prevProps.isFilterFormOpen
    }));
  };

  render() {
    const { isFilterFormOpen } = this.state;
    const {
      activeFilters,
      pendingTransactionsPath,
      allTransactionsPath,
      paidTransactionsPath,
      selectedStatus,
      pendingTransactionsCount,
      showPaidTransactions
    } = this.props;

    return (
      <div className="statement__filter">
        <FilterNavOptions
          filterItem={{
            text: isFilterFormOpen ? 'Esconder Filtro' : 'Filtrar',
            onClick: () => this.toggleFilter()
          }}
          linkItems={{
            allTransactions: {
              href: allTransactionsPath,
              isVisible: showPaidTransactions || pendingTransactionsCount > 0,
              isActive: !selectedStatus
            },
            pendingTransactions: {
              href: pendingTransactionsPath,
              isVisible: pendingTransactionsCount > 0,
              isActive: selectedStatus === 'pendentes',
              counter: pendingTransactionsCount
            },
            paidTransactions: {
              href: paidTransactionsPath,
              isVisible: showPaidTransactions,
              isActive: selectedStatus === 'pagas'
            }
          }}
          sortItem={{
            selected:
              activeFilters && activeFilters.sorting
                ? activeFilters.sorting
                : 'happened_at',
            onSelect: (e) => this.sortingFilterChanged(e),
            isVisible: !selectedStatus || selectedStatus === 'pagas'
          }}
        />

        {isFilterFormOpen && (
          <FilterForm
            formAction={window.location.pathname}
            selectedStatus={selectedStatus}
            initialValues={this.getFormInitialValues()}
          />
        )}
      </div>
    );
  }
}

export default PaymentCollectionFilter;
