import { useCallback, useEffect, useState } from 'react';

const UserHookContract = ({
  railsContext,
  client,
  user,
  address,
  hasShowContractStep,
  submitFormData,
  registrationStep,
  showContractStep
}) => {
  const loadClickSign = useCallback(
    (key) => {
      const urlClicksign =
        railsContext.env !== 'production'
          ? 'https://sandbox.clicksign.com'
          : 'https://app.clicksign.com';

      if (key) {
        const widget = new Clicksign(client.contract_lote_key);
        widget.endpoint = urlClicksign;
        widget.origin = `${window.location.protocol}//${window.location.host}`;

        widget.mount('signature-box');

        widget.on('signed', () => {
          submitFormData(user, client, address);
        });
      }
    },
    [user, client, address, railsContext, submitFormData]
  );

  useEffect(() => {
    if (
      hasShowContractStep &&
      !!client.contract_lote_key &&
      location.href.split('?')[1] !== 'advanced=finalizado'
    ) {
      loadClickSign(client.contract_lote_key);
    }

    if (
      registrationStep === 'pending_blu_contract_signature' &&
      location.href.split('?')[1] !== 'advanced=finalizado'
    )
      showContractStep();
  }, [hasShowContractStep, client.contract_lote_key]);
};

export default UserHookContract;
