import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@useblu/ocean-react';
import { useAsyncFn } from 'react-use';
import { cancelBankBillet } from 'components/AccountStatement/helpers/service';

import { Title, Paragraph, Actions } from './styles';

export default function CancelButton({ uuid }) {
  const [isOpen, setIsOpen] = useState(false);

  const [state, request] = useAsyncFn(cancelBankBillet);

  const handleClose = () => {
    setIsOpen((old) => !old);
  };

  const handleSubmit = () => {
    request(uuid);

    if (!state?.loading) {
      window?.location?.reload();
    }
  };

  return (
    <>
      <button
        className="button button--secondary button--danger"
        style={{ width: '100%' }}
        onClick={handleClose}
        type="button"
      >
        {state?.loading ? 'Processando...' : 'Cancelar'}
      </button>
      <Modal
        ariaHideApp={false}
        portalClassName="ocean-iso"
        maxWidth="sm"
        blocked
        isOpen={isOpen}
        onRequestClose={handleClose}
      >
        <Title>Deseja mesmo cancelar esse pagamento?</Title>
        <Paragraph>Esta ação não poderá ser desfeita.</Paragraph>
        <Actions>
          <Button variant="secondary" onClick={handleClose}>
            Manter pagamento
          </Button>
          <Button
            variant="primary-critical"
            disabled={state?.loading}
            onClick={handleSubmit}
          >
            Sim, cancelar
          </Button>
        </Actions>
      </Modal>
    </>
  );
}

CancelButton.propTypes = {
  uuid: PropTypes.string.isRequired
};
