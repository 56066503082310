import { createReducer } from 'reduxsauce';
import { keyBy } from 'lodash';

import { types } from './actions';

const initialState = {
  data: {},
  loading: false,
  error: null,
  success: null
};

export default createReducer(initialState, {
  [types.FETCH_RPA_REGISTER_DATA_REQUEST]: (state, action) => ({
    ...state,
    loading: true,
    error: null
  }),
  [types.FETCH_RPA_REGISTER_DATA_SUCCESS]: (state, action) => ({
    ...state,
    data: action.data,
    success: true,
    loading: false,
    error: null
  }),
  [types.FETCH_RPA_REGISTER_DATA_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    success: null
  }),
  [types.CREATE_RPA_REGISTER_FORM_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: null
  }),
  [types.CREATE_RPA_REGISTER_FORM_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    success: action.success,
    error: null
  }),
  [types.CREATE_RPA_REGISTER_FORM_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    success: null
  })
});
