/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import _ from 'lodash';
import { Field } from 'redux-form';
import RenderField from 'components/Form/RenderField';
import Loading from 'react-loading';

class ClientAddress extends Component {
  handlerAddressField(e, fieldName) {
    const { address, name } = this.props;

    const newAddress = {
      ...address,
      [fieldName]: e.target.value
    };

    this.props.setAddressToReducer(newAddress, name);
  }

  handlerZipCode(e) {
    const { onZipcodeChange, name, address } = this.props;
    const zipcode = e.target.value;

    if (zipcode.length === 9) {
      onZipcodeChange(zipcode, name);
    } else {
      const newAddress = {
        ...address,
        street: '',
        neighborhood: '',
        city: '',
        state: '',
        number: '',
        complement: '',
        zipcode: e.target.value
      };

      this.props.setAddressToReducer(newAddress, name);
    }
  }

  renderAddressField(fieldName, text, fieldDisabled) {
    const { name, disabled, address = {} } = this.props;
    const value = address[fieldName] === 'null' ? '' : address[fieldName];
    return (
      <div className={`form-controls string required address_${fieldName}`}>
        <div>
          <Field
            type="text"
            className="string required"
            defaultValue={value || ''}
            component={RenderField}
            name={`client[${name}][${fieldName}]`}
            label={text}
            disabled={disabled || (fieldDisabled && !address.noFinded)}
            onChange={(e) => this.handlerAddressField(e, fieldName)}
            ref={`${fieldName}Input`}
          />
        </div>
      </div>
    );
  }

  renderOfficialAddress() {
    const { name, disabled, address, loading } = this.props;

    const zipcode = address.zipcode.replace('-', '');

    return (
      <div>
        <div className="form-row">
          <div className="form-controls string required">
            <div>
              <Field
                className="string required"
                type="text"
                defaultValue={address.zipcode || ''}
                name={`client[${name}][zipcode]`}
                component={RenderField}
                mask="zipcode"
                label="CEP"
                onChange={(e) => this.handlerZipCode(e)}
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        {loading && (
          <Loading
            type="spinningBubbles"
            color="#e1e5ed"
            width={35}
            delay={0}
            style={{ width: '50px', height: '40px', margin: '10px auto' }}
          />
        )}

        <div style={{ display: zipcode.length !== 8 ? 'none' : '' }}>
          <div className="form-row">
            {this.renderAddressField('street', 'Rua', true)}
          </div>
          <div className="form-row">
            {this.renderAddressField('number', 'Número')}
            {this.renderAddressField('complement', 'Complemento')}
          </div>
          <div className="form-row">
            {this.renderAddressField('neighborhood', 'Bairro', true)}
          </div>
          <div className="form-row">
            {this.renderAddressField('city', 'Cidade', true)}
            {this.renderAddressField('state', 'Estado (UF)', true)}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div className="flex-container">{this.renderOfficialAddress()}</div>;
  }
}

ClientAddress.propTypes = {
  setAddressToReducer: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ClientAddress;
