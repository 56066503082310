import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import {
  modalStyles,
  Header,
  BackButton,
  CloseButton
} from './ModalLayoutStyles';

const ModalLayout = ({ onClose, isOpen, showBack, title, children }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    contentLabel="Modal"
    style={modalStyles}
    ariaHideApp={false}
  >
    <Header>
      {showBack && (
        <BackButton type="button" onClick={onClose}>
          <i className="fa fa-caret-left" /> Voltar
        </BackButton>
      )}
      <h2>{title}</h2>
      {!showBack && (
        <CloseButton type="button" onClick={onClose}>
          ×
        </CloseButton>
      )}
    </Header>
    {children}
  </Modal>
);

ModalLayout.defaultProps = {
  showBack: false,
  isOpen: false
};

ModalLayout.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showBack: PropTypes.bool
};

export default ModalLayout;
