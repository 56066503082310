/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import _ from 'lodash';
import { Field } from 'redux-form';
import RenderField from 'components/Form/RenderField';
import Loading from 'react-loading';

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: {}
    };
  }

  updateState(data) {
    this.setState({
      address: {
        zipcode: data.cep || data.zipcode || '',
        number: data.numero || data.number || '',
        street: data.logradouro || data.street || '',
        neighborhood: data.bairro || data.neighborhood || '',
        city: data.localidade || data.city || '',
        state: data.uf || data.state || ''
      }
    });
  }

  updateAddress(data) {
    this.updateState(data);
    this.props.setAddressToReducer(this.state.address);
  }

  handlerAddressField(e, fieldName) {
    const { address } = this.state;
    address[fieldName] = e.target.value;

    this.updateAddress(address);
  }

  handlerZipCode(e) {
    const cep = e.target.value;
    this.handlerAddressField(e, 'zipcode');
    if (cep.length === 9) {
      this.props.onZipcodeChange(cep);
    }
  }

  isAddressIncompleted() {
    const { address = {} } = this.state;
    return (
      (address.zipcode && address.zipcode.replace('-', '').length < 8) ||
      (_.isEmpty(address.zipcode) &&
        _.isEmpty(address.city) &&
        _.isEmpty(address.state))
    );
  }

  renderAddressField(fieldName, text) {
    const { name, address = {} } = this.props;

    return (
      <div className={`form-controls string required address_${fieldName}`}>
        <label className="string required" htmlFor={`address_${fieldName}`}>
          {text}
        </label>
        <div>
          <Field
            className="string required"
            type="text"
            defaultValue={address[fieldName] || ''}
            name={`currentUser[${name}][${fieldName}]`}
            onChange={(e) => this.handlerAddressField(e, fieldName)}
            component={RenderField}
            ref={`${fieldName}Input`}
          />
        </div>
      </div>
    );
  }

  renderOfficialAddress() {
    const { name, address } = this.props;

    return (
      <div>
        <div className="form-row">
          <div className="form-controls string required address_zipcode">
            <label className="string required" htmlFor="address_zipcode">
              CEP
            </label>
            <div>
              <Field
                className="string required"
                type="text"
                defaultValue={address.zipcode || ''}
                name={`currentUser[${name}][zipcode]`}
                component={RenderField}
                mask="zipcode"
                onChange={(e) => this.handlerZipCode(e)}
              />
            </div>
          </div>
        </div>

        <div
          className="loading"
          style={{
            display: this.isAddressIncompleted() ? '' : 'none',
            width: 50,
            height: 50,
            margin: '100px 50%'
          }}
        >
          <Loading
            type="spinningBubbles"
            color="#e1e5ed"
            width="35px"
            delay={0}
            style={{ margin: '0 auto' }}
          />
        </div>

        <div style={{ display: this.isAddressIncompleted() ? 'none' : '' }}>
          <div className="form-row">
            {this.renderAddressField('street', 'Rua')}
          </div>
          <div className="form-row">
            {this.renderAddressField('number', 'Número')}
            {this.renderAddressField('complement', 'Complemento')}
          </div>
          <div className="form-row">
            {this.renderAddressField('neighborhood', 'Bairro')}
          </div>
          <div className="form-row">
            {this.renderAddressField('city', 'Cidade')}
            {this.renderAddressField('state', 'Estado (UF)')}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div className="flex-container">{this.renderOfficialAddress()}</div>;
  }
}

Address.propTypes = {
  setAddressToReducer: PropTypes.func.isRequired,
  onZipcodeChange: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default Address;
