import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { convertToTaxFormat } from '../static/staticTaxes';

const useTaxesByCnae = () => {
  const {
    currentClientVisaGetnetCnaeTaxPlan,
    currentClientMasterGetnetCnaeTaxPlan,
    currentClientEloGetnetCnaeTaxPlan,
    currentClientHiperGetnetCnaeTaxPlan,
    currentClientAmexGetnetCnaeTaxPlan
  } = useSelector((state) => ({
    ...state.railsContext
  }));

  const handleCheckIfHasTaxesByCnae = () =>
    !isEmpty(currentClientVisaGetnetCnaeTaxPlan) &&
    !isEmpty(currentClientMasterGetnetCnaeTaxPlan) &&
    !isEmpty(currentClientEloGetnetCnaeTaxPlan) &&
    !isEmpty(currentClientHiperGetnetCnaeTaxPlan) &&
    !isEmpty(currentClientAmexGetnetCnaeTaxPlan);

  const getCnaeTaxes = () => {
    if (handleCheckIfHasTaxesByCnae()) {
      return {
        debitVisa: convertToTaxFormat(
          currentClientVisaGetnetCnaeTaxPlan.debit_rate
        ),
        debitMaster: convertToTaxFormat(
          currentClientMasterGetnetCnaeTaxPlan.debit_rate
        ),
        debitAmex: convertToTaxFormat(
          currentClientAmexGetnetCnaeTaxPlan.debit_rate
        ),
        debitElo: convertToTaxFormat(
          currentClientEloGetnetCnaeTaxPlan.debit_rate
        ),
        debitHiper: convertToTaxFormat(
          currentClientHiperGetnetCnaeTaxPlan.debit_rate
        ),
        credit1xVisa: convertToTaxFormat(
          currentClientVisaGetnetCnaeTaxPlan.credit_rate_1
        ),
        credit1xMaster: convertToTaxFormat(
          currentClientMasterGetnetCnaeTaxPlan.credit_rate_1
        ),
        credit1xAmex: convertToTaxFormat(
          currentClientAmexGetnetCnaeTaxPlan.credit_rate_1
        ),
        credit1xElo: convertToTaxFormat(
          currentClientEloGetnetCnaeTaxPlan.credit_rate_1
        ),
        credit1xHiper: convertToTaxFormat(
          currentClientHiperGetnetCnaeTaxPlan.credit_rate_1
        ),
        credit2x6Visa: convertToTaxFormat(
          currentClientVisaGetnetCnaeTaxPlan.credit_rate_2
        ),
        credit2x6Master: convertToTaxFormat(
          currentClientMasterGetnetCnaeTaxPlan.credit_rate_2
        ),
        credit2x6Amex: convertToTaxFormat(
          currentClientAmexGetnetCnaeTaxPlan.credit_rate_2
        ),
        credit2x6Elo: convertToTaxFormat(
          currentClientEloGetnetCnaeTaxPlan.credit_rate_2
        ),
        credit2x6Hiper: convertToTaxFormat(
          currentClientHiperGetnetCnaeTaxPlan.credit_rate_2
        ),
        credit7x12Visa: convertToTaxFormat(
          currentClientVisaGetnetCnaeTaxPlan.credit_rate_7
        ),
        credit7x12Master: convertToTaxFormat(
          currentClientMasterGetnetCnaeTaxPlan.credit_rate_7
        ),
        credit7x12Amex: convertToTaxFormat(
          currentClientAmexGetnetCnaeTaxPlan.credit_rate_7
        ),
        credit7x12Elo: convertToTaxFormat(
          currentClientEloGetnetCnaeTaxPlan.credit_rate_7
        ),
        credit7x12Hiper: convertToTaxFormat(
          currentClientHiperGetnetCnaeTaxPlan.credit_rate_7
        )
      };
    }

    return {};
  };

  return { handleCheckIfHasTaxesByCnae, getCnaeTaxes };
};

export default useTaxesByCnae;
