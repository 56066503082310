/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import {
  borderWidthHairline,
  borderRadiusMd,
  borderRadiusLg,
  colorBrandPrimaryDown,
  colorInterfaceDarkDown,
  colorInterfaceDarkPure,
  colorInterfaceDarkUp,
  colorInterfaceLightDown,
  colorInterfaceLightPure,
  colorStatusPositiveDeep,
  fontSizeXs,
  fontSizeXxs,
  fontWeightMedium,
  spacingInlineMd,
  spacingInlineXxs,
  spacingInsetXs,
  spacingInsetSm,
  spacingInsetMd,
  spacingInsetLg,
  spacingInsetXl,
} from '@useblu/ocean-tokens/web/tokens';
import { breakpointTo } from '@useblu/utils';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-top: ${spacingInlineMd};

  ${breakpointTo('bpMedium')} {
    position: absolute;

    width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    margin: 0;

    background-color: ${colorInterfaceLightPure};

    z-index: 10;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: ${borderRadiusLg};

  overflow: hidden;

  ${breakpointTo('bpMedium')} {
    width: 100%;
    border-radius: 0;
  }
`;

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 466px;
  padding: ${spacingInsetMd} ${spacingInsetLg} ${spacingInsetXl};

  background-color: ${colorStatusPositiveDeep};

  & svg,
  .ods-typography {
    color: ${colorInterfaceLightPure};
  }

  & .ods-typography__heading3 {
    margin: 12px 0 ${spacingInlineXxs};
  }

  ${breakpointTo('bpMedium')} {
    width: auto;
    padding-top: 0;
  }
`;

export const TopBar = styled.div`
  width: 100%;
  padding: 10px ${spacingInsetXs};

  color: ${colorInterfaceLightPure};
  background-color: ${colorStatusPositiveDeep};

  & svg {
    height: 24px;
    width: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: ${spacingInsetLg};
  padding-bottom: ${spacingInsetMd};
  margin-top: -${borderRadiusLg};

  border: ${borderWidthHairline} solid ${colorInterfaceLightDown};
  border-radius: ${borderRadiusLg};
  background-color: ${colorInterfaceLightPure};

  & > .ods-typography__caption {
    margin-top: ${spacingInsetMd};

    color: ${colorInterfaceDarkUp};
  }

  ${breakpointTo('bpMedium')} {
    padding: ${spacingInsetMd} ${spacingInsetSm};

    border: none;

    & > .ods-typography__caption {
      margin-top: ${spacingInsetSm};
    }
  }
`;

export const ButtonContainer = styled.div`
  ${breakpointTo('bpMedium')} {
    position: absolute;

    bottom: 0;
    padding: ${spacingInsetSm};
    width: 100%;

    border-top: ${borderWidthHairline} solid ${colorInterfaceLightDown};

    & .ods-btn {
      width: 100%;
    }
  }
`;

export const TransactionList = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-bottom: ${spacingInsetMd};

  border: ${borderWidthHairline} solid ${colorInterfaceLightDown};
  border-radius: ${borderRadiusMd};

  overflow: hidden;

  ${breakpointTo('bpMedium')} {
    margin-bottom: 0;
  }
`;

export const TotalTransactions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 12px 6px 12px ${spacingInsetSm};

  border-bottom: ${({ $active }) =>
    $active
      ? `${borderWidthHairline} solid ${colorInterfaceLightDown}`
      : 'none'};
`;

export const TotalTransactionContent = styled.div`
  display: flex;
  flex-direction: column;

  & .ods-typography__description {
    color: ${colorInterfaceDarkPure};
    font-size: ${fontSizeXs};
    font-weight: ${fontWeightMedium};
  }

  & .ods-typography__caption {
    color: ${colorBrandPrimaryDown};
    font-size: ${fontSizeXxs};
  }
`;

export const TotalTransactionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  color: ${colorInterfaceDarkDown};
  cursor: pointer;

  transform: rotate(${(props) => (props.$active ? '180deg' : '0deg')});
  transition: transform 0.4s;
`;

export const TransactionListContent = styled.div`
  display: flex;
  flex-direction: column;

  height: ${({ $active, $height, $autoHeight }) =>
    $autoHeight ? ($active ? `${$height + 1}px` : '0px') : 'auto'};

  transition: height 0.4s;
  overflow: hidden;
`;
