import axios from 'axios';
import { normalizeCurrency, currencyToNumber } from 'normalizers';
import _ from 'lodash';
import { getCSRFToken } from 'utils/csrf-token';

export function validateBarCode(params, clientId) {
  return axios.post(`/lojas/${clientId}/boletos/validate.json`, {
    bank_billet: params,
  });
}

export function billetParams(values, referenceValue, duplicationConfirmed) {
  const {
    gross_value,
    transaction_category_id,
    inflow_released_at,
    uuid,
    clientEconomicGroup,
    ...rest
  } = values;

  const { bill_barcode, bill_payment_on, bill_receiver_name } = rest;

  return {
    transaction_category_id,
    uuid,
    gross_value: currencyToNumber(normalizeCurrency(gross_value)),
    inflow_released_at,
    headquarters_id: clientEconomicGroup,
    bank_transactions_attributes: [
      { bill_barcode, bill_payment_on, bill_receiver_name },
    ],
    pre_registration: true,
    observation: !_.isEmpty(referenceValue) ? referenceValue : null,
    similar_transaction_confirmed: duplicationConfirmed ? true : null,
  };
}

export function formatError(error) {
  const flattenedKeysError = _.mapKeys(error, (value, key) =>
    key.replace('bank_transactions.', '')
  );

  flattenedKeysError.gross_value =
    flattenedKeysError.net_value || flattenedKeysError.gross_value || [];

  return _.mapValues(flattenedKeysError, (value) => value.join(', '));
}

export function formatErrorCip(error) {
  const flattenedKeysError = _.mapKeys(error, (value, key) =>
    _.camelCase(key.replace('bank_transactions.', ''))
  );

  flattenedKeysError.paymentValue =
    flattenedKeysError.netValue ||
    flattenedKeysError.grossValue ||
    flattenedKeysError.billBarcode ||
    flattenedKeysError.paymentValue ||
    [];

  return _.mapValues(flattenedKeysError, (value) => value.join(', '));
}

export function validateBilletCIPApi({ barcode, clientUuid, date, bankType }) {
  if (bankType === 'Arbi') {
    const query = `
    query {
      billetCip(clientUuid: "${clientUuid}", billetBarCode: "${barcode.replace(
      /\.|\s/g,
      ''
    )}", paymentAt: ${date ? `"${date}"` : null}) {
        clientUuid
        arbiBilletCipRequestId
        status
        billetBarCode
        emittingBank
        emittingBankCode
        recipient
        recipientDocument
        payeeName
        payeeDocument
        dueDate
        duePayment
        amount
        rate
        discount
        netValue
        errorMessage
      }
    }`;

    return axios.post('/graphql', { query });
  }

  return axios.get(
    `/api/clients/${clientUuid}/payment_slips/${barcode.replace(
      /[^\d]+/g,
      ''
    )}`,
    {
      headers: {
        Accept: 'version=1',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );
}

export async function riskVerification(
  clientUuid,
  value,
  bankCode,
  recipientCpfCnpj
) {
  try {
    const response = await axios.post(
      '/api/fraud_detector/bank_billet/validate',
      {
        bank_billet: {
          client_uuid: clientUuid,
          value,
          emitting_bank_code: bankCode,
          recipient_document: recipientCpfCnpj,
        },
      },
      {
        headers: {
          Accept: 'version=1',
          'X-CSRF-Token': getCSRFToken(),
        },
      }
    );

    const { risk } = response?.data;

    return risk;
  } catch (error) {
    return 'high';
  }
}
