import React from 'react';
import PropsType from 'prop-types';
import { Grid, Typography } from '@useblu/ocean-react';

import * as S from './styles';

const Card = ({
  xs,
  sm,
  md,
  title,
  subtitle,
  icon,
  children,
  fullstory,
  url
}) => (
  <Grid.Col
    sm={{
      span: `${sm}`
    }}
    xs={{
      span: `${xs}`
    }}
    md={{
      span: `${md}`
    }}
  >
    <S.Wrapper>
      <S.Icon> {icon} </S.Icon>
      <S.Title>{title}</S.Title>
      <Typography variant="paragraph">{children}</Typography>
    </S.Wrapper>
  </Grid.Col>
);

Card.propsType = {
  xs: PropsType.number,
  sm: PropsType.number,
  md: PropsType.number,
  title: PropsType.string.isRequired,
  subtitle: PropsType.string.isRequired,
  icon: PropsType.string.isRequired,
  children: PropsType.string.isRequired,
  fullstory: PropsType.string.isRequired,
  inverse: PropsType.bool,
  url: PropsType.string
};

export default Card;
