/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  spacingInlineSm,
  spacingInlineMd,
  spacingStackXs,
  spacingStackMd,
  lineHeightMedium,
  fontWeightExtrabold,
  lineHeightComfy,
  fontSizeXs,
  fontSizeSm,
  colorInterfaceDarkDown,
  colorInterfaceDarkDeep,
} from '@useblu/ocean-tokens/web/tokens';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: ${spacingStackMd};
  gap: ${spacingStackXs};
`;

export const Image = styled.img`
  margin-bottom: ${spacingInlineSm};
  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: ${spacingInlineMd};
  }
`;

export const ModalTitle = styled.h1`
  font-weight: ${fontWeightExtrabold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMedium};
  color: ${colorInterfaceDarkDeep};
  margin-bottom: ${spacingStackXs};
`;

export const ModalParagraph = styled.p`
  font-size: ${fontSizeXs};
  line-height: ${lineHeightComfy};
  color: ${colorInterfaceDarkDown};
  margin-bottom: 0;
`;

export const ButtonContainer = styled.div`
  & > button {
    width: 100%;
  }

  @media (min-width: 768px) {
    margin-left: auto;
    min-width: 160px;
  }
`;
