import React from 'react';
import PropTypes from 'prop-types';
import ActionButton from './ActionButton';
import { BtnsWrap } from '../modal.styles';

const Actions = ({
  prevStep,
  nextStep,
  loading,
  disabled,
  nextText,
  nextClass,
  prevClass,
  hasPrev
}) => (
  <BtnsWrap>
    {hasPrev && (
      <ActionButton
        variant="secondary"
        className={`modal_botaovoltar${prevClass}`}
        onClick={prevStep}
        loading={loading}
        disabled={loading}
      >
        Voltar
      </ActionButton>
    )}
    <ActionButton
      disabled={disabled || loading}
      onClick={nextStep}
      loading={loading}
      className={`modal_botaoavancar${nextClass}`}
    >
      {nextText}
    </ActionButton>
  </BtnsWrap>
);

Actions.propTypes = {
  prevStep: PropTypes.func,
  nextStep: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  hasPrev: PropTypes.bool,
  nextText: PropTypes.string,
  disabled: PropTypes.bool,
  nextClass: PropTypes.string,
  prevClass: PropTypes.string
};

Actions.defaultProps = {
  prevStep: () => false,
  hasPrev: true,
  disabled: false,
  nextText: 'Avançar',
  nextClass: '',
  prevClass: ''
};

export default Actions;
