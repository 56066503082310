import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

export function deactivateBankAccount(clientId, payee, payable) {
  return axios.post(`/lojas/${clientId}/transacoes/deactivate`, {
    payee_id: payee.id,
    payable
  });
}

export function createHistoryPayeeApi(clientUUID, bankAccount, ignoreReplicas) {
  return axios.post(
    `/api/clients/${clientUUID}/bank_accounts`,
    {
      bank_account: bankAccount,
      ignore_replicas: ignoreReplicas
    },
    {
      headers: {
        Accept: 'version=1',
        'X-CSRF-Token': getCSRFToken()
      }
    }
  );
}

export function getRegisteredAccount(uuid, clientUuid) {
  return axios.get(`/api/clients/${uuid}/bank_accounts/${clientUuid}`, {
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken()
    }
  });
}

export function updateRegisteredAccount(
  uuid,
  clientUuid,
  bankAccount,
  ignoreReplicas
) {
  return axios.put(
    `/api/clients/${uuid}/bank_accounts/${clientUuid}`,
    {
      bank_account: bankAccount,
      ignore_replicas: ignoreReplicas
    },
    {
      headers: {
        Accept: 'version=1',
        'X-CSRF-Token': getCSRFToken()
      }
    }
  );
}

export function getBankBy(by, value) {
  return axios.get(`/api/banks/by?${by}=${value}`, {
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken()
    }
  });
}
