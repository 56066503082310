import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ScoreIndicator from '../../ScoreIndicator';
import MissingModal from './Modals/MissingModal';
import DetailsModal from './Modals/DetailsModal';
import { ShortMessage, ActionButton } from './Styles';
import ScoreCardChargedLayout from './ScoreCardChargedLayout';
import ScoreCardChargedError from './ScoreCardChargedError';
import ScoreCardChargedLoading from './ScoreCardChargedLoading';

const ScoreCardCharged = ({ retryFn, error, loading, data }) => {
  const [showModal, setShowModal] = useState(false);
  const hasActionButton =
    data?.relationshipLevel.rating.name !== 'blocked' && Boolean(data?.scores);

  const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  if (error) {
    return <ScoreCardChargedError retryFn={retryFn} />;
  }

  if (loading) {
    return <ScoreCardChargedLoading />;
  }

  let actionName = 'Saber Mais';
  let modal = <MissingModal isOpen={showModal} onClose={toggleShowModal} />;
  if (
    data?.relationshipLevel.rating.name !== 'default' &&
    Boolean(data?.scores)
  ) {
    actionName = 'Detalhar';
    modal = (
      <DetailsModal
        isOpen={showModal}
        onClose={toggleShowModal}
        relationshipLevel={data.relationshipLevel}
        longMessage={data.longMessage}
        ranges={data.ranges}
        scores={data.scores}
      />
    );
  }

  return (
    <ScoreCardChargedLayout>
      <ScoreIndicator rating={data.relationshipLevel.rating} />
      <ShortMessage
        dangerouslySetInnerHTML={{ __html: data.shortMessage.thirdPerson }}
      />
      {hasActionButton && (
        <ActionButton type="button" onClick={toggleShowModal}>
          {actionName}
        </ActionButton>
      )}
      {modal}
    </ScoreCardChargedLayout>
  );
};

ScoreCardCharged.defaultProps = {
  error: false,
  loading: false,
  data: null
};

ScoreCardCharged.propTypes = {
  retryFn: PropTypes.func.isRequired,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    relationshipLevel: PropTypes.shape({
      rating: PropTypes.shape({
        name: PropTypes.string.isRequired
      }).isRequired
    }).isRequired,
    longMessage: PropTypes.shape({
      firstPerson: PropTypes.string.isRequired,
      thirdPerson: PropTypes.string.isRequired
    }).isRequired,
    shortMessage: PropTypes.shape({
      firstPerson: PropTypes.string.isRequired,
      thirdPerson: PropTypes.string.isRequired
    }).isRequired,
    ranges: PropTypes.object,
    scores: PropTypes.object
  })
};

export default ScoreCardCharged;
