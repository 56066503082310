import styled from 'styled-components';
import {
  colorStatusNegativePure,
  fontSizeXs,
  spacingInlineXxs,
  spacingStackMd,
  spacingStackXs
} from '@useblu/ocean-tokens/web/tokens';
import { Typography } from '@useblu/ocean-react';
import { Devices } from 'utils/getDeviceMedia';

export const BoxContent = styled.div`
  flex: 0 0 63.157894737%;
  padding-left: 24px;
  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 183%;
  }
  h2 {
    margin: 0 0 16px 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
  }
  img {
    margin-right: 10px;
  }
  @media ${Devices.tablet}, ${Devices.mobile} {
    flex: 0 0 100%;
    padding: 0 0 16px 0;
    margin: 0;
  }
`;

export const BoxAction = styled.div`
  flex: 0 0 18.421052631%;
  display: flex;
  justify-content: center;
  button,
  a {
    font-weight: bold;
    font-size: ${fontSizeXs};
    line-height: 100%;
    color: ${colorStatusNegativePure};
    background-color: Transparent;
  }
  @media ${Devices.tablet}, ${Devices.mobile} {
    flex: 0 0 100%;
    padding: 56px 0 0 0;
  }
`;

export const StyledH3 = styled(Typography)`
  &&& {
    color: ${colorStatusNegativePure};
    margin-bottom: ${spacingStackXs};
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${spacingInlineXxs};
  margin-top: ${spacingStackMd};

  @media ${Devices.tablet}, ${Devices.mobile} {
    justify-content: space-evenly;
    > button {
      width: 100%;
    }
  }
`;
