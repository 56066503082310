import _ from 'lodash';

const validateNaturalSignUpApp = (values, props) => {
  const errors = { client: { user: {} } };
  const client = _.merge(values.client, props.client);

  if (client) {
    if (_.isNil(props.partnerId) && !client.department_id) {
      errors.client = {
        ...errors.client,
        department_id: 'escolha o departamento do cliente'
      };
    }

    if (_.isEmpty(client.cpf_cnpj)) {
      errors.client = {
        ...errors.client,
        cpf_cnpj: 'não pode ficar em branco'
      };
    }

    if (_.isEmpty(client.name)) {
      errors.client = {
        ...errors.client,
        name: 'não pode ficar em branco'
      };
    }

    if (_.isEmpty(client.business_name)) {
      errors.client = {
        ...errors.client,
        business_name: 'não pode ficar em branco'
      };
    }

    if (
      (props.clientType === 'branch' || props.clientType === 'client') &&
      !client.zendesk_origin_ticket_id
    ) {
      errors.client = {
        ...errors.client,
        zendesk_origin_ticket_id: 'não pode ficar em branco'
      };
    }

    if (_.isEmpty(client.user)) {
      errors.client = {
        ...errors.client,
        user: {
          ...errors.client.user,
          email: 'não pode ficar em branco'
        }
      };
    }

    if (!_.isEmpty(client.user)) {
      if (
        client.user.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(client.user.email)
      ) {
        errors.client = {
          ...errors.client,
          user: {
            ...errors.client.user,
            email: 'formato inválido'
          }
        };
      }

      if (_.isEmpty(client.user.phone)) {
        errors.client = {
          ...errors.client,
          user: {
            ...errors.client.user,
            phone: 'não pode ficar em branco'
          }
        };
      }
    }
  }

  return errors;
};

export default validateNaturalSignUpApp;
