/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { connect } from 'react-redux';
import { compose } from 'redux';

/* Actions and Validators */
import usersAccountsActions from 'store/ducks/firstAccess/usersAccounts';
import qsaAccountsActions from 'store/ducks/firstAccess/qsaAccounts';
import sharedActions from 'store/ducks/firstAccess/shared';
import history from 'utils/browserHistory';

/* Presentational */
import { reduxForm } from 'redux-form';
import Loading from 'react-loading';
import _ from 'lodash';
import { maskCpf } from 'utils/masks';
import { clientSelector, qsaCpfSelector } from 'selectors/FirstAccess/shared';
import validateUsersAccountsForm from 'validations/ValidadeUsersAccountsForm';
import UsersAccountsEditModal from './usersAccountsEditModal';
import UsersAccountsDeleteModal from './usersAccountsDeleteModal';

class UsersAccountsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveModalIsOpen: false,
      deleteModalIsOpen: false,
      showUsersList: false,
      type: 'new'
    };
  }

  componentDidMount() {
    const {
      setCurrentStep,
      usersAccountsQsaRequest,
      usersAccountsRequest,
      client,
      uuid
    } = this.props;
    setCurrentStep('usersAccounts');

    if (client) usersAccountsQsaRequest(client);
    usersAccountsRequest(uuid);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      client: nextClient,
      shouldCloseDeleteModal,
      shouldCloseSaveModal
    } = nextProps;
    const {
      client,
      usersAccountsQsaRequest,
      usersAccountsRequest,
      uuid
    } = this.props;

    if (_.isEmpty(client) && !_.isEmpty(nextClient)) {
      usersAccountsQsaRequest(nextClient);
    }

    if (shouldCloseDeleteModal) {
      usersAccountsRequest(uuid);
      this.closeDeleteModal();
    }

    if (shouldCloseSaveModal) {
      this.closeSaveModal();
    }
  }

  setAddress(address, user) {
    const { usersAccountsSetUserAddress } = this.props;
    usersAccountsSetUserAddress(address, user);
  }

  setCurrentUser(user) {
    const { usersAccountsSetCurrentUser } = this.props;
    usersAccountsSetCurrentUser(user);
  }

  next(e) {
    e.preventDefault();
    history.push(
      `/lojas/${this.props.uuid}/primeiro-acesso/assinatura-de-contrato`
    );
  }

  previous(e) {
    e.preventDefault();
    history.push(
      `/lojas/${this.props.uuid}/primeiro-acesso/informacoes-dos-socios`
    );
  }

  deleteUser() {
    const { currentUser, client, usersAccountsDeleteUser } = this.props;
    usersAccountsDeleteUser(currentUser.email, client.uuid);
  }

  editUser(user, e) {
    e.preventDefault();
    this.setCurrentUser(user);
    this.setState({ type: 'edit' });
    this.openSaveModal();
  }

  closeSaveModal() {
    this.setCurrentUser({});
    this.setState({ saveModalIsOpen: false });
  }

  closeDeleteModal() {
    this.setCurrentUser({});
    this.setState({ deleteModalIsOpen: false });
  }

  openSaveModal() {
    this.setState({ saveModalIsOpen: true });
  }

  openDeleteModal(e, user) {
    e.preventDefault();
    this.setCurrentUser(user);
    this.setState({ deleteModalIsOpen: true });
  }

  insertNewUser(e, user) {
    e.preventDefault();
    this.setCurrentUser(user);
    this.setState({ type: 'new' });
    this.openSaveModal();
  }

  choosePartner(e, partner) {
    e.preventDefault();
    this.insertNewUser(e, {
      name: partner.name,
      cpf: partner.cpfCnpj
    });
  }

  saveUser() {
    const {
      uuid,
      currentUser,
      usersAccountsSaveUser,
      usersAccountsUpdateUser
    } = this.props;

    if (currentUser.id) {
      usersAccountsUpdateUser(currentUser, uuid);
    } else {
      usersAccountsSaveUser(currentUser, uuid);
    }

    this.setState({ showUsersList: true });
  }

  showUsersList(e) {
    e.preventDefault();
    this.setState({ showUsersList: true });
  }

  showQsaList(e) {
    e.preventDefault();
    this.setState({ showUsersList: false });
  }

  renderLoading() {
    return (
      <div className="loading">
        <Loading
          type="spinningBubbles"
          color="#e1e5ed"
          width="100px"
          height="100px"
          delay={0}
        />
      </div>
    );
  }

  renderUserEditModal() {
    const {
      currentUser,
      saveModalLoading,
      usersAccountsSearchUserAddress,
      errors
    } = this.props;

    return (
      <UsersAccountsEditModal
        user={currentUser}
        isOpen={this.state.saveModalIsOpen}
        loading={saveModalLoading}
        setUser={(user) => this.setCurrentUser(user)}
        onAddressSearch={(zipcode) => usersAccountsSearchUserAddress(zipcode)}
        onAddressSuccess={(address) => this.setAddress(address)}
        onClose={() => this.closeSaveModal()}
        onSave={() => this.saveUser()}
        errors={errors}
        type={this.state.type}
        invalid={this.props.invalid}
      />
    );
  }

  renderUserDeleteModal() {
    const { deleteModalLoading } = this.props;
    return (
      <UsersAccountsDeleteModal
        isOpen={this.state.deleteModalIsOpen}
        loading={deleteModalLoading}
        onClose={() => this.closeDeleteModal()}
        onDelete={() => this.deleteUser()}
      />
    );
  }

  renderUsersList() {
    const {
      users,
      railsContext: { currentUserCpf },
      permission
    } = this.props;
    let content;

    content = _.map(
      users,
      (user, i) =>
        user.cpf !== currentUserCpf && (
          <div className="first-access-app--users-list list--item" key={i}>
            <div className="list--content">
              <p>
                <b>{user.name}</b>
              </p>
              <p>{maskCpf(user.cpf)}</p>
            </div>
            {permission.admin && (
              <div className="list--actions">
                <button
                  className="button--round"
                  onClick={(e) => this.editUser(user, e)}
                >
                  <i className="fa fa-pencil" />
                </button>
                <button
                  className="button--round button--round-error"
                  onClick={(e) => this.openDeleteModal(e, user)}
                >
                  <i className="fa fa-trash" />
                </button>
              </div>
            )}
          </div>
        )
    );

    if (users.length <= 1) {
      content = (
        <div className="first-access-app--users-list list--item list--empty list">
          <div className="list--content list--content--empty">
            <i className="fa fa-user-times list__icon list__icon--centered" />
            <p className="list--content-text">
              Por enquanto so tem você como usuário, deseja adicionar mais
              alguém?
            </p>
          </div>
        </div>
      );
    }

    return content;
  }

  renderContent() {
    const {
      formLoading,
      qsa,
      railsContext: { currentUserCpf },
      users,
      permission
    } = this.props;
    const { showUsersList } = this.state;

    const qsaUsers = qsa.filter(
      (qsa) =>
        users.length > 0 &&
        qsa.cpfCnpj.length <= 11 &&
        permission.admin &&
        users.filter((user) => user.cpf === qsa.cpfCnpj).length === 0
    );

    return (
      <div className="form-fields-for">
        <h2 className="page-subtitle">
          <i className="fa fa-user" /> Contas de Usuários
        </h2>
        <form className="form-fields-for" noValidate>
          <div
            style={{
              display: _.isEmpty(qsaUsers) || showUsersList ? 'none' : ''
            }}
          >
            <h3 className="slide-subtitle">
              Deseja escolher algum sócio da lista para ser usuário da conta?
            </h3>
            {_.map(
              qsaUsers,
              (partner, i) =>
                partner.cpfCnpj !== currentUserCpf && (
                  <div
                    className="first-access-app--users-list list--item"
                    key={i}
                  >
                    <div className="list--content">
                      <p>
                        <b>{partner.name}</b>
                      </p>
                      <p>
                        {partner.companyAssociates &&
                          partner.companyAssociates[0].position}
                      </p>
                      <p>{maskCpf(partner.cpfCnpj)}</p>
                    </div>
                    <div className="list--actions">
                      <button
                        className="button--round button--round-green"
                        onClick={(e) => this.choosePartner(e, partner)}
                      >
                        <i className="fa fa-user-plus" />
                      </button>
                    </div>
                  </div>
                )
            )}
            <div className="or">OU</div>
            <div className="form-row actions">
              <button
                className="button form-controls button--secondary"
                onClick={(e) => this.showUsersList(e)}
              >
                Agora não
              </button>
              <button
                type="button"
                className="button form-controls button--primary"
                onClick={(e) => this.insertNewUser(e, { mainAddress: {} })}
              >
                Adicionar novo usuário
              </button>
            </div>
          </div>
          <div
            style={{
              display: showUsersList || _.isEmpty(qsaUsers) ? '' : 'none'
            }}
          >
            <h3 className="page-description">Usuários da sua conta BLU.</h3>
            {formLoading ? this.renderLoading() : this.renderUsersList()}
            {formLoading ? (
              ''
            ) : (
              <div className="form-row actions">
                {qsaUsers.length > 0 && permission.admin && (
                  <button
                    type="button"
                    className="button button--medium button--secondary is-auto-size"
                    onClick={(e) => this.showQsaList(e)}
                  >
                    Escolher um sócio
                  </button>
                )}
                {permission.admin && (
                  <button
                    type="button"
                    className="button button--medium button--secondary is-auto-size"
                    onClick={(e) => this.insertNewUser(e, { mainAddress: {} })}
                  >
                    Adicionar novo usuário
                  </button>
                )}
              </div>
            )}
          </div>
          <div
            className="form-row actions"
            style={{
              display: showUsersList || _.isEmpty(qsaUsers) ? '' : 'none'
            }}
          >
            <button
              className="button form-controls button--secondary"
              onClick={(e) => this.previous(e)}
            >
              Voltar
            </button>
            <button
              className="button form-controls button--primary"
              onClick={(e) => this.next(e)}
            >
              Confirmar
            </button>
          </div>
        </form>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="flex-container">
          {this.renderContent()}
          {this.renderUserEditModal()}
          {this.renderUserDeleteModal()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.firstAccessUsersAccounts.users,
  qsa: qsaCpfSelector(state),
  client: clientSelector(state),
  formLoading: state.firstAccessUsersAccounts.formLoading,
  saveModalLoading: state.firstAccessUsersAccounts.saveModalLoading,
  deleteModalLoading: state.firstAccessUsersAccounts.deleteModalLoading,
  shouldCloseDeleteModal: state.firstAccessUsersAccounts.shouldCloseDeleteModal,
  shouldCloseSaveModal: state.firstAccessUsersAccounts.shouldCloseSaveModal,
  errors: state.firstAccessUsersAccounts.errors,
  currentUser: state.firstAccessUsersAccounts.currentUser,
  railsContext: state.railsContext,
  initialValues: {
    currentUser: state.firstAccessUsersAccounts.currentUser,
    client: clientSelector(state),
    qsa: qsaCpfSelector(state)
  },
  permission: state.permission
});

const mapDispatchToProps = (dispatch) => ({
  usersAccountsRequest: (uuid) =>
    dispatch(usersAccountsActions.firstAccessUsersAccountsRequest(uuid)),
  usersAccountsQsaRequest: (client) =>
    dispatch(qsaAccountsActions.firstAccessQsaAccountsRequest(client)),
  usersAccountsSearchUserAddress: (zipcode) =>
    dispatch(
      usersAccountsActions.firstAccessUsersAccountsSearchAddress(zipcode)
    ),
  usersAccountsSetUserAddress: (address) =>
    dispatch(usersAccountsActions.firstAccessUsersAccountsSetAddress(address)),
  usersAccountsDeleteUser: (email, clientUuid) =>
    dispatch(
      usersAccountsActions.firstAccessUsersAccountsDeleteUser(email, clientUuid)
    ),
  usersAccountsSetCurrentUser: (user) =>
    dispatch(usersAccountsActions.firstAccessUsersAccountsSetCurrentUser(user)),
  usersAccountsSaveUser: (user, uuid) =>
    dispatch(usersAccountsActions.firstAccessUsersAccountsSaveUser(user, uuid)),
  usersAccountsUpdateUser: (user, uuid) =>
    dispatch(
      usersAccountsActions.firstAccessUsersAccountsUpdateUser(user, uuid)
    ),
  setCurrentStep: (step) => dispatch(sharedActions.firstAccessCurrentStep(step))
});

UsersAccountsForm.propTypes = {
  uuid: PropTypes.string.isRequired,
  usersAccountsRequest: PropTypes.func.isRequired,
  usersAccountsQsaRequest: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  qsa: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  usersAccountsSearchUserAddress: PropTypes.func.isRequired,
  usersAccountsSetUserAddress: PropTypes.func.isRequired,
  usersAccountsDeleteUser: PropTypes.func.isRequired,
  usersAccountsSetCurrentUser: PropTypes.func.isRequired,
  usersAccountsSaveUser: PropTypes.func.isRequired,
  usersAccountsUpdateUser: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  saveModalLoading: PropTypes.bool,
  deleteModalLoading: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  railsContext: PropTypes.shape({}).isRequired,
  shouldCloseDeleteModal: PropTypes.bool.isRequired,
  shouldCloseSaveModal: PropTypes.bool.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({})
  ]),
  permission: PropTypes.shape({}).isRequired
};

UsersAccountsForm.defaultProps = {
  formLoading: false,
  saveModalLoading: false,
  deleteModalLoading: false,
  client: {},
  qsa: [],
  errors: []
};

const UsersAccountsFormReduxForm = reduxForm({
  form: 'usersAccountsForm',
  initialValues: { enabled: true },
  validate: validateUsersAccountsForm,
  asyncValidating: true,
  shouldValidate() {
    return true;
  },
  enableReinitialize: true
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  UsersAccountsFormReduxForm
)(UsersAccountsForm);
