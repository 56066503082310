import styled from 'styled-components';

import { Button } from '@useblu/ocean-react';

import {
  colorBrandPrimaryDown,
  colorInterfaceLightPure
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  padding: 32px;
  background-color: ${colorBrandPrimaryDown};
  display: flex;
  flex-direction: column-reverse;

  img {
    max-width: 242px;
    width: 50%;
    min-width: 182px;
  }

  @media screen and (min-width: 769px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    div {
      max-width: 442px;
      margin-right: 60px;
    }
    img {
      max-width: 289px;
      width: auto;
    }
  }
`;

export const Title = styled.div`
  font-weight: 300;
  font-size: 32px;
  line-height: 110%;
  padding: 24px 0px;
  color: ${colorInterfaceLightPure};
`;

export const Action = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;
