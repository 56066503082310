import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ClientSelectorActions from 'store/ducks/clientSelector';
import ClientStatusCreators from 'store/ducks/clientStatus';

const useClientFeatureStatus = () => {
  const dispatch = useDispatch();

  const {
    loading: statusLoading,
    cercAgreementOptInConfirmed,
    socialContractStatus,
    cercAgreementOptInAgreedAt,
    automaticAnticipationEnabledAt,
    cercAgreementOptInStatus,
    federalRevenueStatus,
    multiAcquirerOrdersOptInConfirmed,
    automaticAnticipationOptInConfirmed,
    addresses,
    needSocialContract,
    automaticAnticipationEnabledType
  } = useSelector((state) => ({
    ...state.clientStatus
  }));

  const stores = useSelector((state) => state.clientSelector?.data || []);

  const [loading, setLoading] = useState(statusLoading);

  useEffect(() => {
    setLoading(statusLoading);
  }, [statusLoading]);

  const {
    clientId,
    clientClientId,
    enabledExternalAcquirerIds,
    currentClientRealName,
    currentUserName,
    currentClientName,
    multiAcquirerOrdersEnabled
  } = useSelector((state) => ({
    ...state.railsContext
  }));

  const clientUUID = clientId || clientClientId;

  const updateStatus = () => {
    dispatch(ClientStatusCreators.getClientStatusRequest(clientUUID));
  };

  useEffect(() => {
    dispatch(ClientSelectorActions.getCompaniesStoreRequest(clientUUID));
    dispatch(ClientStatusCreators.getClientStatusRequest(clientUUID));
  }, [dispatch, clientUUID]);

  return {
    clientUUID,
    storeName:
      currentClientRealName || currentClientName || currentUserName || '',
    loading,
    stores,
    cercAgreementOptInAgreedAt,
    multiAcquirerOrdersEnabled,
    automaticAnticipationEnabledAt,
    automaticAnticipationOptInConfirmed,
    automaticAnticipationEnabledType,
    cercAgreementOptInStatus,
    federalRevenueStatus,
    cercAgreementOptInConfirmed,
    enabledExternalAcquirerIds,
    multiAcquirerOrdersOptInConfirmed,
    socialContractStatus,
    needSocialContract,
    addresses,
    setLoading,
    updateStatus
  };
};

export default useClientFeatureStatus;
