import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';

import DeviceCardLoading from './DeviceCardLoading';
import DeviceCardError from './DeviceCardError';
import DeviceCardSuccess from './DeviceCardSuccess';
import DeviceCardList from './DeviceCardList';

const DeviceCardStates = () => {
  const { loading, error, byId, success } = useSelector(
    (state) => state.bToken
  );

  const itemLoading = map(byId)[0]?.loading;

  if (loading) return <DeviceCardLoading />;
  if (success) return <DeviceCardSuccess />;

  return error ? (
    <DeviceCardError {...error} loading={itemLoading} />
  ) : (
    <DeviceCardList data={map(byId)} />
  );
};

export default DeviceCardStates;
