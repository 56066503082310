import React from 'react';
import { WarningContainer } from 'components/Devise/devise.styles';
import { Typography, Button } from '@useblu/ocean-react';
import { useMedia } from 'react-use';
import DeviseScreenLayout from '../DeviseScreenLayout';
import ilustraCanceled from '../../images/ilustra-bad-request.svg';

const CanceledAccountWarning = () => {
  const isMobile = useMedia('(max-width: 768px)');
  return (
    <DeviseScreenLayout className="adjust-logo">
      <WarningContainer $withCaptionOnMobile>
        <img src={ilustraCanceled} alt="conta cancelada" />
        <Typography variant="heading2">Essa conta foi cancelada</Typography>
        <Typography variant="paragraph">
          Para ativar novamente a sua conta ligue para o canal direto do lojista
          pelo telefone 3003 0807 (capitais e regiões metropolitanas) ou no 0800
          326 0807 (demais regiões).
        </Typography>
        {isMobile && (
          <Button
            variant="primary"
            onClick={() => window.location.assign('/users/sign_in')}
          >
            Voltar ao início
          </Button>
        )}
      </WarningContainer>
    </DeviseScreenLayout>
  );
};

export default CanceledAccountWarning;
