import _ from 'lodash';

const validateClientInfoForm = (values, props) => {
  const errors = {
    client: { mainAddress: {}, posDeliveryAddress: {}, officialBankAccount: {} }
  };
  const params = _.merge(values, props);

  if (params.client) {
    const { client } = params;

    if (_.isEmpty(client.cpfCnpj)) {
      errors.client = {
        ...errors.client,
        cpfCnpj: 'não pode ficar em branco'
      };
    }

    if (_.isEmpty(client.name)) {
      errors.client = {
        ...errors.client,
        name: 'não pode ficar em branco'
      };
    }

    if (_.isEmpty(client.businessName)) {
      errors.client = {
        ...errors.client,
        businessName: 'não pode ficar em branco'
      };
    }

    if (client.mainAddress) {
      const { mainAddress } = client;

      if (_.isEmpty(mainAddress.zipcode)) {
        errors.client = {
          ...errors.client,
          mainAddress: {
            ...errors.client.mainAddress,
            zipcode: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(mainAddress.street)) {
        errors.client = {
          ...errors.client,
          mainAddress: {
            ...errors.client.mainAddress,
            street: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(mainAddress.number)) {
        errors.client = {
          ...errors.client,
          mainAddress: {
            ...errors.client.mainAddress,
            number: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(mainAddress.neighborhood)) {
        errors.client = {
          ...errors.client,
          mainAddress: {
            ...errors.client.mainAddress,
            neighborhood: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(mainAddress.city)) {
        errors.client = {
          ...errors.client,
          mainAddress: {
            ...errors.client.mainAddress,
            city: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(mainAddress.state)) {
        errors.client = {
          ...errors.client,
          mainAddress: {
            ...errors.client.mainAddress,
            state: 'não pode ficar em branco'
          }
        };
      }
    } else {
      errors.client = {
        ...errors.client,
        mainAddress: {
          ...errors.client.mainAddress,
          zipcode: 'não pode ficar em branco'
        }
      };
    }

    if (client.posDeliveryAddress) {
      const { posDeliveryAddress } = client;

      if (_.isEmpty(posDeliveryAddress.zipcode)) {
        errors.client = {
          ...errors.client,
          posDeliveryAddress: {
            ...errors.client.posDeliveryAddress,
            zipcode: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(posDeliveryAddress.street)) {
        errors.client = {
          ...errors.client,
          posDeliveryAddress: {
            ...errors.client.posDeliveryAddress,
            street: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(posDeliveryAddress.number)) {
        errors.client = {
          ...errors.client,
          posDeliveryAddress: {
            ...errors.client.posDeliveryAddress,
            number: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(posDeliveryAddress.neighborhood)) {
        errors.client = {
          ...errors.client,
          posDeliveryAddress: {
            ...errors.client.posDeliveryAddress,
            neighborhood: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(posDeliveryAddress.city)) {
        errors.client = {
          ...errors.client,
          posDeliveryAddress: {
            ...errors.client.posDeliveryAddress,
            city: 'não pode ficar em branco'
          }
        };
      }

      if (_.isEmpty(posDeliveryAddress.state)) {
        errors.client = {
          ...errors.client,
          posDeliveryAddress: {
            ...errors.client.posDeliveryAddress,
            state: 'não pode ficar em branco'
          }
        };
      }
    } else {
      errors.client = {
        ...errors.client,
        posDeliveryAddress: {
          ...errors.client.posDeliveryAddress,
          zipcode: 'não pode ficar em branco'
        }
      };
    }
  }

  return errors;
};

export default validateClientInfoForm;
