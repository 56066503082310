import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  uploadTransferFileRequest: ['file', 'clientUuid'],
  uploadTransferFileSuccess: ['fileUuid'],
  uploadTransferFileFailure: ['errorMessage'],
  getImportPaymentConfirmationRequest: ['fileUuid'],
  getImportPaymentConfirmationSuccess: ['data'],
  getImportPaymentConfirmationFailure: ['errorMessage'],
  setImportPaymentConfirmationRequest: ['uuid', 'transactions'],
  setImportPaymentConfirmationSuccess: [
    'data',
    'errorMessage',
    'duplicatedTransaction',
    'taxesValues',
  ],
  setImportPaymentConfirmationFailure: ['errorMessage'],
  updateImportPaymentConfirmation: ['dataEdit'],
  updateDataImportPaymentConfirmation: ['data', 'duplicatedTransactions'],
  confirmImportPaymentRequest: ['uuid', 'reference'],
  confirmImportPaymentSuccess: null,
  confirmImportPaymentFailure: ['errorMessage'],
  closeDuplicatedTransaction: null,
  clearImportPaymentConfirmation: null,
  setFileUuid: ['fileUuid'],
  clearErrors: null,
});

export { Types, Creators };
export default Creators;

const INITIAL_STATE = {
  fileUuid: null,
  loading: false,
  loadingConfirm: false,
  data: [],
  dataEdit: {},
  taxesValues: {},
  error: false,
  errorMessage: '',
  importLoading: false,
  duplicatedTransaction: false,
};

export const uploadTransferFileRequest = (state) => ({
  ...state,
  importLoading: true,
});

export const uploadTransferFileSuccess = (state, action) => ({
  ...state,
  fileUuid: action.fileUuid,
  errorMessage: '',
});

export const uploadTransferFileFailure = (state, action) => ({
  ...state,
  errorMessage: action.errorMessage,
  importLoading: false,
});

export const request = (state) => ({
  ...state,
  loading: true,
});

export const failure = (state, action) => ({
  ...state,
  loading: false,
  error: true,
  errorMessage: action.errorMessage,
});

export const getImportPaymentConfirmationSuccess = (state, action) => ({
  ...state,
  loading: false,
  data: action.data,
});

export const setImportPaymentConfirmationRequest = (state) => ({
  ...state,
  loadingConfirm: true,
});

export const setImportPaymentConfirmationSuccess = (state, action) => ({
  ...state,
  loadingConfirm: false,
  data: [...action.data],
  taxesValues: action.taxesValues,
  errorMessage: action.duplicatedTransaction ? '' : action.errorMessage,
  duplicatedTransaction: action.duplicatedTransaction,
});

export const setImportPaymentConfirmationFailure = (state, action) => ({
  ...state,
  loadingConfirm: false,
  errorMessage: action.errorMessage,
  errorImportData: true,
});

export const updateImportPaymentConfirmation = (state, action) => ({
  ...state,
  dataEdit: {
    ...state.dataEdit,
    ...action.dataEdit,
  },
});

export const updateDataImportPaymentConfirmation = (state, action) => ({
  ...state,
  data: [...action.data],
  duplicatedTransaction: action.duplicatedTransaction,
  dataEdit: {},
});

export const confirmImportPaymentRequest = (state) => ({
  ...state,
  loadingConfirm: true,
  errorMessage: '',
});

export const confirmImportPaymentSuccess = (state) => ({
  ...state,
  loadingConfirm: false,
});

export const confirmImportPaymentFailure = (state, action) => ({
  ...state,
  loadingConfirm: false,
  errorMessage: action.errorMessage,
});

export const closeDuplicatedTransaction = (state) => ({
  ...state,
  duplicatedTransaction: false,
});

export const setFileUuid = (state, action) => ({
  ...state,
  fileUuid: action.fileUuid,
});

export const clearErrors = (state) => ({
  ...state,
  error: false,
  errorImportData: false,
  errorMessage: '',
});

export const clearState = (state) => ({
  ...state,
  ...INITIAL_STATE,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPLOAD_TRANSFER_FILE_REQUEST]: uploadTransferFileRequest,
  [Types.UPLOAD_TRANSFER_FILE_SUCCESS]: uploadTransferFileSuccess,
  [Types.UPLOAD_TRANSFER_FILE_FAILURE]: uploadTransferFileFailure,
  [Types.GET_IMPORT_PAYMENT_CONFIRMATION_REQUEST]: request,
  [Types.GET_IMPORT_PAYMENT_CONFIRMATION_SUCCESS]:
    getImportPaymentConfirmationSuccess,
  [Types.GET_IMPORT_PAYMENT_CONFIRMATION_FAILURE]: failure,
  [Types.SET_IMPORT_PAYMENT_CONFIRMATION_REQUEST]:
    setImportPaymentConfirmationRequest,
  [Types.SET_IMPORT_PAYMENT_CONFIRMATION_SUCCESS]:
    setImportPaymentConfirmationSuccess,
  [Types.SET_IMPORT_PAYMENT_CONFIRMATION_FAILURE]:
    setImportPaymentConfirmationFailure,
  [Types.UPDATE_IMPORT_PAYMENT_CONFIRMATION]: updateImportPaymentConfirmation,
  [Types.UPDATE_DATA_IMPORT_PAYMENT_CONFIRMATION]:
    updateDataImportPaymentConfirmation,
  [Types.CONFIRM_IMPORT_PAYMENT_REQUEST]: confirmImportPaymentRequest,
  [Types.CONFIRM_IMPORT_PAYMENT_SUCCESS]: confirmImportPaymentSuccess,
  [Types.CONFIRM_IMPORT_PAYMENT_FAILURE]: confirmImportPaymentFailure,
  [Types.CLOSE_DUPLICATED_TRANSACTION]: closeDuplicatedTransaction,
  [Types.CLEAR_IMPORT_PAYMENT_CONFIRMATION]: clearState,
  [Types.SET_FILE_UUID]: setFileUuid,
  [Types.CLEAR_ERRORS]: clearErrors,
});
