import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { railsContextSelector } from 'selectors/rails-context';
import Logo from 'components/Dashboard/shared/Logo';
import ClientBalance from 'components/Dashboard/Header/components/ClientBalance';
import { useMedia } from 'react-use';

import HeaderContext from '../context';
import ClientSelector from './ClientSelector';
import FaqMenu from './FaqMenu';
import SettingsMenu from './SettingsMenu';
import { Balance, Actions, WrapperSettingsMenu } from '../styles';

const NavContent = () => {
  const {
    showBalance,
    balance,
    externalWallet,
    optionsItemsGroup,
    currentStoreId,
    clientName,
    homeLink
  } = useContext(HeaderContext);
  const isMobile = useMedia('(max-width: 768px)');
  const { economicGroupView } = useSelector(railsContextSelector);
  const showBluIcon = !(economicGroupView && isMobile);

  return (
    <>
      {!isMobile && (
        <Balance>
          {showBalance && (
            <ClientBalance {...balance} externalWallet={externalWallet} />
          )}
        </Balance>
      )}
      <Actions>
        <FaqMenu />
        <WrapperSettingsMenu>
          <SettingsMenu setting={optionsItemsGroup} />
        </WrapperSettingsMenu>
      </Actions>
      <ClientSelector currentStoreId={currentStoreId} clientName={clientName} />

      {showBluIcon && <Logo link={homeLink} color="#0025E0" />}
    </>
  );
};

export default NavContent;
