import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  saveGroupNameRequest: ['groupId', 'groupName', 'currentClientUuid'],
  saveGroupNameSuccess: ['groupId', 'groupUuid', 'groupName'],
  saveGroupNameFailure: null,
  loadGroupConfigurationRequest: null,
  loadGroupConfigurationSuccess: ['groupId', 'groupUuid', 'groupName'],
  loadGroupConfigurationFailure: null,
  saveGroupUpdateName: null
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  groupName: '',
  groupId: '0',
  groupUuid: '',
  loadLoading: false,
  loading: false,
  error: false,
  nameUpdated: false
};

export const request = (state) => ({
  ...state,
  loading: true,
  nameUpdated: false
});

export const success = (state, action) => ({
  ...state,
  loading: false,
  groupId: action.groupId,
  groupUuid: action.groupUuid,
  groupName: action.groupName,
  nameUpdated: true
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true,
  nameUpdated: false
});

export const loadRequest = (state) => ({
  ...state,
  loadLoading: true
});

export const loadSuccess = (state, action) => ({
  ...state,
  loadLoading: false,
  groupId: action.groupId,
  groupUuid: action.groupUuid,
  groupName: action.groupName
});

export const loadFailure = (state) => ({
  ...state,
  loadLoading: false,
  error: true
});

export const saveGroupUpdateName = (state) => ({
  ...state,
  nameUpdated: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SAVE_GROUP_NAME_REQUEST]: request,
  [Types.SAVE_GROUP_NAME_SUCCESS]: success,
  [Types.SAVE_GROUP_NAME_FAILURE]: failure,
  [Types.LOAD_GROUP_CONFIGURATION_REQUEST]: loadRequest,
  [Types.LOAD_GROUP_CONFIGURATION_SUCCESS]: loadSuccess,
  [Types.LOAD_GROUP_CONFIGURATION_FAILURE]: loadFailure,
  [Types.SAVE_GROUP_UPDATE_NAME]: saveGroupUpdateName
});
