import React from 'react';
import PropTypes from 'prop-types';

import { Title, Body, Box, BoxIcon } from './DeviceCardLayout.styles';

const DeviceCardLayout = ({
  children,
  isSuccess,
  isLoading,
  isError,
  iconImg
}) => (
  <div>
    <Title>
      <span>Dispositivo</span>
    </Title>
    <Body>
      <Box
        isCentralized={isSuccess || isLoading || isError}
        isLoading={isLoading}
      >
        {!isLoading && (
          <BoxIcon>
            <img alt={iconImg.alt} src={iconImg.src} width={iconImg.width} />
          </BoxIcon>
        )}
        {children}
      </Box>
    </Body>
  </div>
);

DeviceCardLayout.defaultProps = {
  isSuccess: false,
  isLoading: false,
  isError: false,
  iconImg: null
};

DeviceCardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  iconImg: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.any.isRequired,
    width: PropTypes.string.isRequired
  })
};

export default DeviceCardLayout;
