import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectors } from 'store/ducks/paymentCollectionDetails';
import { normalizeValue } from 'normalizers';
import SummaryRefundValues from 'components/RefundPaymentCollection/HeaderSummary';

const Wrapper = styled.div`
  margin: 0 0 15px;
`;

const SummaryValues = ({ isCharger }) => {
  const {
    refunded,
    amountValue,
    actualNetValue,
    refundedValue,
    remainingRefundValue
  } = useSelector(selectors.getDetailsData);

  if (refunded) {
    return (
      <SummaryRefundValues
        isCharger={isCharger}
        refundedValue={refundedValue}
        remainingRefundValue={remainingRefundValue}
        admNetValue={actualNetValue}
      />
    );
  }

  const showNetValue = actualNetValue && amountValue !== actualNetValue;
  if (!showNetValue) return null;

  return (
    <Wrapper>
      <div className={`statement-detail__${isCharger ? 'inflow' : 'debit'}`}>
        Valor {isCharger ? 'recebido' : 'pago'}{' '}
        <span>{normalizeValue(actualNetValue)}</span>
      </div>
    </Wrapper>
  );
};

SummaryValues.defaultProps = {
  isCharger: false
};

SummaryValues.propTypes = {
  isCharger: PropTypes.bool
};

export default SummaryValues;
