import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export const clientDataByType = ({ clientUUID, onRequestClose }) => ({
  blu_light: {
    message: (
      <>
        Pronto, tudo certo! Agora é só ativar o{' '}
        <strong>Pagamento automático de boletos em atraso</strong> para garantir
        a máxima facilidade e economia na hora de pagar o seu fornecedor.
      </>
    ),
    buttonText: 'Ativar',
    buttonAction: () => {
      window.location.href = `/lojas/${clientUUID}/pagamento-a-fornecedor/boleto-mais-seguro/contratos`;
    },
  },
  blu_light_client_need_regularize_status: {
    message: (
      <>
        O CNPJ informado precisa ser regularizado junto à Receita Federal. Assim
        que a situação estiver regular, a ativação do Pagamento automático de
        boletos em atraso poderá ser finalizada.
      </>
    ),
    buttonText: 'Entendi',
    buttonAction: () => {
      onRequestClose('ContractSigning');
    },
  },
  blu_light_client_need_social_contract: {
    message: (
      <>
        Falta pouco! Libere as funções da conta, confirmando suas informações
        para ativar o Pagamento automático de boletos em atraso.
      </>
    ),
    buttonText: 'Liberar funções da conta',
    buttonAction: () => {
      window.location.href = `/lojas/${clientUUID}/completude-cadastral`;
    },
  },
  client_valid_allow_pos_solicitation: {
    message: (
      <>
        Você tem direito a uma maquininha Blu, sem pagar nada por isso. <br />
        Gostaria de solicitar a sua?
      </>
    ),
    buttonText: 'Solicitar maquininha',
    buttonAction: () => {
      window.location.href = `/lojas/${clientUUID}/solicitacao-de-pos/`;
    },
  },
  client_need_social_contract: {
    message: (
      <>
        Falta pouco! Confirme suas informações para ter acesso a todas as
        funções da sua conta.
      </>
    ),
    buttonText: 'Liberar funções da conta',
    buttonAction: () => {
      window.location.href = `/lojas/${clientUUID}/completude-cadastral`;
    },
  },
  client_need_regularize_status: {
    message: (
      <>
        Pronto! O ambiente da sua Conta Digital Blu está configurado. Mas o CNPJ
        informado precisa ser regularizado junto à Receita Federal. Não se
        preocupe, assim que a situação estiver regular, você terá acesso
        completo a sua Conta Digital Blu.
      </>
    ),
    buttonText: 'Entendi',
    buttonAction: () => {
      onRequestClose('ContractSigning');
    },
  },
  client_valid: {
    message: (
      <>
        Pronto, tudo certo! Agora você pode garantir a máxima facilidade e
        economia na hora de pagar o seu fornecedor.
      </>
    ),
    buttonText: 'Entendi',
    buttonAction: () => {
      onRequestClose('ContractSigning');
    },
  },
  client_under_analysis: {
    message: (
      <>
        Seu cadastro foi enviado para análise! Em breve você terá acesso sua
        conta digital Blu. Assim que o processo for finalizado você receberá uma
        notificação via e-mail e WhatsApp.
      </>
    ),
    buttonText: 'Entendi',
    buttonAction: () => {
      onRequestClose('ContractSigning');
    },
  },
});
