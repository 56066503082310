import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import store, { history } from 'store';
import { ConnectedRouter } from 'connected-react-router';

import ClicksignContractStepOne from './ClicksignContractStepOne';
import ClicksignContractStepTwo from './ClicksignContractStepTwo';

const ClicksignContract = ({
  title,
  signer,
  contractLoteKey,
  canceledContractPath
}) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path="/lojas/:uuid/apresentacao-contrato"
          component={(props) => (
            <ClicksignContractStepOne {...props.match.params} title={title} />
          )}
        />
        <Route
          exact
          path="/lojas/:uuid/apresentacao-contrato/assinatura"
          component={(props) => (
            <ClicksignContractStepTwo
              {...props.match.params}
              signer={signer}
              contractLoteKey={contractLoteKey}
              canceledContractPath={canceledContractPath}
            />
          )}
        />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

ClicksignContract.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({})
  }),
  title: PropTypes.string.isRequired,
  signer: PropTypes.object.isRequired,
  contractLoteKey: PropTypes.string.isRequired,
  canceledContractPath: PropTypes.string.isRequired
};

ClicksignContract.defaultProps = {
  match: {
    params: {}
  }
};

export default ClicksignContract;
