import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

export function createLeadData({ cnpj, email, distributor, utm }) {
  return axios({
    method: 'post',
    url: '/api/sign_up/leads',
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken()
    },
    data: {
      lead: {
        cnpj,
        email,
        distributor,
        utm_params: utm
      }
    }
  });
}

export function editLeadData({
  leadId,
  email,
  name,
  cpf,
  phone,
  contractBluAccepted
}) {
  return axios({
    method: 'patch',
    url: `/api/sign_up/leads/${leadId}`,
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken()
    },
    data: {
      lead: { email, name, cpf, phone, contract_checked: contractBluAccepted }
    }
  });
}

export function confirmToken({ leadId, token, authTokenType }) {
  return axios({
    method: 'post',
    url: `/api/sign_up/contact_validation/perform_${authTokenType}_validation`,
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken()
    },
    data: {
      lead_id: leadId,
      token
    }
  });
}

export function resendToken({ leadId, authTokenType }) {
  return axios({
    method: 'get',
    url: `/api/sign_up/contact_validation/send_${authTokenType}_token?lead_id=${leadId}`,
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken()
    }
  });
}

export function createUser({ leadId, passcode }) {
  return axios({
    method: 'post',
    url: '/api/sign_up/client_automatic_registrations',
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken()
    },
    data: {
      lead_id: leadId,
      password: passcode
    }
  });
}

// OLD API calls

export function signInToken({ token }) {
  return axios({
    method: 'post',
    url: `/users/sign_in?token=${token}`,
    headers: {
      Accept: 'application/json'
    }
  });
}

export function createUserPass(params) {
  return axios({
    method: 'put',
    url: '/users/password',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: params
  });
}

export function TokenResetRequest(clientId, token, params) {
  return axios({
    method: 'patch',
    url: `/lojas/${clientId}/autorizacoes/${token}/perform?provider=sms&type=public`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: params
  });
}

export function resendTokenResetRequest(clientId, token) {
  return axios({
    method: 'get',
    url: `/lojas/${clientId}/autorizacoes/${token}/resend_sms_token?type=public`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });
}
