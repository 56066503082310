import React from 'react';
import PropTypes from 'prop-types';

import { serverToLong } from './PeriodFilters/DateHelper';
import ClientTransaction from '../ClientTransaction';

const TransactionRecords = ({ records, releasedAt, group }) => (
  <>
    <h4 className="list__title">
      Quando aconteceu - {serverToLong(releasedAt, group)}
    </h4>
    {records.map((t) => (
      <ClientTransaction
        key={t.category}
        link="#"
        transaction={{ ...t, happenedAt: null }}
      />
    ))}
  </>
);

TransactionRecords.propTypes = {
  records: PropTypes.array.isRequired,
  releasedAt: PropTypes.string.isRequired,
  group: PropTypes.string
};

TransactionRecords.defaultProps = {
  group: 'day'
};

export default TransactionRecords;
