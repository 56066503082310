import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@useblu/ocean-react';
import { colorStatusNegativePure } from '@useblu/ocean-tokens/web/tokens';
import { Title, Description, Actions } from './styles';

const CancelTransactionModal = ({ isOpen, onClose, onCancel, loading }) => (
  <Modal
    isOpen={isOpen}
    ariaHideApp={false}
    onRequestClose={onClose}
    maxWidth="sm"
    blocked
  >
    <Title variant="heading2" color={colorStatusNegativePure}>
      Deseja cancelar a transferência?
    </Title>
    <Description variant="paragraph">
      Essa ação não pode ser desfeita.
    </Description>
    <Actions>
      <Button variant="secondary" onClick={onClose} disabled={loading}>
        Não
      </Button>
      <Button
        variant="primaryCritical"
        onClick={onCancel}
        loading={loading}
        disabled={loading}
      >
        Sim, cancelar
      </Button>
    </Actions>
  </Modal>
);

CancelTransactionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default memo(CancelTransactionModal);
