import React from 'react';
import PropTypes from 'prop-types';
import history from 'utils/browserHistory';

const setCookie = (uuid) => {
  return;
};

const ClicksignContractStepOne = ({ uuid, title }) => (
  <div className="clicksign-container is-padded">
    <div className="clicksign-title">
      <p>
        Você foi selecionado para fazer parte da <br />
        <big>PARCERIA</big>
      </p>
    </div>
    <div className="clicksign-body">
      <div className="clicksign-child part-one">
        <p>
          Use a <b>{title}</b> como
          <br />
          sempre fez para:
        </p>
        <p>
          ver vendas,
          <br />
          aceitar cobranças,
          <br />
          fazer pagamentos,
          <br />
          <b>nada muda</b>
        </p>
      </div>
      <div className="clicksign-child">
        <div
          className={title === 'BLU' ? 'img-blu-getnet' : 'img-pagnet-getnet'}
        ></div>
        <div className="part-two-content">
          {title === 'BLU' ? (
            <p>
              <b>
                Estamos reduzindo suas taxas de
                <br />
                debito, crédito e parcelado em 0,2%.
              </b>
            </p>
          ) : null}
          <p className="desc-one">
            <b>Basta aceitar para fazer parte dessa parceria.</b>
          </p>
          {title === 'BLU' ? (
            <p>
              Caso você tenha custos menores, nos envie o comprovante que
              igualamos as taxas, <b>seremos sua melhor taxa!</b>
            </p>
          ) : null}
        </div>
        <div className="button-desktop">
          <button
            className="button button-clicksign"
            onClick={() =>
              history.push(`/lojas/${uuid}/apresentacao-contrato/assinatura`)
            }
          >
            Clique aqui para continuar!
          </button>
        </div>
      </div>
      <div className="clicksign-child part-three">
        <p className="desc-one">
          A <b>única mudança</b> é que
          <br />
          você também poderá
          <br />
          fazer as vendas de
          <br />
          cartão na máquina
        </p>
        <div className="img-getnet"></div>
        <p className="desc-two">
          <b>que enviaremos.</b>
        </p>
        <p className="desc-three">Com acesso a</p>
        <p className="desc-four">
          <b>
            + Bandeiras
            <br />
          </b>
        </p>
        {title === 'BLU' ? (
          <p className="desc-four">
            <b>Melhores Taxas</b>
          </p>
        ) : null}
        <p className="desc-three">
          <b>Sem aluguel adicional</b>
        </p>
      </div>
      <div className="button-mobile">
        <button
          className="button button-clicksign"
          onClick={() => history.push('/apresentacao-contrato/assinatura')}
        >
          Clique aqui para continuar!
        </button>
      </div>
    </div>
  </div>
);

ClicksignContractStepOne.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ClicksignContractStepOne;
