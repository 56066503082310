import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { normalizeCurrency } from 'normalizers';

const RefundSimulationFutureOutflows = ({ refundOutflows }) => (
  <div className="refund-simulation-result-table__future-outflows">
    <div className="refund-simulation-result-table__subheader">
      PARCELA À RECEBER
    </div>
    {refundOutflows.map(({ id, releasedAt, netValue }) => (
      <div key={id} className="refund-simulation-result-table__row">
        <div className="refund-simulation-result-table__cell">
          Devol. Cobrança
        </div>
        <div className="refund-simulation-result-table__cell">
          {moment(releasedAt).format('DD/MM/YYYY')}
        </div>
        <div className="refund-simulation-result-table__cell refund-simulation-result-table__cell--money">
          -R$ {normalizeCurrency(netValue)}
        </div>
      </div>
    ))}
  </div>
);

RefundSimulationFutureOutflows.propTypes = {
  refundOutflows: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default RefundSimulationFutureOutflows;
