import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Card from 'components/Shared/Card';
import pendingIcon from 'images/icons/ic-envelope.svg';
import successIcon from 'images/icons/ic-sucesso.svg';
import PaymentPendingBodyRow from './PaymentPendingBodyRow';
import { PaymentPendingContainer, BannerContainer } from './styles';

const PaymentPendingContent = ({ uuid }) => {
  const {
    paymentConfirmation: {
      transactionsWithSameOwnership,
      transactionsWithoutSameOwnership,
    },
  } = useSelector((state) => state);

  const paymentConfirmationState = JSON.parse(
    localStorage.getItem('paymentConfirmation')
  );

  const goToHome = useCallback(() => {
    const { location } = window;
    localStorage.removeItem('paymentConfirmation');
    return location.assign(
      `${location.origin}/lojas/${uuid}/extrato/pendentes/tab-extrato`
    );
  }, [uuid]);

  const transactionsWithoutSameOwnershipAux =
    paymentConfirmationState?.transactionsWithoutSameOwnership
      ? paymentConfirmationState?.transactionsWithoutSameOwnership
      : transactionsWithoutSameOwnership;
  const transactionsWithSameOwnershipAux =
    paymentConfirmationState?.transactionsWithSameOwnership
      ? paymentConfirmationState?.transactionsWithSameOwnership
      : transactionsWithSameOwnership;

  const showDivider = () =>
    transactionsWithoutSameOwnershipAux.length > 1 &&
    transactionsWithSameOwnershipAux.length > 1;

  const titleTransactionsWithSameOwnership = () =>
    transactionsWithSameOwnershipAux.length > 1
      ? 'Transações confirmadas!'
      : 'Transação confirmada!';

  useEffect(() => {
    if (
      transactionsWithSameOwnershipAux.length === 0 &&
      transactionsWithoutSameOwnershipAux.length === 0
    ) {
      goToHome();
    }
  }, [transactionsWithSameOwnershipAux, transactionsWithoutSameOwnershipAux]);

  useEffect(
    () => () => {
      localStorage.removeItem('paymentConfirmation');
    },
    []
  );

  return (
    <PaymentPendingContainer>
      <BannerContainer>
        <Card className="payment-pending">
          <div className="payment-pending-header">
            <div className="payment-pending-header__logo">
              {transactionsWithoutSameOwnershipAux.length === 0 ? (
                <img
                  className="img-success"
                  src={successIcon}
                  alt="Transação confirmada!"
                />
              ) : (
                <img
                  className="img-pending"
                  src={pendingIcon}
                  alt="Quase lá! Aprove por e-mail e SMS"
                />
              )}
            </div>
            <div className="payment-pending-header__title">
              {transactionsWithoutSameOwnershipAux.length === 0 ? (
                titleTransactionsWithSameOwnership()
              ) : (
                <>
                  <span className="block-mobile">Quase lá!</span> Aprove por
                  e-mail e SMS.
                </>
              )}
            </div>
            <div className="payment-pending-header__description">
              {transactionsWithoutSameOwnershipAux.length === 0
                ? 'Transferências para a mesma titularidade são aprovadas automaticamente. Atenção: após as 16h00 a efetivação ocorrerá no próximo dia útil.'
                : 'Para finalizar, verifique o seu e-mail e complete a aprovação. Faça isso até 16h00 para concluir a operação no mesmo dia.'}
            </div>
          </div>
          <div className="payment-pending-body">
            <PaymentPendingBodyRow data={transactionsWithoutSameOwnershipAux} />
            {showDivider() && <div className="payment-pending-body__divider" />}
            <PaymentPendingBodyRow
              data={transactionsWithSameOwnershipAux}
              isSameOwnership
              showTip={!!transactionsWithoutSameOwnershipAux.length}
            />
          </div>
          <div className="payment-pending-footer">
            <button
              type="button"
              className="button button--rounder"
              onClick={goToHome}
            >
              Ver extrato
            </button>
          </div>
        </Card>
      </BannerContainer>
    </PaymentPendingContainer>
  );
};

PaymentPendingContent.propTypes = {
  uuid: PropTypes.string.isRequired,
};

const PaymentPending = ({ uuid }) => (
  // <DARFProvider>
  <PaymentPendingContent uuid={uuid} />
  // </DARFProvider>
);

PaymentPending.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default PaymentPending;
