import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import humps from 'lodash-humps';
import { normalizeCurrency } from 'normalizers';

import { Value, ValuePositive, Container } from './style';

export const CATEGORIES_FOR_OBSERVATION = [
  'Boleto',
  'Transferência bancária',
  'Transferência entre clientes'
];

const ClientTransaction = ({ link, transaction, economicGroupView }) => {
  const {
    nature,
    netValue,
    grossValue,
    happenedAt,
    payee,
    category,
    clientName,
    payerBranchName,
    providerDetails,
    observation,
    status
  } = humps(transaction);
  const isInflow = nature === 'inflow';
  const showObservation =
    observation && CATEGORIES_FOR_OBSERVATION.includes(category);

  return (
    <div
      className={`list__item account-statement__item account-statement__item-hover account-statement__item--${nature}`}
      data-status={status}
    >
      <Container className="new-account-statement__item-full-link" link={link}>
        <div className="new-account-statement__item-value">
          {isInflow ? (
            <ValuePositive>{`R$ ${normalizeCurrency(netValue)}`}</ValuePositive>
          ) : (
            <Value>{`-R$ ${normalizeCurrency(grossValue)}`}</Value>
          )}

          {happenedAt && (
            <span className="account-statement__item-net-value-children">
              {`às ${moment(happenedAt).format('HH[h]mm')}min`}
            </span>
          )}
        </div>

        <div className="new-account-statement__item-description">
          {payee && category === 'cancelamento de cashback' && (
            <h2 className="account-statement__item-title">
              <b>Ref.</b> Pagamento enviado para <b>{payee}</b>
            </h2>
          )}
          {payee && category !== 'cancelamento de cashback' && (
            <h2 className="account-statement__item-title">{payee}</h2>
          )}

          {category && (
            <h1 className="account-statement__item-transaction">{category}</h1>
          )}

          {economicGroupView && clientName && (
            <h3 className="account-statement__item-client">
              <i className="ic ic-store" />
              {clientName}
            </h3>
          )}

          {showObservation && (
            <div
              className="new-account-statement__item"
              data-testid="observation"
            >
              {observation}
            </div>
          )}

          {payerBranchName && (
            <h3 className="account-statement__item-client">
              <em className="ic ic-store" />
              {payerBranchName}
            </h3>
          )}
          {providerDetails && (
            <h3 className="account-statement__item-client">
              <em className="ic ic-store" />
              {providerDetails}
            </h3>
          )}
        </div>

        {link !== '#' && (
          <div className="new-account-statement__item-arrow">
            <em className="fa fa-chevron-right" />
          </div>
        )}
      </Container>
    </div>
  );
};

ClientTransaction.propTypes = {
  transaction: PropTypes.shape({
    nature: PropTypes.string,
    netValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    grossValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    happenedAt: PropTypes.string,
    payee: PropTypes.string,
    category: PropTypes.string,
    clientName: PropTypes.string,
    payerBranchName: PropTypes.string,
    providerDetails: PropTypes.string,
    observation: PropTypes.string
  }).isRequired,
  link: PropTypes.string,
  economicGroupView: PropTypes.bool
};

ClientTransaction.defaultProps = {
  link: '#',
  economicGroupView: false
};

export default ClientTransaction;
