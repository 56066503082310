import React from 'react';
import styled from 'styled-components';

import noDeviceImg from 'images/no-device.png';
import { BoxContent } from './DeviceCard.styles';
import DeviceCardLayout from './DeviceCardLayout';
import BluAppDownload from '../Shared/BluAppDownload/index.js';

const BoxContentEmpty = styled(BoxContent)`
  text-align: center;

  h1 {
    margin: 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 110%;
    color: #b6b9cc;
  }

  h2 {
    padding-top: 8px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #b6b9cc;
  }
`;

const DeviceCardEmpty = () => (
  <div>
    <DeviceCardLayout
      iconImg={{ alt: 'Sem Dispositivo', src: noDeviceImg, width: '91px' }}
    >
      <BoxContentEmpty>
        <h1>Nenhum dispositivo autorizado</h1>
        <h2>
          <strong>
            Acesse o app da Blu e configure o bToken no seu celular
          </strong>
        </h2>
      </BoxContentEmpty>
    </DeviceCardLayout>

    <BluAppDownload />
  </div>
);

export default DeviceCardEmpty;
