import _ from 'lodash';

export const clientSelector = (state) =>
  state.firstAccessShared.client && state.firstAccessShared.client.data;
export const userSelector = (state) => {
  const users =
    state.firstAccessShared.users && state.firstAccessShared.users.data;
  if (users) {
    return users.filter(
      (user) => user.id === _.toString(state.railsContext.currentUserId)
    )[0];
  }
  return {};
};
export const usersLoadingSelector = (state) =>
  state.firstAccessShared.users && state.firstAccessShared.users.loading;
export const statusStepsSelector = (state) =>
  (state.firstAccessShared.client &&
    state.firstAccessShared.client.data &&
    state.firstAccessShared.client.data.clientFormStepStatus) ||
  {};
export const qsaCpfSelector = (state) =>
  state.firstAccessQsaAccounts.qsa &&
  _.filter(state.firstAccessQsaAccounts.qsa, (partner) => {
    return partner.cpfCnpj.replace(/\D/g, '').length === 11;
  });
