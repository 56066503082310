import styled from 'styled-components';

import {
  colorInterfaceDarkDeep,
  colorInterfaceLightPure,
  borderRadiusSm,
  spacingInlineXxs
} from '@useblu/ocean-tokens/web/tokens';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`
};

export const SnackbarContainer = styled.div`
  visibility: hidden;
  min-width: 250px;
  background-color: ${colorInterfaceDarkDeep};
  color: ${colorInterfaceLightPure};
  text-align: left;
  border-radius: ${borderRadiusSm};
  padding: ${spacingInlineXxs};
  position: fixed;
  z-index: 1;
  right: 140px;
  bottom: 52px;
  display: flex;
  align-items: center;
  gap: 8px;

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
    z-index: 11;
  }

  &.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
  }

  @media ${devices.mobile} {
    left: 50%;
    right: 0;
    transform: translate(-50%, 0);
  }
`;

export const SnackAction = styled.button`
  margin-left: auto;
  background: none;
  font-weight: bold;
`;
