import styled from 'styled-components';

import {
  fontFamilyHighlight,
  colorInterfaceLightUp,
  colorInterfaceDarkDown,
  colorBrandPrimaryPure,
  fontSizeXxxs,
  fontSizeXs,
  fontSizeLg
} from '@useblu/ocean-tokens/web/tokens';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colorInterfaceLightUp};
  padding: 32px 0;
`;

export const Slogan = styled.p`
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeXs};
  font-weight: 500;
  color: ${colorInterfaceDarkDown};

  @media screen and (min-width: 769px) {
    font-size: ${fontSizeLg};
  }
`;

export const Highlight = styled.span`
  font-weight: 900;
  color: ${colorBrandPrimaryPure};
`;

export const Img = styled.img`
  width: 100px;
  @media screen and (min-width: 769px) {
    width: 200px;
  }
`;

export const Text = styled.p`
  font-size: ${fontSizeXxxs};
  color: ${colorInterfaceDarkDown};
`;

export const LogoBlu = styled.img`
  margin: 48px 0 10px;
`;
