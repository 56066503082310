import styled from 'styled-components';
import { Button as ButtonClick, Typography } from '@useblu/ocean-react';
import {
  borderRadiusLg,
  colorComplementaryDeep,
  colorInterfaceDarkDeep,
  colorInterfaceLightPure,
  fontSizeSm,
  fontSizeXs,
  shadowLevel3,
  spacingInlineXxs,
  spacingInsetLg,
  spacingStackLg,
  spacingStackMd
} from '@useblu/ocean-tokens/web/tokens';

const mediaScreen = {
  xs: `@media (max-width: 480px)`,
  sm: `@media (min-width: 600px)`,
  md: `@media (min-width: 992px)`,
  lg: `@media (min-width: 993px)`
};

export const Wrapper = styled.div`
  background: ${colorInterfaceLightPure};
  border-radius: ${borderRadiusLg};
  padding: ${spacingStackMd} ${spacingStackLg};
  box-shadow: ${shadowLevel3};
  border: 1px solid #d8dae8;
  margin-top: ${spacingStackLg};

  ${mediaScreen.md} {
    min-height: 345px;
    margin-top: -18rem;
  }
`;

export const Title = styled(Typography).attrs(() => ({
  variant: 'heading3'
}))`
  &&& {
    color: ${colorInterfaceDarkDeep};
    font-size: ${fontSizeSm};
  }
`;

export const SubTitle = styled(Typography).attrs(() => ({
  variant: 'heading3'
}))`
  &&& {
    color: ${colorComplementaryDeep};
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-size: ${fontSizeXs};
    margin: ${spacingInsetLg} 0;
    letter-spacing: 0.24em;

    svg {
      margin-right: ${spacingInlineXxs};
      ${(props) => props.inverse && 'transform: rotate(-90deg);'}
    }
  }
`;

export const Button = styled(ButtonClick)`
  width: 100%;
  margin-top: ${spacingInsetLg};
`;
