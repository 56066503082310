import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { normalizeValue } from 'normalizers';
import { railsContextSelector } from 'selectors/rails-context';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import PaymentBilletInstallments from 'components/PaymentCollectionDetails/containers/PaymentBilletInstallments';
import InstallmentsContainer from 'components/PaymentCollectionDetails/containers/InstallmentsContainer';
import AdvancementDetailsInflow from 'components/Shared/AdvancementDetailsInflow';
import PaymentCollectionInstallments from 'components/PaymentCollectionDetails/containers/PaymentCollectionInstallments';
import NonOptimizationInstallments from 'components/PaymentCollectionDetails/containers/NonOptimizationInstallments';

const Installments = () => {
  const { clientId } = useSelector(railsContextSelector);
  const { kind, optimization, installmentsOfOptimization, transactions } =
    useSelector(selectors.getDetailsData);

  if (
    kind.name === 'payment_collection_blu_billet' ||
    kind.name === 'payment_collection_bank_billet'
  )
    return <PaymentBilletInstallments />;

  if (!isEmpty(transactions)) {
    return (
      <InstallmentsContainer>
        {transactions.map((transaction, i) => (
          <AdvancementDetailsInflow
            key={transaction.id}
            netValue={normalizeValue(transaction.netValue)}
            installment={i + 1}
            antecipation={transaction.antecipation}
            originalReleasedAt={
              transaction.originalReleasedAt || transaction.releasedAt
            }
            releasedAt={transaction.releasedAt}
            advancedValue={transaction.netValue.toString()}
            advancedDays={transaction.advancedDays}
            advancesRateValue={transaction.advancesRateValue.toString()}
            url={
              transaction.advancerUuid &&
              `/lojas/${clientId}/transacoes/${transaction.advancerUuid}`
            }
            paymentCollection={transaction.hasPaymentCollection}
            optimization={transaction.optimization}
            list
            transactionId={transaction.id}
          />
        ))}
      </InstallmentsContainer>
    );
  }

  if (optimization) {
    if (installmentsOfOptimization) {
      return (
        <PaymentCollectionInstallments
          installments={installmentsOfOptimization}
        />
      );
    }

    return (
      <InstallmentsContainer>
        <small className="statement-detail__hint list__item-blank">
          Recebimento das transações como elas estiverem
        </small>
      </InstallmentsContainer>
    );
  }

  return <NonOptimizationInstallments />;
};

export default Installments;
