import React from 'react';
import PropTypes from 'prop-types';
import PageView from 'components/AccountStatement/components/PageView';
import { useMedia } from 'react-use';
import moment from 'moment';
import {
  Button,
  Typography,
  TransactionListItem,
  List,
  TopBar,
  SubHeader
} from '@useblu/ocean-react';
import { isEmpty } from 'lodash';
import { formatDate } from 'normalizers';
import { formatPrice } from 'components/AccountStatement/helpers/currency';
import { PixOutline, ArrowLeftOutline } from '@useblu/ocean-icons-react';
import * as S from 'components/AccountStatement/screens/Pix/styles';
import { colorInterfaceDarkUp } from '@useblu/ocean-tokens/web/tokens';
import '../breadcrumb.css';

const Details = ({
  title,
  value,
  date,
  clientUUID,
  refundData,
  proofPath,
  ctStatus,
  ctParentUuid,
  paymentInboundData
}) => {
  const isMobile = useMedia('(max-width: 768px)');

  const statusList = {
    pending: 'Processando',
    confirmed: 'Confirmado',
    canceled: 'Cancelado'
  };

  const colorList = {
    pending: 'default',
    confirmed: 'positive',
    canceled: 'negative'
  };

  const piAttrs = paymentInboundData?.attributes;

  return (
    <S.Container>
      <PageView
        title={title}
        backLink="/"
        header={
          <>
            <S.Bar>
              <TopBar
                id="TopBar-playground"
                variants="default"
                leftIcon={isMobile ? <ArrowLeftOutline /> : null}
                title="Extrato"
              />
            </S.Bar>
            <Typography variant="heading3">{title}</Typography>
          </>
        }
      >
        <S.WrapperResumeValues>
          <div className="payment-data">
            <S.ResumeItem className="value">
              <span>Valor</span>
              <p>{formatPrice(value)}</p>
            </S.ResumeItem>
            <S.ResumeItem>
              <span>Data do reembolso</span>
              <p>{formatDate(date)}</p>
            </S.ResumeItem>
            {!isEmpty(refundData.reason) && (
              <S.ResumeItem>
                <span>Motivo do reembolso</span>
                <p>{refundData.reason}</p>
              </S.ResumeItem>
            )}
            <S.StatusTag type={colorList[ctStatus]}>
              {statusList[ctStatus]}
            </S.StatusTag>
          </div>
          <div className="date-information">
            Operação realizada em {moment(date).format('LLL')}
          </div>
        </S.WrapperResumeValues>
        <S.WrapperRefunds>
          <List>
            <SubHeader>Transação de origem</SubHeader>
            <TransactionListItem
              icon={<PixOutline style={{ color: colorInterfaceDarkUp }} />}
              level2={piAttrs?.debit_party?.name}
              onClick={() => {
                window.location.href = `/lojas/${clientUUID}/transacoes/${ctParentUuid}`;
              }}
              value={piAttrs?.value && formatPrice(piAttrs?.value)}
              positive
            >
              Pix Recebido
            </TransactionListItem>
          </List>
        </S.WrapperRefunds>
        <S.WrapperButtons>
          <Button
            size="md"
            variant="primary"
            disabled={isEmpty(refundData)}
            onClick={() => window.open(proofPath, '_blank')}
          >
            Baixar comprovante
          </Button>
        </S.WrapperButtons>
      </PageView>
    </S.Container>
  );
};

Details.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  clientUUID: PropTypes.string.isRequired,
  refundData: PropTypes.object,
  proofPath: PropTypes.string,
  ctStatus: PropTypes.string.isRequired,
  ctParentUuid: PropTypes.string.isRequired,
  paymentInboundData: PropTypes.object
};

Details.defaultProps = {
  refundData: {},
  debitParty: {},
  proofPath: '',
  paymentInboundData: {}
};

export default Details;
