import styled from 'styled-components';

export const ShortMessage = styled.p`
  width: 100%;
  margin: 0px;
  margin-right: 18px;
  color: #343e4f;
  font-size: 12px;
  line-height: 16px;
`;

export const ActionButton = styled.button`
  background: #0033f5;
  border-radius: 80px;
  padding: 11px 16px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  margin-right: 8px;
  flex-shrink: 0;
`;
