/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as yup from 'yup';
import { Formik, ErrorMessage } from 'formik';

import { Input, Button, Alert } from '@useblu/ocean-react';

import * as S from './styles';
import { AlertWrapper } from '../../devise.styles';

import { editLeadData, resendToken } from '../../services/signUp';

export const ResetEmailModal = ({ toggleModal, setChangedEmail }) => {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState({
    active: false,
    message: ''
  });

  const sessionData = JSON.parse(
    sessionStorage.getItem('auto-signup-anonymus')
  );

  const formikProps = {
    initialValues: {
      email: ''
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .transform((value) => value.trim())
        .email('Adicione um e-mail válido.')
        .required('Este campo é obrigatório.')
    }),
    onSubmit: async (values) => {
      setLoading(true);

      try {
        await editLeadData({
          leadId: sessionData?.leadId,
          email: values?.email
        });

        resendToken({
          leadId: sessionData?.leadId,
          authTokenType: 'email'
        })
          .then(() => {
            setLoading(false);
            toggleModal(false);

            setChangedEmail(values?.email);

            sessionStorage.setItem(
              'auto-signup-anonymus',
              JSON.stringify({
                ...sessionData,
                email: values?.email
              })
            );
          })
          .catch((error) => {
            setLoading(false);

            setHasError({
              active: true,
              message: error?.response?.data?.message || error.message
            });
          });
      } catch (error) {
        setLoading(false);

        setHasError({
          active: true,
          message: error?.response?.data?.message || error.message
        });
      }
    }
  };

  return (
    <S.Container>
      <S.Title>Informe seu novo e-mail</S.Title>

      <S.Subtitle>
        Enviaremos um código de segurança para confirmar-lo.
      </S.Subtitle>

      {hasError.active && (
        <AlertWrapper>
          <Alert type="warning">{hasError.message}</Alert>
        </AlertWrapper>
      )}

      <Formik validateOnBlur={false} {...formikProps}>
        {({ errors, touched, values, handleSubmit, handleChange }) => (
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <S.WrapInput>
              <Input
                id="email"
                type="email"
                value={values.email}
                position="left"
                label="Digite seu novo e-mail"
                placeholder="exemplo@email.com.br"
                className={errors.email && touched.email ? 'error' : ''}
                onChange={(e) => {
                  handleChange(e);
                }}
              />
              <ErrorMessage
                name="email"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </S.WrapInput>

            <S.ButtonWrapper>
              <Button variant="secondary" size="md" onClick={toggleModal}>
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="md"
                loading={loading}
              >
                Alterar
              </Button>
            </S.ButtonWrapper>
          </form>
        )}
      </Formik>
    </S.Container>
  );
};

ResetEmailModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  setChangedEmail: PropTypes.func.isRequired
};
