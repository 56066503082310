import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ClassNames from 'classnames';

import { useAsyncFn, useInterval, useMedia } from 'react-use';
import { Formik, ErrorMessage } from 'formik';
import Loading from 'react-loading';
import { Alert } from '@useblu/ocean-react';

import Snackbar, { useSnackbar } from 'components/Shared/Snackbar';
import { TokenResetRequest, resendTokenResetRequest } from '../services/signUp';

import {
  Title,
  Subtitle,
  ConfirmTokenBtn,
  WrapBtns,
  CustomForm,
  AlertWrapper,
  Danger,
  CenterButtons,
  CenteredCustomTokenInput
} from '../devise.styles';

function ConfirmTokenSms({ userData, next, isNewUser }) {
  const isMobile = useMedia('(max-width: 768px)');
  const {
    isActive: snackIsActive,
    message: snackMessage,
    type: snackType,
    openSnackBar
  } = useSnackbar();

  const [disabledButton, setDisabledButton] = React.useState(60);
  const [timeBlock, setTimeBlock] = useState(false);
  const [networkError, setNetworkError] = useState({
    error: false,
    message: ''
  });
  const [isTokenError, setError] = useState(false);
  const [isTokenExpired, setErrorExpired] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [confirmTokenState, confirmTokenRequest] =
    useAsyncFn(TokenResetRequest);
  const [resendTokenState, resendTokenRequest] = useAsyncFn(
    resendTokenResetRequest
  );

  const { phone, client_id: clientId, token } = userData;

  let isNetworkError = false;

  const formik = {
    initialValues: {
      first: '',
      second: '',
      third: '',
      fourth: ''
    },
    onSubmit: (values) => {
      if (!confirmTokenState.loading) {
        if (isTokenError) setError(false);
        setLoading(true);
        confirmTokenRequest(clientId, token, {
          user: {
            password: Object.values(values).join('')
          }
        });
      }
    }
  };

  useEffect(() => {
    if (confirmTokenState?.value?.status === 200) {
      setError(false);

      next(confirmTokenState?.value?.data?.token);
    }

    if (confirmTokenState?.error) {
      setLoading(false);

      if (!confirmTokenState?.error.response) {
        setNetworkError({
          error: true,
          message: confirmTokenState?.error?.message
        });
      } else {
        setError(true);
      }
    }
  }, [confirmTokenState, next, setError]);

  useEffect(() => {
    if (resendTokenState?.value?.status === 200) {
      openSnackBar('Código reenviado com sucesso.', 'success');
    }

    if (resendTokenState?.error) {
      if (!resendTokenState?.error.response) {
        setNetworkError({
          error: true,
          message: resendTokenState?.error?.message
        });
      } else {
        setErrorExpired(true);
      }
    }
  }, [resendTokenState]);

  useInterval(
    () => {
      if (disabledButton !== 0) setDisabledButton(disabledButton - 1);
    },
    disabledButton ? 1000 : null
  );

  useInterval(
    () => {
      if (isTokenError) setError(false);
      if (isTokenExpired) setErrorExpired(false);
    },
    isTokenError || isTokenExpired ? 180000 : null
  );

  useInterval(
    () => {
      if (timeBlock) setTimeBlock(false);
    },
    timeBlock ? 3000 : null
  );

  isNetworkError = networkError.error && !isTokenError && !isTokenExpired;

  return (
    <>
      <Title>Para finalizar, informe seu código de segurança</Title>

      <Subtitle>
        Enviamos um número de 4 dígitos para:{' '}
        <strong style={{ display: isMobile && 'block' }}>{phone}</strong>
      </Subtitle>

      {isNetworkError && (
        <AlertWrapper>
          <Alert type="error">{networkError?.message}</Alert>
        </AlertWrapper>
      )}

      {isTokenError && (
        <AlertWrapper>
          <Alert type="error">Token inválido. Tente novamente.</Alert>
        </AlertWrapper>
      )}

      {isTokenExpired && (
        <AlertWrapper>
          <Alert type="error">Token expirado.</Alert>
        </AlertWrapper>
      )}

      <Snackbar
        isActive={snackIsActive}
        message={snackMessage}
        type={snackType}
      />

      <span
        style={{
          marginBottom: '8px'
        }}
      >
        Digite o código para continuar
      </span>

      <Formik
        initialValues={formik.initialValues}
        onSubmit={formik.onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validate={(values) => {
          const errors = {};
          const joinToken = Object.values(values).join('');

          if (joinToken.length <= 3) {
            errors.first = 'Campo obrigatório';
          }

          if (joinToken.length >= 4 && !/^[a-z0-9]+$/i.test(joinToken)) {
            errors.first = 'Apenas Letras e Números';
          }

          return errors;
        }}
      >
        {({
          errors,
          values,
          touched,
          handleSubmit,
          setFieldValue,
          setErrors
        }) => {
          const isError =
            (errors.first && touched.first) ||
            (errors.second && touched.second) ||
            (errors.third && touched.third) ||
            (errors.fourth && touched.fourth);

          const hasEmptyValuesOrLessThanFourDigits =
            Object.values(values).some((val) => val === '') ||
            Object.values(values).length < 4;

          return (
            <CustomForm onSubmit={handleSubmit} autoComplete="off">
              <div className="token">
                <CenteredCustomTokenInput
                  autoFocus
                  className={isError ? 'error' : ''}
                  digitsQuantity={4}
                  onChangeToken={(tokenValues) => {
                    const userToken = tokenValues.split('');
                    setFieldValue('first', userToken[0]);
                    setFieldValue('second', userToken[1]);
                    setFieldValue('third', userToken[2]);
                    setFieldValue('fourth', userToken[3]);
                    setErrors({});
                  }}
                />
              </div>

              <Danger>
                <ErrorMessage
                  name="first"
                  render={(msg) => <span className="error-msg">{msg}</span>}
                />
              </Danger>

              <CenterButtons>
                <WrapBtns
                  className="without-margin"
                  style={{ marginTop: '24px', width: '100%' }}
                >
                  <ConfirmTokenBtn
                    className={isNewUser && 'verificar_autocadastro_token'}
                    type="submit"
                    tabIndex={-1}
                    disabled={hasEmptyValuesOrLessThanFourDigits}
                  >
                    {isLoading ? (
                      <Loading
                        type="spinningBubbles"
                        color="#e1e5ed"
                        delay={0}
                        height={35}
                        width={35}
                      />
                    ) : (
                      'Verificar código'
                    )}
                  </ConfirmTokenBtn>
                </WrapBtns>

                {disabledButton ? (
                  <ConfirmTokenBtn className="send-new-token" disabled>
                    Reenviar em{' '}
                    {new Date(disabledButton * 1000)
                      .toISOString()
                      .substr(14, 5)}
                  </ConfirmTokenBtn>
                ) : (
                  <ConfirmTokenBtn
                    className={ClassNames('resend-token', {
                      ac_etapa_6_confirmacaodotoken_botaoreenviarcodigo:
                        isNewUser
                    })}
                    tabIndex={0}
                    component="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setDisabledButton(60);
                      if (!timeBlock) {
                        setError(false);
                        resendTokenRequest(clientId, token);
                        setTimeBlock(true);
                      }
                    }}
                  >
                    Reenviar SMS
                  </ConfirmTokenBtn>
                )}
              </CenterButtons>
            </CustomForm>
          );
        }}
      </Formik>
    </>
  );
}

ConfirmTokenSms.propTypes = {
  userData: PropTypes.object.isRequired,
  next: PropTypes.func.isRequired,
  isNewUser: PropTypes.bool.isRequired
};

export default ConfirmTokenSms;
