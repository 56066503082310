import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@useblu/ocean-react';
import NotificationSVG from 'components/TransactionAuthorizationConfirmation/images/ilustra-liveness.svg';
import * as S from './BTokenModalContent.styles';

const BTokenNotification = ({ setModalStep }) => {
  return (
    <S.NotificationModalContainer>
      <S.NotificationImage>
        <img src={NotificationSVG} alt="Notificação" />
      </S.NotificationImage>
      <S.NotificationContent>
        <Typography variant="heading3">
          Toque na notificação para autorizar
        </Typography>
        <Typography variant="description">
          A notificação foi enviada para o celular com bToken ativo. Revise as
          informações antes de autorizar
        </Typography>
      </S.NotificationContent>
      <S.NotificationActions>
        <Typography variant="caption">
          Não recebeu a notificação?{' '}
          <S.LinkButton type="button" onClick={() => setModalStep(1)}>
            Tentar de outro jeito
          </S.LinkButton>
        </Typography>
      </S.NotificationActions>
    </S.NotificationModalContainer>
  );
};

BTokenNotification.propTypes = {
  setModalStep: PropTypes.func.isRequired,
};

export default BTokenNotification;
