import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { normalizeValue } from 'normalizers';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import ReadOnlyField from '../components/ReadOnlyField';

const PartialPaymentInfo = ({ isCharger }) => {
  const { originalValue, valueOfPartialPayment } = useSelector(
    selectors.getDetailsData
  );

  return (
    <>
      <ReadOnlyField
        label="Valor original"
        value={<strong>{normalizeValue(originalValue)}</strong>}
      />
      <ReadOnlyField
        label={`Valor total ${isCharger ? 'recebido' : 'pago'} até o momento`}
        value={normalizeValue(valueOfPartialPayment)}
      />
    </>
  );
};

PartialPaymentInfo.defaultProps = {
  isCharger: false
};

PartialPaymentInfo.propTypes = {
  isCharger: PropTypes.bool
};

export default PartialPaymentInfo;
