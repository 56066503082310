import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */
const { Types, Creators } = createActions({
  validateBarCodeRequest: [
    'barcode',
    'clientUuid',
    'date',
    'initialFetch',
    'bankType',
  ],
  validateBarCodeSuccess: ['data', 'barcode', 'initialFetch'],
  validateBarCodeFailure: ['message'],
  validateTransactionRequest: ['params', 'clientUuid'],
  validateTransactionSuccess: ['billetProcessed', 'dataBillet'],
  validateTransactionFailure: null,
  confirmBilletRequest: ['params', 'clientUuid', 'newModeling'],
  confirmBilletSuccess: ['billetProcessedSuccess'],
  confirmBilletFailure: ['errorMessage'],
  setSimilarTransactions: ['similarTransactionsObject'],
  setInitialFetch: ['initialFetch'],
  unsetSimilarTransactions: null,
  clearStateBilletCip: null,
});

export { Types };
export default Creators;

/* Initial State */
const INITIAL_STATE = {
  error: false,
  loading: false,
  dataCip: {},
  dataBillet: null,
  billetUuid: null,
  initialFetch: false,
  billetBarcode: null,
  billetProcessed: null,
  similarTransactions: false,
  similarTransactionsObject: null,
  barcodeMessage: '',
};

export const setSimilarTransactions = (state, action) => ({
  ...state,
  similarTransactions: true,
  similarTransactionsObject: action.similarTransactionsObject,
});

export const unsetSimilarTransactions = (state) => ({
  ...state,
  similarTransactions: false,
  similarTransactionsObject: null,
});

export const validateBarcodeRequest = (state) => ({
  ...state,
  loading: true,
});

export const validateBarcodeSuccess = (state, action) => ({
  ...state,
  dataCip: action.data,
  loading: false,
  billetBarcode: action.barcode,
  initialFetch: action.initialFetch,
});

export const validateBarcodeFailure = (state, action) => ({
  ...state,
  loading: false,
  error: true,
  barcodeMessage: action.message,
});

export const validateTransactionRequest = (state) => ({
  ...state,
  loading: true,
});

export const validateTransactionSuccess = (state, action) => ({
  ...state,
  loading: false,
  billetUuid: action.dataBillet.uuid,
  dataBillet: action.dataBillet,
  billetProcessed: action.billetProcessed,
});

export const validateTransactionFailure = (state) => ({
  ...state,
  loading: false,
  error: true,
});

export const clearStateBilletCip = (state) => ({
  ...state,
  ...INITIAL_STATE,
});

export const confirmBilletRequest = (state) => ({
  ...state,
  loading: true,
});

export const setInitialFetch = (state, action) => ({
  ...state,
  initialFetch: action.initialFetch,
});

export const confirmBilletSuccess = (state, action) => ({
  ...state,
  loading: false,
  billetProcessedSuccess: action.billetProcessedSuccess,
});

export const confirmBilletFailure = (state, action) => ({
  ...state,
  loading: false,
  error: true,
  errorMessage: action.errorMessage,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SIMILAR_TRANSACTIONS]: setSimilarTransactions,
  [Types.UNSET_SIMILAR_TRANSACTIONS]: unsetSimilarTransactions,
  [Types.VALIDATE_BAR_CODE_REQUEST]: validateBarcodeRequest,
  [Types.VALIDATE_BAR_CODE_SUCCESS]: validateBarcodeSuccess,
  [Types.VALIDATE_BAR_CODE_FAILURE]: validateBarcodeFailure,
  [Types.VALIDATE_TRANSACTION_REQUEST]: validateTransactionRequest,
  [Types.VALIDATE_TRANSACTION_SUCCESS]: validateTransactionSuccess,
  [Types.VALIDATE_TRANSACTION_FAILURE]: validateTransactionFailure,
  [Types.CLEAR_STATE_BILLET_CIP]: clearStateBilletCip,
  [Types.CONFIRM_BILLET_REQUEST]: confirmBilletRequest,
  [Types.CONFIRM_BILLET_SUCCESS]: confirmBilletSuccess,
  [Types.CONFIRM_BILLET_FAILURE]: confirmBilletFailure,
  [Types.SET_INITIAL_FETCH]: setInitialFetch,
});
