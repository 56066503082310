import React, { useEffect } from 'react';

import { useInterval } from 'react-use';
import { Alert, Button } from '@useblu/ocean-react';
import { ShieldCheckOutline } from '@useblu/ocean-icons-react';

import Snackbar, { useSnackbar } from 'components/Shared/Snackbar';
import Loading from 'react-loading';
import { useAuthenticationContext } from '../context/authentication.context';

import TokenInput from './TokenInput';

import {
  Title,
  Subtitle,
  BtnSubmit,
  TokenForm,
  Resend,
  TokenButtonGroup,
  TokenContainer
} from '../devise.styles';

const TwoFactorAuthentication = () => {
  const {
    token,
    setToken,
    error,
    loadingTokenValidation,
    signInData,
    twoFactorAuthentication,
    resendToken,
    isOpenSnackBar,
    disabledButton,
    setDisabledButton
  } = useAuthenticationContext();
  const {
    isActive: snackIsActive,
    message: snackMessage,
    type: snackType,
    openSnackBar
  } = useSnackbar();

  useInterval(
    () => {
      if (disabledButton > 0) setDisabledButton(disabledButton - 1);
    },
    disabledButton ? 1000 : null
  );

  useEffect(() => {
    if (isOpenSnackBar) {
      openSnackBar('Código reenviado com sucesso.', 'success');
    }
  }, [isOpenSnackBar]);

  return (
    <>
      <Title>Informe o seu código de acesso para continuar</Title>
      <Subtitle>
        Você recebeu um código via SMS no número
        <br />
        <strong>{signInData.phone}</strong>
      </Subtitle>

      <TokenContainer>
        <Alert icon={<ShieldCheckOutline size={24} stroke="#5872f5" />}>
          Esse código de acesso garante que nenhuma pessoa desautorizada ou mal
          intencionada consiga acessar sua conta.
        </Alert>

        {error && (
          <Alert type="error" style={{ width: '100%' }}>
            {error}
          </Alert>
        )}

        <TokenForm>
          <div>Digite o código para continuar</div>
          <TokenInput
            digitsQuantity={4}
            autoFocus
            onChangeToken={(value) => setToken(value)}
            error={Boolean(error)}
          />

          <TokenButtonGroup>
            <BtnSubmit
              className="login-btn"
              type="submit"
              id="user_submit"
              tabIndex={-1}
              disabled={token.length < 4}
              onClick={twoFactorAuthentication}
            >
              {loadingTokenValidation ? (
                <Loading
                  type="spinningBubbles"
                  color="#e1e5ed"
                  delay={0}
                  height={35}
                  width={35}
                />
              ) : (
                '   Verificar Código'
              )}
            </BtnSubmit>
            <Resend>
              <Button
                variant="text"
                type="button"
                disabled={disabledButton > 0}
                onClick={resendToken}
                data-testid="resendTokenButton"
              >
                {disabledButton
                  ? `Reenviar em ${new Date(disabledButton * 1000)
                      .toISOString()
                      .substr(14, 5)}`
                  : 'Reenviar Token'}
              </Button>
            </Resend>
          </TokenButtonGroup>
        </TokenForm>

        <Snackbar
          isActive={snackIsActive}
          message={snackMessage}
          type={snackType}
        />
      </TokenContainer>
    </>
  );
};

export default TwoFactorAuthentication;
