import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, Form, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Loading from 'react-loading';

import { normalizeCurrency, currencyToNumber } from 'normalizers';
import RenderField from 'components/Form/RenderField';
import { refundPaymentSimulation } from 'api/payment-collection';

const RefundSimulationStep = ({
  clientUuid,
  uuid,
  maxValueToRefund,
  minValueToRefund,
  setActiveStep,
  change,
  handleSubmit,
  submitting,
  invalid
}) => {
  const [isLoading, setLoading] = useState(false);

  const submitForm = ({ refundValue }) => {
    setLoading(true);
    const numRefundValue = parseFloat(currencyToNumber(refundValue));

    return refundPaymentSimulation(clientUuid, uuid, numRefundValue)
      .then(({ data: { data, errors } }) => {
        if (errors) {
          return Promise.reject(errors);
        }

        setActiveStep({
          index: 1,
          data: {
            refundValue: numRefundValue,
            ...data.paymentCollectionRefundSimulation
          }
        });
      })
      .catch((errors) => {
        setLoading(false);

        let errorMessage = 'ocorreu algum erro ao realizar a simulação';
        if (errors && errors.length > 0) {
          errorMessage = errors[0].message.split(': ').pop();
        }

        throw new SubmissionError({
          refundValue: errorMessage
        });
      });
  };

  return (
    <Form onSubmit={handleSubmit(submitForm)}>
      <div className="flex-container">
        <div className="refund-payment-collection__value-limits">
          <span>
            Valor disponível para devolução:
            <span className="refund-payment-collection__value-limits--money">
              R$ {maxValueToRefund}
            </span>
          </span>
          <span>
            Valor mínimo para devolução:
            <span className="refund-payment-collection__value-limits--money">
              R$ {minValueToRefund}
            </span>
          </span>
        </div>

        <div className="refund-payment-collection__refund-value">
          <Field
            name="refundValue"
            type="text"
            component={RenderField}
            mask="currency"
            extraClass="form-controls--currency"
            prefix="R$"
            format={normalizeCurrency}
            normalize={normalizeCurrency}
          >
            <button
              type="button"
              className="button button--secondary button--medium"
              onClick={() => change('refundValue', maxValueToRefund)}
            >
              <small>usar valor</small>Total
            </button>
          </Field>
        </div>

        <button
          type="submit"
          className="button button--primary"
          disabled={submitting || invalid || isLoading}
        >
          {isLoading ? (
            <div className="refund-payment-collection__simulation--loading">
              <Loading
                type="spinningBubbles"
                color="#343e4f"
                delay={0}
                width={17}
                height={17}
              />
              <span>AGUARDE, ESTAMOS PROCESSANDO</span>
            </div>
          ) : (
            'Simular devolução'
          )}
        </button>
      </div>
    </Form>
  );
};

RefundSimulationStep.propTypes = {
  clientUuid: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  maxValueToRefund: PropTypes.string.isRequired,
  minValueToRefund: PropTypes.string.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool
};

RefundSimulationStep.defaultProps = {
  submitting: false,
  invalid: false
};

const mapStateToProps = (_, { maxValueToRefund, minValueToRefund }) => ({
  initialValues: { maxValueToRefund, minValueToRefund }
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: 'RefundPaymentCollectionForm',
    touchOnChange: true,
    validate: ({ refundValue, maxValueToRefund, minValueToRefund }) => {
      const errors = {};

      if (!refundValue) {
        errors.refundValue = 'valor não pode ficar em branco';
      } else {
        const numRefundValue = parseFloat(currencyToNumber(refundValue));
        const numMaxValueToRefund = parseFloat(
          currencyToNumber(maxValueToRefund)
        );
        const numMinValueToRefund = parseFloat(
          currencyToNumber(minValueToRefund)
        );

        if (numRefundValue <= 0) {
          errors.refundValue = 'valor deve ser maior do que zero';
        } else if (numRefundValue > numMaxValueToRefund) {
          errors.refundValue =
            'valor ultrapassa o limite disponível para devolução';
        } else if (numRefundValue < numMinValueToRefund) {
          errors.refundValue = 'valor não atigiu o mínimo para devolução';
        }
      }

      return errors;
    }
  })
)(RefundSimulationStep);
