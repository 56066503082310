import React from 'react';
import { WarningContainer } from 'components/Devise/devise.styles';
import { Typography, Button } from '@useblu/ocean-react';
import { useMedia } from 'react-use';
import DeviseScreenLayout from '../DeviseScreenLayout';
import docValidation from '../../images/ilustra-bad-request.svg';

const FailedCafStatusWarning = () => {
  const isMobile = useMedia('(max-width: 768px)');
  return (
    <DeviseScreenLayout className="adjust-logo">
      <WarningContainer $withCaptionOnMobile>
        <img src={docValidation} alt="doc validation" />
        <Typography variant="heading2">
          Não foi possível validar seus documentos
        </Typography>
        <Typography variant="paragraph">
          Por isso, seu cadastro não foi concluído.
        </Typography>
        {isMobile && (
          <Button
            variant="primary"
            onClick={() => window.location.assign('/users/sign_in')}
          >
            Voltar ao início
          </Button>
        )}
      </WarningContainer>
    </DeviseScreenLayout>
  );
};
export default FailedCafStatusWarning;
