import { getUserBigBoostData } from 'api/big-boost';
import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/getClientBigboost';

export function* loadBigBoostData(params) {
  const cpf = params?.cpf;

  try {
    if (cpf) {
      const userData = yield call(getUserBigBoostData, cpf);

      const { bigBoostIndividualBasicData } = userData.data.data;

      if (bigBoostIndividualBasicData) {
        yield put(
          ActionCreators.getClientBigboostSuccess({
            ...bigBoostIndividualBasicData,
            cpf
          })
        );
      }
    }
  } catch (err) {
    yield put(ActionCreators.getClientBigboostFailure(err));
  }
}
