import React from 'react';
import PropTypes from 'prop-types';

import BTokenModal from '../BTokenModal';
import {
  Title,
  Steps,
  Step,
  Badge,
  Image,
  Description,
  DescriptionText
} from './KnowMoreStyles';

import optionStep from '../images/btoken-option-step.svg';
import codeStep from '../images/ilustra-otpdesktop.png';

function KnowMore({ isOpen, close }) {
  return (
    <BTokenModal
      showCloseButton={true}
      customWidth={670}
      customMaxHeight={'95vh'}
      isOpen={isOpen}
      close={close}
      isDrawerOnMobile={false}
    >
      <Title>Como autorizar suas transações com o código bToken</Title>

      <Steps>
        <Step>
          <Description>
            <Badge>
              <span>1</span>
            </Badge>
            <DescriptionText>
              Abra o app Blu no seu celular e{' '}
              <strong>selecione a opção bToken</strong>
            </DescriptionText>
          </Description>
          <Image src={optionStep} alt="Passo 1" />
        </Step>
        <Step>
          <Description>
            <Badge>
              <span>2</span>
            </Badge>
            <DescriptionText>
              <strong>Digite</strong> na tela do portal{' '}
              <strong>o código</strong> exibido no celular
            </DescriptionText>
          </Description>
          <Image src={codeStep} alt="Passo 2" />
        </Step>
      </Steps>
    </BTokenModal>
  );
}

KnowMore.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default KnowMore;
