import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import {
  colorBrandPrimaryUp,
  colorInterfaceLightPure,
  fontWeightExtrabold
} from '@useblu/ocean-tokens/web/tokens';

import Tag from '../../shared/Tag';
import MenuIcon from './MenuIcon';

const ItemContent = styled.li`
  font-style: black;
  font-size: 12px;
  margin-bottom: 8px;
  width: 100%;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 56px;
    font-weight: ${fontWeightExtrabold};
    color: ${colorBrandPrimaryUp};
    position: relative;

    span.menuLabel {
      padding-top: 4px;
    }

    &.is-active,
    &:hover,
    &:active,
    &:focus {
      color: ${colorInterfaceLightPure};

      path,
      rect {
        stroke: ${colorInterfaceLightPure};
      }
    }
  }

  img,
  i,
  svg {
    display: block;
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  ${Tag} {
    position: absolute;
    left: 55px;
    margin-top: -40px;

    /*  Tag (Mobile and Tablet) */
    @media (max-width: 760px) {
      position: relative;
      left: 12px;
      margin: 0;
    }
  }
`;

const Item = ({
  link,
  target,
  active,
  label,
  icon,
  badgeCount,
  separator,
  onClick,
  spotlight
}) =>
  !separator && (
    <ItemContent>
      <a
        href={link}
        target={target}
        rel="noopener noreferrer"
        data-testid={`itemMenu-${label}`}
        className={classNames({ 'is-active': active })}
        onClick={(e) => onClick(e)}
      >
        <MenuIcon icon={icon} />
        <span className="menuLabel">{label}</span>
        {badgeCount > 0 && <Tag>{badgeCount}</Tag>}
        {!isEmpty(spotlight) && <Tag>{spotlight}</Tag>}
      </a>
    </ItemContent>
  );

Item.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string,
  target: PropTypes.string,
  active: PropTypes.bool,
  icon: PropTypes.string,
  badgeCount: PropTypes.number,
  separator: PropTypes.bool,
  onClick: PropTypes.func,
  spotlight: PropTypes.string
};

Item.defaultProps = {
  label: '',
  target: undefined,
  active: false,
  icon: '',
  spotlight: '',
  badgeCount: undefined,
  separator: false,
  onClick: () => {}
};

export default Item;
