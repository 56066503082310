import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: #6c79a1;
  font-size: 16px;
  padding: 0 13px;
`;

export const Row = styled(Container)`
  grid-column: 1 / -1;
`;
