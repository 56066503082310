import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@useblu/ocean-react';
import { ExternalLink } from '@useblu/ocean-icons-react';
import { formatDate } from 'normalizers';

import notActiveImg from './images/img-not-active.svg';
import { ModalContent, ModalActions } from './style';

const BTokenWarning = ({ forceBtokenAt }) => {
  const redirect = () => {
    window.location.href = '/btoken';
  };

  const openBlank = () => {
    window.open(`https://www.comunidade.blu.com.br/post/btoken-blu`, '_blank');
  };

  return (
    <>
      <ModalContent>
        <div className="img-container">
          <img src={notActiveImg} alt="not active" />
        </div>
        <div className="info-container">
          <p>Aprove suas transações com 1 clique</p>
          <h1>Ative o bToken e evite bloqueios em sua conta</h1>
          <span>
            A partir do dia {formatDate(forceBtokenAt)} a ativação do bToken
            será obrigatória para realizar transferências e pagamentos.
          </span>
        </div>
      </ModalContent>
      <ModalActions>
        <Button onClick={openBlank} variant="secondary">
          Saiba mais <ExternalLink />
        </Button>
        <Button onClick={redirect}>Ativar bToken</Button>
      </ModalActions>
    </>
  );
};

BTokenWarning.propTypes = {
  forceBtokenAt: PropTypes.string.isRequired
};

export default BTokenWarning;
