const isValidName = (name) => {
  const specialCharchters = /[0-9!@#$%&*()_+\-=\[\]{};:"\\|,.<>\/?^`´~¨]/g;
  let validation = true;

  if (typeof name === 'string') {
    const fullName = name.replace(/\s+/g, ' ').trim().split(' ');

    validation = fullName.length >= 2;

    if (specialCharchters.test(name)) validation = false;
  } else {
    validation = false;
  }
  return validation;
};

export default isValidName;
