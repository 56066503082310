import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { normalizeCurrency } from 'normalizers';

const ChargedRefundSummary = ({ paidValue }) => (
  <Fragment>
    {paidValue > 0 && (
      <span>
        Valor pago:{' '}
        <span className="statement-detail__refunded--outflow">
          R$ {normalizeCurrency(paidValue)}
        </span>
      </span>
    )}
  </Fragment>
);

ChargedRefundSummary.propTypes = {
  paidValue: PropTypes.number.isRequired
};

export default ChargedRefundSummary;
