import React from 'react';
import PropTypes from 'prop-types';

import SignInForm from './SignInForm';
import TwoFactorAuthentication from './TwoFactorAuthentication';
import { useAuthenticationContext } from '../context/authentication.context';

const Authentication = ({
  secretKey,
  autoSignUpDisabled,
  recaptchaKeyV2,
  recaptchaKeyV3,
  antiFraudAlertEnabled,
}) => {
  const { renderTwoFactorComponent } = useAuthenticationContext();

  return renderTwoFactorComponent ? (
    <TwoFactorAuthentication />
  ) : (
    <SignInForm
      secretKey={secretKey}
      autoSignUpDisabled={autoSignUpDisabled}
      recaptchaKeyV2={recaptchaKeyV2}
      recaptchaKeyV3={recaptchaKeyV3}
      antiFraudAlertEnabled={antiFraudAlertEnabled}
    />
  );
};

Authentication.propTypes = {
  secretKey: PropTypes.string,
  recaptchaKeyV2: PropTypes.string,
  recaptchaKeyV3: PropTypes.string,
  autoSignUpDisabled: PropTypes.bool,
  antiFraudAlertEnabled: PropTypes.bool,
};

Authentication.defaultProps = {
  secretKey: '',
  recaptchaKeyV2: '',
  recaptchaKeyV3: '',
  autoSignUpDisabled: false,
  antiFraudAlertEnabled: false,
};

export default Authentication;
