import loadBankOptionsAPI from 'api/bank-options';

import { call, put } from 'redux-saga/effects';

import ActionCreators from 'store/ducks/bankOptions';

function* loadBankOptions() {
  try {
    const response = yield call(loadBankOptionsAPI);

    const { data } = response;

    yield put(ActionCreators.bankOptionsSuccess(data));
  } catch (err) {
    yield put(ActionCreators.bankOptionsFailure());
  }
}

export default loadBankOptions;
