import styled from 'styled-components';
import { Typography } from '@useblu/ocean-react';
import {
  borderRadiusLg,
  colorInterfaceLightUp,
  colorInterfaceDarkDeep,
  colorInterfaceLightPure,
  fontSizeSm,
  shadowLevel3,
  spacingStackLg,
  spacingStackMd,
  colorBrandPrimaryPure,
  spacingInlineXs
} from '@useblu/ocean-tokens/web/tokens';

const mediaScreen = {
  xs: `@media (max-width: 480px)`,
  sm: `@media (min-width: 600px)`,
  md: `@media (min-width: 992px)`,
  lg: `@media (min-width: 993px)`
};

export const Wrapper = styled.div`
  background: ${colorInterfaceLightPure};
  border-radius: ${borderRadiusLg};
  padding: ${spacingStackMd} ${spacingStackLg};
  box-shadow: ${shadowLevel3};
  border: 1px solid #d8dae8;
  height: 100%;

  ${mediaScreen.xs} {
    height: auto;
    margin-top: ${spacingStackMd};
  }
`;

export const Title = styled(Typography).attrs(() => ({
  variant: 'heading3'
}))`
  &&& {
    margin: ${spacingInlineXs} 0px;
    color: ${colorInterfaceDarkDeep};
    font-size: ${fontSizeSm};
  }
`;

export const Icon = styled.div`
  background: ${colorInterfaceLightUp};
  display: flex;
  border-radius: 50%;
  align-items: center;
  padding: 10px 0px;
  width: 45px;
  justify-content: center;

  svg {
    color: ${colorBrandPrimaryPure};
  }
`;
