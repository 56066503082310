import styled from 'styled-components';

import {
  borderRadiusLg,
  colorInterfaceDarkDeep,
  colorInterfaceDarkDown,
  fontSizeMd,
  fontSizeXs,
  fontSizeXxxs
} from '@useblu/ocean-tokens/web/tokens';

import { Typography } from '@useblu/ocean-react';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  margin-top: 2rem;
`;

export const Thead = styled.thead`
  tr th {
    background: #dde2ff;
    border-radius: ${borderRadiusLg} ${borderRadiusLg} 0 0;
    border: 0;
  }
`;
export const Tbody = styled.tbody`
  tr {
    background: #f7f9ff;

    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: ${borderRadiusLg};
        }

        &:last-child {
          border-bottom-right-radius: ${borderRadiusLg};
        }
      }
    }

    &:nth-child(2n + 0) {
      background: #eef1ff;
    }

    td {
      color: ${colorInterfaceDarkDown};
      border: 0;
      padding: 0.5rem;
      font-size: ${fontSizeXs};

      b {
        font-weight: bold;
      }

      span {
        display: block;
        font-size: 12px;
      }
    }
  }
`;

export const SubTitle = styled(Typography).attrs(() => ({
  variant: 'description'
}))`
  &&& {
    text-align: center;
    font-size: ${fontSizeXs};
    font-weight: 800;
    color: ${colorInterfaceDarkDown};
    margin-bottom: 0;
  }
`;

export const Title = styled(Typography).attrs(() => ({
  variant: 'description'
}))`
  &&& {
    text-align: center;
    font-size: ${fontSizeMd};
    font-weight: 900;
    color: ${colorInterfaceDarkDeep};
  }
`;

export const Description = styled(Typography).attrs(() => ({
  variant: 'description'
}))`
  &&& {
    margin-top: -20px;
    margin-bottom: 20px;
    padding: 1rem;
    font-size: ${fontSizeXxxs};
    color: ${colorInterfaceDarkDeep};
  }
`;
