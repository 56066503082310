import React, { useCallback, useEffect, useState } from 'react';
import Loading from 'react-loading';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import history from 'utils/browserHistory';
import Breadcrumb from 'components/Shared/Breadcrumb';
import importConfirmationCreators from 'store/ducks/importPaymentConfirmation';
import { normalizeCurrency, formatDate } from 'normalizers';
import { importPaymentConfirmationSelector } from 'selectors/payment-confirmation';
import { SubTitle } from 'components/Shared/Layout/Header/style';
import TechnicalProblemsModal from 'components/Shared/TechnicalProblemsModal';
import { isEmpty } from 'lodash';

const Confirmation = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const [reference, setReference] = useState('');
  const [showTechnicalProblemsModal, setShowTechnicalProblemsModal] =
    useState(false);

  const { taxesValues, loadingConfirm, data, errorMessage } = useSelector(
    importPaymentConfirmationSelector
  );

  const submit = () => {
    dispatch(
      importConfirmationCreators.confirmImportPaymentRequest(uuid, reference)
    );
  };

  useEffect(() => {
    if (!isEmpty(errorMessage)) setShowTechnicalProblemsModal(true);
  }, [errorMessage]);

  const closeModal = useCallback(() => {
    setShowTechnicalProblemsModal(false);
  }, []);

  if (data.length === 0)
    window.location.href = `/lojas/${uuid}/transacoes/transferencia/nova`;

  return (
    <div>
      <Breadcrumb
        items={[
          ['Início', `/lojas/${uuid}/dashboard`],
          ['Transferência', `/lojas/${uuid}/transacoes/transferencia/nova`],
          [
            'Importar arquivo de transferência',
            `/lojas/${uuid}/transacoes/transferencia/importacao`,
          ],
          [
            'Dados do arquivo',
            `/lojas/${uuid}/transacoes/transferencia/nova/dados-importacao`,
          ],
          ['Incluir Transferencias'],
        ]}
        className="breadcrumbs-2 no-padding only-desktop"
      />
      <div className="transfer-new-page-title only-desktop">
        <h1>Incluir transferências</h1>
        <SubTitle>
          Confira as informações abaixo antes de incluir as transferências.
        </SubTitle>
      </div>
      <div className="page-title-mobile only-mobile">
        <h1 className="page-title-mobile-content">
          <button
            type="button"
            onClick={() =>
              history.push(
                `/lojas/${uuid}/transacoes/transferencia/nova/dados-importacao`
              )
            }
            className="page-title-mobile-back"
          >
            <i className="ic ic-arrow-left" />
          </button>
          <span>Importar arquivo</span>
        </h1>
      </div>
      <div className="u-flex">
        <div
          className="confirmation-taxes-content flex-1"
          style={{ maxWidth: '768px' }}
        >
          <div className="confirmation-taxes-multiple-fields">
            <div className="confirmation-taxes-field">
              <label>Data da inclusão</label>
              <span>{formatDate(taxesValues.inclusionAt)}</span>
            </div>
            <div className="confirmation-taxes-field">
              <label>Qtd. de transferências</label>
              <span>{taxesValues.numberOfTransactions}</span>
            </div>
            <div className="confirmation-taxes-field">
              <label>Valor das transferências</label>
              <span>
                <b>
                  {`R$ ${normalizeCurrency(parseFloat(taxesValues.total))}`}
                </b>
              </span>
            </div>
          </div>
          <hr className="confirmation-taxes-divider" />
          <div className="confirmation-taxes-multiple-fields">
            <div className="confirmation-taxes-field">
              <label>Antecipação de recebíveis</label>
              <span>
                {`R$ ${normalizeCurrency(
                  parseFloat(taxesValues.totalAdvancedValue)
                )}`}
              </span>
            </div>
            <div className="confirmation-taxes-field other-rates">
              <label>Outras tarifas</label>
              <span className="value">
                {`R$ ${normalizeCurrency(parseFloat(taxesValues.otherRates))}`}
              </span>
            </div>
            <div className="confirmation-taxes-field">
              <label>Valor debitado</label>
              <span>
                <b>
                  {`R$ ${normalizeCurrency(
                    parseFloat(taxesValues.totalWithTaxes)
                  )}`}
                </b>
              </span>
            </div>
          </div>
          <div className="confirmation-taxes-reference">
            <div>
              <label htmlFor="reference">Referência no extrato</label>
            </div>
            <div className="confirmation-taxes-reference-input">
              <input
                id="reference"
                type="text"
                onChange={(e) => setReference(e.target.value)}
                name="reference"
                value={reference}
              />
            </div>
          </div>
          <p className="confirmation-taxes-warning">
            <b>ATENÇÃO!</b> Antecipações de recebíveis não poderão ser
            canceladas após está operação.
          </p>
          <div className="confirmation-taxes-buttons">
            <button
              type="button"
              onClick={() =>
                history.push(
                  `/lojas/${uuid}/transacoes/transferencia/nova/dados-importacao`
                )
              }
              className="button button--rounder button--secondary flex-1"
            >
              Voltar
            </button>
            <button
              type="button"
              onClick={submit}
              disabled={loadingConfirm}
              className="button button--rounder flex-1"
            >
              {loadingConfirm ? (
                <div className="u-flex content-center">
                  <Loading
                    type="spinningBubbles"
                    color="#343e4f"
                    delay={0}
                    width={17}
                    height={17}
                  />
                  <span>&nbsp;Incluindo Transferências...</span>
                </div>
              ) : (
                'Incluir'
              )}
            </button>
          </div>
        </div>
      </div>
      <TechnicalProblemsModal
        isOpen={showTechnicalProblemsModal}
        onClose={closeModal}
      />
    </div>
  );
};

export default Confirmation;
