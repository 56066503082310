import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
  fetchBTokenByUserRequest: null,
  fetchBTokenByUserSuccess: ['list'],
  fetchBTokenByUserFailure: ['error'],
  revokeBTokenByIdRequest: ['id'],
  revokeBTokenByIdSuccess: ['id'],
  revokeBTokenByIdFailure: ['error']
});

export { types };

export default actions;
