/* Core */
import React from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { connect } from 'react-redux';
import { compose } from 'redux';

/* Redux Form */
import { onSubmitActions } from 'redux-form-submit-saga';

/* Actions and Validations */
import clientInfoActions from 'store/ducks/firstAccess/clientInfo';
import sharedActions from 'store/ducks/firstAccess/shared';
import validateClientInfoForm from 'validations/ValidateClientInfoForm';

/* Presentational */
import { reduxForm, Form, Field } from 'redux-form';
import RenderField from 'components/Form/RenderField';
import Loading from 'react-loading';
import _ from 'lodash';
import Client from 'components/FirstAccess/Client/Client';
import ClientAddress from 'components/FirstAccess/Client/ClientAddress';
import { clientSelector } from 'selectors/FirstAccess/shared';
import history from 'utils/browserHistory';

function SampleNextArrow(props) {
  const { className, onClick, currentSlide, slideCount, invalid } = props;

  return (
    <div className={className}>
      <button
        type="button"
        className="button button--primary"
        onClick={onClick}
        style={{
          display: _.isEqual(currentSlide, slideCount - 1) ? 'none' : ''
        }}
        disabled={invalid}
      >
        Confirmar
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick, currentSlide, invalid } = props;

  return (
    <div className={className}>
      <button
        type="button"
        className="button button--secondary button--small"
        onClick={onClick}
        style={{
          display: _.isEqual(currentSlide, 0) ? 'none' : ''
        }}
        disabled={invalid}
      >
        <i className="fa fa-chevron-left" /> Voltar
      </button>
    </div>
  );
}

class ClientInfoForm extends React.Component {
  constructor(props) {
    super(props);

    this.searchPosAddress = this.searchPosAddress.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.state = {
      setMainAddressAsPosAddress: true,
      posDeliveryAddressSearch: { error: false },
      slides: {
        current: 'client',
        client: { enabled: false },
        mainAddress: { enabled: false },
        posDeliveryAddress: { enabled: false }
      }
    };
  }

  componentDidMount() {
    this.props.setCurrentStep('clientInfo');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      client: nextClient,
      client: { mainAddress, posDeliveryAddress }
    } = nextProps;

    const { client, clientInfoSetAddress } = this.props;

    if (!_.isEmpty(client) && _.isEmpty(posDeliveryAddress) && mainAddress) {
      clientInfoSetAddress(nextClient, mainAddress, 'posDeliveryAddress');
    }
  }

  setAddress(address, kind) {
    const { client, clientInfoSetAddress } = this.props;
    const { setMainAddressAsPosAddress } = this.state;

    if (_.isEqual(kind, 'posDeliveryAddress')) {
      return clientInfoSetAddress(client, address, 'posDeliveryAddress');
    } else if (_.isEqual(kind, 'mainAddress') && setMainAddressAsPosAddress) {
      clientInfoSetAddress(client, address, 'posDeliveryAddress');
    }

    return clientInfoSetAddress(client, address, 'mainAddress');
  }

  setPosAddressState(
    setMainAddressAsPosAddress,
    setPosDeliveryAddressSearch,
    setNewPosDeliveryAddress
  ) {
    this.setState({
      setMainAddressAsPosAddress,
      setPosDeliveryAddressSearch,
      setNewPosDeliveryAddress
    });
  }

  setSearchedPosDeliveryAddressError(error) {
    this.setState({ posDeliveryAddressSearch: { error } });
  }

  searchPosAddress() {
    const { clientInfoSearchAddressByCpfCnpj, client } = this.props;
    if (this.refs.posDeliveryAddressCpfCnpj.value) {
      const trimmedValue = this.refs.posDeliveryAddressCpfCnpj.value
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
      clientInfoSearchAddressByCpfCnpj(client, trimmedValue);
    }
  }

  handlePosAddressChanges(e) {
    const { client, clientInfoSetAddress } = this.props;
    const { value } = e.target;
    let posDeliveryAddress;

    if (_.isEqual(value, 'setMainAddressAsPosAddress')) {
      this.setPosAddressState(true, false, false);
      posDeliveryAddress = client.mainAddress;
    }

    if (_.isEqual(value, 'setPosDeliveryAddressSearch')) {
      this.setPosAddressState(false, true, false);
    }

    if (_.isEqual(value, 'setNewPosDeliveryAddress')) {
      this.setPosAddressState(false, false, true);
      posDeliveryAddress = {
        zipcode: '',
        street: '',
        number: '',
        neighborhood: '',
        complement: '',
        city: '',
        state: ''
      };
    }

    if (!_.isEmpty(posDeliveryAddress)) {
      return clientInfoSetAddress(
        client,
        posDeliveryAddress,
        'posDeliveryAddress'
      );
    }

    return posDeliveryAddress;
  }

  hideSummary(summary) {
    const { slides } = this.state;
    slides[summary] = {
      enabled: !slides[summary].enabled
    };
    this.setState({ ...slides });
  }

  changeForm(current) {
    const { slides } = this.state;
    this.setState({
      slides: {
        ...slides,
        current
      }
    });
  }

  renderClient() {
    const { client, firstAccessClientSuccess, invalid } = this.props;
    const { slides } = this.state;
    let content;

    if (!_.isEmpty(client)) {
      content = (
        <div className="client-info-form">
          <div className="title-container">
            <h1 className="page-subtitle">
              <i className="fa fa-building" /> Dados da Empresa
            </h1>
            <h3 className="page-description">Confirma o nome fantasia?</h3>
            <br />
            <Client
              client={client}
              disabled={!slides.client.enabled}
              setClient={firstAccessClientSuccess}
            />
          </div>
          <div className="form-row links">
            <a
              onClick={() => this.hideSummary('client')}
              className={slides.client.enabled ? 'enabled' : ''}
            >
              <i className="fa fa-pencil" />{' '}
              {slides.client.enabled ? 'sair do modo edição' : 'alterar'}
            </a>
          </div>
          <div className="form-row">
            <button
              type="button"
              className="button button--primary button--large"
              onClick={() => this.changeForm('mainAddress')}
              disabled={invalid}
            >
              Confirmar
            </button>
          </div>
        </div>
      );
    } else {
      content = this.renderLoading();
    }

    return content;
  }

  renderMainAddress() {
    const {
      client,
      clientInfoSearchAddressByZipcode,
      loadings,
      invalid
    } = this.props;

    const { slides } = this.state;

    let content;

    if (client.mainAddress) {
      content = (
        <div className="client-info-form">
          <div className="title-container">
            <h1 className="page-subtitle">
              <i className="fa fa-building" /> Dados da Empresa
            </h1>
            <h3 className="page-description">
              Confirma o endereço da empresa?
            </h3>
            <br />
            <ClientAddress
              name="mainAddress"
              address={client.mainAddress}
              setAddressToReducer={(address, kind) =>
                this.setAddress(address, kind)
              }
              onZipcodeChange={(zipcode, kind) =>
                clientInfoSearchAddressByZipcode(client, zipcode, kind)
              }
              disabled={!slides.mainAddress.enabled}
              loading={loadings.mainAddressLoading}
            />
          </div>
          <div className="form-row links">
            <a
              onClick={() => this.hideSummary('mainAddress')}
              className={slides.mainAddress.enabled ? 'enabled' : ''}
            >
              <i className="fa fa-pencil" />{' '}
              {slides.mainAddress.enabled ? 'sair do modo edição' : 'alterar'}
            </a>
          </div>
          <div className="form-row actions">
            <button
              className="button button--secondary"
              onClick={() => this.changeForm('client')}
            >
              Voltar
            </button>
            <button
              type="button"
              className="button button--primary"
              onClick={() => this.changeForm('posDeliveryAddress')}
              disabled={invalid}
            >
              Confirmar
            </button>
          </div>
        </div>
      );
    } else {
      content = this.renderLoading();
    }

    return content;
  }

  renderPosAddress() {
    const {
      client,
      clientInfoSearchAddressByZipcode,
      loadings,
      submitting,
      invalid
    } = this.props;

    const {
      setMainAddressAsPosAddress,
      setPosDeliveryAddressSearch,
      setNewPosDeliveryAddress,
      slides
    } = this.state;

    const {
      noFinded
    } = this.props.firstAccessShared.client.data.posDeliveryAddress;

    let content = null;

    if (setPosDeliveryAddressSearch) {
      content = this.renderPosAddressSearch();
    }

    if (
      !_.isEmpty(client.posDeliveryAddress) &&
      (!setPosDeliveryAddressSearch || noFinded === false)
    ) {
      content = (
        <div>
          {content}
          <ClientAddress
            name="posDeliveryAddress"
            address={client.posDeliveryAddress || {}}
            setAddressToReducer={(address, kind) =>
              this.setAddress(address, kind)
            }
            onZipcodeChange={(zipcode, kind) =>
              clientInfoSearchAddressByZipcode(client, zipcode, kind)
            }
            disabled={
              !slides.posDeliveryAddress.enabled ||
              setMainAddressAsPosAddress ||
              setPosDeliveryAddressSearch
            }
            loading={loadings.posAddressLoading}
          />
          <div className="form-row links">
            <a
              onClick={() => this.hideSummary('posDeliveryAddress')}
              className={slides.posDeliveryAddress.enabled ? 'enabled' : ''}
            >
              <i className="fa fa-pencil" />
              {slides.posDeliveryAddress.enabled
                ? 'sair do modo edição'
                : 'alterar'}
            </a>
          </div>
          <div className="form-row actions">
            <button
              className="button button--secondary"
              onClick={() => this.changeForm('mainAddress')}
            >
              Voltar
            </button>
            <button
              className="button button--primary"
              disabled={submitting || invalid}
            >
              Confirmar
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="title-container client-info-form">
        <h1 className="page-subtitle">
          <i className="fa fa-building" /> Dados da Empresa
        </h1>
        <h3 className="page-description">
          Confirma o endereço de recebimento do POS?
        </h3>
        <br />
        {slides.posDeliveryAddress.enabled && (
          <div className="form-row radio">
            <div className="form-controls radio">
              <label>
                <input
                  type="radio"
                  value="setMainAddressAsPosAddress"
                  defaultChecked={setMainAddressAsPosAddress ? 'checked' : ''}
                  name="setPosDeliveryAddress"
                  onChange={(e) => this.handlePosAddressChanges(e)}
                />
                Usar endereço principal
              </label>
              <label>
                <input
                  type="radio"
                  value="setNewPosDeliveryAddress"
                  defaultChecked={setNewPosDeliveryAddress ? 'checked' : ''}
                  name="setPosDeliveryAddress"
                  onChange={(e) => this.handlePosAddressChanges(e)}
                />
                Cadastrar novo endereço
              </label>
              <label>
                <input
                  type="radio"
                  value="setPosDeliveryAddressSearch"
                  defaultChecked={setPosDeliveryAddressSearch ? 'checked' : ''}
                  name="setPosDeliveryAddress"
                  onChange={(e) => this.handlePosAddressChanges(e)}
                />
                Buscar outro endereço
              </label>
            </div>
          </div>
        )}
        {content}
      </div>
    );
  }

  renderPosAddressSearch() {
    const {
      noFinded
    } = this.props.firstAccessShared.client.data.posDeliveryAddress;

    return (
      <div className="form-row">
        <div className="form-controls text">
          <label htmlFor="posDeliveryAddressCpfCnpj">
            Digite um CPF ou CNPJ e clique em buscar para encontrar um endereço
          </label>
          <div className={`${noFinded === true ? 'field-with-errors' : ''}`}>
            <Field
              type="text"
              component={RenderField}
              mask="cpf_cnpj"
              id="posDeliveryAddressCpfCnpj"
              name="posDeliveryAddressCpfCnpj"
              ref="posDeliveryAddressCpfCnpj"
              onChange={() => this.setSearchedPosDeliveryAddressError(false)}
            />
            <button
              type="button"
              className="button button--secondary button--small"
              onClick={this.searchPosAddress}
            >
              Buscar
            </button>
            {noFinded && (
              <span className="field-error">Cpf/Cnpj não encontrado.</span>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="loading">
        <Loading
          type="spinningBubbles"
          color="#e1e5ed"
          width={35}
          height={35}
          delay={0}
        />
      </div>
    );
  }

  renderClientInfoForm() {
    const { handleSubmit, client } = this.props;

    const {
      slides: { current }
    } = this.state;

    if (!_.isEmpty(client)) {
      return (
        <Form className="form-fields-for" onSubmit={handleSubmit} noValidate>
          {current === 'client' && this.renderClient()}
          {current === 'mainAddress' && this.renderMainAddress()}
          {current === 'posDeliveryAddress' && this.renderPosAddress()}
        </Form>
      );
    }

    return null;
  }

  render() {
    return <div className="flex-container">{this.renderClientInfoForm()}</div>;
  }
}

ClientInfoForm.propTypes = {
  client: PropTypes.object.isRequired,
  clientInfoSetAddress: PropTypes.func.isRequired,
  clientInfoSearchAddressByZipcode: PropTypes.func.isRequired,
  clientInfoSearchAddressByCpfCnpj: PropTypes.func.isRequired,
  firstAccessClientSuccess: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  loadings: PropTypes.bool,
  firstAccessShared: PropTypes.shape({
    client: PropTypes.shape({
      data: PropTypes.shape({
        posDeliveryAddress: PropTypes.shape({
          noFinded: PropTypes.bool
        })
      })
    })
  }).isRequired
};

ClientInfoForm.defaultProps = {
  client: {},
  errors: {},
  submitting: false,
  invalid: true,
  handleSubmit: () => {},
  loadings: {}
};

const mapStateToProps = (state) => ({
  client: clientSelector(state),
  businessName: clientSelector(state) && clientSelector(state).businessName,
  zipcode:
    clientSelector(state) &&
    clientSelector(state).mainAddress &&
    clientSelector(state).mainAddress.zipcode,
  street:
    clientSelector(state) &&
    clientSelector(state).mainAddress &&
    clientSelector(state).mainAddress.street,
  neighborhood:
    clientSelector(state) &&
    clientSelector(state).mainAddress &&
    clientSelector(state).mainAddress.neighborhood,
  city:
    clientSelector(state) &&
    clientSelector(state).mainAddress &&
    clientSelector(state).mainAddress.city,
  state:
    clientSelector(state) &&
    clientSelector(state).mainAddress &&
    clientSelector(state).mainAddress.state,
  number:
    clientSelector(state) &&
    clientSelector(state).mainAddress &&
    clientSelector(state).mainAddress.number,
  complement:
    clientSelector(state) &&
    clientSelector(state).mainAddress &&
    clientSelector(state).mainAddress.complement,
  zipcode2:
    clientSelector(state) &&
    clientSelector(state).posDeliveryAddress &&
    clientSelector(state).posDeliveryAddress.zipcode,
  street2:
    clientSelector(state) &&
    clientSelector(state).posDeliveryAddress &&
    clientSelector(state).posDeliveryAddress.street,
  neighborhood2:
    clientSelector(state) &&
    clientSelector(state).posDeliveryAddress &&
    clientSelector(state).posDeliveryAddress.neighborhood,
  city2:
    clientSelector(state) &&
    clientSelector(state).posDeliveryAddress &&
    clientSelector(state).posDeliveryAddress.city,
  state2:
    clientSelector(state) &&
    clientSelector(state).posDeliveryAddress &&
    clientSelector(state).posDeliveryAddress.state,
  number2:
    clientSelector(state) &&
    clientSelector(state).posDeliveryAddress &&
    clientSelector(state).posDeliveryAddress.number,
  complement2:
    clientSelector(state) &&
    clientSelector(state).posDeliveryAddress &&
    clientSelector(state).posDeliveryAddress.complement,
  errors: state.firstAccessClientInfo.errors,
  firstAccessShared: state.firstAccessShared,
  loadings: state.firstAccessClientInfo.address,
  initialValues: {
    client: clientSelector(state)
  }
});

const mapDispatchToProps = (dispatch) => ({
  clientInfoSearchAddressByZipcode: (client, zipcode, kind) => {
    dispatch(
      clientInfoActions.firstAccessClientInfoSearchAddressByZipcode(
        client,
        zipcode,
        kind
      )
    );
  },
  clientInfoSetAddress: (client, address, kind) => {
    dispatch(
      clientInfoActions.firstAccessClientInfoSetAddressRequest(
        client,
        address,
        kind
      )
    );
  },
  clientInfoSearchAddressByCpfCnpj: (client, cpfCnpj) => {
    dispatch(
      clientInfoActions.firstAccessClientInfoSearchAddressByCpfCnpj(
        client,
        cpfCnpj
      )
    );
  },
  setCurrentStep: (step) => {
    dispatch(sharedActions.firstAccessCurrentStep(step));
  },
  firstAccessClientSuccess: (client) => {
    dispatch(sharedActions.firstAccessClientSuccess(client));
  }
});

const ClientInfoFormReduxForm = reduxForm({
  form: 'clientInfoForm',
  validate: validateClientInfoForm,
  shouldValidate() {
    return true;
  },
  enableReinitialize: true,
  onSubmit: onSubmitActions('FIRST_ACCESS_CLIENT_INFO_FORM'),
  onSubmitSuccess: (result, dispatch, props) => {
    history.push(`/lojas/${props.uuid}/primeiro-acesso/informacoes-dos-socios`);
  },
  onSubmitFail: (errors) => {
    if (!_.isEmpty(errors)) {
      const fieldName = Object.keys(errors)[0];
      const fieldValue = Object.keys(errors[fieldName])[0];
      const inputError = document.querySelectorAll(
        `input[name='${fieldName}[${fieldValue}]'], select[name='${fieldName}[${fieldValue}]']`
      )[0];

      if (inputError) {
        inputError.focus();
      }
    }
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  ClientInfoFormReduxForm
)(ClientInfoForm);
