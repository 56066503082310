import { keyBy, map } from 'lodash';
import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
  addSingleCNAB: ['payload'],
  addMultipleCNAB: ['payload', 'updateHasMore'],
  goNextPage: null
});

export { actions };

export const initialState = {
  byId: {},
  nonProcessedIds: [],
  currentPage: 1,
  hasMore: false
};

function reducer(state, action) {
  switch (action.type) {
    case types.ADD_SINGLE_CNAB:
      if (!action.payload) return state;

      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        nonProcessedIds: [...state.nonProcessedIds, action.payload.id]
      };
    case types.ADD_MULTIPLE_CNAB: {
      if (!action.payload) return state;

      const byId = {
        ...state.byId,
        ...keyBy(action.payload.data, 'id')
      };

      return {
        ...state,
        byId,
        nonProcessedIds: map(byId)
          .filter((c) => c.status !== 'finished')
          .flatMap((c) => c.id),
        hasMore: action.updateHasMore ? action.payload.hasMore : state.hasMore
      };
    }
    case types.GO_NEXT_PAGE:
      return { ...state, currentPage: state.currentPage + 1 };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export default reducer;
