import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types } = createActions({
  railsContext: null
});

export { Types };

/* Initial State */
let INITIAL_STATE = {};

if (window.railsState) {
  INITIAL_STATE = { ...INITIAL_STATE, ...window.railsState.railsContext };
}

/* Reducers */
export const initial = (state) => ({
  ...state
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.RAILS_CONTEXT]: initial
});
