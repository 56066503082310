import React, { useEffect, useMemo, useState } from 'react';
import PageView from 'components/AccountStatement/components/PageView';
import { isEmpty } from 'lodash';
import { useAsyncFn, useMedia } from 'react-use';
import { formatDate } from 'normalizers';
import { getPaymentData } from 'api/pix';
import { formatPrice } from 'components/AccountStatement/helpers/currency';
import { Button, TopBar } from '@useblu/ocean-react';
import { ArrowLeftOutline } from '@useblu/ocean-icons-react';
import useRailsContext from 'hooks/useRailsContext';
import { getBankBy } from 'api/bank-account';
import humps from 'lodash-humps';
import { normalizeCpfOrCnpj, normalizeMoney } from '@useblu/utils';
import useAdvancementDetailsDrawer from 'components/Shared/AdvancementDetailsDrawer/hooks/useAdvancementDetailsDrawer';
import * as S from './styles';
import accountStatementStatus from '../../DARFScreen/accountStatementStatus';

const Withdrawal = () => {
  const isMobile = useMedia('(max-width: 768px)');

  const { openDrawer } = useAdvancementDetailsDrawer();
  const { clientId } = useRailsContext();

  const [bank, setBank] = useState(null);
  const [transactionUUID, setTransactionUUID] = useState('');

  const [data, requestData] = useAsyncFn(async () => {
    const response = await getPaymentData(clientId, transactionUUID);
    return humps(response.data);
  }, [transactionUUID, clientId]);

  const memoizedBank = useMemo(
    () => (bank ? `${bank.compensation_code} - ${bank.name}` : ''),
    [bank]
  );

  useEffect(() => {
    if (data.value?.pixPayment.creditParty.bank) {
      getBankBy('ispb', data.value?.pixPayment.creditParty.bank).then(
        (response) => {
          setBank(response.data.bank);
        }
      );
    }
  }, [data.value?.pixPayment.creditParty.bank]);

  useEffect(() => {
    const path = window.location.pathname.split('/');
    const transactionUUID = path[path.length - 1];

    setTransactionUUID(transactionUUID);
  }, []);

  useEffect(() => {
    if (transactionUUID) {
      requestData();
    }
  }, [transactionUUID, requestData]);

  return (
    <S.Container>
      <S.BreadcrumbStyle />
      <PageView
        title="Pix saque"
        backLink="/"
        header={
          <>
            <S.Bar>
              <TopBar
                id="TopBar-playground"
                variants="default"
                leftIcon={isMobile ? <ArrowLeftOutline size={24} /> : null}
                title="Pix saque"
              />
            </S.Bar>
          </>
        }
      >
        {data.loading ? (
          'Carregando...'
        ) : (
          <>
            <S.RefundStatusTag
              type={accountStatementStatus(data.value?.operationalStatus)}
              setIconOff
            >
              {data.value?.operationalStatusLabel}
            </S.RefundStatusTag>
            <S.WrapperResumeValues>
              <div className="payment-data">
                <S.ResumeValuesSection>
                  <S.ResumeValuesItem>
                    <p>Valor do saque</p>
                    <p>
                      <b>{formatPrice(data.value?.pixPayment.value)}</b>
                    </p>
                  </S.ResumeValuesItem>
                  <S.ResumeValuesItem>
                    <p>Data da transferência</p>
                    <p>{formatDate(data.value?.pixPayment.createdAt)}</p>
                  </S.ResumeValuesItem>
                  {!isEmpty(data.value?.pixPayment.description) && (
                    <S.ResumeValuesItem>
                      <p>Mensagem</p>
                      <p>{data.value?.pixPayment.description}</p>
                    </S.ResumeValuesItem>
                  )}
                </S.ResumeValuesSection>
                <S.ResumeValuesSection>
                  <S.ResumeValuesItem>
                    <p>Para</p>
                    <p>{data.value?.pixPayment.creditParty.name}</p>
                  </S.ResumeValuesItem>
                  <S.ResumeValuesItem>
                    <p>CPF ou CNPJ</p>
                    <p>
                      {normalizeCpfOrCnpj(
                        data.value?.pixPayment.creditParty.taxId
                      )}
                    </p>
                  </S.ResumeValuesItem>
                  <S.ResumeValuesItem>
                    <p>Instituição</p>
                    <p>{memoizedBank}</p>
                  </S.ResumeValuesItem>
                </S.ResumeValuesSection>
                <S.ResumeValuesSection>
                  <S.ResumeValuesItem>
                    <p>Código da operação</p>
                    <p>{data.value?.uuid}</p>
                  </S.ResumeValuesItem>
                  <S.ResumeValuesItem>
                    <p>Código de autenticação</p>
                    <p>{data.value?.pixPayment.e2EId}</p>
                  </S.ResumeValuesItem>
                </S.ResumeValuesSection>
              </div>
            </S.WrapperResumeValues>
            {(parseFloat(data.value?.advancesRateValue) > 0 ||
              parseFloat(data.value?.withdrawalRateValue) > 0 ||
              parseFloat(data.value?.bankTransactionRateValue) > 0) && (
              <S.PaymentCostsContainer>
                <S.PaymentCostsHeader>Custos do pagamento</S.PaymentCostsHeader>
                <S.Table>
                  <tbody>
                    {parseFloat(data.value?.bankTransactionRateValue) > 0 && (
                      <S.TableRow>
                        <td>
                          <p>Tarifas</p>
                        </td>
                        <td>
                          <p>
                            {normalizeMoney(
                              data.value?.bankTransactionRateValue
                            )}
                          </p>
                        </td>
                      </S.TableRow>
                    )}
                    {parseFloat(data.value?.withdrawalRateValue) > 0 && (
                      <S.TableRow>
                        <td>
                          <p>Repasse</p>
                        </td>
                        <td>
                          <p>
                            {normalizeMoney(data.value?.withdrawalRateValue)}
                          </p>
                        </td>
                      </S.TableRow>
                    )}
                    {parseFloat(data.value?.advancesRateValue) > 0 && (
                      <S.TableRow>
                        <td>
                          <p>Taxa de antecipação</p>
                        </td>
                        <td>
                          <p>{normalizeMoney(data.value?.advancesRateValue)}</p>
                        </td>
                        <td style={{ verticalAlign: 'middle' }}>
                          <S.LinkButton
                            variant="text"
                            onClick={() => openDrawer(data.value?.uuid)}
                            data-track="DetalhesPix_Link_AntecipacaoPontual"
                          >
                            Ver detalhes
                          </S.LinkButton>
                        </td>
                      </S.TableRow>
                    )}
                  </tbody>
                </S.Table>
              </S.PaymentCostsContainer>
            )}
            {data.value?.bankTransactionProcessed && (
              <S.WrapperButtons>
                <Button
                  size="md"
                  variant="primary"
                  onClick={() =>
                    window.open(
                      `/lojas/${clientId}/transacoes/${data.value?.uuid}/recibo`,
                      '_blank'
                    )
                  }
                >
                  Baixar comprovante
                </Button>
              </S.WrapperButtons>
            )}
          </>
        )}
      </PageView>
    </S.Container>
  );
};

export default Withdrawal;
