import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@useblu/ocean-react';
import cx from 'classnames';

import { CheckboxContainer, AcceptTermsContainer } from '../modal.styles';

const AcceptTerms = ({
  isAccepted,
  onChange,
  required,
  link,
  className,
  isLAGWithFile
}) => (
  <AcceptTermsContainer className={`ocean-iso ${className}`}>
    <CheckboxContainer className={cx({ required })}>
      <Checkbox
        id="aceep_terms"
        data-testid="aceep_terms"
        checked={isAccepted}
        onChange={onChange}
        label={
          <>
            Aceito os{' '}
            <a href={link} target="blank">
              termos de serviço.
            </a>
          </>
        }
        role="checkbox"
        disabled={isLAGWithFile}
      />
      <span className="error" aria-label="Você precisa aceitar os termos.">
        Você precisa aceitar os termos.
      </span>
    </CheckboxContainer>
  </AcceptTermsContainer>
);

AcceptTerms.propTypes = {
  onChange: PropTypes.func.isRequired,
  isAccepted: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  isLAGWithFile: PropTypes.bool
};

AcceptTerms.defaultProps = {
  className: '',
  isLAGWithFile: false
};

export default AcceptTerms;
