import _ from 'lodash';
import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  getEconomicGroupHistoriesRequest: ['clientUuid', 'page'],
  getEconomicGroupHistoriesSuccess: ['data'],
  getEconomicGroupHistoriesFailure: null
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  error: false,
  loading: false,
  economicGroupHistories: []
};

export const economicGroupHistoriesRequest = (state) => ({
  ...state,
  loading: true
});

export const economicGroupHistoriesSuccess = (state, action) => {
  let economicGroupHistoriesAux = [];

  if (_.isEmpty(state.economicGroupHistories)) {
    economicGroupHistoriesAux = action.data.entries;
  } else {
    economicGroupHistoriesAux = state.economicGroupHistories;
    economicGroupHistoriesAux = economicGroupHistoriesAux.concat(
      action.data.entries
    );
  }

  return {
    ...state,
    loading: false,
    economicGroupHistories: economicGroupHistoriesAux,
    nextPage: action.data.nextPage
  };
};

export const economicGroupHistoriesFailure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ECONOMIC_GROUP_HISTORIES_REQUEST]: economicGroupHistoriesRequest,
  [Types.GET_ECONOMIC_GROUP_HISTORIES_SUCCESS]: economicGroupHistoriesSuccess,
  [Types.GET_ECONOMIC_GROUP_HISTORIES_FAILURE]: economicGroupHistoriesFailure
});
