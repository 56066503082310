import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { Row, Col, Button, Progress } from '@useblu/ocean-react';
import { bool, mixed, object, setLocale, string } from 'yup';
import humps from 'lodash-humps';
import moment from 'moment';

import actions from 'store/ducks/rpaRegisterForm/actions';

/* eslint-disable no-nested-ternary */
import Success from 'components/RPARegisterForm/components/Success';

import {
  ButtonRow,
  ButtonsDivider,
  StepContainer,
  CustomButton,
  MobileHeader
} from 'components/RPARegisterForm/RPARegisterForm.styles.js';
import {
  fetchRpaRegisterDataSelector,
  rpaRegisterFormRequestLoadingSelector
} from 'store/ducks/rpaRegisterForm/selectors';
import ValidatePIS from 'validations/ValidatePis';
import { invalidCepMsg } from 'hooks/useZipCode';
import PersonalDataStep from './components/PersonalDataStep';
import DocumentsStep from './components/DocumentsStep';
import AddressStep from './components/AddressStep';
import arrowLeft from './assets/arrow-left-outline.svg';

const reqMsg = {
  name: 'Insira um nome e sobrenome.',
  date: 'A data informada não é válida.',
  pis: 'O Número do PIS informado não é válido.',
  idNumber: 'O Documento informado não é válido.',
  idEmitter: 'O Órgão emissor informado não é válido.',
  cep: invalidCepMsg
};

const BIRTHDAY_REGEX =
  /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

const NAME_REGEX = /^\s*[\S]+(\s[\S]+)+\s*$/;

setLocale({
  mixed: {
    required: 'Este campo não pode ficar em branco.'
  }
});

const RPARegisterFormScreen = () => {
  const dispatch = useDispatch();

  const loading = useSelector(rpaRegisterFormRequestLoadingSelector);
  const { personalData, documents, address } = useSelector(
    fetchRpaRegisterDataSelector
  );

  const [step, setStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [childrenArrLength, setChildrenArrayLength] = useState(0);

  useEffect(() => {
    setIsLastStep(step === childrenArrLength - 1);
  }, [step, childrenArrLength]);

  const submitCallback =
    (helpers) =>
    ({ isError, statusCode, errorMsg }) => {
      if (isLastStep && !isError) {
        return setCompleted(true);
      }

      if (!isError) {
        helpers.setTouched({});
        return setStep((s) => s + 1);
      }

      if (isError && statusCode === 422) {
        const errors = (errorMsg?.errors || []).reduce(
          (prev, curr) => ({
            ...prev,
            [curr.type]: curr.messages[0]
          }),
          {}
        );
        helpers.setErrors(humps({ ...errors }));
      }
    };

  const handleSubmit = async (values, helpers) => {
    await dispatch(
      actions.createRpaRegisterFormRequest(values, submitCallback(helpers))
    );
  };

  return (
    <>
      <MobileHeader completed={completed}>
        {step > 0 ? (
          <button type="button" onClick={() => setStep((s) => s - 1)}>
            <img src={arrowLeft} alt="Voltar" />
          </button>
        ) : null}
        Completar dados
      </MobileHeader>
      <StepContainer>
        <Row>
          <Col xs="12">
            <FormikStepper
              initialValues={{
                email: personalData?.email || '',
                rpaCpf: personalData?.cpfCnpj || '',
                name: personalData?.name || '',
                birthday: personalData?.birthday || '',
                motherName: personalData?.motherName || '',
                fatherName: personalData?.fatherName || '',
                noFatherName: personalData?.noFatherName,
                pisNumber: documents?.pisNumber || '',
                personalIdNumber: documents?.personalIdNumber || '',
                personalIdNumberEmitter:
                  documents?.personalIdNumberEmitter || '',
                addressZipcode: address?.addressZipcode || '',
                addressStreet: address?.addressStreet || '',
                addressNumber: address?.addressNumber || '',
                addressComplement: address?.addressComplement || '',
                addressNeighborhood: address?.addressNeighborhood || '',
                addressCity: address?.addressCity || '',
                state: address?.state || ''
              }}
              step={step}
              setBackStep={() => setStep((s) => s - 1)}
              completed={completed}
              isLastStep={isLastStep}
              setChildrenArrayLength={setChildrenArrayLength}
              loading={loading}
              enableReinitialize
              onSubmit={async (values, helpers) => {
                await handleSubmit(values, helpers);
              }}
            >
              <FormikStep
                label="Para começar, informe os dados pessoais a seguir."
                verifyAttributesOnApi={[
                  'rpa_cpf',
                  'name',
                  'birthday',
                  'mother_name',
                  'father_name'
                ]}
                validationSchema={object({
                  email: string().notRequired(),
                  rpaCpf: string().notRequired(),
                  name: string().matches(NAME_REGEX, reqMsg.name).required(),
                  birthday: string()
                    .required()
                    .matches(BIRTHDAY_REGEX, reqMsg.date)
                    .test({
                      name: 'birthday',
                      message: reqMsg.date,
                      test: (value) => {
                        const ofAge =
                          moment().diff(moment(value, 'DD/MM/YYYY'), 'years') >
                          17;
                        const possibleHumanAge =
                          moment().diff(moment(value, 'DD/MM/YYYY'), 'years') <=
                          130;
                        const isValid = ofAge && possibleHumanAge;
                        return isValid;
                      }
                    }),
                  motherName: string()
                    .matches(NAME_REGEX, reqMsg.name)
                    .required(),
                  fatherName: mixed().when('noFatherName', {
                    is: false,
                    then: string().matches(NAME_REGEX, reqMsg.name).required(),
                    otherwise: string().notRequired()
                  }),
                  noFatherName: bool()
                })}
              >
                <PersonalDataStep
                  cpf={personalData?.cpfCnpj}
                  email={personalData?.email}
                />
              </FormikStep>
              <FormikStep
                label="Agora preencha os seguintes documentos."
                verifyAttributesOnApi={[
                  'pis_number',
                  'personal_id_number',
                  'personal_id_number_emitter'
                ]}
                validationSchema={object({
                  pisNumber: string()
                    .required()
                    .test('pisNumber', reqMsg.pis, (value) =>
                      ValidatePIS(value)
                    ),
                  personalIdNumber: string().required(),
                  personalIdNumberEmitter: string().required()
                })}
              >
                <DocumentsStep />
              </FormikStep>
              <FormikStep
                label="Para finalizar, informe o seu endereço."
                verifyAttributesOnApi={[
                  'address_zipcode',
                  'address_street',
                  'address_number',
                  'address_complement',
                  'address_neighborhood',
                  'address_city',
                  'state'
                ]}
                validationSchema={object({
                  addressZipcode: string()
                    .required()
                    .test(
                      'addressZipcode',
                      reqMsg.cep,
                      (value) => value?.replace(/\D/g, '').length === 8
                    ),
                  addressStreet: string().required(),
                  addressNumber: string().required(),
                  addressComplement: string(),
                  addressNeighborhood: string().required(),
                  addressCity: string().required(),
                  state: string().required()
                })}
              >
                <AddressStep />
              </FormikStep>
            </FormikStepper>
          </Col>
        </Row>
      </StepContainer>
    </>
  );
};

// eslint-disable-next-line react/prop-types
export function FormikStep({ children }) {
  return <StepContainer>{children}</StepContainer>;
}

// eslint-disable-next-line react/prop-types
export function FormikStepper({
  children,
  setChildrenArrayLength,
  setBackStep,
  step,
  completed,
  isLastStep,
  loading,
  ...props
}) {
  const childrenArray = React.Children.toArray(children);
  const currentChild = childrenArray[step];

  useEffect(() => {
    setChildrenArrayLength(childrenArray.length);
  }, []);

  return completed ? (
    <>
      <Success />
    </>
  ) : (
    <Formik
      {...props}
      validationSchema={currentChild?.props?.validationSchema}
      onSubmit={async (values, helpers) => {
        const data = {
          ...values,
          verifyAttributes: [...currentChild.props?.verifyAttributesOnApi]
        };
        props.onSubmit(data, helpers);
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          {/* access node currentChild.props if you want */}
          {currentChild}
          <StepContainer>
            <ButtonsDivider />
            <ButtonRow>
              {step > 0 ? (
                <Button
                  type="button"
                  disabled={isSubmitting || loading}
                  onClick={setBackStep}
                  variant="secondary"
                  className="back"
                >
                  Voltar
                </Button>
              ) : null}
              <CustomButton disabled={isSubmitting || loading} type="submit">
                {isSubmitting || loading ? (
                  <Progress />
                ) : isLastStep ? (
                  'Finalizar'
                ) : (
                  'Continuar'
                )}
              </CustomButton>
            </ButtonRow>
          </StepContainer>
        </Form>
      )}
    </Formik>
  );
}

export default RPARegisterFormScreen;
