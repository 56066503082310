import styled from 'styled-components';

import {
  colorBrandPrimaryDown,
  colorInterfaceDarkDown,
  colorInterfaceLightUp
} from '@useblu/ocean-tokens/web/tokens';

export const Actions = styled.p`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 8px;
`;

export const ModalContent = styled.div`
  width: 680px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 11px 8px 11px;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 24px;

    ${Actions} {
      align-items: center;
      justify-content: center;

      .ods-btn {
        width: 100%;
      }
    }
  }
`;

export const Title = styled.h1`
  width: 100%;
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 110%;
`;

export const Text = styled.p`
  color: ${colorInterfaceDarkDown};
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 110%;
  margin: 0 0 0 16px;
`;

export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;
`;

export const Benefit = styled.div`
  display: flex;
  align-items: center;
  height: 52px;
  margin-bottom: 16px;
`;

export const IconBox = styled.div`
  background-color: ${colorInterfaceLightUp};
  border-radius: 100%;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colorBrandPrimaryDown};
`;
