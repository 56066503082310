import axios from 'axios';

export function signIn(params) {
  return axios.post(
    `/api/sessions`,
    {
      user: params,
    },
    {
      headers: {
        Accept: 'version=1, application/json',
        'Content-Type': 'application/json',
      },
    }
  );
}

export default signIn;
