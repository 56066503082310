/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import humps from 'lodash-humps';
import PropTypes from 'prop-types';
import { useAsyncFn } from 'react-use';
import { Drawer } from '@useblu/ocean-react';
import getCompaniesStoreApi from 'api/client-selector';
import * as S from './styles';
import { getDetailsAnticipation } from './services/advancementDetailsDrawerApi';
import useAdvancementDetailsDrawer from './hooks/useAdvancementDetailsDrawer';
import AdvancementDetailsDrawerError from './components/AdvancementDetailsDrawerError';
import AdvancementDetailsDrawerContent from './components/AdvancementDetailsDrawerContainer';

const AdvancementDetailsDrawer = ({ currentStoreId }) => {
  const { isDrawerOpen, uuid, closeDrawer, isAccounStatement, isURDetails } =
    useAdvancementDetailsDrawer();

  const [detailsAnticipationData, callDetailsAnticipation] = useAsyncFn(
    getDetailsAnticipation
  );

  const [storesState, fetchStoreRequest] = useAsyncFn(async () => {
    const response = await getCompaniesStoreApi(currentStoreId);

    return humps(response.data.data.currentUser.clientsSelector);
  });

  useEffect(() => {
    if (isDrawerOpen && uuid) {
      callDetailsAnticipation(currentStoreId, uuid);
    }
  }, [isDrawerOpen, uuid]);

  useEffect(() => {
    if (
      detailsAnticipationData?.value?.outflow?.belongsToEconomicGroup &&
      !detailsAnticipationData?.loading
    ) {
      fetchStoreRequest(currentStoreId);
    }
  }, [detailsAnticipationData]);

  return (
    <Drawer
      open={isDrawerOpen}
      onDrawerClose={closeDrawer}
      overlayClose={closeDrawer}
    >
      <S.DrawerContent>
        {detailsAnticipationData.value?.error ? (
          <AdvancementDetailsDrawerError
            isAccounStatement={isAccounStatement}
            currentStoreId={currentStoreId}
            uuid={uuid}
          />
        ) : (
          <AdvancementDetailsDrawerContent
            uuid={uuid}
            isURDetails={isURDetails}
            storesState={storesState}
            currentStoreId={currentStoreId}
            isAccounStatement={isAccounStatement}
            detailsAnticipationData={detailsAnticipationData}
          />
        )}
      </S.DrawerContent>
    </Drawer>
  );
};

AdvancementDetailsDrawer.propTypes = {
  currentStoreId: PropTypes.string.isRequired,
};

export default AdvancementDetailsDrawer;
