import React from 'react';
import { WarningContainer } from 'components/Devise/devise.styles';
import { Button, Typography } from '@useblu/ocean-react';
import { useMedia } from 'react-use';
import DeviseScreenLayout from '../DeviseScreenLayout';
import qrCode from '../../images/blu-btoken-setup.png';
import ilustraMobile from '../../images/ilustra-btoken-validation.svg';

const ForceCafStatusWarning = () => {
  const isMobile = useMedia('(max-width: 768px)');
  return (
    <DeviseScreenLayout className="adjust-logo">
      <WarningContainer $withCaptionOnMobile>
        <img src={isMobile ? ilustraMobile : qrCode} alt="QR Code" />
        <Typography variant="heading2">
          Valide o seu usuário para continuar acessando a sua Conta Digital Blu
        </Typography>
        <Typography variant="paragraph">
          Aponte a sua câmera para o QR code, baixe o aplicativo e envie os seus
          documentos. É rápido e simples de fazer.
        </Typography>
        {isMobile && (
          <Button
            variant="primary"
            onClick={() =>
              window.open('https://appblu.app.link/btoken', '_blank')
            }
          >
            Baixar aplicativo
          </Button>
        )}
      </WarningContainer>
    </DeviseScreenLayout>
  );
};

export default ForceCafStatusWarning;
