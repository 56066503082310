import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { normalizeValue } from 'normalizers';
import { useSelector, useDispatch } from 'react-redux';

import UIStateCreators from 'store/ducks/UIState';

import {
  Balance,
  Title,
  Value,
  ClientBalanceNavStyled,
  Content,
  HideBalance,
} from './ClientBalance.styles';
import DropdownMenu from '../../shared/DropdownMenu';
import ToggleViewIcon from '../../shared/ToggleViewIcon';
import { EqualsSign, PlusSign } from './Signs';

const styleProps = {
  width: '352px',
  position: 'left',
  action: 'hover',
};

const ClientBalance = ({ total, future, current }) => {
  const dispatch = useDispatch();

  const { privateMode } = useSelector(
    (state) => state.UIState || { privateMode: true }
  );

  const toggleBalanceVisible = () => {
    dispatch(UIStateCreators.tooglePrivateMode());
  };

  const classNamesTotalValue = useMemo(
    () => classNames({ highlighted: true, isNegative: total * 1 < 0 }),
    [total]
  );

  return (
    <ClientBalanceNavStyled>
      <HideBalance>
        <button
          type="button"
          data-testid="toggle-balance-dkt"
          onClick={toggleBalanceVisible}
        >
          <ToggleViewIcon visible={!privateMode} />
        </button>
      </HideBalance>
      <DropdownMenu displayClass="saldo-total" {...styleProps}>
        <Balance>
          <Title className="highlighted">Seu saldo disponível</Title>
          <Value className={classNamesTotalValue}>
            {!privateMode ? normalizeValue(total) : 'R$ ••••••'}
          </Value>
        </Balance>
        <Content>
          <h1>Saldo disponível</h1>
          <p>
            Representa todo o seu dinheiro dentro da Blu, composto pela soma do
            Saldo atual + Agenda Blu + Agenda outras maquininhas.
          </p>
        </Content>
      </DropdownMenu>
      <EqualsSign />
      <DropdownMenu displayClass="saldo-atual" {...styleProps}>
        <Balance>
          <Title>Saldo atual</Title>
          <Value>{!privateMode ? normalizeValue(current) : 'R$ ••••••'}</Value>
        </Balance>
        <Content>
          <h1>Saldo atual</h1>
          <p>
            Representa o que foi recebido até a data atual e está disponível
            para uso imediato.
          </p>
        </Content>
      </DropdownMenu>
      <PlusSign />
      <DropdownMenu
        displayClass="saldo-agenda"
        data-testid="saldo-agenda"
        {...styleProps}
      >
        <Balance>
          <Title>Agenda Blu</Title>
          <Value>{!privateMode ? normalizeValue(future) : 'R$ ••••••'}</Value>
        </Balance>
        <Content>
          <h1>
            Agenda Blu <small>(antigo ”Saldo a receber”)</small>
          </h1>
          <p>
            Representa o somatório dos valores das vendas realizadas via Blu que
            ainda não atingiram a data de recebimento.
          </p>
        </Content>
      </DropdownMenu>

      <PlusSign />
    </ClientBalanceNavStyled>
  );
};

ClientBalance.propTypes = {
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  future: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  externalWallet: PropTypes.object,
};

ClientBalance.defaultProps = {
  externalWallet: {},
};

export default ClientBalance;
