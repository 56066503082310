import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { formatPrice } from 'components/AccountStatement/helpers/currency';
import { map, isEmpty } from 'lodash';
import { maskCpfOrCnpj } from 'utils/masks';
import { normalizeMoney } from '@useblu/utils';
import { colorInterfaceDarkUp } from '@useblu/ocean-tokens/web/tokens';

import { formatDate, normalizeCurrency } from 'normalizers';
import {
  ArrowLeftOutline,
  ReceiptRefundOutline,
} from '@useblu/ocean-icons-react';
import {
  TopBar,
  Grid,
  Button,
  Progress,
  List,
  SubHeader,
  TransactionListItem,
} from '@useblu/ocean-react';

import useAdvancementDetailsDrawer from 'components/Shared/AdvancementDetailsDrawer/hooks/useAdvancementDetailsDrawer';
import * as S from './styles';
import useFindBank from './hooks/useFindBank';
import accountStatementStatus from '../../DARFScreen/accountStatementStatus';
import CancelTransactionModal from './CancelTransactionModal';
import usePixPayment from './hooks/usePixPayment';

const PixPayment = ({
  value,
  pixPayment,
  bankInfo,
  transactionUuid,
  title,
  clientUUID,
  status,
  dueDateOn,
  statusName,
  withdrawalRateValue,
  rateValue,
  canCancel,
  hasAdvancementRateValue,
  proofEnabled,
  proofPath,
  cancelUrl,
  refunds,
  observation,
  canceled,
  cancelObj,
  advancementRateValue,
  canConfirmBtoken,
  confirmWithoutBtoken,
  btokenConfirmationLink,
}) => {
  const isMobile = useMedia('(max-width: 768px)');

  const {
    loadingCancel,
    cancelModalOpen,
    executeCancel,
    openCancelModal,
    closeCancelModal,
  } = usePixPayment(cancelUrl, clientUUID);

  const { openDrawer } = useAdvancementDetailsDrawer();

  const { pixPaymentApi, loadingScreen, bank } = useFindBank(
    clientUUID,
    transactionUuid,
    pixPayment.key
  );

  const accountTypes = {
    CACC: 'Conta Corrente',
    SVGS: 'Conta Poupança',
    SLRY: 'Conta Salário',
    TRAN: 'Conta de Pagamento',
  };

  const {
    office,
    number,
    owner_name: ownerName,
    cpf_cnpj: cpfCnpj,
    savings_account_operation: savingsAccountOperation,
  } = pixPayment;

  const { compensation_code: compensationCode, name } = bankInfo;

  const executeBtokenConfirmation = () => {
    window.location.href = btokenConfirmationLink;
  };

  const maskedCPFOrCNPJ = maskCpfOrCnpj(cpfCnpj);

  const cpfOrCnpj =
    maskedCPFOrCNPJ.length === 14
      ? `***${maskedCPFOrCNPJ.substring(3, 12)}**`
      : `${maskedCPFOrCNPJ}`;

  const handleRedirectDashboard = () => {
    window.location.href = '/';
  };

  const openModalBtokenConfirmation = () => {
    const event = new CustomEvent('feature-modal-open', {
      detail: { feature: 'BTokenForceActivation' },
    });
    document.dispatchEvent(event);
  };

  return (
    <S.PixPaymentContainer className="ocean-iso">
      <S.GridContainer fluid="md">
        <Grid.Row>
          <Grid.Col
            md={{
              span: '10',
              offset: '1',
            }}
            lg={{
              span: '8',
              offset: '2',
            }}
            xl={{
              span: '8',
              offset: '2',
            }}
          >
            <S.BreadcrumbStyle />
            <S.Bar>
              <TopBar
                id="TopBar-playground"
                variants="default"
                title={title}
                leftIcon={
                  isMobile ? (
                    <ArrowLeftOutline data-testid="back-button" />
                  ) : null
                }
                onLeftAction={() => {
                  handleRedirectDashboard();
                }}
              />
            </S.Bar>

            {loadingScreen ? (
              <div>
                <Progress size="md" />
              </div>
            ) : (
              <>
                <S.TagStatusTransaction
                  className={`statement-detail--bg-${status}`}
                  type={accountStatementStatus(status)}
                  setIconOff
                >
                  {statusName}
                </S.TagStatusTransaction>
                <S.ContentContainer>
                  <S.TableDataTransaction>
                    <S.Table>
                      <tbody>
                        <S.ValueTableRow>
                          <td>
                            <p>Valor</p>
                          </td>
                          <td>
                            <p>{formatPrice(value)}</p>
                          </td>
                        </S.ValueTableRow>
                        <S.DateTableRow
                          className={isEmpty(observation) ? 'session' : ''}
                        >
                          <td>
                            <p>Data da transferência</p>
                          </td>
                          <td>
                            <p>{formatDate(dueDateOn)}</p>
                          </td>
                        </S.DateTableRow>
                        {!isEmpty(observation) && (
                          <S.DateTableRow className="session">
                            <td>
                              <p>Descrição</p>
                            </td>
                            <td>
                              <p>{observation}</p>
                            </td>
                          </S.DateTableRow>
                        )}
                        <S.DateTableRow>
                          <td>
                            <p>Para</p>
                          </td>
                          <td>
                            <p>{ownerName || pixPayment.name}</p>
                          </td>
                        </S.DateTableRow>
                        <S.DateTableRow>
                          <td>
                            <p>
                              {maskedCPFOrCNPJ.length === 14 ? 'CPF' : 'CNPJ'}
                            </p>
                          </td>
                          <td>
                            <p>{cpfOrCnpj}</p>
                          </td>
                        </S.DateTableRow>
                        {pixPayment.key && (
                          <>
                            {!isEmpty(pixPaymentApi) && (
                              <>
                                <S.DateTableRow>
                                  <td>
                                    <p>Instituição</p>
                                  </td>
                                  <td>
                                    <p>{bank.name}</p>
                                  </td>
                                </S.DateTableRow>
                                <S.DateTableRow>
                                  <td>
                                    <p>Tipo</p>
                                  </td>
                                  <td>
                                    <p>
                                      {
                                        accountTypes[
                                          pixPaymentApi.credit_party.accountType
                                        ]
                                      }
                                    </p>
                                  </td>
                                </S.DateTableRow>
                                <S.DateTableRow className="session">
                                  <td>
                                    <p>Chave Pix</p>
                                  </td>
                                  <td>
                                    <p>{pixPayment.key}</p>
                                  </td>
                                </S.DateTableRow>
                              </>
                            )}
                          </>
                        )}
                        {!isEmpty(bankInfo) && (
                          <>
                            <S.DateTableRow>
                              <td>
                                <p>Instituição</p>
                              </td>
                              <td>
                                <p>
                                  {compensationCode} - {name}
                                </p>
                              </td>
                            </S.DateTableRow>
                            <S.DateTableRow>
                              <td>
                                <p>Agência</p>
                              </td>
                              <td>
                                <p>{office}</p>
                              </td>
                            </S.DateTableRow>
                            <S.DateTableRow>
                              <td>
                                <p>Conta</p>
                              </td>
                              <td>
                                <p>{number}</p>
                              </td>
                            </S.DateTableRow>
                            <S.DateTableRow className="session">
                              <td>
                                <p>Tipo</p>
                              </td>
                              <td>
                                <p>
                                  {savingsAccountOperation
                                    ? 'Conta Poupança'
                                    : 'Conta Corrente'}
                                </p>
                              </td>
                            </S.DateTableRow>
                          </>
                        )}
                        {canceled && (
                          <>
                            <S.DateTableRow>
                              <td>
                                <p>
                                  {cancelObj.canceledByClient
                                    ? 'Cancelado por'
                                    : 'Motivo do cancelamento'}
                                </p>
                              </td>
                              <td>
                                <p>{cancelObj.nameOrMessage}</p>
                              </td>
                            </S.DateTableRow>
                            <S.DateTableRow className="session">
                              <td>
                                <p>Data do cancelamento</p>
                              </td>
                              <td>
                                <p>{formatDate(cancelObj.cancelationDate)}</p>
                              </td>
                            </S.DateTableRow>
                          </>
                        )}
                        <S.DateTableRow>
                          <td>
                            <p>Código da operação</p>
                          </td>
                          <td>
                            <p>{transactionUuid}</p>
                          </td>
                        </S.DateTableRow>
                        {pixPaymentApi && (
                          <S.CodeAuthenticationTableRow>
                            <td>
                              <p>Código de autenticação</p>
                            </td>
                            <td>
                              <p>{pixPaymentApi.e2e_id}</p>
                            </td>
                          </S.CodeAuthenticationTableRow>
                        )}
                      </tbody>
                    </S.Table>
                  </S.TableDataTransaction>
                  {(rateValue > 0 ||
                    withdrawalRateValue > 0 ||
                    hasAdvancementRateValue) && (
                    <S.PaymentCostsContainer>
                      <S.PaymentCostsHeader>
                        Custos do pagamento
                      </S.PaymentCostsHeader>
                      <S.Table>
                        <tbody>
                          {rateValue > 0 && (
                            <S.TableRow>
                              <td>
                                <p>Tarifas</p>
                              </td>
                              <td>
                                <p>{normalizeMoney(rateValue)}</p>
                              </td>
                            </S.TableRow>
                          )}
                          {withdrawalRateValue > 0 && (
                            <S.TableRow>
                              <td>
                                <p>Repasse</p>
                              </td>
                              <td>
                                <p>{normalizeMoney(withdrawalRateValue)}</p>
                              </td>
                            </S.TableRow>
                          )}
                          {hasAdvancementRateValue && (
                            <S.TableRow>
                              <td>
                                <p>Taxa de antecipação</p>
                              </td>
                              <td>
                                <p>{normalizeMoney(advancementRateValue)}</p>
                              </td>
                              <td style={{ verticalAlign: 'middle' }}>
                                <S.LinkButton
                                  variant="text"
                                  onClick={() => openDrawer(transactionUuid)}
                                  data-track="DetalhesPix_Link_AntecipacaoPontual"
                                >
                                  Ver detalhes
                                </S.LinkButton>
                              </td>
                            </S.TableRow>
                          )}
                        </tbody>
                      </S.Table>
                    </S.PaymentCostsContainer>
                  )}
                  <br />
                  {map(refunds, (item) => (
                    <List>
                      <SubHeader>Devoluções</SubHeader>
                      <TransactionListItem
                        icon={
                          <ReceiptRefundOutline
                            style={{ color: colorInterfaceDarkUp }}
                          />
                        }
                        onClick={() => {
                          window.location.href = `/lojas/${clientUUID}/transacoes/${item.uuid}`;
                        }}
                        level2={ownerName}
                        value={normalizeCurrency(parseFloat(item.gross_value))}
                        time={formatDate(item.created_at)}
                        positive
                      >
                        Devolução Pix
                      </TransactionListItem>
                    </List>
                  ))}
                </S.ContentContainer>
                <S.DownloadVoucherContainer>
                  {canCancel && (
                    <Button
                      variant="textCritical"
                      onClick={openCancelModal}
                      loading={loadingCancel}
                    >
                      Cancelar pagamento
                    </Button>
                  )}
                  {(canConfirmBtoken || confirmWithoutBtoken) && (
                    <Button
                      variant="primary"
                      onClick={
                        confirmWithoutBtoken
                          ? openModalBtokenConfirmation
                          : executeBtokenConfirmation
                      }
                    >
                      Confirmar
                    </Button>
                  )}
                  {proofEnabled && (
                    <Button
                      variant="primary"
                      onClick={() => window.open(proofPath, '_blank')}
                    >
                      Baixar comprovante
                    </Button>
                  )}
                </S.DownloadVoucherContainer>
              </>
            )}
          </Grid.Col>
        </Grid.Row>
      </S.GridContainer>
      <CancelTransactionModal
        isOpen={cancelModalOpen}
        onClose={closeCancelModal}
        onCancel={executeCancel}
        loading={loadingCancel}
      />
    </S.PixPaymentContainer>
  );
};

PixPayment.propTypes = {
  value: PropTypes.string.isRequired,
  pixPayment: PropTypes.object.isRequired,
  bankInfo: PropTypes.object,
  transactionUuid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  clientUUID: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  dueDateOn: PropTypes.string.isRequired,
  statusName: PropTypes.string.isRequired,
  withdrawalRateValue: PropTypes.string.isRequired,
  rateValue: PropTypes.string.isRequired,
  canCancel: PropTypes.bool.isRequired,
  hasAdvancementRateValue: PropTypes.bool.isRequired,
  proofEnabled: PropTypes.bool.isRequired,
  proofPath: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  refunds: PropTypes.array,
  observation: PropTypes.string,
  canceled: PropTypes.bool.isRequired,
  cancelObj: PropTypes.object.isRequired,
  advancementRateValue: PropTypes.number,
  btokenConfirmationLink: PropTypes.string.isRequired,
  canConfirmBtoken: PropTypes.bool.isRequired,
  confirmWithoutBtoken: PropTypes.bool.isRequired,
};

PixPayment.defaultProps = {
  refunds: [],
  observation: '',
  bankInfo: {},
  advancementRateValue: null,
};

export default PixPayment;
