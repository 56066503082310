/* Core */
import React from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { Provider } from 'react-redux';
import store, { history } from 'store';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

/* Components */
import FormWizard from 'components/FirstAccess/FormWizard';
import ClientInfoForm from 'components/FirstAccess/ClientInfoForm';
import UsersUploadForm from 'components/FirstAccess/UsersUploadForm';
import QsaAccountsForm from 'components/FirstAccess/QsaAccountsForm';
import UsersContractsForm from 'components/FirstAccess/UsersContractsForm';
import UsersAccountsForm from 'components/FirstAccess/UsersAccountsForm';

const routes = [
  { path: '/lojas/:uuid/primeiro-acesso' },
  { path: '/lojas/:uuid/primeiro-acesso/informacoes-da-empresa' },
  { path: '/lojas/:uuid/primeiro-acesso/identificacao-do-socio' },
  { path: '/lojas/:uuid/primeiro-acesso/autorizacoes-dos-socios' },
  { path: '/lojas/:uuid/primeiro-acesso/assinatura-de-contrato' },
  { path: '/lojas/:uuid/primeiro-acesso/enviar-contrato' }
];

const FirstAccess = (railsProps) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            render={(props) => (
              <FormWizard {...props.match.params} railsProps={railsProps} />
            )}
          />
        ))}
        <Switch>
          <Route
            exact
            path="/lojas/:uuid/primeiro-acesso"
            render={(props) => (
              <div className="first-access-app flex-container">
                {railsProps.currentClient.need_validation ? (
                  <ClientInfoForm {...props.match.params} />
                ) : (
                  <QsaAccountsForm {...props.match.params} />
                )}
              </div>
            )}
          />
          <Route
            exact
            path="/lojas/:uuid/primeiro-acesso/informacoes-da-empresa"
            render={(props) => (
              <div className="first-access-app flex-container">
                <ClientInfoForm {...props.match.params} />
              </div>
            )}
          />
          <Route
            path="/lojas/:uuid/primeiro-acesso/informacoes-dos-socios"
            render={(props) => (
              <div className="first-access-app flex-container">
                <QsaAccountsForm {...props.match.params} />
              </div>
            )}
          />
          <Route
            path="/lojas/:uuid/primeiro-acesso/autorizacoes-dos-socios"
            render={(props) => (
              <div className="first-access-app flex-container">
                <UsersAccountsForm {...props.match.params} />
              </div>
            )}
          />
          <Route
            path="/lojas/:uuid/primeiro-acesso/assinatura-de-contrato"
            render={(props) => (
              <div className="first-access-app flex-container">
                <UsersContractsForm {...props.match.params} />
              </div>
            )}
          />
          <Route
            exact
            path="/lojas/:uuid/primeiro-acesso/enviar-contrato"
            render={(props) => (
              <div className="first-access-app flex-container">
                <UsersUploadForm {...props.match.params} />
              </div>
            )}
          />
        </Switch>
      </div>
    </ConnectedRouter>
  </Provider>
);

FirstAccess.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({})
  })
};

FirstAccess.defaultProps = {
  match: {
    params: {}
  }
};

export default FirstAccess;
