import React from 'react';
import Loading from 'react-loading';

import { BoxContent } from './DeviceCard.styles';
import DeviceCardLayout from './DeviceCardLayout';

const DeviceCardLoading = () => (
  <DeviceCardLayout isLoading>
    <BoxContent>
      <Loading
        data-testid="loading"
        type="spin"
        color="#0025E0"
        delay={0}
        width={40}
        height={40}
      />
    </BoxContent>
  </DeviceCardLayout>
);

export default DeviceCardLoading;
