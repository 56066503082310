import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Image } from './Styles';
import ModalLayout from './ModalLayout';
import helpImg from './images/help-illustration.svg';

const HelpModal = ({ onClose, isOpen }) => (
  <ModalLayout
    isOpen={isOpen}
    showBack
    title="Sobre o Uso Blu"
    onClose={onClose}
  >
    <Wrapper>
      <Image src={helpImg} alt="Sobre o Uso Blu" />
      <p>
        Essa pontuação representa o quanto este varejista utilizou os serviços
        da Blu realizando vendas e compras (pagas) com fornecedores.
      </p>
      <p>
        Quanto maior a pontuação, que <strong>varia de 5 a 9</strong>, mais este
        varejista se relacionou com a Blu e outros fornecedores, e mais apto ele
        está a obter de você, melhores condições de pagamento, por exemplo o{' '}
        <strong>Boleto Blu</strong>.
      </p>
    </Wrapper>
  </ModalLayout>
);

HelpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default HelpModal;
