import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { normalizeValue } from 'normalizers';
import moment from 'utils/moment.pt-br';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import PaymentBilletInstallments from 'components/PaymentCollectionDetails/containers/PaymentBilletInstallments';
import InstallmentsContainer from 'components/PaymentCollectionDetails/containers/InstallmentsContainer';
import NonOptimizationInstallments from 'components/PaymentCollectionDetails/containers/NonOptimizationInstallments';

const Installments = () => {
  const { kind, optimization, transactions } = useSelector(
    selectors.getDetailsData
  );

  if (
    kind.name === 'payment_collection_blu_billet' ||
    kind.name === 'payment_collection_bank_billet'
  )
    return <PaymentBilletInstallments />;

  if (!isEmpty(transactions)) {
    return (
      <InstallmentsContainer>
        {transactions.map((transaction, i) => (
          <div
            key={transaction.id}
            className="list__item list__item-sp-between statement-detail__installment"
          >
            <div className="list__item-left list__item-left--auto">
              <p>
                <b>
                  {i + 1}ª Parcela{transaction.antecipation && ' - Antecipada'}
                </b>
              </p>
              <p>{moment(transaction.releasedAt, 'YYYY-MM-DD').format('L')}</p>
            </div>

            <div className="list__item-right account-statement__item-value">
              <p>
                <b>{normalizeValue(transaction.netValue)}</b>
              </p>
            </div>
          </div>
        ))}
      </InstallmentsContainer>
    );
  }

  if (optimization) {
    return (
      <InstallmentsContainer>
        <small className="statement-detail__hint list__item-blank">
          Pagamento sem antecipação de recebíveis
        </small>
      </InstallmentsContainer>
    );
  }

  return <NonOptimizationInstallments />;
};

export default Installments;
