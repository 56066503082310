import React, { Fragment, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import PropTypes from 'prop-types';
import Loading from 'react-loading';

const ConfirmOutflowCancelModal = ({
  actions,
  modalTitle,
  modalDescription,
  batchPayment,
  uuid,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionSelect, setActionSelect] = useState();

  const defaultExecuteAction = () => {
    axios
      .get(actionSelect.url)
      .then((response) => {
        const { errors } = response.data;

        if (errors && errors.length > 0) {
          setLoading(false);
          return false;
        }

        window.location.href = `/lojas/${uuid}/dashboard`;
        return true;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const batchPaymentCancel = () => {
    const { paymentId } = batchPayment;
    const query = `
    mutation {
      cancelBatchPayment(clientUuid: "${uuid}", batchPaymentId: "${paymentId}") {
        id
      }
    }
    `;

    axios
      .post('/graphql', { query })
      .then((response) => {
        const { errors } = response.data;

        if (errors && errors.length > 0) {
          setLoading(false);
          return false;
        }

        window.location.href = `/lojas/${uuid}/dashboard`;
        return true;
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const executeAction = () => {
    setLoading(true);

    if (actionSelect.batchPayment) {
      batchPaymentCancel();
    } else {
      defaultExecuteAction();
    }
  };

  const classes = {
    afterOpen: 'modal--open',
    base: 'modal payment-modal',
  };

  const classesType = (type) => {
    switch (type) {
      case 'cancel':
        return 'button button--danger';
      default:
        return 'button button--secundary';
    }
  };

  const modalOpen = (active, action) => {
    setModalIsOpen(active);
    setActionSelect(action);
  };

  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      {actions.map((action) => (
        <a
          onClick={() => modalOpen(true, action)}
          className={classesType(action.type)}
          style={{ width: '100%' }}
        >
          {action.text}
        </a>
      ))}
      <Modal
        isOpen={modalIsOpen}
        className={classes}
        ariaHideApp={false}
        contentLabel="Modal"
        onRequestClose={() => setModalIsOpen(false)}
      >
        {modalTitle && <h2>{modalTitle}</h2>}
        {modalDescription && (
          <p className="payment-modal-description">{modalDescription}</p>
        )}
        <div className="payment-modal-buttons">
          <button
            type="button"
            onClick={executeAction}
            className="button button--danger flex-1"
            disabled={loading}
          >
            {loading ? (
              <div className="u-flex">
                <Loading
                  type="spinningBubbles"
                  color="#343e4f"
                  delay={0}
                  width={17}
                  height={17}
                />
                <span>Cancelando...</span>
              </div>
            ) : (
              'Cancelar pagamento'
            )}
          </button>
          <button
            type="button"
            onClick={() => setModalIsOpen(false)}
            className="button button--secondary flex-1"
          >
            Manter pagamento
          </button>
        </div>
      </Modal>
    </Fragment>
  );
};

ConfirmOutflowCancelModal.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
      batchPayment: PropTypes.bool,
      type: PropTypes.oneOfType('cancel').isRequired,
    })
  ).isRequired,
  batchPayment: PropTypes.exact({
    paymentId: PropTypes.string.isRequired,
    paymentUuid: PropTypes.string.isRequired,
  }),
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  uuid: PropTypes.string.isRequired,
};

ConfirmOutflowCancelModal.defaultProps = {
  modalTitle: '',
  modalDescription: '',
  batchPayment: null,
};

export default ConfirmOutflowCancelModal;
