import React from 'react';
import PropTypes from 'prop-types';

import AcceptTerms from './AcceptTerms';

import {
  Title,
  TitlePage,
  ScrollMobile,
  ModalFooterContainer,
  ModalContent
} from '../modal.styles';
import Actions from './Actions';

const FeatureTerms = ({
  header,
  title,
  link,
  description,
  nextStep,
  prevStep,
  loading,
  hasPrev,
  nextText,
  nextClass,
  prevClass,
  isLAGWithFile
}) => {
  const [isAccepted, updateIfIsAccept] = React.useState(false);
  const [isRequired, updateIfIsRequired] = React.useState(false);

  const onChangeCheckbox = (e) => {
    updateIfIsAccept(e.target.checked);
    if (e.target.checked) {
      updateIfIsRequired(false);
    }
  };

  return (
    <>
      <ScrollMobile className="mobile-scroll-to-error">
        <TitlePage>{header}</TitlePage>
        <Title>{title}</Title>
        <ModalContent>{description}</ModalContent>
        <AcceptTerms
          link={link}
          className="mobile"
          required={isRequired}
          onChange={onChangeCheckbox}
          isAccepted={isAccepted}
          isLAGWithFile={isLAGWithFile}
        />
      </ScrollMobile>
      <ModalFooterContainer>
        <AcceptTerms
          link={link}
          className="desktop"
          required={isRequired}
          onChange={onChangeCheckbox}
          isAccepted={isAccepted}
          isLAGWithFile={isLAGWithFile}
        />
        <Actions
          prevStep={prevStep}
          hasPrev={hasPrev}
          nextStep={() => {
            if (isAccepted) {
              nextStep(isAccepted);
            } else {
              updateIfIsRequired(true);

              const scroller = document.querySelector(
                '.mobile-scroll-to-error'
              );
              if (document && scroller && scroller.scrollBy) {
                scroller.scrollBy(0, 100000);
              }
            }
          }}
          nextClass={nextClass}
          nextText={nextText}
          prevClass={prevClass}
          loading={loading}
        />
      </ModalFooterContainer>
    </>
  );
};

FeatureTerms.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  link: PropTypes.string.isRequired,
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func,
  hasPrev: PropTypes.bool,
  nextText: PropTypes.string,
  nextClass: PropTypes.string,
  prevClass: PropTypes.string,
  loading: PropTypes.bool,
  isLAGWithFile: PropTypes.bool
};

FeatureTerms.defaultProps = {
  loading: false,
  prevStep: () => false,
  hasPrev: false,
  nextText: 'Avançar',
  nextClass: '',
  prevClass: '',
  isLAGWithFile: false
};

export default FeatureTerms;
