import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@useblu/ocean-react';

import { Loading } from './Styles';
import { Wrapper, Table } from './TableList.styles';
import TableListItem from './TableListItem';

// eslint-disable-next-line react/prop-types
const SingleRow = ({ children, ...rest }) => (
  <tr {...rest}>
    <td
      className="table-list__body-item"
      colSpan={3}
      style={{ textAlign: 'center' }}
    >
      {children}
    </td>
  </tr>
);

const TableList = ({ data, loading, hasMore, loadMore }) => {
  const showEmptyMsg = !data.length && !loading;

  return (
    <Wrapper>
      <Table aria-label="Arquivos importados" className="table table-list">
        <thead className="table-list__header">
          <tr>
            <th
              scope="col"
              className="table-list__header-item"
              style={{ textAlign: 'center' }}
            >
              Data da Importação
            </th>
            <th
              scope="col"
              className="table-list__header-item"
              style={{ textAlign: 'center' }}
            >
              Status
            </th>
            <th
              scope="col"
              className="table-list__header-item"
              style={{ textAlign: 'center' }}
            >
              Downloads
            </th>
          </tr>
        </thead>
        <tbody className="table-list__body">
          {data.map((cnab) => (
            <TableListItem key={cnab.id} {...cnab} />
          ))}
          {showEmptyMsg && <SingleRow>Nenhum registro encontrado.</SingleRow>}
          {loading && (
            <SingleRow data-testid="loading">
              <Loading
                type="spinningBubbles"
                color="#343e4f"
                width={30}
                height={30}
              />
            </SingleRow>
          )}
        </tbody>
      </Table>

      {hasMore && (
        <Button
          variant="secondary"
          type="button"
          disabled={loading}
          onClick={() => loadMore()}
          blocked
        >
          Ver mais arquivos
        </Button>
      )}
    </Wrapper>
  );
};

TableList.defaultProps = {
  data: [],
  hasMore: false,
  loading: false
};

TableList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  loadMore: PropTypes.func.isRequired
};

export default React.memo(TableList);
