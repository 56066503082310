import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tag } from '@useblu/ocean-react';
import { ChevronRight } from '@useblu/ocean-icons-react';
import { normalizeMoney } from '@useblu/utils';
import ItemSkeleton from './ItemSkeleton';
import * as S from '../styles';

const AccountStatementInfo = ({ detailsAnticipationData, currentStoreId }) => {
  return (
    <>
      <S.Subtitle variant="heading5">
        {detailsAnticipationData?.value?.isAnticipationRetainWithZeroRate
          ? 'Economia gerada pelo PagBlu'
          : 'Pagamento que gerou a antecipação'}
      </S.Subtitle>
      <S.PaymentItem
        href={
          detailsAnticipationData?.value?.outflow.linkEnabled
            ? `/lojas/${currentStoreId}/transacoes/${detailsAnticipationData?.value?.outflow.uuid}?financial_statement=true`
            : undefined
        }
        data-track="AntecipacaoPontual_Card_CT"
      >
        <div>
          <ItemSkeleton
            loading={detailsAnticipationData.loading}
            value={
              <Typography variant="paragraph">
                {normalizeMoney(
                  Math.abs(detailsAnticipationData?.value?.outflow.netValue)
                )}
              </Typography>
            }
          />
          <ItemSkeleton
            loading={detailsAnticipationData.loading}
            value={
              <Typography variant="description">
                {detailsAnticipationData?.value?.outflow.receiverName}
              </Typography>
            }
          />
          <ItemSkeleton
            loading={detailsAnticipationData.loading}
            value={
              <Tag type="neutral-03">
                {
                  detailsAnticipationData?.value?.outflow
                    .transactionCategoryName
                }
              </Tag>
            }
          />
        </div>
        <div>
          <ChevronRight />
        </div>
      </S.PaymentItem>
    </>
  );
};

AccountStatementInfo.propTypes = {
  currentStoreId: PropTypes.string.isRequired,
  detailsAnticipationData: PropTypes.object,
};

AccountStatementInfo.defaultProps = {
  detailsAnticipationData: null,
};

export default AccountStatementInfo;
