import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RangeSelector = ({ selected, range, item, onChange }) => {
  const [selectedDateIndex, setDateIndex] = React.useState(selected);

  const wrapperPosition = () => {
    const position = selectedDateIndex * 78 + 18 - (4 * 78 - 15);

    return { transform: `translateX(${-position}px)` };
  };

  const classes = (isSelected) =>
    classNames({
      'period-date-filter__date': true,
      'period-date-filter__date--selected': isSelected
    });

  const onSelect = (index) => {
    setDateIndex(index);
    onChange(range[index]);
  };

  return (
    <div className="period-date-filter">
      <div className="period-date-filter__container">
        <div className="period-date-filter__wrapper" style={wrapperPosition()}>
          {range.map((date, index) => (
            <button
              type="button"
              key={`${date.date}`}
              className={classes(index === selectedDateIndex)}
              onClick={() => {
                onSelect(index);
              }}
            >
              {item(range[index])}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

RangeSelector.propTypes = {
  selected: PropTypes.number.isRequired,
  range: PropTypes.array.isRequired,
  item: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RangeSelector;
