import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Image } from './Styles';
import ModalLayout from './ModalLayout';
import missingImg from './images/missing-illustration.svg';

const MissingModal = ({ isOpen, onClose }) => (
  <ModalLayout isOpen={isOpen} title="É apenas o início" onClose={onClose}>
    <Wrapper>
      <Image
        src={missingImg}
        alt="Pessoa no primeiro degrau ao subir uma escada"
      />
      <p>Este lojista ainda está no começo de sua jornada de Uso Blu.</p>
      <p>
        Mas não se preocupe, isso significa apenas que é novo ou utilizar pouco
        nossos serviços de vendas e/ou compras com fornecedores.
      </p>
      <p>
        Na Blu é sempre seguro realizar cobranças para seus clientes que pagarão
        com seus recebíveis sem taxa de antecipação.
      </p>
    </Wrapper>
  </ModalLayout>
);

MissingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default MissingModal;
