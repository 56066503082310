import React from 'react';

import {
  BannerContainer,
  BannerContent,
  BannerTextContainer,
  BannerText,
  BoldText
} from './styles';

const Banner = () => (
  <BannerContainer>
    <BannerContent>
      <BannerTextContainer>
        <BannerText>
          Se todos os dias você escolhe o melhor para o seu negócio,{' '}
          <BoldText>
            com a forma de antecipar seus recebíveis não poderia ser diferente.
          </BoldText>
        </BannerText>
      </BannerTextContainer>
    </BannerContent>
  </BannerContainer>
);

export default Banner;
