export const optionsIncome = [
  {
    value: '0,5 - 1 SM',
    label: '0,5 - 1 SM'
  },
  {
    value: '1 - 2 SM',
    label: '1 - 2 SM'
  },
  {
    value: '2 - 4 SM',
    label: '2 - 4 SM'
  },
  {
    value: '4 - 10 SM',
    label: '4 - 10 SM'
  },
  {
    value: '10 - 20 SM',
    label: '10 - 20 SM'
  },
  {
    value: '20+ SM',
    label: '20+ SM'
  }
];

export const optionsPatrimony = [
  {
    value: 'ABAIXO DE 100K',
    label: 'ABAIXO DE 100K'
  },
  {
    value: '100K - 250K',
    label: '100K - 250K'
  },
  {
    value: '250K - 500K',
    label: '250K - 500K'
  },
  {
    value: '500K - 1MM',
    label: '500K - 1MM'
  },
  {
    value: '1 - 5MM',
    label: '1 - 5MM'
  },
  {
    value: '5+ MM',
    label: '5+ MM'
  }
];

export const optionsDocumentType = [
  {
    value: 'RG',
    label: 'RG'
  },
  {
    value: 'CNH',
    label: 'CNH'
  }
];
