import axios from 'axios';
import humps from 'lodash-humps';
import { getCSRFToken } from 'utils/csrf-token';

export const axiosInstance = axios.create({
  headers: {
    Accept: 'version=1',
    Authorization: `token ${getCSRFToken()}`
  }
});

axiosInstance.interceptors.response.use(
  (response) => Promise.resolve(humps(response.data)),
  (error) =>
    Promise.reject(
      new Error(error.response?.data.message || 'Ocorreu um erro inesperado.')
    )
);

const BASE_ENDPOINT = '/api/cnabs';

export const uploadCnab = (data) => axiosInstance.post(BASE_ENDPOINT, data);

export const getCnabsPerPage = (page, client) =>
  axiosInstance.get(BASE_ENDPOINT, {
    params: { page, client }
  });

export const getCnabsByIds = (ids, client) =>
  axiosInstance.get(BASE_ENDPOINT, {
    params: { ids, client }
  });
