import React from 'react';
import PropTypes from 'prop-types';

import { Steps, Typography } from '@useblu/ocean-react';
import LogoBlu from './LogoBlu';
import LogoWhite from '../images/logo-blu-white.svg';
import LogoImage from '../images/logo-blu.svg';

import * as S from './DeviseScreenLayout.styles';

const DeviseScreenLayout = ({
  children,
  aside,
  color,
  className,
  align,
  formStep,
  hideStep,
  isNewUser,
  hideAside,
  bluLightProviderName,
}) => {
  if (hideAside) {
    return (
      <S.WrapperWithoutAside>
        <S.WrapperWithoutAsideContent className="ocean-iso">
          <img className="logo" src={LogoImage} alt="logo blu" />
          <Typography variant="description">
            Solicitação enviada por <strong>{bluLightProviderName}</strong>
          </Typography>

          <S.LabelStage style={{ display: hideStep && 'none' }}>
            <Steps currentStep={formStep} steps={!isNewUser ? 3 : 5} />
          </S.LabelStage>

          <div className="children">{children}</div>
        </S.WrapperWithoutAsideContent>
      </S.WrapperWithoutAside>
    );
  }

  return (
    <S.WrapperPageAside>
      {aside ? (
        <div className={`aside-container aside-container--color-${color}`}>
          {aside}
        </div>
      ) : (
        <div className={`aside-container aside-container--color-${color}`}>
          <img
            data-testid="btn-logo"
            className="logo"
            src={LogoWhite}
            alt="logo blu"
          />
        </div>
      )}

      <div className="page-container ocean-iso">
        <S.Container>
          <S.WrapperContent align={align} className={className}>
            <S.LogoStepper>
              <LogoBlu className="internal" />
              {formStep !== 0 && formStep !== 6 ? (
                <S.LabelStage style={{ display: hideStep && 'none' }}>
                  <Steps currentStep={formStep} steps={!isNewUser ? 3 : 5} />
                </S.LabelStage>
              ) : undefined}
            </S.LogoStepper>
            {children}
          </S.WrapperContent>
        </S.Container>
      </div>
    </S.WrapperPageAside>
  );
};

DeviseScreenLayout.propTypes = {
  aside: PropTypes.node,
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  align: PropTypes.bool,
  formStep: PropTypes.number,
  hideStep: PropTypes.bool,
  isNewUser: PropTypes.bool,
  hideAside: PropTypes.bool,
  bluLightProviderName: PropTypes.string,
};

DeviseScreenLayout.defaultProps = {
  aside: undefined,
  color: 'default',
  className: '',
  align: false,
  formStep: 0,
  hideStep: false,
  isNewUser: true,
  hideAside: false,
  bluLightProviderName: undefined,
};

export default DeviseScreenLayout;
