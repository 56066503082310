import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
  fetchZipCodeAdressRequest: ['zipCode', 'callback'],
  fetchZipCodeAdressSuccess: ['data'],
  fetchZipCodeAdressFailure: ['error'],
  cleansZipCodeAdressFetched: null
});

export { types };

export default actions;
