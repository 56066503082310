/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const fetchRating = (cpfCnpj, authToken) => {
  return axios.get(`${process.env.RATING_API_URL}/${cpfCnpj}`, {
    headers: {
      'x-api-key': process.env.RATING_API_KEY,
      Authorization: `token ${authToken}`
    }
  });
};
