import React from 'react';
import { QuestionMarkCircleOutline } from '@useblu/ocean-icons-react';

import * as S from './styles';

const DidYouKnow = () => (
  <>
    <S.Wrapper>
      <S.Icon>
        <QuestionMarkCircleOutline />
      </S.Icon>
      <S.Body>
        <S.Title>Você sabia?</S.Title>

        <S.SubTitle>
          Com a Antecipação Inteligente, no momento do pagamento, a Blu calcula
          e antecipa apenas o valor necessário do seu saldo futuro para pagar o
          fornecedor parceiro, sem cobrar nenhuma taxa.
          <span>Tudo isso, em apenas 1 passo!</span>
        </S.SubTitle>
      </S.Body>
    </S.Wrapper>
  </>
);

export default DidYouKnow;
