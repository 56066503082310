import styled from 'styled-components';
import {
  spacingInsetSm,
  spacingInlineXxs,
  lineHeightComfy,
  fontSizeXxxs,
  colorInterfaceLightUp,
  colorInterfaceDarkDown,
  borderRadiusMd
} from '@useblu/ocean-tokens/web/tokens';

export const Box = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${spacingInsetSm};
  background: ${colorInterfaceLightUp};
  border-radius: ${borderRadiusMd};
`;

export const Text = styled.div`
  font-size: ${fontSizeXxxs};
  line-height: ${lineHeightComfy};
  display: flex;
  align-items: center;
  color: ${colorInterfaceDarkDown};
  margin-bottom: 0;
`;

export const Image = styled.img`
  margin-right: ${spacingInlineXxs};
  @media (min-width: 768px) {
    width: 16px;
  }
`;
