import React from 'react';
import { useSelector } from 'react-redux';

import { normalizeValue } from 'normalizers';
import { getCSRFToken } from 'utils/csrf-token';
import { railsContextSelector } from 'selectors/rails-context';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import Header from 'components/PaymentCollectionDetails/containers/Header';
import MainActions from 'components/PaymentCollectionDetails/containers/MainActions';
import RefundPaymentCollectionSimulation from 'components/RefundPaymentCollection/Simulation';
import ReadOnlyField from 'components/PaymentCollectionDetails/components/ReadOnlyField';
import ReadOnlyFieldCollapse from 'components/Shared/ReadOnlyFieldCollapse';
import Dates from 'components/PaymentCollectionDetails/containers/Dates';
import ListContainer from 'components/PaymentCollectionDetails/components/ListContainer';
import ListItem from 'components/PaymentCollectionDetails/components/ListItem';
import RefundPaymentCollectionHistory from 'components/RefundPaymentCollection/History';
import InstallmentsAndCosts from 'components/PaymentCollectionDetails/containers/InstallmentsAndCosts';
import PartialPaymentInfo from 'components/PaymentCollectionDetails/containers/PartialPaymentInfo';
import PaymentPlanSection from './components/PaymentPlanSection';
import Costs from './components/Costs';
import CancelButton from './containers/CancelButton';
import Installments from './containers/Installments';

const ChargerScreen = () => {
  const { clientId, economicGroupView } = useSelector(railsContextSelector);
  const data = useSelector(selectors.getDetailsData);

  const isBankBillet = data?.kind?.name === 'payment_collection_bank_billet';

  return (
    <div className="flex-container">
      <Header isCharger />

      <div className="statement-detail__content container is-padded">
        <MainActions extraActions={data.showCancel && <CancelButton />} />

        {data.showAcceptPartialPayment && (
          <div className="statement-detail__actions">
            <a
              className="button button--secondary button--secondary-with-ellipsis"
              data-disable-with="Processando..."
              href={`/lojas/${clientId}/cobrancas/${data.uuid}/partial_payment`}
            >
              Aceitar valor parcial {normalizeValue(data.valueToPartialPay)}
            </a>
          </div>
        )}

        {data.showRefundOption && (
          <RefundPaymentCollectionSimulation
            maxValueToRefund={data.remainingRefundValue.toString()}
            minValueToRefund={data.minRefundValue.toString()}
            clientUuid={clientId}
            uuid={data.uuid}
            formAction={`/lojas/${clientId}/cobrancas/${data.uuid}/devolucoes`}
            authenticityToken={getCSRFToken()}
            canRefund={data.canRefund && !economicGroupView}
            unavailableReason={
              economicGroupView
                ? 'Devolução pelo modo grupo econômico indisponível no momento'
                : data.refundUnavailableReason
            }
          />
        )}

        <br />

        {data.hasPartialPayment && <PartialPaymentInfo isCharger />}

        {data.reference && (
          <ReadOnlyField label="Código de referência" value={data.reference} />
        )}

        <ReadOnlyField
          label={isBankBillet ? 'Identificador' : 'Identificador da Cobrança'}
          value={data.id}
        />

        {data.kindCollection && (
          <ReadOnlyField label="Tipo de Cobrança" value={data.kindCollection} />
        )}

        <ReadOnlyFieldCollapse
          label="Cobrado"
          value={data.charged.name}
          openLabel="ver detalhes"
          fields={[
            { label: 'Nome fantasia', value: data.charged.businessName },
            { label: 'CNPJ/CPF', value: data.charged.cpfCnpj }
          ]}
        />

        <Dates />

        {data.bonuses && (
          <ListContainer title="Bônus">
            {data.bonuses.map((bonus) => (
              <ListItem
                key={bonus.title}
                title={bonus.title}
                value={normalizeValue(bonus.value)}
              />
            ))}
          </ListContainer>
        )}

        {data.paymentPlans && <PaymentPlanSection {...data.paymentPlans} />}

        <InstallmentsAndCosts
          InstallmentsElementType={Installments}
          CostsElementType={Costs}
          isCharger
        />

        {data.refunds && (
          <RefundPaymentCollectionHistory
            refunds={data.refunds}
            showAccountStatementLink={data.showRefundAccountStatementLink}
            isCharger
          />
        )}

        {data.createdFrom && (
          <small>* Esta cobrança foi criada via {data.createdFrom}.</small>
        )}
      </div>
    </div>
  );
};

export default ChargerScreen;
