import React from 'react';

const MoneyOutline = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: 'inherit' }}
  >
    <path
      d="M18.4004 5.63087C14.8926 2.12304 9.13613 2.12304 5.6283 5.63087C2.48026 8.77891 2.21042 13.546 4.54897 17.0538L4.27914 18.2231L3.91936 19.7521C3.82942 20.2918 4.27914 20.7415 4.72886 20.5616L6.16797 20.1119L7.42719 19.7521C10.8451 21.8208 15.3423 21.2812 18.3104 18.4029C21.9082 14.8951 21.9082 9.13869 18.4004 5.63087Z"
      stroke="#5872F5"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 9C14.7 8 13.7 7.5 12 7.5M12 7.5C9.4 7.5 9 9 9 9.8C9 12.9 15 11.3 15 14.3C15 15.1 14.6 16.5 12 16.5M12 7.5V6M12 16.5C10.3 16.5 9.3 16 9 15M12 16.5V18"
      stroke="#5872F5"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MoneyOutline;
