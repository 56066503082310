import React from 'react';

import {
  Container,
  CarouselContainer,
  Title,
  Card,
  CardImg,
  CardNumber,
  CardText,
  Highlight
} from './styles';

import howToEnable1 from './images/howToEnable1.svg';
import howToEnable2 from './images/howToEnable2.svg';
import howToEnable3 from './images/howToEnable3.svg';
import howToEnable4 from './images/howToEnable4.svg';

import Carousel from '../../Shared/Carousel';

const HowToEnable = () => (
  <Container data-testid="automatic-anticipation-how-to-enable">
    <Title>Como habilitar:</Title>

    <CarouselContainer>
      <Carousel>
        <Card>
          <CardImg
            src={howToEnable1}
            alt="imagem mostrando o botão de habilitar Antecipação Automática"
          />
          <CardNumber>01</CardNumber>
          <CardText>
            Clique em <Highlight>ativar antecipação automática</Highlight>
          </CardText>
        </Card>
        <Card>
          <CardImg
            src={howToEnable2}
            alt="imagem mostrando as opções de 'Aceitar para todas as lojas que sou representante legal' ou 'Aceitar apenas para esta loja'"
          />
          <CardNumber>02</CardNumber>
          <CardText>
            <Highlight>Escolha para quais lojas</Highlight> você deseja
            habilitar a Antecipação Automática
          </CardText>
        </Card>
        <Card>
          <CardImg
            src={howToEnable3}
            alt="imagem mostrando que os termos de serviço foram aceitos e o botão de ativar"
          />
          <CardNumber>03</CardNumber>

          <CardText>
            <Highlight>Aceite os Termos e Condições</Highlight> e confirme
            clicando em <Highlight>Ativar</Highlight>
          </CardText>
        </Card>
        <Card>
          <CardImg
            src={howToEnable4}
            alt="imagem mostrando que a Antecipação Automática foi ativada"
          />
          <CardNumber>04</CardNumber>
          <CardText>
            <Highlight>Feito!</Highlight> Agora seu dinheiro estará disponível
            como saldo atual na sua Conta Digital Blu em até 24 horas ou no
            próximo dia útil.
          </CardText>
        </Card>
      </Carousel>
    </CarouselContainer>
  </Container>
);

export default HowToEnable;
