import { css } from 'styled-components';

import {
  colorInterfaceLightPure,
  shadowLevel1,
  colorInterfaceLightDown
} from '@useblu/ocean-tokens/web/tokens';

const ContainerMixin = css`
  border-radius: 8px;

  background: ${colorInterfaceLightPure};
  border: 1px solid ${colorInterfaceLightDown};
  box-shadow: ${shadowLevel1};
`;

export default ContainerMixin;
