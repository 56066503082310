import React from 'react';
import PropTypes from 'prop-types';
import Actions from './Actions';
import {
  Caption,
  Title,
  ModalFooterContainer,
  ModalContent,
  FooterText
} from '../modal.styles';

const ForbiddenUser = ({ onRequestClose }) => (
  <div className="fullstory-optin-automatic_anticipation-forbidden">
    <Title className="automatic_anticipation_title">
      A ativação deste produto só pode ser feita pelo administrador de sua conta
    </Title>
    <ModalContent className="small fullstory-forbbiden-user">
      <Caption className="automatic_anticipation_center">
        Para ativar este produto fale com o administrador de sua Conta Digital
        Blu.
      </Caption>
    </ModalContent>

    <ModalFooterContainer className="no-border">
      <FooterText className="desktop" />
      <Actions
        nextStep={() => {
          onRequestClose();
        }}
        hasPrev={false}
        loading={false}
        nextText="Entendi"
      />
    </ModalFooterContainer>
  </div>
);

ForbiddenUser.propTypes = {
  onRequestClose: PropTypes.func
};

ForbiddenUser.defaultProps = {
  onRequestClose: () => false
};

export default ForbiddenUser;
