import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

export const Wrapper = styled.div`
  &&& {
    display: flex;

    @media screen and (max-width: 1024px) {
      padding: 40px 24px;
    }

    @media screen and (max-width: 768px) {
      padding: 24px 16px 80px;
    }
  }

  .ods-form-control__element {
    z-index: 1;
  }
`;

export const Content = styled.div`
  max-width: 744px;
  align-self: center;

  .transfer-upload {
    height: auto;
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  gap: 10px;
`;
