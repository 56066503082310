import React from 'react';
import PropsType from 'prop-types';
import styled from 'styled-components';

import { Link } from '@useblu/ocean-react';
import { ArrowNarrowLeft } from '@useblu/ocean-icons-react';

import { colorInterfaceLightDown } from '@useblu/ocean-tokens/web/tokens';

const Wrapper = styled.div`
  border: 1px solid ${colorInterfaceLightDown};
  padding: 16px;

  .pgnt-header-back-link {
    font-weight: 800;
    font-size: 20px;
    line-height: 110%;
    display: flex;
    align-items: center;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    svg {
      margin-right: 18px;
    }
  }
`;

const PageViewHeader = ({ title, backLink }) => (
  <Wrapper className="ocean-iso">
    <Link className="pgnt-header-back-link" href={backLink}>
      <ArrowNarrowLeft size={24} /> {title}
    </Link>
  </Wrapper>
);

PageViewHeader.propTypes = {
  title: PropsType.string.isRequired,
  backLink: PropsType.string.isRequired,
};

export default PageViewHeader;
