import React from 'react';
import PropTypes from 'prop-types';
import { Button as OceanButton } from '@useblu/ocean-react';
import { useSelector } from 'react-redux';
import { useLocalStorage } from 'react-use';

import {
  ShoppingBagOutline,
  ReceiptTaxOutline,
  ChartBarOutline,
} from '@useblu/ocean-icons-react';
import {
  ModalContent,
  Text,
  Title,
  Actions,
  BenefitsContainer,
  IconBox,
  Benefit,
} from './ContractSigning.styles';

import MoneyOutline from '../icons/MoneyOutline';

const ContractSigning = ({ onRequestClose, hasSocialContractToSigning }) => {
  const clientId = useSelector(
    (state) =>
      state.railsContext?.clientId || state.railsContext?.clientClientId
  );

  const [, setValueStorage] = useLocalStorage(
    `has-contract-signing-closed-by-${clientId}`
  );

  const onSubmit = () => {
    onRequestClose('ContractSigning');

    if (hasSocialContractToSigning) {
      window.location.href = `/lojas/${clientId}/completude-cadastral/enviar-contrato`;
    } else {
      setValueStorage(true);
      window.location.assign(`/lojas/${clientId}/contratos`);
    }
  };

  return (
    <ModalContent className="fullstory-contract-signing-modal">
      <Title>
        Finalize seu cadastro e comece a aproveitar todos os benefícios Blu
      </Title>
      <BenefitsContainer>
        <Benefit>
          <IconBox>
            <ShoppingBagOutline style={{ width: 'inherit' }} />
          </IconBox>
          <Text>Atraia mais clientes e potencialize suas vendas.</Text>
        </Benefit>
        <Benefit>
          <IconBox>
            <MoneyOutline />
          </IconBox>
          <Text>
            Compre com novos fornecedores sem necessidade de análise ou limite
            de crédito.
          </Text>
        </Benefit>
        <Benefit>
          <IconBox>
            <ReceiptTaxOutline style={{ width: 'inherit' }} />
          </IconBox>
          <Text>Ganhe desconto ao pagar seu fornecedor pela Blu.</Text>
        </Benefit>
        <Benefit>
          <IconBox>
            <ChartBarOutline style={{ width: 'inherit' }} />
          </IconBox>
          <Text>
            Faça toda a sua rotina financeira em só lugar e sem pagar nada por
            isso.
          </Text>
        </Benefit>
      </BenefitsContainer>
      <Actions>
        <OceanButton onClick={onSubmit}>Finalizar cadastro</OceanButton>
      </Actions>
    </ModalContent>
  );
};

ContractSigning.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  hasSocialContractToSigning: PropTypes.bool,
};

ContractSigning.defaultProps = {
  hasSocialContractToSigning: false,
};

export default ContractSigning;
