import React from 'react';
import PropTypes from 'prop-types';

const CardListItem = ({ redirect, title, content, color }) => {
  const style = {
    color
  };

  return (
    <div className="card-list-item">
      <a href={redirect} style={style}>
        <div className="item-box">
          <p className="item-box__title">{title}</p>
          <p className="item-box__content">{content}</p>
        </div>
        <div className="item-navigation">
          <i className="fa fa-angle-right" />
        </div>
      </a>
    </div>
  );
};

CardListItem.propTypes = {
  redirect: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  color: PropTypes.string
};

CardListItem.defaultProps = {
  color: '#79869c'
};

export default CardListItem;
