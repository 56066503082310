import styled from 'styled-components';
import {
  colorInterfaceLightPure,
  shadowLevel1
} from '@useblu/ocean-tokens/web/tokens';

import { LogoNav } from 'components/Dashboard/shared/Logo';
import { MobileBalanceNavStyled } from './components/MobileBalance.styles';

export const Nav = styled.nav`
  display: flex;
  height: 80px;
  width: 100%;
  padding: 8px;
  align-items: stretch;
  background: ${colorInterfaceLightPure};
  box-shadow: ${shadowLevel1};

  @media screen and (max-width: 768px) {
    height: 56px;
    padding: 0px;
    flex-direction: row-reverse;

    > nav {
      width: 100%;
    }
  }
`;

export const Balance = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;

  @media screen and (max-width: 768px) {
    padding-right: 8px;
  }
`;

export const WrapperSettingsMenu = styled.div`
  position: relative;
  left: 5px;
`;

export const HeaderContainer = styled.header`
  display: flex;
  position: relative;
  width: 100%;
  z-index: 4;

  ${LogoNav} , ${MobileBalanceNavStyled} {
    display: none;
  }

  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    ${MobileBalanceNavStyled} {
      display: flex;
    }

    ${LogoNav} {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      min-width: 48px;
      margin: 4px;
      padding: 0px;
      background: transparent;

      svg {
        height: 24px;
      }
    }

    ${Balance} {
      display: none;
    }
  }
`;
