/* Core */
import React from 'react';
import PropTypes from 'prop-types';

import { isEmpty } from 'lodash';
/* Presentational */
import DayDateFilter from './DayDateFilter';
import MonthDateFilter from './MonthDateFilter';

import PeriodDatePicker from './PeriodDatePicker';
import {
  toDay,
  printToDate,
  dateToPrint,
  serverToDate,
  dateToServer
} from './DateHelper';

const PeriodGroup = {
  DayPeriodDateFilter: 'day',
  MonthPeriodDateFilter: 'month',
  PeriodDatePicker: 'day'
};

const prepareFilter = ({ date, from, to }, type) => {
  if (type === 'DayPeriodDateFilter') {
    return {
      from: dateToServer(printToDate(date).startOf('day')),
      to: dateToServer(printToDate(date).startOf('day'))
    };
  }
  if (type === 'MonthPeriodDateFilter') {
    return {
      from: dateToServer(printToDate(date).startOf('month')),
      to: dateToServer(printToDate(date).endOf('month'))
    };
  }
  return {
    from: dateToServer(printToDate(from)),
    to: dateToServer(printToDate(to))
  };
};

const PeriodFilters = ({ type, onChange }) => {
  const selected = toDay();
  const [filter, setFilter] = React.useState({});

  const onChangeFilter = (changed) => {
    setFilter(changed);
  };

  React.useEffect(() => {
    if (!isEmpty(filter)) {
      if (!filter.from && !filter.to) {
        filter.to = dateToPrint(selected);
        filter.from = dateToPrint(serverToDate(selected).subtract(7, 'days'));
      }

      onChange({
        ...prepareFilter(filter, type),
        group_by: PeriodGroup[type]
      });
    }
  }, [filter, type, onChange]);

  const PeriodFilter = (
    <PeriodDatePicker selected={selected} onChange={onChangeFilter} />
  );

  switch (type) {
    case 'DayPeriodDateFilter':
      return <DayDateFilter selected={selected} onChange={onChangeFilter} />;
    case 'MonthPeriodDateFilter':
      return <MonthDateFilter selected={selected} onChange={onChangeFilter} />;
    case 'PeriodDatePicker':
      return PeriodFilter;
    default:
      return PeriodFilter;
  }
};

PeriodFilters.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PeriodFilters;
