/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { connect } from 'react-redux';
import { compose } from 'redux';

/* Actions and Validators */
import qsaAccountsActions from 'store/ducks/firstAccess/qsaAccounts';
import sharedActions from 'store/ducks/firstAccess/shared';
import history from 'utils/browserHistory';
import { maskCpfOrCnpj, maskValue } from 'utils/masks';

/* Presentational */
import { reduxForm, Form, Field } from 'redux-form';
import RenderField from 'components/Form/RenderField';
import Loading from 'react-loading';
import _ from 'lodash';
import { clientSelector } from 'selectors/FirstAccess/shared';
import validateQsaAccountsForm from 'validations/ValidateQsaAccountsForm';
import QsaAccountsModal from './qsaAccountsModal';
import QsaAccountsDeleteModal from './qsaAccountsDeleteModal';

const currencyToFloat = (value) => {
  if (typeof value === 'string') {
    return parseFloat(value.replace('.', '').replace(',', '.'));
  }

  return value;
};

const participationGtZero = (partner) => {
  const value = currencyToFloat(
    partner.companyAssociates[0].profitParticipation
  );
  return value <= 0 || _.isNil(value) ? 'deve ser maior que 0' : undefined;
};

const participationLimit = (limit, partner) => {
  const value = currencyToFloat(
    partner.companyAssociates[0].profitParticipation
  );
  return value > 0 && value > 100 - (limit - 1)
    ? 'limite de participação excedido'
    : undefined;
};

const participationSum = (sum, partner) => {
  const value = currencyToFloat(
    partner.companyAssociates[0].profitParticipation
  );
  return value > 0 && sum > 100 ? 'limite de participação excedido' : undefined;
};

class QsaAccountsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModal: {
        isOpen: false
      },
      qsaModal: {
        isOpen: false
      }
    };
  }

  componentDidMount() {
    const { setCurrentStep, client, qsaAccountsRequest } = this.props;
    setCurrentStep('qsaAccounts');

    if (client) {
      qsaAccountsRequest(client);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { qsaModal, deleteModal, partner, client: nextClient } = nextProps;
    const { qsaAccountsRequest, client } = this.props;

    if (_.isEmpty(client) && !_.isEmpty(nextClient)) {
      qsaAccountsRequest(nextClient);
    }

    if (
      _.isEqual(partner, {}) &&
      (this.state.qsaModal.isOpen || this.state.deleteModal.isOpen)
    ) {
      !qsaModal.loading && this.closeQsaModal();
      !deleteModal.loading && this.closeDeleteModal();
    }
  }

  onProfitParticipationChange(value, partner, i) {
    const { qsa, qsaAccountsUpdate } = this.props;

    const updatedQsa = [...qsa];
    let updatedPartner = { ...partner };

    updatedPartner = {
      ...updatedPartner,
      companyAssociates: [
        {
          ...updatedPartner.companyAssociates[0],
          profitParticipation: maskValue(value, 'currency')
        }
      ]
    };

    updatedQsa[i] = updatedPartner;

    qsaAccountsUpdate(updatedQsa);
  }

  setPartner(partner) {
    const { qsaAccountsSetPartner } = this.props;
    qsaAccountsSetPartner(partner);
  }

  setPartnerAddress(address, partner) {
    const { qsaAccountsSetPartnerAddress } = this.props;
    qsaAccountsSetPartnerAddress(address, partner);
  }

  getParticipationLimit() {
    const qsaWithPosition = this.qsaWithPosition();
    const limit = qsaWithPosition.length;
    return limit;
  }

  getParticipationSum() {
    const qsaWithPosition = this.qsaWithPosition();
    const sum = _.sumBy(qsaWithPosition, (partner) =>
      currencyToFloat(partner.companyAssociates[0].profitParticipation)
    );
    return sum;
  }

  previous(e) {
    e.preventDefault();
    history.push(
      `/lojas/${this.props.uuid}/primeiro-acesso/informacoes-da-empresa`
    );
  }

  next(e) {
    e.preventDefault();
    history.push(
      `/lojas/${this.props.uuid}/primeiro-acesso/autorizacoes-dos-socios`
    );
  }

  editPartner(partner, e) {
    e.preventDefault();
    this.setPartner(partner);
    this.openQsaModal();
  }

  deletePartner() {
    const { client, partner, qsaAccountsDeletePartner } = this.props;
    qsaAccountsDeletePartner(partner.cpfCnpj, client.cpfCnpj);
  }

  closeSaveModal() {
    this.setPartner({});
    this.setState({ saveModal: { isOpen: false } });
  }

  closeDeleteModal() {
    this.setPartner({});
    this.setState({ deleteModal: { isOpen: false } });
  }

  openDeleteModal(e, partner) {
    e.preventDefault();
    this.setPartner(partner);
    this.setState({ deleteModal: { isOpen: true } });
  }

  openQsaModal() {
    this.setState({ qsaModal: { isOpen: true } });
  }

  closeQsaModal() {
    this.setPartner({});
    this.setState({ qsaModal: { isOpen: false } });
  }

  newPartner(e) {
    e.preventDefault();

    this.setPartner({
      name: '',
      cpfCnpj: '',
      companyAssociates: [
        {
          position: ''
        }
      ]
    });

    this.openQsaModal();
  }

  savePartner() {
    const { client, partner, qsaAccountsSavePartner } = this.props;

    partner.cpfCnpj = partner.cpfCnpj.replace(/\D/g, '');

    qsaAccountsSavePartner(client, partner);
  }

  qsaWithPosition() {
    const { qsa } = this.props;

    if (_.isEmpty(qsa)) {
      return [];
    }

    return _.filter(qsa, (partner) => {
      return this.isPartner(partner);
    });
  }

  handleSubmit(e) {
    const { qsaAccountsFormSubmit, client, qsa, uuid } = this.props;

    e.preventDefault();

    qsaAccountsFormSubmit(client.cpfCnpj, qsa, uuid);
  }

  isPartner(partner) {
    return (
      _.isEqual(partner.companyAssociates[0].position, 'Sócio') ||
      _.isEqual(partner.companyAssociates[0].position, 'Sócio administrador')
    );
  }

  isProfitParticipationOk() {
    const oneHundredPercentParticipation = this.getParticipationSum() === 100;
    const noPartnerWithZeroParticipation =
      this.qsaWithPosition().filter(
        (partner) =>
          currencyToFloat(partner.companyAssociates[0].profitParticipation) ===
          0.0
      ).length === 0;

    return oneHundredPercentParticipation && noPartnerWithZeroParticipation;
  }

  participationHint() {
    if (!this.props.qsa.length) {
      return '';
    }

    const participationSum = this.getParticipationSum();

    if (participationSum < 100) {
      return `Você precisa de mais ${(100 - participationSum).toFixed(
        2
      )}% de participação para continuar`;
    }

    if (participationSum > 100) {
      return `Você precisa de menos ${(participationSum - 100).toFixed(
        2
      )}% de participação para continuar`;
    }

    return '';
  }

  renderPartnerProfitParticipation(partner, i) {
    let profitParticipation = partner.companyAssociates[0].profitParticipation;

    if (typeof profitParticipation === 'number') {
      profitParticipation = profitParticipation.toFixed(2);
    }

    const error =
      participationGtZero(partner) ||
      participationLimit(this.getParticipationLimit(), partner) ||
      participationSum(this.getParticipationSum(), partner);

    let profitAdditionalProps = {};

    if (error) {
      profitAdditionalProps = {
        meta: {
          error,
          touched: true
        }
      };
    }

    return (
      <Field
        type="text"
        required
        name={`qsa[${i}][profitParticipation]`}
        component={RenderField}
        label="Percentual de participação:"
        defaultValue={profitParticipation}
        mask="currency"
        onChange={(e) =>
          this.onProfitParticipationChange(e.target.value, partner, i)
        }
        {...profitAdditionalProps}
      />
    );
  }

  renderQsaList() {
    const { qsa, permission } = this.props;
    let content;

    content = _.map(qsa, (partner, i) => (
      <div className="first-access-app--users-list list--item" key={i}>
        <div className="list--content">
          <p>
            <i className="fa fa-user" /> <b>{partner.name}</b>
          </p>
          <p>
            {partner.companyAssociates && partner.companyAssociates[0].position}
          </p>
          <p>{maskCpfOrCnpj(partner.cpfCnpj)}</p>
        </div>
        <div className="list--actions">
          {this.isPartner(partner) &&
            this.renderPartnerProfitParticipation(partner, i)}
        </div>
        {!partner.bureaus && permission.admin ? (
          <div className="list--actions">
            <button
              className="button--round"
              onClick={(e) => this.editPartner(partner, e)}
            >
              <i className="fa fa-pencil" />
            </button>
            <button
              className="button--round button--round-error"
              onClick={(e) => this.openDeleteModal(e, partner)}
              style={{ display: partner.bigBoost ? 'none' : '' }}
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        ) : (
          <div className="list--actions" />
        )}
      </div>
    ));

    if (_.isEmpty(qsa)) {
      content = (
        <div className="first-access-app--users-list list--item list--empty list">
          <div className="list--content list--content--empty">
            <i className="fa fa-user-times list__icon list__icon--centered" />
            <p className="list--content-text">
              Não encontramos sócios para sua empresa na Receita Federal!
              <br />
              Avançe para o <b>próximo passo.</b>
            </p>
          </div>
        </div>
      );
    }

    return content;
  }

  renderLoading() {
    return (
      <div className="loading">
        <Loading
          type="spinningBubbles"
          color="#e1e5ed"
          width="100px"
          height="100px"
          delay={0}
        />
      </div>
    );
  }

  renderQsaDeleteModal() {
    const { deleteModal } = this.props;

    return (
      <QsaAccountsDeleteModal
        isOpen={this.state.deleteModal.isOpen}
        loading={deleteModal.loading}
        onClose={() => this.closeDeleteModal()}
        onDelete={() => this.deletePartner()}
      />
    );
  }

  renderQsaModal() {
    const { partner, qsaModal, invalid, formErrors } = this.props;

    return (
      <QsaAccountsModal
        partner={partner}
        isOpen={this.state.qsaModal.isOpen}
        loading={qsaModal.loading}
        invalid={invalid}
        setPartner={(partner) => this.setPartner(partner)}
        onClose={() => this.closeQsaModal()}
        onSave={() => this.savePartner()}
        errors={formErrors}
      />
    );
  }

  renderQsaAccounts() {
    const { formLoading, permission, qsa } = this.props;

    return (
      <div className="form-fields-for">
        <h1 className="page-subtitle">
          <i className="fa fa-users" /> Quadro societário
        </h1>
        <h3 className="page-description">
          Confirme o Quadro Societário e preencha a participação de cada Sócio
        </h3>
        <Form className="form-fields-for" noValidate>
          {formLoading ? this.renderLoading() : this.renderQsaList()}
          {formLoading ? (
            ''
          ) : (
            <div className="form-row actions">
              <div className="participation-hint">
                {this.participationHint()}
              </div>
              {permission.admin && qsa.length > 0 && (
                <button
                  type="button"
                  className="button button--medium button--secondary is-auto-size"
                  name="new-user--add"
                  onClick={(e) => this.newPartner(e)}
                >
                  Adicionar novo sócio
                </button>
              )}
            </div>
          )}
          <div className="form-row actions">
            <button
              className="button form-controls button--secondary"
              onClick={(e) => this.previous(e)}
            >
              Voltar
            </button>

            {qsa.length ? (
              <button
                disabled={!this.isProfitParticipationOk()}
                onClick={(e) => this.handleSubmit(e)}
                className="button button--primary"
              >
                Confirmar
              </button>
            ) : (
              <button
                onClick={(e) => this.next(e)}
                className="button button--primary"
              >
                Avançar
              </button>
            )}
          </div>
        </Form>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="flex-container">
          {this.renderQsaAccounts()}
          {this.renderQsaModal()}
          {this.renderQsaDeleteModal()}
        </div>
      </div>
    );
  }
}

QsaAccountsForm.propTypes = {
  uuid: PropTypes.string.isRequired,
  qsa: PropTypes.array.isRequired,
  client: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  formLoading: PropTypes.bool,
  invalid: PropTypes.bool,
  qsaAccountsRequest: PropTypes.func.isRequired,
  qsaAccountsSetPartnerAddress: PropTypes.func.isRequired,
  qsaAccountsDeletePartner: PropTypes.func.isRequired,
  qsaAccountsSetPartner: PropTypes.func.isRequired,
  qsaAccountsSavePartner: PropTypes.func.isRequired,
  qsaAccountsUpdate: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  qsaModal: PropTypes.object.isRequired,
  deleteModal: PropTypes.object.isRequired,
  qsaAccountsFormSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.shape({}),
  permission: PropTypes.shape({}).isRequired
};

QsaAccountsForm.defaultProps = {
  formLoading: false,
  saveModal: {
    loading: false
  },
  deleteModal: {
    loading: false
  },
  invalid: false,
  submitting: false,
  handleSubmit: () => {},
  formErrors: {}
};

const mapStateToProps = (state) => ({
  qsa: state.firstAccessQsaAccounts.qsa,
  client: clientSelector(state),
  formLoading: state.firstAccessQsaAccounts.form.loading,
  qsaModal: state.firstAccessQsaAccounts.qsaModal,
  deleteModal: state.firstAccessQsaAccounts.deleteModal,
  partner: state.firstAccessQsaAccounts.partner,
  railsContext: state.railsContext,
  initialValues: {
    qsa: state.firstAccessQsaAccounts.qsa,
    partner: state.firstAccessQsaAccounts.partner,
    client: clientSelector(state)
  },
  formErrors: state.firstAccessQsaAccounts.errors,
  permission: state.permission
});

const mapDispatchToProps = (dispatch) => ({
  qsaAccountsRequest: (client) =>
    dispatch(qsaAccountsActions.firstAccessQsaAccountsRequest(client)),
  qsaAccountsSearchPartnerAddress: (zipcode, partner) =>
    dispatch(
      qsaAccountsActions.firstAccessQsaAccountsSearchAddress(zipcode, partner)
    ),
  qsaAccountsSetPartnerAddress: (address, partner) =>
    dispatch(
      qsaAccountsActions.firstAccessQsaAccountsSetAddress(address, partner)
    ),
  qsaAccountsDeletePartner: (cpfCnpj, companyCnpj) =>
    dispatch(
      qsaAccountsActions.firstAccessQsaAccountsDeletePartner(
        cpfCnpj,
        companyCnpj
      )
    ),
  qsaAccountsSetPartner: (partner) =>
    dispatch(qsaAccountsActions.firstAccessQsaAccountsSetPartner(partner)),
  qsaAccountsSavePartner: (client, partner) =>
    dispatch(
      qsaAccountsActions.firstAccessQsaAccountsSavePartnerRequest(
        client,
        partner
      )
    ),
  qsaAccountsUpdate: (qsa) =>
    dispatch(qsaAccountsActions.firstAccessQsaAccountsUpdate(qsa)),
  setCurrentStep: (step) =>
    dispatch(sharedActions.firstAccessCurrentStep(step)),
  qsaAccountsFormSubmit: (companyCnpj, qsa, uuid) =>
    dispatch(
      qsaAccountsActions.firstAccessQsaAccountsFormSubmit(
        companyCnpj,
        qsa,
        uuid
      )
    )
});

const QsaAccountsFormReduxForm = reduxForm({
  form: 'QsaAccountsForm',
  initialValues: { enabled: true },
  validate: validateQsaAccountsForm,
  asyncValidating: true,
  shouldValidate() {
    return true;
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  QsaAccountsFormReduxForm
)(QsaAccountsForm);
