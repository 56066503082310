import { Typography } from '@useblu/ocean-react';
import {
  colorInterfaceDarkDeep,
  colorStatusNegativePure,
  fontSizeXs,
  spacingStackXxs,
  spacingInlineXs,
  spacingStackXs
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';
import { Devices } from 'utils/getDeviceMedia';

export const Title = styled(Typography)`
  color: ${colorStatusNegativePure};

  @media ${Devices.mobile} {
    margin-bottom: ${spacingStackXs};
    text-align: center;
  }
`;

export const Description = styled(Typography)`
  color: ${colorInterfaceDarkDeep};
  font-size: ${fontSizeXs};

  @media ${Devices.mobile} {
    text-align: center;
  }
`;

export const Actions = styled.div`
  display: flex;
  padding: ${spacingStackXxs} 0;
  margin-top: ${spacingStackXxs};
  gap: ${spacingInlineXs};
  justify-content: flex-end;

  @media ${Devices.mobile} {
    flex-direction: column-reverse;
  }
`;
