import React from 'react';
import PropTypes from 'prop-types';

import { normalizeValue } from 'normalizers';
import InstallmentsContainer from './InstallmentsContainer';

const PaymentCollectionInstallments = ({ installments }) => (
  <InstallmentsContainer>
    {installments.map((installment, index) => (
      <div
        key={installment.availability}
        className="list__item list__item-sp-between statement-detail__installment"
      >
        <div className="list__item-left">
          <p>
            <strong>{index + 1}ª Parcela</strong>
          </p>
          <p>{normalizeValue(installment.value)}</p>
        </div>

        <div className="list__item-right account-statement__item-value">
          <p>
            <span>disponível </span>
            {installment.availability}
          </p>
        </div>
      </div>
    ))}
  </InstallmentsContainer>
);

PaymentCollectionInstallments.propTypes = {
  installments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      availability: PropTypes.string.isRequired
    })
  ).isRequired
};

export default PaymentCollectionInstallments;
