import styled from 'styled-components';
import {
  fontSizeMd,
  spacingInsetSm,
  spacingStackMd,
  spacingStackXs,
  spacingStackXxl,
  spacingStackXxs,
  spacingInlineXxs,
  colorInterfaceLightDown,
  colorBrandPrimaryPure,
  colorInterfaceLightPure
} from '@useblu/ocean-tokens/web/tokens';

import { Grid } from '@useblu/ocean-react';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  tablet: `(max-width: 1024px)`
};

export const GridContainer = styled(Grid.Container)`
  &&& {
    @media ${devices.mobile} {
      background-color: ${colorInterfaceLightPure};
      left: 0;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
      display: flex;
      flex-direction: column;
      flex: 1;

      .ods-row {
        flex: 1;
      }

      .ods-col-12 {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  font-size: ${fontSizeMd};
  padding: ${spacingStackXs};
  color: ${colorBrandPrimaryPure};
  background: none;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingStackMd};
  margin-top: ${spacingStackXxl};

  @media ${devices.mobile} {
    margin-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  gap: ${spacingStackXxs};

  & h2.ods-typography__heading2 {
    color: ${colorBrandPrimaryPure};
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacingInlineXxs};
  padding: ${spacingInsetSm} 0;
  border-top: 1px solid ${colorInterfaceLightDown};
  margin-top: ${spacingStackMd};

  @media ${devices.mobile} {
    flex-direction: column-reverse;
    width: 100%;
    padding: ${spacingInsetSm};
  }
`;
