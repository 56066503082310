import useSWR from 'swr';
import humps from 'lodash-humps';

import API from '../fetch';

export default function useFetch(url, version = 1, options = {}) {
  const data = useSWR(
    url,
    async (url) => {
      const response = await API.get(url, {
        headers: {
          Accept: `version=${version}`
        }
      });

      return humps(response.data);
    },
    options
  );

  return { ...data };
}
