import React from 'react';

import FingerSVG from './images/finger.svg';

import { Container, Main, Title } from './styles';

const RememberIt = () => (
  <Main>
    <Container>
      <img src={FingerSVG} alt="Lembre-se" />
      <div>
        <Title>Lembre-se</Title>
        <p>
          Antecipando automaticamente ou de forma pontual, você sempre irá
          economizar pagando seus fornecedores com a Blu. <br />
          Escolhendo a opção de pagamento <b> PagBlu </b>, você pode nunca mais
          pagar taxa de antecipação!
        </p>
      </div>
    </Container>
  </Main>
);

export default RememberIt;
