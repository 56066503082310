import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@useblu/ocean-react';
import StyledTextField from './styles';

export default function TextField({ field, form, checkboxMargin, ...props }) {
  return (
    <StyledTextField checkboxMargin={checkboxMargin}>
      <Input {...field} {...props} />
    </StyledTextField>
  );
}

// TextInput.propTypes = {
//   id: PropTypes.string,
//   label:PropTypes.string,
//   name:PropTypes.string,
//   type:PropTypes.string,
//   position:PropTypes.string,
//   helperText:PropTypes.string,
//   adornment:PropTypes.string,
//   placeholder:PropTypes.string,
// }

// TextInput.defaultProps = {
//   id: "input-value",
//   label:"label",
//   name:"name",
//   type:"text",
//   position:"left",
//   helperText:"helperText",
//   adornment: "",
//   placeholder:"",
// }
