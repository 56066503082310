import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

const FilterTabLink = ({
  allPath,
  pendingPath,
  pendingCount,
  scheduledPath,
  scheduledCount,
  paidPath,
  showPaid,
  active
}) => (
  <div className="statement__filter-link-container">
    <button type="button" className="button button--filter" disabled>
      <i className="ic ic-filter" />
      Filtrar
    </button>
    <div className="statement__filter-tab-link-container">
      {!isEmpty(allPath) && (
        <>
          <span className="statement__filter-pending-separator display-web">
            |
          </span>
          <a href={allPath} className="all-transactions-link">
            <span className={active === 'all' ? 'active' : ''}>Todas</span>
          </a>
        </>
      )}
      {!isEmpty(pendingPath) && pendingCount > 0 && (
        <>
          <span className="statement__filter-pending-separator">&nbsp;</span>
          <a
            href={pendingPath}
            className={`statement__filter-pending-link ${
              active === 'pending' ? 'active' : ''
            }`}
          >
            <span className="pending-link-description">Pendentes</span>
            <span className="pending-link-counter">{pendingCount}</span>
          </a>
        </>
      )}
      {!isEmpty(scheduledPath) && scheduledCount > 0 && (
        <>
          <span className="statement__filter-pending-separator">&nbsp;</span>
          <a
            href={scheduledPath}
            className={`statement__filter-pending-link ${
              active === 'scheduled' ? 'active' : ''
            }`}
          >
            <span className="pending-link-description">Agendadas</span>
            <span className="pending-link-counter scheduled">
              {scheduledCount}
            </span>
          </a>
        </>
      )}
      {!isEmpty(paidPath) && showPaid && (
        <>
          <span className="statement__filter-pending-separator">&nbsp;</span>
          <a href={paidPath} className="all-transactions-link">
            <span className={active === 'paid' ? 'active' : ''}>Pagas</span>
          </a>
        </>
      )}
    </div>
  </div>
);

FilterTabLink.propTypes = {
  active: PropTypes.string.isRequired,
  allPath: PropTypes.string.isRequired,
  pendingPath: PropTypes.string,
  pendingCount: PropTypes.number,
  scheduledPath: PropTypes.string,
  scheduledCount: PropTypes.number,
  paidPath: PropTypes.string,
  showPaid: PropTypes.bool
};

FilterTabLink.defaultProps = {
  pendingPath: '',
  pendingCount: 0,
  scheduledPath: '',
  scheduledCount: 0,
  paidPath: '',
  showPaid: false
};

export default FilterTabLink;
