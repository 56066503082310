import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types, Creators } = createActions({
  getPaymentConfirmationRequest: ['uuid', 'loading'],
  getPaymentConfirmationSuccess: ['data'],
  getPaymentConfirmationFailure: null,
  setPaymentConfirmationRequest: ['clientUuid', 'ids'],
  setPaymentConfirmationSuccess: [
    'transactionsWithSameOwnership',
    'transactionsWithoutSameOwnership'
  ],
  setPaymentConfirmationFailure: null,
  cancelPaymentConfirmationRequest: ['uuid', 'ids'],
  cancelPaymentConfirmationSuccess: null,
  cancelPaymentConfirmationFailure: null,
  setPaymentConfirmationInDateRequest: ['uuid', 'transactions'],
  setPaymentConfirmationInDateSuccess: [
    'transactionsWithSameOwnership',
    'transactionsWithoutSameOwnership'
  ],
  setPaymentConfirmationInDateFailure: null
});

export { Types, Creators };
export default Creators;

/* Initial State */
const INITIAL_STATE = {
  loading: true,
  loadingConfirm: false,
  loadingCancel: false,
  redirect: false,
  transactionsWithSameOwnership: [],
  transactionsWithoutSameOwnership: [],
  data: []
};

/* Reducers */
export const request = (state, action) => ({
  ...state,
  loading: action.loading,
  redirect: false
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const getPaymentConfirmationSuccess = (state, action) => ({
  ...state,
  loading: false,
  loadingConfirm: false,
  loadingCancel: false,
  data: action.data
});

export const setPaymentConfirmationRequest = (state) => ({
  ...state,
  loadingConfirm: true,
  redirect: false
});

export const setPaymentConfirmationSuccess = (state, action) => ({
  ...INITIAL_STATE,
  transactionsWithSameOwnership: action.transactionsWithSameOwnership,
  transactionsWithoutSameOwnership: action.transactionsWithoutSameOwnership,
  redirect: true
});

export const setPaymentConfirmationFailure = (state) => ({
  ...state,
  loadingConfirm: false,
  error: true,
  redirect: false
});

export const cancelPaymentConfirmationRequest = (state) => ({
  ...state,
  loadingCancel: true,
  redirect: false
});

export const cancelPaymentConfirmationSuccess = (state) => ({
  ...state,
  loadingCancel: false,
  redirect: true
});

export const cancelPaymentConfirmationFailure = (state) => ({
  ...state,
  loadingCancel: false,
  error: true,
  redirect: false
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAYMENT_CONFIRMATION_REQUEST]: request,
  [Types.GET_PAYMENT_CONFIRMATION_SUCCESS]: getPaymentConfirmationSuccess,
  [Types.GET_PAYMENT_CONFIRMATION_FAILURE]: failure,
  [Types.SET_PAYMENT_CONFIRMATION_REQUEST]: setPaymentConfirmationRequest,
  [Types.SET_PAYMENT_CONFIRMATION_SUCCESS]: setPaymentConfirmationSuccess,
  [Types.SET_PAYMENT_CONFIRMATION_FAILURE]: setPaymentConfirmationFailure,
  [Types.CANCEL_PAYMENT_CONFIRMATION_REQUEST]: cancelPaymentConfirmationRequest,
  [Types.CANCEL_PAYMENT_CONFIRMATION_SUCCESS]: cancelPaymentConfirmationSuccess,
  [Types.CANCEL_PAYMENT_CONFIRMATION_FAILURE]: cancelPaymentConfirmationFailure,
  [Types.SET_PAYMENT_CONFIRMATION_IN_DATE_REQUEST]:
    setPaymentConfirmationRequest,
  [Types.SET_PAYMENT_CONFIRMATION_IN_DATE_SUCCESS]:
    setPaymentConfirmationSuccess,
  [Types.SET_PAYMENT_CONFIRMATION_IN_DATE_FAILURE]:
    setPaymentConfirmationFailure
});
