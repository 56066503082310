import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { railsContextSelector } from 'selectors/rails-context';
import { useSelector } from 'react-redux';

import { MobileView } from 'react-device-detect';
import { Button, Modal } from '@useblu/ocean-react';

import {
  getClientTaxes,
  getAtecipationTax
} from '../../../../../api/contract-form';

import TaxPlans from '../TaxPlans';

import { Container, ModalStyle } from './styles';

const TaxPlansModal = ({ isVisible, toggleModal }) => {
  const [clientTaxTable, setClientTaxTable] = useState();
  const [antecipationTaxes, setAntecipationTaxes] = useState();
  const handleConfirm = () => toggleModal(false);
  const { clientId } = useSelector(railsContextSelector);

  function getTaxesTable() {
    return getClientTaxes(clientId)
      .then((response) => {
        setClientTaxTable(response.data);
      })
      .catch((error) => {
        console.log(error);
        throw new Error();
      });
  }

  function getTax() {
    return getAtecipationTax(clientId)
      .then((response) => {
        setAntecipationTaxes(response.data.data.client.taxPlan);
      })
      .catch((error) => {
        console.log(error);

        throw new Error();
      });
  }

  useEffect(() => {
    getTaxesTable();
    getTax();
  }, []);

  return (
    <>
      <Modal
        isOpen={isVisible}
        ariaHideApp={false}
        portalClassName="ocean-iso"
        onRequestClose={handleConfirm}
        shouldCloseOnOverlayClick={false}
        maxWidth="md"
      >
        <Container>
          <TaxPlans
            title="Taxas e Valores"
            taxTable={clientTaxTable || {}}
            antecipationTaxes={antecipationTaxes || {}}
          />
        </Container>
        <MobileView>
          <Button
            size="md"
            blocked
            variant="secondary"
            onClick={handleConfirm}
            type="button"
          >
            Fechar
          </Button>
        </MobileView>
      </Modal>
      <ModalStyle />
    </>
  );
};
TaxPlansModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default TaxPlansModal;
