import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types, Creators } = createActions({
  paymentSelectedRequest: ['uuid'],
  paymentSelectedSuccess: ['data'],
  paymentSelectedFailure: ['message'],
  paymentSelectedApproveRequest: ['uuid', 'kind'],
  paymentSelectedApproveSuccess: ['status'],
  paymentSelectedApproveFailure: ['message'],
  paymentSelectedApprovePartialRequest: ['uuid'],
  paymentSelectedApprovePartialSuccess: ['values'],
  paymentSelectedApprovePartialFailure: ['message'],
  paymentSelectedDeclineRequest: ['uuid'],
  paymentSelectedDeclineSuccess: null,
  paymentSelectedDeclineFailure: ['message'],
  paymentSelectedCancelRequest: ['uuid'],
  paymentSelectedCancelSuccess: null,
  paymentSelectedCancelFailure: ['message'],
  paymentSelectedClearError: null
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  paymentSelected: {},
  loading: false,
  error: false,
  errorMessage: '',
  errorOrigin: '',
  approved: false,
  approvedPartialy: false,
  declined: false,
  canceled: false,
  loadingActions: false
};

/* Reducers */

export const request = (state) => ({
  ...state,
  paymentSelected: {},
  loading: true,
  approved: false,
  approvedPartialy: false,
  declined: false,
  canceled: false
});

export const success = (state, action) => ({
  ...state,
  paymentSelected: action.data,
  loading: false,
  error: false
});

export const failure = (state, action) => ({
  ...state,
  loading: false,
  error: true,
  errorMessage: action.message,
  errorOrigin: 'fetch'
});

export const approveRequest = (state) => ({
  ...state,
  loadingActions: true
});

export const approveSuccess = (state, action) => ({
  ...state,
  paymentSelected: {
    ...state.paymentSelected,
    status: action.status
  },
  approved: true,
  loadingActions: false
});

export const approveFailure = (state, action) => ({
  ...state,
  loadingActions: false,
  error: true,
  errorMessage: action.message,
  errorOrigin: 'approve'
});

export const approvePartialRequest = (state) => ({
  ...state,
  loadingActions: true
});

export const approvePartialSuccess = (state, action) => ({
  ...state,
  paymentSelected: {
    ...state.paymentSelected,
    ...action.values
  },
  approvedPartialy: true,
  loadingActions: false
});

export const approvePartialFailure = (state, action) => ({
  ...state,
  loadingActions: false,
  error: true,
  errorMessage: action.message,
  errorOrigin: 'approvePartial'
});

export const declineRequest = (state) => ({
  ...state,
  loadingActions: true
});

export const declineSuccess = (state, action) => ({
  ...state,
  paymentSelected: {
    ...state.paymentSelected,
    status: 'rejected'
  },
  declined: true,
  loadingActions: false
});

export const declineFailure = (state, action) => ({
  ...state,
  loadingActions: false,
  error: true,
  errorMessage: action.message,
  errorOrigin: 'decline'
});

export const cancelRequest = (state) => ({
  ...state,
  loadingActions: true
});

export const cancelSuccess = (state) => ({
  ...state,
  canceled: true,
  loadingActions: false
});

export const cancelFailure = (state, action) => ({
  ...state,
  loadingActions: false,
  error: true,
  errorMessage: action.message,
  errorOrigin: 'cancel'
});

export const clearError = (state) => ({
  ...state,
  error: false,
  errorMessage: ''
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PAYMENT_SELECTED_REQUEST]: request,
  [Types.PAYMENT_SELECTED_SUCCESS]: success,
  [Types.PAYMENT_SELECTED_FAILURE]: failure,
  [Types.PAYMENT_SELECTED_APPROVE_REQUEST]: approveRequest,
  [Types.PAYMENT_SELECTED_APPROVE_SUCCESS]: approveSuccess,
  [Types.PAYMENT_SELECTED_APPROVE_FAILURE]: approveFailure,
  [Types.PAYMENT_SELECTED_APPROVE_PARTIAL_REQUEST]: approvePartialRequest,
  [Types.PAYMENT_SELECTED_APPROVE_PARTIAL_SUCCESS]: approvePartialSuccess,
  [Types.PAYMENT_SELECTED_APPROVE_PARTIAL_FAILURE]: approvePartialFailure,
  [Types.PAYMENT_SELECTED_DECLINE_REQUEST]: declineRequest,
  [Types.PAYMENT_SELECTED_DECLINE_SUCCESS]: declineSuccess,
  [Types.PAYMENT_SELECTED_DECLINE_FAILURE]: declineFailure,
  [Types.PAYMENT_SELECTED_CANCEL_REQUEST]: cancelRequest,
  [Types.PAYMENT_SELECTED_CANCEL_SUCCESS]: cancelSuccess,
  [Types.PAYMENT_SELECTED_CANCEL_FAILURE]: cancelFailure,
  [Types.PAYMENT_SELECTED_CLEAR_ERROR]: clearError
});
