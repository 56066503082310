import React from 'react';
import { WarningContainer } from 'components/Devise/devise.styles';
import { Typography, Button } from '@useblu/ocean-react';
import DeviseScreenLayout from '../DeviseScreenLayout';
import infoWaiting from '../../images/ilustra-info-validation.svg';

const ProcessedCafStatusWarning = () => {
  return (
    <DeviseScreenLayout className="adjust-logo">
      <WarningContainer $withCaptionOnMobile>
        <img src={infoWaiting} alt="info waiting" />
        <Typography variant="heading2">Informações enviadas</Typography>
        <Typography variant="paragraph">
          A análise dos dados será concluída em aproximadamente 5 minutos. Você
          receberá uma notificação e um email quando estiver tudo certo.
        </Typography>

        <Button
          variant="primary"
          onClick={() => window.location.assign('/users/sign_in')}
        >
          Entendi
        </Button>
      </WarningContainer>
    </DeviseScreenLayout>
  );
};
export default ProcessedCafStatusWarning;
