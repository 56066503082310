import React from 'react';
import { WarningContainer } from 'components/Devise/devise.styles';
import { Button, Typography } from '@useblu/ocean-react';
import { useMedia } from 'react-use';
import ilustraHourglass from '../../images/ilustra-hourglass.svg';
import DeviseScreenLayout from '../DeviseScreenLayout';

const AccountNotReadyWarning = () => {
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <DeviseScreenLayout className="adjust-logo">
      <WarningContainer $withCaption>
        <img src={ilustraHourglass} alt="ilustra-hourglass" />
        <Typography variant="heading2">
          A conta ainda não está pronta para ser utilizada
        </Typography>
        <Typography variant="paragraph">Tente novamente mais tarde.</Typography>
        <Typography variant="caption">
          Se o problema persistir entre em contato através do canal direto do
          lojista pelo telefone 3003 0807 (capitais e regiões metropolitanas) ou
          no 0800 326 0807 (demais regiões).
        </Typography>
        {isMobile && (
          <Button
            variant="primary"
            onClick={() => window.location.assign('/users/sign_in')}
          >
            Voltar ao início
          </Button>
        )}
      </WarningContainer>
    </DeviseScreenLayout>
  );
};

export default AccountNotReadyWarning;
