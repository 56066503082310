import styled from 'styled-components';

import {
  fontFamilyBase,
  fontSizeXs,
  colorInterfaceDarkDown
} from '@useblu/ocean-tokens/web/tokens';

import { Devices } from 'utils/getDeviceMedia';

export const MultiAcquirerOptinContainer = styled.div`
  width: 736px;
  height: 550px;
  display: flex;
  flex-direction: column;

  .padding {
    @media ${Devices.mobile} {
      padding: 0 16px;
    }
  }

  @media ${Devices.tablet} {
    width: 100%;
    height: 100%;
  }

  @media ${Devices.mobile} {
    width: 100%;
    height: 100%;
  }
`;

export const MultiAcquirerOptinTitle = styled.h2`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  font-weight: 400;
  color: ${colorInterfaceDarkDown};
  line-height: 24px;
  flex: 1;
`;

export const SelectWrapper = styled.div`
  .ods-select__root {
    width: 100%;
    min-width: 0px;
  }
`;
