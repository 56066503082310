/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import classNames from 'classnames';
import maskInput, { maskComplete, maskValue } from 'utils/masks';

export default class RenderTextAreaField extends Component {
  componentDidMount() {
    const { mask } = this.props;

    if (mask) {
      maskInput(this.input, mask);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { autofocus } = newProps;

    if (autofocus) {
      this.input.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      mask,
      input: { value },
      onMaskComplete
    } = this.props;

    if (mask !== prevProps.mask) {
      maskInput(this.input, mask);
    }

    if (
      onMaskComplete &&
      maskComplete(value, mask) &&
      value !== prevProps.input.value
    ) {
      onMaskComplete();
    }
  }

  maskValue() {
    const {
      mask,
      defaultValue,
      input: { value }
    } = this.props;

    if (mask && defaultValue && defaultValue !== value) {
      return maskValue(defaultValue, mask);
    }

    if (!mask && defaultValue && defaultValue !== value) {
      return defaultValue;
    }

    return value;
  }

  render() {
    const {
      input,
      label,
      id,
      placeholder,
      meta: { touched, error },
      hint,
      children,
      extraClass,
      prefix,
      disabled,
      className,
      inputMode
    } = this.props;

    return (
      <div
        className={classNames({
          'form-controls': true,
          [extraClass]: extraClass,
          'is-disabled': disabled
        })}
      >
        <label htmlFor={id}>{label}</label>
        <div className={classNames({ 'field-with-errors': touched && error })}>
          {prefix && <span className="form-prefix">{prefix}</span>}
          <textarea
            ref={(i) => {
              this.input = i;
            }}
            id={id}
            {...input}
            inputMode={inputMode}
            placeholder={placeholder}
            disabled={disabled}
            value={this.maskValue()}
            className={className}
          />
          {children}
          {hint && (
            <span className="hint">
              <small>{hint}</small>
            </span>
          )}
          {touched && error && <span className="field-error">{error}</span>}
        </div>
      </div>
    );
  }
}

RenderTextAreaField.propTypes = {
  mask: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  extraClass: PropTypes.string,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  onMaskComplete: PropTypes.func,
  hint: PropTypes.string,
  children: PropTypes.object,
  className: PropTypes.string,
  inputMode: PropTypes.string
};

RenderTextAreaField.defaultProps = {
  mask: '',
  input: {},
  meta: {},
  label: '',
  id: '',
  placeholder: '',
  defaultValue: '',
  extraClass: '',
  prefix: '',
  disabled: false,
  autofocus: false,
  onMaskComplete: null,
  hint: '',
  children: null,
  className: '',
  inputMode: ''
};
