import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  clientPaymentMethodRequest: ['clientId'],
  clientPaymentMethodSuccess: ['data'],
  clientPaymentMethodFailure: ['error'],
  sendClientPaymentMethodRequest: ['userId', 'paymentMethod', 'bankAccountId'],
  sendClientPaymentMethodFailure: ['error'],
  clientPaymentMethodClearError: [null]
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  automaticBankAccountId: null,
  paymentMethod: null,
  loading: false,
  error: null
};

export const clientPaymentMethodRequest = (state) => ({
  ...state,
  loading: true
});

export const clientPaymentMethodSuccess = (state, action) => {
  const { automaticBankAccountId, paymentMethod } = action.data;
  return {
    ...state,
    automaticBankAccountId,
    paymentMethod,
    loading: false,
    error: null
  };
};

export const clientPaymentMethodFailure = (state, action) => ({
  ...state,
  loading: false,
  error: action.error
});

export const sendClientPaymentMethodRequest = (state) => ({
  ...state,
  loading: true
});

export const clientPaymentMethodClearError = (state) => ({
  ...state,
  error: null
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLIENT_PAYMENT_METHOD_REQUEST]: clientPaymentMethodRequest,
  [Types.CLIENT_PAYMENT_METHOD_SUCCESS]: clientPaymentMethodSuccess,
  [Types.CLIENT_PAYMENT_METHOD_FAILURE]: clientPaymentMethodFailure,
  [Types.SEND_CLIENT_PAYMENT_METHOD_REQUEST]: sendClientPaymentMethodRequest,
  [Types.SEND_CLIENT_PAYMENT_METHOD_FAILURE]: clientPaymentMethodFailure,
  [Types.CLIENT_PAYMENT_METHOD_CLEAR_ERROR]: clientPaymentMethodClearError
});
