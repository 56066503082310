import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { railsContextSelector } from 'selectors/rails-context';
import { selectors } from 'store/ducks/paymentCollectionDetails';

import CancelButton from './CancelButton';

const MainActions = ({ extraActions }) => {
  const { clientId } = useSelector(railsContextSelector);
  const {
    uuid,
    parentUuid,
    childrenUuid,
    status,
    kind,
    outflowUuid,
    bankBilletCanCancel,
  } = useSelector(selectors.getDetailsData);

  const isBankBillet = kind?.name === 'payment_collection_bank_billet';

  return (
    <div className="statement-detail__actions statement-detail__actions--with-react">
      {status?.name === 'paid' && isBankBillet && (
        <a
          className="button button--secondary"
          target="_blank"
          rel="noreferrer"
          href={`/lojas/${clientId}/transacoes/${outflowUuid}/recibo`}
        >
          Baixar comprovante
        </a>
      )}

      {extraActions}

      {parentUuid && (
        <a
          className="button button--secondary"
          href={`/lojas/${clientId}/cobrancas/${parentUuid}`}
        >
          Cobrança original
        </a>
      )}

      {childrenUuid && (
        <a
          className="button button--secondary"
          href={`/lojas/${clientId}/cobrancas/${childrenUuid}`}
        >
          Ver a nova cobrança do valor restante
        </a>
      )}

      {isBankBillet && bankBilletCanCancel && <CancelButton uuid={uuid} />}
    </div>
  );
};

MainActions.defaultProps = {
  extraActions: null,
};

MainActions.propTypes = {
  extraActions: PropTypes.node,
};

export default MainActions;
