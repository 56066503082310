import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loading from 'react-loading';
import Modal from 'react-modal';
import { Field } from 'redux-form';

import RenderDropdownListCallback from 'components/Form/RenderDropdownListCallback';
import RenderField from 'components/Form/RenderField';

class QsaAccountsModal extends Component {
  handleInputChange(e, field) {
    const { partner } = this.props;
    partner[field] = e.currentTarget.value;
    this.props.setPartner(partner);
  }

  handlePositionChange(position) {
    const { partner } = this.props;
    if (!partner.companyAssociates) {
      partner.companyAssociates = [{}];
    }
    partner.companyAssociates[0].position = position;
    this.props.setPartner(partner);
  }

  partnerPositionOptions() {
    return [
      { key: 'Sócio', label: 'Sócio' },
      { key: 'Sócio administrador', label: 'Sócio administrador' },
      { key: 'Administrador', label: 'Administrador' },
      { key: 'Operador', label: 'Operador' }
    ];
  }

  renderModal() {
    const { partner, invalid, errors } = this.props;

    const classes = {
      afterOpen: 'modal--open',
      base: 'modal qsa-accounts--modal',
      beforeClose: 'modal qsa-accounts--modal'
    };

    const overlayClasses = {
      afterOpen: 'modal-overlay--open',
      base: 'modal-overlay',
      beforeClose: 'modal-overlay'
    };

    let cpfAdditionalProps = {};

    if (errors && errors.cpfCnpj) {
      cpfAdditionalProps = {
        meta: {
          error: errors.cpfCnpj,
          touched: true
        }
      };
    }

    return (
      <Modal
        isOpen={this.props.isOpen}
        contentLabel="Modal"
        className={classes}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
      >
        <h3 className="page-subtitle">
          <i className="fa fa-user" /> Edição de sócio
        </h3>
        <div className="modal-container">
          <div className="modal-description">
            <div className="form-row">
              <Field
                type="text"
                required
                name="partner.name"
                component={RenderField}
                label="Nome"
                onChange={(e) => this.handleInputChange(e, 'name')}
              />
            </div>
            <div className="form-row">
              <Field
                type="text"
                required
                name="partner.cpfCnpj"
                component={RenderField}
                mask="cpf_cnpj"
                label="CPF/CNPJ"
                onChange={(e) => this.handleInputChange(e, 'cpfCnpj')}
                {...cpfAdditionalProps}
              />
            </div>
            <div className="form-row">
              <Field
                name="partner.position"
                component={RenderDropdownListCallback}
                options={this.partnerPositionOptions()}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ key }) => key}
                simpleValue
                selectValue={
                  partner.companyAssociates &&
                  partner.companyAssociates[0].position
                }
                label="Função"
                placeholder="Escolha uma opção"
                handleChange={(position) => this.handlePositionChange(position)}
              />
            </div>
          </div>
        </div>
        <div className="modal-action">
          <button
            className="button button--secondary"
            onClick={() => this.props.onClose()}
          >
            Cancelar
          </button>
          <button
            className="button button--primary"
            onClick={() => this.props.onSave()}
            disabled={invalid}
          >
            Salvar
            {this.props.loading && (
              <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                <Loading
                  type="spinningBubbles"
                  color="#ffffff"
                  width="15px"
                  height="15px"
                  delay={0}
                />
              </div>
            )}
          </button>
        </div>
      </Modal>
    );
  }

  render() {
    return (
      <div>
        <div className="flex-container">{this.renderModal()}</div>
      </div>
    );
  }
}

QsaAccountsModal.propTypes = {
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  invalid: PropTypes.bool,
  partner: PropTypes.object.isRequired,
  setPartner: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errors: PropTypes.shape({})
};

QsaAccountsModal.defaultProps = {
  isOpen: false,
  loading: false,
  invalid: false,
  errors: {}
};

export default QsaAccountsModal;
