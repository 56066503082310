import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Field } from 'redux-form';
import Loading from 'react-loading';

import searchAddress from 'actions/via-cep';
import RenderField from 'components/Form/RenderField';

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = { address: this.props.address };
    this.disabledFields = false;
  }

  componentDidMount() {
    this.toogleAddressForm();
  }

  componentDidUpdate(prevProps) {
    const { address } = this.props;

    if (prevProps.address !== address) {
      const addressReseted = _.defaults(address, {
        street: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        number: '',
        zipcode: '',
      });

      this.setAddressToState(addressReseted);

      setTimeout(() => {
        this.toogleAddressForm();
      }, 1);
    }
    this.handlerAddressField = this.handlerAddressField.bind(this);
  }

  setAddressToState(addressReseted) {
    this.setState({
      address: addressReseted,
      disabledFields: this.state.disabledFields,
    });
  }

  toogleAddressForm() {
    const { name } = this.props;
    const addressIsLoading = !$(this.refs[`${name}FormLoading`]).is(':visible');

    if (
      this.state.address.zipcode &&
      this.state.address.zipcode.replace('-', '').length == 8 &&
      addressIsLoading
    ) {
      $(this.refs[`${name}FormToggle`]).show();
    } else {
      $(this.refs[`${name}FormToggle`]).hide();
    }
  }

  updateState(data) {
    this.setState({
      address: {
        zipcode: data.cep || data.zipcode || '',
        number: data.numero || data.number || '',
        street: data.logradouro || data.street || '',
        neighborhood: data.bairro || data.neighborhood || '',
        city: data.localidade || data.city || '',
        state: data.uf || data.state || '',
      },
      disabledFields: this.disabledFields,
    });

    this.props.setAddressToReducer(this.state.address);
  }

  handlerAddressField(e, fieldName) {
    const { address } = this.state;
    address[fieldName] = e.target.value;

    this.updateState(address);
  }

  handlerEmptyAddressField(data) {
    Object.keys(data).forEach((key) => {
      if ((key === 'logradouro' || key === 'bairro') && data[key] === '') {
        this.disabledFields = false;
      }
    });
  }

  handlerZipCode(e) {
    const cep = e.target.value;
    const { name } = this.props;

    this.handlerAddressField(e, 'zipcode');

    if (cep.length === 9) {
      searchAddress(cep).then((response) => {
        this.disabledFields = !response.data.erro;
        this.handlerEmptyAddressField(response.data);
        this.updateState(response.data);
        $(this.refs[`${name}FormLoading`]).hide();
        this.toogleAddressForm();

        if (response.data.erro) {
          this.disabledFields = false;
          this.updateState({ zipcode: cep });
          document.querySelector(`input[name='${name}[street]']`)?.focus();
        } else {
          document.querySelector(`input[name='${name}[number]']`)?.focus();
        }
      });
    } else if (cep.length >= 0) {
      $(this.refs[`${name}FormLoading`]).show();
      this.toogleAddressForm();
    }
  }

  renderAddressField(fieldName, text) {
    const { name } = this.props;
    const { address = {}, disabledFields } = this.state;

    return (
      <div className={`form-controls string required address_${fieldName}`}>
        <label className="string required" htmlFor={`address_${fieldName}`}>
          {text}
        </label>
        <div>
          <Field
            className="string required"
            type="text"
            defaultValue={address[fieldName] || ''}
            name={`${name}[${fieldName}]`}
            disabled={
              disabledFields &&
              fieldName !== 'number' &&
              fieldName !== 'complement'
            }
            onChange={(e) => this.handlerAddressField(e, fieldName)}
            component={RenderField}
            ref={`${fieldName}Input`}
          />
        </div>
      </div>
    );
  }

  renderAddress() {
    const { name, showForm } = this.props;
    const { address } = this.state;

    return (
      <div style={{ display: showForm ? 'block' : 'none' }}>
        <div className="form-row">
          <div className="form-controls string required address_zipcode">
            <label className="string required" htmlFor="address_zipcode">
              CEP
            </label>
            <div>
              <Field
                className="string required"
                type="text"
                name={`${name}[zipcode]`}
                ref="zipcodeInput"
                component={RenderField}
                mask="zipcode"
                onChange={(e) => this.handlerZipCode(e)}
              />
            </div>
          </div>
        </div>

        <div
          ref={`${name}FormLoading`}
          className="loading"
          style={{
            display: 'none',
            width: 50,
            height: 50,
            margin: '100px 50%',
          }}
        >
          <Loading
            type="spinningBubbles"
            color="#e1e5ed"
            width={35}
            delay={0}
            style={{ margin: '0 auto' }}
          />
        </div>

        <div ref={`${name}FormToggle`}>
          <div className="form-row">
            {this.renderAddressField('street', 'Rua')}
          </div>
          <div className="form-row">
            {this.renderAddressField('number', 'Número')}
            {this.renderAddressField('complement', 'Complemento')}
          </div>
          <div className="form-row">
            {this.renderAddressField('neighborhood', 'Bairro')}
          </div>
          <div className="form-row">
            {this.renderAddressField('city', 'Cidade')}
            {this.renderAddressField('state', 'Estado (UF)')}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div className="flex-container">{this.renderAddress()}</div>;
  }
}

Address.propTypes = {
  setAddressToReducer: PropTypes.func,
  address: PropTypes.object,
  name: PropTypes.string.isRequired,
  showForm: PropTypes.bool,
};

export default Address;
