import React, { useEffect, useState } from 'react';
import { sendScreenEvent } from '@useblu/blu-lytics';
import { Alert, Checkbox } from '@useblu/ocean-react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Loading from 'react-loading';

import isValidCPF from 'validations/ValidateCpf';
import isValidName from 'validations/ValidateName';

import { editLeadData } from '../services/signUp';

import {
  CustomInput,
  BtnSubmit,
  WrapBtns,
  WrapInput,
  CustomForm,
  AlertWrapper,
} from '../devise.styles';

const SocialForm = ({ onSuccess, onBack, formData }) => {
  const [hasChangeValidation, enableChangeValidation] = useState(false);
  const [hasError, setHasError] = useState({
    active: false,
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isTermsError, setTermsError] = useState(false);
  const sessionData = JSON.parse(
    sessionStorage.getItem('auto-signup-anonymus')
  );

  const bluContractAndLgpdTerms = (
    <>
      Li e concordo com os{' '}
      <a
        href={`/contrato-prestacao-de-servicos.pdf?tipo-cliente=${
          formData.distributor === false ? 'lojista' : 'fornecedor'
        }`}
        target="_blank"
        rel="noreferrer"
      >
        Termos e Condições
      </a>{' '}
      e com a{' '}
      <a
        href="https://economize.blu.com.br/politica-de-privacidade"
        target="_blank"
        rel="noreferrer"
      >
        Política de Privacidade
      </a>{' '}
      da Blu.
    </>
  );

  const handleCheckbox = (e) => {
    if (e.target.checked === true) {
      setTermsError(false);
    }
    setIsAccepted(e.target.checked);
  };

  const formikProps = {
    initialValues: formData,
    validateOnChange: hasChangeValidation,
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Este campo é obrigatório.')
        .test({
          name: 'isValidName',
          message: 'Este Nome não é válido.',
          test: (value) => isValidName(value),
        }),
      cpf: Yup.string()
        .required('Este campo é obrigatório.')
        .test({
          name: 'isValidCpf',
          message: 'Este CPF não é válido',
          test: (value) => isValidCPF(value?.replace(/\D/g, '')),
        }),
      phone: Yup.string()
        .required('Este campo é obrigatório.')
        .matches(/\(\d{2}\) \d{5}-\d{4}/, 'Este Telefone não é válido.'),
    }),
    onSubmit: async ({ cpf, name, phone }, { setErrors, validateForm }) => {
      if (!validateForm()) return;

      if (!isAccepted) {
        setTermsError(true);
        return;
      }

      setErrors({});
      setLoading(true);

      editLeadData({
        leadId: sessionData?.leadId,
        name,
        cpf,
        phone,
        contractBluAccepted: isAccepted,
      })
        .then(() => {
          setLoading(false);

          sessionStorage.setItem(
            'auto-signup-anonymus',
            JSON.stringify({
              ...sessionData,
              cpf,
              name,
              phone,
            })
          );

          onSuccess({
            formData: { ...formData, cpf, name, phone, isAccepted },
          });
        })
        .catch((error) => {
          setLoading(false);

          setHasError((prevState) => ({
            ...prevState,
            active: true,
            message: error?.response?.data?.message || error.message,
          }));
        });
    },
  };

  useEffect(() => {
    sendScreenEvent('register_person_view');
  }, []);

  return (
    <>
      {hasError.active && (
        <AlertWrapper>
          <Alert type="warning">{hasError.message}</Alert>
        </AlertWrapper>
      )}

      <Formik {...formikProps}>
        {({ errors, values, touched, handleSubmit }) => (
          <CustomForm
            onSubmit={(e) => {
              enableChangeValidation(true);
              handleSubmit(e);
            }}
          >
            <WrapInput>
              <CustomInput
                id="name"
                type="name"
                name="name"
                label="Nome completo do Representante Legal"
                placeholder="Como consta no seu documento"
                className={errors.name && touched.name ? 'error' : ''}
                value={values.name}
                tabIndex={-1}
              />
              <ErrorMessage
                name="name"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </WrapInput>

            <WrapInput>
              <CustomInput
                id="cpf"
                mask="cpf"
                name="cpf"
                label="CPF do Representante Legal"
                placeholder="000.000.000-00"
                className={errors.cpf && touched.cpf ? 'error' : ''}
                value={values.cpf}
                tabIndex={-1}
              />
              <ErrorMessage
                name="cpf"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </WrapInput>

            <WrapInput>
              <CustomInput
                id="phone"
                mask="phone"
                name="phone"
                label="Celular com DDD do Representante Legal"
                placeholder="(00) 90000-0000"
                helperText="Este número será usado para validar as suas transações"
                className={errors.phone && touched.phone ? 'error' : ''}
                value={values.phone}
                tabIndex={-1}
              />
              <ErrorMessage
                name="phone"
                render={(msg) => <span className="error-msg">{msg}</span>}
              />
            </WrapInput>

            <WrapInput>
              <Checkbox
                id="termsAndConditions"
                name="termsAndConditions"
                checked={isAccepted}
                onChange={handleCheckbox}
                label={bluContractAndLgpdTerms}
                error={isTermsError}
                role="checkbox"
              />
              {isTermsError && (
                <span className="error-msg">
                  Aceite nossos termos para continuar.
                </span>
              )}
            </WrapInput>

            <WrapBtns rowReverse formPadding className="without-margin">
              <BtnSubmit
                type="submit"
                className="continuar_autocadastro_dados_pessoais"
                aria-label="continuar_autocadastro_dados_pessoais"
                tabIndex={-1}
              >
                {loading ? (
                  <Loading
                    type="spinningBubbles"
                    color="#e1e5ed"
                    delay={0}
                    height={35}
                    width={35}
                  />
                ) : (
                  'Continuar'
                )}
              </BtnSubmit>
              <BtnSubmit
                aria-label="voltar_autocadastro_dados_pessoais"
                onClick={(e) => {
                  e.preventDefault();
                  onBack();
                }}
                variant="secondary"
                disabled={loading}
                tabIndex={-1}
              >
                Voltar
              </BtnSubmit>
            </WrapBtns>
          </CustomForm>
        )}
      </Formik>
    </>
  );
};

export default SocialForm;
