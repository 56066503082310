import React from 'react';
import PropTypes from 'prop-types';

import { XCircle, CheckCircle } from '@useblu/ocean-icons-react';

const IconValidation = ({ state }) => {
  const color = () => {
    switch (state) {
      case 'invalid':
        return '#F5456E';
      case 'valid':
        return '#3DCC64';
      default:
        return '#D8DAE8';
    }
  };

  return state === 'valid' ? (
    <CheckCircle size={20} fill={color()} />
  ) : (
    <XCircle size={20} fill={color()} />
  );
};

IconValidation.propTypes = {
  state: PropTypes.string.isRequired
};

export default IconValidation;
