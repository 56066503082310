import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Value = styled.span`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  white-space: nowrap;
  font-size: 28px;
  font-weight: 100;
  height: 42px;
  line-height: 42px;
  text-align: left;
  color: #5a5a5a;
`;

export const ValuePositive = styled(Value)`
  color: #33c4d6;
`;

export const Container = ({ link, className, children }) =>
  link !== '#' ? (
    <a className={className} href={link}>
      {children}
    </a>
  ) : (
    <div className={className}>{children}</div>
  );

Container.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired
};
