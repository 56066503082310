import {
  getPaymentSelected,
  approvePayment as approvePaymentAPI,
  approvePaymentWithKind as approvePaymentWithKindAPI,
  approvePartialPayment as approvePartialPaymentAPI,
  declinePayment as declinePaymentAPI,
  cancelPayment as cancelPaymentAPI
} from 'api/payment-collection';

import { call, put, select } from 'redux-saga/effects';

import paymentSelectedActions from 'store/ducks/paymentSelected';
import pendentPaymentsActions from 'store/ducks/pendentPayments';

function* failureOperation(action, error) {
  let errorMessage = 'Ocorreu um erro nessa operação';
  if (Array.isArray(error) && error.length > 0) {
    errorMessage = error[0].message.split(': ').pop();
  }

  yield put(action(errorMessage));
}

function* loadPaymentSelected({ uuid }) {
  let paymentCollection;
  let error;

  try {
    yield put(pendentPaymentsActions.pendentPaymentsSelect(uuid));

    const clientId = yield select((state) => state.railsContext?.clientId);

    const response = yield call(getPaymentSelected, clientId, uuid);

    if (response.data.errors) throw response.data.errors;

    paymentCollection = response.data.data.paymentCollection;

    yield put(paymentSelectedActions.paymentSelectedSuccess(paymentCollection));
  } catch (err) {
    error = err;
    yield* failureOperation(paymentSelectedActions.paymentSelectedFailure, err);
  }
}

function* approvePayment({ uuid, kind }) {
  let error;

  try {
    const clientId = yield select((state) => state.railsContext?.clientId);

    let response;
    if (kind) {
      response = yield call(approvePaymentWithKindAPI, clientId, uuid, kind);
    } else {
      response = yield call(approvePaymentAPI, clientId, uuid);
    }

    if (response.data.errors) throw response.data.errors;

    if (response.data.data) {
      const { status } = response.data.data.approvePaymentCollectionV2
        ? response.data.data.approvePaymentCollectionV2
        : response.data.data.approvePaymentCollection;

      yield put(paymentSelectedActions.paymentSelectedApproveSuccess(status));

      if (status === 'paid') {
        yield put(pendentPaymentsActions.pendentPaymentsRemove(uuid));
      } else {
        yield put(
          pendentPaymentsActions.pendentPaymentsUpdateStatus(uuid, status)
        );
      }

      yield put(
        pendentPaymentsActions.pendentPaymentsUpdateContentType('approved')
      );
    }
  } catch (err) {
    error = err;
    yield* failureOperation(
      paymentSelectedActions.paymentSelectedApproveFailure,
      err
    );
  }

  try {
    const paymentCollection = yield select(
      (state) => state.paymentSelected.paymentSelected
    );
  } catch (error) {}
}

function* approvePartialPayment({ uuid }) {
  let error;

  try {
    const clientId = yield select((state) => state.railsContext?.clientId);

    const response = yield call(approvePartialPaymentAPI, clientId, uuid);

    if (response.data.errors) throw response.data.errors;

    if (response.data.data) {
      const {
        grossValue,
        netValue,
        valueRemainingToPay
      } = response.data.data.partialPayPaymentCollection;

      yield put(
        paymentSelectedActions.paymentSelectedApprovePartialSuccess({
          grossValue,
          netValue,
          valueRemainingToPay
        })
      );
      yield put(pendentPaymentsActions.pendentPaymentsRemove(uuid));
      yield put(
        pendentPaymentsActions.pendentPaymentsUpdateContentType(
          'partiallyApproved'
        )
      );
    }
  } catch (err) {
    error = err;
    yield* failureOperation(
      paymentSelectedActions.paymentSelectedApprovePartialFailure,
      err
    );
  }

  try {
    const paymentCollection = yield select(
      (state) => state.paymentSelected.paymentSelected
    );
  } catch (error) {}
}

function* declinePayment({ uuid }) {
  let error;
  try {
    const clientId = yield select((state) => state.railsContext?.clientId);

    const { data } = yield call(declinePaymentAPI, clientId, uuid);

    if (data.errors) throw data.errors;

    if (data.data) {
      yield put(paymentSelectedActions.paymentSelectedDeclineSuccess());
      yield put(pendentPaymentsActions.pendentPaymentsRemove(uuid));
      yield put(
        pendentPaymentsActions.pendentPaymentsUpdateContentType('declined')
      );
    }
  } catch (err) {
    error = err;
    yield* failureOperation(
      paymentSelectedActions.paymentSelectedDeclineFailure,
      err
    );
  }

  try {
    const paymentCollection = yield select(
      (state) => state.paymentSelected.paymentSelected
    );
  } catch (error) {}
}

function* cancelPayment({ uuid }) {
  let error;

  try {
    const clientId = yield select((state) => state.railsContext?.clientId);

    const response = yield call(cancelPaymentAPI, clientId, uuid);

    if (response.data.errors) throw response.data.errors;

    if (response.data.data) {
      yield put(paymentSelectedActions.paymentSelectedCancelSuccess());
      yield put(pendentPaymentsActions.pendentPaymentsRemove(uuid));
      yield put(
        pendentPaymentsActions.pendentPaymentsUpdateContentType('canceled')
      );
    }
  } catch (err) {
    error = err;
    yield* failureOperation(
      paymentSelectedActions.paymentSelectedCancelFailure,
      err
    );
  }

  try {
    const paymentCollection = yield select(
      (state) => state.paymentSelected.paymentSelected
    );
  } catch (error) {}
}

export {
  loadPaymentSelected,
  approvePayment,
  approvePartialPayment,
  declinePayment,
  cancelPayment
};
