import {
  colorInterfaceDarkDeep,
  fontFamilyHighlight,
  shadowLevel1
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`
};

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;

  img {
    margin-bottom: 16px;
    width: 130px;
  }

  @media ${devices.notebook} {
    text-align: center;
    width: 554px;

    img {
      margin-bottom: 10px;
      width: 195px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 16px;

  > div {
    width: 100%;

    button {
      display: block;
      margin: 0 auto;
    }
  }

  @media ${devices.notebook} {
    margin-bottom: 80px;
    font-size: 16px;
  }
`;

export const TaxPlansList = styled.div`
  table {
    margin-top: 16px;

    tr {
      td {
        padding: 0.5rem 16px;

        &:nth-child(1) {
          text-align: left;
        }

        b {
          color: #393b47;
          font-weight: 900;
        }
      }
    }
  }

  @media ${devices.notebook} {
    table {
      margin-top: 5px;
    }

    > div {
      display: block;
      width: 100%;
    }
  }
`;

export const Bar = styled.div`
  box-shadow: ${shadowLevel1};
  padding: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;

  @media ${devices.notebook} {
    margin-bottom: 2rem;
  }

  .ods-topbar .ods-topbar-title span {
    display: none;
  }
`;

export const List = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: ${(props) => (props.last ? '24px' : 0)};

  p {
    flex: 1;
    margin-left: 16px !important;
  }

  .ods-icon-btn {
    background: #f7f9ff;
    margin: 0 auto 4px auto;

    svg {
      color: #0025e0;
    }
  }
`;

export const Button = styled.button`
  background: transparent;
  border: 0;
  color: #0025e0;
`;

export const Title = styled.h1`
  font-weight: 800;
  font-family: ${fontFamilyHighlight};
  font-size: 32px;
  line-height: 45px;
  color: ${colorInterfaceDarkDeep};
  margin: 8px 0 16px;

  @media ${devices.mobile} {
    font-size: 24px;
    line-height: 26px;
    color: ${colorInterfaceDarkDeep};
  }
`;
