import React from 'react';

import { render, fireEvent } from 'utils/test-utils';
import signUpActions from 'store/ducks/signUp';
import ClientAndBranchSignUpForm from 'components/SignUpApp/ClientAndBranchSignUpForm';
import configureStore from 'redux-mock-store';
import { shallow } from 'enzyme';
import { Provider } from 'react-redux';

global.$ = require('jquery');

const mockStore = configureStore();

const mockDispatchfn = jest.fn();

const setup = ({ cpfCnpj, currentUserId }) =>
  render(<ClientAndBranchSignUpForm />, {
    initialState: {
      form: {
        clientAndBranchSignUpForm: {
          values: {
            client: {
              pos_preferences_cell_operator: 'definir_na_logistica',
              bank_account: {
                savings_account_operation: false
              },
              main_address: {},
              user: {},
              tax_plan: {},
              cpf_cnpj: cpfCnpj
            }
          }
        }
      },
      railsContext: {
        currentUserId
      },
      signUp: {
        client: {
          bank_account: { savings_account_operation: false },
          main_address: {},
          user: {},
          tax_plan: {},
          cpf_cnpj: cpfCnpj
        },
        tax_plans: null
      },
      clientsBigboost: {
        bigBoostData: [],
        bigBoostDataLoading: false,
        bigBoostDataError: null
      }
    }
  });

describe('<ClientAndBranchSignUpForm /> tests', () => {
  it('form should be defined', () => {
    const wrapper = shallow(
      <Provider store={mockStore()}>
        <ClientAndBranchSignUpForm dispatch={mockDispatchfn} />
      </Provider>
    );

    expect(wrapper).toBeDefined();
  });

  it('should not trigger `signUpLoadBigBoost` action on blur when user is anonymous', () => {
    // const { queryByRole } = setup({ currentUserId: 'anonymous' });
    const cpfCnpj = '11.553.756/0001-84';
    const { getByRole, store } = setup({
      cpfCnpj,
      currentUserId: 'anonymous'
    });

    fireEvent.click(getByRole('button', { name: 'Consultar' }));

    expect(store.getActions()).not.toContainValue(
      signUpActions.signUpLoadBigBoost(cpfCnpj)
    );
  });

  it('should trigger `signUpLoadBigBoost` action on click', async () => {
    const cpfCnpj = '11.553.756/0001-84';
    const { getByTestId, store } = setup({
      cpfCnpj
    });

    fireEvent.click(getByTestId('bigboost-search-button'));

    expect(store.getActions()).toContainValue(
      signUpActions.signUpLoadBigBoost(cpfCnpj)
    );
  });
});
