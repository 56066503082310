import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import * as S from '../styles';

const ItemSkeleton = ({ loading, value, isPagBlu }) => {
  return loading ? (
    <ContentLoader
      speed={2}
      width="100%"
      height={16}
      backgroundColor="#F7F9FF"
      foregroundColor="#EBECF5"
    >
      <>
        <rect
          x="0"
          y="0"
          rx="4"
          ry="4"
          width="80%"
          height="16"
          data-testid="sub-title-skeleton"
        />
      </>
    </ContentLoader>
  ) : (
    <S.SkeletonSpan className={isPagBlu && 'pag-blu'}>{value}</S.SkeletonSpan>
  );
};

ItemSkeleton.propTypes = {
  isPagBlu: PropTypes.bool,
  loading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ItemSkeleton.defaultProps = {
  loading: false,
  value: '',
  isPagBlu: false,
};

export default ItemSkeleton;
