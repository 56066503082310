import React from 'react';
import PropTypes from 'prop-types';
import PageView from 'components/AccountStatement/components/PageView';
import { useMedia } from 'react-use';
import moment from 'moment';
import {
  Button,
  TopBar,
  List,
  SubHeader,
  TransactionListItem,
  Progress
} from '@useblu/ocean-react';
import { formatDate } from 'normalizers';
import { formatPrice } from 'components/AccountStatement/helpers/currency';
import { ArrowLeftOutline, PixOutline } from '@useblu/ocean-icons-react';
import * as S from 'components/AccountStatement/screens/Pix/RefundInbound/styles';
import { isEmpty } from 'lodash';
import { maskValue } from 'utils/masks';
import { colorInterfaceDarkUp } from '@useblu/ocean-tokens/web/tokens';
import { isJSON } from 'utils/functions';
import accountStatementStatus from '../../DARFScreen/accountStatementStatus';

const hideCpf = (cpfCnpj) => {
  if (cpfCnpj) {
    if (cpfCnpj.length > 11) return maskValue(cpfCnpj, 'cnpj');

    const toReplace = cpfCnpj.slice(4, 10);
    const masked = maskValue(toReplace, 'cpf');

    return `***.${masked}-**`;
  }

  return cpfCnpj;
};

const Details = ({
  title,
  status,
  statusName,
  date,
  proofLink,
  refundData,
  loading,
  clientId,
  bank,
  error
}) => {
  const isMobile = useMedia('(max-width: 768px)');

  const accountTypes = {
    CACC: 'Conta Corrente',
    SVGS: 'Conta Poupança',
    SLRY: 'Conta Salário',
    TRAN: 'Conta de Pagamento'
  };

  const openReceipt = () => {
    window.open(proofLink, '_blank');
  };

  let payment = null;
  let creditParty = null;

  if (refundData?.payment) {
    payment = refundData?.payment;
    creditParty = refundData?.payment?.credit_party;
  }

  if (isJSON(creditParty)) {
    creditParty = JSON.parse(creditParty);
  }

  return (
    <S.Container>
      <S.BreadcrumbStyle />
      <PageView
        title={title}
        backLink="/"
        header={
          <>
            <S.Bar>
              <TopBar
                id="TopBar-playground"
                variants="default"
                leftIcon={isMobile ? <ArrowLeftOutline /> : null}
                title={title}
              />
            </S.Bar>
          </>
        }
      >
        {loading || error ? (
          <S.LoadingContainer>
            {loading && <Progress size="md" />}
            {error && (
              <p>
                Ocorreu um problema ao consultar a devolução, tente novamente
                mais tarde
              </p>
            )}
          </S.LoadingContainer>
        ) : (
          <>
            <S.RefundStatusTag type={accountStatementStatus(status)} setIconOff>
              {statusName}
            </S.RefundStatusTag>
            <S.WrapperResumeValues>
              <div className="payment-data">
                <S.ResumeValuesSection>
                  <S.ResumeValuesItem>
                    <p>Valor</p>
                    <p>
                      <b>{formatPrice(refundData.value)}</b>
                    </p>
                  </S.ResumeValuesItem>
                  <S.ResumeValuesItem>
                    <p>Data do reembolso</p>
                    <p>{formatDate(date)}</p>
                  </S.ResumeValuesItem>
                  {!isEmpty(refundData.description) && (
                    <S.ResumeValuesItem>
                      <p>Motivo da devolução</p>
                      <p>{refundData.description}</p>
                    </S.ResumeValuesItem>
                  )}
                </S.ResumeValuesSection>
                <S.ResumeValuesSection>
                  <S.ResumeValuesItem>
                    <p>Pagador</p>
                    <p>
                      Nome: {creditParty?.name}
                      <br />
                      {creditParty?.taxId.length > 11 ? 'CNPJ: ' : 'CPF: '}
                      {hideCpf(creditParty?.taxId)}
                    </p>
                  </S.ResumeValuesItem>
                  {creditParty.key && (
                    <S.ResumeValuesItem>
                      <p>Conta bancária</p>
                      <p>
                        Chave Pix: {creditParty.key}
                        <br />
                        Banco:{' '}
                        {bank
                          ? `${bank.compensation_code} - ${bank.name}`
                          : creditParty?.bank}
                        <br /> Tipo: {accountTypes[creditParty?.accountType]}
                      </p>
                    </S.ResumeValuesItem>
                  )}
                  {!creditParty.key && (
                    <S.ResumeValuesItem>
                      <p>Conta bancária</p>
                      <p>
                        Banco:{' '}
                        {bank
                          ? `${bank.compensation_code} - ${bank.name}`
                          : creditParty?.bank}
                        <br />
                        Agência: {creditParty?.branch}
                        <br /> Conta: {creditParty?.account}
                        <br /> Tipo: {accountTypes[creditParty?.accountType]}
                      </p>
                    </S.ResumeValuesItem>
                  )}
                </S.ResumeValuesSection>
                <S.ResumeValuesSection>
                  <S.ResumeValuesItem>
                    <p>Código de autenticação</p>
                    <p>{refundData.original_e2e_id}</p>
                  </S.ResumeValuesItem>
                </S.ResumeValuesSection>
              </div>
              <div className="date-information">
                Operação realizada em {moment(date).format('LLL')}
              </div>
            </S.WrapperResumeValues>
            <S.WrapperRefunds>
              <List>
                <SubHeader>Transação de origem</SubHeader>
                <TransactionListItem
                  icon={<PixOutline style={{ color: colorInterfaceDarkUp }} />}
                  level2={creditParty?.name}
                  value={formatPrice(payment?.value)}
                  onClick={() => {
                    window.location.href = `/lojas/${clientId}/transacoes/${payment?.client_transaction_payment_uuid}`;
                  }}
                  positive
                >
                  Pix enviado
                </TransactionListItem>
              </List>
            </S.WrapperRefunds>
            <S.WrapperButtons>
              <Button size="md" variant="primary" onClick={openReceipt}>
                Baixar comprovante
              </Button>
            </S.WrapperButtons>
          </>
        )}
      </PageView>
    </S.Container>
  );
};

Details.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusName: PropTypes.string.isRequired,
  proofLink: PropTypes.string.isRequired,
  refundData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  clientId: PropTypes.string.isRequired,
  bank: PropTypes.object,
  error: PropTypes.bool
};

Details.defaultProps = {
  refundData: {},
  bank: null,
  error: false
};

export default Details;
