import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Button, Link, Typography } from '@useblu/ocean-react';
import { colorStatusNegativePure } from '@useblu/ocean-tokens/web/tokens';

import { Loading } from './Styles';

const FailedLink = styled(Link)`
  &&& {
    color: ${colorStatusNegativePure};
    background: transparent;
  }
`;

const StatusParse = ({ status, errorMessage }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleModalClose = () => setOpenModal(false);

  if (status === 'pending') return 'Pendente';
  if (status === 'finished') return 'Concluído';
  if (status === 'processing') {
    return (
      <Loading
        data-testid="status-processing"
        type="spinningBubbles"
        color="#343e4f"
        width={20}
        height={20}
      />
    );
  }

  // status === 'failed'
  return (
    <>
      <FailedLink
        data-testid="open-modal-link"
        onClick={() => setOpenModal(true)}
      >
        Falha
      </FailedLink>
      <Modal
        isOpen={openModal}
        onRequestClose={handleModalClose}
        className="modal pendent-payment-collection__modal ocean-iso"
        overlayClassName="modal-overlay pendent-payment-collection__modal--overlay"
        style={{
          content: {
            padding: 24,
            display: 'grid',
            gap: 16,
            justifyItems: 'center'
          }
        }}
        ariaHideApp={false}
      >
        <Typography variant="heading3">
          Falha no processamento do CNAB
        </Typography>
        <Typography variant="paragraph">{errorMessage}</Typography>
        <Button type="button" onClick={handleModalClose}>
          Fechar
        </Button>
      </Modal>
    </>
  );
};

StatusParse.propTypes = {
  status: PropTypes.string.isRequired,
  errorMessage: PropTypes.string
};

StatusParse.defaultProps = {
  errorMessage: ''
};

export default React.memo(StatusParse);
