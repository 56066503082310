import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const RefundSimulationResultTooltip = ({ description, value, tooltip }) => (
  <div className="refund-simulation-result-table__total-item refund-simulation-result-table__total-others">
    <span style={{ display: 'flex' }}>
      {description}
      <span className="refund-simulation-result-table__others-tooltip">
        <i data-tip data-for="others-tooltip" className="fa fa-info-circle" />
        <ReactTooltip
          id="others-tooltip"
          place="top"
          effect="solid"
          type="light"
          border
          clickable
          className="refund-simulation-result-table__others-tooltip-content"
        >
          {tooltip}
        </ReactTooltip>
      </span>
    </span>
    <span>{value}</span>
  </div>
);

RefundSimulationResultTooltip.propTypes = {
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired
};

export default RefundSimulationResultTooltip;
