import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import viaCep from 'api/via-cep';
import * as yup from 'yup';

const useFormAddress = ({
  client,
  address,
  stateMock,
  clientAddressUpdate,
  showClientStep,
  showContractStep
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [cepError, setCepError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasChangeValidation, enableChangeValidation] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    business_name: '',
    zipcode: '',
    street: '',
    complement: '',
    number: '',
    neighborhood: '',
    city: '',
    state: ''
  });

  const updateFieldsByCep = useCallback(
    (cep, setFieldValue) => {
      setIsLoading(true);

      viaCep(cep)
        .then((response) => {
          setIsLoading(false);

          if (response.data.erro) {
            return setCepError(true);
          }

          const {
            bairro: neighborhood,
            localidade: city,
            logradouro: street,
            uf: state
          } = response.data;

          setFieldValue('neighborhood', neighborhood);
          setFieldValue('city', city);
          setFieldValue('street', street);
          setFieldValue('state', state);
          setFieldValue(
            'idState',
            stateMock.find((item) => item.label === state).value
          );

          return setCepError(false);
        })
        .catch(() => {
          setIsLoading(false);
          setCepError(true);
        });
    },
    [setCepError, stateMock]
  );

  const checkCep = async (cep) => {
    const isValidCep = await viaCep(cep)
      .then((response) => !response.data.erro)
      .catch(() => false);

    setCepError(!isValidCep);

    return isValidCep;
  };

  const formikProps = {
    initialValues: {
      name: formData.name || '',
      business_name: formData.business_name || '',
      zipcode: formData.zipcode || '',
      street: formData.street || '',
      complement: formData.complement || '',
      number: formData.number || '',
      neighborhood: formData.neighborhood || '',
      city: formData.city || '',
      state: formData.state || '',
      id: formData.id || ''
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup
        .string()
        .required('Campo obrigatório')
        .test({
          name: 'isValidSocialName',
          message: 'Este Nome não é válido',
          test: (socialName) => {
            let lessThanTwoWords = true;
            const checkBackspace = /^\s/g;
            if (socialName) {
              const fullSocialName = socialName
                .replace(/\s+/g, ' ')
                .trim()
                .split(' ');
              lessThanTwoWords = fullSocialName.length >= 2;

              if (checkBackspace.test(socialName.charAt(0)))
                lessThanTwoWords = false;
            }
            return lessThanTwoWords;
          }
        }),
      business_name: yup.string().required('Campo obrigatório'),
      zipcode: yup.string().required('Campo obrigatório'),
      street: yup.string().required('Campo obrigatório'),
      number: yup.string().required('Campo obrigatório'),
      neighborhood: yup.string().required('Campo obrigatório'),
      city: yup.string().required('Campo obrigatório'),
      state: yup.string().required('Campo obrigatório')
    }),

    onSubmit: async (values) => {
      if (await checkCep(values.zipcode)) {
        clientAddressUpdate(values)
          .then((response) => {
            if (response.success) {
              setVisible(false);
              showContractStep();
            }
          })
          .catch(() => {
            dispatch({ type: 'DATA_LOAD_SUCCESS' });
          });
      }
    }
  };

  const hasState = (state) => {
    const info = state && stateMock.find((item) => item.label === state);
    if (info) return info && info.value;
    return null;
  };

  const selectState = useCallback(
    (address, client) => {
      const state = stateMock.find((item) => item.label === address.state);
      setFormData({
        ...address,
        id: client.id,
        name: client.name,
        business_name: client.business_name,
        idState: state && state.value
      });
    },
    [setFormData]
  );

  const maskDefault = (value, client) => {
    const valideZipCode = /(^[0-9]{5}(?:-[0-9]{4})?$)/.test(value.zipcode);

    if (!valideZipCode) {
      const zipCode = value?.zipcode
        ?.replace(/[^\d]/g, '')
        ?.replace(/(\d{5})(\d{3})/, '$1-$2');

      setFormData({
        ...value,
        ...client,
        zipcode: zipCode
      });
    }
  };

  useEffect(() => {
    if (showClientStep) {
      selectState(address, client);
      maskDefault(address, client);
    }
  }, [client, address, selectState, showClientStep]);

  return {
    formikProps,
    setFormData,
    formData,
    hasChangeValidation,
    enableChangeValidation,
    cepError,
    visible,
    setVisible,
    updateFieldsByCep,
    hasState,
    isLoading
  };
};

export default useFormAddress;
