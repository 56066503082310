import { sendEmailUploadContract, sendContractUpload } from 'api/first-access';
import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/firstAccess/usersUpload';
import sharedActionCreator from 'store/ducks/firstAccess/shared';
import _ from 'lodash';

export function* sendUsersUploadEmail(uuid, email) {
  const response = yield call(sendEmailUploadContract, uuid, email);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(ActionCreators.firstAccessUsersUploadEmailSuccess());
  } else {
    yield put(ActionCreators.firstAccessUsersUploadEmailFailure());
  }
}

export function* sendUsersUploadFile(params) {
  const response = yield call(sendContractUpload, params.uuid, params.file);
  const { client } = params;
  const { data } = response.data;

  if (_.isEmpty(data.errors)) {
    yield put(
      sharedActionCreator.firstAccessClientSuccess(
        _.merge(client, data.uploadContractClient)
      )
    );
    yield put(ActionCreators.firstAccessUsersUploadSubmitSuccess());
  } else {
    yield put(ActionCreators.firstAccessUsersUploadSubmitFailure());
  }
}
