import {
  fontFamilyHighlight,
  colorBrandPrimaryPure,
  fontSizeLg
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';
import { Button, Modal } from '@useblu/ocean-react';
import Select from 'react-select';
import InputControl from 'components/Shared/InputControl';

export const Content = styled.div`
  border-radius: 8px;
  padding: 20px 25px;
  background: #fff;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 25px 35px;
  }

  form {
    height: 490px;

    @media only screen and (max-width: 768px) {
      height: 100%;
      overflow-y: scroll;
    }
  }
`;

export const Title = styled.h2`
  color: ${colorBrandPrimaryPure};
  font-size: ${fontSizeLg};
  font-family: ${fontFamilyHighlight};
  font-weight: 800;
  margin-bottom: 4px;
`;

export const WrapInput = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ods-form-control__root {
    flex: 1;
  }
`;

export const CustomInput = styled(InputControl)`
  &&& {
    margin-bottom: 12px;
  }
`;

export const WrapCustomSelect = styled.div`
  width: 100%;
`;

export const CustomSelect = styled(Select)`
  &&& {
    width: 100%;
    height: 48px;
    position: relative;
    top: -1px;
    margin: 5px 0;

    > div {
      min-height: 46px;
    }
  }
`;

export const CustomButton = styled(Button)`
  &&& {
    margin-left: 30px;
    position: relative;
    top: 6px;
  }
`;

export const CustomButtonSubmit = styled(Button)`
  &&& {
    width: 100%;
  }
`;

export const CustomModal = styled(Modal)`
  &&& {
    max-height: 450px;
    max-width: 650px;
    margin: 0 auto;
    position: relative;
    top: 5%;
    outline: none;

    @media only screen and (max-width: 768px) {
      max-height: initial;
      max-width: initial;
      width: 100%;
      height: 100vh;
      top: 0;
      overflow: scroll;

      .ods-modal__body {
        padding: 0;
      }
    }

    .ods-modal__header {
      position: absolute;
      top: 0px;
      right: 30px;
      z-index: 9;

      @media only screen and (max-width: 768px) {
        right: 0;
      }
    }
  }
`;
