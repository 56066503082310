import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { normalizeCurrency } from 'normalizers';
import PaymentListActions from 'store/ducks/paymentsList';
import { Button } from '@useblu/ocean-react';
import {
  SwitchHorizontalOutline,
  BarcodeOutline
} from '@useblu/ocean-icons-react';
import { PaymentListContainer, PaymentList, PaymentFooter } from './styles';

const PaymentsList = ({ uuid, showButton }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PaymentListActions.getPaymentListRequest(uuid));
  }, [uuid, dispatch]);

  const redirect = () => {
    window.location.href = `/lojas/${uuid}/transacoes/confirmacao-pagamentos`;
  };

  const {
    data: paymentsList,
    dataCount,
    dataTotal
  } = useSelector((state) => state.paymentsList);

  if (dataCount === 0) {
    return null;
  }

  const filteredBillet = paymentsList.filter(
    (item) => item.transactionCategory === 'Boleto'
  );
  const filteredTransfer = paymentsList.filter(
    (item) =>
      item.transactionCategory === 'Transferência bancária' ||
      item.transactionCategory === 'Transferência entre clientes'
  );

  let showedItemsCount = 0;

  return (
    dataCount > 0 && (
      <PaymentListContainer className="only-desktop">
        <h1>{dataCount} transações esperando para serem aprovadas.</h1>
        <div>
          <div>
            <PaymentList>
              {filteredTransfer.length > 0 && (
                <li className="subtitle">
                  <SwitchHorizontalOutline />
                  Transferências na lista
                </li>
              )}
              {filteredTransfer.slice(0, 3).map((item) => {
                showedItemsCount += 1;
                return (
                  <li key={item.id}>
                    <span>{item.payee}</span>
                    <p>{`R$ ${normalizeCurrency(item.grossValue)}`}</p>
                  </li>
                );
              })}
              {filteredBillet.length > 0 && (
                <li className="subtitle">
                  <BarcodeOutline />
                  Boletos na lista
                </li>
              )}
              {filteredBillet.slice(0, 3).map((item) => {
                showedItemsCount += 1;
                return (
                  <li key={item.id}>
                    <span>{item.payee}</span>
                    <p>{`R$ ${normalizeCurrency(item.grossValue)}`}</p>
                  </li>
                );
              })}
            </PaymentList>
          </div>
          <PaymentFooter>
            {dataCount - showedItemsCount > 0 && (
              <span>{`e mais ${dataCount - showedItemsCount} ${
                dataCount - showedItemsCount === 1 ? 'transação' : 'transações'
              } na lista`}</span>
            )}

            <div>
              <h1>Total</h1>
              <p>{`R$ ${normalizeCurrency(dataTotal)}`}</p>
            </div>
            {showButton && (
              <Button variant="primary" type="button" onClick={redirect}>
                Ver lista completa
              </Button>
            )}
          </PaymentFooter>
        </div>
      </PaymentListContainer>
    )
  );
};

PaymentsList.propTypes = {
  uuid: PropTypes.string.isRequired,
  showButton: PropTypes.bool
};

PaymentsList.defaultProps = {
  showButton: false
};

export default PaymentsList;
