import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getRefundInbound } from 'api/pix';
import useRailsContext from 'hooks/useRailsContext';
import { getBankBy } from 'api/bank-account';
import { isEmpty } from 'lodash';
import { isJSON } from 'utils/functions';
import Details from './Details';

const RefundInbound = (props) => {
  const { refundId } = props;

  const { clientId } = useRailsContext();

  const [error, setError] = useState(false);
  const [bank, setBank] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refundData, setRefundData] = useState({});

  useEffect(() => {
    if (!isEmpty(refundData)) {
      let creditParty = null;

      if (
        refundData?.payment?.credit_party &&
        isJSON(refundData?.payment?.credit_party)
      ) {
        creditParty = JSON.parse(refundData?.payment?.credit_party);
      } else {
        creditParty = refundData?.payment?.credit_party;
      }

      if (creditParty.bank) {
        getBankBy('ispb', creditParty.bank).then((response) => {
          setBank(response.data.bank);
        });
      }
    }
  }, [refundData]);

  const getRefundData = useCallback(async () => {
    try {
      const {
        data: {
          data: { attributes }
        }
      } = await getRefundInbound(clientId, refundId);

      if (!attributes) {
        throw new Error();
      }

      setRefundData(attributes);
      setLoading(false);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [clientId, refundId]);

  useEffect(() => {
    if (refundId) getRefundData();
  }, [refundId, getRefundData]);

  return (
    <Details
      {...props}
      refundData={refundData}
      loading={loading}
      clientId={clientId}
      bank={bank}
      error={error}
    />
  );
};

RefundInbound.propTypes = {
  refundId: PropTypes.string.isRequired
};

export default RefundInbound;
