import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Field,
  Form,
  reduxForm,
  getFormValues,
  SubmissionError
} from 'redux-form';
import _ from 'lodash';
import Loading from 'react-loading';

import onSignUpSuccess from 'actions/sign-up-app';
import { saveClient } from 'api/sign-up-app';
import RenderField from 'components/Form/RenderField';
import RenderDropdownListCallback from 'components/Form/RenderDropdownListCallback';
import signUpActions from 'store/ducks/signUp';
import validateNaturalSignUpApp from 'validations/ValidateNaturalSignUpApp';
import TaxPlans from 'components/SignUpApp/Shared/TaxPlans';
import PosOptions from 'components/SignUpApp/Shared/PosOptions';
import Address from 'components/SignUpApp/Shared/Address';
import Captcha from 'components/SignUpApp/Shared/Captcha';

class NaturalSignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { error: {} };
    this.captchaRef = React.createRef();
  }

  componentDidMount() {
    const { signUpLoadDepartments, partnerId, clientId } = this.props;
    this.loadTaxPlan();

    if (_.isEmpty(partnerId) && _.isEmpty(clientId)) {
      signUpLoadDepartments();
    }
  }

  loadTaxPlan(param_id = null) {
    const { signUpLoadTaxPlans, partnerId, clientId } = this.props;

    if (
      !_.isUndefined(partnerId) ||
      !_.isUndefined(clientId) ||
      !_.isNull(param_id)
    ) {
      let isEmpty = _.isUndefined(partnerId) && _.isUndefined(clientId);
      let id = partnerId || clientId || param_id;

      signUpLoadTaxPlans(isEmpty, id);
    }
  }

  submit(values) {
    const { onSuccess } = this.props;
    const params = this.buildParams(values);
    this.validateTaxPlan();

    if (!_.isEmpty(params)) {
      this.captchaRef.current.execute();

      return saveClient(params)
        .then((response) => {
          if (_.isEqual(response.status, 200)) {
            onSuccess();
          }
        })
        .catch((error) => {
          const {
            response: { data }
          } = error;
          this.setState({ error: data });
          window.scrollTo(0, 0);
        });
    }
  }

  buildParams(values) {
    let { client } = this.props;
    const {
      tax_plans,
      partnerId,
      sellerId,
      clientType,
      clientId,
      railsContext
    } = this.props;
    let taxPlan = {};

    if (_.isEmpty(client.tax_plan)) {
      taxPlan = _.find(tax_plans, { selected: true });
    } else {
      taxPlan = client.tax_plan;
    }

    client = _.merge(client, {
      tax_plan_id: _.get(taxPlan, 'id', null)
    });

    const clientParams = _.merge(values.client, client);

    let clientKind = {};

    if (_.isEqual(clientType, 'seller')) {
      clientKind = { seller: true };
    }

    if (_.isEqual(clientType, 'distributor')) {
      clientKind = { distributor: true };
    }

    if (_.isEqual(clientType, 'shipping_company')) {
      clientKind = { shipping_company: true };
    }

    if (_.isEqual(clientType, 'branch')) {
      clientKind = { branch_company: true };
    }

    const bankAccountParams = _.merge(
      _.omit(clientParams.bank_account, ['bank']),
      {
        bank_id: _.get(clientParams, 'bank_account.bank.id'),
        nickname: _.get(clientParams, 'bank_account.owner_name'),
        cpf_cnpj: clientParams.cpf_cnpj
      }
    );

    let buildedClientParams = _.merge(
      _.omit(clientParams, [
        'main_address',
        'bank_account',
        'tax_plan',
        'user'
      ]),
      {
        main_address_attributes: clientParams.main_address,
        bank_accounts_attributes: { 0: bankAccountParams },
        tax_plan_attributes: _.omit(taxPlan, [
          'id',
          'partner_tax_plan',
          'selected'
        ]),
        user_clients_attributes: { 0: { user_attributes: clientParams.user } },
        client_id: clientId,
        current_user_email: railsContext.currentUserEmail
      }
    );

    if (!_.isNil(partnerId)) {
      buildedClientParams = _.merge(buildedClientParams, {
        inverse_clientships_attributes: [
          {
            merchant: partnerId,
            seller: sellerId
          }
        ]
      });
    } else {
      buildedClientParams = _.merge(buildedClientParams, clientKind);
    }

    return { client: buildedClientParams };
  }

  validateTaxPlan() {
    const { tax_plans } = this.props;
    const taxPlan = _.find(tax_plans, { selected: true });

    const taxPlanAttributes = [
      'debit_rate',
      'credit_rate_1',
      'credit_rate_2',
      'credit_rate_3',
      'credit_rate_4',
      'credit_rate_5',
      'credit_rate_6',
      'credit_rate_7',
      'credit_rate_8',
      'credit_rate_9',
      'credit_rate_10',
      'credit_rate_11',
      'credit_rate_12',
      'anticipation_rate',
      'outflow_to_payment_to_bill_rate',
      'bank_transfer_rate',
      'outflow_to_transfer_to_prepaid_card_rate',
      'payment_collection_rate',
      'outflow_to_transfer_to_client_rate',
      'pos_rent_amount'
    ];

    if (taxPlan && !taxPlan.id) {
      _.each(taxPlanAttributes, (attribute) => {
        const error = {};
        error[attribute] = 'não pode ficar em branco';

        if (!taxPlan[attribute] && taxPlan[attribute] !== 0) {
          throw new SubmissionError({ client: { tax_plan: error } });
        }
      });
    }
  }

  toggleClientGroupDisplay(e) {
    const parent = $(e.currentTarget).closest('[data-collapsible]');

    parent.children('.content-collapsible').toggle();
    parent.toggleClass('open closed');
  }

  resetBankAccount(bankAccount) {
    const { signUpReloadBankAccount } = this.props;
    return signUpReloadBankAccount(bankAccount);
  }

  onDepartmentChange(departmentId) {
    const { client, signUpReloadClient, partnerId } = this.props;
    client.department_id = departmentId;
    signUpReloadClient(client);
    this.loadTaxPlan(departmentId);
  }

  renderLoading() {
    return (
      <Loading
        type="spinningBubbles"
        color="#e1e5ed"
        delay={0}
        height={35}
        width={35}
      />
    );
  }

  renderSubmitErrors(error) {
    if (!error?.message) {
      return;
    }

    const messageTitle = error.message.split(':')[0];
    const messageBody = error.message.replace(
      'Ocorreram os seguintes erros ao salvar o cliente: A validação falhou: ',
      ''
    );

    return (
      <div className="alert-message">
        <h3 className="errors" data-testid="message-title">
          {messageTitle}:
        </h3>
        <ul className="errors">
          {_.map(messageBody.split(','), (message, i) => (
            <li key={i} data-testid={`message-item-${i}`}>
              {message}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderDepartmentsOption(department) {
    return { key: department.id, label: department.name };
  }

  renderDepartments() {
    const { departments, partnerId, clientId } = this.props;
    let content;

    if (
      _.isEmpty(partnerId) &&
      _.isEmpty(clientId) &&
      departments &&
      departments.length
    ) {
      content = (
        <div className="client-data-group">
          <h3 className="page-subtitle">
            <i className="fa fa-folder-open" /> Departamento do Cliente
          </h3>
          <div className="form-row">
            <div className="form-controls">
              <Field
                name="client[department_id]"
                component={RenderDropdownListCallback}
                options={departments.map(this.renderDepartmentsOption)}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ key }) => key}
                simpleValue
                label="Selecione o departamento do Cliente"
                placeholder="Escolha uma opção"
                handleChange={(departmentId) =>
                  this.onDepartmentChange(departmentId)
                }
              />
            </div>
          </div>
        </div>
      );
    }

    return content;
  }

  renderNaturalSignUpForm() {
    const {
      handleSubmit,
      submitting,
      client,
      tax_plans,
      signUpSetMainAddress,
      signUpSetTaxPlans
    } = this.props;

    let { error } = this.state;

    return (
      <Form
        className="form-fields-for"
        onSubmit={handleSubmit((values) => this.submit(values))}
        noValidate
      >
        {this.renderSubmitErrors(error)}
        {this.renderDepartments()}
        <div className="client-data-group">
          <h3 className="page-subtitle">
            <i className="fa fa-building" /> Dados do cliente
          </h3>
          <div className="form-row">
            <Field
              component={RenderField}
              label="CPF"
              name="client[cpf_cnpj]"
              type="text"
              mask="cpf"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Nome"
              name="client[name]"
              type="text"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Identificação na fatura do cartão"
              name="client[business_name]"
              type="text"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="E-mail"
              name="client[user][email]"
              type="text"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Telefone"
              name="client[user][phone]"
              type="text"
              mask="phone"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Telefone 2"
              name="client[user][phone_2]"
              type="text"
              mask="phone"
            />
            <Field
              component={RenderField}
              label="Telefone 3"
              name="client[user][phone_3]"
              type="text"
              mask="phone"
            />
          </div>
          <div className="form-row">
            <Field
              component={RenderField}
              label="Ticket ID"
              name="client[zendesk_origin_ticket_id]"
              type="number"
              mask="number"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-controls boolean optional">
            <div>
              <input value="0" type="hidden" name="client[initial_state]" />
              <label className="checkbox">
                <Field
                  name="client[initial_state]"
                  component="input"
                  className="boolean optional"
                  type="checkbox"
                />
              </label>
            </div>
            <label htmlFor="client[initial_state]">Pré cadastro?</label>
          </div>
        </div>
        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle client-data-group-title"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-map" /> Endereço <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            <Address
              name="client[main_address]"
              address={client.main_address}
              setAddressToReducer={(address) => signUpSetMainAddress(address)}
              showForm
            />
          </div>
        </div>
        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-credit-card" /> Preferências de POS{' '}
            <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            <PosOptions />
          </div>
        </div>
        <div className="client-data-group closed" data-collapsible>
          <h3
            className="page-subtitle client-data-group-title"
            onClick={(e) => this.toggleClientGroupDisplay(e)}
          >
            <i className="fa fa-certificate" /> Plano de Taxas{' '}
            <i className="fa icon" />
          </h3>
          <div className="content-collapsible" style={{ display: 'none' }}>
            {tax_plans ? (
              <TaxPlans
                taxPlans={tax_plans}
                setTaxToReducer={(taxPlan) => signUpSetTaxPlans(taxPlan)}
              />
            ) : (
              this.renderLoading()
            )}
          </div>
        </div>
        <div className="form-row actions">
          <button className="button button--primary" disabled={submitting}>
            Enviar
          </button>
        </div>
        <Captcha ref={this.captchaRef} />
      </Form>
    );
  }

  render() {
    return (
      <div className="flex-container">{this.renderNaturalSignUpForm()}</div>
    );
  }
}

NaturalSignUpForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  error: PropTypes.object,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  client: PropTypes.object,
  tax_plans: PropTypes.array,
  departments: PropTypes.array,
  partnerId: PropTypes.string,
  sellerId: PropTypes.string,
  clientType: PropTypes.string,
  clientId: PropTypes.string,
  signUpReloadBankAccount: PropTypes.func,
  signUpReloadClient: PropTypes.func,
  signUpSetMainAddress: PropTypes.func,
  signUpSetTaxPlans: PropTypes.func,
  signUpLoadTaxPlans: PropTypes.func,
  signUpLoadDepartments: PropTypes.func,
  formValues: PropTypes.object,
  railsContext: PropTypes.object
};

NaturalSignUpForm.defaultProps = {
  error: {},
  submitting: false,
  invalid: false
};

const mapStateToProps = (state) => ({
  client: state.signUp.client,
  tax_plans: state.signUp.tax_plans,
  departments: state.signUp.departments,
  initialValues: {
    client: {
      pos_preferences_cell_operator: 'definir_na_logistica',
      ...state.signUp.client
    }
  },
  formValues: getFormValues('naturalSignUpForm')(state),
  railsContext: state.railsContext
});

const mapDispatchToProps = (dispatch) => ({
  signUpLoadTaxPlans: (isPartnerIdNil, id) =>
    dispatch(signUpActions.signUpLoadTaxPlans(isPartnerIdNil, id)),
  signUpLoadDepartments: () => dispatch(signUpActions.signUpLoadDepartments()),
  signUpSetTaxPlans: (taxPlan) =>
    dispatch(signUpActions.signUpSetTaxPlans(taxPlan)),
  signUpSetMainAddress: (address) =>
    dispatch(signUpActions.signUpSetMainAddress(address)),
  signUpReloadBankAccount: (bankAccount) => {
    dispatch(signUpActions.signUpReloadBankAccount(bankAccount));
  },
  signUpReloadClient: (client) => {
    dispatch(signUpActions.signUpReloadClient(client));
  },
  onSuccess: () => {
    dispatch(onSignUpSuccess());
  }
});

const NaturalSignUpReduxForm = reduxForm({
  form: 'naturalSignUpForm',
  validate: validateNaturalSignUpApp,
  shouldValidate() {
    return true;
  },
  onSubmitFail: (errors) => {
    if (!_.isEmpty(errors)) {
      const fieldKeys = [];
      const fieldNames = [];

      const errorKey = Object.keys(errors.client)[0];
      const errorValue = Object.values(errors.client)[0];

      const error = { client: {} };
      error.client[errorKey] = errorValue;

      _.mixin({
        deeply(map) {
          return function (obj, fn) {
            return map(
              _.mapValues(obj, function (v) {
                return _.isPlainObject(v) ? _.deeply(map)(v, fn) : v;
              }),
              fn
            );
          };
        }
      });

      _.deeply(_.mapKeys)(error, (value, key) => {
        return fieldKeys.push(key);
      });

      _.each(_.reverse(fieldKeys), (value, index) => {
        const name = _.isEqual(index, 0) ? value : `[${value}]`;
        return fieldNames.push(name);
      });

      const inputError = document.querySelectorAll(
        `input[name='${fieldNames.join('')}'], select[name='${fieldNames.join(
          ''
        )}']`
      )[0];

      if (inputError) {
        inputError.focus();
      }
    }
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  NaturalSignUpReduxForm
)(NaturalSignUpForm);
