import { Button as ButtonClick, Typography } from '@useblu/ocean-react';
import {
  colorInterfaceLightPure,
  fontSizeLg,
  fontSizeSm,
  spacingInlineLg,
  spacingInlineXl,
  spacingInlineXs,
  spacingInsetLg
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

const mediaScreen = {
  xs: `@media (max-width: 480px)`,
  sm: `@media (min-width: 600px)`,
  md: `@media (min-width: 992px)`,
  lg: `@media (min-width: 993px)`
};

export const Wrapper = styled.section`
  margin-top: ${spacingInlineXl};
  padding: ${spacingInsetLg} 0;
  background: linear-gradient(104.26deg, #0025e0 20.29%, #5872f5 94.43%);

  ${mediaScreen.xs} {
    margin-top: 0;
  }
`;

export const Title = styled(Typography).attrs(() => ({
  variant: 'heading2'
}))`
  &&& {
    padding: 0 ${spacingInlineLg};
    text-align: center;
    color: ${colorInterfaceLightPure};
    font-size: ${fontSizeSm};
    ${mediaScreen.md} {
      font-size: ${fontSizeLg};
    }
  }
`;

export const SubTitle = styled(Typography).attrs(() => ({
  variant: 'paragraph'
}))`
  &&& {
    padding: 0 ${spacingInlineLg};
    text-align: center;
    color: ${colorInterfaceLightPure};
    font-size: ${fontSizeSm};
    margin-top: ${spacingInlineXs};
    ${mediaScreen.md} {
      font-size: ${fontSizeSm};
    }
  }
`;

export const Icon = styled.div`
  text-align: center;
  margin-bottom: ${spacingInlineXs};
`;

export const Button = styled(ButtonClick)`
  display: block;
  margin: ${spacingInlineXs} auto 0 auto;
  width: 328px;
`;
