const defaultValues = {
  posAmount: 1,
  documentPlaceEmission: '',
  documentType: 'RG',
  documentNumber: '',
  documentDateEmission: '',
  documentUfEmission: '',
  legalRepresentativeCpf: '',
  legalRepresentativeEmail: '',
  legalRepresentativePhone: '',
  legalRepresentativeNationality: 'Brasil',
  addressId: ''
};

const defaultAddress = {
  zipcode: '',
  city: '',
  street: '',
  number: '',
  complement: '',
  neighborhood: '',
  uf: ''
};

const ufList = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO'
];

export { defaultValues, defaultAddress, ufList };
