import React from 'react';
import PropTypes from 'prop-types';
import { columnType } from '../../types';

const SorterButtons = (columnKey, sorter) => (
  <div className="table-sorter">
    <div title="Ordenar" className="table-sorter-content">
      <i
        className={`${
          sorter && sorter.direction === 'asc' && columnKey === sorter.key
            ? 'active'
            : ''
        }`}
      >
        <svg
          viewBox="0 0 1024 1024"
          focusable="false"
          data-icon="caret-up"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z" />
        </svg>
      </i>
      <i
        className={`${
          sorter && sorter.direction === 'desc' && columnKey === sorter.key
            ? 'active'
            : ''
        }`}
      >
        <svg
          viewBox="0 0 1024 1024"
          focusable="false"
          data-icon="caret-down"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z" />
        </svg>
      </i>
    </div>
  </div>
);

const TableHeaderColumn = ({ column, sorter, sorterFunc }) =>
  column.sorter ? (
    <th
      width={column.width}
      className={`table-list__header-item column-header-${column.key} table-sorter-header`}
      onClick={sorterFunc(column.key)}
    >
      {column.title}
      {SorterButtons(column.key, sorter)}
    </th>
  ) : (
    <th
      width={column.width}
      className={`table-list__header-item column-header-${column.key}`}
    >
      {column.title}
    </th>
  );

TableHeaderColumn.propTypes = {
  column: columnType.isRequired,
  sorter: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  sorterFunc: PropTypes.func
};

TableHeaderColumn.defaultProps = {
  sorter: false,
  sorterFunc: null
};

export default TableHeaderColumn;
