import styled, { css } from 'styled-components';
import { Row } from './Styles';
import { Devices } from 'utils/getDeviceMedia';

export const Title = styled.div`
  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #393b47;
  }
`;

export const Body = styled.div`
  background: #ffffff;
  border: 1px solid #ebecf5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 40px;
  margin-top: 11px;
`;

export const Box = styled(Row)`
  flex-wrap: wrap;
  align-items: center;
  @media ${Devices.tablet}, ${Devices.mobile} {
    justify-content: center;
    text-align: center;
  }
  ${(props) =>
    props.isCentralized &&
    css`
      display: block;
      text-align: center;
      text-align: -webkit-center;
    `}
`;

export const BoxIcon = styled.div`
  flex: 0 0 18.421052631%;
  @media ${Devices.tablet}, ${Devices.mobile} {
    flex: 0 0 100%;
    padding-bottom: 16px;
  }
`;
