import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { getPaymentInboundsApi } from 'api/pix';

import Details from './components/Details';

const PixReceivement = ({
  title,
  date,
  value,
  transactionId,
  proofLink,
  status,
  statusName,
  transactionUuid,
}) => {
  const { clientId } = useSelector((state) => ({
    ...state.railsContext,
  }));

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const getPaymentInbounds = useCallback(async () => {
    try {
      setLoading(true);
      const { data: dataDebit } = await getPaymentInboundsApi(
        clientId,
        transactionId
      );
      setData(dataDebit.data);
    } finally {
      setLoading(false);
    }
  }, [clientId, transactionId]);

  useEffect(() => {
    getPaymentInbounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Details
      title={title}
      date={date}
      status={status}
      value={value}
      transactionId={transactionId}
      e2eId={data?.attributes?.e2e_id}
      debitParty={data?.attributes?.debit_party}
      refunds={data?.attributes?.refunds}
      description={data?.attributes?.description}
      receiverKey={data?.attributes?.receiver_key}
      loading={loading}
      transactionUuid={transactionUuid}
      proofLink={proofLink}
      statusName={statusName}
      uuid={clientId}
      getPaymentInbounds={getPaymentInbounds}
    />
  );
};

PixReceivement.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
  proofLink: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  statusName: PropTypes.string.isRequired,
  transactionUuid: PropTypes.string.isRequired,
};

export default PixReceivement;
