import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Security from './Security';
import SelbieLogin from './SelbieLogin';
import SellMoreVideo from './SellMoreVideo';
import CarouselSteps from './CarouselSteps';
import KnowMore from './KnowMore';
import Community from './Community';
import SellMoreFooter from './SellMoreFooter';

import { Wrapper } from './styles.js';

const SellMoreLandingPage = ({ selbieLogin, uuid }) => (
  <Wrapper data-testid="sellmore-landingpage" className="ocean-iso">
    <Header />
    <Security />
    <SelbieLogin selbieLogin={selbieLogin} />
    <SellMoreVideo />
    <CarouselSteps />
    <KnowMore uuid={uuid} />
    <Community />
    <SellMoreFooter />
  </Wrapper>
);

SellMoreLandingPage.propTypes = {
  uuid: PropTypes.string.isRequired,
  selbieLogin: PropTypes.object.isRequired
};

export default SellMoreLandingPage;
