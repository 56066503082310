import styled from 'styled-components';

import { colorHighlightPure } from '@useblu/ocean-tokens/web/tokens';

// ATTENTION:
// This is a self contained component, it should be replaced by the dropdown
// from ocean asap.

const Tag = styled.span`
  padding: 2px 8px;
  height: 18px;
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 900;
  line-height: 14px;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(255, 114, 110, 0.2);
  border-radius: 100px;
  margin: 4px 0px;

  background: ${colorHighlightPure};
`;

export default Tag;
