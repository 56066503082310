import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { sendScreenEvent } from '@useblu/blu-lytics';
import {
  RoleOption,
  OptionsWrapper,
  IconAndName,
  RepText,
} from '../devise.styles';
import retailerOutline from '../images/retailer-outline.svg';
import factoryOutline from '../images/factory-outline.svg';

const DistributorForm = ({ onSuccess }) => {
  useEffect(() => {
    sendScreenEvent('register_company_view');
  }, []);

  return (
    <>
      <OptionsWrapper>
        <RoleOption
          className="ac_etapa_1_seletor_botaolojista"
          onClick={() => onSuccess(false)}
        >
          <IconAndName>
            <div className="img-background">
              <img src={retailerOutline} alt="opção Lojista" />
            </div>
            <h3>Lojista</h3>
          </IconAndName>
          <i className="fa fa-chevron-right" />
        </RoleOption>
        <RoleOption
          className="ac_etapa_1_seletor_botaofornecedor"
          onClick={() => onSuccess(true)}
        >
          <IconAndName>
            <div className="img-background">
              <img src={factoryOutline} alt="opção Indústria" />
            </div>
            <h3>Indústria</h3>
          </IconAndName>
          <i className="fa fa-chevron-right" />
        </RoleOption>
      </OptionsWrapper>

      <RepText>
        É um <strong>Representante Comercial</strong>?{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://repnota1000.blu.com.br/"
        >
          Cadastre-se agora na Comunidade Blu Rep.
        </a>
      </RepText>
    </>
  );
};

DistributorForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default DistributorForm;
