import { createActions, createReducer } from 'reduxsauce';
import _ from 'lodash';

const { Types, Creators } = createActions({
  getCompaniesStoreRequest: ['currentClientUuid'],
  getCompaniesStoreSuccess: ['data'],
  getCompaniesStoreFailure: null,
  updateClientSelectorEconomicGroup: ['clientId']
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false
};

export const request = (state) => ({
  ...state,
  loading: true
});

export const success = (state, action) => ({
  ...state,
  data: action.data,
  loading: false
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const updateClientSelectorEconomicGroup = (state, action) => {
  const index = _.findIndex(state.data, { id: action.clientId });

  const items = [...state.data];
  const item = { ...items[index] };

  item.inEconomicGroup = !item.inEconomicGroup;
  items[index] = item;

  return {
    ...state,
    data: items
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_COMPANIES_STORE_REQUEST]: request,
  [Types.GET_COMPANIES_STORE_SUCCESS]: success,
  [Types.GET_COMPANIES_STORE_FAILURE]: failure,
  [Types.UPDATE_CLIENT_SELECTOR_ECONOMIC_GROUP]: updateClientSelectorEconomicGroup
});
