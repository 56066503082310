import {
  getClient,
  submitClient,
  findAddressByZipcode,
  findAddressByCpfCnpj
} from 'api/first-access';
import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/firstAccess/clientInfo';
import sharedActionCreator from 'store/ducks/firstAccess/shared';

import _ from 'lodash';

export function* loadClientInfo(uuid) {
  const response = yield call(getClient, uuid);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(
      ActionCreators.firstAccessClientInfoSuccess(data.data.clientFind[0])
    );
  } else {
    yield put(ActionCreators.firstAccessClientInfoFailure());
  }
}

export function* saveClientInfo(action) {
  try {
    const response = yield call(submitClient, action.payload);
    if (_.isEmpty(response.data.errors)) {
      yield put({
        type: 'FIRST_ACCESS_CLIENT_INFO_FORM_SUCCESS',
        payload: response.data.firstAccessUpdateClient
      });
    } else {
      throw response.data.errors[0].extensions;
    }
  } catch (error) {
    yield put({
      type: 'FIRST_ACCESS_CLIENT_INFO_FORM_FAILURE',
      payload: error
    });
  }
}

export function* saveClientInfoAddress(params) {
  const { client, address, kind } = params;
  let updatedClient;

  if (_.isEqual(kind, 'mainAddress')) {
    updatedClient = _.merge(client, { mainAddress: address });
  } else {
    updatedClient = _.merge(client, { posDeliveryAddress: address });
  }

  yield put(sharedActionCreator.firstAccessClientSuccess(updatedClient));
  yield put(
    ActionCreators.firstAccessClientInfoSetAddressSuccess(client, address, kind)
  );
}

export function* searchClientAddressByZipcode(params) {
  const response = yield call(findAddressByZipcode, params);
  const { data } = response;

  let address = {};

  if (_.isEmpty(data.errors)) {
    address = {
      ...data.data.addressByCep,
      noFinded: false
    };
  } else {
    address = {
      zipcode: params.data,
      noFinded: true
    };
  }

  yield put(
    ActionCreators.firstAccessClientInfoSetAddressRequest(
      params.client,
      address,
      params.kind
    )
  );
}

export function* searchClientAddressByCpfCnpj(params) {
  const response = yield call(findAddressByCpfCnpj, params);
  const { data } = response;

  let posDeliveryAddress = {};

  if (_.isEmpty(data.errors)) {
    posDeliveryAddress = {
      ...data.data.client.mainAddress,
      noFinded: false
    };
  } else {
    posDeliveryAddress = {
      cpfCnpj: params.cpfCnpj,
      noFinded: true
    };
  }

  yield put(
    ActionCreators.firstAccessClientInfoSetAddressRequest(
      params.client,
      posDeliveryAddress,
      'posDeliveryAddress'
    )
  );
}
