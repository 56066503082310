import maskInput from 'utils/masks';

$(() => {
  $('[data-mask]').each(function () {
    const mask = $(this).data('mask');

    if (mask == 'percentage') {
      const value = $(this).val();

      if (parseInt(value) == parseFloat(value)) {
        $(this).val(parseFloat(value).toFixed(2));
      }
    }

    maskInput(this, mask);
  });

  $('.flash-message--auto-close').delay(5000).fadeOut();

  $('.flash-message--auto-close')
    .find('.flash-message-close')
    .click(function close() {
      $(this).parent().stop().fadeOut();
    });

  $('[data-toggle-value]').each(function () {
    const self = $(this);
    const toggler = $($(this).data('toggle'));
    const value = $(this).data('toggle-value');

    toggler
      .on('change', function () {
        self.toggle($(this).val() === value);
      })
      .trigger('change');
  });
});
