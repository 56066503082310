import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { normalizeCurrency } from 'normalizers';
import RefundSimulationStepper from './RefundSimulationStepper';
import RefundSimulationStep from './RefundSimulationStep';
import RefundSimulationResultStep from './RefundSimulationResultStep';
import RefundSimulationInfoTooltip from './RefundSimulationInfoTooltip';

const RefundSimulationRoot = ({
  maxValueToRefund,
  minValueToRefund,
  clientUuid,
  uuid,
  formAction,
  authenticityToken,
  canRefund,
  unavailableReason
}) => {
  const [isOpen, toggleCollapsible] = useState(false);
  const [activeStep, setActiveStep] = useState({ index: 0, data: {} });

  return (
    <ul
      className={classNames('refund-payment-collection__collapsible', {
        'refund-payment-collection__collapsible--disabled': !canRefund
      })}
    >
      <li>
        <div
          className={classNames(
            'refund-payment-collection__collapsible-header',
            {
              'refund-payment-collection__collapsible-header--disabled': !canRefund
            }
          )}
          onClick={() => canRefund && toggleCollapsible(!isOpen)}
          onKeyPress={(e) =>
            e.keyCode === 13 && canRefund && toggleCollapsible(!isOpen)
          }
        >
          <i className={`fa ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
          <div className="refund-payment-collection__collapsible-header-content">
            <label>Devolução {canRefund ? 'disponível' : 'indisponível'}</label>
            {unavailableReason && (
              <RefundSimulationInfoTooltip text={unavailableReason} />
            )}
          </div>
        </div>
        {isOpen && (
          <div className="refund-payment-collection__collapsible-body">
            <RefundSimulationStepper
              steps={[{ label: 'Simulação' }, { label: 'Valores à devolver' }]}
              activeIndex={activeStep.index}
            />
            {activeStep.index === 0 && (
              <RefundSimulationStep
                clientUuid={clientUuid}
                uuid={uuid}
                maxValueToRefund={normalizeCurrency(
                  parseFloat(maxValueToRefund)
                )}
                minValueToRefund={normalizeCurrency(
                  parseFloat(minValueToRefund)
                )}
                setActiveStep={setActiveStep}
                {...activeStep.data}
              />
            )}
            {activeStep.index === 1 && (
              <RefundSimulationResultStep
                formAction={formAction}
                setActiveStep={setActiveStep}
                authenticityToken={authenticityToken}
                {...activeStep.data}
              />
            )}
          </div>
        )}
      </li>
    </ul>
  );
};

RefundSimulationRoot.propTypes = {
  maxValueToRefund: PropTypes.string.isRequired,
  minValueToRefund: PropTypes.string.isRequired,
  clientUuid: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  formAction: PropTypes.string.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  canRefund: PropTypes.bool,
  unavailableReason: PropTypes.string
};

RefundSimulationRoot.defaultProps = {
  canRefund: false,
  unavailableReason: ''
};

export default RefundSimulationRoot;
