import styled from 'styled-components';

export const Header = styled.div`
  @media (max-width: 768px) {
    align-items: center;
    background-color: #fff;
    display: flex;
    margin-bottom: 10px;
    left: 0;
    position: relative;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 10;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 600;
    width: 100%;
  }
`;

export const SubTitle = styled.p`
  font-size: 16px;
  margin-top: 16px;
`;

export const ButtonNavigation = styled.button`
  display: none;
  @media (max-width: 768px) {
    background: none;
    color: #000aff;
    display: initial;
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: ${(props) => (props.backButton ? '20px' : 'initial')};
    right: ${(props) => (!props.backButton ? '20px' : 'initial')};
    top: 20px;
    z-index: 11;
  }
`;

export default Header;
