import axios from 'axios';
import humps from 'lodash-humps';
import { getCSRFToken } from 'utils/csrf-token';

export const performTransactionAuthorization = (id, code) =>
  axios.patch(
    `/api/transaction_authorizations/${id}/perform`,
    { code },
    {
      headers: {
        Accept: 'version=3',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );

export const showTransactionAuthorization = (id) =>
  axios.get(`/api/transaction_authorizations/${id}`, {
    headers: { Accept: 'version=3' },
  });

export const getUserInformation = () => {
  return axios
    .get('/api/users/authenticated', {
      headers: { Accept: 'version=1', 'X-CSRF-Token': getCSRFToken() },
    })
    .then((response) => {
      return humps(response?.data?.user);
    });
};

export const sendEmailAutomaticAnticipation = (
  clientUUID,
  enableForRepresentingClients
) =>
  axios.post(
    `/api/clients/${clientUUID}/automatic_anticipations/transaction_authorizations/send_email`,
    {
      enable_for_representing_clients: enableForRepresentingClients,
    },
    {
      headers: {
        Accept: 'version=2',
        Authorization: getCSRFToken(),
      },
    }
  );

export const resendNotificationApi = (token) =>
  axios.post(
    `/api/transaction_authorizations/${token}/resend_notification`,
    null,
    {
      headers: {
        Accept: 'version=3',
        'X-CSRF-Token': getCSRFToken(),
      },
    }
  );
