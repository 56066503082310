import React from 'react';
import PropTypes from 'prop-types';
import { currencyToNumber, normalizeSeparator } from 'normalizers';
import CnpjSelection from './CnpjSelection';

const CnpjSelectionAutomaticAntecipation = ({
  isChecked,
  nextStep,
  header,
  prevStep,
  loading,
  automaticAnticipationVariableRate,
}) => (
  <CnpjSelection
    header={header}
    title="Para quais lojas deseja ativar a antecipação automática?"
    description={
      <>
        Você vai receber no dia seguinte o valor de todas as suas vendas feitas
        no crédito a uma{' '}
        {`taxa variável de ${automaticAnticipationVariableRate}% + ${normalizeSeparator(
          currencyToNumber(automaticAnticipationVariableRate) / 2,
          false
        )}% por parcela*.`}
      </>
    }
    nextStep={nextStep}
    prevStep={prevStep}
    loading={loading}
    hasInfo
    isChecked={isChecked}
  />
);

CnpjSelectionAutomaticAntecipation.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  header: PropTypes.object,
  isChecked: PropTypes.bool,
  loading: PropTypes.bool,
  automaticAnticipationVariableRate: PropTypes.string,
};

CnpjSelectionAutomaticAntecipation.defaultProps = {
  loading: false,
  isChecked: false,
  header: 'Seleção de loja',
  automaticAnticipationVariableRate: '',
};

export default CnpjSelectionAutomaticAntecipation;
