import React from 'react';

import {
  SecuritySection,
  SecurityImageContainer,
  SecurityContent
} from './styles';
import SecurityImage from './images/security-system.svg';

const Security = () => (
  <SecuritySection>
    <SecurityImageContainer>
      <img src={SecurityImage} alt="Segurança" />
    </SecurityImageContainer>
    <SecurityContent>
      <h1>Novo sistema mais seguro para você.</h1>
      <div>
        <img src={SecurityImage} alt="Segurança" />
        <p>Atualizamos o sistema antifraude do aplicativo Blu Venda +.</p>
      </div>

      <ul>
        <li>+ confiança entre você e seus clientes.</li>
        <li>+ segurança para você.</li>
      </ul>
    </SecurityContent>
  </SecuritySection>
);

export default Security;
