export default function accountStatementStatus(status) {
  switch (status) {
    case 'confirmed_processed_true':
    case 'confirmed':
    case 'processed':
    case 'paid':
    case 'charged_paid':
    case 'charger_paid':
    case 'confirmed_inflow':
      return 'positive';
    case 'automatic_analysis_waiting_false':
    case 'automatic_analysis_pending_false':
    case 'automatic_analysis':
    case 'analysis_inflow':
    case 'pending_inflow':
    case 'system_rejected_inflow':
    case 'antifraud_analysis_inflow':
    case 'confirmed_pending_false':
    case 'confirmed_waiting_process_true':
    case 'confirmed_waiting_process_false':
    case 'confirmed_registered_false':
    case 'processing_advancement_waiting_false':
    case 'processing_pre_registration_advancement':
    case 'processing_pre_registration_advancement_waiting_false':
      return 'neutral-02';
    case 'new':
    case 'pending_waiting_false':
    case 'waiting':
    case 'pre_registration':
    case 'pre_registration_waiting_false':
    case 'pending':
      return 'warning';
    case 'canceled':
    case 'canceled_inflow':
    case 'canceled_by_client_future_value_inflow':
    case 'canceled_by_client_and_value_already_liquidated_inflow':
    case 'canceled_pending_funds_inflow':
    case 'canceled_and_sent_acquirer_inflow':
    case 'canceled_by_acquirer_inflow':
    case 'canceled_refunded_false':
    case 'canceled_refunded_true':
    case 'pending_refunded_false':
      return 'negative';
    default:
      return 'neutral';
  }
}
