import { useState, useEffect } from 'react';
import axios from 'axios';

import useClientFeatureStatus from 'components/Dashboard/hooks/useClientFeatureStatus';

export default function useMultidepartmentStatus() {
  const { clientUUID } = useClientFeatureStatus();
  const [boletoFacilStatus, setBoletoFacilStatus] = useState();
  const [contractAssignedStatus, setContractAssignedStatus] = useState();
  const [multiacquirerStatus, setMltiacquirerStatus] = useState();

  function getMultidepartmentStatus() {
    const query = `
      query {
        client(uuid: "${clientUUID}"){
          contractAssigned
          boletoFacil
          multiacquirer
        }
      }
    `;

    return axios
      .post('/graphql', { query })
      .then((response) => {
        const { client } = response.data.data;

        setBoletoFacilStatus(client.boleto_facil);
        setContractAssignedStatus(client.contract_assigned);
        setMltiacquirerStatus(client.multiacquirer);
      })
      .catch((error) => {
        console.log(error);
        throw new Error();
      });
  }

  useEffect(() => {
    getMultidepartmentStatus();
  }, []);

  return {
    boletoFacilStatus,
    contractAssignedStatus,
    multiacquirerStatus
  };
}
