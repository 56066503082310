import { getRepresentativesNames } from '.';

describe('getRepresentativesNames', () => {
  it('returns only legal representatives', () => {
    const bigBoostData = [
      {
        cpfCnpj: '123456789',
        documentType: 'CPF',
        name: 'Administrador 1',
        position: 'Administrador',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '123789456',
        documentType: 'CPF',
        name: 'Representante Legal 1',
        position: '',
        relationshipType: 'REPRESENTANTELEGAL'
      },
      {
        cpfCnpj: '987654321',
        documentType: 'CNPJ',
        name: 'Socio S.A.',
        position: 'Sócio',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '123123123',
        documentType: 'CPF',
        name: 'Administrador 2',
        position: 'Administrador',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '111222333',
        documentType: 'CPF',
        name: 'Administrador 3',
        position: 'Sócio',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '456456456',
        documentType: 'CPF',
        name: 'Administrador 4',
        position: 'Sócio Administrador',
        relationshipType: 'OWNERSHIP'
      },
      {
        cpfCnpj: '789789789',
        documentType: 'CPF',
        name: 'Representante Legal 2',
        position: '',
        relationshipType: 'REPRESENTANTELEGAL'
      },
      {
        cpfCnpj: '777888999',
        documentType: 'CPF',
        name: 'Representante',
        position: '',
        relationshipType: 'SQL'
      }
    ];

    const result = getRepresentativesNames(bigBoostData);

    expect(result.length).toBe(2);
    expect(result[0].name).toBe('Representante Legal 1');
    expect(result[1].name).toBe('Representante Legal 2');
  });

  it('returns only admin representatives when there are no legal representatives', () => {
    const bigBoostData = [
      {
        cpfCnpj: '123456789',
        documentType: 'CPF',
        name: 'Administrador 1',
        position: 'Administrador',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '987654321',
        documentType: 'CNPJ',
        name: 'Socio S.A.',
        position: 'Sócio',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '123123123',
        documentType: 'CPF',
        name: 'Administrador 2',
        position: 'Administrador',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '111222333',
        documentType: 'CPF',
        name: 'Administrador 3',
        position: 'Sócio',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '456456456',
        documentType: 'CPF',
        name: 'Administrador 4',
        position: 'Sócio Administrador',
        relationshipType: 'OWNERSHIP'
      },
      {
        cpfCnpj: '777888999',
        documentType: 'CPF',
        name: 'Representante',
        position: '',
        relationshipType: 'SQL'
      }
    ];

    const result = getRepresentativesNames(bigBoostData);

    expect(result.length).toBe(3);
    expect(result[0].name).toBe('Administrador 1');
    expect(result[1].name).toBe('Administrador 2');
    expect(result[2].name).toBe('Administrador 4');
  });

  it('returns only QSA or Ownership representatives when there are no legal representatives nor admin representatives', () => {
    const bigBoostData = [
      {
        cpfCnpj: '123456789',
        documentType: 'CPF',
        name: 'Sócio 1',
        position: 'Sócio',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '987654321',
        documentType: 'CNPJ',
        name: 'Socio S.A.',
        position: 'Sócio',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '123123123',
        documentType: 'CPF',
        name: 'Sócio 2',
        position: 'Sócio',
        relationshipType: 'QSA'
      },
      {
        cpfCnpj: '456456456',
        documentType: 'CPF',
        name: 'Sócio 3',
        position: 'Sócio',
        relationshipType: 'OWNERSHIP'
      },
      {
        cpfCnpj: '777888999',
        documentType: 'CPF',
        name: 'Representante',
        position: '',
        relationshipType: 'SQL'
      }
    ];

    const result = getRepresentativesNames(bigBoostData);

    expect(result.length).toBe(3);
    expect(result[0].name).toBe('Sócio 1');
    expect(result[1].name).toBe('Sócio 2');
    expect(result[2].name).toBe('Sócio 3');
  });
});

describe('removeDuplicatesByCpfCnpj', () => {
  it('removes duplicate representatives', () => {
    const bigBoostData = [
      {
        cpfCnpj: '789789789',
        documentType: 'CPF',
        name: 'Representante Legal 2',
        position: '',
        relationshipType: 'REPRESENTANTELEGAL'
      },
      {
        cpfCnpj: '789789789',
        documentType: 'CPF',
        name: 'CPF igual e nome diferente',
        position: '',
        relationshipType: 'REPRESENTANTELEGAL'
      }
    ];

    const result = getRepresentativesNames(bigBoostData);

    expect(result.length).toBe(1);
    expect(result[0].name).toBe('Representante Legal 2');
  });
});
