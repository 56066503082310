import { createReducer } from 'reduxsauce';
import storage from 'redux-persist/lib/storage';

import { types } from './actions';

export const persistConfig = {
  key: 'bluRating',
  storage,
  whitelist: ['byKey']
};

const initialState = {
  loading: false,
  error: null,
  byKey: {}
};

export default createReducer(initialState, {
  [types.FETCH_RATING_BY_CLIENT_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: null
  }),
  [types.FETCH_RATING_BY_CLIENT_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    byKey: {
      ...state.byKey,
      [action.cpfCnpj]: {
        key: action.cpfCnpj,
        fetchedAt: Date.now(),
        ...action.data
      }
    }
  }),
  [types.FETCH_RATING_BY_CLIENT_FAILURE]: (state) => ({
    ...state,
    loading: false,
    error: true
  })
});
