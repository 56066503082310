import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { normalizeValue } from 'normalizers';

import { BalanceContainer, Total, Value, Item, Title } from './styles';
import totalSvg from '../images/total.svg';

const Balance = ({
  total,
  current,
  future,
  externalWallet,
  isMobile,
  className,
}) => {
  const { total: totalWallet = 0.0 } = externalWallet;

  const classNamesTotalValue = useMemo(
    () => classNames({ isNegative: total * 1 < 0 }),
    [total]
  );

  return (
    <BalanceContainer className={className}>
      {!isMobile ? (
        <Total className={classNamesTotalValue}>
          <img src={totalSvg} alt="Total" />
          <h1>{normalizeValue(total)}</h1>
          <p>Seu saldo disponível é composto por:</p>
        </Total>
      ) : (
        <Item>
          <Title>Saldo disponível</Title>
          <Value>{normalizeValue(total)}</Value>
        </Item>
      )}
      <Item>
        <Title>Saldo atual</Title>
        <Value>{normalizeValue(current)}</Value>
      </Item>
      <Item>
        <Title>Agenda Blu</Title>
        <Value>{normalizeValue(future)}</Value>
      </Item>

      <Item>
        <Title>Agenda Outras maquininhas</Title>
        <Value>{normalizeValue(totalWallet)}</Value>
      </Item>
    </BalanceContainer>
  );
};

Balance.propTypes = {
  total: PropTypes.string,
  current: PropTypes.string,
  future: PropTypes.string,
  externalWallet: PropTypes.object,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
};

Balance.defaultProps = {
  className: '',
  total: '0.0',
  current: '0.0',
  future: '0.0',
  externalWallet: {},
  isMobile: false,
};

export default Balance;
