import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
  fetchPaymentCollectionDetailsRequest: ['uuid', 'isCharger'],
  fetchPaymentCollectionDetailsSuccess: ['data'],
  fetchPaymentCollectionDetailsFailure: ['error']
});

export { types };

export default actions;
