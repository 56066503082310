import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReadOnlyField from 'components/Application/ReadOnlyField';
import { maskCpfOrCnpj } from 'utils/masks';
import humps from 'lodash-humps';
import Collapse from 'components/Shared/Collapse';

class Client extends Component {
  constructor(props) {
    super(props);
    this.client = humps(props.client);
  }

  renderDetails = (client, isExternalClient, externalClient) => {
    if (client.bankAccount) {
      return this.renderExternalClientDetails(this.client);
    }
    if (client.cpfCnpj) {
      return this.renderIternalClientDetails(
        client,
        isExternalClient,
        externalClient
      );
    }

    return null;
  };

  renderIternalClientDetails = (client, isExternalClient, externalClient) => (
    <div className="detail__content" ref={(ref) => (this.containerRef = ref)}>
      <h4 className="detail__title">Detalhes da conta</h4>
      <ReadOnlyField
        label="Favorecido"
        value={isExternalClient ? externalClient?.name : client?.name}
      />
      {!isExternalClient && <ReadOnlyField label="Tipo da Conta" value="Blu" />}
      <ReadOnlyField
        label="CPF/CNPJ"
        value={maskCpfOrCnpj(
          isExternalClient ? externalClient?.cpf_cnpj : client.cpfCnpj
        )}
      />
    </div>
  );

  renderExternalClientDetails = (client) => {
    const accountType = client.bankAccount.savingsAccountOperation
      ? 'Conta Poupança'
      : 'Conta Corrente';

    let { office } = client.bankAccount;

    office =
      office.indexOf('-') > -1
        ? office.substring(0, office.indexOf('-'))
        : office;

    return (
      <div className="detail__content" ref={(ref) => (this.containerRef = ref)}>
        <h4 className="detail__title">Detalhes da conta</h4>
        <ReadOnlyField
          label="Banco"
          value={
            typeof client.bankAccount.bank === 'object'
              ? client.bankAccount.bank.name
              : client.bankAccount.bank
          }
        />
        <ReadOnlyField label="Agência" value={office} />
        <ReadOnlyField label="Conta" value={client.bankAccount.number} />
        <ReadOnlyField
          label="Favorecido"
          value={client.bankAccount.ownerName}
        />
        <ReadOnlyField
          label="Nome ou razão social do titular da conta"
          value={client.bankAccount.nickname}
        />
        <ReadOnlyField label="Tipo da Conta" value={accountType} />
        <ReadOnlyField
          label="CPF/CNPJ"
          value={maskCpfOrCnpj(client.bankAccount.cpfCnpj)}
        />
      </div>
    );
  };

  render() {
    const { bankTransferId, otherProvidersOutflowId } = railsState.railsContext;
    const { transferCategoryId, isExternalClient, externalClient } = this.props;

    const clientAccountType =
      transferCategoryId === bankTransferId ||
      transferCategoryId === otherProvidersOutflowId
        ? 'conta bancária'
        : `conta ${railsState.railsContext.storeName}`;

    let clientName =
      transferCategoryId === bankTransferId ||
      transferCategoryId === otherProvidersOutflowId
        ? this.client.bankAccount.ownerName
        : this.client.name;

    if (this.client.bankAccount || this.client.cpfCnpj) {
      clientName += ` | ${clientAccountType}`;
    }

    const details = this.renderDetails(
      this.client,
      isExternalClient,
      externalClient
    );

    return (
      <div className="transaction-client">
        <div className="form-row">
          <ReadOnlyField
            label="Favorecido"
            value={isExternalClient ? externalClient?.name : clientName}
          >
            {details && (
              <Collapse
                getContainerRef={() => this.containerRef}
                arrowColor="#8698bc"
              />
            )}
          </ReadOnlyField>
        </div>
        {details}
      </div>
    );
  }
}

Client.propTypes = {
  client: PropTypes.object.isRequired,
  transferCategoryId: PropTypes.number,
  externalClient: PropTypes.object,
  isExternalClient: PropTypes.bool,
};

Client.defaultProps = {
  transferCategoryId: null,
  externalClient: {},
  isExternalClient: false,
};

export default Client;
