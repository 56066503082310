import styled from 'styled-components';
import { Devices } from 'utils/getDeviceMedia';
import {
  spacingStackXs,
  spacingInlineXs,
  spacingInlineSm,
  colorBrandPrimaryPure,
  fontSizeSm,
  lineHeightMedium,
} from '@useblu/ocean-tokens/web/tokens';

export const TechnicalProblemsContent = styled.div`
  align-items: center;
  display: flex;
  gap: ${spacingInlineSm};

  > .texts-content {
    &:first-of-type {
      flex: 1;
    }

    &:last-of-type {
      display: flex;
      flex-direction: column;
      gap: ${spacingStackXs};
      flex: 2;

      > .ods-typography__heading2 {
        color: ${colorBrandPrimaryPure};
      }
    }
  }

  @media ${Devices.mobile} {
    align-items: center;
    flex-direction: column;

    > .texts-content {
      > .ods-typography__heading2 {
        font-size: ${fontSizeSm};
        line-height: ${lineHeightMedium};
      }

      .ods-typography__paragraph {
        text-align: center;
      }
    }
  }
`;

export const IlustrationLimitContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 120px;
  @media ${Devices.mobile} {
    width: 132px;
  }
`;

export const TechnicalProblemsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${spacingInlineXs};
  margin-top: ${spacingStackXs};

  @media ${Devices.mobile} {
    flex-direction: column-reverse;
  }
`;
