import axios from 'axios';

export function twoFactorAuthenticationService(token, userToken) {
  return axios.post(
    `/api/two_factor`,
    {
      user: {
        token,
        user_token: userToken
      }
    },
    {
      headers: {
        Accept: 'version=1, application/json',
        'Content-Type': 'application/json'
      }
    }
  );
}

export function resendTokenService(userToken) {
  return axios.post(
    `/api/two_factor/resend_token`,
    {
      user: {
        user_token: userToken
      }
    },
    {
      headers: {
        Accept: 'version=1, application/json',
        'Content-Type': 'application/json'
      }
    }
  );
}
