import axios from 'axios';
import _ from 'lodash';
import humps from 'lodash-humps';

export function validateTransaction(params, clientId) {
  return axios.post(`/lojas/${clientId}/transacoes/validate.json`, {
    client_transaction: params,
  });
}

const dateAttr = (date) =>
  _.isEmpty(date) && !_.isDate(date) ? null : `"${date}"`;

const validateAttr = (value) => (_.isEmpty(value) ? null : `"${value}"`);

const buildBankTransactionsParams = (bankTransactionsAttributes) => {
  if (!bankTransactionsAttributes || !bankTransactionsAttributes.length)
    return '';

  const bankTransactionsParams = humps(bankTransactionsAttributes[0]);

  if (bankTransactionsParams.billBarcode) {
    return `
    ,bankTransactionsAttributes: [{
      dueDateOn: ${
        !bankTransactionsParams.dueDateOn
          ? null
          : `"${bankTransactionsParams.dueDateOn}"`
      },
      billBarcode: "${bankTransactionsParams.billBarcode}",
      billPaymentOn: "${bankTransactionsParams.billPaymentOn}",
      billReceiverName: "${bankTransactionsParams.billReceiverName}"
      billReceiverCpfCnpj: ${validateAttr(
        bankTransactionsParams.billReceiverCpfCnpj
      )},
    }]`;
  }

  if (bankTransactionsParams.pixAttributes) {
    const pix = bankTransactionsParams.pixAttributes;

    return `
      ,bankTransactionsAttributes: [{
        dueDateOn: ${dateAttr(pix.dueDateOn)},
        pixableId: "${pix.pixableId}",
        pixableType: "${pix.pixableType}",
        baasRequestId: ${pix.baasRequestId ? `"${pix.baasRequestId}"` : null},
        bankOperationId: ${
          pix.baasReferenceId ? `"${pix.baasReferenceId}"` : null
        }
      }]
    `;
  }

  if (bankTransactionsParams.feePaymentAttributes) {
    const feePayment = bankTransactionsParams.feePaymentAttributes;

    return `
      ,bankTransactionsAttributes: [{
        dueDateOn: ${dateAttr(bankTransactionsParams.dueDateOn)},
        billPaymentOn: ${dateAttr(bankTransactionsParams.billPaymentOn)},
        feePaymentAttributes: {
          name: "${feePayment.name}",
          cpfCnpj: "${feePayment.cpfCnpj}",
          treasuryRevenueCode: "${feePayment.treasuryRevenueCode}",
          referenceNumber: "${feePayment.referenceNumber}",
          feeType: "${feePayment.feeType}",
          referenceDate: ${dateAttr(feePayment.referenceDate)},
          mainAmount: ${feePayment.mainAmount},
          interestAmount: ${feePayment.interestAmount},
          fineAmount: ${feePayment.fineAmount}
        }
      }]
    `;
  }

  return `
    ,bankTransactionsAttributes: [{
      bankAccountId: "${bankTransactionsParams.bankAccountId}",
      dueDateOn: ${
        bankTransactionsParams.dueDateOn
          ? `"${bankTransactionsParams.dueDateOn}"`
          : null
      }
    }]
  `;
};

export function createPaymentAsyncSimulation(
  params,
  clientId,
  clientTransactionUUID
) {
  const {
    grossValue,
    releasedAt,
    transactionCategoryId,
    maximumOutflowValue,
    preRegistration,
    inflowReleasedAt,
    bankTransactionsAttributes,
    clientReceiverId,
    headquartersId,
    similarTransaction,
    dailyTransferLimit,
  } = params;

  const bankTransactionsParams = buildBankTransactionsParams(
    bankTransactionsAttributes
  );

  const query = `
    mutation {
      createPaymentAsyncSimulation(
        uuid: "${clientTransactionUUID || ''}",
        clientUuid: "${clientId}",
        grossValue: ${grossValue},
        releasedAt: ${dateAttr(releasedAt)},
        inflowReleasedAt: ${dateAttr(inflowReleasedAt)},
        transactionCategoryId: ${transactionCategoryId},
        maximumOutflowValue: ${maximumOutflowValue || false},
        similarTransactions: ${
          typeof similarTransaction === 'undefined' ? true : similarTransaction
        },
        dailyTransferLimit: ${
          typeof dailyTransferLimit === 'undefined' ? true : dailyTransferLimit
        },
        preRegistration: ${preRegistration},
        clientReceiverId: ${clientReceiverId || null},
        headquartersId: ${headquartersId ? `"${headquartersId}"` : null}
        ${bankTransactionsParams}
      ) {
        id
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function getPaymentSimulation(clientUuid, simulationRequestId) {
  const query = `
    query {
      paymentSimulationRequest(
        id: "${simulationRequestId}",
        clientUuid: "${clientUuid}"
      ) {
        clientTransaction {
          errors {
            fullMessages
          }
          uuid
          grossValue
          netValue
          advancesRateValue
          advancedValue
          anticipationRate
          happenedAt
          releasedAt
          lastTransactionAdvancedFrom
          transactionCategoryId
          bankTransactionRateValue
          withdrawalRateValue
          status
          providerNsuCode
          bankTransactions {
            bankAccountId
            billBarcode
            billPaymentOn
            billReceiverName
            dueDateOn
          }
        }
        dailyTransferLimit
        similarTransactions {
          grossValue
          netValue
          createdAt
        }
        simulationRequestId
        advancementSimulation {
          advancementValue
          advancementRateType
          advancementRateValue
          advancementRateTotal
          advancementTotalCost
          advancementAverageTerm
          advancementDesiredAverageTerm
          advancementAverageTermInMonths
          advancementItems {
            kind
            receivableId
            acquirerName
            advancedDays
            advancementCost
            advancementValue
            providerBrandName
            originalReleaseAt
            advancementRateValue
          }
        }
        advancementSimulationsEconomicGroup {
          clientUuid
          advancementValue
          advancementItems {
            kind
            receivableId
            acquirerName
            advancedDays
            advancementCost
            advancementValue
            providerBrandName
            originalReleaseAt
            advancementRateValue
          }
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function createPaymentSimulation(
  params,
  clientId,
  clientTransactionUUID
) {
  const {
    grossValue,
    releasedAt,
    transactionCategoryId,
    maximumOutflowValue,
    preRegistration,
    inflowReleasedAt,
    bankTransactionsAttributes,
    clientReceiverId,
    headquartersId,
    similarTransaction,
    dailyTransferLimit,
  } = params;

  const bankTransactionsParams = buildBankTransactionsParams(
    bankTransactionsAttributes
  );

  const query = `
    mutation {
      createPaymentSimulation(
        uuid: "${clientTransactionUUID || ''}",
        clientUuid: "${clientId}",
        grossValue: ${grossValue},
        releasedAt: ${dateAttr(releasedAt)},
        inflowReleasedAt: ${dateAttr(inflowReleasedAt)},
        transactionCategoryId: ${transactionCategoryId},
        maximumOutflowValue: ${maximumOutflowValue || false},
        similarTransactions: ${
          typeof similarTransaction === 'undefined' ? true : similarTransaction
        },
        dailyTransferLimit: ${
          typeof dailyTransferLimit === 'undefined' ? true : dailyTransferLimit
        },
        preRegistration: ${preRegistration},
        clientReceiverId: ${clientReceiverId || null},
        headquartersId: ${headquartersId ? `"${headquartersId}"` : null}
        ${bankTransactionsParams}
      ) {
        clientTransaction {
          errors {
            fullMessages
          }
          uuid
          grossValue
          netValue
          advancesRateValue
          advancedValue
          anticipationRate
          happenedAt
          releasedAt
          lastTransactionAdvancedFrom
          transactionCategoryId
          bankTransactionRateValue
          withdrawalRateValue
          status
          providerNsuCode
          bankTransactions {
            bankAccountId
            billBarcode
            billPaymentOn
            billReceiverName
            dueDateOn
          }
        }
        dailyTransferLimit
        similarTransactions {
          grossValue
          netValue
          createdAt
        }
        advancementSimulation {
          advancementValue
          advancementRateType
          advancementRateValue
          advancementRateTotal
          advancementTotalCost
          advancementAverageTerm
          advancementDesiredAverageTerm
          advancementAverageTermInMonths
          advancementItems {
            kind
            receivableId
            acquirerName
            advancedDays
            advancementCost
            advancementValue
            providerBrandName
            originalReleaseAt
            advancementRateValue
          }
        }
        advancementSimulationsEconomicGroup {
          clientUuid
          advancementValue
          advancementItems {
            kind
            receivableId
            acquirerName
            advancedDays
            advancementCost
            advancementValue
            providerBrandName
            originalReleaseAt
            advancementRateValue
          }
        }
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function confirmTransaction(params, clientId) {
  const csrfToken = document.querySelector('[name=csrf-token]').content;
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  return axios.post(`/lojas/${clientId}/transacoes.json`, {
    client_transaction: params,
  });
}

export function createPaymentApi(params, clientId) {
  const { bank_transactions_attributes } = params;

  const bankTransactionsParams = buildBankTransactionsParams(
    bank_transactions_attributes
  );

  const simulationRequestId =
    params.simulation_request_id === undefined
      ? ''
      : params.simulation_request_id;

  const query = `
    mutation {
      createPayment(
        clientUuid: "${clientId}",
        simulationRequestId: "${simulationRequestId}",
        params: {
          uuid: "${params.uuid}",
          transactionCategoryId: ${params.transaction_category_id},
          grossValue: ${params.gross_value},
          releasedAt: "${params.released_at}",
          maximumOutflowValue: ${params.maximum_outflow_value || false},
          clientReceiverId: ${params.client_receiver_id || null},
          headquartersId: ${
            params.headquarters_id ? `"${params.headquarters_id}"` : null
          },
          inflowReleasedAt: ${dateAttr(params.inflow_released_at)},
          similarTransactionConfirmed: ${
            params.similar_transaction_confirmed || false
          },
          transferLimitConfirmed: ${params.transfer_limit_confirmed || false},
          preRegistration: ${params.pre_registration},
          observation: "${
            _.isEmpty(params.observation) ? '' : params.observation
          }",
          ${bankTransactionsParams}
        }
      ) {
        id
        uuid
        grossValue
        netValue
        transactionCategory {
          id
          name
        }
        anticipationRate
        advancedValue
        nature
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function formatError(error) {
  const globalErrors = _.compact(
    _.concat(error.client_receiver, error.client_status)
  );

  const fixedKeys = { ...error, formAttributes: globalErrors };

  return _.mapValues(fixedKeys, (value) => {
    if (value) {
      return value.join(', ');
    }
  });
}

export function redirectIfNotAllowed(status, redirect = false) {
  if (status === 401) {
    const url = window.location.pathname.split('/');
    window.location = `/${url.slice(1, 3).join('/')}/dashboard`;
  } else if (status === 422 && redirect) {
    window.location = window.location.href;
  }
}

export function advancementRequestApi({ advancementRequestUuids, clientUuid }) {
  const query = `
  query {
    advancementRequest(advancementRequestIds: [${advancementRequestUuids.map(
      (item) => `"${item}"`
    )}], clientUuid: "${clientUuid}") {
      clientUuid
      advancementValue
      advancementRateValue
      advancementTotalCost
      advancementItems {
        kind
        advancedDays
        receivableId
        providerBrandName
        acquirerName
        advancementCost
        advancementValue
        advancementRateValue
        originalReleaseAt
      }
    }
  }`;

  return axios.post('/graphql', { query });
}
