import Jcep from 'jcep';

export default class AddressForm {
  constructor(options) {
    this.setupFields();
    $('.jcep-toggle').toggle(options.startOpen || false);
  }

  showForm(error) {
    $('.jcep-toggle').show();

    this.hideLoading();

    if (!error) {
      $('[data-neighborhood], [data-street], [data-city], [data-state]').attr(
        'readOnly',
        true
      );
    }
  }

  showLoading() {
    $('[data-cep]').parent().addClass('is-loading');
  }

  hideLoading() {
    $('.is-loading').removeClass('is-loading');
  }

  setupFields() {
    this.jcep = new Jcep({
      success: () => this.showForm(),
      error: (error) => this.showForm(error),
      beforeSend: this.showLoading
    });

    $('[data-cep]').on('keyup', function onKeyUp() {
      const val = $(this).val();

      if (
        (val.length === 8 && val.indexOf('-') < 0) ||
        (val.length === 9 && val.indexOf('-') >= 0)
      ) {
        $(this).trigger('change');
        $(this).blur();
      }
    });
  }
}
