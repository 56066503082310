import React from 'react';
import PropTypes from 'prop-types';

const ReadOnlyField = ({ label, value }) => (
  <div className="readonly-field">
    <span className="readonly-field__label">{label}</span>
    <p className="readonly-field__value">{value}</p>
  </div>
);

ReadOnlyField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired
};

export default ReadOnlyField;
