import React from 'react';
import PropTypes from 'prop-types';
import moment from 'utils/moment.pt-br';

// eslint-disable-next-line react/prop-types
const Chip = ({ cssModifier, children }) => (
  <div className={`chip-root chip-root--small chip-color--${cssModifier}`}>
    <div className="chip-label chip-label--small">{children}</div>
  </div>
);

const StatusChip = ({ installment }) => {
  if (installment.status === 'paid') {
    return (
      <Chip cssModifier="success">
        Paga em {moment(installment.paidAt, 'YYYY-MM-DD').format('L')}
      </Chip>
    );
  }

  if (installment.status === 'canceled') {
    return (
      <Chip cssModifier="danger">
        Cancelada em {moment(installment.updatedAt, 'YYYY-MM-DD').format('L')}
      </Chip>
    );
  }

  return (
    <Chip cssModifier="warning">
      Agendada para {moment(installment.scheduledAt, 'YYYY-MM-DD').format('L')}
    </Chip>
  );
};

StatusChip.propTypes = {
  installment: PropTypes.shape({
    status: PropTypes.string.isRequired,
    scheduledAt: PropTypes.string,
    paidAt: PropTypes.string,
    updatedAt: PropTypes.string
  }).isRequired
};

export default StatusChip;
