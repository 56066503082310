import React, { useState, useEffect } from 'react';
import { Button, Modal, Typography } from '@useblu/ocean-react';
import PropTypes from 'prop-types';

import useModalManagement from 'components/Dashboard/hooks/useModalManagement';
import useRailsContext from 'hooks/useRailsContext';
import { modalContentByStatus } from './RegistrationStatusModal.data';

import documentLockSVG from './images/ilustra-lock.svg';
import needRegularizeSVG from './images/ilustra-need-regularize.svg';
import * as S from './style';

const RegistrationStatusModal = ({ registrationStatus }) => {
  const { isModalOpen, feature, closeModal } = useModalManagement();
  const { clientId } = useRailsContext();
  const [modalOpen, setModalOpen] = useState(false);

  const modalContent = modalContentByStatus({
    clientId,
  })[registrationStatus];

  const modalImageByStatus = {
    blu_light_client_need_regularize_CNPJ: needRegularizeSVG,
    blu_light_pending_social_contract: documentLockSVG,
    blu_light_social_contract_under_analysis: documentLockSVG,
    blu_light_social_contract_reproved: documentLockSVG,
    client_need_regularize_CNPJ: documentLockSVG,
    client_under_analysis: documentLockSVG,
    pending_social_contract: documentLockSVG,
    social_contract_reproved: documentLockSVG,
    social_contract_under_analysis: documentLockSVG,
    pending_legal_representative_invite: documentLockSVG,
    blu_light_pending_legal_representative_invite: documentLockSVG,
    social_contract_under_analysis_or_invite_legal_representative:
      documentLockSVG,
    blu_light_social_contract_under_analysis_or_invite_legal_representative:
      documentLockSVG,
    pending_social_contract_or_invite_legal_representative: documentLockSVG,
    blu_light_pending_social_contract_or_invite_legal_representative:
      documentLockSVG,
  };

  useEffect(() => {
    if (feature === 'RegistrationStatusModal') {
      setModalOpen(isModalOpen);
    }
  }, [isModalOpen, feature]);

  const onClose = () => {
    setModalOpen(false);

    if (isModalOpen) {
      closeModal();
    }
  };

  const handleButtonClick = () => {
    // eslint-disable-next-line no-unused-expressions
    modalContent?.buttonLink
      ? (window.location.href = `${modalContent?.buttonLink}`)
      : onClose();
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={onClose}
      portalClassName="ocean-iso"
      maxWidth="sm"
      ariaHideApp={false}
    >
      <S.GlobalStyled />
      <S.ModalContainer>
        <img
          src={modalImageByStatus[registrationStatus]}
          alt={modalContent?.title}
        />
        <S.TextContent>
          <Typography variant="heading3">{modalContent?.title}</Typography>
          <Typography variant="description">
            {modalContent?.description}
          </Typography>
        </S.TextContent>
        <S.ButtonContainer>
          <Button onClick={handleButtonClick} variant="primary">
            {modalContent?.buttonDescription}
          </Button>
        </S.ButtonContainer>
      </S.ModalContainer>
    </Modal>
  );
};

RegistrationStatusModal.propTypes = {
  registrationStatus: PropTypes.string,
};

RegistrationStatusModal.defaultProps = {
  registrationStatus: '',
};

export default RegistrationStatusModal;
