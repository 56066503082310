import React from 'react';
import PropTypes from 'prop-types';

import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import store, { history } from 'store';
import { ConnectedRouter } from 'connected-react-router';

import EconomicGroupHistory from 'components/History/EconomicGroupHistory';

const History = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route
          exact
          path="/lojas/:uuid/historico"
          component={(props) => (
            <EconomicGroupHistory {...props.match.params} />
          )}
        />
      </Switch>
    </ConnectedRouter>
  </Provider>
);

History.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({})
  })
};

export default History;
