/* eslint-disable import/prefer-default-export */
import styled, { createGlobalStyle } from 'styled-components';

import {
  borderRadiusMd,
  spacingInlineXs,
  spacingInlineXxs,
  spacingStackSm,
  fontSizeSm,
  fontSizeMd,
  fontSizeXs,
  spacingStackXs,
  spacingInlineMd,
  fontWeightRegular,
  colorInterfaceLightDown,
  colorInterfaceDarkDown,
  colorInterfaceDarkPure,
  colorInterfaceDarkUp,
  spacingStackXxxs,
  spacingStackXxs
} from '@useblu/ocean-tokens/web/tokens';
import { Tag } from '@useblu/ocean-react';
import { Devices } from 'utils/getDeviceMedia';

const devices = {
  mobile: `(max-width: 768px)`
};

export const WrapperButtons = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-top: ${spacingInlineXs};
  margin-top: ${spacingInlineMd};
  border-top: 1px solid ${colorInterfaceLightDown};
  justify-content: flex-end;
  gap: ${spacingInlineXxs};

  @media ${devices.mobile} {
    display: flex;
    flex-direction: column-reverse;
    margin: ${spacingInlineXs} ${spacingInlineXs} ${spacingInlineXs};
  }
`;

export const WrapperResumeValues = styled.div`
  .payment-data {
    position: relative;
    border: 1px solid ${colorInterfaceLightDown};
    border-top-left-radius: ${borderRadiusMd};
    border-top-right-radius: ${borderRadiusMd};
    padding: ${spacingStackSm} ${spacingInlineXs};
    margin-top: ${spacingInlineXs};
    display: flex;
    flex-direction: column;
    gap: ${spacingStackSm};
  }

  .date-information {
    border-left: 1px solid ${colorInterfaceLightDown};
    border-right: 1px solid ${colorInterfaceLightDown};
    border-bottom: 1px solid ${colorInterfaceLightDown};
    padding: ${spacingInlineXs};
    border-bottom-left-radius: ${borderRadiusMd};
    border-bottom-right-radius: ${borderRadiusMd};
    color: ${colorInterfaceDarkUp};
  }

  @media ${devices.mobile} {
    padding: 0 ${spacingInlineXs};

    .payment-data {
      margin-top: ${spacingInlineXs};
    }
  }
`;

export const Bar = styled.div`
  width: 100%;

  .ods-topbar {
    flex-wrap: nowrap;
    height: auto;
    margin-bottom: ${spacingStackSm};
  }

  .ods-topbar-title {
    margin: 0;
    font-size: ${fontSizeMd};

    span {
      font-size: ${fontSizeXs};
      color: ${colorInterfaceDarkDown};
      margin-top: ${spacingStackXxxs};
      font-weight: ${fontWeightRegular};
    }
  }

  @media ${devices.mobile} {
    .ods-topbar-title {
      font-size: ${fontSizeSm};
    }

    .ods-topbar-left {
      margin-left: 0;
      margin-right: ${spacingStackXs};
    }
  }
`;

export const StatusTag = styled(Tag)`
  position: absolute;
  right: 0;
  margin: ${spacingInlineXs} ${spacingStackSm};
`;

export const Container = styled.div`
  .pgnt-page {
    padding-top: ${spacingStackXs};

    & > div:first-of-type {
      border: 0;
    }
  }

  @media ${devices.mobile} {
    .pgnt-page {
      padding: 0;
    }
  }
`;

export const WrapperRefunds = styled.div`
  margin-top: ${spacingInlineXs};

  .ods-transaction-list-item__sub-information {
    width: 25%;
  }

  @media ${devices.mobile} {
    padding: 0 ${spacingInlineXs};
  }
`;

export const ResumeValuesItem = styled.div`
  display: flex;
  gap: ${spacingInlineXs};

  > p {
    margin: 0;

    :last-of-type {
      flex: 2;
      color: ${colorInterfaceDarkPure};
      font-size: ${fontSizeXs};
    }

    :first-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      align-items: flex-start;
      text-align: right;
      flex: 1;
      color: ${colorInterfaceDarkDown};
      font-size: ${fontSizeXs};
    }
  }

  @media ${Devices.mobile} {
    flex-direction: column;
    gap: 0;

    > p {
      :first-of-type {
        justify-content: flex-start;
      }
    }
  }
`;

export const ResumeValuesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingStackXxs};

  @media ${Devices.mobile} {
    gap: ${spacingStackXxs};
  }
`;

export const BreadcrumbStyle = createGlobalStyle`
  .light-main .dark-main-content .breadcrumbs {
    border: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 744px;
    width: 100%;
  }

  .breadcrumbs-container {
    margin-top: 32px;
    padding: 0;
  }
`;

export const RefundStatusTag = styled(Tag)`
  &&& {
    @media ${Devices.mobile} {
      margin: 0 ${spacingInlineXs};
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
`;
