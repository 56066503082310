import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@useblu/ocean-react';
import { normalizeMoney } from '@useblu/utils';
import * as S from '../styles';
import ItemSkeleton from './ItemSkeleton';
import UnderstandCalculation from './UnderstandCalculation';
import AccountStatementInfo from './AccountStatementInfo';
import AnticipationFromGE from './AnticipationFromGE';

const AdvancementDetailsDrawerContent = ({
  uuid,
  isURDetails,
  storesState,
  currentStoreId,
  isAccounStatement,
  detailsAnticipationData,
}) => {
  const redirectToURs = () => {
    window.location.href = `/lojas/${currentStoreId}/movimentacoes/antecipacao-de-recebiveis/${detailsAnticipationData?.value?.uuid}`;
  };

  const belongsToEconomicGroup =
    detailsAnticipationData?.value?.outflow?.belongsToEconomicGroup;

  return (
    <>
      <S.DrawerTitle variant="heading3">
        Detalhes da antecipação
        {belongsToEconomicGroup && (
          <Typography variant="caption">Feita pelo grupo econômico</Typography>
        )}
      </S.DrawerTitle>
      <S.DrawerValues>
        <S.DrawerValueItem>
          <Typography variant="description">
            {detailsAnticipationData?.value?.outflow
              ? 'Valor necessário para concluir o pagamento'
              : 'Valor adicionado ao saldo atual'}
          </Typography>
          <ItemSkeleton
            loading={detailsAnticipationData.loading}
            value={normalizeMoney(detailsAnticipationData?.value?.netValue)}
          />
        </S.DrawerValueItem>
        <S.DrawerValuesDivider />
        <S.DrawerValueItem>
          <Typography variant="description">Custo da antecipação</Typography>
          <ItemSkeleton
            isPagBlu={
              detailsAnticipationData?.value?.isAnticipationRetainWithZeroRate
            }
            loading={detailsAnticipationData.loading}
            value={normalizeMoney(detailsAnticipationData?.value?.cost)}
          />
          {detailsAnticipationData?.value?.isAnticipationRetainWithZeroRate && (
            <S.PagBluDescription>
              Você economizou ao antecipar sem custo para pagar fornecedores com
              PagBlu
            </S.PagBluDescription>
          )}
        </S.DrawerValueItem>
        <S.DrawerValuesDivider />
        <S.DrawerValueItem className="with-button">
          <div>
            <Typography variant="description">Total antecipado</Typography>
            <ItemSkeleton
              loading={detailsAnticipationData.loading}
              value={normalizeMoney(detailsAnticipationData?.value?.total)}
            />
          </div>
          <div>
            {detailsAnticipationData?.value?.uuid && !isURDetails && (
              <Button
                variant="secondary"
                data-track="AntecipacaoPontual_DrawerButton_UR"
                size="small"
                onClick={redirectToURs}
              >
                URs antecipadas
              </Button>
            )}
          </div>
        </S.DrawerValueItem>
      </S.DrawerValues>
      {belongsToEconomicGroup &&
        !detailsAnticipationData.loading &&
        detailsAnticipationData?.value?.outflow.uuid !== uuid && (
          <AnticipationFromGE
            storesState={storesState?.value}
            outflow={detailsAnticipationData?.value?.outflow}
          />
        )}
      {detailsAnticipationData?.value?.anticipationRateType !==
        'compound_interest' &&
        !detailsAnticipationData?.value?.isAnticipationRetainWithZeroRate &&
        !detailsAnticipationData.loading &&
        (!belongsToEconomicGroup ||
          detailsAnticipationData?.value?.outflow.uuid === uuid) && (
          <UnderstandCalculation
            detailsAnticipationData={detailsAnticipationData}
          />
        )}
      {isAccounStatement &&
        !detailsAnticipationData.loading &&
        detailsAnticipationData?.value?.outflow &&
        !belongsToEconomicGroup && (
          <AccountStatementInfo
            currentStoreId={currentStoreId}
            detailsAnticipationData={detailsAnticipationData}
          />
        )}
    </>
  );
};

AdvancementDetailsDrawerContent.propTypes = {
  uuid: PropTypes.string,
  isURDetails: PropTypes.bool.isRequired,
  storesState: PropTypes.object,
  currentStoreId: PropTypes.string.isRequired,
  isAccounStatement: PropTypes.bool.isRequired,
  detailsAnticipationData: PropTypes.object.isRequired,
};

AdvancementDetailsDrawerContent.defaultProps = {
  uuid: '',
  storesState: null,
};

export default AdvancementDetailsDrawerContent;
