import { createActions, createReducer } from 'reduxsauce';
import _ from 'lodash';

/* Types & Creators */

const { Types, Creators } = createActions({
  pendentPaymentsRequest: ['uuid'],
  pendentPaymentsSuccess: ['data'],
  pendentPaymentsFailure: null,
  pendentPaymentsSelect: ['uuid'],
  pendentPaymentsRemove: ['uuid'],
  pendentPaymentsUpdateStatus: ['uuid', 'status'],
  pendentPaymentsUpdateSentOrReceived: ['sentOrReceived', 'pendingOrApproved'],
  pendentPaymentsUpdatePendingOrApproved: ['pendingOrApproved'],
  pendentPaymentsUpdateContentType: ['contentType']
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  pendentPayments: [],
  sentOrReceived: '',
  pendingOrApproved: '',
  loading: false,
  error: false,
  contentType: null
};

/* Reducers */

export const request = (state) => ({
  ...state,
  loading: true
});

export const success = (state, action) => ({
  ...state,
  pendentPayments: action.data,
  loading: false,
  error: false
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const select = (state, action) => {
  const pendentPayments = state.pendentPayments.map((category) => ({
    ...category,
    paymentCollections: category.paymentCollections.map((group) => ({
      ...group,
      paymentCollections: group.paymentCollections.map((payment) => ({
        ...payment,
        selected: payment.uuid === action.uuid
      }))
    }))
  }));

  return {
    ...state,
    contentType: 'detail',
    pendentPayments
  };
};

export const remove = (state, action) => {
  const pendentPayments = state.pendentPayments
    .map((category) => {
      const groups = category.paymentCollections
        .map((group) => {
          const payments = group.paymentCollections.filter(
            (payment) => payment.uuid !== action.uuid
          );

          if (!payments.length) {
            return undefined;
          }

          return {
            ...group,
            paymentCollections: payments
          };
        })
        .filter((group) => group);

      if (!groups.length) {
        return undefined;
      }

      return {
        ...category,
        paymentCollections: groups
      };
    })
    .filter((category) => category);

  const { sentOrReceived, pendingOrApproved } = state;

  const newState = updateSentOrReceived(
    { ...state, pendentPayments },
    { sentOrReceived, pendingOrApproved }
  );

  return newState;
};

export const updateStatus = (state, action) => {
  const pendentPayments = state.pendentPayments.map((category) => ({
    ...category,
    paymentCollections: category.paymentCollections.map((group) => ({
      ...group,
      paymentCollections: group.paymentCollections.map((payment) => ({
        ...payment,
        status: payment.uuid === action.uuid ? action.status : payment.status
      }))
    }))
  }));

  return {
    ...state,
    pendentPayments
  };
};

export const updateSentOrReceived = (state, action) => {
  let { sentOrReceived, pendingOrApproved } = action;

  if (
    !pendingOrApproved ||
    !state.pendentPayments.filter(
      (category) =>
        category.category === `${sentOrReceived}_${pendingOrApproved}`
    ).length
  ) {
    if (
      state.pendentPayments.filter(
        (category) => category.category === `${sentOrReceived}_pending`
      ).length
    ) {
      pendingOrApproved = 'pending';
    } else {
      pendingOrApproved = 'approved';
    }
  }

  return {
    ...state,
    sentOrReceived,
    pendingOrApproved
  };
};

export const updatePendingOrApproved = (state, action) => ({
  ...state,
  pendingOrApproved: action.pendingOrApproved
});

export const updateContentType = (state, action) => ({
  ...state,
  contentType: action.contentType
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.PENDENT_PAYMENTS_REQUEST]: request,
  [Types.PENDENT_PAYMENTS_SUCCESS]: success,
  [Types.PENDENT_PAYMENTS_FAILURE]: failure,
  [Types.PENDENT_PAYMENTS_SELECT]: select,
  [Types.PENDENT_PAYMENTS_REMOVE]: remove,
  [Types.PENDENT_PAYMENTS_UPDATE_STATUS]: updateStatus,
  [Types.PENDENT_PAYMENTS_UPDATE_SENT_OR_RECEIVED]: updateSentOrReceived,
  [Types.PENDENT_PAYMENTS_UPDATE_PENDING_OR_APPROVED]: updatePendingOrApproved,
  [Types.PENDENT_PAYMENTS_UPDATE_CONTENT_TYPE]: updateContentType
});

/* Selectors */

const getPaymentsInCurrentCategory = (state) => {
  const { sentOrReceived, pendingOrApproved, pendentPayments } =
    state.pendentPayments || {};

  if (_.isEmpty(pendentPayments)) {
    return [];
  }

  const currentPendentPayments = pendentPayments.find(
    ({ category }) => category === `${sentOrReceived}_${pendingOrApproved}`
  );

  if (_.isEmpty(currentPendentPayments)) {
    return [];
  }

  return currentPendentPayments.paymentCollections || [];
};

const hasPaymentsInCurrentCategory = (state) =>
  !_.isEmpty(getPaymentsInCurrentCategory(state));

const hasPaymentSelectedInCurrentCategory = (state) => {
  const payments = getPaymentsInCurrentCategory(state);
  const selected = _.find(payments, (p) => {
    return p.paymentCollections && _.find(p.paymentCollections, 'selected');
  });

  return !_.isEmpty(selected);
};

const getContentType = (state) => state.pendentPayments.contentType;

export const selectors = {
  getPaymentsInCurrentCategory,
  hasPaymentsInCurrentCategory,
  hasPaymentSelectedInCurrentCategory,
  getContentType
};
