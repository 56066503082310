/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import moment from 'utils/moment.pt-br';
import MomentLocaleUtils from 'react-day-picker/moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import classNames from 'classnames';
import { Alert } from '@useblu/ocean-react';

const RenderDatePicker = ({
  input,
  label,
  type,
  meta: { touched, error },
  extraClass,
  disabled,
  children,
  ...rest
}) => (
  <div
    className={classNames({
      'form-controls': true,
      [type]: type,
      [extraClass]: extraClass,
      'is-disabled': disabled
    })}
  >
    <label htmlFor="datepicker">{label}</label>
    <div className={classNames({ 'field-with-errors': error })}>
      <DatePickerWrapper {...input} {...rest} />
      {children}
      {error && <span className="field-error">{error}</span>}
    </div>
  </div>
);

class DatePickerWrapper extends Component {
  state = {
    show: false
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.state;
    const { showDatePicker, defaultValue } = nextProps;

    this.setState({ show: showDatePicker });

    if (defaultValue !== value) {
      this.setState({ value: defaultValue });
    }
  }

  showPicker = () => this.setState({ show: true });

  handleDayClick = (day, { selected }) => {
    const { onChange, updateReleasedAt, customFunctionOnDayClick } = this.props;
    const formatedDate = moment(day).format('L');

    onChange(day);

    if (typeof updateReleasedAt === 'function') {
      updateReleasedAt(formatedDate);
    }

    this.setState({
      show: false,
      value: day,
      selectedDay: selected ? null : day
    });

    if (customFunctionOnDayClick !== undefined) {
      customFunctionOnDayClick();
    }
  };

  disabledDays = (day) => {
    let isDisabled = false;
    const { min, disabledDays } = this.props;

    if (disabledDays.length > 0) {
      disabledDays.forEach((holiday) => {
        if (moment(day).startOf('day').isSame(moment(holiday).startOf('day'))) {
          isDisabled = true;
        }
      });
    }

    return (
      DateUtils.isPastDay(day) ||
      moment(day).isBefore(min, 'day') ||
      day.getDay() === 0 ||
      day.getDay() === 6 ||
      isDisabled
    );
  };

  render() {
    const today = new Date(Date.now());
    const { value } = this.props;
    const { value: stateValue } = this.state;
    const selectedDay = moment(value, 'L', true).toDate();
    const { show } = this.state;
    const valueField = stateValue || value;

    return (
      <div>
        <input
          type="text"
          className="date-field"
          value={valueField && moment(valueField).format('L')}
          readOnly
          onFocus={() => this.showPicker()}
        />
        {show && (
          <div className="ocean-iso">
            <DayPicker
              enableOutsideDays
              locale="pt-BR"
              className="DayPicker--single"
              localeUtils={MomentLocaleUtils}
              disabledDays={this.disabledDays}
              selectedDays={(day) => DateUtils.isSameDay(selectedDay, day)}
              onDayClick={this.handleDayClick}
            />
            {moment(today).isBefore(moment('2022-03-02'), 'day') && (
              <Alert type="default">
                Nos dias 28 de Fevereiro e 1° de Março não haverá expediente
                bancário. Sendo assim não será possível realizar ou agendar
                transferências (TED).
              </Alert>
            )}
          </div>
        )}
      </div>
    );
  }
}

DatePickerWrapper.propTypes = {
  onChange: PropTypes.func,
  showDatePicker: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  updateReleasedAt: PropTypes.func,
  customFunctionOnDayClick: PropTypes.func,
  disabledDays: PropTypes.array,
  min: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string])
};

DatePickerWrapper.defaultProps = {
  onChange: null,
  defaultValue: '',
  updateReleasedAt: null,
  customFunctionOnDayClick: null,
  disabledDays: [],
  value: '',
  showDatePicker: false,
  min: ''
};

RenderDatePicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  extraClass: PropTypes.string,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.object,
  showDatePicker: PropTypes.bool
};

export default RenderDatePicker;

export { DatePickerWrapper };
