import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal } from '@useblu/ocean-react';
import { useSelector } from 'react-redux';
import { currencyToNumber, normalizeCurrency } from 'normalizers';
import { maskValue } from 'utils/masks';
import { sendRefund } from 'api/pix';
import { formatPrice } from 'components/AccountStatement/helpers/currency';
import * as S from './styles';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const ModalRefund = ({
  uuid,
  maxValue,
  isOpen,
  dateLimit,
  toggleModal,
  transactionId,
  openSnackBar,
  getPaymentInbounds
}) => {
  const { clientId } = useSelector((state) => ({
    ...state.railsContext
  }));

  const [inputValue, setInputValue] = useState('');
  const [reason, setReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const valueToNumber = currencyToNumber(inputValue || '0');
  const isGreaterThanMaxValue = valueToNumber > maxValue;

  const handleSubmit = async () => {
    setLoading(true);

    sendRefund(clientId, transactionId, currencyToNumber(inputValue), reason)
      .then(async (response) => {
        await delay(2000);
        getPaymentInbounds();
        if (response.data.client_transaction) {
          window.location.href = `/lojas/${uuid}/transacoes/${response.data.client_transaction.uuid}`;
        } else {
          openSnackBar('Operação realizada com successo!', 'success', 5000);
          toggleModal();
          setInputValue('');
          setReason('');
        }
      })
      .catch((error) => {
        if (error.response.data.details[0]) {
          setError(error.response.data.details[0].messages[0]);
        } else {
          openSnackBar('Ocorreu um problema, tente novamente!', 'error', 5000);
          toggleModal();
          setInputValue('');
          setReason('');
        }

        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { value } = e.target;

    const valueWithMask = maskValue(value, 'currency');

    setInputValue(valueWithMask);
    setError(null);
  };

  return (
    <Modal
      isOpen={isOpen}
      portalClassName="ocean-iso"
      maxWidth="sm"
      ariaHideApp={false}
      onRequestClose={loading ? null : toggleModal}
    >
      <S.Wrapper>
        <S.Title variant="heading1">Devolução</S.Title>
        <S.Subtitle variant="paragraph">
          Faça a devolução do valor integral ou parcial para quem te pagou até o
          dia {dateLimit}.
        </S.Subtitle>
        <Input
          label="Valor da devolução"
          name="value"
          data-testid="value"
          type="text"
          position="left"
          value={inputValue}
          onChange={handleChange}
          adornment={<span>R$</span>}
          error={isGreaterThanMaxValue || error}
          helperText={
            error || `Você pode devolver até ${formatPrice(maxValue)}`
          }
          placeholder="0.00"
          disabled={maxValue <= 0}
        />
        <S.TotalValueButton
          variant="secondary"
          size="sm"
          onClick={() => setInputValue(normalizeCurrency(maxValue))}
        >
          Devolver valor total
        </S.TotalValueButton>
        <Input
          label="Motivo da devolução (opcional)"
          name="reason"
          data-testid="reason"
          value={reason}
          onChange={({ target }) => setReason(target.value)}
          type="text"
          placeholder="Sua mensagem aqui..."
          disabled={maxValue <= 0}
        />
        <S.Actions>
          <Button variant="secondary" onClick={toggleModal} disabled={loading}>
            Cancelar
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={
              loading || !inputValue || isGreaterThanMaxValue || !valueToNumber
            }
            variant="primary"
          >
            {loading ? 'Enviando...' : 'Devolver'}
          </Button>
        </S.Actions>
      </S.Wrapper>
    </Modal>
  );
};

ModalRefund.propTypes = {
  uuid: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  transactionId: PropTypes.string.isRequired,
  openSnackBar: PropTypes.func.isRequired,
  dateLimit: PropTypes.string.isRequired,
  getPaymentInbounds: PropTypes.func.isRequired
};

export default ModalRefund;
