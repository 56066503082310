import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isEqual } from 'lodash';
import { useFormikContext, useField } from 'formik';
import { WrapCustomSelect, CustomSelect, Hint } from './styles';

const AutoCompleteFormik = ({
  name,
  options = [],
  label,
  onChange,
  helperText,
  ...props
}) => {
  const [field] = useField({ name });
  const { handleChange, values, errors, touched, setTouched } =
    useFormikContext();
  const [filled, setFilled] = useState(false);

  const value = values[name];

  useEffect(() => {
    if (!isEmpty(value) && !filled) {
      setFilled(true);
    }
  }, [value, filled]);

  return (
    <WrapCustomSelect>
      {label && <label htmlFor={name}>{label}</label>}
      <CustomSelect
        name={name}
        inputId={name}
        filled={filled}
        onBlur={() => {
          setTouched({ ...touched, [name]: true });
        }}
        classNamePrefix="custom-select"
        value={
          options
            ? options.find((option) => isEqual(option.value, field.value))
            : ''
        }
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            const event = {
              target: {
                name,
                value: e.value
              }
            };
            handleChange(event);
          }
        }}
        options={options}
        {...props}
      />
      {((errors[name] && touched[name]) || helperText) && (
        <Hint error={errors[name]}>
          {errors[name] && touched[name] ? errors[name] : helperText}
        </Hint>
      )}
    </WrapCustomSelect>
  );
};

AutoCompleteFormik.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string
};

AutoCompleteFormik.defaultProps = {
  options: [],
  label: null,
  onChange: null,
  helperText: null
};

export default AutoCompleteFormik;
