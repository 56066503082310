import {
  borderRadiusMd,
  colorInterfaceDarkDeep,
  colorInterfaceDarkDown,
  colorInterfaceLightDown,
  colorInterfaceLightPure,
  colorInterfaceLightUp,
  fontWeightBold,
  fontWeightRegular,
  spacingInsetXs
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';
import { Devices } from 'utils/getDeviceMedia';

export const ScrollableContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  max-height: 300px;
  border-radius: ${borderRadiusMd};
  border: 1px solid ${colorInterfaceLightDown};

  @media ${Devices.mobile} {
    flex: 1;
    max-height: unset;
    border-radius: 0;
    border: 0;
  }
`;

export const AdvancementTable = styled.table`
  border-radius: ${borderRadiusMd};
  margin: 0;

  @media ${Devices.mobile} {
    border-radius: 0;
    border: 0;
  }
`;

export const TFoot = styled.tfoot`
  position: sticky;
  bottom: 0;
  background-color: ${colorInterfaceLightPure};
`;

export const THead = styled.thead`
  top: 0;
  position: sticky;
  background-color: ${colorInterfaceLightUp};
`;

export const HeadLine = styled.tr`
  background-color: ${colorInterfaceLightUp};

  & > th {
    padding: ${spacingInsetXs};
    color: ${colorInterfaceDarkDown};
    border-bottom: 1px solid ${colorInterfaceLightDown};
    font-weight: ${fontWeightRegular};
  }
`;

export const ItemLine = styled.tr`
  & > td {
    padding: ${spacingInsetXs};
    color: ${colorInterfaceDarkDeep};
  }
`;

export const FooterLine = styled.tr`
  & > td {
    padding: ${spacingInsetXs};
    color: ${colorInterfaceDarkDeep};
    font-weight: ${fontWeightBold};
    position: sticky;
    bottom: 0;
  }
`;
