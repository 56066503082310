import _ from 'lodash';

const validateUsersAccountsForm = (values, props) => {
  const errors = {};
  const params = _.merge(values, props);

  if (params.currentUser) {
    const { currentUser } = params;

    if (_.isEmpty(currentUser.name)) {
      errors.currentUser = {
        ...errors.currentUser,
        name: 'não pode ficar em branco'
      };
    }

    if (_.isEmpty(currentUser.cpf)) {
      errors.currentUser = {
        ...errors.currentUser,
        cpf: 'não pode ficar em branco'
      };
    }

    if (
      currentUser.cpf &&
      currentUser.cpf.replace('.', '').replace('.', '').replace('-', '')
        .length < 11
    ) {
      errors.currentUser = {
        ...errors.currentUser,
        cpf: 'está incompleto'
      };
    }

    if (_.isEmpty(currentUser.phone)) {
      errors.currentUser = {
        ...errors.currentUser,
        phone: 'não pode ficar em branco'
      };
    }

    if (currentUser.phone && currentUser.phone.length < 15) {
      errors.currentUser = {
        ...errors.currentUser,
        phone: 'está incompleto'
      };
    }

    if (_.isEmpty(currentUser.email)) {
      errors.currentUser = {
        ...errors.currentUser,
        email: 'não pode ficar em branco'
      };
    }
  }

  return errors;
};

export default validateUsersAccountsForm;
