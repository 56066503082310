import React from 'react';
import { useSelector } from 'react-redux';

import moment from 'utils/moment.pt-br';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import PaymentCollectionInstallments from './PaymentCollectionInstallments';

const NonOptimizationInstallments = () => {
  const { nonOptimizationInstallments } = useSelector(selectors.getDetailsData);

  return (
    <PaymentCollectionInstallments
      installments={nonOptimizationInstallments.map((i) => ({
        ...i,
        availability: moment(i.dueDateOn, 'YYYY-MM-DD').format('L')
      }))}
    />
  );
};

export default NonOptimizationInstallments;
