import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import MomentLocaleUtils from 'react-day-picker/moment';
import DayPicker, { DateUtils } from 'react-day-picker';

import moment from 'utils/moment.pt-br';
import classNames from 'classnames';

const RenderFloatDatePicker = ({
  input,
  label,
  type,
  meta: { error },
  extraClass,
  children,
  ...rest
}) => (
  <div
    className={classNames({
      'form-controls': true,
      [type]: type,
      [extraClass]: extraClass
    })}
  >
    <label htmlFor="datepicker">{label}</label>
    <div className={classNames({ 'field-with-errors': error })}>
      <FloatDatePickerWrapper {...input} {...rest} />
      {children}
      {error && <span className="field-error">{error}</span>}
    </div>
  </div>
);

RenderFloatDatePicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  extraClass: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.object,
  inputClass: PropTypes.string,
  showDatePicker: PropTypes.bool,
  dayPickerPosition: PropTypes.string
};

RenderFloatDatePicker.defaultProps = {
  dayPickerPosition: 'bottom',
  inputClass: '',
  showDatePicker: false,
  children: null,
  disabled: false,
  extraClass: '',
  type: '',
  placeholder: '',
  id: '',
  label: null,
  meta: {},
  input: null
};

const FloatDatePickerWrapper = ({
  max,
  min,
  value,
  dayPickerPosition,
  inputClass,
  onChange,
  style,
  disabled,
  showDatePicker,
  enableWeekends,
  onFocus,
  disabledDays
}) => {
  const [show, setShow] = useState(false);
  const [stateValue, setStateValue] = useState(false);
  const [showControl, setShowControl] = useState(false);

  const node = useRef(null);
  const inputRef = useRef(null);

  const closePicker = (e) => {
    if (
      node.current.contains(e.target) &&
      e.target.className !== 'DayPicker-Wrapper'
    ) {
      return;
    }

    setShow(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', closePicker, false);

    return () => {
      document.removeEventListener('mousedown', closePicker, false);
    };
  }, []);

  useEffect(() => {
    setStateValue(value);
  }, [value]);

  useEffect(() => {
    if (showDatePicker) {
      inputRef.current.click();
      setShowControl(true);
    } else {
      setShowControl(false);
    }
  }, [showDatePicker]);

  const handleDayClick = (day) => {
    onChange(day);

    setStateValue(day);
    setShow(false);
  };

  const disabledDaysHandle = (day) => {
    let isDisabled = false;

    if (DateUtils.isPastDay(day)) return true;

    if (moment(day).isBefore(min, 'day') || moment(day).isAfter(max, 'day'))
      return true;

    if (!enableWeekends && (day.getDay() === 0 || day.getDay() === 6))
      return true;

    if (disabledDays.length > 0) {
      disabledDays.forEach((holiday) => {
        if (moment(day).startOf('day').isSame(moment(holiday).startOf('day'))) {
          isDisabled = true;
        }
      });
    }

    if (isDisabled) return true;

    return false;
  };

  const handleClickInput = () => {
    if (showControl) {
      setShowControl(false);
    } else {
      setShow(!show);
    }
  };

  const selectedDayAux = moment(value, 'L', true).toDate();
  const valueField = stateValue || value;

  return (
    <div ref={node} className="DayPicker-input-wrapper">
      <input
        ref={inputRef}
        type="text"
        style={style}
        value={valueField && moment(valueField).format('L')}
        onFocus={!disabled && onFocus ? onFocus : null}
        onClick={handleClickInput}
        readOnly
        className={`date-field ${inputClass}`}
        placeholder="  /  /  "
      />
      <button
        type="button"
        onClick={handleClickInput}
        onFocus={!disabled && onFocus ? onFocus : null}
      >
        <i className="fa fa-calendar" aria-hidden="true" />
      </button>
      {show && (
        <div className="DayPicker-Wrapper" onClick={closePicker}>
          <DayPicker
            enableOutsideDays
            locale="pt-BR"
            className={classNames({
              'DayPicker--top': dayPickerPosition === 'top',
              'DayPicker--bottom': dayPickerPosition === 'bottom'
            })}
            localeUtils={MomentLocaleUtils}
            disabledDays={disabledDaysHandle}
            selectedDays={(day) => DateUtils.isSameDay(selectedDayAux, day)}
            onDayClick={handleDayClick}
          />
        </div>
      )}
    </div>
  );
};

FloatDatePickerWrapper.propTypes = {
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  dayPickerPosition: PropTypes.string,
  inputClass: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  showDatePicker: PropTypes.bool,
  enableWeekends: PropTypes.bool,
  onFocus: PropTypes.func,
  disabledDays: PropTypes.array
};

FloatDatePickerWrapper.defaultProps = {
  min: null,
  max: null,
  value: '',
  onFocus: null,
  dayPickerPosition: '',
  inputClass: '',
  onChange: null,
  style: {},
  disabled: false,
  showDatePicker: false,
  enableWeekends: false,
  disabledDays: []
};

export default RenderFloatDatePicker;

export { FloatDatePickerWrapper };
