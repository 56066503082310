import React from 'react';
import PropTypes from 'prop-types';

const RefundPaymentCollectionStatusBar = ({
  isPending,
  remainingRefundValue
}) => {
  let text = remainingRefundValue
    ? 'Pagamento parcialmente devolvido'
    : 'Pagamento devolvido';

  if (isPending) {
    text = 'Devolução em andamento';
  }

  return (
    <div className="statement-detail__status statement-detail--bg-refunded-payment-collection">
      {text}
    </div>
  );
};

RefundPaymentCollectionStatusBar.propTypes = {
  isPending: PropTypes.bool.isRequired,
  remainingRefundValue: PropTypes.number.isRequired
};

export default RefundPaymentCollectionStatusBar;
