import React from 'react';

import PropTypes from 'prop-types';
import { Button } from '@useblu/ocean-react';
import useModalManagement from 'components/Dashboard/hooks/useModalManagement';
import { Container, CardList, InfoCardText, ButtonContainer } from './styles';

import clock24hIcon from './images/clock24hIcon.svg';
import receiptTaxIcon from './images/receiptTaxIcon.svg';
import cashbackIcon from './images/cashbackIcon.svg';
import calculatorIcon from './images/calculatorIcon.svg';
import fixedTax from './images/fixedTax.svg';

import InfoCard from './InfoCard';

const infoCards = (tax) => [
  {
    icon: receiptTaxIcon,
    title: `Taxa fixa de antecipação: ${tax}%`,
    text: () => (
      <InfoCardText>
        O valor da taxa não altera conforme o número de parcelas.
      </InfoCardText>
    ),
    Tag: () => <img src={fixedTax} className="tag" alt="Taxa Fixa" />
  },
  {
    icon: cashbackIcon,
    title: 'Receba de volta',
    text: () => (
      <InfoCardText>
        Receba toda a taxa de antecipação de volta ao pagar seus fornecedores
        pela Blu!
      </InfoCardText>
    )
  },
  {
    icon: clock24hIcon,
    title: 'Receba no próximo dia útil',
    text: () => (
      <InfoCardText>
        Seu dinheiro cai no dia seguinte ou no próximo dia útil.
      </InfoCardText>
    )
  },
  {
    icon: calculatorIcon,
    title: 'Sem custos',
    text: () => <InfoCardText>Para aderir ou desabilitar.</InfoCardText>
  }
];

const Infos = ({ tax, fullstoryClassName }) => {
  const { openModal } = useModalManagement();

  return (
    <Container data-testid="automatic-anticipation-infos">
      <CardList>
        {infoCards(tax).map(({ icon, title, text, Tag }) => (
          <InfoCard
            key={title}
            Tag={Tag}
            icon={icon}
            title={title}
            Text={text}
          />
        ))}
      </CardList>
      <ButtonContainer>
        <Button
          className={fullstoryClassName}
          role="button"
          onClick={() => openModal('AutomaticAnticipation')}
        >
          Ativar Antecipação Automática
        </Button>
      </ButtonContainer>
    </Container>
  );
};

Infos.propTypes = {
  tax: PropTypes.string,
  fullstoryClassName: PropTypes.string
};

Infos.defaultProps = {
  tax: '7,99',
  fullstoryClassName: ''
};

export default Infos;
