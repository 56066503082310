import React from 'react';
import PropTypes from 'prop-types';
import { normalizeCurrency, formatDate } from 'normalizers';

const ReceivableFragmentsList = ({ fragments, currentClientUuid, label }) => {
  const creditTitle = (installments, installment) => {
    if (installments)
      return installments === 1
        ? 'Crédito à vista'
        : `Crédito parcelado - Parcela ${installment} de ${installments}`;

    return 'Crédito';
  };

  const title = ({ kind, installments, installment }) => {
    return {
      debit: 'Debito',
      payment_collection: 'Cobrança',
      credit: creditTitle(installments, installment)
    }[kind];
  };

  const url = (fragment) => {
    if (fragment.inflow_reference_type === 'payment_collection')
      return `/lojas/${currentClientUuid}/cobrancas/${fragment.payment_collection_uuid}`;

    if (fragment.sale_id)
      return `/lojas/${currentClientUuid}/adjusted/extrato/vendas-no-cartao/venda/${fragment.sale_id}`;

    return false;
  };

  return (
    <div>
      <div className="list__wrapper pagnet-collapse-component">
        <h4 className="list__title">{label}</h4>
        {fragments.map((fragment) => (
          <div
            key={fragment.id}
            style={{ height: 82 }}
            className="list__item list__item-sp-between statement-detail__installment"
          >
            <div className="list__item-left">
              <p>
                <b>{title(fragment)}</b>
              </p>
              <p>{formatDate(fragment.release_at)}</p>
            </div>
            <div
              className="list__item-right"
              style={{ maxWidth: 185, alignItems: 'center' }}
            >
              {url(fragment) && (
                <a className="detail__toggle" href={url(fragment)}>
                  Ver Detalhes
                </a>
              )}
              <div className="list__item-with-link">
                <p>
                  <b>R${normalizeCurrency(fragment.value)}</b>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ReceivableFragmentsList.propTypes = {
  fragments: PropTypes.array,
  label: PropTypes.string,
  currentClientUuid: PropTypes.string.isRequired
};

ReceivableFragmentsList.defaultProps = {
  fragments: [],
  label: 'Valores contidos nesta unidade de recebível'
};

export default ReceivableFragmentsList;
