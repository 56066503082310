import React from 'react';
import PropTypes from 'prop-types';

import infoIcon from '../images/info-icon.svg';
import { Box, Text, Image } from './InfoBoxStyles';

function InfoBox({ img, text, children }) {
  return (
    <Box>
      <Image src={img} alt="Informativo" />
      <Text>{text || children}</Text>
    </Box>
  );
}

InfoBox.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.any
};

InfoBox.defaultProps = {
  img: infoIcon,
  text: '',
  children: null
};

export default InfoBox;
