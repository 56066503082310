import styled from 'styled-components';
import {
  colorBrandPrimaryDeep,
  colorBrandPrimaryPure,
  colorInterfaceLightPure,
  fontFamilyHighlight
} from '@useblu/ocean-tokens/web/tokens';

export const MenuContainer = styled.aside`
  background-color: ${(props) =>
    props.economicGroupView ? colorBrandPrimaryDeep : colorBrandPrimaryPure};
  height: 100vh;
  overflow: hidden;

  nav {
    width: 96px;

    .conciliacao {
      path:first-child {
        stroke: none !important;
        fill: #b8c3ff;
      }
    }
    a:hover,
    a.is-active {
      .conciliacao {
        path:first-child {
          fill: ${colorInterfaceLightPure};
        }
      }
    }
  }

  @media screen and (max-width: 768px), screen and (max-height: 500px) {
    overflow-y: scroll;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuMobileContainer = styled.div`
  width: 100%;
  height: 56px;
  position: relative;
  bottom: 0;
  display: none;

  nav.main {
    background-color: ${(props) =>
      props.economicGroupView ? colorBrandPrimaryDeep : colorBrandPrimaryPure};
    width: 100%;
    height: 56px;
    position: fixed;
    bottom: 0;
    z-index: 4;

    ul {
      width: 100%;
      display: inline-flex;
    }
  }

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const SubmenuMobileContainer = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  transform: translateY(100%);
  transition: transform ease-in-out 0.3s;

  &.is-open {
    transform: translateY(0%);
  }

  .submenu-header {
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 17px;
    margin-bottom: 16px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    button {
      background: transparent;
    }

    h2 {
      margin: 0;
      color: #0025e0;
      font-family: ${fontFamilyHighlight};
      font-size: 20px;
      font-weight: 700;
      line-height: 22px;
      margin-left: 20px;
    }
  }

  ul {
    li {
      a {
        flex-direction: row;
        justify-content: flex-start;
        padding: 5px 10px;
        height: auto;
        color: #393b47 !important;
        font-family: ${fontFamilyHighlight};
        font-size: 16px;
        font-weight: 700;

        &:hover,
        :active,
        :focus {
          color: #393b47;

          svg {
            path,
            rect {
              stroke: #393b47;
            }
          }
        }

        svg {
          padding-right: 10px;
          width: 45px;

          &.conciliacao {
            @media screen and (max-width: 768px) {
              path:first-child {
                stroke-width: 0px;
                fill: #393b47 !important;
              }
            }
          }

          path,
          rect {
            stroke: #393b47 !important;
          }
        }
      }
    }
  }
`;
