const isProduction = railsState.railsContext.env === 'production';

const logger = (...args) =>
  process.env.NODE_ENV !== 'test' && console.log(...args);

const pendoMock = {
  initialize(args) {
    logger('initialize', args);
  },
  loadGuides(args) {
    logger('loadGuides', args);
    return Promise.resolve();
  },
  showGuideById(args) {
    logger('showGuideById', args);
  }
};

(function (apiKey) {
  if (!isProduction) {
    window.pendo = pendoMock;
    return false;
  }

  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push'](
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');

  pendo.initialize({
    visitor: {
      id: railsState.railsContext.currentUserId,
      email: railsState.railsContext.currentUserEmail,
      clients_count: railsState.railsContext.currentUserClientsCount,
      has_economic_group: railsState.railsContext.currentUserHasEconomicGroup,
      language: 'pt-BR',
      nfornecedores: 0,
      N_fornecedores_por_lojista_antes_promocao: ''
    },

    account: {
      id: railsState.railsContext.clientId,
      name: railsState.railsContext.currentClientName,
      real_name: railsState.railsContext.currentClientRealName,
      department_id: railsState.railsContext.currentClientDepartmentId,
      store_id: railsState.railsContext.currentClientStoreId,
      distributor: railsState.railsContext.currentClientDistributor,
      seller: railsState.railsContext.currentClientSeller,
      alert_ted: true
    }
  });
})('64cb7f0d-0e48-4ba5-66aa-41c6b29b7f48');
