import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

export const completeNameErrorMock = {
  response: {
    errors: [
      {
        type: 'father_name',
        messages: ['Por favor, digite um Nome do Pai válido.']
      }
    ]
  }
};

// no_father_name,
export const sellerUpdateMock = {
  id: '70339931612',
  seller: {
    rpa_cpf: '70339931612',
    name: 'Test Partner',
    birthday: '17/05/1988',
    mother_name: 'Mãe Teste',
    father_name: 'Pai Teste',
    verify_attributes: [
      'rpa_cpf',
      'name',
      'mother_name',
      'father_name',
      'birthday'
    ]
  }
};

export const rpaDataMock = {
  data: {
    personal_data: {
      name: 'Test Partner',
      cpf_cnpj: '70339931612',
      email: 'test.partner@pag.net',
      birthday: '1986-10-10',
      mother_name: 'Mãe Partner',
      father_name: 'Pai Partner'
    },
    documents: {
      pis_number: '98765433211',
      personal_id_number: '466789053322312',
      personal_id_number_emitter: 'Detran',
      ctps_number: '3972739',
      ctps_serie: '00021',
      ctps_state: 'PA'
    },
    address: {
      address_zipcode: null,
      address_street: null,
      address_number: null,
      address_complement: null,
      address_neighborhood: null,
      address_city: null
    }
  }
};

export const fetchRpaRegisterData = () =>
  axios.get('api/rep/sellers/rpa', {
    headers: { Accept: 'version=1' }
  });

export const createRpaRegisterForm = (data) =>
  axios.patch('api/rep/sellers', data, {
    headers: {
      Accept: 'version=1',
      'X-CSRF-Token': getCSRFToken() // Check this line necessity
    }
  });
