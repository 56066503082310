/* eslint-disable react/no-array-index-key */
import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import * as S from './TokenInput.styles';
import TokenDigitInput from './TokenDigitInput';

export function TokenInputComponent({
  digitsQuantity,
  autoFocus,
  onChangeToken,
  error = false,
  errorMessage,
  ...rest
}) {
  const [activeInput, setActiveInput] = useState(0);
  const [tokenValues, setTokenValues] = useState(
    Array(digitsQuantity).fill('')
  );

  const handleTokenChange = useCallback(
    (code) => {
      const tokenValue = code.join('');
      onChangeToken(tokenValue);
    },
    [onChangeToken]
  );

  const changeCodeAtFocus = useCallback(
    (code) => {
      const updatedTokenValues = [...tokenValues];

      updatedTokenValues[activeInput] = code[0] || '';
      setTokenValues(updatedTokenValues);
      handleTokenChange(updatedTokenValues);
    },
    [activeInput, handleTokenChange, tokenValues]
  );

  const focusInput = useCallback(
    (inputIndex) => {
      const selectedIndex = Math.max(
        Math.min(digitsQuantity - 1, inputIndex),
        0
      );
      setActiveInput(selectedIndex);
    },
    [digitsQuantity]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const handleOnFocus = useCallback(
    (index) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  const handleOnChange = useCallback(
    (event) => {
      const currentValue = event.currentTarget.value;
      if (!currentValue) {
        event.preventDefault();
        return;
      }
      changeCodeAtFocus(currentValue);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput]
  );

  const handleOnPaste = useCallback(
    (event) => {
      event.preventDefault();
      const pastedText = event.clipboardData
        .getData('text/plain')
        .trim()
        .slice(0, digitsQuantity - activeInput)
        .split('');
      if (pastedText) {
        let nextFocusIndex = 0;
        const updatedTokenValues = [...tokenValues];
        updatedTokenValues.forEach((values, index) => {
          if (index >= activeInput) {
            const changedValue = pastedText.shift() || values;
            if (changedValue) {
              updatedTokenValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setTokenValues(updatedTokenValues);
        setActiveInput(Math.min(nextFocusIndex + 1, digitsQuantity - 1));
        onChangeToken(updatedTokenValues.join(''));
      }
    },
    [activeInput, digitsQuantity, tokenValues, onChangeToken]
  );

  const handleOnKeyDown = useCallback(
    (event) => {
      const pressedKey = event.key;
      if (pressedKey === 'Backspace') {
        event.preventDefault();
        if (tokenValues[activeInput]) {
          changeCodeAtFocus('');
        } else {
          focusPrevInput();
        }
      }
    },
    [activeInput, changeCodeAtFocus, focusPrevInput, tokenValues]
  );

  return (
    <S.Container>
      <S.Wrapper {...rest}>
        {Array(digitsQuantity)
          .fill('')
          .map((_, index) => (
            <TokenDigitInput
              data-testid={`TokenDigitInput-${index}`}
              key={`TokenDigitInput-${index}`}
              focus={activeInput === index}
              value={tokenValues && tokenValues[index]}
              error={error}
              autoFocus={autoFocus}
              onFocus={handleOnFocus(index)}
              onChange={handleOnChange}
              onKeyDown={handleOnKeyDown}
              onPaste={handleOnPaste}
            />
          ))}
      </S.Wrapper>
      <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
    </S.Container>
  );
}

TokenInputComponent.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  autoFocus: PropTypes.bool,
  digitsQuantity: PropTypes.number.isRequired,
  onChangeToken: PropTypes.func.isRequired,
};

TokenInputComponent.defaultProps = {
  error: false,
  errorMessage: '',
  autoFocus: false,
};

const TokenInput = memo(TokenInputComponent);
export default TokenInput;
