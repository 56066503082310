import styled, { css } from 'styled-components';

import { Button } from '@useblu/ocean-react';
import {
  colorBrandPrimaryPure,
  fontFamilyHighlight,
  colorInterfaceDarkUp,
  fontFamilyBase,
  spacingStackSm,
  spacingStackXs,
  fontWeightExtrabold,
  fontWeightRegular,
  colorInterfaceDarkDown,
  colorStatusNegativePure,
  colorBrandPrimaryDown,
  colorInterfaceDarkDeep,
  colorInterfaceLightDown,
  colorInterfaceLightUp,
} from '@useblu/ocean-tokens/web/tokens';

import InputControl from 'components/Shared/InputControl';
import TokenInput from './components/TokenInput';

const devices = {
  mobile: `(max-width: 768px)`,
  tablet: `(max-width: 1366px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`,
};

export const PasswordValidation = styled.div`
  margin-bottom: 24px;

  .requirements {
    color: ${colorBrandPrimaryDown};
  }

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: ${colorBrandPrimaryDown};
    margin: 10px 0px;
  }
  p {
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    color: ${colorInterfaceDarkDown};
    margin: 0px 0px 4px 0px;
    display: flex;
  }
`;

export const StepList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;

  div.pgnt-step-item {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${colorBrandPrimaryDown};
    align-items: center;
    padding: 4px;

    img {
      margin-right: 14px;
    }
  }
`;

export const TitleH2 = styled.h2`
  font-weight: 800;
  font-family: ${fontFamilyHighlight};
  font-size: ${(props) => (props.$firstAccess ? '24px' : '32px')};
  line-height: ${(props) => (props.$firstAccess ? '26px' : '35px')};
  color: ${colorBrandPrimaryPure};
  margin: 0 0 4px 0;

  @media ${devices.mobile} {
    font-size: 24px;
    line-height: 26px;
    color: ${(props) =>
      props.$newUser ? colorInterfaceDarkDeep : colorBrandPrimaryPure};
  }
`;

export const Title = styled.h1`
  font-weight: 800;
  font-family: ${fontFamilyHighlight};
  font-size: 32px;
  line-height: 35px;
  color: ${({ $grey }) =>
    $grey ? colorInterfaceDarkDeep : colorBrandPrimaryPure};
  margin: 0 0 8px;

  @media ${devices.mobile} {
    font-size: ${(props) => (props.$newUser ? '30px' : '32px')};
    color: ${(props) =>
      props.$newUser ? colorInterfaceDarkDeep : colorBrandPrimaryPure};
  }
`;

export const RoleTitle = styled(Title)`
  margin: 36px 0 24px;

  @media ${devices.mobile} {
    margin: 8px 0 16px;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 58px !important;
`;

export const RoleOption = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${colorInterfaceLightDown};
  padding: 16px 24px 16px 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: 0.3s;
  h3 {
    padding-left: 16px;
    margin: 0;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    color: ${colorInterfaceDarkDeep};
  }
  i {
    color: ${colorInterfaceDarkDown};
  }
  &:hover {
    box-shadow: 0px 4px 8px rgba(12, 13, 20, 0.08);
  }
`;

export const IconAndName = styled.div`
  display: flex;
  align-items: center;
  .img-background {
    background: ${colorInterfaceLightUp};
    border-radius: 100%;
    line-height: 0;
    min-width: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 20px;
    }
  }
`;

export const Subtitle = styled.h2`
  font-weight: ${fontWeightRegular};
  font-family: ${fontFamilyBase};
  font-size: 16px;
  line-height: 24px;
  color: ${colorInterfaceDarkDown};
  margin: ${(props) => (props.$authToken ? '0 0 8px' : '0 0 24px')};

  p {
    margin-top: 16px;
  }

  span {
    font-size: 16px;
    color: ${colorInterfaceDarkDown};

    a,
    a:hover,
    a:active,
    a:visited {
      color: ${colorBrandPrimaryPure} !important;
    }
  }
`;

export const RepText = styled(Subtitle)`
  color: ${colorInterfaceDarkDown};
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  padding-top: 6px;
  strong {
    display: inline;
  }
  a,
  a:hover,
  a:active,
  a:visited {
    color: ${colorBrandPrimaryPure} !important;
  }
`;

export const WrapCustomInput = styled.div`
  position: relative;

  &.margin {
    margin-bottom: 24px;
  }

  .ods-input {
    min-width: 434px;

    @media ${devices.tablet} {
      min-width: initial;
      width: 100%;
    }

    .token & {
      min-width: initial;
      width: 48px;
      height: 48px;
      margin-right: 8px;
    }

    &.error {
      border-color: ${colorStatusNegativePure};
    }
  }
`;

export const WrapInput = styled.div`
  margin-bottom: 16px;

  &:first-child {
    margin-bottom: 32px;
  }

  & .ods-checkbox__label a {
    color: ${colorBrandPrimaryPure};
  }
`;

export const CustomInput = styled(InputControl)`
  &&& {
    min-width: 434px;

    @media ${devices.tablet} {
      min-width: initial;
    }

    .token & {
      min-width: initial;
      width: 48px;
      height: 48px;
    }

    &.error {
      border-color: ${colorStatusNegativePure};
    }
  }
`;

export const CenteredCustomInput = styled(CustomInput)`
  .ods-input {
    text-align: center;
  }
`;

export const BtnSubmit = styled(Button)`
  &&& {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .without-margin & {
      margin-top: 0;
    }

    input {
      margin: 0;
    }

    @media ${devices.mobile} {
      flex-grow: 1;
    }

    &.login-btn {
      margin: 0 0 16px 0;
    }

    &.create-account-btn {
      margin: 0 0 24px 0;
    }
  }
  :disabled {
    background: red;
  }
`;

export const ConfirmTokenBtn = styled(BtnSubmit)`
  &&& {
    width: 100%;
    &:disabled {
      background-color: ${colorInterfaceLightDown};
    }
    &.send-new-token {
      background-color: transparent;
      color: ${colorInterfaceDarkUp};
      font-size: 14px;
    }
    &.resend-token {
      background: transparent;
      color: ${colorBrandPrimaryPure};
      font-size: 14px;
    }
  }
`;

export const CustomForm = styled.form`
  .token {
    display: flex;
    gap: 8px;
    width: 48px;

    input {
      font-weight: bold;
      text-align: center;
    }
  }
`;

export const WrapBtns = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  ${(props) =>
    props.rowReverse &&
    css`
      display: flex;
      flex-direction: row-reverse;
    `}

  ${(props) =>
    props.formPadding &&
    css`
      margin-bottom: 4rem;
    `}

  button.ods-btn,
  a {
    margin-left: 0px;
    margin-right: 0px;
  }

  @media ${devices.mobile} {
    ${(props) =>
      props.$mobileColumnDirection &&
      css`
        display: flex;
        flex-direction: column-reverse;
        gap: 0;
      `}
    width: 100%;
  }
`;

export const CenterButtons = styled.div`
  width: 51.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${devices.mobile} {
    width: 100%;
  }
`;

export const WrapActions = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
`;

export const WrapSubActions = styled(WrapActions)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 32px 0;
`;

export const AlertWrapper = styled.div`
  margin-bottom: 24px;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

  & > img {
    margin-bottom: 24px;
    width: 114px;
    height: 98px;
  }

  @media ${devices.mobile} {
    height: auto;
  }

  ${Title} {
    text-align: center;
    color: ${colorInterfaceDarkDeep};
  }

  ${Subtitle} {
    text-align: center;
  }
  @media ${devices.mobile} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    ${Title} {
      text-align: center;
    }

    ${Subtitle} {
      text-align: center;
    }
  }
`;

export const Danger = styled.div`
  display: block;
`;

export const CustomTokenInput = styled(TokenInput)`
  &&& {
    display: flex;
    gap: 8px;

    .token & {
      width: 48px;
      height: 48px;
    }

    &.error {
      margin-bottom: 4px;
      .ods-input {
        border-color: ${colorStatusNegativePure};
      }
    }
  }
`;

export const CenteredCustomTokenInput = styled(CustomTokenInput)`
  .ods-input {
    text-align: center;
    width: 48px;
    height: 48px;
  }
`;

export const WrapRecaptcha = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 24px;

  & > div {
    width: min-content;
  }
`;

export const WrapInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: ${colorBrandPrimaryPure};
  }
`;

export const BlockedAccount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & img {
    max-width: 100%;
    height: 96px;
    margin-bottom: 42px;

    object-fit: contain;
    object-position: center;
  }

  & h1 {
    color: ${colorInterfaceDarkDeep};
    text-align: center;
  }

  & h2 {
    color: ${colorInterfaceDarkDown};
    text-align: center;
  }

  @media ${devices.mobile} {
    height: auto;
  }
`;

export const ResendEmailButton = styled.button`
  color: ${colorBrandPrimaryPure};
  font-size: 14px;
  background: none;
  font-weight: ${fontWeightRegular};
`;

export const BlockedAccountTitle = styled.h1`
  font-weight: ${fontWeightExtrabold};
  font-family: ${fontFamilyHighlight};
  font-size: 24px;
  line-height: 35px;
  color: ${colorInterfaceDarkDeep};
  margin: 0 0 16px;
`;

export const ResetPasswordInstructions = styled.div`
  &&& {
    width: 460px;
  }
  @media ${devices.mobile} {
    &&& {
      width: 100%;
    }
  }
`;

export const ModalButton = styled.p`
  width: fit-content;
  font-family: ${fontFamilyHighlight};
  color: ${colorBrandPrimaryPure};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;

  margin: 0 0 32.5px;

  &:hover {
    cursor: pointer;
  }

  @media ${devices.mobile} {
    margin: 0 0 24.5px;
  }
`;

export const TokenForm = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 492px;
  margin: 0 auto;
  margin-bottom: 32px;
  height: 72px;

  > div:nth-child(2) {
    margin-bottom: 32px;
  }

  > div {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 4px;

    .ods-form-control__root {
      height: 48px;
      width: 48px;
      input {
        margin: 0;
        border: 0;
        text-align: center;
        border-radius: 4px;
      }
    }
  }
`;

export const Resend = styled.div`
  display: flex;
  justify-content: center;
  &&& {
    & .ods-btn {
      height: 48px;
      padding: 0;
      background-color: transparent;

      &:disabled,
      &:hover {
        background-color: transparent;
      }
    }
  }
`;

export const TokenButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 216px;
  padding-bottom: 0px !important;

  &&& {
    margin: 0px;
    gap: 0px;

    & .login-btn {
      margin: 0px;
      gap: 0px;
    }
  }

  @media ${devices.mobile} {
    padding-bottom: 0px;
  }

  @media ${devices.notebook} {
    padding-bottom: 0px;
  }
  @media ${devices.desktop} {
    padding-bottom: 0px;
  }
`;

export const TokenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 434px;

  & .ods-alert {
    margin-bottom: 32.5px;
  }

  @media ${devices.mobile} {
    width: 100%;
  }
`;

export const WarningContainer = styled.div`
  margin-bottom: 88px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: ${spacingStackXs};

  & > img {
    ${(props) => props.$withCaption && `margin-top: auto;`}
    margin-bottom: ${spacingStackSm};
  }

  .ods-typography__caption {
    margin-top: auto !important;
  }

  & > button {
    margin-top: auto;
  }

  & > .ods-typography__caption + button {
    margin-top: 0;
  }

  @media ${devices.mobile} {
    margin-bottom: ${spacingStackXs};

    & > img {
      ${(props) => props.$withCaptionOnMobile && `margin-top: auto;`}
    }
  }
`;

export const WhatsappWrapper = styled.a`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
  bottom: 20px;
  right: 20px;

  color: #fff !important;
  text-decoration: none;
  border-radius: 50%;
  background-color: #25d366;

  z-index: 999;

  & svg {
    width: 32px;
    height: 32px;
  }
`;
