import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { normalizeCurrency, formatDate } from 'normalizers';
import Collapse from 'components/Shared/Collapse';
import ReadOnlyField from 'components/Application/ReadOnlyField';
import IdentityInfo from './IdentityInfo';

class AdvancementDetailsInflow extends React.Component {
  renderAdvancedItems() {
    const {
      advancedValue,
      advancesRateValue,
      releasedAt,
      advancedDays,
      url,
      paymentCollection,
      optimization,
      list
    } = this.props;

    const removeColumn = paymentCollection && !optimization;
    const description = list ? 'Pagamento' : 'Recebimento';

    return (
      <div className="advancement-details">
        <table>
          <thead>
            <tr>
              <th>Data do {description}</th>
              <th>Valor {list ? 'do Pagamento' : 'Recebido'}</th>
              <th>Dias Antecipados</th>
              {!removeColumn && <th>Taxa Antecipação</th>}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr className="no-border">
              <td>{formatDate(releasedAt)}</td>
              <td>R$ {normalizeCurrency(parseFloat(advancedValue))}</td>
              <td>{advancedDays}</td>
              {!removeColumn && (
                <td>R$ {normalizeCurrency(parseFloat(advancesRateValue))}</td>
              )}
              <td>
                <a href={url}>detalhes</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderListItem() {
    const {
      refund,
      netValue,
      refundPath,
      simulation,
      statusClass,
      installment,
      antecipation,
      refundDescription,
      statusDescription,
      originalReleasedAt,
      simulationInstallment,
      transactionId
    } = this.props;

    const simulationAntecipation =
      simulation && parseInt(simulationInstallment, 10) === installment;

    return (
      <>
        <div className="list__item-left">
          <p>
            <b data-transaction-id={transactionId}>
              {installment}º Parcela{antecipation && ' - Antecipado'}
              {simulationAntecipation && ' - A ser antecipado'}
              {transactionId && <IdentityInfo id={transactionId} />}
            </b>
          </p>
          <p>{formatDate(originalReleasedAt)}</p>
          {!isEmpty(statusClass) && !isEmpty(statusDescription) && (
            <small className={statusClass}>{statusDescription}</small>
          )}
        </div>
        <div className="list__item-right" style={{ maxWidth: '185px' }}>
          {refund && (
            <p className="refund-link-container">
              <a className="refund-link" href={refundPath}>
                {refundDescription}
              </a>
            </p>
          )}
          {antecipation && (
            <div className="statement-detail__content-group">
              <Collapse
                getContainerRef={() => this.containerRef}
                arrowColor="#e1e5ed"
                parent
              />
            </div>
          )}
          <div className="list__item-with-link">
            <p>
              <b>{netValue}</b>
            </p>
          </div>
        </div>
      </>
    );
  }

  renderItem() {
    const { advancesRateValue, antecipation } = this.props;

    return (
      <ReadOnlyField
        label="Taxa de antecipação"
        value={`R$ ${normalizeCurrency(parseFloat(advancesRateValue))}`}
      >
        {antecipation && (
          <Collapse
            getContainerRef={() => this.containerRef}
            arrowColor="#e1e5ed"
            parent
          />
        )}
      </ReadOnlyField>
    );
  }

  render() {
    const {
      list,
      antecipation,
      simulation,
      installment,
      simulationInstallment
    } = this.props;

    let containerStyle = {};

    const item = list ? this.renderListItem() : this.renderItem();

    const containerClass = classNames({
      list__item: list,
      'list__item-sp-between': list,
      'statement-detail__installment': list
    });

    if (simulation && parseInt(simulationInstallment, 10) !== installment) {
      containerStyle = { opacity: '.4' };
    }

    return (
      <div className={containerClass} style={containerStyle}>
        {item}
        {antecipation && (
          <div
            ref={(ref) => {
              this.containerRef = ref;
            }}
            style={{ marginTop: '10px' }}
          >
            {this.renderAdvancedItems()}
          </div>
        )}
      </div>
    );
  }
}

AdvancementDetailsInflow.defaultProps = {
  url: '',
  refund: false,
  refundDescription: '',
  refundPath: '',
  statusClass: '',
  paymentCollection: false,
  optimization: false,
  statusDescription: '',
  originalReleasedAt: '',
  simulation: false,
  simulationInstallment: '0.00',
  transactionId: ''
};

AdvancementDetailsInflow.propTypes = {
  refund: PropTypes.bool,
  netValue: PropTypes.string.isRequired,
  refundDescription: PropTypes.string,
  refundPath: PropTypes.string,
  releasedAt: PropTypes.string.isRequired,
  installment: PropTypes.number.isRequired,
  advancedDays: PropTypes.number.isRequired,
  antecipation: PropTypes.bool.isRequired,
  advancedValue: PropTypes.string.isRequired,
  advancesRateValue: PropTypes.string.isRequired,
  originalReleasedAt: PropTypes.string,
  url: PropTypes.string,
  paymentCollection: PropTypes.bool,
  optimization: PropTypes.bool,
  list: PropTypes.bool.isRequired,
  simulation: PropTypes.bool,
  simulationInstallment: PropTypes.string,
  statusClass: PropTypes.string,
  statusDescription: PropTypes.string,
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default AdvancementDetailsInflow;
