import { createReducer } from 'reduxsauce';

import { types } from './actions';

const initialState = {
  data: {},
  loading: false,
  error: null,
  success: null
};

export default createReducer(initialState, {
  [types.FETCH_ZIP_CODE_ADRESS_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: null
  }),
  [types.FETCH_ZIP_CODE_ADRESS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.data,
    success: true,
    loading: false,
    error: null
  }),
  [types.FETCH_ZIP_CODE_ADRESS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    success: null
  }),
  [types.CLEANS_ZIP_CODE_ADRESS_FETCHED]: () => ({
    ...initialState
  })
});
