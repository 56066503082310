import {
  borderRadiusSm,
  colorInterfaceLightPure,
  fontFamilyHighlight,
  fontSizeSm,
  colorInterfaceDarkDeep,
  fontWeightMedium,
  colorComplementaryDown,
  colorBrandPrimaryPure,
  colorBrandPrimaryUp,
  fontSizeXs,
  fontSizeMd,
  fontSizeXxs,
  spacingStackSm,
  spacingStackLg,
  colorBrandPrimaryDeep,
  fontSizeLg,
  spacingStackMd,
  fontWeightBold,
  colorHighlightPure,
  colorInterfaceLightDown,
  colorInterfaceLightUp,
  spacingStackXl,
  spacingStackXxl,
  spacingStackXs,
  spacingInlineXs,
  spacingInsetXs,
  fontSizeXxxs,
  spacingInlineXxs,
  colorInterfaceDarkDown,
  colorBrandPrimaryDown,
  fontFamilyBase
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { Button } from '@useblu/ocean-react';

import icCheck from 'images/icons/ic-check.svg';
import WhatsSpot from './images/whats.svg';
import infoIcon from './images/InfoIcon.svg';
import lockIcon from './images/lockIcon.svg';

const devices = {
  mobile: `(max-width: 768px)`,
  tablet: `(max-width: 1024px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`
};

export const Wrapper = styled.div`
  @media ${devices.mobile} {
    padding-bottom: 56px;
  }
`;

/* Header */
export const HeaderContainer = styled.div`
  background-color: ${colorBrandPrimaryPure};
  display: flex;
  min-height: 672px;
  position: relative;

  @media ${devices.tablet} {
    min-height: 500px;
  }

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${devices.notebook} {
    flex: 2;
    > div {
      padding-left: ${spacingStackSm};
    }
  }

  @media ${devices.desktop} {
    flex: 1;
  }

  @media ${devices.mobile} {
    align-items: flex-start;
    margin: ${spacingStackSm} ${spacingStackSm} 0;
  }
`;

export const TextBalloon = styled.p`
  background-color: ${colorInterfaceLightPure};
  border-radius: 7px;
  color: ${colorInterfaceDarkDeep};
  display: inline-block;
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightMedium};
  padding: 10px 15px;
  position: relative;
  line-height: 22px;

  @media ${devices.tablet} {
    font-size: ${fontSizeXs};
  }

  :after {
    content: ' ';
    position: absolute;
    left: 18px;
    top: 40px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    clear: both;
  }

  @media ${devices.mobile} {
    font-size: ${fontSizeSm};
  }
`;

export const HeaderTitle = styled.h1`
  font-size: ${fontSizeLg};
  color: ${colorComplementaryDown};
  font-family: ${fontFamilyHighlight};
  font-weight: ${fontWeightBold};
  margin: 0;
`;

export const HeaderSubTitle = styled.h2`
  font-family: ${fontFamilyHighlight};
  color: ${colorInterfaceLightPure};
  font-size: ${fontSizeSm};
  margin: 0;
`;

export const DownloadSection = styled.div`
  display: block;
  margin-top: 40px;

  a:first-of-type {
    margin-right: 15px;
  }

  p {
    color: ${colorInterfaceLightPure};
    font-size: ${fontSizeXs};
  }

  @media ${devices.mobile} {
    display: none;
  }
`;

export const DownloadSectionMobile = styled.div`
  display: none;
  margin: 0 ${spacingStackSm} ${spacingStackSm};

  a:first-of-type {
    margin-right: 15px;
  }

  p {
    color: ${colorInterfaceLightPure};
    font-size: ${fontSizeXs};
  }

  @media ${devices.mobile} {
    display: block;
  }
`;

export const HeaderImageContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${devices.notebook} {
    flex: 3;
  }

  @media ${devices.desktop} {
    flex: 1;
  }

  @media ${devices.mobile} {
    margin: ${spacingStackSm};
    background: ${colorBrandPrimaryDeep};
  }
`;

export const HeaderImage = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;

  > img {
    position: relative;
    bottom: 0;
    height: 90%;
  }

  @media ${devices.mobile} {
    justify-content: flex-end;
    flex: 1;

    > img {
      position: relative;
      bottom: 0;
      height: 250px;
      width: 160px;
    }
  }
`;

export const WhatsSpotlight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  p {
    background-repeat: no-repeat;
    background-image: url('${WhatsSpot}');
    background-position: bottom left;
    color: ${colorInterfaceLightPure};
    font-size: ${fontSizeMd};
    font-family: ${fontFamilyHighlight};
    padding-bottom: 5px;
    position: relative;
    left: -35%;
  }

  span {
    color: ${colorComplementaryDown};
  }

  @media ${devices.tablet} {
    p {
      font-size: ${fontSizeXs};
    }
  }

  @media ${devices.mobile} {
    flex: 1;
    p {
      font-size: ${fontSizeXxs};
      left: 0;
    }
  }
`;

/* Security section */
export const SecuritySection = styled.div`
  height: 450px;
  display: flex;
  background: ${colorInterfaceLightDown};
`;

export const SecurityImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;

  @media ${devices.mobile} {
    display: none;
  }
`;

export const SecurityContent = styled.div`
  display: flex;
  flex: 3;
  justify-content: center;
  flex-direction: column;
  font-family: ${fontFamilyHighlight};

  h1 {
    color: ${colorBrandPrimaryPure};
    font-size: ${fontSizeMd};
    font-weight: ${fontWeightBold};
    margin-bottom: 10;
  }

  p {
    font-size: ${fontSizeXs};
  }

  img {
    display: none;
    height: 200px;
  }

  ul li {
    display: flex;
    font-size: ${fontSizeSm};

    &::before {
      content: url('${icCheck}');
      display: flex;
      top: 5px;
      height: 20px;
      width: 20px;
      position: relative;
      margin-right: 5px;
    }
  }

  @media ${devices.mobile} {
    justify-content: flex-start;
    padding: ${spacingStackSm};

    div {
      display: flex;
      flex: 1;
      align-items: center;
    }

    img {
      display: block;
    }

    ul li {
      font-size: ${fontSizeXs};
    }
  }
`;

/* Login */
export const LoginContainer = styled.div`
  display: flex;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export const LoginDescription = styled.div`
  flex: 3;
  align-items: center;
  background: ${colorBrandPrimaryDeep};
  display: flex;
  flex-direction: column;
  padding: ${spacingStackLg} ${spacingStackLg} ${spacingStackXxl};

  > div {
    max-width: 600px;

    > p {
      padding-left: 100px;
      background: url('${infoIcon}') no-repeat;
      color: ${colorInterfaceLightPure};
      font-family: ${fontFamilyHighlight};
      font-size: ${fontSizeMd};
      background-size: 90;
      min-height: 100px;
      margin-bottom: ${spacingStackLg};
    }
  }

  @media ${devices.tablet} {
    padding: ${spacingStackMd} ${spacingStackMd} ${spacingStackLg};

    > p {
      font-size: ${fontSizeSm};
      background-size: 80;
      max-width: 400px;
    }
  }

  @media ${devices.mobile} {
    padding: ${spacingStackSm} ${spacingStackSm} ${spacingStackLg};

    > div {
      > p {
        padding-left: 75px;
        background-size: 60px;
        font-size: ${fontSizeXs};
        margin-bottom: ${spacingStackSm};
        max-width: 310px;
      }
    }
  }
`;

export const LoginBanner = styled.div`
  background: ${colorInterfaceLightPure};
  font-weight: ${fontWeightMedium};
  position: relative;
  z-index: 1;
  margin-bottom: ${spacingStackXl};

  p {
    color: ${colorBrandPrimaryPure};
    font-size: ${fontSizeMd};
    margin: 0;
  }

  span {
    color: ${colorHighlightPure};
    font-size: ${fontSizeLg};
    margin-right: 5px;
  }

  div {
    display: block;
    background: ${colorInterfaceLightPure};
    position: relative;
    padding: ${spacingStackSm} ${spacingStackMd};
    border: 2px solid ${colorBrandPrimaryDeep};
    left: 15px;
    top: -15px;
  }

  :after {
    content: '*Além da taxa administrativa (MDR)';
    position: absolute;
    font-family: ${fontFamilyHighlight};
    color: ${colorBrandPrimaryUp};
    font-size: ${fontSizeXs};
    margin-top: ${spacingStackXxl};
  }

  @media ${devices.tablet} {
    p {
      font-size: ${fontSizeSm};
    }

    span {
      font-size: ${fontSizeMd};
    }

    :after {
      margin-top: ${spacingStackLg};
    }
  }

  @media ${devices.mobile} {
    p {
      font-size: ${fontSizeXs};
    }

    span {
      font-size: ${fontSizeSm};
    }

    div {
      padding: ${spacingStackXs} ${spacingStackSm};
    }

    :after {
      margin-top: ${spacingStackLg};
    }
  }
`;

export const LoginSection = styled.div`
  flex: 2;
  padding: ${spacingStackLg};
  background: ${colorInterfaceLightUp};
  display: flex;
  flex-direction: column;

  p {
    flex: 1;
    font-size: ${fontSizeSm};
  }

  @media ${devices.tablet} {
    padding: ${spacingStackMd};
  }

  @media ${devices.mobile} {
    padding: ${spacingStackSm};

    p {
      font-size: ${fontSizeXs};
      font-weight: ${fontWeightMedium};
    }
  }
`;

export const LoginForm = styled.div`
  flex: 3;

  span {
    font-size: ${fontSizeXxs};
    color: ${colorInterfaceDarkDeep};
    margin-bottom: ${spacingInsetXs};
    font-weight: ${fontWeightBold};
  }

  p {
    background: ${colorInterfaceLightPure};
    padding: ${spacingInlineXs};
    border-radius: ${borderRadiusSm};
    border: 1px solid #d8dae8;
    color: ${colorInterfaceDarkDown};
  }
`;

export const AppDownload = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p {
    font-size: ${fontSizeXs};
  }

  div {
    display: flex;
    flex: 1;

    a:first-of-type {
      margin-right: 15px;
    }

    :first-of-type {
      align-items: flex-end;
    }
  }

  @media ${devices.mobile} {
    margin-top: 40px;

    p {
      font-size: ${fontSizeXxxs};
    }
  }
`;

/* Video */
export const SellMoreVideoContainer = styled.div`
  display: flex;
  min-height: 375px;
  background: ${colorBrandPrimaryPure};
  padding: ${spacingStackLg};

  @media ${devices.tablet} {
    padding: ${spacingStackMd};
  }

  @media ${devices.mobile} {
    flex-direction: column;
    padding: ${spacingStackSm};
  }
`;

export const CustomReactPlayer = styled(ReactPlayer)`
  && {
    width: 100% !important;
    height: 100% !important;
  }
`;

export const SellMore = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 300px;
  }

  @media ${devices.mobile} {
    width: 100%;

    div {
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
    }
  }
`;

export const SellMoreDescription = styled.div`
  flex: 1;
  color: ${colorInterfaceLightPure};
  display: flex;
  justify-content: center;

  div {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h1 {
    font-size: ${fontSizeLg};
  }

  p {
    font-size: ${fontSizeXs};
  }
`;

export const Separation = styled.div`
  width: 15px;
`;

/* Know more */
export const KnowMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const KnowMoreHeader = styled.div`
  display: flex;
  flex: 1;
  padding: ${spacingStackLg} ${spacingStackLg} ${spacingStackXs};
  background: ${colorBrandPrimaryDeep};

  span {
    color: ${colorInterfaceLightPure};
    background: ${colorHighlightPure};
    font-size: ${fontSizeXxs};
    padding: 2px ${spacingInlineXxs};
    border-radius: 100px;
    font-weight: ${fontWeightBold};
  }

  @media ${devices.mobile} {
    padding: ${spacingStackSm} ${spacingStackSm} ${spacingStackXl};
    flex-direction: column;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  > div {
    width: 500px;
  }

  h1 {
    margin-top: ${spacingStackXs};
    margin-bottom: 0;
    font-family: ${fontFamilyHighlight};
    color: ${colorInterfaceLightPure};
    font-size: ${fontSizeLg};
  }

  @media ${devices.mobile} {
    h1 {
      font-size: ${fontSizeMd};
    }
  }
`;

export const KnowMoreSeparator = styled.div`
  flex: 1;
`;

export const KnowMoreContent = styled.div`
  display: flex;
  padding: ${spacingStackSm} ${spacingStackLg};
  background: ${colorInterfaceLightUp};

  @media ${devices.mobile} {
    position: relative;
    flex-direction: column-reverse;
    padding: ${spacingStackLg} ${spacingStackSm} ${spacingStackSm};
  }
`;

export const KnowMoreDescription = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  div {
    max-width: 500px;
  }

  p {
    margin-top: ${spacingStackMd};
    font-size: ${fontSizeXs};
    font-weight: ${fontWeightBold};
    color: ${colorInterfaceDarkDown};
    font-family: ${fontFamilyHighlight};
    background: url('${lockIcon}') no-repeat;
    padding-left: 30px;
    background-size: 30px 30px;
    height: 33px;
    display: flex;
    align-items: flex-end;
  }

  h2 {
    font-weight: ${fontWeightBold};
    color: ${colorBrandPrimaryPure};
    font-size: ${fontSizeLg};

    span {
      color: ${colorInterfaceDarkDown};
      font-size: ${fontSizeSm};
    }
  }

  @media ${devices.tablet} {
    p {
      font-size: ${fontSizeXxs};
    }

    h2 {
      font-size: ${fontSizeMd};

      span {
        font-size: ${fontSizeXs};
      }
    }
  }

  @media ${devices.mobile} {
    div {
      display: flex;
      flex-direction: column;
    }

    p {
      display: none;
    }

    h2 {
      font-size: ${fontSizeLg};

      span {
        font-size: ${fontSizeSm};
      }
    }
  }
`;

export const CustomButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonWithoutHover = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: ${colorBrandPrimaryPure} !important;
  }

  :focus {
    background-color: ${colorBrandPrimaryPure} !important;
  }
`;

export const KnowMoreImage = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media ${devices.mobile} {
    div {
      position: absolute;
      top: -60px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

/* Community */
export const CommunityContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${spacingStackLg};
  background: ${colorBrandPrimaryDown};
  font-size: ${fontSizeLg};
  color: ${colorInterfaceLightPure};
  font-weight: ${fontWeightBold};
  align-items: center;

  @media ${devices.tablet} {
    font-size: ${fontSizeMd};
  }

  @media ${devices.mobile} {
    align-items: flex-start;
    padding: ${spacingStackSm};
    font-size: ${fontSizeSm};
    flex-direction: column-reverse;
  }
`;

/* Footer */
export const DesktopFooterContainer = styled.div`
  display: flex;
  background: ${colorInterfaceLightUp};
  padding: ${spacingStackLg};
  font-family: ${fontFamilyHighlight};

  div {
    :first-of-type {
      flex: 1;
    }

    :last-of-type {
      display: flex;
      flex: 2;
      flex-direction: row;
      justify-content: space-around;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  @media ${devices.tablet} {
    padding: ${spacingStackSm};
  }

  @media ${devices.mobile} {
    display: none;
  }
`;

export const MobileFooterContainer = styled.div`
  display: none;

  @media ${devices.mobile} {
    display: block;
  }
`;

export const FooterInfo = styled.div`
  flex: 2;
  padding-left: 15px;

  p {
    color: ${colorBrandPrimaryPure};
    font-size: ${fontSizeXs};
    font-weight: ${fontWeightBold};
  }

  span {
    display: block;
    color: ${colorInterfaceDarkDown};
    font-size: ${fontSizeXs};
  }
`;

/* Carousel */
export const CarouselCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardImg = styled.img`
  width: 70px;
  height: 70px;
`;

export const CardText = styled.p`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeSm};
  text-align: center;
  color: ${colorInterfaceDarkDown};

  @media ${devices.tablet} {
    font-size: ${fontSizeXxs};
  }

  @media ${devices.mobile} {
    font-size: ${fontSizeXs};
  }
`;
