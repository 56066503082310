import React from 'react';
import PropTypes from 'prop-types';

import {
  KnowMoreContainer,
  KnowMoreHeader,
  ContentContainer,
  KnowMoreSeparator,
  KnowMoreContent,
  KnowMoreDescription,
  KnowMoreImage,
  CustomButton
} from './styles';

import knowMore from './images/knowMore.svg';

const KnowMore = ({ uuid }) => {
  const redirect = () => {
    window.location.href = `/lojas/${uuid}/economize-mais-use-recebiveis`;
  };

  return (
    <KnowMoreContainer>
      <KnowMoreHeader>
        <ContentContainer>
          <div>
            <span>Em breve</span>
            <h1>Registro de Recebíveis Blu</h1>
          </div>
        </ContentContainer>
        <KnowMoreSeparator />
      </KnowMoreHeader>
      <KnowMoreContent>
        <KnowMoreDescription>
          <div>
            <h2>
              <span>Traga a agenda de suas outras maquininhas e</span>
              <br />
              ganhe de 0,5% Cashback Blu em cada transação do link
            </h2>
            <CustomButton size="lg" onClick={redirect}>
              Saiba mais
            </CustomButton>
            <p>Em breve você poderá desbloquear esse benefício. Aguarde!</p>
          </div>
        </KnowMoreDescription>
        <KnowMoreImage>
          <div>
            <img src={knowMore} alt="Saiba mais" />
          </div>
        </KnowMoreImage>
      </KnowMoreContent>
    </KnowMoreContainer>
  );
};
KnowMore.propTypes = {
  uuid: PropTypes.string.isRequired
};

export default KnowMore;
