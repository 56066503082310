import React from 'react';
import PropTypes from 'prop-types';

import { coreTaxTitle } from '../enum/taxes';
import * as S from './styles';

const TaxPlans = ({ title, taxTable, antecipationTaxes }) => (
  <S.Wrapper>
    {title && <S.Title>{title}</S.Title>}
    <S.Table>
      <S.Thead>
        <tr>
          <th colSpan="3">
            <S.SubTitle>Operação de cartão</S.SubTitle>
          </th>
        </tr>
      </S.Thead>
      <S.Tbody>
        {taxTable?.items.map((item, index) => (
          <tr key={index}>
            <td colSpan="2">{coreTaxTitle(item.title)}</td>
            <td align="right">
              <b>
                {item.taxes[0].tax === '-'
                  ? item.taxes[0].tax
                  : `${item.taxes[0].tax}%`}
              </b>
            </td>
          </tr>
        ))}
      </S.Tbody>
    </S.Table>

    <S.Table>
      <S.Thead>
        <tr>
          <th colSpan="3">
            <S.SubTitle>Outras taxas</S.SubTitle>
          </th>
        </tr>
      </S.Thead>
      <S.Tbody>
        <tr>
          <td colSpan="2">Antecipação de Recebíveis*</td>
          <td align="right">
            <b>{antecipationTaxes.anticipationRate?.toFixed(1).toString()}%</b>
            <span>
              {' '}
              {`(CDI + ${antecipationTaxes.anticipationRateComposition
                ?.toFixed(1)
                .toString()}% a.m)`}
            </span>
          </td>
        </tr>
      </S.Tbody>
    </S.Table>

    <S.Description>
      *Lembrando que com a Blu você tem a opção de comprar com seus fornecedores
      pagando ZERO para antecipar, o que pode gerar uma economia de até 10% para
      o seu negócio.
    </S.Description>
  </S.Wrapper>
);

TaxPlans.propTypes = {
  taxTable: PropTypes.object.isRequired,
  antecipationTaxes: PropTypes.object.isRequired,
  title: PropTypes.string
};

TaxPlans.defaultProps = {
  title: ''
};

export default TaxPlans;
