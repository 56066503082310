import React from 'react';
import { Grid } from '@useblu/ocean-react';
import imgSecurity from 'images/security-icon.png';
import HowToUse from '../HowToUse';
import DeviceCardStates from '../DeviceCardStates';
import { Header, HeaderDescription } from '../BToken.styles';
import { GridContainer } from './styles';

const BTokenConfiguration = () => (
  <div className="ocean-iso">
    <GridContainer>
      <Grid.Row>
        <Grid.Col
          xl={{
            span: 6,
            offset: 3
          }}
          sm={{
            span: 8,
            offset: 2
          }}
          xs="12"
        >
          <Header>
            <div>
              <span>Sua conta</span>
              <h1>bToken</h1>
            </div>

            <div>
              <img src={imgSecurity} alt="bToken" width="48px" />
            </div>

            <HeaderDescription>
              <span>
                O bToken é uma proteção adicional para a sua Conta Digital Blu e
                serve para autenticar as transações que você realizar pelo
                celular ou computador com mais segurança e praticidade.
              </span>
            </HeaderDescription>
          </Header>
          <DeviceCardStates />
          <HowToUse />
        </Grid.Col>
      </Grid.Row>
    </GridContainer>
  </div>
);

export default BTokenConfiguration;
