import styled from 'styled-components';
import { Typography } from '@useblu/ocean-react';
import {
  spacingInlineXxs,
  spacingInlineXs,
  colorComplementaryPure,
  spacingInlineSm,
  fontSizeXxl,
  colorInterfaceDarkPure,
  colorInterfaceDarkDown,
  fontSizeXl,
  spacingInlineXxxl,
  spacingInlineXxxs,
  fontSizeLg,
  fontSizeMd
} from '@useblu/ocean-tokens/web/tokens';

import icLine from 'images/icons/ic-line.svg';
import IHero from 'images/icons/ilustration-hero.png';
import IheroSmall from 'images/icons/ilustration-hero-small.png';
import ICoin from 'images/icons/coins-3d.png';

const mediaScreen = {
  xs: `@media (max-width: 480px)`,
  sm: `@media (min-width: 600px)`,
  md: `@media (min-width: 992px)`,
  lg: `@media (min-width: 993px)`
};

export const Wrapper = styled.section`
  padding-top: ${spacingInlineXxxl};
  background: rgb(28, 153, 153);
  background: linear-gradient(
    0deg,
    rgba(28, 153, 153, 0) 0%,
    rgba(28, 153, 153, 0.7371323529411764) 100%,
    rgba(28, 153, 153, 1) 100%
  );
  position: relative;
  min-height: 600px;

  &:after {
    content: '';
    background: url(${IheroSmall}) no-repeat center;
    background-size: contain;
    position: absolute;
    width: 100%;
    top: 20%;
    height: 550px;
  }

  ${mediaScreen.sm} {
    margin-bottom: -18rem;

    &:before {
      content: '';
      background: url(${IHero}) no-repeat center;
      background-size: contain;
      position: absolute;
      top: 0;
      right: 0;
      width: 500px;
      height: 450px;
    }

    &:after {
      width: 100%;
      height: 300px;
      position: absolute;
      top: inherit;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      background: url(${ICoin}) no-repeat center;
      background-size: contain;
    }
  }

  ${mediaScreen.md} {
    margin-bottom: 0;
    min-height: 900px;
    &:before {
      width: 600px;
      height: 550px;
    }
  }
`;

export const Tag = styled.div`
  background: #b0f5f5;
  border-radius: ${spacingInlineXxxs};
  padding: ${spacingInlineXxs} ${spacingInlineXs};
  margin: 0 auto ${spacingInlineSm} auto;

  p {
    color: ${colorComplementaryPure} !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    letter-spacing: 0.24em;
  }

  ${mediaScreen.sm} {
    margin: 0 0 ${spacingInlineSm} 0;
    border-radius: ${spacingInlineXxs};
  }
`;

export const Title = styled(Typography)`
  width: 100%;
  color: ${colorInterfaceDarkPure} !important;
  line-height: 100% !important;
  text-align: center;

  ${mediaScreen.sm} {
    text-align: left;
    font-size: ${fontSizeLg} !important;
  }

  ${mediaScreen.md} {
    text-align: left;
    font-size: ${fontSizeXxl} !important;
  }
`;

export const Description = styled(Typography)`
  width: 100%;
  position: relative;
  margin-top: ${spacingInlineSm} !important;
  color: ${colorInterfaceDarkDown} !important;
  text-align: center;
  line-height: 35px !important;

  span {
    display: table;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    &:after {
      content: url(${icLine});
      height: 10px;
      position: absolute;
      left: 0;
      bottom: 15px;
    }
  }

  ${mediaScreen.sm} {
    text-align: left;
    font-size: ${fontSizeMd} !important;

    span {
      min-height: 41px;
      margin: 0;

      &:after {
        bottom: 21px;
      }
    }
  }

  ${mediaScreen.md} {
    text-align: left;
    font-size: ${fontSizeXl} !important;

    span {
      &:after {
        transform: initial;
        left: 0;
      }
    }
  }
`;
