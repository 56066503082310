import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleOutline, XCircleOutline } from '@useblu/ocean-icons-react';
import {
  colorStatusPositivePure,
  colorStatusNegativePure
} from '@useblu/ocean-tokens/web/tokens';
import { SnackbarContainer, SnackAction } from './styles';

export function useSnackbar() {
  const [isActive, setIsActive] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [type, setType] = React.useState();
  const [actionName, setActionName] = React.useState();
  const [action, setAction] = React.useState(() => () => {});
  const [time, setTime] = React.useState(3000);
  const [timeoutId, setTimeoutId] = React.useState(0);

  React.useEffect(() => {
    if (isActive === true) {
      const id = setTimeout(() => {
        setIsActive(false);
      }, time);
      setTimeoutId(id);
    } else {
      clearTimeout(timeoutId);
    }
  }, [isActive, time]);

  const openSnackBar = (
    msg = 'Something went wrong...',
    tp = 'error',
    tm = 3000,
    actName,
    act
  ) => {
    setIsActive(false);
    setMessage(msg);
    setType(tp);
    setActionName(actName);
    setAction(() => () => act());
    setTime(tm);
    setIsActive(true);
  };

  return { isActive, message, type, openSnackBar, actionName, action };
}

const Snackbar = ({ isActive, message, type, actionName, action }) => {
  const types = {
    error: <XCircleOutline color={colorStatusNegativePure} />,
    success: <CheckCircleOutline color={colorStatusPositivePure} />
  };

  return (
    <SnackbarContainer className={isActive ? 'fadeIn' : 'fadeOut'}>
      {types[type]}
      {message}
      {actionName && (
        <SnackAction
          type="button"
          style={{
            color:
              type === 'success'
                ? colorStatusPositivePure
                : colorStatusNegativePure
          }}
          onClick={action}
        >
          {actionName}
        </SnackAction>
      )}
    </SnackbarContainer>
  );
};

Snackbar.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  actionName: PropTypes.string,
  action: PropTypes.func
};

Snackbar.defaultProps = {
  type: '',
  message: '',
  actionName: null,
  action: null
};

export default Snackbar;
