import styled from 'styled-components';

import {
  colorInterfaceDarkDeep,
  colorInterfaceDarkDown,
  colorInterfaceDarkUp,
  colorBrandPrimaryPure,
  fontSizeXxxs,
  spacingStackSm,
  spacingStackXs,
  spacingInsetMd,
  spacingStackXxs,
  spacingInsetSm,
  fontFamilyBase,
  spacingStackLg,
  spacingInlineXs,
} from '@useblu/ocean-tokens/web/tokens';
import { Devices } from 'utils/getDeviceMedia';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .ods-alert {
    margin-bottom: 16px;
  }

  @media ${Devices.mobile} {
    height: 100%;
    padding: 0 16px;
    margin-top: -56px;
  }
`;

export const Image = styled.img`
  margin: 15px 0 24px 0;
`;

export const Title = styled.h3`
  font-family: Avenir;
  font-size: 20px;
  font-weight: 800;
  line-height: 22px;
  color: ${colorInterfaceDarkDeep};
  margin: 0 0 8px 0;

  @media ${Devices.mobile} {
    text-align: center;
  }
`;

export const Subtitle = styled.p`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${colorInterfaceDarkDown};
  margin: 0 0 24px 0;

  .service-receiver-text {
    font-weight: 700;
  }

  @media ${Devices.mobile} {
    text-align: center;
  }
`;

export const TokenInputWrapper = styled.div`
  margin: 0 0 32px 0;
`;

export const CustomForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: ${spacingStackXs};
`;

export const ErrorCode = styled.span`
  margin-top: ${spacingStackSm};
  color: ${colorInterfaceDarkUp};
`;

export const ResendToken = styled.span`
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: ${colorInterfaceDarkUp};
  margin-bottom: 32px;

  &:hover {
    cursor: default;
  }

  & > span {
    color: ${colorBrandPrimaryPure};
    cursor: pointer;
  }

  @media ${Devices.mobile} {
    font-size: ${fontSizeXxxs};
    text-align: center;
  }
`;

export const XCircleOutlineContainer = styled.div`
  margin: 15px 0 24px 0;
`;

// Notificação
export const NotificationModalContainer = styled.div`
  display: flex;
  max-width: 476px;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

export const NotificationImage = styled.div`
  & > img {
    width: 178px;
    height: 80px;
  }

  margin-bottom: ${spacingInsetMd};
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingStackXxs};
  margin-bottom: ${spacingInsetSm};

  & .ods-typography__heading3 {
    color: ${colorInterfaceDarkDeep};
    font-family: ${fontFamilyBase};
  }
`;

export const NotificationActions = styled.div`
  margin-top: ${spacingStackLg};

  .ods-link--primary {
    justify-content: center;
    font-size: ${fontSizeXxxs};
    margin-top: ${spacingStackXxs};
  }
`;

// Options
export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  gap: ${spacingInlineXs};
  margin-top: ${spacingStackLg};
  width: 320px;

  > div {
    flex: 1;
  }

  .ods-shortcut__label {
    height: 40px;
    display: flex;
    align-items: flex-end;
  }
`;

export const LinkButton = styled.button`
  background: none;
  border: none;
  color: ${colorBrandPrimaryPure};
  cursor: pointer;
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 600;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }
`;
