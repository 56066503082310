import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useWindowDimensions from 'hooks/useWindowDimensions';

import closeIcon from '../images/close.svg';
import Modal from 'react-modal';

function BTokenModal({
  isOpen,
  isDrawerOnMobile,
  children,
  close,
  showCloseButton,
  customWidth,
  customMaxHeight
}) {
  const hiddenClass = 'backdrop-component';
  const [modalMaxWidth, setModalMaxWidth] = useState();

  const { width: windowWidth } = useWindowDimensions();
  const isMobile = windowWidth < 768;
  const isSmallest = windowWidth < 330;

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      close();
    }
  }, []);

  const handleClick = (e) => {
    if (e.target.classList.contains(hiddenClass)) close();
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick, false);
    };
  }, []);

  useEffect(() => {
    if (isMobile && !isDrawerOnMobile) {
      setModalMaxWidth(isSmallest ? '90%' : '80%');
    } else if (isDrawerOnMobile && isMobile) {
      setModalMaxWidth('100%');
    } else {
      setModalMaxWidth(customWidth ? customWidth : 480);
    }
  }, [isMobile]);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Modal"
      className={{
        afterOpen: 'backdrop-component -show ocean-iso',
        base: 'backdrop-component ocean-iso',
        beforeClose: 'backdrop-component ocean-iso'
      }}
      overlayClassName="backdrop-component -show -overlay"
      ariaHideApp={false}
      shouldReturnFocusAfterClose={false}
      id="modal"
    >
      <div
        className={isDrawerOnMobile ? 'modal-body -drawer' : 'modal-body'}
        style={{
          width: customWidth,
          maxWidth: modalMaxWidth,
          maxHeight: customMaxHeight
        }}
      >
        <img
          src={closeIcon}
          alt="Fechar"
          className={showCloseButton ? 'close' : 'close -hide'}
          onClick={() => close()}
        />
        {children}
      </div>
    </Modal>
  );
}

BTokenModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  close: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  customWidth: PropTypes.number,
  isDrawerOnMobile: PropTypes.bool,
  customMaxHeight: PropTypes.string
};

BTokenModal.defaultProps = {
  showCloseButton: false,
  customWidth: null,
  isDrawerOnMobile: true,
  customMaxHeight: '80vh'
};

export default BTokenModal;
