import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';
import { Formik } from 'formik';
import { Container, Row, Col } from '@useblu/ocean-react';
import { railsContextSelector } from 'selectors/rails-context';

import useModalBaseManagement from 'hooks/useModalBaseManagement';
import { getUser, updateUserInfo } from 'api/first-access';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {
  Content,
  Title,
  WrapInput,
  CustomButtonSubmit,
  CustomModal,
  CustomInput
} from './styles';

import {
  optionsIncome,
  optionsPatrimony,
  optionsDocumentType
} from './selectOptions';

import SelectControl from './components/SelectControl';
import AutocompleteFormWithData from './components/AutocompleteFormWithData';

const ModalComplementaryUserInfo = ({ isOpen }) => {
  const [incomeValue, setIncomeValue] = useState();
  const [patrimonyValue, setPatrimonyValue] = useState();
  const [documentTypeValue, setDocumentTypeValue] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [userData, setUserData] = useState(null);

  const {
    currentUserPhone,
    currentUserName,
    currentUserCpf,
    currentUserId,
    clientId
  } = useSelector(railsContextSelector);

  const formik = {
    initialValues: {
      cpf: '',
      nationality: '',
      income: '',
      patrimony: '',
      documentType: '',
      documentNumber: '',
      documentExpeditionDate: '',
      documentOrigin: '',
      documentPlaceEmission: '',
      zipCode: '',
      street: '',
      number: '',
      neighborhood: '',
      city: '',
      state: '',
      complement: '',
      phone: '',
      email: ''
    },
    onSubmit: async (values) => {
      try {
        await updateUserInfo({
          id: clientId,
          user: normalizeDataToSubmit(values)
        });
        onClose();
      } catch (err) {
        onClose();
        throw err;
      }
    }
  };

  const { isModalComplementaryUserInfOpened, closeModal } =
    useModalBaseManagement();

  const onClose = () => closeModal();

  const normalizeDataToSubmit = (data) => {
    let dataParsed = { ...data };
    const dateReversed = dataParsed.documentExpeditionDate.split('/').reverse();

    dataParsed.documentExpeditionDate = `${dateReversed[0]}/${dateReversed[1]}/${dateReversed[2]}`;
    dataParsed.income = dataParsed.income.label || dataParsed.income;
    dataParsed.patrimony = dataParsed.patrimony.label || dataParsed.patrimony;
    dataParsed.documentType =
      dataParsed.documentType.label || dataParsed.documentType;

    dataParsed.mainAddress = {
      number: dataParsed.number,
      neighborhood: dataParsed.neighborhood,
      street: dataParsed.street,
      zipcode: dataParsed.zipCode,
      complement: dataParsed.complement,
      city: dataParsed.city,
      state: dataParsed.state
    };

    if (
      currentUserPhone?.replace(/\D/g, '') === data.phone.replace(/\D/g, '')
    ) {
      dataParsed.phone = '';
    }

    dataParsed.name = currentUserName;
    dataParsed = omit(dataParsed, [
      'number',
      'neighborhood',
      'street',
      'city',
      'state',
      'complement',
      'zipCode'
    ]);

    return dataParsed;
  };

  useEffect(() => {
    if ((currentUserId && isModalComplementaryUserInfOpened) || isOpen) {
      getUser({ id: currentUserId })
        .then((value) => setUserData(value.data.data.user))
        .catch(() => onClose());
    }
  }, [isModalComplementaryUserInfOpened, isOpen, currentUserId]);

  return (
    <CustomModal
      portalClassName="ocean-iso"
      isOpen={isModalComplementaryUserInfOpened || isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <Content>
        <Formik initialValues={formik.initialValues} onSubmit={formik.onSubmit}>
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <Container>
                <AutocompleteFormWithData
                  user={userData}
                  currentUserCpf={currentUserCpf}
                  setIncomeValue={setIncomeValue}
                  setPatrimonyValue={setPatrimonyValue}
                  setDocumentTypeValue={setDocumentTypeValue}
                />

                <Row>
                  <Col xs={12}>
                    <Title>Confirme seus dados</Title>
                  </Col>
                </Row>

                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList>
                    <Tab>Informações pessoais</Tab>
                    <Tab>Endereço</Tab>
                    <Tab>Dados de Contato</Tab>
                  </TabList>

                  <TabPanel>
                    <Row>
                      <Col xs={12} sm="6">
                        <WrapInput>
                          <CustomInput
                            id="cpf"
                            name="cpf"
                            mask="cpf"
                            label="CPF"
                            data-testid="cpf"
                            value={props.values.name}
                          />
                        </WrapInput>
                      </Col>
                      <Col xs={12} sm="6">
                        <CustomInput
                          type="text"
                          id="nationality"
                          name="nationality"
                          label="Nacionalidade"
                          value={props.values.name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={6}>
                        <SelectControl
                          name="income"
                          label="Renda Mensal (Salário minimo)"
                          placeholder="Selecione"
                          options={optionsIncome}
                          valueSelect={incomeValue}
                          setValue={setIncomeValue}
                        />
                      </Col>

                      <Col xs={12} sm={6}>
                        <SelectControl
                          name="patrimony"
                          label="Patrimônio pessoal"
                          placeholder="Selecione"
                          options={optionsPatrimony}
                          valueSelect={patrimonyValue}
                          setValue={setPatrimonyValue}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={6}>
                        <SelectControl
                          name="documentType"
                          label="Tipo do documento"
                          placeholder="Selecione"
                          options={optionsDocumentType}
                          valueSelect={documentTypeValue}
                          setValue={setDocumentTypeValue}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <CustomInput
                          type="text"
                          id="documentNumber"
                          name="documentNumber"
                          label="Numero do documento"
                          value={props.values.name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={4}>
                        <CustomInput
                          type="text"
                          id="documentExpeditionDate"
                          name="documentExpeditionDate"
                          label="Data de emissão"
                          mask="date"
                          value={props.values.name}
                        />
                      </Col>
                      <Col xs={12} sm={4}>
                        <CustomInput
                          type="text"
                          id="documentOrigin"
                          name="documentOrigin"
                          label="Órgão emissor"
                          value={props.values.name}
                        />
                      </Col>
                      <Col xs={12} sm={4}>
                        <CustomInput
                          type="text"
                          id="documentPlaceEmission"
                          name="documentPlaceEmission"
                          label="Estado emissor"
                          value={props.values.name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <CustomButtonSubmit onClick={() => setTabIndex(1)}>
                          Próxima
                        </CustomButtonSubmit>
                      </Col>
                    </Row>
                  </TabPanel>

                  <TabPanel>
                    <Row>
                      <Col xs={12} sm={6}>
                        <CustomInput
                          type="text"
                          id="zipCode"
                          name="zipCode"
                          label="Cep"
                          mask="zipcode"
                          data-testid="zipcode"
                          value={props.values.name}
                        />
                      </Col>

                      <Col xs={12} sm={6}>
                        <CustomInput
                          type="text"
                          id="street"
                          name="street"
                          label="Logradouro"
                          data-testid="street"
                          value={props.values.name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={6}>
                        <CustomInput
                          type="text"
                          id="number"
                          name="number"
                          label="Número"
                          data-testid="number"
                          value={props.values.name}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <CustomInput
                          type="text"
                          id="neighborhood"
                          name="neighborhood"
                          label="Bairro"
                          data-testid="neighborhood"
                          value={props.values.name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={6}>
                        <CustomInput
                          type="text"
                          id="city"
                          name="city"
                          label="Cidade"
                          value={props.values.name}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <CustomInput
                          type="text"
                          id="state"
                          name="state"
                          label="Estado"
                          value={props.values.name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <CustomInput
                          type="text"
                          id="complement"
                          name="complement"
                          label="Complemento"
                          value={props.values.name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <CustomButtonSubmit onClick={() => setTabIndex(2)}>
                          Próxima
                        </CustomButtonSubmit>
                      </Col>
                    </Row>
                  </TabPanel>

                  <TabPanel>
                    <Row>
                      <Col xs={12} sm={6}>
                        <CustomInput
                          id="phone"
                          name="phone"
                          label="Celular"
                          mask="phone"
                          data-testid="phone"
                          value={props.values.name}
                        />
                      </Col>

                      <Col xs={12} sm={6}>
                        <CustomInput
                          type="text"
                          id="email"
                          name="email"
                          label="Email"
                          data-testid="email"
                          value={props.values.name}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <CustomButtonSubmit type="submit">
                          Salvar
                        </CustomButtonSubmit>
                      </Col>
                    </Row>
                  </TabPanel>
                </Tabs>
              </Container>
            </form>
          )}
        </Formik>
      </Content>
    </CustomModal>
  );
};

export default ModalComplementaryUserInfo;
