/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@useblu/ocean-react';
import { useAsyncFn, useMedia } from 'react-use';

import { getClientStep } from 'api/clients';

import useClientFeatureStatus from 'components/Dashboard/hooks/useClientFeatureStatus';
import useModalManagement from 'components/Dashboard/hooks/useModalManagement';
import {
  ChatGreetingContainer,
  GreetingContainer,
  ChatContainer,
  VerticalLine,
} from './ReceptionRoomModal.styles';
import {
  FooterText,
  ScrollMobile,
  ModalContent,
  ModalFooterContainer,
  Frame,
  BtnsWrap,
} from '../modal.styles';
import FakeChat from '../components/FakeChat';
import { clientDataByType } from './ReceptionRoomModal.data';

const ReceptionRoomModal = ({ onRequestClose }) => {
  const { updateStatus, clientUUID, storeName } = useClientFeatureStatus();
  const { openModal } = useModalManagement();
  const isMobile = useMedia('(max-width: 768px)');

  const [clientStep, setClientStep] = useState('');
  const [loading, setLoading] = useState(true);
  const [clientStepResponse, getClientStepApiRequest] =
    useAsyncFn(getClientStep);

  const clientFirstName = `${storeName.charAt(0).toUpperCase()}${storeName
    .slice(1)
    .split(' ')[0]
    .toLowerCase()}`;

  useEffect(() => {
    getClientStepApiRequest(clientUUID);
  }, []);

  useEffect(() => {
    if (!clientStepResponse.value?.data || clientStepResponse.loading) {
      return;
    }

    const step = clientStepResponse.value?.data?.step;

    setLoading(!step);
    setClientStep(step);
    updateStatus();
  }, [clientStepResponse]);

  const clientData = clientDataByType({
    onRequestClose,
    openModal,
    clientUUID,
  })[clientStep];

  return (
    <Frame>
      <ScrollMobile className="mobile-scroll-to-error">
        <ModalContent>
          <ChatGreetingContainer>
            <GreetingContainer>
              <h2>👋</h2>
              <h1>
                {clientFirstName}!
                <br />
                Boas vindas à Blu
              </h1>
            </GreetingContainer>
            {!isMobile && <VerticalLine />}
            <ChatContainer>
              <FakeChat lastMessage={clientData?.message} />
            </ChatContainer>
          </ChatGreetingContainer>
        </ModalContent>
      </ScrollMobile>
      <ModalFooterContainer>
        <FooterText className="desktop" />
        <BtnsWrap>
          {clientStep === 'client_valid_allow_pos_solicitation' ? (
            <Button
              size="md"
              blocked={isMobile}
              type="button"
              onClick={() => onRequestClose('ContractSigning')}
              variant="secondary"
            >
              Agora não
            </Button>
          ) : (
            <></>
          )}
          <Button
            size="md"
            blocked={isMobile}
            loading={loading}
            type="submit"
            onClick={() => clientData?.buttonAction()}
          >
            {clientData?.buttonText}
          </Button>
        </BtnsWrap>
      </ModalFooterContainer>
    </Frame>
  );
};

ReceptionRoomModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default ReceptionRoomModal;
