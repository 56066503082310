import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function FilterNavOptions({ filterItem, linkItems, sortItem }) {
  return (
    <div className="statement__filter-info">
      <div className="statement__filter-info-right">
        <button
          type="button"
          className="button button--filter"
          onClick={filterItem.onClick}
        >
          <i className="ic ic-filter" />
          {filterItem.text}
        </button>
        <div className="statement__filter-tab-link-container">
          {linkItems.allTransactions.isVisible && (
            <Fragment>
              <span className="statement__filter-pending-separator display-web">
                |
              </span>
              <a
                href={linkItems.allTransactions.href}
                className="all-transactions-link"
              >
                <span
                  className={classNames({
                    active: linkItems.allTransactions.isActive
                  })}
                >
                  Todas
                </span>
              </a>
            </Fragment>
          )}
          {linkItems.pendingTransactions.isVisible && (
            <Fragment>
              <span className="statement__filter-pending-separator">
                &nbsp;
              </span>
              <a
                href={linkItems.pendingTransactions.href}
                className={classNames('statement__filter-pending-link', {
                  active: linkItems.pendingTransactions.isActive
                })}
              >
                <span className="pending-link-description">Pendentes</span>
                <span className="pending-link-counter">
                  {linkItems.pendingTransactions.counter}
                </span>
              </a>
            </Fragment>
          )}
          {linkItems.paidTransactions.isVisible && (
            <Fragment>
              <span className="statement__filter-pending-separator">
                &nbsp;
              </span>
              <a
                href={linkItems.paidTransactions.href}
                className="all-transactions-link"
              >
                <span
                  className={classNames({
                    active: linkItems.paidTransactions.isActive
                  })}
                >
                  Pagas
                </span>
              </a>
            </Fragment>
          )}
        </div>
        {sortItem.isVisible && (
          <Fragment>
            <span className="statement__filter-pending-separator display-web">
              |
            </span>
            <div className="statement__filter-sorting">
              <label htmlFor="sortingFilter">Ordenar Por:</label>
              <select
                id="sortingFilter"
                onChange={sortItem.onSelect}
                defaultValue={sortItem.selected}
              >
                <option className="select__placeholder" value="happened_at">
                  Data de Cobrança
                </option>
                <option className="select__placeholder" value="released_at">
                  Data do Pagamento
                </option>
              </select>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

FilterNavOptions.propTypes = {
  filterItem: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  linkItems: PropTypes.shape({
    allTransactions: PropTypes.object.isRequired,
    pendingTransactions: PropTypes.object.isRequired,
    paidTransactions: PropTypes.object.isRequired
  }).isRequired,
  sortItem: PropTypes.shape({
    selected: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired
  }).isRequired
};

export default FilterNavOptions;
