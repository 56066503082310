import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const TextWrapper = styled.div`
  padding-top: 1rem;
`;

export const MainWrapper = styled.div`
  margin-bottom: 2rem;
`;

const LgpdInfo = ({ responsible, responsibleMail }) => (
  <MainWrapper>
    <TextWrapper>
      Em atendimento a Lei Geral de Proteção de Dados, a Blu informa que o
      encarregado pela proteção de dados é : {responsible}.
    </TextWrapper>
    <TextWrapper>
      Contato: <a href={`mailto:${responsibleMail}`}>{responsibleMail}</a>
    </TextWrapper>
  </MainWrapper>
);

LgpdInfo.propTypes = {
  responsible: PropTypes.string.isRequired,
  responsibleMail: PropTypes.string.isRequired
};

export default LgpdInfo;
