import _ from 'lodash';
import humps from 'lodash-humps';
import moment from 'moment';
import { call, put } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import history from 'utils/browserHistory';
import ActionCreators from 'store/ducks/billetCIP';
import {
  confirmTransaction,
  createPaymentSimulation,
  createPaymentApi,
} from 'api/transaction';
import { validateBilletCIPApi, formatErrorCip } from 'api/billet';
import { isJSON } from 'utils/functions';
import buildAdvancementSimulation from 'utils/buildAdvancementSimulation';

function* validateArbi(params, response) {
  const { errors } = response.data;

  if (errors && errors.length > 0) {
    throw new Error('Ocorreu um problema ao consultar o código de barras');
  }

  const {
    data: { billetCip },
  } = response.data;

  if (billetCip.status === 'failed' && billetCip.errorMessage) {
    throw new Error(billetCip.errorMessage);
  }

  yield put(
    ActionCreators.validateBarCodeSuccess(
      billetCip,
      params.barcode,
      params.initialFetch
    )
  );
}

function* validateBTG(params, response) {
  const { data } = response;

  yield put(
    ActionCreators.validateBarCodeSuccess(
      humps(data),
      params.barcode,
      params.initialFetch
    )
  );
}

export function* validateBilletCIP(params) {
  try {
    const response = yield call(validateBilletCIPApi, params);

    if (params.bankType === 'BTG') {
      yield validateBTG(params, response);
    }

    if (params.bankType === 'Arbi') {
      yield validateArbi(params, response);
    }

    history.push(`/lojas/${params.clientUuid}/transacoes/boleto/contas/dados`);
  } catch (error) {
    let message = '';

    if (params.bankType === 'BTG') {
      const {
        response: {
          data: { errors },
        },
      } = error;

      message = errors[0].user_message;
    }

    if (params.bankType === 'Arbi') {
      message = error.message;
    }

    yield put(ActionCreators.validateBarCodeFailure(message));
  }
}

export function* validateTransactionCIP({ params, clientUuid }) {
  const formId = 'billetCIPValuesForm';

  try {
    yield put(startSubmit(formId));

    const response = yield call(
      createPaymentSimulation,
      humps(params),
      clientUuid
    );

    const { errors } = response.data;

    if (errors && errors.length > 0) {
      throw new Error(errors[0].message);
    }

    const {
      data: {
        data: { createPaymentSimulation: createPaymentSimulationResponse },
      },
    } = response;

    const responseData = {
      ...createPaymentSimulationResponse.clientTransaction,
      similarTransactions: createPaymentSimulationResponse.similarTransactions,
      weightedAverageDay: null,
      advancementSimulation: buildAdvancementSimulation(
        createPaymentSimulationResponse
      ),
    };

    const {
      advancedValue,
      anticipationRate,
      lastTransactionAdvancedFrom,
      similarTransactions,
      weightedAverageDay,
    } = responseData;

    const billet = {
      ...humps(params),
      weightedAverageDay,
      anticipationRate,
      lastTransactionAdvancedFrom,
      advancedValue,
      uuid: responseData.uuid,
    };

    if (
      similarTransactions.length > 0 &&
      !params.similar_transaction_confirmed
    ) {
      const value = similarTransactions[0].netValue;

      const similarTransactionsOrdered = similarTransactions.sort(
        (a, b) =>
          moment(b.createdAt).format('HH:mm') >
          moment(a.createdAt).format('HH:mm')
      );

      const schedules = similarTransactionsOrdered
        .slice(0, 6)
        .map(({ createdAt }, index) => ({
          id: index,
          schedule: moment(createdAt).format('HH:mm'),
        }));

      yield put(
        ActionCreators.setSimilarTransactions({
          value,
          schedules: schedules.sort((a, b) => b.id > a.id),
        })
      );
    } else {
      yield put(ActionCreators.unsetSimilarTransactions());
      yield put(
        ActionCreators.validateTransactionSuccess(billet, responseData)
      );

      history.push(`/lojas/${clientUuid}/transacoes/boleto/contas/confirmacao`);
    }

    yield put(stopSubmit(formId));
  } catch (error) {
    if (isJSON(error.message) && JSON.parse(error.message).validation) {
      yield put(stopSubmit(formId, JSON.parse(error.message).validation));
    } else if (isJSON(error.message)) {
      yield put(stopSubmit(formId, formatErrorCip(JSON.parse(error.message))));
    } else {
      yield put(
        stopSubmit(formId, formatErrorCip({ grossValue: [error.message] }))
      );
    }
    yield put(ActionCreators.validateTransactionFailure());
  }
}

export function* confirmBilletCIP({ params, clientUuid, newModeling }) {
  try {
    let response;
    if (_.isEmpty(params)) {
      throw new Error('Dados do boleto é obrigatório!');
    }
    if (!clientUuid) {
      throw new Error('clientUuid obrigatório!');
    }

    if (newModeling) {
      response = yield call(createPaymentApi, params, clientUuid);

      const { errors } = response.data;

      if (errors && errors.length > 0) {
        throw new Error(
          'Não foi possível confirmar a transação. Por favor, tente novamente mais tarde.'
        );
      }
    } else {
      response = yield call(confirmTransaction, params, clientUuid);
    }

    yield put(ActionCreators.confirmBilletSuccess(response.data));
    window.location = `/lojas/${clientUuid}/transacoes/confirmacao-pagamentos`;
  } catch (e) {
    yield put(ActionCreators.confirmBilletFailure(e.message));
  }
}
