import React from 'react';
import PropTypes from 'prop-types';

const Card = (props) => {
  const styles = {
    height: props.height ? props.height : null,
    ...props.style
  };

  let content = (
    <div className={`card-list ${props.className}`} style={styles}>
      {props.children}
    </div>
  );

  if (props.title) {
    content = (
      <div className={`card-list ${props.className}`} style={styles}>
        {props.title}
        <div>{props.children}</div>
      </div>
    );
  }

  return content;
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.node,
  height: PropTypes.string
};

Card.defaultProps = {
  className: '',
  title: '',
  height: ''
};

export default Card;
