import styled, { createGlobalStyle } from 'styled-components';

import {
  colorInterfaceLightPure,
  colorInterfaceDarkUp,
  spacingInlineMd,
} from '@useblu/ocean-tokens/web/tokens';

export const GlobalStyle = createGlobalStyle`
  .ReactModal__Content div {
    margin: auto;
  }

  .application-dashboard > .dashboard-content > main, .ReactModal__Content {
    background: ${colorInterfaceLightPure};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .ods-typography.ods-typography__paragraph {
    color: ${colorInterfaceDarkUp};
  }

  .pgnt-page {
    padding: ${spacingInlineMd} 0;
    width: 100%;
    height: 100%;
    max-width: 744px;
    min-width: 360px;
  }
`;

export const WrapperMobile = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  background: ${colorInterfaceLightPure};
  height: 100%;
  overflow: hidden auto;
  outline: none;
  position: absolute;
  top: 0px;
  z-index: 5;
  width: 100%;

  .pgnt-page {
    padding: 0;
  }

  .ods-list {
    border-radius: 0;
  }
`;
