export function removeDuplicatesByCpfCnpj(representativesArray) {
  return representativesArray?.reduce((acc, current) => {
    const x = acc.find(
      (representative) => representative.cpfCnpj === current.cpfCnpj
    );
    return x ? acc : acc.concat(current);
  }, []);
}

export function getRepresentativesNames(representativesArray) {
  if (!Array.isArray(representativesArray)) {
    return [];
  }

  const representativesWithCPF = representativesArray?.filter(
    (representative) => representative.documentType?.toLowerCase() === 'cpf'
  );

  const legalRepresentatives = representativesWithCPF?.filter(
    (representative) =>
      representative?.relationshipType?.toLowerCase() === 'representantelegal'
  );

  if (legalRepresentatives?.length > 0) {
    return removeDuplicatesByCpfCnpj(legalRepresentatives);
  }

  const ownersOrQSARepresentatives = representativesWithCPF?.filter(
    (representative) => {
      const relationshipType = representative?.relationshipType?.toLowerCase();
      return relationshipType === 'qsa' || relationshipType === 'ownership';
    }
  );

  const adminRepresentatives = ownersOrQSARepresentatives?.filter(
    (representative) =>
      representative?.position?.toLowerCase().includes('administrador')
  );

  const representatives =
    adminRepresentatives?.length > 0
      ? adminRepresentatives
      : ownersOrQSARepresentatives;

  return removeDuplicatesByCpfCnpj(representatives);
}
