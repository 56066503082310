import { createActions, createReducer } from 'reduxsauce';
import _ from 'lodash';

/* Types & Creators */

const { Types, Creators } = createActions({
  firstAccessQsaAccountsRequest: ['client'],
  firstAccessQsaAccountsSuccess: ['data'],
  firstAccessQsaAccountsFailure: null,
  firstAccessQsaAccountsFormSubmit: ['companyCnpj', 'qsa', 'uuid'],
  firstAccessQsaAccountsSetAddress: ['data'],
  firstAccessQsaAccountsSearchAddress: ['data', 'partner'],
  firstAccessQsaAccountsDeletePartner: ['cpfCnpj', 'companyCnpj'],
  firstAccessQsaAccountsDeletePartnerSuccess: ['data'],
  firstAccessQsaAccountsSetPartner: ['data'],
  firstAccessQsaAccountsSavePartnerRequest: ['client', 'partner'],
  firstAccessQsaAccountsSavePartnerSuccess: ['data'],
  firstAccessQsaAccountsSavePartnerFailure: ['errors'],
  firstAccessQsaAccountsUpdate: ['data']
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  qsa: [],
  form: {
    loading: false
  },
  qsaModal: {
    loading: false
  },
  deleteModal: {
    loading: false
  },
  errors: {},
  partner: { mainAddress: {} }
};

/* Reducers */

export const request = (state) => ({
  ...state,
  form: {
    loading: true
  }
});

export const success = (state, action) => ({
  ...state,
  form: {
    loading: false
  },
  qsa: action.data
});

export const failure = (state) => ({
  ...state,
  form: {
    loading: false
  },
  qsa: []
});

export const formSubmitRequest = (state) => ({
  ...state,
  form: {
    loading: true
  }
});

export const savePartnerRequest = (state) => ({
  ...state,
  qsaModal: {
    loading: true
  }
});

export const savePartnerSuccess = (state, action) => {
  const { qsa } = state;

  const newQsa = qsa.filter(
    (partner) => partner.cpfCnpj !== action.data.cpfCnpj
  );

  newQsa.push(action.data);

  return {
    ...state,
    qsaModal: {
      loading: false
    },
    partner: {},
    qsa: newQsa,
    errors: {}
  };
};

export const savePartnerFailure = (state, action) => ({
  ...state,
  qsaModal: {
    loading: false
  },
  errors: action.errors
});

export const deletePartnerRequest = (state) => ({
  ...state,
  deleteModal: {
    loading: true
  }
});

export const deletePartnerSuccess = (state, action) => {
  const { qsa } = state;

  const qsaWithoutPartner = _.filter(qsa, (partner) => {
    return !_.isEqual(partner.cpfCnpj, action.data.cpfCnpj);
  });

  return {
    ...state,
    deleteModal: {
      loading: false
    },
    partner: {},
    qsa: qsaWithoutPartner
  };
};

export const setPartner = (state, action) => ({
  ...state,
  partner: action.data,
  errors: {}
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_REQUEST]: request,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_SUCCESS]: success,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_FAILURE]: failure,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_FORM_SUBMIT]: formSubmitRequest,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_DELETE_PARTNER]: deletePartnerRequest,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_DELETE_PARTNER_SUCCESS]: deletePartnerSuccess,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_SET_PARTNER]: setPartner,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_SAVE_PARTNER_REQUEST]: savePartnerRequest,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_SAVE_PARTNER_SUCCESS]: savePartnerSuccess,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_SAVE_PARTNER_FAILURE]: savePartnerFailure,
  [Types.FIRST_ACCESS_QSA_ACCOUNTS_UPDATE]: success
});
