import { put, call, takeLatest } from 'redux-saga/effects';
import actions, { types } from './actions';
import viaCep from 'api/via-cep';

export function* watchFetchZipCodeAdressRequest({ zipCode, callback = null }) {
  try {
    const response = yield call(viaCep, zipCode);
    const { data } = response;

    const zipCodeAddress = {
      street: data.logradouro,
      neighborhood: data.bairro,
      complement: data.complemento,
      city: data.localidade,
      state: data.uf,
      zipCode: data.cep || zipCode
    };

    yield put(actions.fetchZipCodeAdressSuccess(zipCodeAddress));

    if (callback) callback();
  } catch (error) {
    let statusCode = 500;
    let errorMsg = 'Algo deu errado ao carregar os seus dados.';

    if (error.response) {
      statusCode = error.response.status;
      errorMsg = error.response.data.message;
    }
    yield put(
      actions.fetchZipCodeAdressFailure({
        statusCode,
        errorMsg
      })
    );
  }
}

export default [
  takeLatest(
    types.FETCH_ZIP_CODE_ADRESS_REQUEST,
    watchFetchZipCodeAdressRequest
  )
];
