import qs from 'qs';
import axios from 'axios';

const transactionsByPeriod = async (filter, uuid) => {
  try {
    const { data } = await axios.get(
      `/lojas/${uuid}/periodo/?${qs.stringify({ filter })}`,
      {
        headers: { accept: 'application/json' }
      }
    );
    return data.period_statement_transactions;
  } catch (err) {
    return [];
  }
};

export default transactionsByPeriod;
