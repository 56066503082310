import { isEmpty } from 'lodash';
import { call, put, select } from 'redux-saga/effects';

import ActionCreators from 'store/ducks/importPaymentConfirmation';
import { importPaymentConfirmationSelector } from 'selectors/payment-confirmation';

import {
  uploadTransferFileApi,
  getImportPaymentConfirmationListApi,
  setImportPaymentsConfirmationApi
} from 'api/import-payment-confirmation';

export function* uploadTransferFile({ file, clientUuid }) {
  try {
    const response = yield call(uploadTransferFileApi, file);

    const { errors } = response.data;

    if (errors) {
      throw new Error(errors[0].message);
    }

    const {
      data: {
        batchPaymentFile: { uuid }
      }
    } = response.data;

    yield put(ActionCreators.uploadTransferFileSuccess(uuid));

    const responseFile = yield call(getImportPaymentConfirmationListApi, uuid);

    const {
      data: {
        data: { batchPaymentTransactionsFile }
      }
    } = responseFile;

    if (
      !batchPaymentTransactionsFile.status &&
      batchPaymentTransactionsFile.errorMessage
    ) {
      throw new Error(batchPaymentTransactionsFile.errorMessage);
    }

    yield put(
      ActionCreators.getImportPaymentConfirmationSuccess(
        batchPaymentTransactionsFile.transactions
      )
    );

    window.location.href = `/lojas/${clientUuid}/transacoes/transferencia/nova/dados-importacao`;
  } catch (error) {
    yield put(ActionCreators.uploadTransferFileFailure(error.message));
  }
}

export function* getImportPaymentConfirmation({ fileUuid }) {
  try {
    const response = yield call(getImportPaymentConfirmationListApi, fileUuid);

    const {
      data: {
        data: { batchPaymentTransactionsFile }
      }
    } = response;

    if (
      !batchPaymentTransactionsFile.status &&
      batchPaymentTransactionsFile.errorMessage
    ) {
      throw new Error(batchPaymentTransactionsFile.errorMessage);
    }

    yield put(
      ActionCreators.getImportPaymentConfirmationSuccess(
        batchPaymentTransactionsFile.transactions
      )
    );
  } catch (error) {
    yield put(
      ActionCreators.getImportPaymentConfirmationFailure(error.message)
    );
  }
}

export function* setImportPaymentConfirmation({
  uuid: clienteUuid,
  transactions: transactionsParam
}) {
  const bluIndexes = [];
  const dataTransactions = transactionsParam.slice();

  dataTransactions.forEach((element, index) => {
    if (
      element.blu &&
      (!isEmpty(element.bankCode.replace(/0/g, '')) ||
        !isEmpty(element.bankAccount.replace(/0/g, '')) ||
        !isEmpty(element.bankBranchCode.replace(/0/g, '')))
    ) {
      bluIndexes.push(index);
      dataTransactions[index] = {
        ...dataTransactions[index],
        blu: false
      };
    }
  });

  try {
    const response = yield call(
      setImportPaymentsConfirmationApi,
      clienteUuid,
      dataTransactions,
      false,
      null
    );

    const {
      data: {
        data: { batchPaymentTransactions }
      }
    } = response;

    const {
      error,
      transactions,
      total,
      otherRates,
      inclusionAt,
      totalWithTaxes,
      totalAdvancedValue,
      numberOfTransactions
    } = batchPaymentTransactions;

    const errorTransaction = transactions.find(
      (e) => e.error.length && !e.warningNotConfirmedSimilarTransaction
    );
    const warning = transactions
      .map((d) => d.warningNotConfirmedSimilarTransaction)
      .find((w) => w);

    const taxesValues = {
      total,
      otherRates,
      inclusionAt,
      totalWithTaxes,
      totalAdvancedValue,
      numberOfTransactions
    };

    bluIndexes.forEach((element) => {
      transactions[element] = {
        ...transactions[element],
        blu: true
      };
    });

    yield put(
      ActionCreators.setImportPaymentConfirmationSuccess(
        transactions,
        error,
        !errorTransaction && warning,
        taxesValues
      )
    );

    if (!error) {
      yield put(ActionCreators.clearErrors());
      window.location.href = `/lojas/${clienteUuid}/transacoes/transferencia/nova/confirmacao-importacao`;
    }
  } catch (error) {
    yield put(
      ActionCreators.setImportPaymentConfirmationFailure('Erro na transação')
    );
  }
}

export function* confirmImportPayment({ uuid, reference }) {
  try {
    const { data } = yield select(importPaymentConfirmationSelector);

    const dataTransactions = data.slice();

    dataTransactions.forEach((element, index) => {
      if (
        element.blu &&
        (!isEmpty(element.bankCode.replace(/0/g, '')) ||
          !isEmpty(element.bankAccount.replace(/0/g, '')) ||
          !isEmpty(element.bankBranchCode.replace(/0/g, '')))
      ) {
        dataTransactions[index] = {
          ...dataTransactions[index],
          blu: false
        };
      }
    });

    const response = yield call(
      setImportPaymentsConfirmationApi,
      uuid,
      dataTransactions,
      true,
      reference
    );

    const {
      data: {
        data: { batchPaymentTransactions }
      }
    } = response;

    const { error } = batchPaymentTransactions;

    if (error && error.length > 0) {
      throw new Error(error);
    }

    yield put(ActionCreators.confirmImportPaymentSuccess());
    yield put(ActionCreators.clearImportPaymentConfirmation());

    window.location.href = `/lojas/${uuid}/transacoes/confirmacao-pagamentos`;
  } catch (error) {
    yield put(ActionCreators.confirmImportPaymentFailure(error.message));
  }
}
