import React from 'react';
import ContractDownload from './components/ContractDownload';
import LgpdInfo from './components/LgpdInfo';

const BluInformations = () => (
  <div className="container">
    <div className="flex-container">
      <h1 className="page-title">Informações Blu</h1>

      <h2 className="page-subtitle">Lei Geral de Proteção de Dados</h2>
      <LgpdInfo
        responsible="Juliana Malinowski"
        responsibleMail="lgpd@useblu.com.br"
      />

      <h2 className="page-subtitle">Contrato Plataforma Blu</h2>
      <ContractDownload />
    </div>
  </div>
);

export default BluInformations;
