import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useMenuOpen from '../hooks/useMenuOpen';
import { DropdownMenuContainer, Content } from './DropdownMenu.styles';

// ATTENTION:
// This is a self contained component, it should be replaced by the dropdown
// from ocean asap.

const DropdownMenu = ({
  displayClass,
  title,
  children,
  action,
  width,
  position,
  lockMouseEvents
}) => {
  const {
    classes,
    actionsProps,
    containerProps,
    isOpen,
    isContentMounted,
    ref
  } = useMenuOpen(action);

  return (
    <DropdownMenuContainer
      className={displayClass}
      ref={ref}
      data-testid={title}
      {...(action === 'hover' && containerProps)}
    >
      <button
        type="button"
        id="open"
        data-testid="open"
        aria-label={title}
        {...(!lockMouseEvents && actionsProps)}
        className={classNames({ 'is-open': isOpen })}
      >
        {children[0]}
      </button>

      {children[1] && isContentMounted && (
        <Content
          position={position}
          className={classes}
          data-testid="content"
          style={{ width }}
        >
          {children[1]}
        </Content>
      )}
    </DropdownMenuContainer>
  );
};

DropdownMenu.propTypes = {
  action: PropTypes.oneOf(['click', 'hover']),
  position: PropTypes.oneOf(['left', 'right']),
  displayClass: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  width: PropTypes.string,
  title: PropTypes.string,
  lockMouseEvents: PropTypes.bool
};

DropdownMenu.defaultProps = {
  width: '220px',
  action: 'click',
  position: 'right',
  title: '',
  lockMouseEvents: false,
  displayClass: null
};

export default DropdownMenu;
