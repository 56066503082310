import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class HeaderAccountBalance extends Component {
  static propTypes = {
    total: PropTypes.string.isRequired,
    current: PropTypes.string.isRequired,
    future: PropTypes.string.isRequired,
    pending: PropTypes.string.isRequired
  };

  state = {
    open: false
  };

  toggleDetails = (e) => {
    e.preventDefault();

    this.setState((state) => ({ open: !state.open }));
  };

  render() {
    const { total, current, future, pending } = this.props;
    const { open } = this.state;

    const accountDetailClasses = classNames({
      'account-balance__details': true,
      'is-open': open
    });

    return (
      <div className="account-balance">
        <div className={accountDetailClasses}>
          <div className="account-balance__detail">
            <small>Em análise</small>
            <strong>{pending}</strong>
          </div>

          <div className="account-balance__detail">
            <small>Atual</small>
            <strong>{current}</strong>
          </div>

          <div className="account-balance__detail">
            <small>A receber</small>
            <strong>{future}</strong>
          </div>
        </div>

        <div className="account-balance__info">
          <small>saldo disponível</small>
          <strong>{total}</strong>
        </div>

        <button
          type="button"
          className="button button--small button--outline"
          onClick={(e) => this.toggleDetails(e)}
        >
          {open && 'menos'} detalhes
        </button>
      </div>
    );
  }
}

export default HeaderAccountBalance;
