import {
  spacingStackXs,
  spacingStackXxs
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

const StyledTextField = styled.div`
  margin-bottom: ${(props) =>
    props.checkboxMargin ? spacingStackXxs : spacingStackXs};
`;

export default StyledTextField;
