import React from 'react';
import PropTypes from 'prop-types';

import FilesUpload from './FilesUpload';

import { Caption } from '../modal.styles';

const LagLicenseUpload = ({ file, setFile, loading }) => (
  <>
    <Caption>
      Clientes do segmento de <strong>Artigos de Defesa e Segurança</strong>,
      para concluir a solicitação, devem enviar a Licença de Armeiro utilizando
      o campo a seguir:
    </Caption>
    <FilesUpload file={file} setFile={setFile} loading={loading} />
  </>
);

LagLicenseUpload.propTypes = {
  file: PropTypes.object.isRequired,
  setFile: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

LagLicenseUpload.defaultProps = {
  loading: false
};

export default LagLicenseUpload;
