import masker from 'vanilla-masker';
import maskInput, { masks } from 'utils/masks';
import _ from 'lodash';
import UsersForm from 'components/UsersForm';

export default class SalesForm {
  constructor(options) {
    // Initialize client type switch
    let self = this;

    $('.client_kind input[type=radio]')
      .on('change', function () {
        self.changeClientType($(this).val());
      })
      .filter(':checked')
      .trigger('change');

    this.autocomplete();
    this.collapseHandler();
    this.bankMasks();
    this.cardsHandler();

    new UsersForm(options);
  }

  // Change the client type, hidding/disabling some fields
  changeClientType(type) {
    let $form = $('form#new_client'),
      _type = type.indexOf('PJ') !== -1 ? 'PJ' : 'PF',
      _has_partner = type.indexOf('parceiro') !== -1;

    $form.removeClass('pf pj parceiro').addClass(type.toLowerCase());

    $('[data-kind] :input').attr('disabled', true);
    $(`[data-kind~="${_type}"] :input`).attr('disabled', false);

    $('[data-toggle-result] :input').attr('disabled', _type === 'PF');

    if (_has_partner)
      $('[data-kind~="Parceiro"] :input').attr('disabled', false);
  }

  // Initialize autocomplete fields that are based on another field
  autocomplete() {
    $(':input[data-autocomplete]').each(function () {
      let $input = $(this),
        $reference = $($input.data('autocomplete'));

      $reference.each(function () {
        $(this)
          .on('keyup', function () {
            $input.val($(this).val()).trigger('keyup');
          })
          .trigger('keyup');
      });
    });
  }

  // Initialize collapsible field groups
  collapseHandler() {
    $('.client-data-group[data-collapsible] .page-subtitle')
      .on('click', function () {
        const $parent = $(this).closest('[data-collapsible]');

        $parent.children('.content-collapsible').toggle();
        $parent.toggleClass('open closed');
      })
      .trigger('click');
  }

  // Initialize bank details masks
  bankMasks() {
    $('select[name*=bank_id]')
      .on('change', function () {
        let $option = $(this).find('option:selected'),
          $branch = $(':input[name*=bank_account][name*=office]')[0],
          $account = $(':input[name*=bank_account][name*=number]')[0],
          compensationCode = parseInt($option.data('compensation-code')),
          mask = _.get(masks, `bank.${compensationCode}`);

        if (!mask) compensationCode = 'noop';

        masker($branch).unMask();
        maskInput($branch, `bank.${compensationCode}.branch`);

        masker($account).unMask();
        maskInput($account, `bank.${compensationCode}.account`);
      })
      .trigger('change');
  }

  cardsHandler() {
    // Initialize tax plan cards handler
    const $cards = $('.card.tax-plan');

    $cards
      .on('click', (e) => {
        $cards.removeClass('selected').find('input').attr('disabled', true);
        $(e.currentTarget)
          .addClass('selected')
          .find('input')
          .attr('disabled', false);
      })
      .filter('.selected')
      .trigger('click');

    // Add first to default
    $cards.first().click();

    // Initialize tax plan percentage toggles
    $(':input[name*=percentage]')
      .on('change', function () {
        let percentage = $(this).is(':checked'),
          name = $(this).attr('name').replace('_percentage', ''),
          field = $(`.card.tax-plan :input[name="${name}"]`)[0];

        // Toggle taxes type
        const mask = percentage ? 'percentage' : 'currency';
        masker(field).unMask();
        maskInput(field, mask);

        if (name == '[all_taxes]') {
          $('.card.tax-plan .detailed-taxes :input[name*=percentage]')
            .attr('checked', percentage)
            .trigger('change');
        }
      })
      .trigger('change');

    // Initialize taxes toggle
    $('.button[data-toggle]').on('click', () => {
      $('.simple-taxes,.detailed-taxes').toggle();
    });
  }
}
