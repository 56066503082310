import styled from 'styled-components';

import { Grid } from '@useblu/ocean-react';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  tablet: `(max-width: 1024px)`
};

export const GridContainer = styled(Grid.Container)`
  &&& {
    @media ${devices.mobile} {
      .ods-row {
        flex: 1;
      }

      .ods-col-12 {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
