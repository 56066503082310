import React from 'react';
import ContentLoader from 'react-content-loader';

import ScoreCardChargedLayout from './ScoreCardChargedLayout';

const ScoreCardChargedLoading = () => (
  <ScoreCardChargedLayout>
    <ContentLoader
      data-testid="content-loader"
      speed={2}
      width={328}
      height={60}
      viewBox="0 0 328 60"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="28" cy="28" r="28" />
      <rect x="255" y="8" rx="20" ry="20" width="72" height="34" />
      <rect x="77" y="10" rx="3" ry="3" width="150" height="15" />
      <rect x="77" y="32" rx="3" ry="3" width="80" height="15" />
    </ContentLoader>
  </ScoreCardChargedLayout>
);

export default ScoreCardChargedLoading;
