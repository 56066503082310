import styled from 'styled-components';
import { Typography, Button } from '@useblu/ocean-react';
import {
  colorBrandPrimaryPure,
  shadowLevel1,
  colorInterfaceLightPure,
  colorInterfaceDarkUp
} from '@useblu/ocean-tokens/web/tokens';
import { VerticalContainer } from 'components/PaymentCollectionDetails/Styles';

export const Container = styled(VerticalContainer)`
  background-color: #f6f8fb;
`;

export const Paper = styled.div`
  max-width: 420px;
  width: 100%;
  margin: 0 10px 10px;
  background-color: #f6f8fb;
  border-radius: 24px;
  box-shadow: ${shadowLevel1};
  padding: 12px 24px 24px;
  text-align: center;
  display: grid;
  justify-items: center;
  row-gap: 24px;
  background-color: ${colorInterfaceLightPure};
`;

export const StatusCode = styled(Typography).attrs(() => ({
  variant: 'paragraph'
}))`
  &&& {
    color: ${colorInterfaceDarkUp};
  }
`;

export const Title = styled(Typography).attrs(() => ({
  variant: 'heading3'
}))`
  &&& {
    color: ${colorBrandPrimaryPure};
    margin-bottom: 10px;
  }
`;

export const ErrorMsg = styled(Typography).attrs(() => ({
  variant: 'paragraph'
}))`
  word-break: break-word;
`;

export const HomeBtn = styled(Button)`
  display: grid;
  align-items: center;

  &&& {
    color: ${colorInterfaceLightPure};
  }
`;
