import React from 'react';
import PropTypes from 'prop-types';

import { CardContainer, InfoCardIcon, InfoCardTitle } from './styles';

const InfoCard = ({ icon, title, Text, Tag }) => {
  return (
    <CardContainer>
      {Tag && <Tag />}
      <InfoCardIcon src={icon} alt="" />
      <InfoCardTitle>{title}</InfoCardTitle>
      <Text />
    </CardContainer>
  );
};

InfoCard.defaultProps = {
  icon: '',
  title: '',
  Text: null,
  Tag: null
};

InfoCard.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  Text: PropTypes.func,
  Tag: PropTypes.func
};

export default InfoCard;
