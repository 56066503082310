import React from 'react';

import { WhatsappOutline } from '@useblu/ocean-icons-react';

import * as S from '../devise.styles';

const WhatsappButton = () => (
  <S.WhatsappWrapper
    target="_blank"
    href="https://api.whatsapp.com/message/FTBZGMHEX2ZHE1?autoload=1&app_absent=0"
  >
    <WhatsappOutline />
  </S.WhatsappWrapper>
);

export default WhatsappButton;
