import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import { Modal, Button, Alert, Progress } from '@useblu/ocean-react';
import Register from '../../assets/ilustra-rpa-modal-intervensao.svg';

import { ModalStyle, Body, Img, Title, Paragraph, Footer } from './styles';

function ModalCompleteYourData({ isVisible, toogleModal, error, loading }) {
  useEffect(() => {
    ReactGA.event({
      category: 'rpacadastro',
      action: 'rpa_pendency_view'
    });
  }, []);

  const handleTrackOnClose = () => {
    toogleModal(false);
    ReactGA.event({
      category: 'rpacadastro',
      action: 'rpa_personal_data_view'
    });
  };

  return (
    <>
      <Modal
        isOpen={isVisible}
        portalClassName="ocean-iso is-rpa"
        onRequestClose={() => {}}
        maxWidth="sm"
        ariaHideApp={false}
        disableClose
        id="rpa_pendency_view"
      >
        <Body>
          <Img src={Register} alt="Register" />
          <Title>Ops, você precisa terminar de preencher alguns dados.</Title>
          <Paragraph>
            Complete seu cadastro uma única vez para continuar navegando na sua
            Conta Digital Blu.
          </Paragraph>
        </Body>
        <Footer>
          <Button
            data-testid={loading && 'loading'}
            disabled={loading || error}
            onClick={handleTrackOnClose}
          >
            {loading ? <Progress /> : 'Completar dados'}
          </Button>
          <Alert>
            Estes dados são necessários para a emissão do Recibo de Pagamento
            Autônomo, documento que formaliza o pagamento para serviços
            prestados por pessoas físicas para empresas.
          </Alert>
        </Footer>
      </Modal>
      <ModalStyle />
    </>
  );
}

ModalCompleteYourData.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toogleModal: PropTypes.func.isRequired,
  error: PropTypes.bool,
  loading: PropTypes.bool
};

ModalCompleteYourData.defaultProps = {
  error: false,
  loading: false
};

export default ModalCompleteYourData;
