import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

export function searchClient(cpfCnpj) {
  return axios.post('/lojas/search.json', { cpf_cnpj: cpfCnpj });
}

export function saveClient(params, clientId) {
  return axios.post(`/lojas/${clientId}/contas-bancarias.json`, {
    bank_account: params,
  });
}

export function hasPosTransactions(clientUuid) {
  return axios.post('/graphql', {
    query: `{ client(uuid: "${clientUuid}") { hasPosTransactions } }`,
  });
}

export function searchOnAPI(term, clientId) {
  return axios({
    method: 'get',
    url: `/lojas/${clientId}/cobrancas/cobrados`,
    headers: { 'Cache-Control': 'no-cache', Accept: 'application/json' },
    params: {
      'payment_collection[search_term]': term,
    },
  });
}

export function getClientAccounts(uuid, cpfCnpj) {
  return axios.get(
    `/api/clients/${uuid}/history_payees/by_cpf_cnpj/${cpfCnpj}`,
    {
      headers: {
        'X-CSRF-Token': getCSRFToken(),
        Accept: 'version=1',
      },
    }
  );
}

export function enableCombinedProducts(uuid, optinForAllCnpjs, acquirers) {
  const query = `
    mutation {
      enableCombinedProducts(clientUuid: "${uuid}", includeAllCnpjs: ${optinForAllCnpjs}, acquirerIds: [${acquirers}]) {
        success
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function getClientStep(uuid) {
  return axios.post(
    `/api/clients/${uuid}/next_step`,
    { uuid },
    {
      headers: {
        'X-CSRF-Token': getCSRFToken(),
        Accept: 'version=1',
        'Content-Type': 'application/json',
      },
    }
  );
}

export function getClientStatusApi(uuid) {
  const query = `
    query {
      client(uuid: "${uuid}") {
        socialContractStatus
        federalRevenueStatus
        coreDepartment
        automaticAnticipationEnabledAt
        needSocialContract
        taxPlan {
          enableAutomaticAnticipation
        }
        clientCercAgreement {
          status
          agreedAt
        }
        multiAcquirerOrders {
          status
        }
        addresses {
          main
          zipcode
          street
          number
          complement
          neighborhood
          city
          state
       }
      }
    }
  `;

  return axios.post('/graphql', { query });
}

export function anticipationOptinApi(uuid, product, optinForAllCnpjs) {
  return axios.post(
    `/api/anticipations`,
    {
      client_uuid: uuid,
      product,
      include_all_cnpjs: optinForAllCnpjs,
    },
    {
      headers: {
        'X-CSRF-Token': getCSRFToken(),
        Accept: 'version=1',
      },
    }
  );
}

export function disableAutomaticAnticipation(clientUuid) {
  return axios.delete(`/api/anticipations/${clientUuid}`, {
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      Accept: 'version=1',
    },
  });
}

export function automaticAnticipationStatusApi(uuid) {
  return axios.get(`/api/anticipations/${uuid}`, {
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      Accept: 'version=1',
    },
  });
}

export function filesUpload({ document, clientUUID }) {
  const formData = new FormData();
  formData.append('social_contract', document);

  return axios.post(`/api/clients/${clientUUID}/files_upload`, formData, {
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      'Content-Type': 'multipart/form-data',
      Accept: 'version=1',
    },
  });
}

export function getClientDetails(clientUUID) {
  return axios.get(`/api/clients/${clientUUID}`, {
    headers: {
      'X-CSRF-Token': getCSRFToken(),
      Accept: 'version=1',
    },
  });
}
