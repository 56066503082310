import React from 'react';
import PropTypes from 'prop-types';

import { Typography, TransactionListItem } from '@useblu/ocean-react';
import { colorBrandPrimaryPure } from '@useblu/ocean-tokens/web/tokens';
import {
  ChevronRight,
  LockTaxOutline,
  VariableOutline
} from '@useblu/ocean-icons-react';

import {
  PlanContent,
  MoreInformation,
  PlanSelectorContainer
} from '../modal.styles';

const PlanSelector = ({
  nextStep,
  automaticAnticipationFixRate,
  automaticAnticipationVariableRate,
  clientUUID
}) => (
  <PlanSelectorContainer>
    <Typography style={{ color: colorBrandPrimaryPure }} variant="heading1">
      Escolha a melhor solução para o seu negócio
    </Typography>

    <PlanContent>
      <TransactionListItem
        icon={<VariableOutline />}
        level2="Feita para quem vende em menos parcelas"
        onClick={() => nextStep('automatic_anticipation_by_installments')}
        value={<ChevronRight />}
      >
        Taxa variável a partir de {automaticAnticipationVariableRate}%
      </TransactionListItem>
      <TransactionListItem
        icon={<LockTaxOutline />}
        level2="Ideal para quem vende em muitas parcelas"
        onClick={() => nextStep('automatic_anticipation')}
        value={<ChevronRight />}
      >
        Taxa fixa de {automaticAnticipationFixRate}%
      </TransactionListItem>
      <MoreInformation>
        Não sabe qual a melhor opção?{' '}
        <a href={`/lojas/${clientUUID}/antecipacao-automatica-com-cashback`}>
          Clique aqui e saiba mais.
        </a>
      </MoreInformation>
    </PlanContent>
  </PlanSelectorContainer>
);

PlanSelector.propTypes = {
  nextStep: PropTypes.func.isRequired,
  automaticAnticipationFixRate: PropTypes.string,
  automaticAnticipationVariableRate: PropTypes.string,
  clientUUID: PropTypes.string
};

PlanSelector.defaultProps = {
  automaticAnticipationFixRate: '',
  automaticAnticipationVariableRate: '',
  clientUUID: ''
};

export default PlanSelector;
