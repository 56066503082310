import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import humps from 'lodash-humps';

import { fetchPaymentCollectionDetails } from 'api/payment-collection';
import actions, { types } from './actions';

function* watchFetchPaymentCollectionDetailsRequest(action) {
  try {
    const [response] = yield all([
      call(fetchPaymentCollectionDetails, action.uuid, action.isCharger),
      delay(1000)
    ]);

    yield put(
      actions.fetchPaymentCollectionDetailsSuccess(humps(response.data))
    );
  } catch (error) {
    let statusCode = 500;
    let errorMsg = 'Algo deu errado ao carregar os dados dessa cobrança.';

    if (error.response) {
      statusCode = error.response.status;
      errorMsg = error.response.data.message;
    }

    yield put(
      actions.fetchPaymentCollectionDetailsFailure({
        statusCode,
        errorMsg
      })
    );
  }
}

export default [
  takeLatest(
    types.FETCH_PAYMENT_COLLECTION_DETAILS_REQUEST,
    watchFetchPaymentCollectionDetailsRequest
  )
];
