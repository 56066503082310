import styled from 'styled-components';

const devices = {
  mobile: `(max-width: 768px)`,
  tablet: `(max-width: 1024px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`
};

export const WrapperAccordion = styled.div`
  margin: 27px 168px 56px;
  border: 1px solid #ebecf5;
  border-radius: 10px;

  @media ${devices.tablet} {
    margin: 27px 35px 56px;
  }
`;

export default WrapperAccordion;
