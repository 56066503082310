import { all, takeLatest, takeEvery } from 'redux-saga/effects';

/* Types */
import { Types as bankOptionsTypes } from 'store/ducks/bankOptions';
import { Types as signUpTypes } from 'store/ducks/signUp';
import { Types as pendentPayments } from 'store/ducks/pendentPayments';
import { Types as paymentSelected } from 'store/ducks/paymentSelected';

/* Sagas */
import loadBankOptions from 'store/sagas/bankOptions';
import {
  loadTaxPlans,
  loadDepartments,
  loadBigBoost,
  loadBigBoostUserData,
} from 'store/sagas/signUp';
import {
  loadPendentPayments,
  setNewestPaymentInCurrentCategoryAsDefault,
} from 'store/sagas/pendentPayments';
import {
  loadPaymentSelected,
  approvePayment,
  approvePartialPayment,
  declinePayment,
  cancelPayment,
} from 'store/sagas/paymentSelected';

import { Types as groupCompaniesSearchListTypes } from 'store/ducks/groupCompaniesSearchList';
import { Types as groupConfigurationTypes } from 'store/ducks/groupConfiguration';
import { Types as firstAccessSharedTypes } from 'store/ducks/firstAccess/shared';
import { Types as firstAccessClientInfoTypes } from 'store/ducks/firstAccess/clientInfo';
import { Types as firstAccessUsersUploadTypes } from 'store/ducks/firstAccess/usersUpload';
import { Types as firstAccessQsaAccountsTypes } from 'store/ducks/firstAccess/qsaAccounts';
import { Types as firstAccessUsersContractsTypes } from 'store/ducks/firstAccess/usersContracts';
import { Types as firstAccessUsersAccountsTypes } from 'store/ducks/firstAccess/usersAccounts';
import { Types as permissionTypes } from 'store/ducks/firstAccess/permission';
import { Types as clientSelectorTypes } from 'store/ducks/clientSelector';
import { Types as historyTypes } from 'store/ducks/history';
import { Types as paymentConfirmation } from 'store/ducks/paymentConfirmation';
import { Types as importPaymentConfirmationTypes } from 'store/ducks/importPaymentConfirmation';
import { Types as paymentsListTypes } from 'store/ducks/paymentsList';
import { Types as billetCIPTypes } from 'store/ducks/billetCIP';
import { Types as advancementRequestTypes } from 'store/ducks/advancementRequest';
import { Types as transactionAuthorizationConfirmation } from 'store/ducks/transactionAuthorizationConfirmation';
import { Types as clientStatus } from 'store/ducks/clientStatus';
import { Types as batchPayments } from 'store/ducks/batchPayments';
import { Types as clientPaymentMethod } from 'store/ducks/clientPaymentMethods';

/* Sagas */
import {
  removeGroupCompany,
  loadCompanies,
  sendRequestCompany,
  addAllEconomicGroupClient,
} from 'store/sagas/groupCompaniesSearchList';

import {
  loadGroupConfiguration,
  saveGroupConfiguration,
} from 'store/sagas/groupConfiguration';

import getCompaniesStore from 'store/sagas/clientSelector';

// First Access Sagas
import { loadClient, loadUsers } from 'store/sagas/firstAccess/shared';

import {
  saveClientInfo,
  saveClientInfoAddress,
  searchClientAddressByZipcode,
  searchClientAddressByCpfCnpj,
} from 'store/sagas/firstAccess/clientInfo';

import {
  sendUsersUploadEmail,
  sendUsersUploadFile,
} from 'store/sagas/firstAccess/usersUpload';

import {
  loadQsaAccounts,
  qsaSavePartnerRequest,
  qsaDeletePartnerRequest,
  saveQsaProfitParticipations,
} from 'store/sagas/firstAccess/qsaAccounts';

import loadUsersContracts from 'store/sagas/firstAccess/usersContracts';

import {
  loadUsersAccounts,
  deleteUserRequest,
  saveUserRequest,
  updateUserRequest,
} from 'store/sagas/firstAccess/usersAccounts';

import getEconomicGroupHistories from 'store/sagas/history';

import {
  cancelPaymentConfirmation,
  getPaymentConfirmation,
  setPaymentConfirmation,
  setPaymentConfirmationInDate,
} from 'store/sagas/paymentConfirmation';

import {
  uploadTransferFile,
  confirmImportPayment,
  getImportPaymentConfirmation,
  setImportPaymentConfirmation,
} from 'store/sagas/importPaymentConfirmation';

import { getPaymentsList } from 'store/sagas/paymentsList';

import {
  confirmBilletCIP,
  validateBilletCIP,
  validateTransactionCIP,
} from 'store/sagas/billetCIP';

import getClientStatus from 'store/sagas/clientStatus';

import advancementRequest from 'store/sagas/advancementRequest';

import { sagas as ratingSagas } from 'store/ducks/bluRating';
import { sagas as bTokenSagas } from 'store/ducks/bToken';
import { sagas as paymentCollectionDetailsSagas } from 'store/ducks/paymentCollectionDetails';
import { loadBigBoostData } from 'store/sagas/clientBigBoost';
import { Types as clientBigBoostTypes } from 'store/ducks/getClientBigboost';

import {
  clientPaymentMethodRequest,
  sendClientPaymentMethodRequest,
} from 'store/sagas/clientPaymentMethod';

import {
  resendNotification,
  transactionAuthorization,
  showTransactionAuthorizationStatus,
} from 'store/sagas/transactionAuthorizationConfirmation';

import { batchPayment } from 'store/sagas/batchPayments';

import { sagas as rpaRegisterFormSagas } from 'store/ducks/rpaRegisterForm';
import { sagas as zipCodeSagas } from 'store/ducks/zipCode';
import { checkPermission } from './firstAccess/permission';

export default function* root() {
  yield all([
    takeLatest(bankOptionsTypes.BANK_OPTIONS_REQUEST, loadBankOptions),
    takeLatest(signUpTypes.SIGN_UP_LOAD_TAX_PLANS, loadTaxPlans),
    takeLatest(signUpTypes.SIGN_UP_LOAD_DEPARTMENTS, loadDepartments),
    takeLatest(signUpTypes.SIGN_UP_LOAD_BIG_BOOST, loadBigBoost),
    takeLatest(signUpTypes.SIGN_UP_LOAD_USER_BIG_BOOST, loadBigBoostUserData),
    takeLatest(
      pendentPayments.PENDENT_PAYMENTS_UPDATE_SENT_OR_RECEIVED,
      setNewestPaymentInCurrentCategoryAsDefault
    ),
    takeLatest(pendentPayments.PENDENT_PAYMENTS_REQUEST, loadPendentPayments),
    takeLatest(groupCompaniesSearchListTypes.COMPANIES_REQUEST, loadCompanies),
    takeLatest(
      groupCompaniesSearchListTypes.ADD_ALL_ECONOMIC_GROUP_CLIENTS_REQUEST,
      addAllEconomicGroupClient
    ),
    takeLatest(
      groupConfigurationTypes.SAVE_GROUP_NAME_REQUEST,
      saveGroupConfiguration
    ),
    takeLatest(
      groupConfigurationTypes.LOAD_GROUP_CONFIGURATION_REQUEST,
      loadGroupConfiguration
    ),
    takeLatest(paymentSelected.PAYMENT_SELECTED_REQUEST, loadPaymentSelected),
    takeLatest(
      paymentSelected.PAYMENT_SELECTED_APPROVE_REQUEST,
      approvePayment
    ),
    takeLatest(
      paymentSelected.PAYMENT_SELECTED_APPROVE_PARTIAL_REQUEST,
      approvePartialPayment
    ),
    takeLatest(
      paymentSelected.PAYMENT_SELECTED_DECLINE_REQUEST,
      declinePayment
    ),
    takeLatest(paymentSelected.PAYMENT_SELECTED_CANCEL_REQUEST, cancelPayment),
    takeLatest(
      clientSelectorTypes.GET_COMPANIES_STORE_REQUEST,
      getCompaniesStore
    ),

    takeEvery(
      groupCompaniesSearchListTypes.SEND_REQUEST_COMPANY_REQUEST,
      sendRequestCompany
    ),
    takeEvery(
      groupCompaniesSearchListTypes.REMOVE_GROUP_COMPANY_REQUEST,
      removeGroupCompany
    ),
    takeLatest(
      historyTypes.GET_ECONOMIC_GROUP_HISTORIES_REQUEST,
      getEconomicGroupHistories
    ),

    // FIRST ACCESS
    takeLatest(firstAccessSharedTypes.FIRST_ACCESS_CLIENT_REQUEST, loadClient),
    takeLatest(firstAccessSharedTypes.FIRST_ACCESS_USERS_REQUEST, loadUsers),
    takeLatest(
      firstAccessClientInfoTypes.FIRST_ACCESS_CLIENT_INFO_FORM_SUBMIT,
      saveClientInfo
    ),
    takeLatest(
      firstAccessClientInfoTypes.FIRST_ACCESS_CLIENT_INFO_SET_ADDRESS_REQUEST,
      saveClientInfoAddress
    ),
    takeLatest(
      firstAccessClientInfoTypes.FIRST_ACCESS_CLIENT_INFO_SEARCH_ADDRESS_BY_ZIPCODE,
      searchClientAddressByZipcode
    ),
    takeLatest(
      firstAccessClientInfoTypes.FIRST_ACCESS_CLIENT_INFO_SEARCH_ADDRESS_BY_CPF_CNPJ,
      searchClientAddressByCpfCnpj
    ),
    takeLatest(
      firstAccessUsersUploadTypes.FIRST_ACCESS_USERS_UPLOAD_EMAIL_REQUEST,
      sendUsersUploadEmail
    ),
    takeLatest(
      firstAccessUsersUploadTypes.FIRST_ACCESS_USERS_UPLOAD_SUBMIT_REQUEST,
      sendUsersUploadFile
    ),
    takeLatest(
      firstAccessQsaAccountsTypes.FIRST_ACCESS_QSA_ACCOUNTS_REQUEST,
      loadQsaAccounts
    ),
    takeLatest(
      firstAccessQsaAccountsTypes.FIRST_ACCESS_QSA_ACCOUNTS_FORM_SUBMIT,
      saveQsaProfitParticipations
    ),
    takeLatest(
      firstAccessQsaAccountsTypes.FIRST_ACCESS_QSA_ACCOUNTS_SAVE_PARTNER_REQUEST,
      qsaSavePartnerRequest
    ),
    takeLatest(
      firstAccessQsaAccountsTypes.FIRST_ACCESS_QSA_ACCOUNTS_DELETE_PARTNER,
      qsaDeletePartnerRequest
    ),
    takeLatest(
      firstAccessUsersContractsTypes.FIRST_ACCESS_USERS_CONTRACTS_REQUEST,
      loadUsersContracts
    ),
    takeLatest(
      firstAccessUsersAccountsTypes.FIRST_ACCESS_USERS_ACCOUNTS_REQUEST,
      loadUsersAccounts
    ),
    takeLatest(
      firstAccessUsersAccountsTypes.FIRST_ACCESS_USERS_ACCOUNTS_SAVE_USER,
      saveUserRequest
    ),
    takeLatest(
      firstAccessUsersAccountsTypes.FIRST_ACCESS_USERS_ACCOUNTS_UPDATE_USER,
      updateUserRequest
    ),
    takeLatest(
      firstAccessUsersAccountsTypes.FIRST_ACCESS_USERS_ACCOUNTS_DELETE_USER,
      deleteUserRequest
    ),
    takeLatest(
      firstAccessUsersAccountsTypes.FIRST_ACCESS_USERS_ACCOUNTS_DELETE_USER,
      deleteUserRequest
    ),
    takeLatest(permissionTypes.PERMISSION_CHECK, checkPermission),

    takeLatest(
      paymentConfirmation.GET_PAYMENT_CONFIRMATION_REQUEST,
      getPaymentConfirmation
    ),
    takeLatest(
      paymentConfirmation.SET_PAYMENT_CONFIRMATION_REQUEST,
      setPaymentConfirmation
    ),
    takeLatest(
      paymentConfirmation.SET_PAYMENT_CONFIRMATION_IN_DATE_REQUEST,
      setPaymentConfirmationInDate
    ),
    takeLatest(
      paymentConfirmation.CANCEL_PAYMENT_CONFIRMATION_REQUEST,
      cancelPaymentConfirmation
    ),
    takeLatest(paymentsListTypes.GET_PAYMENT_LIST_REQUEST, getPaymentsList),

    takeLatest(
      importPaymentConfirmationTypes.UPLOAD_TRANSFER_FILE_REQUEST,
      uploadTransferFile
    ),

    takeLatest(
      importPaymentConfirmationTypes.GET_IMPORT_PAYMENT_CONFIRMATION_REQUEST,
      getImportPaymentConfirmation
    ),
    takeLatest(
      importPaymentConfirmationTypes.SET_IMPORT_PAYMENT_CONFIRMATION_REQUEST,
      setImportPaymentConfirmation
    ),
    takeLatest(billetCIPTypes.VALIDATE_BAR_CODE_REQUEST, validateBilletCIP),
    takeLatest(
      billetCIPTypes.VALIDATE_TRANSACTION_REQUEST,
      validateTransactionCIP
    ),
    takeLatest(billetCIPTypes.CONFIRM_BILLET_REQUEST, confirmBilletCIP),
    takeLatest(
      importPaymentConfirmationTypes.CONFIRM_IMPORT_PAYMENT_REQUEST,
      confirmImportPayment
    ),
    takeLatest(
      advancementRequestTypes.ADVANCEMENT_REQUEST_REQUEST,
      advancementRequest
    ),

    takeLatest(clientStatus.GET_CLIENT_STATUS_REQUEST, getClientStatus),
    takeLatest(
      transactionAuthorizationConfirmation.TRANSACTION_AUTHORIZATION_REQUEST,
      transactionAuthorization
    ),
    takeLatest(
      transactionAuthorizationConfirmation.SHOW_TRANSACTION_AUTHORIZATION_REQUEST,
      showTransactionAuthorizationStatus
    ),
    takeLatest(
      transactionAuthorizationConfirmation.RESEND_NOTIFICATION_REQUEST,
      resendNotification
    ),

    takeLatest(batchPayments.BATCH_PAYMENTS_REQUEST, batchPayment),

    takeLatest(
      clientPaymentMethod.CLIENT_PAYMENT_METHOD_REQUEST,
      clientPaymentMethodRequest
    ),

    takeLatest(
      clientPaymentMethod.SEND_CLIENT_PAYMENT_METHOD_REQUEST,
      sendClientPaymentMethodRequest
    ),

    takeLatest(clientBigBoostTypes.GET_CLIENT_BIGBOOST, loadBigBoostData),
    ...ratingSagas,
    ...bTokenSagas,
    ...paymentCollectionDetailsSagas,
    ...rpaRegisterFormSagas,
    ...zipCodeSagas,
  ]);
}
