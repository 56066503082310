/* Core */
import React from 'react';

/* Presentational */
import Loading from 'react-loading';

const Load = () => (
  <div className="loading" style={{ marginTop: '30px' }}>
    <Loading
      type="spinningBubbles"
      color="#e1e5ed"
      delay={0}
      width={35}
      height={35}
    />
  </div>
);

export default Load;
