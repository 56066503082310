import React from 'react';
import PropTypes from 'prop-types';

const TableHeaderRow = ({ children }) => <tr>{children}</tr>;

TableHeaderRow.propTypes = {
  children: PropTypes.node.isRequired
};

export default TableHeaderRow;
