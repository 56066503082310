import React from 'react';
import Carousel from 'components/Shared/Carousel';

import {
  Container,
  CardNumber,
  Highlight
} from 'components/AutomaticAnticipationLandingPage/HowToEnable/styles.js';
import useWindowDimensions from 'hooks/useWindowDimensions';
import img1 from './images/img1.svg';
import img2 from './images/img2.svg';
import img3 from './images/img3.svg';
import img4 from './images/img4.svg';
import img5 from './images/img5.svg';

import { CarouselCard, CardImg, CardText } from './styles.js';

function useIsMobile() {
  const { width: windowWidth } = useWindowDimensions();

  return {
    isMobile: windowWidth <= 768
  };
}

const CarouselSteps = () => {
  const { isMobile } = useIsMobile();

  return (
    <Container>
      <Carousel transparent={!isMobile} initialStep={2} arrowNavigation>
        <CarouselCard>
          <CardImg
            src={img1}
            alt="imagem mostrando o botão de habilitar Antecipação Automática"
          />
          <CardNumber>01</CardNumber>
          <CardText>
            <Highlight>
              Cadastre seus clientes e produtos e tenha seus dados sempre à mão
            </Highlight>
            <br />
            Consulte no mesmo lugar os dados dos seus clientes, orçamentos e o
            status de pedidos.
          </CardText>
        </CarouselCard>
        <CarouselCard>
          <CardImg
            src={img2}
            alt="imagem mostrando as opções de 'Aceitar para todas as lojas que sou representante legal' ou 'Aceitar apenas para esta loja'"
          />
          <CardNumber>02</CardNumber>
          <CardText>
            <Highlight>
              Crie seus orçamentos de forma simples e rápida
            </Highlight>
            <br />
            Cadastre seus orçamentos e acompanhe o status dos pedidos dos seus
            clientes em tempo real.
          </CardText>
        </CarouselCard>
        <CarouselCard>
          <CardImg
            src={img3}
            alt="imagem mostrando que os termos de serviço foram aceitos e o botão de ativar"
          />
          <CardNumber>03</CardNumber>

          <CardText>
            <Highlight>
              Entre em contato com o seu cliente via WhatsApp
            </Highlight>
            <br />
            Entre em contato com o seu cliente via WhatsApp
          </CardText>
        </CarouselCard>
        <CarouselCard>
          <CardImg
            src={img4}
            alt="imagem mostrando que a Antecipação Automática foi ativada"
          />
          <CardNumber>04</CardNumber>
          <CardText>
            <Highlight>
              Envie o orçamento online com link para pagamento
            </Highlight>
            <br />
            Seu cliente pode acessar seu orçamento a qualquer momento e em
            qualquer lugar. E o melhor de tudo, ele consegue fechar a compra e
            pagar online!
          </CardText>
        </CarouselCard>
        <CarouselCard>
          <CardImg
            src={img5}
            alt="imagem mostrando que a Antecipação Automática foi ativada"
          />
          <CardNumber>05</CardNumber>
          <CardText>
            <Highlight>Acompanhe os pagamentos efetuados</Highlight>
            <br />
            Você consegue acompanhar os pagamentos efetuados pelos seus clientes
            no seu aplicativo e no portal Blu.
          </CardText>
        </CarouselCard>
      </Carousel>
    </Container>
  );
};

export default CarouselSteps;
