import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TopBar } from '@useblu/ocean-react';
import { useMedia } from 'react-use';
import { ArrowLeftOutline } from '@useblu/ocean-icons-react';
import Snackbar, { useSnackbar } from 'components/Shared/Snackbar';
import { useSelector } from 'react-redux';

import AutomaticAnticipationForm from './AutomaticAnticipationForm';
import { Bar, AutomaticContainer, Description } from './styles';
import './base.css';

const AutomaticAnticipation = ({
  bTokenActive,
  automaticAnticipationFixRate,
  automaticAnticipationWith2FA,
  automaticAnticipationVariableRate,
}) => {
  const isMobile = useMedia('(max-width: 768px)');

  const { isActive, message, type, openSnackBar } = useSnackbar();

  const { automaticAnticipationOptInConfirmed } = useSelector(
    (state) => state.clientStatus
  );

  return (
    <AutomaticContainer>
      <Grid.Row>
        <Grid.Col
          xl={{
            span: '6',
            offset: '3',
          }}
          lg={{
            span: '8',
            offset: '2',
          }}
          md="12"
        >
          <Bar>
            <TopBar
              id="TopBar-playground"
              variants="default"
              title="Antecipação Automática"
              leftIcon={isMobile ? <ArrowLeftOutline /> : null}
              onLeftAction={() => {}}
            />
          </Bar>
          {automaticAnticipationOptInConfirmed && (
            <Description>
              Suas vendas feitas no crédito, de 1x a 12x, caem em até 1 dia
              útil.
            </Description>
          )}
          <AutomaticAnticipationForm
            bTokenActive={bTokenActive}
            openSnackBar={openSnackBar}
            automaticAnticipationWith2FA={automaticAnticipationWith2FA}
            automaticAnticipationVariableRate={
              automaticAnticipationVariableRate
            }
            automaticAnticipationFixRate={automaticAnticipationFixRate}
          />
        </Grid.Col>
      </Grid.Row>
      <Snackbar isActive={isActive} message={message} type={type} />
    </AutomaticContainer>
  );
};

AutomaticAnticipation.propTypes = {
  bTokenActive: PropTypes.bool.isRequired,
  automaticAnticipationWith2FA: PropTypes.bool,
  automaticAnticipationFixRate: PropTypes.string.isRequired,
  automaticAnticipationVariableRate: PropTypes.string.isRequired,
};

AutomaticAnticipation.defaultProps = {
  automaticAnticipationWith2FA: false,
};

export default AutomaticAnticipation;
