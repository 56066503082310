/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  borderRadiusMd,
  spacingInlineXxs,
  spacingInsetXl,
  spacingInlineSm,
  spacingInlineXs,
  spacingInlineMd,
  shadowLevel1,
  lineHeightMedium,
  fontWeightExtrabold,
  lineHeightComfy,
  fontSizeXxxs,
  fontSizeXxs,
  fontSizeXs,
  fontSizeSm,
  fontSizeMd,
  colorBrandPrimaryPure,
  colorInterfaceDarkDown,
  colorInterfaceDarkDeep
} from '@useblu/ocean-tokens/web/tokens';

export const ElementsContainer = styled.div`
  margin: auto;
  @media (min-width: 768px) {
    max-width: 764px;
    padding: 0 20px;
  }
`;

export const Title = styled.h1`
  color: ${colorInterfaceDarkDeep};
  font-weight: ${fontWeightExtrabold};
  font-size: ${fontSizeMd};
  line-height: ${lineHeightMedium};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MainContainer = styled.div`
  box-shadow: ${shadowLevel1};
  border-radius: ${borderRadiusMd};
  padding: ${spacingInlineXs};
  margin-bottom: ${spacingInlineSm};
  border: 1px solid #ebecf5;

  @media (min-width: 768px) {
    padding: ${spacingInsetXl};
  }
`;

export const AuthOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (min-width: 768px) {
    > .info {
      margin-left: ${spacingInlineMd};
    }
    flex-direction: row;
  }
`;

export const OptionInfo = styled.div`
  @media (min-width: 768px) {
    margin-left: ${spacingInlineMd};
  }
  .option-description {
    font-size: ${fontSizeXxs};
    line-height: ${lineHeightComfy};
    color: ${colorInterfaceDarkDown};
  }
`;

export const OptionTitle = styled.h3`
  font-weight: ${fontWeightExtrabold};
  font-size: ${fontSizeXs};
  line-height: ${lineHeightMedium};
  color: ${colorInterfaceDarkDeep};
  @media (max-width: 767px) {
    margin-top: ${spacingInlineSm};
    text-align: center;
  }
  margin-bottom: ${spacingInlineXxs};
`;

export const OptionDescription = styled.p`
  font-size: ${fontSizeXxs};
  line-height: ${lineHeightComfy};
  color: ${colorInterfaceDarkDown};
  margin-bottom: ${spacingInlineSm};
  @media (max-width: 767px) {
    text-align: center;
  }
`;
export const Form = styled.form`
  display: flex;
  @media (max-width: 767px) {
    justify-content: center;
  }
`;

export const SeparatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: ${spacingInlineMd} 0;
  @media (max-width: 768px) {
    margin: ${spacingInlineSm} 0;
  }
  font-size: ${fontSizeXs};

  hr {
    width: 90%;
    border-bottom: 1px solid #e5e5e5;
  }

  span {
    color: ${colorInterfaceDarkDown};
    padding: 8px;
  }
`;

export const LoadingText = styled.h3`
  font-weight: ${fontWeightExtrabold};
  font-size: ${fontSizeSm};
  line-height: ${lineHeightMedium};
  text-align: center;
  color: ${colorBrandPrimaryPure};
  margin-top: ${spacingInlineMd};
  margin-bottom: 0;
`;

export const BTokenProblem = styled.p`
  font-size: ${fontSizeXxxs};
  color: ${colorInterfaceDarkDown};
`;
