import {
  getBatchPaymentsLastConfirmed,
  getBatchPayments
} from 'api/batch-payments';
import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/batchPayments';

export function* batchPayment(params) {
  try {
    let response;

    if (params.batchUuid && params.clientUuid) {
      response = yield call(
        getBatchPayments,
        params.batchUuid,
        params.clientUuid
      );
    } else {
      response = yield call(getBatchPaymentsLastConfirmed);
    }

    const { errors, data } = response;

    if (errors && errors.length > 0) {
      console.log(errors);
      throw new Error();
    }

    yield put(ActionCreators.batchPaymentsSuccess(data));
  } catch (error) {
    yield put(ActionCreators.batchPaymentsFailure(error));
  }
}
