import React from 'react';
import { Input } from '@useblu/ocean-react';
import InputMask from 'react-input-mask';

import StyledTextField from './styles';

const MaskField = ({ field, form, ...props }) => {
  return (
    <StyledTextField>
      <InputMask
        {...field}
        {...props}
        maskChar=""
        placeholder={props?.placeholder}
        mask={props?.mask}
      >
        {(inputProps) => (
          <Input
            {...inputProps}
            value={field?.value}
            onChange={field?.handleChange}
          />
        )}
      </InputMask>
    </StyledTextField>
  );
};

export default MaskField;
