import React from 'react';
import { Grid, Typography } from '@useblu/ocean-react';
import { X } from '@useblu/ocean-icons-react';
import { useMedia } from 'react-use';

import ilustraImg from './images/ilustra.svg';
import {
  Content,
  CloseButton,
  GridContainer,
  ImageContainer,
  InfoContainer
} from './styles.js';
import './style.css';

const BTokenActive = () => {
  const isMobile = useMedia('(max-width: 768px)');
  const onClose = () => {
    window.location.href = '/';
  };

  return (
    <GridContainer>
      <Grid.Row>
        <Grid.Col
          xl={{
            span: 6,
            offset: 3
          }}
          sm={{
            span: 8,
            offset: 2
          }}
          xs="12"
        >
          {isMobile && (
            <CloseButton type="button" onClick={onClose}>
              <X />
            </CloseButton>
          )}
          <Content>
            <ImageContainer>
              <img src={ilustraImg} alt="ilustra" />
            </ImageContainer>
            <InfoContainer>
              <Typography variant="heading2">
                O seu bToken foi ativado!
              </Typography>
              <Typography variant="paragraph">
                Agora você pode realizar suas transações
                <br /> com mais segurança e praticidade.
              </Typography>
            </InfoContainer>
          </Content>
        </Grid.Col>
      </Grid.Row>
    </GridContainer>
  );
};

export default BTokenActive;
