const convertToTaxFormat = (tax) => {
  if (tax <= 0) {
    return '-';
  }

  return `${tax.replace('.', ',')}%`;
};

const tableLAG = {
  debitVisa: '0,99%',
  debitMaster: '0,86%',
  debitAmex: '-',
  debitElo: '1,06%',
  debitHiper: '-',
  credit1xVisa: '2,00%',
  credit1xMaster: '2,00%',
  credit1xAmex: '2,43%',
  credit1xElo: '2,16%',
  credit1xHiper: '1,82%',
  credit2x6Visa: '2,22%',
  credit2x6Master: '2,26%',
  credit2x6Amex: '2,61%',
  credit2x6Elo: '2,95%',
  credit2x6Hiper: '2,65%',
  credit7x12Visa: '2,64%',
  credit7x12Master: '2,34%',
  credit7x12Amex: '2,76%',
  credit7x12Elo: '3,19%',
  credit7x12Hiper: '2,95%'
};

const tableMattress = {
  debitVisa: '1,00%',
  debitMaster: '1,00%',
  debitAmex: '-',
  debitElo: '1,00%',
  debitHiper: '-',
  credit1xVisa: '2,10%',
  credit1xMaster: '2,05%',
  credit1xAmex: '2,41%',
  credit1xElo: '2,15%',
  credit1xHiper: '2,05%',
  credit2x6Visa: '2,35%',
  credit2x6Master: '2,30%',
  credit2x6Amex: '2,59%',
  credit2x6Elo: '2,40%',
  credit2x6Hiper: '2,30%',
  credit7x12Visa: '2,45%',
  credit7x12Master: '2,30%',
  credit7x12Amex: '2,74%',
  credit7x12Elo: '2,60%',
  credit7x12Hiper: '2,30%'
};

const tableClothes = {
  debitVisa: '1,00%',
  debitMaster: '1,00%',
  debitAmex: '-',
  debitElo: '1,00%',
  debitHiper: '-',
  credit1xVisa: '1,90%',
  credit1xMaster: '1,90%',
  credit1xAmex: '2,38%',
  credit1xElo: '1,90%',
  credit1xHiper: '1,90%',
  credit2x6Visa: '2,23%',
  credit2x6Master: '2,23%',
  credit2x6Amex: '2,57%',
  credit2x6Elo: '2,23%',
  credit2x6Hiper: '2,23%',
  credit7x12Visa: '2,65%',
  credit7x12Master: '2,65%',
  credit7x12Amex: '2,72%',
  credit7x12Elo: '2,65%',
  credit7x12Hiper: '2,65%'
};

const tableFurniture = {
  debitVisa: '1,00%',
  debitMaster: '1,00%',
  debitAmex: '-',
  debitElo: '1,00%',
  debitHiper: '-',
  credit1xVisa: '2,10%',
  credit1xMaster: '2,05%',
  credit1xAmex: '2,41%',
  credit1xElo: '2,15%',
  credit1xHiper: '2,05%',
  credit2x6Visa: '2,35%',
  credit2x6Master: '2,30%',
  credit2x6Amex: '2,59%',
  credit2x6Elo: '2,40%',
  credit2x6Hiper: '2,30%',
  credit7x12Visa: '2,45%',
  credit7x12Master: '2,30%',
  credit7x12Amex: '2,74%',
  credit7x12Elo: '2,60%',
  credit7x12Hiper: '2,30%'
};

const tableGlasses = {
  debitVisa: '1,05%',
  debitMaster: '1,05%',
  debitAmex: '-',
  debitElo: '1,05%',
  debitHiper: '-',
  credit1xVisa: '1,99%',
  credit1xMaster: '1,99%',
  credit1xAmex: '2,54%',
  credit1xElo: '1,99%',
  credit1xHiper: '1,99%',
  credit2x6Visa: '2,20%',
  credit2x6Master: '2,20%',
  credit2x6Amex: '2,73%',
  credit2x6Elo: '2,20%',
  credit2x6Hiper: '2,20%',
  credit7x12Visa: '2,30%',
  credit7x12Master: '2,30%',
  credit7x12Amex: '2,88%',
  credit7x12Elo: '2,30%',
  credit7x12Hiper: '2,30%'
};

const tableEBA = (clientTaxPlan) => ({
  debitVisa: convertToTaxFormat(clientTaxPlan.debit_rate),
  debitMaster: convertToTaxFormat(clientTaxPlan.debit_rate),
  debitAmex: convertToTaxFormat(clientTaxPlan.debit_rate),
  debitElo: convertToTaxFormat(clientTaxPlan.debit_rate),
  debitHiper: convertToTaxFormat(clientTaxPlan.debit_rate),
  credit1xVisa: convertToTaxFormat(clientTaxPlan.credit_rate_1),
  credit1xMaster: convertToTaxFormat(clientTaxPlan.credit_rate_1),
  credit1xAmex: convertToTaxFormat(clientTaxPlan.credit_rate_1),
  credit1xElo: convertToTaxFormat(clientTaxPlan.credit_rate_1),
  credit1xHiper: convertToTaxFormat(clientTaxPlan.credit_rate_1),
  credit2x6Visa: convertToTaxFormat(clientTaxPlan.credit_rate_2),
  credit2x6Master: convertToTaxFormat(clientTaxPlan.credit_rate_2),
  credit2x6Amex: convertToTaxFormat(clientTaxPlan.credit_rate_2),
  credit2x6Elo: convertToTaxFormat(clientTaxPlan.credit_rate_2),
  credit2x6Hiper: convertToTaxFormat(clientTaxPlan.credit_rate_2),
  credit7x12Visa: convertToTaxFormat(clientTaxPlan.credit_rate_7),
  credit7x12Master: convertToTaxFormat(clientTaxPlan.credit_rate_7),
  credit7x12Amex: convertToTaxFormat(clientTaxPlan.credit_rate_7),
  credit7x12Elo: convertToTaxFormat(clientTaxPlan.credit_rate_7),
  credit7x12Hiper: convertToTaxFormat(clientTaxPlan.credit_rate_7)
});

const tableDefault = {
  debitVisa: '0,99%',
  debitMaster: '0,86%',
  debitAmex: '-',
  debitElo: '1,45%',
  debitHiper: '-',
  credit1xVisa: '2,06%',
  credit1xMaster: '2,00%',
  credit1xAmex: '2,83%',
  credit1xElo: '2,83%',
  credit1xHiper: '2,70%',
  credit2x6Visa: '2,28%',
  credit2x6Master: '2,26%',
  credit2x6Amex: '3,21%',
  credit2x6Elo: '3,21%',
  credit2x6Hiper: '3,08%',
  credit7x12Visa: '2,70%',
  credit7x12Master: '2,41%',
  credit7x12Amex: '3,54%',
  credit7x12Elo: '3,54%',
  credit7x12Hiper: '3,41%'
};

export {
  tableLAG,
  tableMattress,
  tableClothes,
  tableFurniture,
  tableGlasses,
  tableEBA,
  tableDefault,
  convertToTaxFormat
};
