import {
  getContractData,
  updateContractData,
  contractParams,
  handleContractErrorStep,
  createContract,
  trackingContract,
  updateUserDataProfile,
  clientUserDataAddress
} from 'api/contract-form';
import _ from 'lodash';
import history from 'utils/browserHistory';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function paramsUrl(id, pageName) {
  return history.push({
    pathname: `/lojas/${id}/contratos`,
    search: `?advanced=${pageName}`
  });
}

function checkTrackingJob(token, counter = 0) {
  return trackingContract(token).then(async (res) => {
    const { status, message } = res.data.data.trackingJob;

    if (status === 'completed') {
      const { contract_lote_key: contractLoteKey } = JSON.parse(message);
      return contractLoteKey;
    }
    if (status === 'error' || counter >= 20) {
      // TODO display friendly error message for client
      return '';
    }
    await sleep(1000);
    return checkTrackingJob(token, counter + 1);
  });
}

export function loadFormData(userStep) {
  return (dispatch, getState) => {
    const {
      railsContext: { clientId }
    } = getState();
    if (!userStep) dispatch({ type: 'DATA_LOAD' });

    return getContractData(clientId).then((response) => {
      const address = _.isEmpty(response.data.address)
        ? {}
        : response.data.address;
      dispatch({
        type: 'DATA_LOADED',
        user: response.data.user,
        client: response.data.client,
        registration_step: response.data.registration_step,
        address
      });

      if (!userStep) dispatch({ type: 'USER_STEP' });
    });
  };
}

export function generateContract() {
  return (dispatch, getState) => {
    const {
      railsContext: { clientId }
    } = getState();

    return getContractData(clientId).then((response) => {
      if (
        response.data.client.uuid &&
        !response.data.client.contract_lote_key
      ) {
        return createContract(response.data.client.uuid).then((res) =>
          checkTrackingJob(res.data.data.createContractClient.token).then(
            (contractLoteKey) => {
              if (!contractLoteKey) return;
              dispatch({
                type: 'UPDATE_CONTRACT',
                token: contractLoteKey
              });
            }
          )
        );
      }

      return {};
    });
  };
}

export function submitFormData(user, client, address) {
  return (dispatch, getState) => {
    const {
      railsContext: { clientId }
    } = getState();
    dispatch({ type: 'DATA_SUBMIT' });

    return updateContractData(clientId, contractParams(user, client, address))
      .then((response) => {
        if (!_.isEmpty(response.data.errors)) {
          const actionType = handleContractErrorStep(response.data.errors);
          dispatch({
            type: 'DATA_SUBMITED_ERROR',
            errors: response.data.errors
          });
          return dispatch({ type: actionType });
        }

        paramsUrl(clientId, 'finalizado');

        dispatch({ type: 'CONTRACT_FINISH' });

        return {};
      })
      .catch(() => false);
  };
}

export function showUserStep() {
  return (dispatch) => {
    dispatch({ type: 'USER_STEP' });
  };
}

export function showClientStep() {
  return (dispatch) => {
    dispatch({ type: 'CLIENT_STEP' });
  };
}

export function showContractStep() {
  return (dispatch) => {
    dispatch({ type: 'CONTRACT_STEP' });
  };
}

export function showFinishStep() {
  return (dispatch) => {
    dispatch({ type: 'FINISH_STEP' });
  };
}

export function userCadastralUpdate(user) {
  return (dispatch, getState) => {
    const {
      railsContext: { clientId }
    } = getState();

    return updateUserDataProfile(clientId, user)
      .then((response) => {
        if (response.data.contract_lote_key) {
          dispatch({
            type: 'UPDATE_CONTRACT',
            token: response.data.contract_lote_key
          });
          paramsUrl(clientId, 'endereco');
          return { success: response.data.success };
        }

        if (!response.data.success) {
          return { errors: response.data.errors };
        }

        paramsUrl(clientId, 'endereco');
        return { success: response.data.success };
      })
      .catch((error) => error);
  };
}

export function clientAddressUpdate(client, steps) {
  return (dispatch, getState) => {
    const {
      railsContext: { clientId }
    } = getState();

    return clientUserDataAddress(client, steps)
      .then((response) => {
        paramsUrl(clientId, 'assinatura');
        return { success: response.data.success };
      })
      .catch(() => ({ success: false }));
  };
}
