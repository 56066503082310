import styled from 'styled-components';
import Select from 'react-select';
import {
  colorBrandPrimaryDown,
  colorBrandPrimaryUp,
  colorInterfaceDarkDown,
  colorInterfaceLightDeep,
  colorStatusNegativePure,
  fontSizeXs,
  fontSizeXxxs,
  fontWeightRegular
} from '@useblu/ocean-tokens/web/tokens';

export const WrapCustomSelect = styled.div`
  width: 100%;

  & > label {
    color: ${colorInterfaceDarkDown};
    font-weight: ${fontWeightRegular};
  }
`;

export const CustomSelect = styled(Select)`
  &&& {
    width: 100%;
    height: 48px;
    position: relative;
    top: -1px;

    > div {
      min-height: 46px;
    }

    &.error > .custom-select__control {
      border-color: ${colorStatusNegativePure};
    }

    .custom-select__indicator-separator {
      display: none;
    }

    .custom-select__placeholder {
      color: ${colorInterfaceLightDeep};
      font-size: ${fontSizeXs};
    }

    .custom-select__control {
      border: 1px solid ${colorInterfaceLightDeep};
      ${(props) => props.filled && `border: 1px solid ${colorBrandPrimaryUp};`}
      min-height: 48px;
      cursor: pointer;

      &:hover,
      &:focus {
        border: 2px solid ${colorBrandPrimaryDown};
      }
    }

    .custom-select__value-container {
      padding: 0 15px;
    }
  }
`;

export const Hint = styled.div`
  color: ${(props) =>
    props.error ? colorStatusNegativePure : colorInterfaceDarkDown};

  font-size: ${fontSizeXxxs};
`;
