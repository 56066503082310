import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types, Creators } = createActions({
  tooglePrivateMode: null
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  privateMode: false
};

/* Reducers */

export const tooglePrivateMode = (state) => ({
  ...state,
  privateMode: !state.privateMode
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOOGLE_PRIVATE_MODE]: tooglePrivateMode
});
