require('utils/snippets/clicksign');

global.AddressForm = require('components/Address').default;
global.SalesForm = require('components/SalesForm').default;
global.ContractForm = require('components/Form/ContractForm').default;
global.UsersForm = require('components/UsersForm').default;
global.DatePickerInput = require('components/Form/DatePickerInput').default;
global.ClientSelector =
  require('components/Application/ClientSelector').default;
global.PosResendReceiptForm =
  require('components/PosResendReceiptForm').default;
global.SignUpApp = require('components/SignUpApp').default;
global.ClicksignContract = require('components/ClicksignContract').default;
