import React, { useEffect } from 'react';
import { useLocalStorage, useMedia } from 'react-use';
import {
  CalendarOutline,
  CashbackOutline,
  CashOutline,
  ClockOutline,
} from '@useblu/ocean-icons-react';
import { Button, Typography } from '@useblu/ocean-react';
import { useSelector } from 'react-redux';
import { railsContextSelector } from 'selectors/rails-context';
import useModalManagement from 'components/Dashboard/hooks/useModalManagement';

import Ilustra from './images/ilustra.svg';

import * as S from './styles';

export default function ModalAutomaticScheduling() {
  const isMobile = useMedia('(max-width: 768px)');
  const { closeModal } = useModalManagement();
  const [, setValue] = useLocalStorage(
    'view-modal-automatic-scheduling',
    false
  );
  const {
    clientId,
    currentClientTaxPlan: {
      bank_billet_cashback_base: bankBilletCashbackBase,
      bank_billet_cashback_bonus: bankBilletCashbackBonus,
    },
  } = useSelector(railsContextSelector);

  const cashbackPercentage =
    parseFloat(bankBilletCashbackBase) + parseFloat(bankBilletCashbackBonus);

  useEffect(() => {
    setValue(true);
  }, [setValue]);

  return (
    <S.Wrapper>
      <img src={Ilustra} alt="Ilustração" />
      <S.Caption>Agendamento Automático</S.Caption>
      <S.Title>Fique em dia com todos os pagamentos do seu fornecedor</S.Title>
      <S.Description>
        Configure o Agendamento Automático para boletos emitidos pelo seu
        fornecedor. <strong>É rápido e fácil.</strong>
      </S.Description>
      <ul>
        <li>
          <S.Icon>{isMobile ? <CalendarOutline /> : <ClockOutline />}</S.Icon>
          <div>
            <Typography variant="heading4">
              Não perca o prazo de seus boletos
            </Typography>
            <Typography variant="description">
              Seus boletos serão agendados automaticamente para a data de
              vencimento.
            </Typography>
          </div>
        </li>
        <li>
          <S.Icon>
            <CashbackOutline />
          </S.Icon>
          <div>
            <Typography variant="heading4">Ganhe mais cashback</Typography>
            <Typography variant="description">
              {isMobile
                ? 'Receba cashback sempre que seus boletos forem liquidados.'
                : `Você recebe ${cashbackPercentage}% de cashback quando o boleto for liquidado.`}
            </Typography>
          </div>
        </li>
        <li>
          <S.Icon>
            <CashOutline />
          </S.Icon>
          <div>
            <Typography variant="heading4">
              Não comprometa seu saldo antes da hora
            </Typography>
            <Typography variant="description">
              {isMobile
                ? 'Não comprometa seu saldo antes da hora, pois o valor do boleto só é retido no dia do pagamento.'
                : 'O valor do boleto só é retido no dia do pagamento.'}
            </Typography>
          </div>
        </li>
      </ul>
      {window.location.href.includes('agendamento-automatico') ? (
        <Button onClick={() => closeModal('AutomaticScheduling')}>
          Ativar Agendamento Automático
        </Button>
      ) : (
        <a
          href={`/lojas/${clientId}/agendamento-automatico/buscar-fornecedores`}
        >
          <Button>Ativar Agendamento Automático</Button>
        </a>
      )}
    </S.Wrapper>
  );
}
