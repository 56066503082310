const shouldNotInitializeFullStory = document.cookie.includes(
  'privacy_policy_not_allowed'
);

if (!shouldNotInitializeFullStory) {
  /* FullStory.init({
    orgId: '2CYC5',
    devMode: railsState.railsContext.env !== 'production',
  }); */
}
