import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@useblu/ocean-react';

import {
  HeaderImgContainerAA,
  Title,
  ModalFooterContainer,
  ScrollMobile,
  ModalContent,
  BtnsWrap,
  Frame,
  Caption,
  CaptionContainer
} from '../modal.styles';

import successImg from '../images/aa-success-ilustra.svg';

const OptInSuccessAutomaticAnticipation = ({ nextStep, nextText }) => (
  <Frame $withoutHeight>
    <ScrollMobile>
      <ModalContent className="automatic-anticipation">
        <HeaderImgContainerAA className="modalSteps__confirm">
          <img src={successImg} alt="icone main modal" className="desktop" />
        </HeaderImgContainerAA>
        <Title className="center black for-success">
          Antecipação automática ativada
        </Title>
        <CaptionContainer>
          <Caption className="center black">
            Agora todas as suas vendas caem na conta em até um dia útil!
          </Caption>
        </CaptionContainer>
        <BtnsWrap className="center desktop">
          <Button
            className="modal_botao_fechar_campanha_multiadquirencia_getnet"
            onClick={() => nextStep()}
          >
            {nextText}
          </Button>
        </BtnsWrap>
      </ModalContent>
    </ScrollMobile>
    <ModalFooterContainer className="no-border">
      <BtnsWrap className="center mobile">
        <Button
          className="modal_botao_fechar_campanha_multiadquirencia_getnet"
          onClick={() => nextStep()}
        >
          {nextText}
        </Button>
      </BtnsWrap>
    </ModalFooterContainer>
  </Frame>
);

OptInSuccessAutomaticAnticipation.propTypes = {
  nextStep: PropTypes.func.isRequired,
  nextText: PropTypes.string
};

OptInSuccessAutomaticAnticipation.defaultProps = {
  nextText: 'Fechar'
};

export default OptInSuccessAutomaticAnticipation;
