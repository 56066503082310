import React, { Component } from 'react';
import PropTypes from 'prop-types';
import maskInput from 'utils/masks';

export default class PosResendReceiptForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phone: '',
      open: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
  }

  componentDidMount() {
    maskInput(this.phoneField, 'phone');
  }

  handleChange(event) {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  toggleForm() {
    const { open } = this.state;

    this.setState({
      open: !open
    });
  }

  render() {
    const { email, phone, open } = this.state;
    const { formAction, authenticityToken, viewReceiptPath } = this.props;

    const formStyle = {
      display: open ? 'block' : 'none'
    };

    return (
      <div className="pos-receipt-form">
        <div className="pos-receipt-form__toggle">
          <button
            type="button"
            className="button button--primary"
            onClick={this.toggleForm}
          >
            Reenviar comprovante
          </button>

          <a
            href={viewReceiptPath}
            className="button button--secondary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visualizar comprovante
          </a>
        </div>

        <form action={formAction} method="POST" style={formStyle}>
          <input
            type="hidden"
            value={authenticityToken}
            name="authenticity_token"
          />

          <div className="form-row">
            <div className="form-controls">
              <label htmlFor="email">Enviar por email</label>
              <input
                id="email"
                type="email"
                name="receipt[email]"
                placeholder="Digite o email aqui"
                value={email}
                onChange={this.handleChange}
              />
            </div>

            <div className="form-controls">
              <label htmlFor="phone">Enviar por SMS</label>
              <input
                id="phone"
                type="text"
                name="receipt[phone]"
                placeholder="Digite o telefone aqui"
                value={phone}
                onChange={this.handleChange}
                ref={(input) => (this.phoneField = input)}
              />
            </div>
          </div>

          <div className="form-row actions">
            <button
              type="submit"
              className="button button--primary"
              disabled={!(!!phone.length || !!email.length)}
            >
              Reenviar
            </button>
          </div>
        </form>
      </div>
    );
  }
}

PosResendReceiptForm.propTypes = {
  formAction: PropTypes.string,
  authenticityToken: PropTypes.string,
  viewReceiptPath: PropTypes.string
};
