import { getClient, getUsers } from 'api/first-access';
import _ from 'lodash';

import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/firstAccess/shared';

export function* loadClient(params) {
  const response = yield call(getClient, params);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(ActionCreators.firstAccessClientSuccess(data.data.client));
  } else {
    yield put(ActionCreators.firstAccessClientFailure());
  }
}

export function* loadUsers(params) {
  try {
    const response = yield call(getUsers, params);
    const { data } = response.data;
    yield put(ActionCreators.firstAccessUsersSuccess(data.users));
  } catch (err) {
    yield put(ActionCreators.firstAccessUsersFailure());
  }
}
