import React from 'react';

import { Provider } from 'react-redux';
import store from 'store';

import UserContract from './UserContract';
import UserInfoConfirmation from './UserInfoConfirmation';
import UserAddressConfirmation from './UserAddressConfirmation';
import Confirmation from './Confirmation';

import * as S from './styles';

const ContractForm = () => (
  <Provider store={store}>
    <S.Wrapper>
      <UserInfoConfirmation />
      <UserAddressConfirmation />
      <UserContract />
      <Confirmation />
    </S.Wrapper>
  </Provider>
);
export default ContractForm;
