import styled from 'styled-components';
import { Typography } from '@useblu/ocean-react';
import {
  colorStatusNegativePure,
  colorInterfaceDarkDown,
  spacingInlineXs,
  spacingInlineSm
} from '@useblu/ocean-tokens/web/tokens';

export const Title = styled(Typography).attrs(() => ({
  variant: 'heading3'
}))`
  &&& {
    color: ${colorStatusNegativePure};
    margin-bottom: ${spacingInlineXs};
  }
`;

export const Paragraph = styled(Typography).attrs(() => ({
  variant: 'paragraph'
}))`
  &&& {
    color: ${colorInterfaceDarkDown};
    margin-bottom: ${spacingInlineSm};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 25rem) {
    flex-direction: column-reverse;
  }

  button:first-child {
    margin-right: ${spacingInlineXs};

    @media (max-width: 25rem) {
      margin-right: 0;
      margin-top: ${spacingInlineXs};
    }
  }
`;
