import React from 'react';
import PropTypes from 'prop-types';
import { formatDate, normalizeCurrency } from 'normalizers';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import { useMedia } from 'react-use';
import {
  THead,
  TFoot,
  HeadLine,
  AdvancementTable,
  ItemLine,
  FooterLine,
  ScrollableContainer
} from './styles';

const AdvanceTransactionsDetailsTable = ({ advancementData }) => {
  const isMobile = useMedia('(max-width: 768px)');
  const { data: clients } = useSelector((state) => state.clientSelector);

  const getClient = (id) => {
    const client = find(clients, { id });

    return client && client.name ? client.name : '';
  };

  const withName = (items) => items[0].clientUuid?.length > 0;

  return (
    <ScrollableContainer>
      <AdvancementTable cellSpacing={0}>
        <THead>
          <HeadLine>
            {withName(advancementData.advancementItems) && !isMobile && (
              <th className="modal-taxes__store">Loja</th>
            )}
            <th>Tipo</th>
            {!isMobile && <th>Adquirente</th>}
            {!isMobile && <th>Bandeira</th>}
            {!isMobile && <th>Data original</th>}
            {!isMobile && <th>Dias antecipados</th>}
            <th>Valor</th>
            <th>Taxa</th>
          </HeadLine>
        </THead>
        <tbody>
          {advancementData.advancementItems.map((item) => {
            const floatAdvancementValue = parseFloat(item.advancementValue);
            const floatAdvancementRateValue = parseFloat(
              item.advancementRateValue
            );
            const advancementTotalValue =
              floatAdvancementValue + floatAdvancementRateValue;

            return (
              <ItemLine key={item.receivableId}>
                {item.clientUuid && !isMobile && (
                  <td className="modal-taxes__store">
                    {getClient(item.clientUuid)}
                  </td>
                )}
                <td>{item.kind}</td>
                {!isMobile && <td>{item.acquirerName}</td>}
                {!isMobile && <td>{item.providerBrandName}</td>}
                {!isMobile && <td>{formatDate(item.originalReleaseAt)}</td>}
                {!isMobile && (
                  <td>{`${item.advancedDays} ${
                    item.advancedDays > 1 ? 'dias' : 'dia'
                  }`}</td>
                )}
                <td className="space-left">
                  {`R$ ${normalizeCurrency(parseFloat(advancementTotalValue))}`}
                </td>
                <td>{`R$ ${normalizeCurrency(
                  parseFloat(floatAdvancementRateValue)
                )}`}</td>
              </ItemLine>
            );
          })}
        </tbody>
        <TFoot>
          <FooterLine>
            <td colSpan={isMobile ? '' : '5'}>Total</td>
            <td className="darker space-left">
              {`R$ ${normalizeCurrency(
                parseFloat(
                  advancementData.advancementValue +
                    Math.abs(advancementData.advancementRateValue)
                )
              )}`}
            </td>
            <td>
              {`R$ ${normalizeCurrency(
                parseFloat(advancementData.advancementRateValue)
              )}`}
            </td>
          </FooterLine>
        </TFoot>
      </AdvancementTable>
    </ScrollableContainer>
  );
};

AdvanceTransactionsDetailsTable.propTypes = {
  advancementData: PropTypes.object
};

AdvanceTransactionsDetailsTable.defaultProps = {
  advancementData: {}
};

export default AdvanceTransactionsDetailsTable;
