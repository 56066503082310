import React from 'react';
import PropTypes from 'prop-types';

const PendingOutflowsLayout = ({ pageTitle, confirmationText, link }) => (
  <div className="container">
    <div className="flex-container is-padded">
      <div className="confirmation-image" />
      <h1 className="page-title">{pageTitle}</h1>
      <p className="confirmation-text">{confirmationText}</p>
      <div className="flex-container confirmation-actions is-padded">
        <a
          className="button button--secondary button--outline"
          href={link.href}
        >
          {link.text}
        </a>
      </div>
    </div>
  </div>
);

PendingOutflowsLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};

export default PendingOutflowsLayout;
