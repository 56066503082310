/* eslint-disable import/prefer-default-export */
export const mockDebitParty = {
  account: '12345678',
  personType: 'NATURAL_PERSON',
  taxId: '0000000000',
  accountType: 'CACC',
  branch: '1',
  bank: '237',
  name: 'EMPRESA TESTE'
};
