import { Typography, Divider } from '@useblu/ocean-react';
import {
  colorInterfaceDarkPure,
  colorInterfaceDarkUp,
  fontSizeXs,
  fontSizeXxxs,
  spacingInlineSm,
  spacingInlineXs,
  spacingStackMd,
  spacingStackXs,
  spacingStackXxs,
  spacingStackSm,
  colorInterfaceLightUp,
  borderRadiusMd,
  fontWeightRegular,
  colorComplementaryDeep,
  spacingStackXxxs,
  colorInterfaceLightDown,
  colorStatusPositiveUp,
  colorInterfaceDarkDown,
  colorStatusPositiveDeep,
  spacingStackXxl,
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

export const DrawerContent = styled.div`
  overflow-y: auto;
`;

export const DrawerTitle = styled(Typography)`
  &.ods-typography {
    margin: 0 ${spacingInlineSm} ${spacingStackMd};
  }
`;

export const DrawerValues = styled.div`
  margin: 0 ${spacingInlineSm} ${spacingStackMd};
`;

export const DrawerValueItem = styled.div`
  &.with-button {
    display: flex;

    > div {
      flex: 1;

      &:last-of-type {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > button {
          padding: ${spacingStackXxs} ${spacingInlineXs};
        }
      }
    }
  }

  span {
    color: ${colorInterfaceDarkPure};
    font-size: ${fontSizeXs};
  }
`;

export const DrawerValuesDivider = styled(Divider)`
  &.ods-divider {
    margin-top: ${spacingStackXs};
    margin-bottom: ${spacingStackXs};
  }
`;

export const Subtitle = styled(Typography)`
  &.ods-typography {
    color: ${colorInterfaceDarkUp};
    margin: 0 ${spacingInlineSm} ${spacingStackXs};
  }
`;

export const CalcExplanation = styled.div`
  margin: 0 ${spacingInlineSm} ${spacingStackSm};
  padding: ${spacingInlineXs};
  background-color: ${colorInterfaceLightUp};
  border-radius: ${borderRadiusMd};
  display: flex;
  flex-direction: column;
  gap: ${spacingStackXs};
`;

export const Value = styled.span`
  color: ${colorInterfaceDarkPure};
  font-size: ${fontSizeXs};
  font-weight: ${fontWeightRegular};
`;

export const ExplanationTitleCaption = styled(Typography)`
  &.ods-typography {
    color: ${colorComplementaryDeep};
    margin-bottom: ${spacingStackXxxs};
  }
`;

export const PaymentItem = styled.a`
  display: flex;
  border: 1px solid ${colorInterfaceLightDown};
  border-radius: ${borderRadiusMd};
  margin: 0 ${spacingInlineSm};
  padding: ${spacingStackXs} ${spacingInlineXs};
  margin-bottom: ${spacingStackSm};

  &:hover {
    background-color: ${colorInterfaceLightUp};
  }

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    flex: 1;

    svg:not(:nth-child(3)) {
      margin-bottom: ${spacingStackXxs};
    }

    .ods-typography__paragraph {
      color: ${colorInterfaceDarkPure};
    }

    .ods-tag--neutral-03 {
      margin-top: ${spacingStackXxs};
    }
  }

  & > div:last-of-type {
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      color: ${colorInterfaceDarkUp};
    }
  }
`;

export const PagBluDescription = styled.div`
  margin-top: ${spacingStackXs};
  padding: ${spacingStackXs};
  background-color: ${colorStatusPositiveUp};
  border-radius: ${borderRadiusMd};
  color: ${colorInterfaceDarkDown};
  font-size: ${fontSizeXxxs};
`;

export const SkeletonSpan = styled.span`
  &.pag-blu {
    color: ${colorStatusPositiveDeep};
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  padding: ${spacingStackXxl} ${spacingStackSm} ${spacingStackSm};
  text-align: center;
  align-items: center;
  flex-direction: column;

  & > img {
    margin-bottom: ${spacingStackSm};
  }

  & > .ods-typography__heading4 {
    margin-bottom: ${spacingStackXxs};
  }

  & > .transaction {
    margin-bottom: ${spacingStackSm};
  }

  & > button {
    margin: ${spacingInlineXs} 0;
  }
`;

export const AnticipationGEContainer = styled.div`
  border-radius: ${borderRadiusMd};
  margin: 0 ${spacingInlineSm} ${spacingStackMd};
  background: ${colorInterfaceLightUp};
  display: flex;
  flex-direction: column;
  padding: ${spacingStackSm};
  align-items: center;
  gap: ${spacingStackXs};
  text-align: center;
`;
