import styled, { keyframes } from 'styled-components';
import { Devices } from 'utils/getDeviceMedia';

import {
  colorInterfaceLightDeep,
  colorInterfaceDarkDeep,
  colorInterfaceLightUp,
  colorBrandPrimaryPure,
  colorBrandPrimaryUp,
  colorBrandPrimaryDown
} from '@useblu/ocean-tokens/web/tokens';

const chatAnimation = keyframes`
  from {opacity: 0;}
  to {opacity: 1; }
`;

export const ChatScroll = styled.div`
  height: 405px;
  overflow: hidden;
  display: flex;
  align-items: center;

  @media ${Devices.tablet}, ${Devices.mobile} {
    height: 100%;
  }
`;

export const FakeChatContainer = styled.div`
  width: 405px;
  display: flex;
  justify-content: left;
  align-items: center;

  @media ${Devices.tablet}, ${Devices.mobile} {
    width: 100%;
  }
`;

export const Chat = styled.div`
  display: inline-block;

  div {
    color: ${colorInterfaceLightDeep};
    background-color: ${colorInterfaceLightUp};
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 16px 16px 16px 0px;
    transition: color 1.4s ease;
    animation-name: ${chatAnimation};
    animation-fill-mode: forwards;
    animation-duration: 1.4s;
  }

  div:last-child {
    color: ${colorInterfaceDarkDeep};
  }
`;

const dotsAnimation = keyframes`
  0% {
    transform: translateY(0px);
    background-color: ${colorBrandPrimaryDown}; 
  }
  28% {
    background-color: ${colorBrandPrimaryUp}; 
  }
  44% {
    transform: translateY(0px);
    background-color: ${colorBrandPrimaryDown}; 
  }
`;

export const ChatBubble = styled.div`
  background: #f7f9ff;
  padding: 8px;
  width: 42px;
  height: 21px;
  display: inline-block;
  border-radius: 12px;
`;

export const ChatTyping = styled.div`
  align-items: center;
  display: flex;

  .dot {
    animation: ${dotsAnimation} 1.8s infinite ease-in-out;
    background: ${colorBrandPrimaryPure};
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-right: 2px;
    vertical-align: middle;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .dot:nth-child(1) {
    animation-delay: 200ms;
  }
  .dot:nth-child(2) {
    animation-delay: 300ms;
  }
  .dot:nth-child(3) {
    animation-delay: 400ms;
  }
  .dot:nth-child(4) {
    animation-delay: 500ms;
  }
  .dot:last-child {
    margin-right: 0;
  }
`;
