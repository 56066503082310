import React from 'react';
import { useSelector } from 'react-redux';
import Options from '../../shared/Options';
import DropdownMenu from '../../shared/DropdownMenu';

import { Circle } from '../../shared/Button';

const FaqMenu = () => {
  const clientId = useSelector(
    (state) =>
      state.railsContext?.clientId || state.railsContext?.clientClientId
  );

  const faqOptionsItems = [
    {
      groupName: 'Vantagens blu',
      items: [
        // {
        //   id: 0,
        //   label: 'Vem aí o Registro de Recebíveis Blu',
        //   className: 'fullstory-faq_button',
        //   link: `/lojas/${clientId}/economize-mais-use-recebiveis`,
        //   target: '_self',
        //   isNew: true
        // }
      ]
    }
  ];
  return (
    <DropdownMenu
      title="FAQ"
      displayClass="faq-menu"
      width="240px"
      lockMouseEvents={true}
    >
      <Circle>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 22C24.4045 22 24.7691 21.7564 24.9239 21.3827C25.0787 21.009 24.9931 20.5789 24.7071 20.2929L23.3022 18.888C23.2064 18.7921 23.1304 18.6783 23.0785 18.5531C23.0267 18.4279 23 18.2937 23 18.1582V18.158V15.0001C23 15.0001 23 15 23 15C23.0002 13.5522 22.5515 12.14 21.7158 10.9577C21.0154 9.96673 20.0719 9.17819 18.9812 8.66455C18.9056 7.99234 18.6043 7.36165 18.1213 6.87868C17.5587 6.31607 16.7956 6 16 6C15.2043 6 14.4413 6.31607 13.8787 6.87868C13.3957 7.3617 13.0944 7.99246 13.0188 8.66475C10.6451 9.78419 9 12.1993 9 15V18.159C9 18.4318 8.89137 18.6944 8.69789 18.8879L9.405 12.595L8.69789 18.8879L7.29289 20.2929C7.0069 20.5789 6.92134 21.009 7.07612 21.3827C7.2309 21.7564 7.59554 22 8 22H12C12 23.0609 12.4214 24.0783 13.1716 24.8284C13.9217 25.5786 14.9391 26 16 26C17.0609 26 18.0783 25.5786 18.8284 24.8284C19.5786 24.0783 20 23.0609 20 22H24Z"
            stroke="#393B47"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            x="13"
            y="21.5"
            width="6"
            height="1"
            strokeWidth="1"
            stroke="#393B47"
          />
        </svg>
      </Circle>
      <Options showGroupName={false} groups={faqOptionsItems} />
    </DropdownMenu>
  );
};

export default FaqMenu;
