import React from 'react';
import PropTypes from 'prop-types';

import CnpjSelection from './CnpjSelection';

const CnpjSelectionCombo = ({
  nextStep,
  header,
  prevStep,
  loading,
  isChecked
}) => (
  <CnpjSelection
    header={header}
    nextClass="4_campanha_aa_combo"
    prevClass="3_campanha_aa_combo"
    title="Quanto mais lojas mais benefícios!"
    description={
      <>
        Ao ativar a{' '}
        <strong>
          Antecipação Automática Blu + Centralização de Recebíveis Blu
        </strong>{' '}
        você terá muito mais vantagens na hora de pagar os seus fornecedores via
        Blu.
      </>
    }
    prevStep={prevStep}
    hasPrev
    nextStep={nextStep}
    loading={loading}
    isChecked={isChecked}
  />
);

CnpjSelectionCombo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func,
  header: PropTypes.string,
  isChecked: PropTypes.bool,
  loading: PropTypes.bool
};

CnpjSelectionCombo.defaultProps = {
  loading: false,
  isChecked: true,
  header: 'Seleção de loja',
  prevStep: () => false
};

export default CnpjSelectionCombo;
