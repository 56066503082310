import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@useblu/ocean-react';
import { normalizeMoney } from '@useblu/utils';
import { normalizeSeparator } from 'normalizers';
import ItemSkeleton from './ItemSkeleton';
import * as S from '../styles';

const UnderstandCalculation = ({ detailsAnticipationData }) => {
  return (
    <>
      <S.Subtitle variant="heading5">Entenda o cálculo</S.Subtitle>
      <S.CalcExplanation>
        <Typography variant="description">
          Seu custo total de antecipação é igual ao valor antecipado
          multiplicado pela sua taxa efetiva, nesse caso,{' '}
          {normalizeSeparator(
            detailsAnticipationData?.value?.anticipationRateTotal,
            false
          )}
          %
        </Typography>
        <ItemSkeleton
          loading={detailsAnticipationData.loading}
          value={
            <S.Value>
              {`${normalizeMoney(
                detailsAnticipationData?.value?.total
              )} x ${normalizeSeparator(
                detailsAnticipationData?.value?.anticipationRateTotal
              )}% = ${normalizeMoney(detailsAnticipationData?.value?.cost)}`}
            </S.Value>
          }
        />
        <Divider />
        <Typography variant="description">
          Como é calculada a sua taxa efetiva:
        </Typography>
        <div>
          <S.ExplanationTitleCaption variant="caption">
            Usando a sua taxa de antecipação ao mês
          </S.ExplanationTitleCaption>
          <ItemSkeleton
            loading={detailsAnticipationData.loading}
            value={
              <S.Value>
                {normalizeSeparator(
                  detailsAnticipationData?.value?.anticipationRate
                )}
                %
              </S.Value>
            }
          />
          <Typography variant="caption">
            Sua taxa de Antecipação Pontual, calculada de acordo com os seus
            pontos do Blu Mais
          </Typography>
        </div>
        <div>
          <S.ExplanationTitleCaption variant="caption">
            E multiplicando pelo seu prazo médio em meses
          </S.ExplanationTitleCaption>
          <ItemSkeleton
            loading={detailsAnticipationData.loading}
            value={
              <S.Value>
                {normalizeSeparator(
                  detailsAnticipationData?.value?.averageTermInMonths,
                  false
                )}
              </S.Value>
            }
          />
          <Typography variant="caption">
            Prazo médio das suas vendas (equivalente a{' '}
            {normalizeSeparator(
              detailsAnticipationData?.value?.averageTermInDays,
              false
            ) || 0}{' '}
            dias)
          </Typography>
        </div>
        <div>
          <S.ExplanationTitleCaption variant="caption">
            O que resulta em
          </S.ExplanationTitleCaption>
          <ItemSkeleton
            loading={detailsAnticipationData.loading}
            value={
              <S.Value>
                {normalizeSeparator(
                  detailsAnticipationData?.value?.anticipationRateTotal,
                  false
                )}
                %
              </S.Value>
            }
          />
          <Typography variant="caption">
            Sua taxa efetiva de antecipação
          </Typography>
        </div>
      </S.CalcExplanation>
    </>
  );
};

UnderstandCalculation.propTypes = {
  detailsAnticipationData: PropTypes.object,
};

UnderstandCalculation.defaultProps = {
  detailsAnticipationData: null,
};

export default UnderstandCalculation;
