import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Breadcrumb = ({ items, className }) => {
  if (items && items.length) {
    const itemsHtml = items.reduce((arr, item, idx) => {
      const description = item[0];
      const link = item[1];

      const className = classNames({
        first: idx === 0,
        last: idx === items.length - 1,
        [`item-${idx}`]: `item-${idx}`
      });

      if (link) {
        arr.push(
          <a key={idx} className={className} href={link}>
            {description}
          </a>
        );
      } else {
        arr.push(
          <span key={idx} className={className}>
            {description}
          </span>
        );
      }

      if (idx !== items.length - 1) {
        arr.push(
          <span key={`separator${idx}`} className="separator">
            &gt;
          </span>
        );
      }

      return arr;
    }, []);

    return (
      <div className={`breadcrumbs ${className}`}>
        <div className="breadcrumbs-container">{itemsHtml}</div>
      </div>
    );
  }

  return null;
};

Breadcrumb.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string
};

Breadcrumb.defaultProps = {
  items: [],
  className: ''
};

export default Breadcrumb;
