import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
  fetchRpaRegisterDataRequest: null,
  fetchRpaRegisterDataSuccess: ['data'],
  fetchRpaRegisterDataFailure: ['error'],
  createRpaRegisterFormRequest: ['data', 'callback'],
  createRpaRegisterFormSuccess: ['success'],
  createRpaRegisterFormFailure: ['error']
});

export { types };

export default actions;
