import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  toogleDashboardLocation: ['data']
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  currentLocation: null
};

export const toogleLocation = (state, action) => ({
  ...state,
  currentLocation: action.data
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TOOGLE_DASHBOARD_LOCATION]: toogleLocation
});
