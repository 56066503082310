/* Core */
import React from 'react';
import PropTypes from 'prop-types';

const ReadOnlyField = (props) => (
  <div className="readonly-field">
    <div className="readonly-field__label">{props.label}</div>
    <div className="readonly-field__value">
      {props.path ? <a href={props.path}>{props.value}</a> : props.value}
      {props.children}
    </div>
  </div>
);

ReadOnlyField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

export default ReadOnlyField;
