import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import STATES from 'utils/states.json';

export const invalidCepMsg = 'O CEP informado não é válido.';

export default function useCep(value) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validateZipCode = useMemo(() => /^[0-9]{8}$/, []);

  useEffect(() => {
    async function fetchData() {
      const cep = value.replace(/\D/g, '');

      if (validateZipCode.test(cep)) {
        setLoading(true);

        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        if (!data?.erro) {
          setData({
            street: data?.logradouro,
            neighborhood: data?.bairro,
            complement: data?.complemento,
            city: data?.localidade,
            state: STATES.find((item) => data?.uf === item.uf)?.name,
            zipCode: data?.cep
          });
          setError(null);
        } else {
          setError({ message: 'O CEP informado não é válido.' });
          setData(null);
        }

        setLoading(false);
      }
    }
    fetchData();
  }, [value, validateZipCode]);

  return { data, loading, error };
}
