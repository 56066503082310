import {
  colorInterfaceDarkDown,
  colorStatusNegativePure,
  shadowLevel1
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

import InputControl from 'components/Shared/InputControl';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  desktop: `(min-width: 1366px)`
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const Bar = styled.div`
  box-shadow: ${shadowLevel1};
  padding: 0.5rem;
  margin-bottom: 2rem;
  width: 100%;

  @media ${devices.notebook} {
    margin-bottom: 5rem;
  }

  .ods-topbar .ods-topbar-title span {
    display: none;
  }
`;

export const Wrapper = styled.div`
  max-width: 328px;

  @media ${devices.notebook} {
    max-width: 554px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 16px;

  .ods-input--disabled,
  .ods-input--disabled:hover {
    border-color: transparent;
  }

  @media ${devices.notebook} {
    margin-bottom: 24px;
  }
`;

export const Paragraph = styled.p`
  font-family: ${colorInterfaceDarkDown};
  font-size: 16px;
  font-weight: 800;

  margin-bottom: 16px;
`;

export const AddressBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;

  .rowInput {
    width: 152px;
  }

  .city {
    width: 207px;
  }

  .state {
    width: 97px;

    & .ods-select__root {
      min-width: auto;
    }
  }

  @media ${devices.notebook} {
    .rowInput {
      width: 269px;
    }

    .city {
      width: 269px;
    }

    .state {
      width: 269px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const CustomInput = styled(InputControl)`
  &&& {
    min-width: 434px;

    @media ${devices.tablet} {
      min-width: initial;
      width: 100%;
    }

    .token & {
      min-width: initial;
      width: 48px;
      height: 48px;
    }

    &.error {
      border-color: ${colorStatusNegativePure};
    }
  }
`;

export const ErrorText = styled.p`
  color: red;
`;
