import React from 'react';

// this was changed momentarily to a html event based modal to make avaliable the migration
// for the dependencies to the blu front code base, after that, this shold be changed
// back to a redux or context api based modal.

export default function useModalManagement() {
  const OPENEVENTTRIGGER = 'feature-modal-open';
  const CLOSEEVENTTRIGGER = 'feature-modal-close';

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [feature, setFeature] = React.useState(undefined);

  function openModal(subject) {
    const event = new CustomEvent(OPENEVENTTRIGGER, {
      detail: { feature: subject }
    });
    document.dispatchEvent(event);
  }

  function closeModal(subject) {
    const event = new CustomEvent(CLOSEEVENTTRIGGER, {
      detail: { feature: subject }
    });
    document.dispatchEvent(event);
  }

  function closeAction(e) {
    setModalOpen(false);
    setFeature(e.detail.feature);
  }

  function openAction(e) {
    setModalOpen(true);
    setFeature(e.detail.feature);
  }

  // add listeners on mount
  React.useEffect(() => {
    document.addEventListener(OPENEVENTTRIGGER, openAction);
    document.addEventListener(CLOSEEVENTTRIGGER, closeAction);
  }, []);

  // remove listeners on unmount
  React.useEffect(
    () => () => {
      document.removeEventListener(OPENEVENTTRIGGER, openAction);
      document.removeEventListener(CLOSEEVENTTRIGGER, closeAction);
    },
    []
  );

  return {
    isModalOpen,
    feature,
    openModal,
    closeModal
  };
}
