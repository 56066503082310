import axios from 'axios';

// eslint-disable-next-line import/prefer-default-export
export function getBigBoostData(params) {
  const query = `
      query {
        bigBoostData(cpfCnpj: "${params.cpfCnpj}") {
          basicData {
            name
            tradeName
            capitalStock
            status
            activities {
              main
              cnae
              description
            }
          }
          qsa {
            name
            cpfCnpj
            position
            relationshipType
            documentType
          }
        }
      }
    `;

  return axios.post('/graphql', { query });
}

export function getUserBigBoostData(cpf) {
  const query = `
      query {
        bigBoostIndividualBasicData(cpf: "${cpf}") {
          motherName
          birthCountry
          extendedDocumentInformation {
            documentType
            documentLast4Digits
            documentNumber
            documentIssuingStateCode
            documentIssuingAgency
            creationDate
            lastUpdateDate
            sources
          }
          aliases
          alternativeIdNumbers
        }
      }
    `;

  return axios.post('/graphql', { query });
}

export function getBigBoostDataOnDemand(cnpj) {
  const query = `
      query {
        bigBoostCompanyOnDemand(cnpj: "${cnpj}") {
          qsa {
            documentType
            name
            position
            legalRepresentativeCountry
            legalRepresentativeJobTitle
            legalRepresentativeName
            legalRepresentativeQualificationCode
            taxId
          }
          capitalStock
          legalRepresentative { 
            cpfCnpj
            name
          }
          status {
            addresses {
              city
              complement
              neighborhood
              number
              state
              street
              zipcode
            }
            name
            businessName
            cnae
            companyType
            economicActivities {
              cnae
              description
              main
            }
            emails
            federalEntity
            foundationDate
            legalNature
            phones
            queryTime
            specialSituation
            specialSituationDate
            statusDate
            statusReason
            terminationDate 
            status
          }
        }
      }
    `;

  return axios.post('/graphql', { query });
}
