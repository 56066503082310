import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { normalizeCurrency } from 'normalizers';
import ReactTooltip from 'react-tooltip';

const descriptionText = (transactionLength, isSameOwnership) => {
  let text = 'aprovada';
  if (!isSameOwnership) text = 'aguardando aprovação';
  else if (transactionLength > 1) text = 'aprovadas';
  return (
    <span className="description-text">
      {`${transactionLength} ${
        transactionLength > 1 ? 'transações' : 'transação'
      } ${text}`}
    </span>
  );
};

const PaymentPendingBodyRow = ({ data, isSameOwnership, showTip }) =>
  data.length > 0 && (
    <div
      className={classNames('payment-pending-body-row', {
        pending: !isSameOwnership,
        approve: isSameOwnership
      })}
    >
      <div className="payment-pending-body-row__title">
        {descriptionText(data.length, isSameOwnership)}
        {showTip && isSameOwnership && (
          <>
            <i
              className="fa fa-question-circle-o"
              data-tip="Transferências para a mesma titularidade<br />são aprovadas automaticamente."
            />
            <ReactTooltip
              type="light"
              place="right"
              effect="solid"
              border
              multiline
              borderColor="lightgray"
              backgroundColor="black"
              textColor="white"
            />
          </>
        )}
      </div>
      <div className="payment-pending-body-row__value">{`Total: R$ ${normalizeCurrency(
        data.reduce((p, c) => p + Math.abs(c.grossValue), 0)
      )}`}</div>
    </div>
  );

PaymentPendingBodyRow.propTypes = {
  data: PropTypes.array.isRequired,
  isSameOwnership: PropTypes.bool,
  showTip: PropTypes.bool
};

PaymentPendingBodyRow.defaultProps = {
  isSameOwnership: false,
  showTip: false
};

export default PaymentPendingBodyRow;
