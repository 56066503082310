import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import humps from 'lodash-humps';

import { fetchRating } from 'api/blu-rating';
import actions, { types } from './actions';

function* watchFetchRatingByClientRequest(action) {
  try {
    const [response] = yield all([
      call(fetchRating, action.cpfCnpj, action.authToken),
      delay(2000)
    ]);

    yield put(
      actions.fetchRatingByClientSuccess(action.cpfCnpj, humps(response.data))
    );
  } catch (error) {
    yield put(
      actions.fetchRatingByClientFailure(
        'Não foi possível carregar esta informação.'
      )
    );
  }
}

export default [
  takeLatest(
    types.FETCH_RATING_BY_CLIENT_REQUEST,
    watchFetchRatingByClientRequest
  )
];
