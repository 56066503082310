import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({ id, className, label, onChange, error, ...rest }) => (
  <label className="custom-checkbox__root" htmlFor={id}>
    <input
      id={id}
      className={classNames('custom-checkbox', className)}
      {...rest}
      data-testid="custom-checkbox"
      onChange={onChange}
      type="checkbox"
    />
    <span
      className={classNames('custom-checkbox__checkmark', {
        'custom-checkbox__error': error
      })}
    />
    {label && (
      <span
        className="custom-checkbox__label"
        data-testid="custom-checkbox-label"
      >
        {label}
      </span>
    )}
  </label>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node,
  error: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

Checkbox.defaultProps = {
  label: '',
  error: false,
  onChange: null,
  className: ''
};

export default Checkbox;
