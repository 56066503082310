import React, { useState } from 'react';
import PropTypes from 'prop-types';

import useRailsContext from 'hooks/useRailsContext';

import { AuthenticationContext } from './authentication.context';

import {
  twoFactorAuthenticationService,
  resendTokenService
} from '../services/twoFactor';

const AuthenticationProvider = ({ children }) => {
  const { clientId: creatorClientUuid } = useRailsContext();
  const [error, setError] = useState('');
  const [renderTwoFactorComponent, setRenderTwoFactorComponent] =
    useState(false);
  const [signInData, setSignInData] = useState({});
  const [token, setToken] = useState('');
  const [successAlert, setSuccessAlert] = useState(false);
  const [loadingTokenValidation, setLoadingTokenValidation] = useState(false);
  const [isOpenSnackBar, setOpenSnackBar] = useState(false);
  const [disabledButton, setDisabledButton] = useState(60);

  const twoFactorAuthentication = () => {
    setLoadingTokenValidation(true);
    setError('');

    twoFactorAuthenticationService(token, signInData.user_token)
      .then((response) => {
        const { data } = response;
        setLoadingTokenValidation(false);

        if (data.message === 'success') {
          window.location.assign(data.path);
        }
        setLoadingTokenValidation(false);
      })
      .catch((error) => {
        const data = error.response?.data;

        if (data.message === 'error') {
          if (data.email_and_password === false) {
            setError('Código inválido');
          } else {
            setError('');
            setRenderTwoFactorComponent(false);
          }
        }
        setLoadingTokenValidation(false);
      });
  };

  const resendToken = () => {
    setOpenSnackBar(false);
    resendTokenService(signInData.user_token)
      .then((response) => {
        const { data } = response;

        if (data.message === 'success') {
          setDisabledButton(60);
          setOpenSnackBar(true);
        }
      })
      .catch((error) => {
        const data = error.response?.data;

        if (data.message === 'error' && data.email_and_password) {
          setError('');
          setRenderTwoFactorComponent(false);
        }
      });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        creatorClientUuid,
        error,
        renderTwoFactorComponent,
        setRenderTwoFactorComponent,
        setError,
        signInData,
        setSignInData,
        token,
        setToken,
        successAlert,
        setSuccessAlert,
        loadingTokenValidation,
        setLoadingTokenValidation,
        twoFactorAuthentication,
        resendToken,
        isOpenSnackBar,
        setOpenSnackBar,
        disabledButton,
        setDisabledButton
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthenticationProvider;
