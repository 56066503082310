/* Core */
import React from 'react';
import PropTypes from 'prop-types';
/* Presentational */
import MomentLocaleUtils from 'react-day-picker/moment';
import DayPicker, { DateUtils } from 'react-day-picker';
import useWindowDimensions from 'hooks/useWindowDimensions';

import {
  serverToDate,
  printToDate,
  serverToPrint,
  dateToPrint
} from './DateHelper';

const PeriodDatePicker = ({ selected, onChange }) => {
  const toDate = serverToDate(selected).toDate();
  const fromDate = serverToDate(selected).subtract(1, 'week').toDate();
  const to = serverToPrint(selected);
  const from = dateToPrint(fromDate);

  const [dates, setDates] = React.useState({ to, from });
  const { width } = useWindowDimensions();
  const [calendarQuantity, setCalendarQuantity] = React.useState(2);
  const [isSelectingLastDay, setSelectingLastDay] = React.useState(false);
  const [showDatePicker, setDatePicker] = React.useState(false);

  React.useEffect(() => {
    if (calendarQuantity === 2 && width <= 768) {
      setCalendarQuantity(1);
    } else if (calendarQuantity === 1 && width > 768) {
      setCalendarQuantity(2);
    }
  }, [width, calendarQuantity]);

  const toggleDatePicker = (target) => {
    setDatePicker(!showDatePicker);
    setSelectingLastDay(
      (target === 'to' && !isSelectingLastDay) || isSelectingLastDay
    );
  };

  const handleDayMouseEnter = (day) => {
    const fromParsed = printToDate(dates.from);

    if (!isSelectingLastDay || (dates.from && day < fromParsed)) {
      return;
    }

    setDates((e) => ({
      ...e,
      to: dateToPrint(day)
    }));
  };

  const handleDayClick = (day) => {
    const formattedDay = dateToPrint(day);
    const fromParsed = printToDate(dates.from).toDate();

    if (DateUtils.isSameDay(day, fromParsed)) {
      return;
    }

    if (isSelectingLastDay) {
      if (dates.to < dates.from) {
        setDates({
          from: formattedDay,
          to: ''
        });
      } else {
        setDates((e) => ({
          ...e,
          to: dates.to
        }));

        setSelectingLastDay(false);
        setDatePicker(false);
        onChange(dates);
      }
    } else {
      setSelectingLastDay(true);
      setDates({
        from: formattedDay,
        to: ''
      });
    }
  };

  return (
    <div className="period-date-picker">
      <div className="period-date-picker__container">
        <div className="period-date-picker__field">
          <label htmlFor="start_date">de</label>
          <input
            type="text"
            data-testid="start_date"
            id="start_date"
            value={dates.from}
            readOnly
            onClick={() => toggleDatePicker('from')}
          />
        </div>

        <div className="period-date-picker__field">
          <label htmlFor="end_date">até</label>
          <input
            type="text"
            id="end_date"
            value={dates.to}
            readOnly
            onClick={() => toggleDatePicker('to')}
          />
        </div>
      </div>

      {showDatePicker && (
        <DayPicker
          numberOfMonths={calendarQuantity}
          enableOutsideDays
          className="DayPicker--ranged"
          locale="pt-BR"
          localeUtils={MomentLocaleUtils}
          selectedDays={(day) =>
            DateUtils.isDayInRange(day, {
              to: printToDate(dates.to).toDate(),
              from: printToDate(dates.from).toDate()
            })
          }
          onDayClick={(day) => handleDayClick(day)}
          onDayMouseEnter={(day) => handleDayMouseEnter(day)}
          modifiers={{
            from: (day) => DateUtils.isSameDay(day, fromDate),
            to: (day) => DateUtils.isSameDay(day, toDate)
          }}
        />
      )}
    </div>
  );
};

PeriodDatePicker.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PeriodDatePicker;
