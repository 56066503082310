import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';

import ScoreCardLayout from '../ScoreCardLayout';

const ScoreCardInitialLoading = ({ newLayout }) => (
  <ScoreCardLayout newLayout={newLayout}>
    <ContentLoader
      data-testid="content-loader"
      speed={2}
      width="100%"
      height={80}
      viewBox="0 0 600 80"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="5" y="10" rx="3" ry="3" width="100%" height="15" />
      <rect x="5" y="32" rx="3" ry="3" width="100%" height="15" />
    </ContentLoader>
  </ScoreCardLayout>
);

ScoreCardInitialLoading.defaultProps = {
  newLayout: 'light'
};

ScoreCardInitialLoading.propTypes = {
  newLayout: PropTypes.string
};

export default ScoreCardInitialLoading;
