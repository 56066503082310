import axios from 'axios';
import { getCSRFToken } from 'utils/csrf-token';

const api = axios.create({
  baseURL: '/api/',
  headers: {
    Accept: 'version=1',
    Authorization: `token ${getCSRFToken()}`
  }
});

export default api;
