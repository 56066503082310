import React from 'react';
import PropTypes from 'prop-types';

import { normalizeValue } from 'normalizers';
import ListItem from 'components/PaymentCollectionDetails/components/ListItem';
import CostsTemplate from 'components/PaymentCollectionDetails/containers/CostsTemplate';

const Costs = ({ title }) => (
  <CostsTemplate title={title}>
    {({
      paymentCollectionRateValue,
      paymentCollectionRateValueAfterRefund,
      discount
    }) => (
      <>
        {Boolean(paymentCollectionRateValue) && (
          <ListItem title="Operacional">
            <p>
              {paymentCollectionRateValueAfterRefund ? 'Original: ' : ''}
              {normalizeValue(paymentCollectionRateValue)}
            </p>
            {Boolean(paymentCollectionRateValueAfterRefund) && (
              <p data-testid="paymentCollectionRateValueAfterRefund">
                Atual: {normalizeValue(paymentCollectionRateValueAfterRefund)}
              </p>
            )}
          </ListItem>
        )}

        {discount && (
          <ListItem
            title={discount.title}
            value={normalizeValue(discount.value)}
          />
        )}
      </>
    )}
  </CostsTemplate>
);

Costs.propTypes = {
  title: PropTypes.string.isRequired
};

export default Costs;
