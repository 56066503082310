import React, { useState, useEffect } from 'react';
import { useMedia } from 'react-use';
import { sendScreenEvent } from '@useblu/blu-lytics';
import { TopBar, Grid, Typography } from '@useblu/ocean-react';
import { ArrowLeftOutline } from '@useblu/ocean-icons-react';

import {
  Bar,
  Description,
  MobileQrcode,
  StepItem,
  StepsContainer,
  TutorialContainer,
  LearnMore,
  LearnMoreLink,
  GridContainer,
  DownloadAppBtn,
  Footer,
  Steps,
} from './BTokenNotActiveStyles';

import { BTokenContainer } from '../BToken.styles';

import mobileBTokenImage from './images/mobile-b-Token-image.svg';
import externalLinkSolid from './images/external-link-solid.svg';

function BTokenNotActive() {
  const isMobile = useMedia('(max-width: 768px)');

  const [inScroll, setInScroll] = useState(false);

  useEffect(() => {
    sendScreenEvent('btoken_install_view');
  }, []);

  const changeHeaderBoxShadow = () => {
    if (window.scrollY > 0) {
      setInScroll(true);
    } else {
      setInScroll(false);
    }
  };

  const handleRedirectDashboard = () => {
    window.location.href = '/';
  };

  const handleRedirectLearnMore = () => {
    window.open(
      ' https://www.comunidade.blu.com.br/post/btoken-blu?utm_source=portal-blu&utm_medium=link&utm_campaign=drawer-btoken',
      '_blank'
    );
  };

  const handleRedirectActivationBToken = () => {
    window.location.replace('https://appblu.onelink.me/lEIQ/btoken');
  };

  window.addEventListener('scroll', changeHeaderBoxShadow);

  return (
    <BTokenContainer>
      <GridContainer>
        <Grid.Row>
          <Grid.Col
            sm="12"
            md={{
              span: '10',
              offset: '1',
            }}
            lg={{
              span: '6',
              offset: '3',
            }}
            xl={{
              span: '6',
              offset: '3',
            }}
          >
            <Bar inScroll={inScroll}>
              <TopBar
                id="TopBar-playground"
                variants="default"
                title="bToken"
                leftIcon={isMobile ? <ArrowLeftOutline /> : null}
                onLeftAction={() => {
                  handleRedirectDashboard();
                }}
              />
            </Bar>
            <Description>
              A camada extra de proteção que valida tudo o que fizer no portal.
            </Description>
            <TutorialContainer>
              <MobileQrcode>
                <img src={mobileBTokenImage} alt="celular com qrcode" />
                <LearnMore
                  data-testid="learn-more"
                  onClick={handleRedirectLearnMore}
                >
                  <LearnMoreLink>
                    <p>Saiba mais sobre o bToken</p>
                    <img src={externalLinkSolid} alt="Icone de link clicável" />
                  </LearnMoreLink>
                </LearnMore>
              </MobileQrcode>
              <StepsContainer>
                <Typography variant="heading4">
                  Ative agora e aproveite todos os benefícios da Blu para o seu
                  negócio
                </Typography>
                <Steps>
                  <StepItem data-testid="step-1">
                    Aponte sua câmera para o QR code e baixe o app, ou verifique
                    se sua versão está atualizada.
                  </StepItem>
                  <StepItem data-testid="step-2">
                    Tenha um documento de identificação em mãos.
                  </StepItem>
                  <StepItem data-testid="step-3">
                    Se prepare para tirar uma selfie, confira as dicas e
                    capriche na foto.
                  </StepItem>
                  <StepItem data-testid="step-4">
                    Pronto, seu bToken estará ativo.
                  </StepItem>
                </Steps>
                {isMobile && (
                  <LearnMoreLink $withMargin>
                    <p>Saiba mais sobre o bToken</p>
                    <img src={externalLinkSolid} alt="Icone de link clicável" />
                  </LearnMoreLink>
                )}
              </StepsContainer>
            </TutorialContainer>
          </Grid.Col>
        </Grid.Row>
      </GridContainer>
      <Footer>
        <DownloadAppBtn onClick={handleRedirectActivationBToken}>
          Ativar bToken
        </DownloadAppBtn>
      </Footer>
    </BTokenContainer>
  );
}

export default BTokenNotActive;
