import PropTypes from 'prop-types';

export const columnType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  dataIndex: PropTypes.string.isRequired,
  dataLabel: PropTypes.string,
  key: PropTypes.string.isRequired,
  render: PropTypes.func,
  width: PropTypes.string,
  editable: PropTypes.bool,
  sorter: PropTypes.bool,
  onCell: PropTypes.func,
  type: PropTypes.string,
  options: PropTypes.array,
  disableDataKey: PropTypes.string,
  classNames: PropTypes.shape({
    cell: PropTypes.string
  }),
  mask: PropTypes.string
});

export default {
  columnType
};
