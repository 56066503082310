import {
  colorBrandPrimaryPure,
  fontFamilyHighlight,
  fontSizeSm,
  fontSizeXs,
  fontSizeXxs,
  fontWeightExtrabold,
  fontWeightRegular,
  lineHeightMedium,
  spacingInlineXs,
  spacingStackXxs
} from '@useblu/ocean-tokens/web/tokens';
import styled, { createGlobalStyle } from 'styled-components';
import { Devices } from 'utils/getDeviceMedia';
import { Modal, Typography } from '@useblu/ocean-react';

export const Container = styled.div``;

export const ModalContainer = styled(Modal)`
  &&& {
    background-color: #ffff;
    max-width: 1000px;
  }
`;

export const ModalStyle = createGlobalStyle` 
  .custom-width {
    .ods-modal__content.ods-modal__content--after-open {
     
      width: 80%;
      max-width: 1000px;

      @media ${Devices.mobile} {
        width: 100%;
      } 
    }

    .ods-modal__body {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Title = styled(Typography)`
  &&& {
    margin-bottom: ${spacingStackXxs};

    @media ${Devices.mobile} {
      color: ${colorBrandPrimaryPure};
      font-family: ${fontFamilyHighlight};
      font-size: ${fontSizeSm};
      font-weight: ${fontWeightExtrabold};
      line-height: ${lineHeightMedium};
      margin: 0 auto;
      left: calc((100vw - 221.13px) / 2);
      position: absolute;
      top: 17px;
    }
  }
`;

export const Description = styled.p`
  font-size: ${fontSizeXxs};
  font-weight: ${fontWeightRegular};

  @media ${Devices.mobile} {
    padding: 0 ${spacingInlineXs};
    font-size: ${fontSizeXs};
  }
`;
