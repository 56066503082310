import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { normalizeCurrency } from 'normalizers';

import {
  ListItem,
  InfoContainer,
  InfoType,
  InfoValue,
  Separator,
} from './TransactionListItemStyles';

function TransactionListItem({ transaction, lastChild }) {
  const [name, setName] = useState('');
  const [type, setType] = useState('Transferência');

  useEffect(() => {
    switch (transaction.transaction_category.slug) {
      case 'transfer_to_client_outflow':
        setType('Transferência');
        setName(transaction.client_receiver?.name);
        break;

      case 'transfer_to_bank_account':
        setType('Transferência');
        setName(transaction.bank_account?.owner_name);
        break;

      case 'payment_to_bill':
        setType('Boleto');
        setName(transaction.bank_transactions_attributes?.bill_receiver_name);
        break;

      case 'fee_payment':
        setType('DARF');
        setName(transaction.bank_transactions_attributes?.feePayment?.name);
        break;

      default:
        setType('Transferência');
        setName(transaction.client_receiver?.name);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function truncateString(str, num) {
    if (str && str.length <= num) {
      return str;
    }

    return str ? `${str.slice(0, num)}...` : '';
  }

  return (
    <>
      <ListItem>
        <InfoContainer>
          <InfoType>
            <em>{type}</em>
            <p className="desktop">{name}</p>
            <p className="mobile">{truncateString(name, 30)}</p>
          </InfoType>
          <InfoValue>
            <em>R$ {normalizeCurrency(transaction.gross_value)} </em>
          </InfoValue>
        </InfoContainer>
      </ListItem>
      {!lastChild && <Separator style={{ margin: 0 }} />}
    </>
  );
}

TransactionListItem.propTypes = {
  transaction: PropTypes.shape({
    gross_value: PropTypes.number.isRequired,
    client_receiver: PropTypes.object.isRequired,
    bank_account: PropTypes.object.isRequired,
    transaction_category: PropTypes.object.isRequired,
    bank_transactions_attributes: PropTypes.object.isRequired,
  }).isRequired,
  lastChild: PropTypes.bool.isRequired,
};

export default TransactionListItem;
