import React from 'react';
import PropTypes from 'prop-types';

import { BorderRadiusContainer, Taxes } from '../styles';

const TableWeb = ({ hideHeader, taxes }) => (
  <>
    <BorderRadiusContainer removeBorder={hideHeader}>
      <Taxes>
        <tbody className={hideHeader ? 'taxes-table-optin' : undefined}>
          <tr>
            <th colSpan="2">Débito</th>
          </tr>
          <tr>
            <td>Visa</td>
            <td>{taxes.debitVisa}</td>
          </tr>
          <tr>
            <td>Master</td>
            <td>{taxes.debitMaster}</td>
          </tr>
          <tr>
            <td>Amex</td>
            <td>{taxes.debitAmex}</td>
          </tr>
          <tr>
            <td>Elo</td>
            <td>{taxes.debitElo}</td>
          </tr>
          <tr>
            <td>Hiper</td>
            <td>{taxes.debitHiper}</td>
          </tr>
        </tbody>
      </Taxes>
    </BorderRadiusContainer>
    <BorderRadiusContainer removeBorder={hideHeader}>
      <Taxes>
        <tbody className={hideHeader ? 'taxes-table-optin' : undefined}>
          <tr>
            <th colSpan="2">Crédito à vista</th>
          </tr>
          <tr>
            <td>Visa</td>
            <td>{taxes.credit1xVisa}</td>
          </tr>
          <tr>
            <td>Master</td>
            <td>{taxes.credit1xMaster}</td>
          </tr>
          <tr>
            <td>Amex</td>
            <td>{taxes.credit1xAmex}</td>
          </tr>
          <tr>
            <td>Elo</td>
            <td>{taxes.credit1xElo}</td>
          </tr>
          <tr>
            <td>Hiper</td>
            <td>{taxes.credit1xHiper}</td>
          </tr>
        </tbody>
      </Taxes>
    </BorderRadiusContainer>
    <BorderRadiusContainer removeBorder={hideHeader}>
      <Taxes>
        <tbody className={hideHeader ? 'taxes-table-optin' : undefined}>
          <tr>
            <th colSpan="2">Parcelado em 2x a 6x</th>
          </tr>
          <tr>
            <td>Visa</td>
            <td>{taxes.credit2x6Visa}</td>
          </tr>
          <tr>
            <td>Master</td>
            <td>{taxes.credit2x6Master}</td>
          </tr>
          <tr>
            <td>Amex</td>
            <td>{taxes.credit2x6Amex}</td>
          </tr>
          <tr>
            <td>Elo</td>
            <td>{taxes.credit2x6Elo}</td>
          </tr>
          <tr>
            <td>Hiper</td>
            <td>{taxes.credit2x6Hiper}</td>
          </tr>
        </tbody>
      </Taxes>
    </BorderRadiusContainer>
    <BorderRadiusContainer removeBorder={hideHeader}>
      <Taxes>
        <tbody className={hideHeader ? 'taxes-table-optin' : undefined}>
          <tr>
            <th colSpan="2">Parcelado em 7x a 12x</th>
          </tr>
          <tr>
            <td>Visa</td>
            <td>{taxes.credit7x12Visa}</td>
          </tr>
          <tr>
            <td>Master</td>
            <td>{taxes.credit7x12Master}</td>
          </tr>
          <tr>
            <td>Amex</td>
            <td>{taxes.credit7x12Amex}</td>
          </tr>
          <tr>
            <td>Elo</td>
            <td>{taxes.credit7x12Elo}</td>
          </tr>
          <tr>
            <td>Hiper</td>
            <td>{taxes.credit7x12Hiper}</td>
          </tr>
        </tbody>
      </Taxes>
    </BorderRadiusContainer>
  </>
);

TableWeb.propTypes = {
  hideHeader: PropTypes.bool,
  taxes: PropTypes.object.isRequired
};

TableWeb.defaultProps = {
  hideHeader: false
};

export default TableWeb;
