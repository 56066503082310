import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from 'react-loading';
import _ from 'lodash';

import { TopBar, Container } from '@useblu/ocean-react';

import {
  submitFormData,
  showClientStep,
  showContractStep
} from 'actions/contract-form';
import PageTitle from '../components/PageTitle';

import UserHookContract from './useContract';

import { Wrapper, Bar } from './styles';

const UserContract = ({
  railsContext,
  client,
  user,
  address,
  hasShowContractStep,
  showClientStep,
  submitFormData,
  registrationStep,
  showContractStep
}) => {
  UserHookContract({
    railsContext,
    client,
    user,
    address,
    showClientStep,
    hasShowContractStep,
    submitFormData,
    registrationStep,
    showContractStep
  });

  let content;

  if (!_.isEmpty(client) && !client.contract_lote_key) {
    content = (
      <div>
        <h4> Aguarde alguns instantes enquanto geramos o seu contrato! </h4>
        <div className="loading" style={{ margin: '0 auto' }}>
          <Loading
            type="spinningBubbles"
            color="#e1e5ed"
            width="35px"
            delay={0}
          />
        </div>
      </div>
    );
  }

  if (!_.isEmpty(client) && client.contract_lote_key) {
    const batchContractMessage =
      railsContext.currentUserClientsCount > 1 ? (
        <span>
          Por favor revise os contratos abaixo e clique em{' '}
          <b>Assinar documentos</b>, na parte inferior da página, para confirmar
          a assinatura.
        </span>
      ) : (
        ''
      );

    content = (
      <div>
        <h5> {batchContractMessage} </h5>
        <div id="signature-box" className="clicksign-signature-box" />
      </div>
    );
  }

  const userStatus =
    registrationStep === 'pending_blu_contract_signature' ||
    registrationStep === 'pending_blu_contract_signature_without_qsa';

  return (
    hasShowContractStep &&
    (userStatus ||
      window.location.href.split('?')[1] === 'advanced=assinatura') && (
      <div className="ocean-iso">
        <Bar>
          <TopBar
            id="TopBar-playground"
            variants="default"
            title="Assinatura do contrato"
          />
        </Bar>

        <Container>
          <Wrapper>
            <PageTitle
              title="É hora de assinar o contrato, confira:"
              subtitle="Leia o contrato antes de seguir com a assinatura."
              step={3}
            />
          </Wrapper>
          {content}
        </Container>
      </div>
    )
  );
};

UserContract.propTypes = {
  user: PropTypes.object,
  client: PropTypes.object,
  address: PropTypes.object,
  registrationStep: PropTypes.string,
  railsContext: PropTypes.object.isRequired,
  showClientStep: PropTypes.func.isRequired,
  hasShowContractStep: PropTypes.bool.isRequired,
  submitFormData: PropTypes.func.isRequired,
  showContractStep: PropTypes.func.isRequired
};

UserContract.defaultProps = {
  user: {},
  client: {},
  address: {},
  registrationStep: ''
};

const mapStateToProps = (state) => ({
  user: state.contractForm.user,
  client: state.contractForm.client,
  railsContext: state.railsContext,
  address: state.contractForm.address,
  hasShowContractStep: state.contractForm.showContractStep,
  registrationStep: state.contractForm.registration_step
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showClientStep,
      showContractStep,
      submitFormData
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserContract);
