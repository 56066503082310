import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = React.forwardRef((_props, ref) => (
  <ReCAPTCHA
    ref={ref}
    size="invisible"
    sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY || ''}
  />
));

export default Captcha;
