import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from 'react-loading';

import TableBodyRow from './components/TableBodyRow';
import TableBodyColumn from './components/TableBodyColumn';
import TableHeaderRow from './components/TableHeaderRow';
import TableHeaderColumn from './components/TableHeaderColumn';
import { columnType } from './types';

const customStyleLoading = {
  margin: '8px 0',
  padding: '32px',
  display: 'flex',
  justifyContent: 'center'
};

const Table = ({
  loading,
  dataSource,
  defaultSelectable,
  columns,
  components,
  className,
  selectable,
  onSelectAll,
  onSelect,
  emptyMessage,
  classNames,
  indexErrorMessage
}) => {
  const [dataSelect, setDataSelect] = useState();
  const [sorter, setSorter] = useState();

  const setHandleSelectAll = useCallback(
    () => onSelectAll && onSelectAll(dataSource),
    [dataSource, onSelectAll]
  );

  useEffect(() => {
    if (defaultSelectable) {
      setDataSelect(dataSource);
      setHandleSelectAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const isCheck = (data) => {
    const length = dataSelect ? dataSelect.length : 0;
    if (data && dataSelect) {
      return dataSelect.map((d) => d.id).indexOf(data.id) > -1;
    }
    return length === dataSource.length;
  };

  const onCheck = (data, callback, evt) => {
    const { checked } = evt.target;
    let dataSelectCache = dataSelect || [];
    if (data) {
      if (checked) {
        dataSelectCache = [...dataSelectCache, data];
      } else {
        dataSelectCache = dataSelectCache.filter((d) => d.id !== data.id);
      }
    } else if (!data) {
      if (checked) {
        dataSelectCache = dataSource;
      } else {
        dataSelectCache = [];
      }
    }
    setDataSelect(dataSelectCache);
    if (callback) {
      callback(dataSelectCache);
    }
  };

  const renderCheckItem = (onClick, className, data) =>
    selectable ? (
      <input
        checked={isCheck(data)}
        className={className}
        onChange={onCheck.bind(this, data, onClick)}
        type="checkbox"
      />
    ) : null;

  if (loading) {
    return (
      <div style={customStyleLoading}>
        <Loading type="spinningBubbles" color="#e1e5ed" delay={0} />
      </div>
    );
  }

  const sorterFunc = (key) => () => {
    if (!sorter || sorter.key !== key) {
      setSorter({ key, direction: 'asc' });
    } else if (sorter.direction === 'asc') {
      setSorter({ key, direction: 'desc' });
    } else if (sorter.direction === 'desc') {
      setSorter(null);
    }
  };

  const getItems = () => {
    if (sorter) {
      const sorterItems = [...dataSource].sort((a, b) => {
        if (a[sorter.key] > b[sorter.key]) {
          return 1;
        }
        if (a[sorter.key] < b[sorter.key]) {
          return -1;
        }
        return 0;
      });

      if (sorter.direction === 'desc') {
        return sorterItems.reverse();
      }

      return sorterItems;
    }
    return dataSource;
  };

  return (
    <table className={`table table-list ${className}`} key="table-list">
      <thead className="table-list__header">
        <TableHeaderRow>
          {selectable && (
            <th
              className="table-header-selectable"
              style={{ padding: 0 }}
              width="32px"
            >
              {renderCheckItem(onSelectAll, 'table-list__header-item-action')}
            </th>
          )}
          {columns.map((column) => (
            <TableHeaderColumn
              column={column}
              sorter={sorter}
              sorterFunc={sorterFunc}
              key={`column-${column.key}`}
            />
          ))}
        </TableHeaderRow>
      </thead>
      <tbody className="table-list__body">
        {dataSource.length === 0 && (
          <tr>
            <td colSpan={columns.length} className="table-list__empty">
              {emptyMessage}
            </td>
          </tr>
        )}
        {getItems().map((data, indexRow) => (
          <TableBodyRow
            data={data}
            key={`row-${indexRow + 1}`}
            classNames={classNames}
            columnsLength={columns.length}
            indexErrorMessage={indexErrorMessage}
          >
            {selectable && (
              <td className="table-body-selectable" width="32px">
                {renderCheckItem(
                  onSelect,
                  'table-list__body-item-action',
                  data
                )}
              </td>
            )}
            {columns.map((column, indexCol) => (
              <TableBodyColumn
                components={components}
                column={column}
                data={data}
                classNames={classNames}
                indexRow={indexRow}
                indexCol={indexCol}
                key={`column-${column.dataIndex}`}
              />
            ))}
          </TableBodyRow>
        ))}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(columnType).isRequired,
  className: PropTypes.string,
  dataSource: PropTypes.any.isRequired,
  loading: PropTypes.bool,
  defaultSelectable: PropTypes.bool,
  selectable: PropTypes.bool,
  onSelectAll: PropTypes.any,
  onSelect: PropTypes.any,
  indexErrorMessage: PropTypes.string,
  emptyMessage: PropTypes.string.isRequired,
  classNames: PropTypes.shape({
    cell: PropTypes.any,
    row: PropTypes.any
  }),
  components: PropTypes.shape({
    cell: PropTypes.any
  })
};

Table.defaultProps = {
  className: '',
  indexErrorMessage: '',
  components: null,
  loading: false,
  selectable: false,
  defaultSelectable: true,
  onSelectAll: () => {},
  onSelect: () => {},
  classNames: {}
};

export default Table;
