import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ButtonPopup = ({
  open,
  text,
  type,
  children,
  disabled,
  className,
  hideIcon,
  transferLimitValue
}) => {
  const popupClass = classNames({
    'popup-button': true,
    open
  });

  const style = {};

  if (transferLimitValue) {
    style.top = '-175px';
  }

  return (
    <div className="button-with-popup">
      <div className={popupClass} style={style}>
        {children}
      </div>
      <button type={type} className={`button ${className}`} disabled={disabled}>
        {text}

        {!hideIcon && <i className="fa fa-chevron-right" />}
      </button>
    </div>
  );
};

ButtonPopup.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  hideIcon: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  transferLimitValue: PropTypes.bool
};

ButtonPopup.defaultProps = {
  transferLimitValue: false,
  hideIcon: false,
  className: '',
  type: 'button'
};

export default ButtonPopup;
