import React from 'react';
import PropTypes from 'prop-types';

const ListContainer = ({ title, children }) => (
  <div className="list__wrapper">
    <h4 className="list__title">{title}</h4>
    {children}
  </div>
);

ListContainer.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired
};

export default ListContainer;
