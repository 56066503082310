/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import { Field } from 'redux-form';
import RenderField from 'components/Form/RenderField';
import _ from 'lodash';

class Client extends Component {
  handleClientInputChange(event, fieldName) {
    const { client, setClient } = this.props;

    const newClient = {
      ...client,
      [fieldName]: event.target.value
    };

    setClient(newClient);
  }

  renderClientInfo() {
    const { client, disabled } = this.props;

    return (
      <div>
        <div className="form-row">
          <Field
            type="text"
            required
            defaultValue={client.cpfCnpj}
            name="client[cpfCnpj]"
            component={RenderField}
            mask="cpf_cnpj"
            label="CPF/CNPJ"
            disabled={!_.isEmpty(client.cpfCnpj) || disabled}
            onChange={(e) => this.handleClientInputChange(e, 'cpfCnpj')}
          />
        </div>
        <div className="form-row">
          <Field
            type="text"
            defaultValue={client.name}
            name="client[name]"
            component={RenderField}
            label="Razão Social"
            disabled={!_.isEmpty(client.name) || disabled}
            onChange={(e) => this.handleClientInputChange(e, 'name')}
          />
        </div>
        <div className="form-row">
          <Field
            type="text"
            defaultValue={client.businessName}
            name="client[businessName]"
            component={RenderField}
            label="Nome fantasia"
            disabled={disabled}
            onChange={(e) => this.handleClientInputChange(e, 'businessName')}
          />
        </div>
      </div>
    );
  }

  render() {
    return <div className="flex-container">{this.renderClientInfo()}</div>;
  }
}

Client.propTypes = {
  client: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  setClient: PropTypes.func.isRequired
};

export default Client;
