import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { railsContextSelector } from 'selectors/rails-context';
import Logo from 'components/Dashboard/shared/Logo';
import arrowLeft from 'images/icons/arrow-left.svg';
import { useMedia } from 'react-use';
import useMenuOpen from '../hooks/useMenuOpen';
import Item from './components/Item';
import {
  MenuContainer,
  MenuMobileContainer,
  SubmenuMobileContainer
} from './styles';

const Menu = ({ items, homeLink }) => {
  const { economicGroupView } = useSelector(railsContextSelector);
  const { actionsProps, classes, ref } = useMenuOpen('click');
  const isMobile = useMedia('(max-width: 768px)');

  return isMobile ? (
    <MenuMobileContainer
      ref={ref}
      aria-label="Menu de Navegação mobile"
      economicGroupView={economicGroupView}
    >
      <nav className="main" role="navigation">
        <ul>
          {items.map(
            (item) => !item.mobile && <Item {...item} key={item.link} />
          )}
          <Item
            label="Mais"
            link="#mais"
            icon="ic ic-more"
            onClick={actionsProps.onClick}
          />
        </ul>
      </nav>

      <SubmenuMobileContainer className={classes} data-testid="submenu-view">
        <div className="submenu-header">
          <button
            type="button"
            onClick={actionsProps.onClick}
            data-testid="submenu-click"
          >
            <img src={arrowLeft} alt="icone close menu" />
          </button>
          <h2>Mais</h2>
        </div>
        <nav role="navigation">
          <ul>
            {items.map(
              (item) => item.mobile && <Item {...item} key={item.link} />
            )}
          </ul>
        </nav>
      </SubmenuMobileContainer>
    </MenuMobileContainer>
  ) : (
    <MenuContainer
      aria-label="Menu de Navegação"
      economicGroupView={economicGroupView}
    >
      <nav>
        <ul>
          <Logo link={homeLink} />
          {items.map((item) => (
            <Item {...item} key={item.link} />
          ))}
        </ul>
      </nav>
    </MenuContainer>
  );
};

Menu.propTypes = {
  homeLink: PropTypes.string.isRequired,
  submenuItems: PropTypes.array,
  items: PropTypes.array
};

Menu.defaultProps = {
  items: [],
  submenuItems: []
};

export default Menu;
