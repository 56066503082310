import { getClient } from 'api/first-access';
import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/firstAccess/usersContracts';
import SharedActionCreators from 'store/ducks/firstAccess/shared';
import _ from 'lodash';

export default function* loadUsersContracts(params) {
  const response = yield call(getClient, params);
  const { data } = response;

  if (_.isEmpty(data.errors)) {
    yield put(SharedActionCreators.firstAccessClientSuccess(data.data.client));
    yield put(ActionCreators.firstAccessUsersContractsSuccess());
  } else {
    yield put(SharedActionCreators.firstAccessClientFailure());
  }
}
