import { advancementRequestApi } from 'api/transaction';
import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/advancementRequest';

export const groupTransactions = (advancementRequest) => {
  const items = [];
  let advancementValue = 0;
  let advancementRateValue = 0;

  advancementRequest.map((simulation) => {
    advancementValue += simulation.advancementValue;
    advancementRateValue += simulation.advancementRateValue;

    simulation.advancementItems.map((item) =>
      items.push({
        clientUuid: simulation.clientUuid,
        ...item
      })
    );
  });

  return {
    advancementValue,
    advancementRateValue,
    advancementItems: items
  };
};

function* advancementRequest(params) {
  try {
    let advancementRequestData = {};
    const response = yield call(advancementRequestApi, params);

    const { errors, data } = response.data;

    if (errors && errors.length > 0) {
      throw new Error();
    }

    const { advancementRequest } = data;

    if (advancementRequest.length > 1) {
      advancementRequestData = groupTransactions(advancementRequest);
    } else {
      advancementRequestData = advancementRequest[0];
    }

    yield put(ActionCreators.advancementRequestSuccess(advancementRequestData));
  } catch (error) {
    yield put(ActionCreators.advancementRequestFailure());
  }
}

export default advancementRequest;
