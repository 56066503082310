import { isEmpty } from 'lodash';
import humps from 'lodash-humps';
import {
  getClientStatusApi,
  automaticAnticipationStatusApi
} from 'api/clients';

import { call, put } from 'redux-saga/effects';
import clientStatusActions from 'store/ducks/clientStatus';

export default function* getClientStatus({ uuid }) {
  try {
    let cercAgreementOptInConfirmed = false;
    let multiAcquirerOrdersOptInConfirmed = false;
    let cercAgreementOptInAgreedAt;
    let cercAgreementOptInStatus;
    const response = yield call(getClientStatusApi, uuid);
    const aaResponse = yield call(automaticAnticipationStatusApi, uuid);

    const { errors, data } = response.data;
    const { data: aaData } = aaResponse;

    const {
      featureAutomaticAnticipation,
      featureAutomaticAnticipationByInstallments
    } = humps(aaData);

    let automaticAnticipationEnabledType = '';

    if (featureAutomaticAnticipation?.status) {
      automaticAnticipationEnabledType = 'Taxa fixa';
    } else if (featureAutomaticAnticipationByInstallments?.status) {
      automaticAnticipationEnabledType = 'Taxa variável';
    }

    if (errors) {
      throw new Error('Ocorreu um problema na consulta');
    }

    const {
      clientCercAgreement,
      federalRevenueStatus,
      socialContractStatus,
      multiAcquirerOrders,
      needSocialContract,
      addresses
    } = data?.client;

    if (clientCercAgreement) {
      const { agreedAt, status } = clientCercAgreement;

      if (!isEmpty(agreedAt)) {
        cercAgreementOptInAgreedAt = agreedAt;
        cercAgreementOptInConfirmed = true;
      }

      cercAgreementOptInStatus = status;
    }

    if (multiAcquirerOrders && multiAcquirerOrders.length > 0) {
      const [{ status }] = multiAcquirerOrders;

      if (status) {
        multiAcquirerOrdersOptInConfirmed = true;
      }
    }

    yield put(
      clientStatusActions.getClientStatusSuccess({
        cercAgreementOptInConfirmed:
          cercAgreementOptInStatus === 'canceled'
            ? false
            : cercAgreementOptInConfirmed,
        cercAgreementOptInStatus,
        cercAgreementOptInAgreedAt,
        needSocialContract,
        federalRevenueStatus,
        socialContractStatus,
        multiAcquirerOrdersOptInConfirmed,
        automaticAnticipationOptInConfirmed:
          featureAutomaticAnticipation.status ||
          featureAutomaticAnticipationByInstallments.status,
        automaticAnticipationEnabledAt:
          featureAutomaticAnticipation.activatedAt ||
          featureAutomaticAnticipationByInstallments.activatedAt ||
          '',
        automaticAnticipationEnabledType,
        addresses
      })
    );
  } catch (error) {
    yield put(clientStatusActions.getClientStatusFailure(error.message));
  }
}
