import { Typography } from '@useblu/ocean-react';
import {
  colorBrandPrimaryPure,
  colorInterfaceDarkDeep,
  colorInterfaceDarkDown,
  colorInterfaceLightUp,
  fontSizeSm,
  fontSizeXs,
  spacingInlineLg,
  spacingInlineXl,
  spacingInlineXs,
  spacingInsetLg,
  spacingStackLg
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

const mediaScreen = {
  xs: `@media (max-width: 480px)`,
  sm: `@media (min-width: 600px)`,
  md: `@media (min-width: 992px)`,
  lg: `@media (min-width: 993px)`
};

export const Wrapper = styled.section`
  margin-top: ${spacingInlineXl};
  padding: ${spacingInsetLg} ${spacingStackLg};
  align-items: center;
  background: ${colorInterfaceLightUp};
  display: flex;
  flex-wrap: wrap;

  ${mediaScreen.xs} {
    padding: ${spacingInsetLg} 0px;
  }
`;

export const Title = styled(Typography).attrs(() => ({
  variant: 'heading2'
}))`
  &&& {
    padding: 0 ${spacingInlineLg};
    color: ${colorInterfaceDarkDeep};
    font-size: ${fontSizeSm};

    ${mediaScreen.xs} {
      text-align: center;
    }
  }
`;

export const SubTitle = styled(Typography).attrs(() => ({
  variant: 'paragraph'
}))`
  &&& {
    padding: 0 ${spacingInlineLg};
    color: ${colorInterfaceDarkDown};
    font-size: ${fontSizeXs};
    margin-top: ${spacingInlineXs};

    span {
      font-weight: bold;
    }

    ${mediaScreen.xs} {
      text-align: center;
    }
  }
`;

export const Icon = styled.div`
  text-align: center;
  margin-bottom: ${spacingInlineXs};
  width: 59px;
  height: 59px;
  margin-bottom: 0;

  svg {
    color: ${colorBrandPrimaryPure};
    width: 100%;
    height: 100%;
  }

  ${mediaScreen.xs} {
    margin: 0 auto 10px auto;
  }
`;

export const Body = styled.div`
  flex: 1;

  ${mediaScreen.xs} {
    flex: initial;
    width: 100%;
  }
`;
