import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { normalizeValue } from 'normalizers';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import ReadOnlyField from '../components/ReadOnlyField';

const InstallmentsAndCosts = ({
  isCharger,
  InstallmentsElementType,
  CostsElementType
}) => {
  const {
    bluBilletInstallments,
    showCosts,
    nonApproved,
    totalWithInstallments,
    bluBilletNetValue
  } = useSelector(selectors.getDetailsData);

  return bluBilletInstallments?.length > 1 ? (
    <>
      {showCosts && <CostsElementType title="Custos desta parcela" />}
      <ReadOnlyField
        label="Valor total do Boleto Blu"
        value={
          <strong data-testid="bluBilletTotalValue">
            {normalizeValue(totalWithInstallments)}
          </strong>
        }
      />
      <ReadOnlyField
        label={`Valor ${isCharger ? 'recebido' : 'pago'} do Boleto Blu`}
        value={
          <span data-testid="bluBilletNetValue">
            {normalizeValue(bluBilletNetValue)}
          </span>
        }
      />
      {nonApproved && <InstallmentsElementType />}
    </>
  ) : (
    <>
      {nonApproved && <InstallmentsElementType />}
      {showCosts && <CostsElementType title="Custos" />}
    </>
  );
};

InstallmentsAndCosts.defaultProps = {
  isCharger: false
};

InstallmentsAndCosts.propTypes = {
  isCharger: PropTypes.bool,
  InstallmentsElementType: PropTypes.elementType.isRequired,
  CostsElementType: PropTypes.elementType.isRequired
};

export default InstallmentsAndCosts;
