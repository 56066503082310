import React from 'react';
import PropTypes from 'prop-types';

import { BorderRadiusContainer, Taxes } from '../styles';

const TableMobile = ({ hideHeader, taxes }) => (
  <BorderRadiusContainer
    removeBorder={hideHeader}
    className={hideHeader ? 'hide-header' : undefined}
  >
    <Taxes>
      <tbody className={hideHeader ? 'taxes-table-optin' : undefined}>
        <tr>
          <td />
          <th>Visa</th>
          <th>Master</th>
          <th>Amex</th>
          <th>Elo</th>
          <th>Hiper</th>
        </tr>
        <tr>
          <td>Débito</td>
          <td>{taxes.debitVisa}</td>
          <td>{taxes.debitMaster}</td>
          <td>{taxes.debitAmex}</td>
          <td>{taxes.debitElo}</td>
          <td>{taxes.debitHiper}</td>
        </tr>
        <tr>
          <td>Crédito à vista</td>
          <td>{taxes.credit1xVisa}</td>
          <td>{taxes.credit1xMaster}</td>
          <td>{taxes.credit1xAmex}</td>
          <td>{taxes.credit1xElo}</td>
          <td>{taxes.credit1xHiper}</td>
        </tr>
        <tr>
          <td>Parcelado em 2x a 6x</td>
          <td>{taxes.credit2x6Visa}</td>
          <td>{taxes.credit2x6Master}</td>
          <td>{taxes.credit2x6Amex}</td>
          <td>{taxes.credit2x6Elo}</td>
          <td>{taxes.credit2x6Hiper}</td>
        </tr>
        <tr>
          <td>Parcelado em 7x a 12x</td>
          <td>{taxes.credit7x12Visa}</td>
          <td>{taxes.credit7x12Master}</td>
          <td>{taxes.credit7x12Amex}</td>
          <td>{taxes.credit7x12Elo}</td>
          <td>{taxes.credit7x12Hiper}</td>
        </tr>
      </tbody>
    </Taxes>
  </BorderRadiusContainer>
);

TableMobile.propTypes = {
  hideHeader: PropTypes.bool,
  taxes: PropTypes.object.isRequired
};

TableMobile.defaultProps = {
  hideHeader: false
};

export default TableMobile;
