import { createActions } from 'reduxsauce';

const { Types: types, Creators: actions } = createActions({
  fetchRatingByClientRequest: ['cpfCnpj', 'authToken'],
  fetchRatingByClientSuccess: ['cpfCnpj', 'data'],
  fetchRatingByClientFailure: ['error']
});

export { types };

export default actions;
