import styled, { createGlobalStyle } from 'styled-components';

import { Col, Radio } from '@useblu/ocean-react';

import {
  fontFamilyBase,
  fontFamilyHighlight,
  fontSizeXxxs,
  fontSizeXxs,
  fontSizeXs,
  fontSizeSm,
  fontSizeMd,
  fontSizeLg,
  fontWeightExtrabold,
  colorBrandPrimaryPure,
  colorBrandPrimaryDown,
  colorInterfaceDarkDown,
  colorInterfaceDarkUp,
  colorInterfaceLightUp,
  borderRadiusMd,
  colorInterfaceDarkDeep,
  colorInterfaceLightDown,
  colorInterfaceLightDeep,
  colorInterfaceDarkPure,
  colorStatusWarningUp,
  colorInterfaceLightPure,
  colorStatusPositiveDeep,
  colorStatusPositiveUp,
  colorStatusNegativeUp,
  colorStatusNegativeDeep,
  colorStatusNegativePure,
  lineHeightComfy,
  fontWeightRegular,
  spacingInlineMd,
  spacingInlineXs,
  spacingInlineXxs,
  fontWeightMedium,
  spacingStackXs,
} from '@useblu/ocean-tokens/web/tokens';

import { Devices } from 'utils/getDeviceMedia';

export const ModalStyle = createGlobalStyle`
  .ocean-iso .ods-modal__content .ods-modal__body {
   overflow: auto;
  }
`;

export const LoadingFrame = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const WrapInput = styled.div`
  @media ${Devices.tablet} {
    margin-bottom: 24px;
  }
  @media ${Devices.mobile} {
    margin-bottom: 24px;
  }

  .error-msg {
    color: ${colorStatusNegativePure};
    font-size: 12px;
  }

  .ods-input {
    &.error {
      border-color: ${colorStatusNegativePure};
    }
  }
`;

export const CustomForm = styled.div`
  display: flex;
  flex-direction: column-reverse;

  .ods-container {
    margin-bottom: 24px;
    height: 86px;
  }

  .ods-row {
    margin-left: -16px;
    margin-right: -16px;
  }

  @media ${Devices.tablet} {
    .ods-container {
      margin-bottom: 0;
      height: auto;
    }
  }
  @media ${Devices.mobile} {
    .ods-container {
      margin-bottom: 0;
      height: auto;
    }
  }
`;

export const CustomSelectWrapper = styled.div`
  .ods-select__root {
    width: 100%;
  }

  .error-msg {
    color: ${colorStatusNegativePure};
    font-size: 12px;
  }

  .ods-select__listbox {
    max-height: 95px;
  }

  .ods-select__control {
    &.error {
      border-color: ${colorStatusNegativePure};
    }
  }

  @media ${Devices.tablet} {
    margin-bottom: 24px;
  }
  @media ${Devices.mobile} {
    margin-bottom: 24px;
  }
`;

export const CustomSelectWrapperHighLight = styled(CustomSelectWrapper)`
  .ods-form-label {
    color: ${colorInterfaceDarkDown};
    font-weight: 900;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
  }
`;

export const UploadInputTrigger = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  width: 100%;
  border-radius: 8px;
  background: ${colorInterfaceLightPure};
  border: 1px dashed ${colorInterfaceLightDeep};
  box-sizing: border-box;
  margin: 24px 0px;
  cursor: pointer;

  input {
    display: none;
  }

  &:hover {
    background: ${colorInterfaceLightUp};
  }

  @media ${Devices.tablet} {
    background: ${colorInterfaceLightUp};
    padding: 12px 0;
    border-radius: 28px;
    border: none;
  }
  @media ${Devices.mobile} {
    background: ${colorInterfaceLightUp};
    padding: 12px 0;
    border-radius: 28px;
    border: none;
  }
`;

export const UploadInputTriggerCaption = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: ${colorInterfaceDarkUp};
  margin: 8px 0px;

  @media ${Devices.tablet} {
    display: none;
  }
  @media ${Devices.mobile} {
    display: none;
  }
`;

export const UploadInputTriggerTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: ${colorInterfaceDarkDeep};
  margin: 8px 0;

  svg,
  span {
    display: block;
    margin-right: 6px;
  }

  a {
    display: contents;
    text-decoration: underline;
  }

  @media ${Devices.tablet} {
    margin: 0;
    span {
      display: none;
    }
    svg {
      width: 24px;
      height: 24px;
      fill: ${colorBrandPrimaryPure};
    }
    a {
      text-decoration: none;
    }
  }

  @media ${Devices.mobile} {
    margin: 0;
    span {
      display: none;
    }
    svg {
      width: 24px;
      height: 24px;
      fill: ${colorBrandPrimaryPure};
    }
    a {
      text-decoration: none;
    }
  }
`;

export const UploadInput = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  background: ${colorInterfaceLightPure};
  border: 1px solid ${colorInterfaceLightDeep};
  box-sizing: border-box;
  margin: 24px 0px;
`;

export const Loading = styled.div`
  display: flex;
  align-items: center;

  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  svg {
    animation-name: ckw;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    display: inline-block;
  }
`;

export const FileDescription = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;

  @media ${Devices.tablet} {
    flex-direction: column;
    div {
      margin-bottom: 4px;
    }
  }

  @media ${Devices.mobile} {
    flex-direction: column;
    div {
      margin-bottom: 4px;
    }
  }
`;

export const FileLabel = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  color: ${colorInterfaceDarkDown};
`;

export const FileActions = styled.div`
  display: flex;
  flex-direction: row;

  a {
    display: contents;
    svg {
      margin-left: 12px;
    }
  }
`;

export const FileStats = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 110%;
  padding: 4px 8px;
  border-radius: 16px;
  flex-direction: row;
  white-space: pre;
  align-items: center;
  display: flex;
  background: ${colorStatusPositiveUp};
  color: ${colorStatusPositiveDeep};

  &.error {
    background: ${colorStatusNegativeUp};
    color: ${colorStatusNegativeDeep};
  }

  svg {
    margin-right: 4px;
  }
`;

export const FileStatus = styled.div`
  display: block;

  @media ${Devices.tablet} {
    display: flex;
    width: 100%;
  }

  @media ${Devices.mobile} {
    display: flex;
    width: 100%;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: ${colorStatusWarningUp};
  margin-top: 0;
  padding: 16px;
  width: calc(100% - 32px);
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${colorInterfaceDarkDown};
  border-radius: 8px;

  svg {
    margin-right: 12px;
    min-width: 24px;
    height: 24px;
  }
  p {
    margin: 0px;
  }

  @media ${Devices.tablet} {
    margin: 0 16px;
  }
  @media ${Devices.mobile} {
    margin: 0 16px;
  }
`;

export const StoreSelector = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const StoreItem = styled.div`
  width: 100%;
  display: flex;
  white-space: nowrap;
  height: 24px;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  color: ${colorInterfaceDarkPure};

  svg {
    margin-right: 4px;
  }
`;

export const AcceptTermsContainer = styled.div`
  margin: 40px 0 24px;
  padding-left: 8px;
  display: none;

  &.desktop {
    display: block;

    @media ${Devices.tablet} {
      display: none;
    }
    @media ${Devices.mobile} {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media ${Devices.tablet} {
      display: block;
      margin: 8px 0 24px;
      padding-left: 16px;
    }
    @media ${Devices.mobile} {
      display: block;
      margin: 8px 0 24px;
      padding-left: 16px;
    }
  }
`;

export const Frame = styled.div`
  width: 100%;
  ${(props) => !props.$withoutHeight && `height: 476px;`};

  display: flex;
  flex-direction: column;

  &.large {
    height: 542px;
  }

  @media ${Devices.tablet} {
    width: 100%;
    height: 100%;
    &.large {
      height: 100%;
    }
  }
  @media ${Devices.mobile} {
    width: 100%;
    height: 100%;
    &.large {
      height: 100%;
    }
  }
`;

export const Description = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 14px;
    line-height: 150%;
    margin: 8px 0px 0px 0px;
    display: flex;
    align-items: center;
    color: ${colorInterfaceDarkDown};
  }

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: ${colorInterfaceDarkDeep};
    margin-bottom: 16px;
  }

  hr {
    border-bottom: 1px solid ${colorInterfaceLightDown};
    margin: 0px;
    margin-bottom: 8px;
  }
`;

export const ModalContent = styled.div`
  min-height: 200px;
  height: 100%;
  margin-bottom: 24px;

  &.small {
    height: auto;
    min-height: 60px;
  }

  @media ${Devices.tablet} {
    min-height: auto;
    height: auto;
    padding: ${(props) => (props.isCheckout ? '0 16px' : '0')};
  }
  @media ${Devices.mobile} {
    min-height: auto;
    height: auto;
    padding: ${(props) => (props.isCheckout ? '0 16px' : '0')};

    &.automatic-anticipation {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const PlanContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${spacingInlineMd};
  gap: ${spacingInlineXxs};

  .ods-transaction-list-item {
    border: 1px solid ${colorInterfaceLightDown};
    border-radius: ${borderRadiusMd};
  }

  .ods-transaction-list-item__icon {
    display: flex;
    color: ${colorBrandPrimaryDown};
  }

  .ods-transaction-list-item__value {
    display: flex;
    color: ${colorInterfaceDarkDown};
  }
`;

export const HeaderImgContainer = styled.div`
  margin: 80px 0px 24px;
  display: flex;
  justify-content: center;
`;

export const HeaderImgContainerAA = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${spacingStackXs};
`;

export const Title = styled.h1`
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightExtrabold};
  color: ${colorInterfaceDarkDeep};
  line-height: 110%;
  margin: 0 0 24px;

  text-align: inherit;

  &.social-contract-modal-title {
    margin-bottom: 8px;
  }

  &.black {
    color: ${colorInterfaceDarkDeep};
    margin: 0 0 40px;

    &.for-success {
      margin: 0 0 8px;
    }

    .breakLine {
      display: none;
    }

    @media ${Devices.mobile} {
      .breakLine {
        display: block;
      }
    }
  }

  &.center {
    text-align: center;
  }

  &.planSelector {
    margin: 16px 0px 40px 0px;
    font-size: ${fontSizeMd};

    @media ${Devices.tablet} {
      text-align: left;
      font-size: 24px;
      margin: 0 0 16px;
    }
    @media ${Devices.mobile} {
      text-align: left;
      font-size: 24px;
      margin: 0 0 16px;
    }
  }

  &.automatic_anticipation_title {
    padding: 0px;

    @media ${Devices.mobile} {
      text-align: center;
    }
  }

  @media ${Devices.tablet} {
    font-size: 24px;
    margin: 0 0 16px;
    padding: 0 16px;
  }
  @media ${Devices.mobile} {
    font-size: 24px;
    margin: 0 0 16px;
    padding: 0 16px;
  }
`;

export const TitlePage = styled.h2`
  font-family: ${fontFamilyHighlight};
  color: ${colorInterfaceDarkUp};
  font-size: ${fontSizeXs};
  font-weight: 900;
  line-height: 110%;
  margin: 0 0px 16px;

  @media ${Devices.tablet} {
    font-size: 16px;
    margin: 0 0 16px;
    padding: 0px 16px;
  }
  @media ${Devices.mobile} {
    font-size: 16px;
    margin: 0 0 16px;
    padding: 0px 16px;
  }
`;

export const SmallTitle = styled(Title)`
  font-size: 20px;
  color: ${colorBrandPrimaryPure};
`;

export const CaptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;

  & h2 {
    margin-bottom: 0;
  }

  .divider {
    display: block;
    width: 100%;
    min-width: 235px;
    height: 1px;
    margin: 24px auto;
    background: #ebecf5;

    @media ${Devices.tablet} {
      margin: 24px auto 8px auto;
    }
    @media ${Devices.mobile} {
      margin: 24px auto 8px auto;
    }
  }

  @media ${Devices.tablet} {
    margin: 24px auto 8px auto;
    padding: 0 16px;
  }
  @media ${Devices.mobile} {
    margin: 24px auto 8px auto;
    padding: 0 16px;
  }
`;

export const CaptionItem = styled.h2`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  font-weight: 400;
  color: ${colorInterfaceDarkDown};
  line-height: 24px;
  margin-bottom: 16px;
  flex: 1;

  .caption-links {
    display: block;
    margin-top: 16px;
  }

  &.center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Caption = styled.h2`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXs};
  font-weight: 400;
  color: ${colorInterfaceDarkDown};
  line-height: 24px;
  margin-bottom: 24px;
  flex: 1;

  .caption-links {
    display: block;
    margin-top: 16px;
  }

  .multiacquirer-form-label {
    font-size: ${fontSizeXxs};
  }

  &.center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  @media ${Devices.tablet} {
    margin: 0 auto 16px;
    padding: 0 16px;
  }

  @media ${Devices.mobile} {
    margin: 0 auto 16px;
    padding: 0 16px;

    &.automatic_anticipation_center {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
`;

export const Error = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f5456e;
`;

export const CheckboxContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  cursor: pointer;

  .error {
    position: absolute;
    top: 27px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #f5456e;
    display: none;
  }

  &.required {
    .ods-checkbox__checkmark {
      border-color: #f5456e;
    }
    .error {
      display: block;
    }
  }

  a {
    color: #0028ff;
  }
`;

export const CaptionIcon = styled.div`
  width: 24px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin-right: ${spacingInlineXxs};
  font-size: ${fontSizeXxs};
  font-weight: ${fontWeightMedium};
  color: ${colorBrandPrimaryDown};
  background-color: ${colorInterfaceLightUp};
`;

export const FormRadioConfirmation = styled.form`
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  .ods-radio__root {
    margin-bottom: 16px;
  }

  @media ${Devices.tablet} {
    margin-top: 24px;
    padding: 0 16px;
  }
  @media ${Devices.mobile} {
    margin-top: 24px;
    padding: 0 16px;
  }
`;

export const MessageContainer = styled.div`
  background-color: ${colorInterfaceLightUp};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadiusMd};
  padding: 24px;

  @media ${Devices.mobile} {
    padding: 20px;
    margin-bottom: 60px;
  }
`;

export const MessageContent = styled.div`
  display: flex;
  align-items: center;
`;

export const MessageTitle = styled.p`
  font-family: ${fontFamilyHighlight};
  font-size: ${fontSizeSm};
  font-weight: ${fontWeightExtrabold};
  color: ${colorBrandPrimaryDown};
  line-height: 22px;
  margin-bottom: 8px;

  @media ${Devices.mobile} {
    font-size: ${fontSizeXs};
  }
`;

export const MessageText = styled.p`
  font-family: ${fontFamilyBase};
  font-size: ${fontSizeXxs};
  color: ${colorInterfaceDarkDown};
  line-height: 21px;
  margin-bottom: 0;
`;

export const ReminderIconContainer = styled.img`
  margin-right: 16px;
`;

export const ScrollMobile = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  flex-direction: column;

  &.no-footer {
    height: 100%;
  }

  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: initial;
  }
  hr.desktop-only {
    display: block;
  }
  &.social-contract-modal {
    height: auto;
  }

  @media ${Devices.tablet} {
    overflow-y: auto;

    .mobile-only {
      display: initial;
    }
    hr.mobile-only {
      display: block;
    }
    hr.desktop-only {
      display: none;
    }
    .desktop-only {
      display: none;
    }
  }
  @media ${Devices.mobile} {
    overflow-y: auto;

    .mobile-only {
      display: initial;
    }
    hr.mobile-only {
      display: block;
    }
    hr.desktop-only {
      display: none;
    }
    .desktop-only {
      display: none;
    }
  }
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media ${Devices.mobile} {
    flex-direction: column;
    width: 100%;
    height: 80px;
    padding: 16px;
    border: 1px solid ${colorInterfaceLightDown};
    &.no-border {
      border: none;
    }
  }

  @media ${Devices.tablet} {
    flex-direction: column;
    width: 100%;
    height: 80px;
    padding: 16px;
    border-top: 1px solid ${colorInterfaceLightDown};
    &.no-border {
      border: none;
    }
  }
`;

export const FooterText = styled.div`
  padding: 32px 16px;
  font-size: ${fontSizeXxxs};
  color: ${colorInterfaceDarkUp};

  &.desktop {
    display: block;

    @media ${Devices.tablet} {
      display: none;
    }
    @media ${Devices.mobile} {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media ${Devices.tablet} {
      display: block;
      padding: 0 16px;
    }
    @media ${Devices.mobile} {
      display: block;
      padding: 0 16px;
    }
  }
`;

export const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 24px;
`;

export const SuccessFrame = styled.div`
  height: 336px;
  display: flex;
  flex-direction: column;
  margin-bottom: 84px;
  justify-content: center;

  @media ${Devices.tablet} {
    width: 100%;
    height: calc(100vh - 56px);
  }
  @media ${Devices.mobile} {
    width: 100%;
    height: calc(100vh - 56px);
  }
`;

export const BtnsWrap = styled.div`
  padding: 0;
  display: flex;

  button {
    margin-left: 8px;
  }

  &.center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  &.right {
    align-items: right;
    justify-content: right;
  }

  @media ${Devices.tablet} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .ods-btn {
      width: 100% !important;
      margin: 0;
    }
  }

  @media ${Devices.mobile} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .ods-btn {
      width: 100% !important;
      margin: 0;
    }
  }

  &.desktop {
    display: flex;

    @media ${Devices.tablet} {
      display: none;
    }
    @media ${Devices.mobile} {
      display: none;
    }
  }

  &.mobile {
    display: none;

    @media ${Devices.tablet} {
      display: flex;
    }
    @media ${Devices.mobile} {
      display: flex;
    }
  }
`;

export const List = styled.ul`
  li {
    display: flex;
    margin-bottom: 28px;
  }
`;

export const ListContent = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const ListText = styled.p`
  margin-left: 12px;
  margin-bottom: 0;

  font-size: 16px;
  font-family: ${fontFamilyBase};

  b {
    color: ${colorInterfaceDarkDown};
  }

  a {
    color: ${colorBrandPrimaryPure};
    margin-left: 4px;
  }
`;

export const ListIcon = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: ${colorInterfaceLightUp};
  border-radius: 50%;
  color: ${colorBrandPrimaryPure};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const CardSelection = styled.div`
  max-width: 328px;
  width: 100%;
  height: 367px;
  margin: 0 auto;
  border: 1px solid #ebecf5;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 24px;
  text-align: center;

  @media ${Devices.tablet} {
    height: auto;
  }
  @media ${Devices.mobile} {
    height: auto;
  }

  &.primary {
    border: 1px solid #b8c3ff;
    background: #f7f9ff;
  }
`;

export const Card = styled.div`
  margin: 0px 8px;
  width: 327px;

  @media ${Devices.tablet} {
    margin: 8px;
  }
  @media ${Devices.mobile} {
    margin: 8px;
  }
`;

export const CardHead = styled.div`
  h3 {
    margin: 0 0 16px;
    font-size: 14px;
    font-weight: 800;
    font-family: ${fontFamilyBase};
    line-height: 21px;
    color: ${colorInterfaceDarkDown};

    .primary & {
      color: ${colorBrandPrimaryPure};
    }
  }

  h2 {
    margin: 0 0 12px;
    font-size: 24px;
    font-family: ${fontFamilyHighlight};
    font-weight: 800;
    line-height: 26px;
    color: rgba(57, 59, 71, 1);
  }

  h4 {
    height: 42px;
    margin: 0 0 21px;
    font-size: 14px;
    font-family: ${fontFamilyBase};
    line-height: 21px;
    color: ${colorInterfaceDarkDown};
  }

  .breakLine {
    display: block;
  }

  button {
    width: 148px;

    &:hover {
      &:disabled {
        background: #ebecf5;
      }
    }
  }

  @media ${Devices.tablet} {
    h3,
    h2,
    h4 {
      text-align: left;
      margin: 0 0 16px;
    }
    h2 {
      margin: 0 0 8px;
    }
    button {
      width: 100%;
    }
  }
  @media ${Devices.mobile} {
    .breakLine {
      display: none;
    }

    h3,
    h2,
    h4 {
      text-align: left;
      margin: 0 0 16px;
    }
    h2 {
      margin: 0 0 8px;
    }

    button {
      width: 100%;
    }
  }
`;

export const BenefitsWrapper = styled.div`
  > div {
    display: block;
  }

  hr {
    display: block;
    width: 100%;
    min-width: 235px;
    height: 1px;
    margin: 24px auto;
    background: #ebecf5;

    @media ${Devices.tablet} {
      margin: 16px auto 0px auto;
    }
    @media ${Devices.mobile} {
      margin: 16px auto 0px auto;
    }
  }

  @media ${Devices.tablet} {
    margin-top: 16px;
  }
  @media ${Devices.mobile} {
    margin-top: 16px;
  }

  .see-more {
    display: none;
    width: 100%;
    margin-top: 8px;
    min-height: 36px;
    height: 36px;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;

    span {
      font-style: normal;
      font-weight: 800;
      font-size: 14px;
      line-height: 150%;
      color: ${colorBrandPrimaryPure};
    }
    svg {
      height: 7px;
      width: 23px;

      &.inverted {
        transform: rotateX(180deg);
      }
    }
  }

  @media ${Devices.tablet} {
    > div.visible {
      display: block;
    }
    .see-more {
      display: flex;
    }
    > div {
      display: none;
    }
  }
  @media ${Devices.mobile} {
    .see-more {
      display: flex;
    }
    > div.visible {
      display: block;
    }

    > div {
      display: none;
    }
  }
`;

export const BenefitItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 8px;

  .disabled-text {
    color: #b6b9cc;
  }

  svg {
    margin-right: 7px;
  }

  span {
    font-weight: 400;
    font-size: 12px;
    font-family: ${fontFamilyBase};
    line-height: 18px;
    color: ${colorInterfaceDarkDown};
  }
`;

export const WrapAcquirer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid ${colorInterfaceDarkUp};
  padding: 14px;
  border-radius: 8px;
  margin-bottom: 14px;

  .ods-checkbox__root {
    margin-bottom: 0;
  }
`;

export const FormLabel = styled.label`
  font-family: ${fontFamilyBase};
  font-size: 14;
  font-weight: 700;
  color: ${colorInterfaceDarkDown};
  line-height: 16px;
  flex: 1;
`;

export const CustomDividerWrapper = styled.div`
  width: 100%;
  min-height: 10px;
  margin: 8px 0px;
`;

export const CustomRadioColumn = styled(Col)`
  margin-bottom: 8px;
`;

export const CustomRadio = styled(Radio)`
  .ods-radio__root {
    margin-bottom: 16px;
  }
  .ods-checkbox__label {
    font-family: ${fontFamilyBase};
    font-size: 14;
    font-weight: 700;
    color: ${colorInterfaceDarkDown};
    line-height: 16px;
    flex: 1;
  }
`;

export const LightText = styled.h2`
  color: ${colorInterfaceDarkDown};
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: ${fontWeightRegular};
  font-size: ${fontSizeXs};
  line-height: ${lineHeightComfy};
  text-align: center;
  margin-bottom: 24px;
`;

export const MultiAcquirerLabel = styled(Caption)`
  color: ${colorInterfaceDarkDeep};
  font-size: ${fontSizeXxxs};
  margin-bottom: 4px;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    padding-left: 24px;
  }

  @media (max-width: 768px) {
    padding: 0 24px;
    height: 80vh;
    margin-top: 40px;
  }
`;

export const ImageTextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NotFixedFrame = styled(Frame)`
  height: initial;
`;

export const ModalTitlePage = styled(TitlePage)`
  color: ${colorInterfaceDarkDeep};
  font-size: ${fontSizeMd};
  font-weight: ${fontWeightExtrabold};
  text-align: center;
  margin-bottom: 8px;
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 900;
  }
`;

export const MoreInformation = styled.p`
  color: ${colorInterfaceDarkDown};
  width: 100%;
  margin-top: ${spacingInlineXxs};

  a {
    color: ${colorBrandPrimaryDown};
  }
`;

export const PlanSelectorContainer = styled.div`
  padding: 0 ${spacingInlineXs};
`;
