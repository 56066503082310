import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loading from 'react-loading';

import errorReponseImg from 'images/error-icon.png';
import refreshImg from 'images/refresh-icon.png';
import { BoxContent, BoxAction } from './DeviceCard.styles';
import DeviceCardLayout from './DeviceCardLayout';

const CustomBoxContent = styled(BoxContent)`
  h1 {
    font-weight: normal;
    font-size: 20px;
    line-height: 110%;
    color: #67697a;
    margin: 16px 0 16px 0;
  }
`;

const CustomBoxAction = styled(BoxAction)`
  button,
  a {
    font-size: 14px;
  }
  img {
    padding-right: 8px;
  }
`;

const DeviceCardError = ({ action, message, loading }) => {
  const dispatch = useDispatch();

  return (
    <DeviceCardLayout
      iconImg={{ alt: 'Sem Dispositivo', src: errorReponseImg, width: '40px' }}
      isError
    >
      <CustomBoxContent>
        <h1>{message}</h1>
      </CustomBoxContent>

      <CustomBoxAction>
        {loading ? (
          <Loading
            type="spin"
            color="#0025E0"
            delay={0}
            width={24}
            height={24}
          />
        ) : (
          <button
            type="button"
            onClick={() => dispatch(action)}
            aria-label="Recarregar"
          >
            <img alt="Recarregar" src={refreshImg} width="23px" />
            Recarregar
          </button>
        )}
      </CustomBoxAction>
    </DeviceCardLayout>
  );
};

DeviceCardError.propTypes = {
  action: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired
};

export default DeviceCardError;
