import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@useblu/ocean-react';
import { useSelector } from 'react-redux';

import errorImg from 'images/icons/server-error.svg';
import { railsContextSelector } from 'selectors/rails-context';
import {
  HomeBtn,
  Container,
  Paper,
  StatusCode,
  Title,
  ErrorMsg
} from './ErrorScreen.styles';

const ErrorScreen = ({ statusCode, errorMsg }) => {
  const { clientId } = useSelector(railsContextSelector);

  return (
    <Container className="ocean-iso">
      <Paper>
        <StatusCode variant="paragraph">Erro {statusCode}</StatusCode>
        <img
          src={errorImg}
          alt="Uma tela de notebook com erro"
          width="132"
          height="86"
        />
        <div>
          <Title>Ops!</Title>
          <ErrorMsg>{errorMsg}</ErrorMsg>
        </div>
        <HomeBtn
          component="a"
          href={`/lojas/${clientId}/extrato/pendentes`}
          blocked
        >
          Voltar para a home
        </HomeBtn>
      </Paper>
    </Container>
  );
};

ErrorScreen.propTypes = {
  statusCode: PropTypes.number.isRequired,
  errorMsg: PropTypes.string.isRequired
};

export default ErrorScreen;
