import React from 'react';
import PropTypes from 'prop-types';

import Banner from './Banner';
import IcGirl from './images/iconGirl.svg';
import { Container, Content, Title, List, Item, Thumbnail } from './styles';

const BannerArea = ({ tax }) => (
  <>
    <Container data-testid="automatic-anticipation-header">
      <Content className="automatic-anticipation-header">
        <div>
          <Title>Pague Fornecedor pela Blu e receba de volta {tax}% </Title>
          <List>
            <Item>
              <p>Pague seus fornecedores pela BLU.</p>
            </Item>
            <Item>
              <p>Escolha a opção de pagamento - PagBlu.</p>
            </Item>
            <Item>
              <p>
                Ao aprovar a cobrança você receberá de volta todo o valor pago
                da taxa de antecipação, relacionada a esse pagamento.
                <br />
                <br /> Ou seja, se você pagou {tax}% na antecipação automática
                você recebe de volta na sua Conta Digital Blu os {tax}%.
              </p>
            </Item>
          </List>
        </div>

        <div>
          <Thumbnail src={IcGirl} alt="Fornecedor Blu" />
        </div>
      </Content>
    </Container>
    <Banner />
  </>
);

BannerArea.propTypes = {
  tax: PropTypes.string
};

BannerArea.defaultProps = {
  tax: '7,99'
};

export default BannerArea;
