import React from 'react';
import PropTypes from 'prop-types';

import { ExclamationCircleOutline } from '@useblu/ocean-icons-react';

import { colorInterfaceDarkUp } from '@useblu/ocean-tokens/web/tokens';
import FeatureTerms from './FeatureTerms';

import { CaptionItem, CaptionContainer, CaptionIcon } from '../modal.styles';

const FeatureTermsCombo = ({ prevStep, nextStep, loading, header, title }) => (
  <FeatureTerms
    header={header}
    title={title}
    nextClass="3_campanha_aa_combo"
    prevClass="2_campanha_aa_combo"
    link="https://pagnet-download.s3.us-east-2.amazonaws.com/termo_de_consentimento_expresso_para_visualizacao_gestao_e_registro_de_operacao_v2.pdf"
    description={
      <>
        <CaptionContainer>
          <CaptionIcon>
            <ExclamationCircleOutline color={colorInterfaceDarkUp} />
          </CaptionIcon>
          <CaptionItem className="desktop-only">
            <strong>
              O saldo existente na sua Agenda Blu e/ou Agenda outras maquininhas
            </strong>
            , será antecipado automáticamente a uma taxa de 7,99% ao ativar a
            Antecipação Automática Blu.
          </CaptionItem>

          <CaptionItem className="mobile-only">
            O saldo existente na sua{' '}
            <strong>Agenda Blu e/ou Agenda outras maquininhas</strong>, será
            antecipado automáticamente a uma taxa de 7,99%.
          </CaptionItem>
        </CaptionContainer>

        <CaptionContainer>
          <CaptionIcon>
            <ExclamationCircleOutline color={colorInterfaceDarkUp} />
          </CaptionIcon>
          <CaptionItem>
            Você pode desabilitar essa opção a qualquer momento,{' '}
            <b>porém não haverá estorno do que já foi antecipado.</b>
          </CaptionItem>
        </CaptionContainer>

        <CaptionContainer>
          <CaptionIcon>
            <ExclamationCircleOutline color={colorInterfaceDarkUp} />
          </CaptionIcon>
          <CaptionItem>
            Caso mantenha a Antecipação Automática Blu e opte por cancelar a
            Centralização de Recebíveis Blu, a taxa fixa de antecipação passará
            a ser de <b>8,99%</b>.
          </CaptionItem>
        </CaptionContainer>
      </>
    }
    hasPrev
    nextStep={nextStep}
    prevStep={prevStep}
    loading={loading}
  />
);

FeatureTermsCombo.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  header: PropTypes.string,
  title: PropTypes.string
};

FeatureTermsCombo.defaultProps = {
  loading: false,
  header: 'Importante 2 de 2',
  title: 'Informações importantes'
};

export default FeatureTermsCombo;
