import { getTaxPlans, getDepartments } from 'api/sign-up-app';
import { isArray } from 'lodash';
import {
  getBigBoostData,
  getUserBigBoostData,
  getBigBoostDataOnDemand
} from 'api/big-boost';
import { call, put } from 'redux-saga/effects';
import ActionCreators from 'store/ducks/signUp';
import { searchClient } from 'api/clients';
import ValidateCnpj from 'validations/ValidateCnpj';

export function* loadTaxPlans(isPartnerIdNil, id) {
  try {
    const response = yield call(getTaxPlans, isPartnerIdNil, id);
    // eslint-disable-next-line camelcase
    const { tax_plans } = response.data;
    yield put(ActionCreators.signUpLoadTaxPlansSuccess(tax_plans));
  } catch (err) {
    yield put(ActionCreators.signUpLoadTaxPlansFailure());
  }
}

export function* loadDepartments() {
  try {
    const response = yield call(getDepartments);
    const { departments } = response.data;
    yield put(ActionCreators.signUpLoadDepartmentsSuccess(departments));
  } catch (err) {
    yield put(ActionCreators.signUpLoadDepartmentsFailure());
  }
}

export function* loadBigBoost(params) {
  try {
    if (!params.cpfCnpj) {
      return yield put(
        ActionCreators.signUpLoadBigBoostFailure(
          'Você precisa preencher o campo CNPJ para consultar os dados.'
        )
      );
    }

    if (!ValidateCnpj(params.cpfCnpj)) {
      return yield put(
        ActionCreators.signUpLoadBigBoostFailure('CNPJ inválido.')
      );
    }

    const clientSearch = yield call(searchClient, params.cpfCnpj);
    const clientExists = clientSearch.data !== '';

    if (clientExists) {
      return yield put(
        ActionCreators.signUpLoadBigBoostFailure('O CNPJ já está cadastrado.')
      );
    }

    const { data: responseData } = yield call(getBigBoostData, params);
    const { bigBoostData } = responseData?.data;

    if (
      bigBoostData?.basicData?.status === 'ATIVA' &&
      bigBoostData?.qsa?.length > 0
    ) {
      return yield put(ActionCreators.signUpLoadBigBoostSuccess(bigBoostData));
    }

    const { data: responseOnDemand } = yield call(
      getBigBoostDataOnDemand,
      params.cpfCnpj
    );

    if (!responseOnDemand?.data) {
      return yield put(
        ActionCreators.signUpLoadBigBoostFailure(
          'Api de dados da Receita Federal não respondeu, tente novamente.'
        )
      );
    }

    const { bigBoostOnDemandData } = responseOnDemand?.data;

    if (
      bigBoostOnDemandData?.status?.status === 'ATIVA' &&
      bigBoostOnDemandData?.qsa?.length > 0
    ) {
      return yield put(
        ActionCreators.signUpLoadBigBoostSuccess({
          ...bigBoostOnDemandData,
          basicData: {
            ...bigBoostOnDemandData,
            ...bigBoostOnDemandData.status
          }
        })
      );
    }

    yield put(
      ActionCreators.signUpLoadBigBoostFailure(
        'O CNPJ pesquisado não se encontra ativo ou não possui quadro societario na Receita Federal.'
      )
    );
  } catch (err) {
    yield put(ActionCreators.signUpLoadBigBoostFailure(err));
  }
}

export function* loadBigBoostUserData(params) {
  const cpf = params.data?.qsa[0]?.cpfCnpj || params?.cpf;

  try {
    if (cpf) {
      const userData = yield call(getUserBigBoostData, cpf);

      const { bigBoostIndividualBasicData } = userData.data.data;

      if (bigBoostIndividualBasicData) {
        yield put(
          ActionCreators.signUpLoadUserBigBoostSuccess(
            bigBoostIndividualBasicData
          )
        );
      }
    }
  } catch (err) {
    yield put(ActionCreators.signUpLoadUserBigBoostFailure(err));
  }
}
