import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { normalizeCurrency } from 'normalizers';
import AdvancementRequestCreators from 'store/ducks/advancementRequest';
import useAdvancementDetailsDrawer from 'components/Shared/AdvancementDetailsDrawer/hooks/useAdvancementDetailsDrawer';

const AdvancementDetailsComponent = ({
  advancementRequestIds,
  clientUuid,
  tracking,
  transactionUUID,
}) => {
  const { openDrawer } = useAdvancementDetailsDrawer();
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.advancementRequest);

  useEffect(() => {
    dispatch(
      AdvancementRequestCreators.advancementRequestRequest(
        advancementRequestIds,
        clientUuid
      )
    );
  }, [dispatch, advancementRequestIds, clientUuid]);

  if (data.advancementRateValue)
    return (
      <div className="list__item list__item-sp-between statement-detail__installment">
        <div className="list__item-left">
          <p>
            <b>Antecipação de recebíveis</b>
          </p>
          <p>{normalizeCurrency(data.advancementRateValue)}</p>
        </div>
        {data.advancementItems && data.advancementItems.length > 0 && (
          <>
            <div className="list__item-right" style={{ alignItems: 'center' }}>
              <button
                className="detail__toggle"
                type="button"
                data-track={tracking}
                onClick={() => openDrawer(transactionUUID)}
              >
                ver detalhes
              </button>
            </div>
          </>
        )}
      </div>
    );

  return null;
};

AdvancementDetailsComponent.propTypes = {
  clientUuid: PropTypes.string.isRequired,
  tracking: PropTypes.string.isRequired,
  advancementRequestIds: PropTypes.array.isRequired,
  transactionUUID: PropTypes.string.isRequired,
};

export default AdvancementDetailsComponent;
