import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as S from '../TransactionAuthorizationDoneStyles';
import TransactionListItem from '../TransactionListItem';

function TransactionList({ showTransactions, batchPayment }) {
  const [listContentHeight, setListContentHeight] = useState(0);

  const handleCalculateHeight = () => {
    setTimeout(() => {
      const height =
        document.getElementById('transaction-list')?.scrollHeight || 0;
      setListContentHeight(height);
    }, 1);
  };

  useEffect(() => {
    handleCalculateHeight();
  }, [batchPayment]);

  return (
    <S.TransactionListContent
      id="transaction-list"
      $active={showTransactions}
      $height={listContentHeight}
      $autoHeight={batchPayment?.client_transactions?.length > 1}
    >
      {batchPayment?.client_transactions?.map((transaction, key) => (
        <TransactionListItem
          key={transaction.uuid}
          transaction={transaction}
          lastChild={key + 1 === batchPayment?.client_transactions?.length}
        />
      ))}
    </S.TransactionListContent>
  );
}

TransactionList.propTypes = {
  showTransactions: PropTypes.bool.isRequired,
  batchPayment: PropTypes.object.isRequired,
};

export default TransactionList;
