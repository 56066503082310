const genericData = (clientId, underAnalysis) => ({
  title: 'Você ainda não liberou sua conta',
  description:
    'Confirme algumas informações e aproveite todos os benefícios da sua Conta Digital Blu.',
  buttonDescription: underAnalysis ? 'Fechar' : 'Liberar funções da conta',
  buttonLink: underAnalysis ? `` : `/lojas/${clientId}/completude-cadastral`,
});

// eslint-disable-next-line import/prefer-default-export
export const modalContentByStatus = ({ clientId }) => ({
  blu_light_client_need_regularize_CNPJ: {
    title: 'Regularize seu CNPJ',
    description:
      'Para completar a ativação do Pagamento automático de boletos em atraso, você precisa regularizar a situação do seu CNPJ junto à Receita Federal.',
    buttonDescription: 'Entendi',
  },
  blu_light_pending_legal_representative_invite: genericData(clientId, true),
  blu_light_social_contract_reproved: genericData(clientId),
  client_need_regularize_CNPJ: genericData(clientId),
  client_under_analysis: genericData(clientId, true),
  pending_legal_representative_invite: genericData(clientId, true),
  social_contract_reproved: genericData(clientId),

  pending_social_contract: genericData(clientId),
  blu_light_pending_social_contract: genericData(clientId),
  pending_social_contract_or_invite_legal_representative: genericData(clientId),
  blu_light_pending_social_contract_or_invite_legal_representative:
    genericData(clientId),

  social_contract_under_analysis: genericData(clientId, true),
  blu_light_social_contract_under_analysis: genericData(clientId, true),
  social_contract_under_analysis_or_invite_legal_representative: genericData(
    clientId,
    true
  ),
  blu_light_social_contract_under_analysis_or_invite_legal_representative:
    genericData(clientId, true),
});
