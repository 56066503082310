import React, { useState, useEffect } from 'react';

import {
  XOutline,
  NewspaperOutline,
  PencilOutline
} from '@useblu/ocean-icons-react';

import { TopBar, Button, Typography, IconButton } from '@useblu/ocean-react';
import { railsContextSelector } from 'selectors/rails-context';

import { connect, useSelector } from 'react-redux';

import {
  getClientTaxes,
  getAtecipationTax
} from '../../../../api/contract-form';

import TaxPlans from '../components/TaxPlans';
import SVGSuccess from '../../../Devise/images/illustration-success.svg';

import * as S from './styles';

const Confirmation = () => {
  const { clientId } = useSelector(railsContextSelector);
  const [clientTaxTable, setClientTaxTable] = useState();
  const [antecipationTaxes, setAntecipationTaxes] = useState();

  function getTaxes() {
    getClientTaxes(clientId)
      .then((response) => {
        setClientTaxTable(response.data);
      })
      .catch((error) => {
        console.log(error);
        throw new Error();
      });

    getAtecipationTax(clientId)
      .then((response) => {
        setAntecipationTaxes(response.data.data.client.taxPlan);
      })
      .catch((error) => {
        console.log(error);

        throw new Error();
      });
  }

  useEffect(() => {
    getTaxes();
  }, []);

  return (
    location.href.split('?')[1] === 'advanced=finalizado' && (
      <S.Container className="ocean-iso">
        <S.Bar>
          <TopBar
            id="TopBar-playground"
            variants="default"
            leftIcon={<XOutline />}
            title="Assinatura do contrato"
            onLeftAction={() => {
              window.location.href = `/`;
            }}
          />
        </S.Bar>

        <S.Wrapper>
          <img src={SVGSuccess} alt="Sucesso" />
          <S.Title>Muito bom, o contrato foi assinado.</S.Title>
          <Typography style={{ marginBottom: '10px' }} variant="paragraph">
            Você concluiu as últimas tarefas referentes ao cadastro na Blu.
            Relembre as etapas:
          </Typography>
          <S.TaxPlansList>
            <S.List>
              <IconButton>
                <NewspaperOutline size="20" />
              </IconButton>

              <Typography variant="paragraph">
                Confirmação dos dados pessoais e da empresa para geração do
                contrato e atualização cadastral.
              </Typography>
            </S.List>
            <S.List last>
              <IconButton>
                <PencilOutline size="20" />
              </IconButton>
              <Typography variant="paragraph">
                Assinatura do contrato. Você pode baixar e salvar seu documento
                <S.Button
                  className="atv_etapa_last_botaovercontrato"
                  onClick={() => {
                    window.location.href = `/lojas/${clientId}/contrato/blu.pdf`;
                  }}
                  type="button"
                >
                  clicando aqui.
                </S.Button>
              </Typography>
            </S.List>
            <Typography variant="description">
              Aproveite para conferir a tabela de taxas acordadas:
            </Typography>
            <TaxPlans
              title="Taxas e Valores"
              taxTable={clientTaxTable || {}}
              antecipationTaxes={antecipationTaxes || {}}
            />
          </S.TaxPlansList>
          <S.ButtonWrapper>
            <Button
              size="md"
              className="atv_etapa_last_botaoacessarconta"
              onClick={() => {
                window.location.href = '/';
              }}
              type="button"
            >
              Acessar minha Conta Digital Blu
            </Button>
          </S.ButtonWrapper>
        </S.Wrapper>
      </S.Container>
    )
  );
};

export default connect(Confirmation);
