import React from 'react';

import bluSmile from './images/bluSmile.svg';
import bluLogo from './images/bluLogo.svg';

import { Container, Slogan, Highlight, Text, Img, LogoBlu } from './styles';

const Footer = () => {
  return (
    <Container data-testid="automatic-anticipation-footer">
      <Slogan>
        <Highlight>Blu.</Highlight> Conectando o varejo à indústria.
      </Slogan>

      <Img src={bluSmile} alt="" />

      <LogoBlu src={bluLogo} alt="" />
      <Text>© 2020 Blu. Todos os direitos reservados.</Text>
    </Container>
  );
};

export default Footer;
