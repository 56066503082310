import styled from 'styled-components';

export const Wrapper = styled.div`
  align-self: center;
  max-width: 542px;
`;

export const Table = styled.table`
  th,
  td {
    font-size: 1em;
    padding: 8px;
  }

  th {
    background-color: #f6f8fb;
    border: 0;
  }

  thead {
    display: table-header-group;
  }
`;
