const initialState = {
  items: [],
  loading: false,
  dirty: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'HISTORY_LOAD':
      return {
        ...state,
        loading: true
      };
    case 'HISTORY_LOADED':
      return {
        ...state,
        loading: false,
        items: action.items,
        dirty: true
      };
    default:
      return state;
  }
}
