import styled from 'styled-components';
import {
  colorInterfaceDarkDown,
  colorInterfaceDarkPure,
  fontSizeXs,
  fontWeightRegular
} from '@useblu/ocean-tokens/web/tokens';

export const InfoDescription = styled.span`
  color: ${(props) =>
    props.value ? colorInterfaceDarkPure : colorInterfaceDarkDown};
  font-size: ${fontSizeXs};
  font-weight: ${(props) => (props.value ? fontWeightRegular : 'normal')};
`;
