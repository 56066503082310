/* Core */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
/* Presentational */
import Loading from 'react-loading';

import transactionsByPeriod from '../../api/transactions-by-period';

import TransactionRecords from './TransactionRecords';
import PeriodFilters from './PeriodFilters';

const PeriodTypes = {
  DayPeriodDateFilter: { name: 'diário', link: 'diario', group: 'day' },
  MonthPeriodDateFilter: { name: 'mensal', link: 'mensal', group: 'month' },
  PeriodDatePicker: { name: 'período', link: 'periodo', group: 'day' }
};

const PeriodStatement = ({ periodStatementTransactions, uuid }) => {
  const [isLoading, setLoading] = React.useState(false);
  const [periodGroup, setPeriodGroup] = React.useState('PeriodDatePicker');
  const [periodFilter, setPeriodFilter] = React.useState({});
  const [transactionsList, setTransactionsList] = React.useState(
    periodStatementTransactions
  );

  React.useEffect(() => {
    const fetch = async (filter) => {
      const list = await transactionsByPeriod(filter, uuid);
      setLoading(false);
      setTransactionsList(list);
    };

    if (!isEmpty(periodFilter)) {
      setLoading(true);
      fetch(periodFilter);
    }
  }, [periodFilter, uuid]);

  const onChangeFilter = (filter) => {
    if (!isEqual(filter, periodFilter)) {
      setPeriodFilter(filter);
    }
  };

  const btnClasses = (isFilterSelected) =>
    classNames({
      'button button--secondary button--inverted': true,
      'button button--secondary button--inverted active': isFilterSelected
    });

  return (
    <>
      <h1 className="page-title page-title-color">Análise por período</h1>
      <div className="period-tabs">
        <div className="container">
          <div className="flex-container is-padded">
            <nav className="period-tabs__navigation button--group">
              {Object.keys(PeriodTypes).map((key) => {
                const { name } = PeriodTypes[key];

                return (
                  <button
                    key={key}
                    type="button"
                    onClick={() => {
                      setPeriodGroup(key);
                    }}
                    className={btnClasses(key === periodGroup)}
                  >
                    {name}
                  </button>
                );
              })}
            </nav>
          </div>
        </div>
      </div>

      <PeriodFilters type={periodGroup} onChange={onChangeFilter} />

      {isLoading && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Loading type="spinningBubbles" color="#e1e5ed" delay={0} />
        </div>
      )}

      {!isLoading && (
        <div
          className="flex-container is-padded-statement"
          id="period-statement-container"
        >
          <div className="list__wrapper account-statement__list account-statement-confirmed-transactions">
            {!isEmpty(transactionsList) &&
              transactionsList.map(({ records, released_at: releasedAt }) => (
                <TransactionRecords
                  key={releasedAt}
                  releasedAt={releasedAt}
                  records={records}
                  group={PeriodTypes[periodGroup].group}
                />
              ))}

            {isEmpty(transactionsList) && (
              <div className="list__item-blank">
                Nenhuma transação neste período.
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

PeriodStatement.propTypes = {
  uuid: PropTypes.string.isRequired,
  periodStatementTransactions: PropTypes.array.isRequired
};

export default PeriodStatement;
