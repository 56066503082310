import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const LogoNav = styled.li`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  position: relative;
  text-align: center;

  a {
    height: 104px;
    width: 100%;
    padding: 24px 36px;
  }
`;

const Logo = ({ link }) => (
  <LogoNav>
    <a href={link}>
      <svg
        alt="Blu"
        width="24"
        height="32"
        viewBox="0 0 24 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.6461 14.9369C20.9237 13.7475 21.8095 12.2079 22.1888 10.5175C22.5682 8.82698 22.4236 7.06333 21.774 5.4546C21.1243 3.84587 19.9993 2.46606 18.5446 1.49364C17.0898 0.521211 15.3722 0.000901518 13.6139 0H3.759C2.7623 0.00124508 1.8068 0.391689 1.1021 1.08568C0.397402 1.77968 0.00105346 2.72055 0 3.7019V28.2981C0.00105346 29.2795 0.397402 30.2203 1.1021 30.9143C1.8068 31.6083 2.7623 31.9988 3.759 32H14.6036C16.6379 31.9993 18.617 31.3489 20.2439 30.1464C21.8707 28.9438 23.0574 27.2541 23.6257 25.3309C24.194 23.4077 24.1134 21.3549 23.3958 19.4807C22.6782 17.6065 21.3625 16.0121 19.6461 14.9369ZM14.6036 29.4098H3.759C3.45975 29.4094 3.1729 29.2921 2.96138 29.0837C2.74986 28.8753 2.63095 28.5927 2.63074 28.2981V3.7019C2.632 3.40794 2.75138 3.12643 2.96278 2.91887C3.17419 2.7113 3.46044 2.59456 3.759 2.59415H13.6139C14.9104 2.59403 16.1738 2.99728 17.2236 3.7463C18.2734 4.49533 19.0561 5.55182 19.4597 6.7649C19.8633 7.97798 19.8673 9.28562 19.4711 10.5011C19.0749 11.7165 18.2987 12.7776 17.2535 13.5329V16.6248C18.685 17.2288 19.8612 18.3003 20.5837 19.6588C21.3062 21.0173 21.5307 22.5795 21.2193 24.082C20.908 25.5845 20.0799 26.9353 18.8748 27.9066C17.6696 28.8778 16.1611 29.4101 14.6036 29.4137V29.4098Z"
          fill="white"
        />
        <path
          d="M13.1611 9.20775C13.1611 8.81201 13.0546 8.42333 12.8526 8.08125C12.6505 7.73917 12.36 7.45589 12.0106 7.26023C11.6612 7.06456 11.2655 6.96349 10.8636 6.96731C10.4617 6.97112 10.0679 7.07968 9.72246 7.28193C9.37699 7.48419 9.09209 7.77292 8.89673 8.11878C8.70138 8.46463 8.60255 8.85526 8.61029 9.25092C8.61802 9.64659 8.73206 10.0332 8.94079 10.3714C9.14953 10.7096 9.44551 10.9873 9.79863 11.1763V20.1551C9.35412 20.393 9.00282 20.77 8.80024 21.2265C8.59766 21.6829 8.55536 22.1929 8.68003 22.6757C8.80469 23.1585 9.08921 23.5866 9.48862 23.8924C9.88803 24.1981 10.3795 24.3642 10.8855 24.3642C11.3914 24.3642 11.8829 24.1981 12.2824 23.8924C12.6818 23.5866 12.9663 23.1585 13.0909 22.6757C13.2156 22.1929 13.1733 21.6829 12.9707 21.2265C12.7682 20.77 12.4168 20.393 11.9723 20.1551V11.1763C12.3319 10.9837 12.6322 10.6992 12.8414 10.3528C13.0506 10.0064 13.161 9.6108 13.1611 9.20775Z"
          fill="white"
        />
      </svg>
    </a>
  </LogoNav>
);

Logo.propTypes = {
  link: PropTypes.string.isRequired
};

export default Logo;
