import {
  spacingStackSm,
  spacingStackXs,
  spacingStackXxs,
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const LivenessModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

export const LivenessImage = styled.div`
  & > img {
    width: 180px;
    margin-bottom: ${spacingStackSm};
  }
`;

export const LivenessContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingStackXxs};
`;

export const LivenessActions = styled.div`
  width: 100%;
  display: flex;
  padding: ${spacingStackXs} 0 0;
  margin-top: ${spacingStackXs};
  justify-content: center;
  flex-direction: column;
`;
