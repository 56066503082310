import React from 'react';
import PropTypes from 'prop-types';

import FeatureTerms from './FeatureTerms';

import { CaptionItem, CaptionContainer, CaptionIcon } from '../modal.styles';

const FeatureTermsAutomaticAntecipation = ({
  prevStep,
  nextStep,
  loading,
  header
}) => (
  <FeatureTerms
    header={header}
    nextClass="2_campanha_aa__taxa1"
    prevClass="_campanha_aa__taxa1"
    link="https://pagnet-download.s3.us-east-2.amazonaws.com/termo_de_adesao_v2.pdf"
    title="Informações importantes"
    description={
      <>
        <CaptionContainer>
          <CaptionIcon>1</CaptionIcon>
          <CaptionItem>
            Você vai receber cashback ao pagar os seus fornecedores através do
            PagBlu.
          </CaptionItem>
        </CaptionContainer>
        <CaptionContainer>
          <CaptionIcon>2</CaptionIcon>
          <CaptionItem>
            Ao ativar o produto, todo o saldo existente na sua Agenda Blu será
            antecipado automaticamente e passará para o Saldo Atual.
          </CaptionItem>
        </CaptionContainer>
        <CaptionContainer>
          <CaptionIcon>3</CaptionIcon>
          <CaptionItem>
            Será possível desabilitar essa opção a qualquer momento, no entanto,
            não haverá estorno do que já foi antecipado.
          </CaptionItem>
        </CaptionContainer>
      </>
    }
    nextStep={nextStep}
    prevStep={prevStep}
    loading={loading}
    hasPrev
  />
);

FeatureTermsAutomaticAntecipation.propTypes = {
  nextStep: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  header: PropTypes.object
};

FeatureTermsAutomaticAntecipation.defaultProps = {
  loading: false,
  header: 'Importante'
};

export default FeatureTermsAutomaticAntecipation;
