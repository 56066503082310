import React from 'react';
import PropTypes from 'prop-types';

import { Steps } from '@useblu/ocean-react';

import { Container, StepWrapper, Title, Subtitle } from './styles';

const PageTitle = ({ title, subtitle, step }) => (
  <Container>
    <StepWrapper>
      <Steps currentStep={step} steps={3} />
    </StepWrapper>

    <Title isMargin={subtitle !== ''}>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
  </Container>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  step: PropTypes.number
};

PageTitle.defaultProps = {
  subtitle: '',
  step: 1
};

export default PageTitle;
