import { useEffect, useReducer, useCallback, useMemo } from 'react';
import { useAsync, useAsyncFn, useInterval } from 'react-use';
import { map, orderBy } from 'lodash';

import { uploadCnab, getCnabsPerPage, getCnabsByIds } from './service';
import reducer, { initialState, actions } from './reducer';

const useAsyncCNAB = (clientCpfCnpj) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const cnabList = useMemo(
    () => orderBy(map(state.byId), ['id'], ['desc']),
    [state.byId]
  );
  const loadMore = useCallback(() => {
    dispatch(actions.goNextPage());
  }, []);

  const [uploadCnabState, uploadCnabRequest] = useAsyncFn(uploadCnab);
  const [getCnabsByIdState, getCnabsByIdRequest] = useAsyncFn(getCnabsByIds);
  const getCnabsPerPageState = useAsync(
    () => getCnabsPerPage(state.currentPage, clientCpfCnpj),
    [state.currentPage]
  );

  useEffect(() => {
    dispatch(actions.addMultipleCNAB(getCnabsPerPageState.value, true));
  }, [getCnabsPerPageState.value]);

  useEffect(() => {
    dispatch(actions.addSingleCNAB(uploadCnabState.value));
  }, [uploadCnabState.value]);

  useEffect(() => {
    dispatch(actions.addMultipleCNAB(getCnabsByIdState.value, false));
  }, [getCnabsByIdState.value]);

  useInterval(
    () => {
      getCnabsByIdRequest(state.nonProcessedIds.toString(), clientCpfCnpj);
    },
    state.nonProcessedIds.length ? 5000 : null
  );

  return {
    cnabList,
    hasMore: state.hasMore,
    loadMore,
    uploadCnabState,
    uploadCnabRequest,
    getCnabsPerPageState
  };
};

export default useAsyncCNAB;
