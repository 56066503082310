import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types, Creators } = createActions({
  firstAccessClientRequest: ['uuid', 'userId'],
  firstAccessClientSuccess: ['data'],
  firstAccessClientFailure: null,
  firstAccessUsersRequest: ['uuid'],
  firstAccessUsersSuccess: ['data'],
  firstAccessUsersFailure: null,
  firstAccessBankOptionsRequest: null,
  firstAccessBankOptionsSuccess: ['data'],
  firstAccessBankOptionsFailure: null,
  firstAccessCurrentStep: ['step']
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  bankOptions: [],
  bankOptionsLoading: false,
  bankOptionsError: false,
  client: {},
  users: {},
  currentStep: ''
};

/* Reducers */

export const bankRequest = (state) => ({
  ...state,
  bankOptionsLoading: true
});

export const bankSuccess = (state, action) => ({
  ...state,
  bankOptions: action.data,
  bankOptionsLoading: false,
  bankOptionsError: false
});

export const bankFailure = (state) => ({
  ...state,
  bankOptionsLoading: false,
  bankOptionsError: true
});

export const clientRequest = (state) => ({
  ...state,
  client: {
    ...state.client,
    loading: true
  }
});

export const clientSuccess = (state, action) => ({
  ...state,
  client: {
    ...state.client,
    data: action.data,
    loading: false,
    error: false
  }
});

export const clientFailure = (state) => ({
  ...state,
  client: {
    ...state.client,
    loading: false,
    error: true
  }
});

export const usersRequest = (state) => ({
  ...state,
  users: {
    ...state.users,
    loading: true
  }
});

export const usersSuccess = (state, action) => ({
  ...state,
  users: {
    ...state.users,
    data: action.data,
    loading: false,
    error: false
  }
});

export const usersFailure = (state) => ({
  ...state,
  users: {
    ...state.users,
    loading: false,
    error: true
  }
});

export const currentStep = (state, action) => ({
  ...state,
  currentStep: action.step
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRST_ACCESS_CLIENT_REQUEST]: clientRequest,
  [Types.FIRST_ACCESS_CLIENT_SUCCESS]: clientSuccess,
  [Types.FIRST_ACCESS_CLIENT_FAILURE]: clientFailure,
  [Types.FIRST_ACCESS_USERS_REQUEST]: usersRequest,
  [Types.FIRST_ACCESS_USERS_SUCCESS]: usersSuccess,
  [Types.FIRST_ACCESS_USERS_FAILURE]: usersFailure,
  [Types.FIRST_ACCESS_BANK_OPTIONS_REQUEST]: bankRequest,
  [Types.FIRST_ACCESS_BANK_OPTIONS_SUCCESS]: bankSuccess,
  [Types.FIRST_ACCESS_BANK_OPTIONS_FAILURE]: bankFailure,
  [Types.FIRST_ACCESS_CURRENT_STEP]: currentStep
});
