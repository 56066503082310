import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ClicksignContractStepTwo extends React.Component {
  componentDidMount() {
    this.loadClickSign();
  }

  loadClickSign() {
    const { uuid, signer, contractLoteKey, railsContext } = this.props;
    const urlClicksign =
      railsContext.env !== 'production'
        ? 'https://widget.clicksign-demo.com'
        : 'https://widget.clicksign.com';

    // eslint-disable-next-line no-undef
    clicksign.create('signature-box', contractLoteKey, {
      signer,
      endpoint: urlClicksign,
      version: '2',
      width: '100%',
      height: '550px',
      colors: {
        main_color: '#33c4d6',
        header_color: '#33c4d6'
      },
      callbacks: [
        (event) => {
          if (event.data === 'signed') {
            $.cookie('skip_contract_blu_arbi', true, { expires: 1, path: '/' });
            window.location = `/lojas/${uuid}/dashboard`;
          }
        }
      ]
    });
  }

  render() {
    return (
      <div className="clicksign-container is-padded">
        <div id="signature-box" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  railsContext: state.railsContext
});

ClicksignContractStepTwo.propTypes = {
  uuid: PropTypes.string.isRequired,
  signer: PropTypes.object.isRequired,
  contractLoteKey: PropTypes.string.isRequired,
  railsContext: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(ClicksignContractStepTwo);
