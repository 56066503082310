import { useDispatch, useSelector } from 'react-redux';
import ModalUserInfoActions from 'store/ducks/ComplementaryUserInfo';

export default function useModalBaseManagement() {
  const isModalComplementaryUserInfOpened = useSelector(
    (state) => state.complementaryUserInfoModal?.modalIsOpen
  );

  const dispatch = useDispatch();

  function openModal(flavor) {
    switch (flavor) {
      default:
        dispatch(ModalUserInfoActions.openModalComplementaryUserInfo());
    }
  }

  function closeModal(flavor) {
    switch (flavor) {
      default:
        dispatch(ModalUserInfoActions.closeModalComplementaryUserInfo());
    }
  }

  return {
    isModalComplementaryUserInfOpened,
    openModal,
    closeModal
  };
}
