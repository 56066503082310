import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { railsContextSelector } from 'selectors/rails-context';

const AccountStatementLink = ({ clientUUID, uuid, releasedAt }) => {
  const { currentTime } = useSelector(railsContextSelector);

  if (
    moment(releasedAt, 'YYYY-MM-DD').diff(
      moment(currentTime, 'YYYY-MM-DD'),
      'days'
    ) > 0
  ) {
    return (
      <a href={`/lojas/${clientUUID}/extrato/agenda/recebiveis/${releasedAt}`}>
        detalhes
      </a>
    );
  }

  return (
    <a
      href={`/lojas/${clientUUID}/transacoes/${uuid}?financial_statement=true`}
    >
      detalhes
    </a>
  );
};

AccountStatementLink.propTypes = {
  clientUUID: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  releasedAt: PropTypes.string.isRequired
};

export default AccountStatementLink;
