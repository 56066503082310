import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  getPaymentListRequest: ['uuid'],
  getPaymentListSuccess: ['data', 'dataCount', 'dataTotal'],
  getPaymentListFailure: null
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: false
};

export const request = (state) => ({
  ...state,
  loading: true,
  nameUpdated: false
});

export const success = (state, action) => ({
  ...state,
  data: action.data,
  dataCount: action.dataCount,
  dataTotal: action.dataTotal,
  loading: false
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PAYMENT_LIST_REQUEST]: request,
  [Types.GET_PAYMENT_LIST_SUCCESS]: success,
  [Types.GET_PAYMENT_LIST_FAILURE]: failure
});
