import styled, { createGlobalStyle } from 'styled-components';

import { Devices } from 'utils/getDeviceMedia';

export const GlobalStyled = createGlobalStyle`
  & .ocean-iso {
    & .ods-modal__content--sm {
      max-width: 460px;
    }

    & .ods-modal__body {
      padding: 0 32px 32px;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  & > .ods-btn--primary {
    width: 100%;
  }

  @media ${Devices.mobile} {
    margin-top: 24px;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & img {
    width: 83px;
    height: 83px;
  }

  & .ods-typography {
    text-align: center;
  }
`;

export const TextContent = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;
