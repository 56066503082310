import styled from 'styled-components';
import { colorInterfaceLightPure } from '@useblu/ocean-tokens/web/tokens';
import { Grid } from '@useblu/ocean-react';
import { Row } from './Styles';

const devices = {
  mobile: `(max-width: 768px)`,
  notebook: `(min-width: 769px)`,
  tablet: `(max-width: 1024px)`
};

export const GridContainer = styled(Grid.Container)`
  &&& {
    @media ${devices.mobile} {
      display: flex;
      flex: 1;

      .ods-col-12 {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const BTokenContainer = styled.div`
  @media ${devices.mobile} {
    background-color: ${colorInterfaceLightPure};
    left: 0;
    min-height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    flex-direction: column;
  }
`;

export const Header = styled(Row)`
  justify-content: space-between;
  padding-bottom: 40px;
  padding-top: 40px;
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #b6b9cc;
  }
  h1 {
    margin: 0;
    padding: 4px 0 24px 0;
    font-weight: 900;
    font-size: 24px;
    line-height: 110%;
  }
`;

export const HeaderDescription = styled.div`
  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #67697a;
  }
`;
