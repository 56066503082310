import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DatabaseOutline,
  ReceiptTaxOutline,
  ThumbUpOutline,
} from '@useblu/ocean-icons-react';
import { Grid } from '@useblu/ocean-react';
import Header from './Header';
import Card from './Card';
import CallToAction from './CallToAction';
import HowItWorks from './HowItWorks';
import DidYouKnow from './DidYouKnow';

const LandingPage = ({ currentClientTaxPlanRate, currentClientAAStatus }) => {
  const clientId = useSelector(
    (state) => state.railsContext?.currentClientDepartmentId
  );
  const clientUuId = useSelector((state) => state.railsContext?.clientId);
  const taxPlanRate = currentClientTaxPlanRate.replace('.', ',');
  const billetsUrl = `https://comunidade.blu.com.br/post/pague-seus-boletos-na-blu?utm_source=lp&utm_medium=banner&utm_campaign=lp-pagblu&utm_content=portal&uid=${clientUuId}`;

  return (
    <div className="ocean-iso">
      <Header clientAA={currentClientAAStatus} clientTax={taxPlanRate} />
      <Grid.Container>
        <Grid.Row>
          {!currentClientAAStatus && (
            <Card
              xs="12"
              sm="12"
              md="4"
              title="Taxa Zero"
              subtitle="boleto fácil"
              fullstory="lp_botao1_campanha_solucoesdepagamento"
              icon={<ReceiptTaxOutline />}
              url={billetsUrl}
            >
              A Blu zera a sua taxa de antecipação para pagar fornecedores
              parceiros.
            </Card>
          )}

          <Card
            xs="12"
            sm="12"
            md={currentClientAAStatus ? '6' : '4'}
            title={currentClientAAStatus ? `Mais desconto` : `Mais economia`}
            subtitle="boleto fácil"
            fullstory="lp_botao1_campanha_solucoesdepagamento"
            icon={<DatabaseOutline />}
            url={billetsUrl}
          >
            {currentClientAAStatus
              ? `Ganhe ${taxPlanRate}% de desconto adicional ao pagar fornecedores parceiros.`
              : 'Economize até 10% ao deixar de pagar taxa de antecipação.'}
          </Card>

          <Card
            xs="12"
            sm="12"
            md={currentClientAAStatus ? '6' : '4'}
            title="Mais crédito"
            subtitle="boleto fácil"
            fullstory="lp_botao1_campanha_solucoesdepagamento"
            icon={<ThumbUpOutline />}
            url={billetsUrl}
          >
            Milhares de fornecedores para você comprar sem análise ou limite de
            crédito.
          </Card>
        </Grid.Row>

        <Grid.Row>
          <HowItWorks
            title="Como funciona?"
            clientAA={currentClientAAStatus}
            clientTax={taxPlanRate}
          />
        </Grid.Row>

        {!currentClientAAStatus && (
          <Grid.Row>
            <DidYouKnow />
          </Grid.Row>
        )}
      </Grid.Container>
      <CallToAction clientId={clientId} clientAA={currentClientAAStatus} />
    </div>
  );
};

LandingPage.propTypes = {
  currentClientTaxPlanRate: PropTypes.string.isRequired,
  currentClientAAStatus: PropTypes.bool.isRequired,
};

export default LandingPage;
