import React from 'react';
import PropTypes from 'prop-types';

import Accordion from 'components/Shared/Accordion';
import { WrapperAccordion } from './styles';

const Faq = ({ faqItems }) => (
  <WrapperAccordion>
    {faqItems.map((faqItem) => (
      <Accordion
        key={`accordion-${faqItem.id}`}
        {...faqItem}
        description={<p>{faqItem.description}</p>}
      />
    ))}
  </WrapperAccordion>
);

Faq.defaultProps = {
  faqItems: []
};

Faq.propTypes = {
  faqItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
    })
  )
};

export default Faq;
