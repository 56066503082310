import styled from 'styled-components';

import {
  colorStatusNegativePure,
  colorInterfaceLightDown,
  colorInterfaceLightPure,
  colorInterfaceDarkDeep,
  colorInterfaceDarkUp,
  shadowLevel1,
  fontWeightRegular,
  fontWeightBold,
  colorBrandPrimaryPure,
  fontFamilyBase
} from '@useblu/ocean-tokens/web/tokens';

import * as Balance from '../../Balance/styles';

export const Title = styled.h2`
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: center;
  height: 56px;
  width: 80%;
  top: 57px;
  margin: 0px;
  padding: 19px 16px;
  font-size: 16px;
  line-height: 24px;

  font-weight: ${fontWeightBold};
  background-color: ${colorInterfaceLightPure};
  color: ${colorBrandPrimaryPure};
  transition: opacity 0.5s ease-in-out;

  &.is-closed {
    opacity: 0;
    height: 0px;
    padding: 0px;
  }

  &.is-open {
    opacity: 1;
  }
`;

export const MobileBalanceNavStyled = styled.nav`
  display: flex;
  width: 100%;
  height: 56px;
  align-items: stretch;
  background: ${colorInterfaceLightPure};
  box-shadow: ${shadowLevel1};
  border-top: 1px solid ${colorInterfaceLightDown};
`;

export const Carret = styled.button`
  min-width: 56px;
  height: 56px;
  background: transparent;

  svg {
    fill: none;
    stroke: ${colorInterfaceDarkUp};
    transition: all 0.2s ease-in-out;

    path {
      transition: color 0.5s ease-in-out;
    }

    &.is-closed {
      transform: rotate(0deg);
    }

    &.is-open {
      transform: rotate(180deg);

      path {
        stroke: ${colorBrandPrimaryPure};
      }
    }
  }
`;

export const CurrentBalance = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > button {
    margin: 0px;
    padding: 0px;
    background: transparent;
  }
`;

export const Value = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  transition: opacity 0.5s ease-in-out;

  &.is-closed {
    opacity: 1;
  }

  &.is-open {
    opacity: 0;
  }

  color: ${colorInterfaceDarkDeep};

  > button {
    margin: 4px;
    padding: 0px;
    min-width: 48px;
    height: 48px;
    background: transparent;
  }

  > button:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56px;
    width: 100%;
    margin: 0px;
    font-size: 14px;
    line-height: 22px;

    font-weight: ${fontWeightBold};

    span {
      font-size: 12px;
      line-height: 18px;
      font-weight: ${fontWeightRegular};
    }
  }

  button {
    span:last-child {
      font-family: ${fontFamilyBase};
      font-weight: ${fontWeightBold};
      color: rgb(0, 0, 0);

      &.isNegative {
        color: ${colorStatusNegativePure};
      }
    }
  }
`;

export const MobileBalanceBackDrop = styled.div`
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: calc(100vh - 112px);
    position: absolute;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.5s;

    &.is-closed {
      opacity: 0;
    }

    &.is-open {
      opacity: 1;
    }
  }
`;

export const MobileBalanceContainer = styled.div`
  width: 100%;
  height: calc(100vh - 112px);
  display: block;
  position: absolute;
  top: 112px;
  left: 0;
  overflow: hidden;

  ${Balance.Item} {
    padding: 12px 16px;
    border-top: 1px solid ${colorInterfaceLightDown};
  }

  ${Balance.Item}:first-child {
    border-top: 0px;
    font-weight: ${fontWeightBold};
    ${Balance.Value}, ${Balance.Title} {
      font-weight: ${fontWeightBold};
    }
  }

  ${Balance.Item}:last-child {
    ${Balance.Value} {
      font-weight: ${fontWeightRegular};
    }
  }

  ${Balance.Value} {
    font-weight: normal;
    font-size: 16px;
  }

  ${Balance.BalanceContainer} {
    margin: 0px;
    position: absolute;
    border-radius: 0px;
    border: 0px;
    animation-duration: 0.5s;
    z-index: 2;

    @keyframes show-balance {
      from {
        top: -100vh;
      }
      to {
        top: 0px;
      }
    }

    @keyframes hide-balance {
      from {
        top: 0px;
      }
      to {
        top: -100vh;
      }
    }

    &.is-closed {
      animation-name: hide-balance;
      top: -100vh;
    }

    &.is-open {
      animation-name: show-balance;
      top: 0px;
    }
  }
`;
