/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';

import moment from 'utils/moment.pt-br';
import ListContainer from 'components/PaymentCollectionDetails/components/ListContainer';
import { selectors } from 'store/ducks/paymentCollectionDetails';
import ListItem from '../components/ListItem';

const DateField = ({ field, item }) => (
  <ListItem
    title={field.title}
    value={moment(field.value).format(
      `DD/MM/YYYY${item !== 'dueDateOn' ? ', HH:mm' : ''}`
    )}
  />
);
const Dates = () => {
  const { dates } = useSelector(selectors.getDetailsData);

  return (
    <ListContainer title="Datas">
      {Object.keys(dates)?.map(
        (item) =>
          dates[item] && (
            <DateField
              key={dates[item]?.title}
              item={item}
              field={dates[item]}
            />
          )
      )}
    </ListContainer>
  );
};

export default Dates;
