import React, { Component } from 'react';
import { Field } from 'redux-form';
import RenderDropdownList from 'components/Form/RenderDropdownList';
import moment from 'utils/moment.pt-br';

const now = moment().format();
const thirtyDays = moment().add(1, 'M').format();
const sixtyDays = moment().add(2, 'M').format();
const ninetyDays = moment().add(3, 'M').format();

class PosOptions extends Component {
  posOperatorOptions() {
    return [
      { key: 'definir_na_logistica', label: 'A definir na Logística' },
      { key: 'Vivo', label: 'Vivo' },
      { key: 'Claro', label: 'Claro' },
      { key: 'TIM', label: 'TIM' },
      { key: 'Oi', label: 'Oi' }
    ];
  }

  posQuantityOptions() {
    return [
      { key: 1, label: '1 POS' },
      { key: 2, label: '2 POS' },
      { key: 3, label: '3 POS' },
      { key: 4, label: '4 POS' },
      { key: 5, label: '5 POS' },
      { key: 6, label: '6 POS' },
      { key: 7, label: '7 POS' }
    ];
  }

  posRentMinAmountOptions() {
    return [
      { key: 0, label: 'Isento - sem aluguel' },
      { key: 5000, label: 'R$ 5.000,00' },
      { key: 10000, label: 'R$ 10.000,00' },
      { key: 20000, label: 'R$ 20.000,00' }
    ];
  }

  posRentOptions() {
    return [
      { key: `${now}`, label: 'Sem carência' },
      { key: `${thirtyDays}`, label: '30 dias' },
      { key: `${sixtyDays}`, label: '60 dias' },
      { key: `${ninetyDays}`, label: '90 dias' }
    ];
  }

  posPreferencesEquipmentOptions() {
    return [
      { key: '_nenhum_', label: '(nenhum)' },
      {
        key: 'com_bobina_-_wi_fi_necessário',
        label: 'Com bobina - wi fi necessário'
      },
      {
        key: 'com_bobina_-_wi_fi_não_necessário',
        label: 'Com bobina - wi fi não necessário'
      },
      { key: 'sem_bobina', label: 'Sem bobina' },
      { key: 'pinpad', label: 'pinpad' },
      {
        key: 'tef__sem_envio_de_equipamento_',
        label: 'TEF sem envio de equipamento'
      },
      {
        key: 'pos_getnet',
        label: 'POS Getnet'
      }
    ];
  }

  render() {
    return (
      <div>
        <div className="form-row">
          <div className="form-controls boolean optional">
            <div>
              <input value="0" type="hidden" name="client[global_payments]" />
              <Field
                name="client[global_payments]"
                component="input"
                className="boolean optional"
                type="checkbox"
              />
            </div>
            <label htmlFor="client[global_payments]">
              POS Global Payments?
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="form-controls">
            <Field
              name="client[pos_preferences_quantity]"
              component={RenderDropdownList}
              options={this.posQuantityOptions()}
              simpleValue
              getOptionLabel={({ label }) => label}
              getOptionValue={({ key }) => key}
              label="Quantidade de POS"
              placeholder="Escolha uma opção"
            />
          </div>
          <div className="form-controls">
            <Field
              name="client[pos_preferences_equipment]"
              component={RenderDropdownList}
              options={this.posPreferencesEquipmentOptions()}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ key }) => key}
              simpleValue
              label="Equipamento"
              placeholder="Escolha uma opção"
            />
          </div>
          <div className="form-controls">
            <Field
              name="client[pos_preferences_cell_operator]"
              component={RenderDropdownList}
              options={this.posOperatorOptions()}
              simpleValue
              getOptionLabel={({ label }) => label}
              getOptionValue={({ key }) => key}
              label="Operadora de POS"
              placeholder="Escolha uma opção"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-controls">
            <Field
              name="client[pos_rent_start_at]"
              component={RenderDropdownList}
              options={this.posRentOptions()}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ key }) => key}
              simpleValue
              label="Carência"
              placeholder="Escolha uma opção"
            />
          </div>
          <div className="form-controls">
            <Field
              name="client[pos_rent_min_amount]"
              component={RenderDropdownList}
              options={this.posRentMinAmountOptions()}
              simpleValue
              getOptionLabel={({ label }) => label}
              getOptionValue={({ key }) => key}
              label="Isento a partir de"
              placeholder="Escolha uma opção"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PosOptions;
