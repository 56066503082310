import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from 'react-modal';

class CancelTransactionButton extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  };

  state = {
    modalOpen: false,
    disabled: false
  };

  openModal = () => {
    this.setState({
      modalOpen: true
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  };

  handleClick = () => {
    this.setState({
      disabled: true
    });
  };

  renderClientItemModal() {
    const { modalOpen, disabled } = this.state;
    const { path } = this.props;

    const classes = {
      afterOpen: 'cancel-transaction-modal--open',
      base: 'cancel-transaction-modal',
      beforeClose: 'cancel-transaction-modal'
    };

    const overlayClasses = {
      afterOpen: 'cancel-transaction-overlay--open',
      base: 'cancel-transaction-overlay',
      beforeClose: 'cancel-transaction-overlay'
    };

    const confirmButtonClass = classNames({
      button: true,
      'button--full-danger': true,
      disabled
    });

    return (
      <Modal
        isOpen={modalOpen}
        contentLabel="Modal"
        className={classes}
        overlayClassName={overlayClasses}
        ariaHideApp={false}
      >
        <h1 className="cancel-transaction-title">
          Deseja cancelar essa venda?
        </h1>
        <dl className="cancel-transaction-description">
          <dt>Essa ação não pode ser desfeita:</dt>
          <dd>Cancelará o valor creditado na sua conta</dd>
          <dd>Retornará o valor debitado para o portador do cartão</dd>
          <dt>Deseja cancelar a venda mesmo assim?</dt>
        </dl>
        <div className="cancel-transaction-buttons">
          <button
            className="button button--secondary"
            onClick={this.closeModal}
          >
            Manter Venda
          </button>
          <a
            onClick={this.handleClick}
            className={confirmButtonClass}
            disabled={disabled}
            data-disable-with="Processando..."
            rel="nofollow"
            data-method="post"
            href={path}
          >
            Cancelar Venda
          </a>
        </div>
      </Modal>
    );
  }

  render() {
    const { label } = this.props;

    return (
      <div
        className="statement-detail__actions"
        style={{ justifyContent: 'flex-end' }}
      >
        {this.renderClientItemModal()}
        <button className="button-cancel-transaction" onClick={this.openModal}>
          {label}
        </button>
      </div>
    );
  }
}

export default CancelTransactionButton;
