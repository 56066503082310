import axios from 'axios';
import { useState, useCallback } from 'react';

export default function usePixPayment(cancelUrl, clientUUID) {
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const executeCancel = useCallback(() => {
    setLoadingCancel(true);

    axios
      .get(cancelUrl)
      .then((response) => {
        const { errors } = response.data;

        if (errors && errors.length > 0) {
          setLoadingCancel(false);
          return false;
        }

        window.location.href = `/lojas/${clientUUID}/dashboard`;
        return true;
      })
      .catch(() => {
        setLoadingCancel(false);
      });
  }, [cancelUrl, clientUUID]);

  const openCancelModal = () => {
    setCancelModalOpen(true);
  };

  const closeCancelModal = useCallback(() => {
    setCancelModalOpen(false);
  }, []);

  return {
    loadingCancel,
    cancelModalOpen,
    executeCancel,
    openCancelModal,
    closeCancelModal,
  };
}
