import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { normalizeCurrency } from 'normalizers';
import AccountStatementLink from './AccountStatementLink';

const RefundTransactions = forwardRef(
  ({ isCharger, transactions, showAccountStatementLink }, ref) => {
    const hasInsuranceRate =
      isCharger && transactions.some((t) => t.insuranceRateValue > 0);

    return (
      <div ref={ref} className="refund-history-list__item-details">
        <div className="advancement-details refund-history-list__item-container">
          <table className="refund-history-list__item-container-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Data de {isCharger ? 'Saída' : 'Entrada'}</th>
                <th>Valor</th>
                {hasInsuranceRate && (
                  <th>
                    Seguro Blu
                    <span className="refund-history-list__insurance-tooltip">
                      <i
                        data-for="refund-history-list__insurance-tooltip"
                        data-tip
                        className="fa fa-info-circle"
                      />
                      <ReactTooltip
                        id="refund-history-list__insurance-tooltip"
                        place="top"
                        effect="solid"
                        type="light"
                        border
                        clickable
                        className="refund-history-list__insurance-tooltip-content"
                      >
                        O valor do seguro foi estornado proporcionalmente pela
                        Blu.
                      </ReactTooltip>
                    </span>
                  </th>
                )}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((t) => (
                <tr className="no-border" key={t.id}>
                  <td>{t.id}</td>
                  <td>{moment(t.releasedAt).format('DD/MM/YYYY')}</td>
                  <td>{`R$ ${normalizeCurrency(t.value)}`}</td>
                  {hasInsuranceRate && (
                    <td>{`R$ ${normalizeCurrency(t.insuranceRateValue)}`}</td>
                  )}
                  <td>
                    {showAccountStatementLink && (
                      <AccountStatementLink
                        clientUUID={t.clientUUID}
                        uuid={t.uuid}
                        releasedAt={t.releasedAt}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);

RefundTransactions.propTypes = {
  isCharger: PropTypes.bool.isRequired,
  showAccountStatementLink: PropTypes.bool.isRequired,
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      releasedAt: PropTypes.string.isRequired,
      insuranceRateValue: PropTypes.number,
      clientUUID: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired
    })
  ).isRequired
};

export default RefundTransactions;
