import { createActions, createReducer } from 'reduxsauce';
import { bigBoostDataParser } from 'selectors/sign_up';

/* Types & Creators */

const { Types, Creators } = createActions({
  signUpLoadTaxPlans: ['isPartnerIdNil', 'id'],
  signUpLoadTaxPlansSuccess: ['data'],
  signUpLoadTaxPlansFailure: null,
  signUpReloadBankAccount: ['data'],
  signUpReloadClient: ['data'],
  signUpSetMainAddress: ['data'],
  signUpSetTaxPlans: ['data'],
  signUpLoadDepartments: null,
  signUpLoadDepartmentsSuccess: ['data'],
  signUpLoadDepartmentsFailure: null,
  signUpLoadBigBoost: ['cpfCnpj'],
  signUpLoadBigBoostSuccess: ['data'],
  signUpLoadBigBoostFailure: ['error'],
  signUpLoadUserBigBoost: ['cpf'],
  signUpLoadUserBigBoostSuccess: ['data'],
  signUpLoadUserBigBoostFailure: ['error'],
  signUpRequestBigBoostOnDemand: null,
  signUpLoadBigBoostOnDemandSuccess: ['data']
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  client: {
    bank_account: { savings_account_operation: false },
    main_address: {},
    user: {},
    tax_plan: {}
  },
  loading: false,
  error: false,
  apiOnDemandIsRequested: false,
  loadingBigBoost: false,
  bigBoostMessageError: null,

  bigBoostUserDataLoading: false,
  bigBoostUserDataError: false,
  bigBoostUserData: false
};

/* Reducers */

export const request = (state) => ({
  ...state,
  loading: true
});

export const success = (state, action) => ({
  ...state,
  tax_plans: action.data,
  loading: false,
  error: false
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const reloadBankAccount = (state, action) => ({
  ...state,
  client: {
    ...state.client,
    bank_account: action.data
  }
});

export const reloadClient = (state, action) => ({
  ...state,
  client: {
    ...state.client,
    ...action.data
  }
});

export const setMainAddress = (state, action) => ({
  ...state,
  client: {
    ...state.client,
    main_address: action.data
  }
});

export const setTaxPlans = (state, action) => ({
  ...state,
  tax_plans: action.data
});

export const loadDepartmentsSuccess = (state, action) => ({
  ...state,
  departments: action.data,
  loading: false,
  error: false
});

export const requestBigBoostOnDemand = (state) => ({
  ...state,
  loadingBigBoost: true,
  bigBoostMessageError: null
});

export const requestBigBoost = (state) => ({
  ...state,
  loadingBigBoost: true,
  bigBoostMessageError: null
});

export const loadBigBoostSuccess = (state, action) => ({
  ...state,
  bigBoostData: {
    ...state.bigBoostData,
    ...bigBoostDataParser(action.data)
  },
  loadingBigBoost: false,
  bigBoostMessageError: null
});

export const loadBigBoostOnDemandSuccess = (state, action) => ({
  ...state,
  bigBoostData: {
    ...state.bigBoostData,
    ...bigBoostDataParser({
      ...action.data,
      basicData: {
        ...action.data,
        ...action.data.status
      }
    })
  },
  loadingBigBoost: false,
  bigBoostMessageError: null
});

export const loadBigBoostFailure = (state, action) => ({
  ...state,
  bigBoostMessageError: action.error,
  loadingBigBoost: false
});

export const loadBigBoostUserData = (state) => ({
  ...state,
  bigBoostUserDataLoading: true,
  bigBoostUserDataError: null
});

export const loadBigBoostUserDataSuccess = (state, action) => ({
  ...state,
  bigBoostUserDataLoading: false,
  bigBoostUserDataError: false,
  bigBoostUserData: action.data
});

export const loadBigBoostUserDataFailure = (state, action) => ({
  ...state,
  bigBoostUserDataLoading: false,
  bigBoostUserDataError: action.error
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_UP_LOAD_TAX_PLANS]: request,
  [Types.SIGN_UP_LOAD_TAX_PLANS_SUCCESS]: success,
  [Types.SIGN_UP_LOAD_TAX_PLANS_FAILURE]: failure,
  [Types.SIGN_UP_RELOAD_BANK_ACCOUNT]: reloadBankAccount,
  [Types.SIGN_UP_RELOAD_CLIENT]: reloadClient,
  [Types.SIGN_UP_SET_MAIN_ADDRESS]: setMainAddress,
  [Types.SIGN_UP_SET_TAX_PLANS]: setTaxPlans,
  [Types.SIGN_UP_LOAD_DEPARTMENTS]: request,
  [Types.SIGN_UP_LOAD_DEPARTMENTS_SUCCESS]: loadDepartmentsSuccess,
  [Types.SIGN_UP_LOAD_DEPARTMENTS_FAILURE]: failure,
  [Types.SIGN_UP_LOAD_BIG_BOOST]: requestBigBoost,
  [Types.SIGN_UP_LOAD_BIG_BOOST_SUCCESS]: loadBigBoostSuccess,
  [Types.SIGN_UP_LOAD_BIG_BOOST_FAILURE]: loadBigBoostFailure,

  [Types.SIGN_UP_REQUEST_BIG_BOOST_ON_DEMAND]: requestBigBoostOnDemand,
  [Types.SIGN_UP_LOAD_BIG_BOOST_ON_DEMAND_SUCCESS]: loadBigBoostOnDemandSuccess,

  [Types.SIGN_UP_LOAD_USER_BIG_BOOST]: loadBigBoostUserData,
  [Types.SIGN_UP_LOAD_USER_BIG_BOOST_SUCCESS]: loadBigBoostUserDataSuccess,
  [Types.SIGN_UP_LOAD_USER_BIG_BOOST_FAILURE]: loadBigBoostUserDataFailure
});
