import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const RefundSimulationInfoTooltip = ({ text }) => (
  <span className="refund-payment-collection__tooltip-wrapper">
    <i
      data-for="soclose"
      data-tip
      className="fa fa-info-circle refund-payment-collection__tooltip-fa"
    />
    <ReactTooltip
      id="soclose"
      place="bottom"
      effect="solid"
      type="light"
      border
      clickable
      className="refund-payment-collection__tooltip-content"
    >
      {text}
    </ReactTooltip>
  </span>
);

RefundSimulationInfoTooltip.propTypes = {
  text: PropTypes.string.isRequired
};

export default RefundSimulationInfoTooltip;
