import { createActions, createReducer } from 'reduxsauce';

/* Types & Creators */

const { Types, Creators } = createActions({
  firstAccessUsersUploadEmailRequest: ['uuid', 'email'],
  firstAccessUsersUploadEmailSuccess: null,
  firstAccessUsersUploadEmailFailure: null,
  firstAccessUsersUploadSubmitRequest: ['uuid', 'client', 'file'],
  firstAccessUsersUploadSubmitSuccess: ['data'],
  firstAccessUsersUploadSubmitFailure: null,
  firstAccessUsersUploadToggleModal: null
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  user: {},
  loading: {
    default: false,
    email: false,
    upload: false
  },
  errors: {},
  modal: {
    isOpen: false
  },
  email: {
    sent: false
  }
};

/* Reducers */

export const requestSendEmail = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    email: true
  },
  email: {
    sent: false
  },
  errors: {}
});

export const successSendEmail = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    email: false
  },
  modal: {
    ...state.modal
  },
  email: {
    sent: true
  },
  error: false
});

export const failureSendEmail = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    email: false
  },
  email: {
    sent: false
  },
  error: true
});

export const requestUploadFile = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    upload: true
  },
  errors: {}
});

export const successUploadFile = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    upload: false
  },
  error: false
});

export const failureUploadFile = (state) => ({
  ...state,
  loading: {
    ...state.loading,
    upload: false
  },
  error: true
});

export const toggleModal = (state) => ({
  ...state,
  modal: {
    isOpen: !state.modal.isOpen
  },
  email: {
    sent: false
  }
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRST_ACCESS_USERS_UPLOAD_EMAIL_REQUEST]: requestSendEmail,
  [Types.FIRST_ACCESS_USERS_UPLOAD_EMAIL_SUCCESS]: successSendEmail,
  [Types.FIRST_ACCESS_USERS_UPLOAD_EMAIL_FAILURE]: failureSendEmail,
  [Types.FIRST_ACCESS_USERS_UPLOAD_SUBMIT_REQUEST]: requestUploadFile,
  [Types.FIRST_ACCESS_USERS_UPLOAD_SUBMIT_SUCCESS]: successUploadFile,
  [Types.FIRST_ACCESS_USERS_UPLOAD_SUBMIT_FAILURE]: failureUploadFile,
  [Types.FIRST_ACCESS_USERS_UPLOAD_TOGGLE_MODAL]: toggleModal
});
