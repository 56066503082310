import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@useblu/ocean-react';
import useModalManagement from 'components/Dashboard/hooks/useModalManagement';
import { sendScreenEvent } from '@useblu/blu-lytics';

import BTokenWarning from './BTokenWarning';
import BTokenForceActivation from './BTokenForceActivation';

const BTokenActivation = ({
  bTokenWarning,
  forceBtokenActivation,
  forceBtokenAt,
  currentStoreUuid,
}) => {
  const { isModalOpen, feature, closeModal } = useModalManagement();

  const [modalOpen, setModalOpen] = useState(
    (bTokenWarning || forceBtokenActivation) &&
      (window.location.pathname ===
        `/lojas/${currentStoreUuid}/transacoes/transferencia/nova` ||
        window.location.pathname ===
          `/lojas/${currentStoreUuid}/transacoes/boleto`)
  );
  const [forceBtoken, setForceBtoken] = useState(false);

  useEffect(() => {
    if (feature === 'BTokenForceActivation') {
      setModalOpen(isModalOpen);
    } else if (feature === 'BTokenForceActivationOnTransaction') {
      setModalOpen(isModalOpen);
      setForceBtoken(true);
    }
  }, [isModalOpen, feature]);

  useEffect(() => {
    if (isModalOpen) {
      sendScreenEvent('btoken_required_view');
    }
  }, [isModalOpen]);

  const onClose = () => {
    setModalOpen(false);

    if (isModalOpen) {
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={onClose}
      portalClassName="ocean-iso"
      maxWidth="sm"
      ariaHideApp={false}
    >
      {bTokenWarning && !forceBtoken ? (
        <BTokenWarning forceBtokenAt={forceBtokenAt} />
      ) : (
        <BTokenForceActivation />
      )}
    </Modal>
  );
};

BTokenActivation.propTypes = {
  bTokenWarning: PropTypes.bool.isRequired,
  forceBtokenActivation: PropTypes.bool.isRequired,
  forceBtokenAt: PropTypes.string,
  currentStoreUuid: PropTypes.string.isRequired,
};

BTokenActivation.defaultProps = {
  forceBtokenAt: null,
};

export default BTokenActivation;
