import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Alert, Typography } from '@useblu/ocean-react';
import * as S from './BTokenModalContent.styles';
import TokenInput from '../TokenInput';

import HeaderSVG from './assets/ilustra-biometria-alteracaosenha.svg';

const BTokenModalContent = ({
  info,
  tokenValidationApi,
  postSendApi,
  mobileFullScreen,
  setModalStep,
}) => {
  const digitsQuantity = 6;
  const formRef = useRef(null);
  const [token, setToken] = useState('');
  const [inputErrorCode, setInputErrorCode] = useState('');
  const [alertError, setAlertError] = useState('');
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [tokenError, setTokenError] = useState(false);

  const { title, subtitle, serviceReceiver } = info;

  const validateToken = (token) => {
    setInputErrorMessage('');
    setInputErrorCode('');

    tokenValidationApi(token)
      .then((response) => {
        const { data } = response;

        if (postSendApi) {
          postSendApi(data);
        }
      })
      .catch((error) => {
        if (error.response.data?.error_message || error.response.data?.message)
          setInputErrorMessage(
            error.response.data?.error_message || error.response.data?.message
          );
        else
          setAlertError(
            'Ocorreu um erro inesperado. Caso isso persista, fale com nossa Central de Atendimento ligando para 0800 326 0966 ou pelo chat.'
          );

        if (
          error.response.data?.details &&
          error.response.data?.details.length > 0
        ) {
          setInputErrorCode(error.response.data?.details[0]?.code);
        }
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setInputErrorMessage('');
    setInputErrorCode('');
    setAlertError('');

    if (token.length < digitsQuantity) {
      setInputErrorMessage('Código inválido.');
      setTokenError(true);
      return;
    }

    validateToken(token);
  };

  useEffect(() => {
    if (token.length === digitsQuantity) {
      formRef.current.dispatchEvent(new Event('submit'));
    }
  }, [token]);

  return (
    <S.Container $mobileFullScreen={mobileFullScreen}>
      <S.Image src={HeaderSVG} alt="Ícone de Biometria" />
      <S.Title>{title}</S.Title>
      <S.Subtitle>
        {subtitle}{' '}
        <span className="service-receiver-text">{serviceReceiver}</span>
      </S.Subtitle>

      {alertError ? <Alert type="error">{alertError}</Alert> : <></>}

      <S.CustomForm onSubmit={handleSubmit} ref={formRef}>
        <S.TokenInputWrapper>
          <TokenInput
            onChangeToken={(value) => setToken(value)}
            autoFocus
            digitsQuantity={digitsQuantity}
            error={tokenError}
            errorMessage={inputErrorMessage}
            data-testid="tokenInput"
          />
        </S.TokenInputWrapper>
        <div>
          <Typography variant="caption">
            Não consegue achar o código?{' '}
            <S.LinkButton onClick={() => setModalStep(1)} type="button">
              Tentar de outro jeito
            </S.LinkButton>
          </Typography>
        </div>
        {inputErrorCode ? (
          <S.ErrorCode>Código {inputErrorCode}</S.ErrorCode>
        ) : null}
      </S.CustomForm>
    </S.Container>
  );
};

BTokenModalContent.propTypes = {
  info: PropTypes.object.isRequired,
  tokenValidationApi: PropTypes.func.isRequired,
  postSendApi: PropTypes.func,
  mobileFullScreen: PropTypes.bool.isRequired,
  setModalStep: PropTypes.func.isRequired,
};

BTokenModalContent.defaultProps = {
  postSendApi: null,
};

export default BTokenModalContent;
