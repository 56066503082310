export default function parseErrorResponse(error) {
  try {
    const {
      access_blocked: accessBlocked,
      recaptcha_v2: recaptchaV2,
      maximum_attempts_matched: maximumAttemptsMatched
    } = JSON.parse(error);

    return {
      accessBlocked,
      recaptchaV2,
      maximumAttemptsMatched
    };
  } catch (error) {
    return {
      accessBlocked: false,
      recaptchaV2: false,
      data: error,
      maximumAttemptsMatched: false
    };
  }
}
