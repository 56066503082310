import React from 'react';

import ContentLoader from 'react-content-loader';

import { Container, Row, Col } from '@useblu/ocean-react';

const BigSquare = () => (
  <Row>
    <Col>
      <ContentLoader
        speed={2}
        height={20}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        style={{ width: '100%' }}
      >
        <rect x="0" y="0" rx="3" ry="3" width="700" height="25" />
      </ContentLoader>
    </Col>
  </Row>
);

const MultiAcquirerModalLoader = () => (
  <Container style={{ width: 800 }}>
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
    <BigSquare />
  </Container>
);

export default MultiAcquirerModalLoader;
