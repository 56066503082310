import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #e1e5ed;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  min-height: 106px;
`;

const Title = styled.h2`
  margin: 0;
  margin-top: 2px;
  margin-bottom: 8px;
  color: #78879c;
  font-weight: normal;
  font-size: 12px;
  line-height: 110%;
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ScoreCardChargedLayout = ({ children }) => (
  <Wrapper>
    <Title>Relacionamento Blu</Title>
    <Container>{children}</Container>
  </Wrapper>
);

ScoreCardChargedLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default ScoreCardChargedLayout;
