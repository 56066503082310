import styled from 'styled-components';

import {
  fontSizeXs,
  fontSizeMd,
  fontFamilyBase,
  spacingStackXs,
  spacingStackSm,
  spacingStackXxs,
  spacingInlineSm,
  fontWeightMedium,
  spacingInlineXxs,
  fontWeightExtrabold,
  colorBrandPrimaryDown,
  colorBrandPrimaryPure,
  colorInterfaceDarkDeep,
  colorInterfaceDarkDown,
  colorInterfaceLightUp,
  spacingInlineMd,
} from '@useblu/ocean-tokens/web/tokens';

import { Devices } from 'utils/getDeviceMedia';

export const ModalContent = styled.div`
  display: flex;
  gap: ${spacingInlineSm};
  font-family: ${fontFamilyBase};

  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      max-width: 98px;
    }
  }

  .info-container {
    & > p {
      color: ${colorBrandPrimaryDown};
      font-size: ${fontSizeXs};
      margin-bottom: ${spacingStackXxs};
      font-weight: ${fontWeightMedium};
    }

    & > h1 {
      color: ${colorBrandPrimaryPure};
      font-size: ${fontSizeMd};
      margin-bottom: ${spacingStackXs};
      font-weight: ${fontWeightExtrabold};
    }

    & > span {
      color: ${colorInterfaceDarkDeep};
      font-size: ${fontSizeXs};
    }
  }

  @media ${Devices.tablet} {
    flex-direction: column;
  }

  @media ${Devices.mobile} {
    flex-direction: column;

    .img-container {
      & > img {
        min-width: 0;
      }
    }

    .info-container {
      text-align: center;
    }
  }
`;

export const ModalActions = styled.div`
  display: flex;
  margin-top: ${spacingStackSm};
  justify-content: flex-end;
  align-items: center;

  @media ${Devices.desktop}, ${Devices.notebook}, ${Devices.tablet} {
    .ods-btn--secondary {
      background: none;
      display: flex;
      align-items: center;
      padding: 0;
      gap: ${spacingInlineXxs};

      &:hover {
        background: none;
      }

      & > svg {
        width: 16px;
      }
    }
  }

  @media ${Devices.mobile} {
    flex-direction: column-reverse;
    gap: ${spacingStackXs};

    & > button {
      width: 100%;
    }

    & svg {
      display: none;
    }
  }
`;

export const List = styled.ul`
  margin-top: ${spacingInlineMd};
  display: flex;
  flex-direction: column;
  gap: ${spacingStackXs};

  li {
    display: flex;
    gap: ${spacingInlineXxs};
    align-items: flex-start;

    & > span {
      border-radius: 50%;
      display: flex;
      background: ${colorInterfaceLightUp};

      & > svg {
        color: ${colorBrandPrimaryDown};
      }
    }

    & > p {
      margin: 0;
      color: ${colorInterfaceDarkDown};
    }
  }
`;
