import { createReducer } from 'reduxsauce';
import { keyBy } from 'lodash';

import { types } from './actions';

const initialState = {
  loading: false,
  error: null,
  byId: null,
  success: null
};

export default createReducer(initialState, {
  [types.FETCH_B_TOKEN_BY_USER_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: null,
    byId: null
  }),
  [types.FETCH_B_TOKEN_BY_USER_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    byId: keyBy(action.list, 'id')
  }),
  [types.FETCH_B_TOKEN_BY_USER_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
    byId: null
  }),
  [types.REVOKE_B_TOKEN_BY_ID_REQUEST]: (state, action) => ({
    ...state,
    byId: {
      ...state.byId,
      [action.id]: {
        ...state.byId[action.id],
        loading: true
      }
    }
  }),
  [types.REVOKE_B_TOKEN_BY_ID_SUCCESS]: (state, action) => {
    const { [action.id]: id, ...rest } = state.byId;

    return {
      ...state,
      byId: { ...rest },
      success: true
    };
  },
  [types.REVOKE_B_TOKEN_BY_ID_FAILURE]: (state, action) => ({
    ...state,
    byId: {
      ...state.byId,
      [action.error.action.id]: {
        ...state.byId[action.error.action.id],
        loading: false
      }
    },
    error: action.error
  })
});
