import { Typography, Divider } from '@useblu/ocean-react';
import {
  spacingInlineSm,
  spacingStackMd,
  spacingStackXxs,
  spacingInlineXs,
  colorInterfaceDarkPure,
  colorInterfaceDarkUp,
  fontSizeXs,
  spacingStackXs,
  spacingStackSm,
  colorInterfaceLightUp,
  borderRadiusMd,
  fontWeightRegular,
  colorComplementaryDeep,
  spacingStackXxxs,
  colorStatusPositiveDeep,
} from '@useblu/ocean-tokens/web/tokens';
import styled from 'styled-components';

export const DrawerContent = styled.div`
  overflow-y: auto;
`;

export const DrawerTitle = styled(Typography)`
  &.ods-typography {
    margin: 0 ${spacingInlineSm} ${spacingStackMd};
  }
`;

export const DrawerValues = styled.div`
  margin: 0 ${spacingInlineSm} ${spacingStackMd};
`;

export const DrawerValueItem = styled.div`
  &.with-button {
    display: flex;

    > div {
      flex: 1;

      &:last-of-type {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > button {
          padding: ${spacingStackXxs} ${spacingInlineXs};
        }
      }
    }
  }

  span {
    color: ${colorInterfaceDarkPure};
    font-size: ${fontSizeXs};
  }
`;

export const DrawerValuesDivider = styled(Divider)`
  &.ods-divider {
    margin-top: ${spacingStackXs};
    margin-bottom: ${spacingStackXs};
  }
`;

export const SkeletonSpan = styled.span`
  &.pag-blu {
    color: ${colorStatusPositiveDeep};
  }
`;

export const Subtitle = styled(Typography)`
  &.ods-typography {
    color: ${colorInterfaceDarkUp};
    margin: 0 ${spacingInlineSm} ${spacingStackXs};
  }
`;

export const CalcExplanation = styled.div`
  margin: 0 ${spacingInlineSm} ${spacingStackSm};
  padding: ${spacingInlineXs};
  background-color: ${colorInterfaceLightUp};
  border-radius: ${borderRadiusMd};
  display: flex;
  flex-direction: column;
  gap: ${spacingStackXs};
`;

export const Value = styled.span`
  color: ${colorInterfaceDarkPure};
  font-size: ${fontSizeXs};
  font-weight: ${fontWeightRegular};
`;

export const ExplanationTitleCaption = styled(Typography)`
  &.ods-typography {
    color: ${colorComplementaryDeep};
    margin-bottom: ${spacingStackXxxs};
  }
`;
