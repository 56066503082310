/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const CollapsePanel = ({ title, children }) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  const openToogle = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <div className="collapsepanel">
      <div className="collapsepanel-container" onClick={openToogle}>
        <div className="collapsepanel-container-header">
          <div className="collapsepanel-container-header__title">{title}</div>
          <i
            className={`fa ${
              openCollapse ? 'fa-chevron-up' : 'fa-chevron-down'
            }`}
          />
        </div>
        <div
          className={`collapsepanel-container__content ${
            openCollapse ? 'open' : 'close'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

CollapsePanel.propTypes = {
  title: PropTypes.any,
  children: PropTypes.any.isRequired
};

CollapsePanel.defaultProps = {
  title: ''
};

export default CollapsePanel;
