import { createActions, createReducer } from 'reduxsauce';
import _ from 'lodash';

/* Types & Creators */

const { Types, Creators } = createActions({
  firstAccessClientInfoFormSubmit: ['data'],
  firstAccessClientInfoFormSuccess: ['data'],
  firstAccessClientInfoFormFailure: ['data'],
  firstAccessClientInfoSetAddressRequest: ['client', 'address', 'kind'],
  firstAccessClientInfoSetAddressSuccess: ['client', 'address', 'kind'],
  firstAccessClientInfoSearchAddressByZipcode: ['client', 'data', 'kind'],
  firstAccessClientInfoSearchAddressByCpfCnpj: ['client', 'cpfCnpj']
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  address: {
    posAddressLoading: false,
    mainAddressLoading: false
  },
  bankAccount: {
    loading: false
  },
  loading: false,
  error: false
};

/* Reducers */

export const request = (state) => ({
  ...state,
  loading: true
});

export const success = (state) => ({
  ...state,
  loading: false,
  error: false
});

export const failure = (state) => ({
  ...state,
  loading: false,
  error: true
});

export const setAddressRequest = (state, action) => ({
  ...state,
  address: {
    ...state.address,
    posAddressLoading: _.isEqual(action.kind, 'posDeliveryAddress'),
    mainAddressLoading: _.isEqual(action.kind, 'mainAddress')
  }
});

export const setAddressSuccess = (state) => ({
  ...state,
  address: {
    ...state.address,
    posAddressLoading: false,
    mainAddressLoading: false
  }
});

export const requestZipcode = (state) => ({
  ...state,
  address: {
    ...state.address,
    posAddressLoading: true,
    mainAddressLoading: true
  }
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRST_ACCESS_CLIENT_INFO_FORM_SUBMIT]: request,
  [Types.FIRST_ACCESS_CLIENT_INFO_FORM_SUCCESS]: success,
  [Types.FIRST_ACCESS_CLIENT_INFO_FORM_FAILURE]: failure,
  [Types.FIRST_ACCESS_CLIENT_INFO_SET_ADDRESS_REQUEST]: setAddressRequest,
  [Types.FIRST_ACCESS_CLIENT_INFO_SET_ADDRESS_SUCCESS]: setAddressSuccess,
  [Types.FIRST_ACCESS_CLIENT_INFO_SEARCH_ADDRESS_BY_ZIPCODE]: requestZipcode,
  [Types.FIRST_ACCESS_CLIENT_INFO_SEARCH_ADDRESS_BY_CPF_CNPJ]: requestZipcode
});
