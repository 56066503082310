import { createActions, createReducer } from 'reduxsauce';

const { Types, Creators } = createActions({
  transactionAuthorizationRequest: ['token', 'code'],
  transactionAuthorizationSuccess: ['data'],
  transactionAuthorizationFailure: null,

  showTransactionAuthorizationRequest: ['token'],
  showTransactionAuthorizationSuccess: [
    'status',
    'expirationDate',
    'is_portability',
    'is_automatic_portability',
    'liveness',
    'canceled_by',
  ],
  showTransactionAuthorizationFailure: null,

  resendNotificationRequest: ['token'],
  resendNotificationSuccess: null,
  resendNotificationFailure: ['message'],
});

export { Types };
export default Creators;

const INITIAL_STATE = {
  loading: false,
  showLoading: false,
  error: false,
  done: false,
  expired: false,
  errorMessage: {},
  expirationDate: new Date(Date.now).getTime(),
  data: {},
  resendError: false,
  canceledBy: null,
};

export const request = (state) => ({
  ...state,
  error: false,
  loading: true,
});

export const success = (state, action) => ({
  ...state,
  loading: false,
  error: false,
  done: true,
  data: action.data,
});

export const failure = (state, action) => ({
  ...state,
  loading: false,
  error: true,
});

export const showRequest = (state) => ({
  ...state,
  showLoading: true,
});

export const showSuccess = (state, action) => ({
  ...state,
  done: action.status === 'completed',
  expired: action.status === 'expired',
  expirationDate: action.expirationDate,
  showLoading: false,
  liveness: action.liveness,
  is_portability: action.is_portability,
  is_automatic_portability: action.is_automatic_portability,
  canceledBy: action.canceled_by,
});

export const showFailure = (state, action) => ({
  ...state,
  expired: true,
  showLoading: false,
});

export const resendRequest = (state) => ({
  ...state,
  resendError: false,
});

export const resendFailure = (state, action) => ({
  ...state,
  resendError: action.message,
});

export const resendSuccess = (state) => ({
  ...state,
  resendError: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TRANSACTION_AUTHORIZATION_REQUEST]: request,
  [Types.TRANSACTION_AUTHORIZATION_SUCCESS]: success,
  [Types.TRANSACTION_AUTHORIZATION_FAILURE]: failure,

  [Types.SHOW_TRANSACTION_AUTHORIZATION_REQUEST]: showRequest,
  [Types.SHOW_TRANSACTION_AUTHORIZATION_SUCCESS]: showSuccess,
  [Types.SHOW_TRANSACTION_AUTHORIZATION_FAILURE]: showFailure,

  [Types.RESEND_NOTIFICATION_REQUEST]: resendRequest,
  [Types.RESEND_NOTIFICATION_FAILURE]: resendFailure,
  [Types.RESEND_NOTIFICATION_SUCCESS]: resendSuccess,
});
