import React from 'react';
import { WarningContainer } from 'components/Devise/devise.styles';
import { Typography } from '@useblu/ocean-react';
import illustrationInvalidClient from '../../images/invalidClientAfterSignup.png';
import DeviseScreenLayout from '../DeviseScreenLayout';

const BlockedClient = () => {
  return (
    <DeviseScreenLayout className="adjust-logo">
      <WarningContainer>
        <img src={illustrationInvalidClient} alt="ilustra-hourglass" />
        <Typography variant="heading2">Não é possível prosseguir</Typography>
        <Typography variant="paragraph">
          Nesse momento não podemos te oferecer acesso a plataforma.
        </Typography>
      </WarningContainer>
    </DeviseScreenLayout>
  );
};

export default BlockedClient;
