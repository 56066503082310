import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loading from 'react-loading';
import { connect } from 'react-redux';

import { formatDate } from 'normalizers';
import HistoryActions from 'store/ducks/history';

const header = { textTransform: 'uppercase' };

class EconomicGroupHistory extends React.Component {
  componentDidMount() {
    const { getEconomicGroupHistories, uuid } = this.props;
    getEconomicGroupHistories(uuid, 1);
  }

  seeMore = () => {
    const { uuid, nextPage, getEconomicGroupHistories } = this.props;
    getEconomicGroupHistories(uuid, nextPage);
  };

  render() {
    const { nextPage, seeMoreLoading, economicGroupHistories } = this.props;

    return (
      <div className="container">
        <div className="flex-container is-padded">
          <h1 className="page-title">Histórico do Grupo Econômico</h1>
          <table>
            <thead>
              <tr style={header}>
                <th>Tipo</th>
                <th>Data da Edição</th>
                <th>Hora</th>
                <th colSpan="2">Evento</th>
                <th>Usuário</th>
              </tr>
            </thead>
            <tbody>
              {economicGroupHistories.map((item, i) => {
                let eventAux = '';
                if (item.event.includes('Adicionado:')) {
                  eventAux = (
                    <span>
                      <span style={{ color: 'green' }}>Adicionado: </span>
                      {item.event.replace('Adicionado: ', '')}
                    </span>
                  );
                } else if (item.event.includes('Removido:')) {
                  eventAux = (
                    <span>
                      <span style={{ color: 'red' }}>Removido: </span>
                      {item.event.replace('Removido: ', '')}
                    </span>
                  );
                } else {
                  eventAux = item.event;
                }

                return (
                  <tr key={i}>
                    <td>{item.itemType}</td>
                    <td>{formatDate(item.createdAt)}</td>
                    <td>{moment(item.createdAt).format('HH:mm')}</td>
                    <td colSpan="2">{eventAux}</td>
                    <td>{item.user}</td>
                  </tr>
                );
              })}
            </tbody>
            {nextPage && (
              <tfoot>
                <tr>
                  <td colSpan="6">
                    <button
                      onClick={this.seeMore}
                      className="button button--secondary"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                      }}
                    >
                      {seeMoreLoading ? (
                        <Loading
                          type="spinningBubbles"
                          color="#343e4f"
                          delay={0}
                          width={17}
                          height={17}
                        />
                      ) : (
                        'Ver Mais'
                      )}
                    </button>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    );
  }
}

EconomicGroupHistory.propTypes = {
  uuid: PropTypes.string.isRequired,
  getEconomicGroupHistories: PropTypes.func.isRequired,
  economicGroupHistories: PropTypes.array,
  nextPage: PropTypes.number,
  seeMoreLoading: PropTypes.bool.isRequired
};

EconomicGroupHistory.defaultProps = {
  economicGroupHistories: [],
  nextPage: null
};

const mapStateToProps = (state) => ({
  economicGroupHistories: state.history.economicGroupHistories,
  nextPage: state.history.nextPage,
  seeMoreLoading: state.history.loading
});

const mapDispatchToProps = (dispatch) => ({
  getEconomicGroupHistories: (uuid, page) =>
    dispatch(HistoryActions.getEconomicGroupHistoriesRequest(uuid, page))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EconomicGroupHistory);
