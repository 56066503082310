/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Presentational */
import _ from 'lodash';
import classNames from 'classnames';

export default class ClientSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    document.body.addEventListener('click', (e) => {
      if (
        this.storeSelector !== e.target &&
        !this.storeSelector.contains(e.target)
      ) {
        this.setState({ open: false });
      }
    });
  }

  dropdownToggle(event) {
    event.preventDefault();

    this.setState({
      open: !this.state.open
    });
  }

  currentStore() {
    const { currentStoreId, stores } = this.props;
    return _.find(stores, { id: currentStoreId });
  }

  render() {
    const { open } = this.state;
    const { stores } = this.props;

    const storeSelectorClass = classNames({
      'store-selector': true,
      'store-selector--open': open
    });

    const dropdownClasses = classNames({
      'store-selector__dropdown': true,
      'store-selector__dropdown--open': open
    });

    return (
      <div
        className={storeSelectorClass}
        ref={(storeSelector) => {
          this.storeSelector = storeSelector;
        }}
      >
        <button onClick={this.dropdownToggle.bind(this)}>
          <small className="store-selector__title">seleção de loja</small>
          <p className="store-selector__selected">{this.currentStore().name}</p>
          <i className="fa fa-caret-down" />
        </button>

        <div className={dropdownClasses}>
          {stores
            .sort((a, b) => (a.name < b.name ? -1 : 1))
            .map(
              (store) =>
                store.id !== this.currentStore().id && (
                  <a
                    key={store.id}
                    href={`/lojas/${store.id}/dashboard`}
                    className="store-selector__store"
                  >
                    {store.name}
                  </a>
                )
            )}
        </div>
      </div>
    );
  }
}

ClientSelector.propTypes = {
  currentStoreId: PropTypes.string,
  stores: PropTypes.array
};
