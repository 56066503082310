import styled from 'styled-components';
import {
  fontFamilyHighlight,
  fontFamilyBase,
  fontSizeXxs,
  fontSizeXs,
  fontWeightRegular,
  fontWeightExtrabold,
  spacingInlineXxs,
  spacingStackSm,
  spacingInlineLg,
  colorInterfaceDarkDeep
} from '@useblu/ocean-tokens/web/tokens';

export const BluAppDownloadCard = styled.div`
  padding-top: ${spacingInlineLg};

  h1 {
    font-family: ${fontFamilyHighlight};
    font-style: normal;
    font-weight: ${fontWeightExtrabold};
    font-size: ${fontSizeXs};
    line-height: 110%;
    color: ${colorInterfaceDarkDeep};
    padding-bottom: ${spacingInlineXxs};
  }

  span {
    font-family: ${fontFamilyBase};
    font-style: normal;
    font-weight: ${fontWeightRegular};
    font-size: ${fontSizeXxs};
    line-height: 150%;
    color: ${colorInterfaceDarkDeep};
  }
`;

export const CallToAction = styled.div`
  display: flex;
  padding-top: ${spacingStackSm};
`;

export const QRCode = styled.div`
  img {
    width: 150px !important;
    height: 150px !important;
  }
`;
