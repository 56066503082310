/* Core */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* Redux */
import { connect } from 'react-redux';

/* Actions and Validators */
import usersContractsActions from 'store/ducks/firstAccess/usersContracts';
import qsaAccountsActions from 'store/ducks/firstAccess/qsaAccounts';
import sharedActions from 'store/ducks/firstAccess/shared';

import history from 'utils/browserHistory';

/* Presentational */
import _ from 'lodash';
import { clientSelector } from 'selectors/FirstAccess/shared';
import { updateSignedClickSign } from 'api/first-access';

let clickSignThis = null;

class UsersContractsForm extends Component {
  constructor(props) {
    super(props);
    this.previous = this.previous.bind(this);
    this.next = this.next.bind(this);
    this.rendered = false;
  }

  componentDidMount() {
    const { uuid, railsContext, usersContractsRequest } = this.props;

    usersContractsRequest(uuid, railsContext.currentUserId);
    this.props.setCurrentStep('usersContracts');
  }

  componentDidUpdate() {
    const { client, firstAccessQsaAccountsRequest } = this.props;
    if (!_.isNil(client.contractLoteKey) && this.rendered === false) {
      firstAccessQsaAccountsRequest(client);
      this.loadClickSign();
      this.rendered = true;
    }
  }

  showChat(event) {
    event.preventDefault();

    if ($zopim) {
      $zopim.livechat.window.toggle();
    }
  }

  next() {
    history.push(`/lojas/${this.props.uuid}`);
  }

  previous() {
    history.push(
      `/lojas/${this.props.uuid}/primeiro-acesso/autorizacoes-dos-socios`
    );
  }

  contractIsNotReady() {
    const { client } = this.props;

    return _.isNil(client.contractLoteKey);
  }

  loadClickSign() {
    const { client, railsContext } = this.props;
    clickSignThis = this;

    if (!this.contractIsNotReady()) {
      clicksign.create('signature-box', client.contractLoteKey, {
        signer: {
          email: railsContext.currentUserEmail,
          display_name: railsContext.currentUserName,
          documentation: railsContext.currentUserCpf,
          birthday: railsContext.currentUserBirthday
            ? railsContext.currentUserBirthday
            : railsContext.currentClientFoundationDate
        },
        endpoint: 'https://widget.clicksign-demo.com',
        version: '1',
        width: '775px',
        height: '505px',
        colors: {
          main_color: '#33c4d6',
          header_color: '#33c4d6'
        },
        callbacks: [
          function (ev) {
            if (event.data === 'signed') {
              const params = { client };
              updateSignedClickSign(params);
              clickSignThis.next();
            }
          }
        ]
      });
    }
  }

  contractContent() {
    const { admin } = this.props.permission;

    return (
      <div className="form-fields-for">
        <h2 className="page-subtitle">
          <i className="fa fa-file-text-o" /> Contratos
        </h2>
        {admin === true ? (
          <h3 className="page-description">
            Precisamos da sua assinatura dos seguintes contratos.
          </h3>
        ) : (
          <h3 className="page-description">
            Por favor, peça para que o administrador faça este passo e tenha
            acesso ao contrato digital.
          </h3>
        )}
        <form className="form-fields-for" noValidate>
          {admin ? (
            <div id="signature-box" />
          ) : (
            <div id="signature-box" style={{ display: 'none' }} />
          )}
          <div className="form-row actions">
            <button
              className="button form-controls button--secondary"
              onClick={this.previous}
            >
              Voltar
            </button>

            {!admin && (
              <button
                className="button form-controls button--primary"
                onClick={this.next}
              >
                Pular este passo
              </button>
            )}
          </div>
          <div className="form-row actions help-link">
            <div className="form-row">
              <a className="form-help-link" onClick={(e) => this.showChat(e)}>
                <i className="fa fa-question-circle" /> Alguma dúvida? Clique
                aqui!
              </a>

              <div className="form-help-link">
                <a href="/selecionar-empresa">
                  <i className="fa fa-building" /> Trocar Empresa
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  contractLoading() {
    return (
      <div>
        <div className="form-fields-for">
          <h2 className="page-subtitle">
            <i className="fa fa-file-text-o" /> Contratos
          </h2>
        </div>
        <div className="form-row actions help-link">
          <div className="contract-error-container">
            <i className="fa fa-exclamation-circle" />
            <a
              className="form-help-link--font-large"
              onClick={(e) => this.showChat(e)}
            >
              <p>
                O contrato ainda não foi gerado, clique{' '}
                <span className="click">aqui</span> para falar com nosso suporte
              </p>
            </a>
          </div>
        </div>
        <div className="form-row actions">
          <button
            className="button form-controls button--secondary"
            onClick={this.previous}
          >
            Voltar
          </button>
          <button
            className="button form-controls button--primary"
            onClick={this.next}
          >
            Pular este passo
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="flex-container first-access-app">
          {this.contractIsNotReady()
            ? this.contractLoading()
            : this.contractContent()}
        </div>
      </div>
    );
  }
}

UsersContractsForm.propTypes = {
  uuid: PropTypes.string.isRequired,
  usersContractsRequest: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  railsContext: PropTypes.object,
  permission: PropTypes.shape({
    admin: PropTypes.bool
  }).isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  firstAccessQsaAccountsRequest: PropTypes.func.isRequired
};

UsersContractsForm.defaultProps = {
  client: {},
  loading: false
};

const mapStateToProps = (state) => ({
  client: clientSelector(state),
  loading: state.firstAccessUsersContracts.loading,
  railsContext: state.railsContext,
  permission: state.permission
});

const mapDispatchToProps = (dispatch) => ({
  usersContractsRequest: (uuid, userId) =>
    dispatch(
      usersContractsActions.firstAccessUsersContractsRequest(uuid, userId)
    ),
  firstAccessQsaAccountsRequest: (client) =>
    dispatch(qsaAccountsActions.firstAccessQsaAccountsRequest(client)),
  setCurrentStep: (step) => dispatch(sharedActions.firstAccessCurrentStep(step))
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersContractsForm);
