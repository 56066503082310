import React, { useState } from 'react';
import { Modal, Button } from '@useblu/ocean-react';
import { useSelector } from 'react-redux';

import { railsContextSelector } from 'selectors/rails-context';

import ilustraSenhaAviso from './images/ilustra-senha-aviso.svg';
import ilustraSenhaExpirada from './images/ilustra-senha-expirada.svg';
import { ModalContent, ModalFooter } from './styles';

const PasswordExpiration = () => {
  const { needChangePassword, changePasswordRequired } =
    useSelector(railsContextSelector);

  const [isModalOpen, setModalIsOpen] = useState(needChangePassword || false);

  const onClose = () => {
    setModalIsOpen(false);
  };

  const info = () => {
    if (changePasswordRequired) {
      return {
        img: ilustraSenhaExpirada,
        title: 'Sua senha expirou',
        content:
          'Troque a sua senha para continuar acessando a sua Conta Digital Blu.'
      };
    }

    return {
      img: ilustraSenhaAviso,
      title: 'Sua senha irá expirar em breve',
      content:
        'Precisamos que você troque a sua senha para continuar acessando a sua Conta Digital Blu.'
    };
  };

  return (
    <Modal
      isOpen={isModalOpen}
      ariaHideApp={false}
      portalClassName="ocean-iso"
      maxWidth="md"
      onRequestClose={changePasswordRequired ? null : onClose}
    >
      <div>
        <ModalContent>
          <div>
            <img src={info().img} alt="" />
          </div>
          <div>
            <h1>{info().title}</h1>
            <p>{info().content}</p>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            type="button"
            onClick={() => (window.location.href = '/senha-expirada')}
          >
            Trocar senha
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default PasswordExpiration;
