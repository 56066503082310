import { createActions, createReducer } from 'reduxsauce';
import _ from 'lodash';

/* Types & Creators */

const { Types, Creators } = createActions({
  firstAccessUsersAccountsRequest: ['uuid'],
  firstAccessUsersAccountsSuccess: ['data'],
  firstAccessUsersAccountsFailure: ['data'],
  firstAccessUsersAccountsDeleteUser: ['email', 'clientUuid'],
  firstAccessUsersAccountsDeleteUserSuccess: ['data'],
  firstAccessUsersAccountsSetCurrentUser: ['data'],
  firstAccessUsersAccountsSaveUser: ['user', 'uuid'],
  firstAccessUsersAccountsSaveUserSuccess: ['data'],
  firstAccessUsersAccountsUpdateUser: ['user', 'uuid'],
  firstAccessUsersAccountsUpdateUserSuccess: ['data'],
  firstAccessUsersAccountsSavingFailure: ['errors']
});

export { Types };
export default Creators;

/* Initial State */

const INITIAL_STATE = {
  users: [],
  qsa: [],
  formLoading: false,
  saveModalLoading: false,
  deleteModalLoading: false,
  errors: {},
  currentUser: {},
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false
};

/* Reducers */

export const request = (state) => ({
  ...state,
  formLoading: true,
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false,
  saveModalLoading: false,
  errors: {}
});

export const deleteRequest = (state) => ({
  ...state,
  deleteModalLoading: true,
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false,
  saveModalLoading: false,
  errors: {}
});

export const saveUserRequest = (state) => ({
  ...state,
  saveModalLoading: true,
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false,
  errors: {}
});

export const success = (state, action) => ({
  ...state,
  formLoading: false,
  users: action.data,
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false,
  saveModalLoading: false,
  errors: {}
});

export const failure = (state, action) => ({
  ...state,
  formLoading: false,
  users: action.data,
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false,
  saveModalLoading: false,
  errors: {}
});

export const qsaSuccess = (state, action) => ({
  ...state,
  formLoading: false,
  qsa: action.data,
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false,
  saveModalLoading: false,
  errors: {}
});

export const setCurrentUser = (state, action) => ({
  ...state,
  currentUser: action.data,
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false,
  saveModalLoading: false,
  errors: {}
});

export const saveUserSuccess = (state, action) => {
  const { users } = state;
  const usersSaved = _.unionBy(users, [action.data], 'email');

  return {
    ...state,
    saveModalLoading: false,
    currentUser: {},
    users: usersSaved,
    shouldCloseDeleteModal: false,
    shouldCloseSaveModal: true,
    errors: {}
  };
};

export const savingFailure = (state, action) => ({
  ...state,
  formLoading: false,
  saveModalLoading: false,
  shouldCloseDeleteModal: false,
  shouldCloseSaveModal: false,
  errors: action.errors
});

export const deleteUserSuccess = (state, action) => {
  const { users } = state;
  const usersDeleted = _.pull(users, action.data);
  return {
    ...state,
    deleteModalLoading: false,
    saveModalLoading: false,
    currentUser: {},
    users: usersDeleted,
    shouldCloseDeleteModal: true,
    shouldCloseSaveModal: false,
    errors: {}
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_REQUEST]: request,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_SUCCESS]: success,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_FAILURE]: failure,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_DELETE_USER]: deleteRequest,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_SET_CURRENT_USER]: setCurrentUser,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_SAVE_USER]: saveUserRequest,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_SAVE_USER_SUCCESS]: saveUserSuccess,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_UPDATE_USER]: saveUserRequest,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_UPDATE_USER_SUCCESS]: saveUserSuccess,
  [Types.FIRST_ACCESS_USERS_ACCOUNTS_SAVING_FAILURE]: savingFailure
});
