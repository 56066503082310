import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@useblu/ocean-react';
import useModalManagement from 'components/Dashboard/hooks/useModalManagement';

import TaxSVG from './images/tax.svg';
import cashbackSVG from './images/cashback.svg';

import PaymentConfirmationSVG from './images/payment_confirmation.svg';

import {
  Container,
  FixedTaxContainer,
  CashbackContainer,
  Title,
  Main,
  Hands,
  Icon,
  Description,
  PaymentDescription
} from './styles';

const Header = ({ tax, fullstoryClassName }) => {
  const { openModal } = useModalManagement();

  return (
    <>
      <Main>
        <Container>
          <FixedTaxContainer>
            <Description>
              <Title>Ative a Antecipação Automática por {tax}%</Title>
              <p>
                Venda em <b>12x</b> e receba{' '}
                <b>no dia seguinte na sua Conta Digital Blu!</b>
              </p>
              <Button
                className={fullstoryClassName}
                role="button"
                onClick={() => {
                  openModal('AutomaticAnticipation');
                }}
              >
                Ativar Antecipação Automática
              </Button>
            </Description>
            <Icon src={TaxSVG} alt="Taxa fixa" />
          </FixedTaxContainer>
          <Hands />
          <CashbackContainer>
            <Icon src={cashbackSVG} alt="Cashback" />
            <Description>
              <Title>Receba de volta {tax}% </Title>
              <p>
                <b>Ao pagar seus fornecedores pela Blu </b> com Antecipação
                Zero!
              </p>
            </Description>
          </CashbackContainer>
        </Container>
      </Main>
      <PaymentDescription>
        <Container>
          <img src={PaymentConfirmationSVG} alt="pagmento confirmado" />
          <div>
            <p>
              Pague seus Fornecedores pela Blu na modalidade PagBlu e receba de
              volta {tax}% na sua Conta Digital Blu.
            </p>
            <p>
              Com a Blu você pode nunca mais pagar taxa de antecipação para
              pagar seus Fornecedores.
            </p>
          </div>
        </Container>
      </PaymentDescription>
    </>
  );
};

Header.propTypes = {
  tax: PropTypes.string,
  fullstoryClassName: PropTypes.string
};

Header.defaultProps = {
  tax: '7,99',
  fullstoryClassName: ''
};

export default Header;
